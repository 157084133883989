<template>
	<AdvancedDataTable
			:data-url="route('users.commissionList', userId)"
			:columns="columns"
			sort-by="created_at"
			sort-type="desc"
			name="userCommissions"
			ref="dataTable"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>
							<Price
								:value="item.amount"
							/>
						</strong>

						<attribute
							icon="far fa-clock"
							>
							<DateTime :value="item.created_at"/>
						</attribute>
						<attribute
							v-if="item.sourceUser"
							icon="far fa-user"
							label="Team jutalék"
							>
							{{ item.sourceUser }}
						</attribute>

						<div class="mobile-horizontal">
							<template v-if="item.model_type === '\\App\\Model\\UserBonus'">
								<data-loader :data="['userBonuses']" v-slot="{ dataStore }">
									<template v-if="dataStore.getData('userBonuses', true)[item.model_id] !== undefined">
										<color-badge :value="dataStore.getData('userBonuses', true)[item.model_id]"></color-badge>
									</template>
								</data-loader>
							</template>
							<data-loader :data="['commissionTypes']" v-slot="{ dataStore }">
								<color-badge
									:value="dataStore.getData('commissionTypes')[item.type]"></color-badge>
							</data-loader>

							<color-badge
								:color="item.paid ? 'success' : 'danger'"
								label="Kifizetve"
								:content="item.paid ? 'Igen' : 'Nem'"
							/>
						</div>

						<mobile>
							<project-badge
								:project-id="item.project_id"
								:project-type-id="item.project_type_id"
								:contract-id="item.contract_id"
								v-if="item.project_id"
								:key="'PR' + item.project_id + '_C_' + item.contract_id"
							>
								{{ item.clientName }} - {{ item.projectPublicId }}
							</project-badge>
						</mobile>
					</div>
				</div>
			</div>
		</template>
		<template #item-created_at="item">
			<DateTime :value="item.created_at"/>
		</template>
		<template #item-type="item">
			<template v-if="item.model_type === '\\App\\Model\\UserBonus'">
				<data-loader :data="['userBonuses']" v-slot="{ dataStore }">
					<template v-if="dataStore.getData('userBonuses', true)[item.model_id] !== undefined">
						<color-badge :value="dataStore.getData('userBonuses', true)[item.model_id]"></color-badge>
					</template>
				</data-loader>
			</template>
			<data-loader :data="['commissionTypes']" v-slot="{ dataStore }">
				<color-badge
					:value="dataStore.getData('commissionTypes')[item.type]"></color-badge>
			</data-loader>
		</template>
		<template #item-amount="item">
			<Price
				:value="item.amount"
				/>
		</template>
		<template #item-projectPublicId="item">
			<desktop>
				<project-badge
					:project-id="item.project_id"
					:project-type-id="item.project_type_id"
					:contract-id="item.contract_id"
					v-if="item.project_id"
					:key="'PR' + item.project_id + '_C_' + item.contract_id"
				>
					{{ item.clientName }} - {{ item.projectPublicId }}
				</project-badge>
			</desktop>
		</template>
		<template #item-paid="item">
			<color-badge
				:color="item.paid ? 'success' : 'danger'"
				label="Kifizetve"
				:content="item.paid ? 'Igen' : 'Nem'"
				/>
		</template>
	</AdvancedDataTable>
</template>
<script>
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/badges/BooleanBadge.vue";
import UserLevelBadge from "../../components/UserLevelBadge.vue";
import Price from "../../components/Price.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import DataLoader from "../../components/DataLoader.vue";
import DateTime from "../../components/DateTime.vue";
import ProjectStatusIcon from "../projects/ProjectStatusIcon.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";
import Attribute from "../../components/Attribute.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import ContractDeliveryBadges from "../projects/ContractDeliveryBadges.vue";
import AddressAttribute from "../../components/attributes/AddressAttribute.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";
import Desktop from "../../components/responsive/Desktop.vue";
import Mobile from "../../components/responsive/Mobile.vue";

export default {
	components: {
		Mobile,
		Desktop,
		ProjectBadge,
		AddressAttribute,
		ContractDeliveryBadges, TableActionDropdown,
		Attribute,
		ProjectTypeCell,
		ProjectStatusIcon,
		DateTime,
		DataLoader,
		ColorBadge,
		Price,
		UserLevelBadge,
		AdvancedDataTable,
		BooleanBadge,
		TableActionCell
	},
	props: {
		userId: Number,
	},
	data(){
		let columns = [
			{
				text: 'Időpont',
				value: 'created_at',
				sortable: true,
			},
			{
				text: 'Típus',
				value: 'type',
				sortable: true,
			},
			{
				text: 'Összeg',
				value: 'amount',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Kifizetve',
				value: 'paid',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Team jutalék',
				value: 'sourceUser',
				sortable: true,
			},
			{
				text: 'Projekt',
				value: 'projectPublicId',
				sortable: true,
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			dataStore: useGeneralDataStore(),
		}
	},
	methods: {
		reload: function (){
			this.$refs.dataTable.getData()
		}
	}
}
</script>

<style scoped>
</style>
