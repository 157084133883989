<template>
	<TitleBar title="Készletfoglalás" />

	<AdvancedDataTable
		:data-url="route('inventoryReservations.list')"
		:columns="columns"
		sort-by="signed_at"
		sort-type="asc"
		name="deliveries"
		ref="table"
		:default-filters="defaultFilters"
	>

		<template #filters="{serverOptions}">
			<div class="data-table-filter-item">
				<v-select
					:options="deliveryFilters"
					v-model="serverOptions.delivery_filter"
					:reduce="item => item.id"
					label="name"
					placeholder="Szállítás"
				/>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.clientName }}</strong>

						<address-attribute :address="item" />

						<div>
							<project-badge
								:project-id="item.project_id"
								:contract-id="item.id"
								:project-type-id="item.project_type_id"
								:key="'PR' + item.project_id + '_C_' + item.id"
							>
								{{ item.public_id }}
							</project-badge>
						</div>
						<attribute icon="far fa-clock">
							<date-time :value="item.signed_at"/>
						</attribute>

						<div class="mobile-horizontal">
							<color-badge color="light" >
								Igény: <number :value="item.requiredQuantity - item.deliveredQuantity" />
							</color-badge>

							<color-badge
								v-if="!item.reservedQuantity"
								color="danger"
							>
								Lefoglalva: <number :value="item.reservedQuantity" />
							</color-badge>
							<color-badge
								v-else
								:color="item.reservedQuantity < item.requiredQuantity - item.deliveredQuantity ? 'warning' : 'success'"
							>
								Lefoglalva: <number :value="item.reservedQuantity" />
							</color-badge>
						</div>


					</div>
				</div>
				<div class="table-row-operations d-flex gap-2">
					<Link :href="route('inventoryReservations.contract.index')  + '/' + item.id" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="far fa-eye"></i></Link>
					<data-loader :data="['warehouses']" v-slot="{ dataStore }">
						<div class="dropdown" v-if="item.reservedQuantity > 0 && app.hasPermission('InventoryController_createDelivery')">
							<button type="button" class="btn btn-secondary btn-sm waves-effect" data-bs-toggle="dropdown" :id="'dropdownActions_' + item.id" aria-expanded="false" title="Szállítmány létrehozása"><i class="far fa-truck"></i></button>
							<div class="dropdown-menu dropdown-menu-end" :aria-labelledby="'dropdownActions_' + item.id" role="menu">
								<div class="d-flex gap-2 flex-column">
									<template v-for="warehouse in getAvailableWarehouses(item)">
										<Link :href="route('inventory.create.delivery')  + '/' + item.id + '/' + warehouse.id" class="dropdown-item">{{ warehouse.name }}</Link>
									</template>
								</div>
							</div>
						</div>
					</data-loader>
				</div>
			</div>
		</template>
		<template #item-clientName="item">
			<div class="stacked-cell">
				<strong>{{ item.clientName }}</strong>

				<address-badge :address="item" />

				<div>
					<project-badge
						:project-id="item.project_id"
						:contract-id="item.id"
						:project-type-id="item.project_type_id"
						:key="'PR' + item.project_id + '_C_' + item.id"
						>
						{{ item.public_id }}
					</project-badge>
				</div>
			</div>
		</template>
		<template #item-reservedQuantity="item">
			<color-badge
				v-if="!item.reservedQuantity"
				color="danger"
				>
				<number :value="item.reservedQuantity" />
			</color-badge>
			<color-badge
				v-else
				:color="item.reservedQuantity < item.requiredQuantity - item.deliveredQuantity ? 'warning' : 'success'"
				>
				<number :value="item.reservedQuantity" />
			</color-badge>
		</template>
		<template #item-requiredQuantity="item">
			<color-badge color="light" >
				<number :value="item.requiredQuantity - item.deliveredQuantity" />
			</color-badge>
		</template>
		<template #item-signed_at="item">
			<date-time :value="item.signed_at"/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import vSelect from "vue-select";
import {useGeneralDataStore} from "../stores/generalData";
import {useApplicationStore} from "../stores/applicationStore";
import Number from "../components/Number.vue";
import DateTime from "../components/DateTime.vue";
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {Link} from "@inertiajs/vue3";
import ColorBadge from "../components/ColorBadge.vue";
import Attribute from "../components/Attribute.vue";
import ProjectBadge from "../components/badges/ProjectBadge.vue";
import AddressAttribute from "../components/attributes/AddressAttribute.vue";
import AddressBadge from "../components/badges/AddressBadge.vue";

export default {
	layout: AppLayout,
	components: {
		AddressBadge,
		AddressAttribute,
		ProjectBadge,
		Attribute, ColorBadge, TitleBar, DateTime, Link, Number, vSelect, DataLoader, AdvancedDataTable},
	data(){
		let columns = [
			{
				text: 'Ügyfél',
				value: 'clientName',
				sortable: true
			},
			{
				text: 'Igény',
				value: 'requiredQuantity',
				sortable: true,
				align: 'right',
			},
			{
				text: 'Lefoglalva',
				value: 'reservedQuantity',
				align: 'right',
				sortable: true
			},
			{
				text: 'Keletkezés',
				value: 'signed_at',
				sortable: true
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]
		return {
			columns: columns,
			dataStore: useGeneralDataStore(),
			app: useApplicationStore(),
			defaultFilters: {
				delivery_filter: 'all'
			},
			deliveryFilters: [
				{
					id: 'all',
					name: 'Összes'
				},
				{
					id: 'deliverable',
					name: 'Szállítható'
				},
			]
		}
	},
	computed: {
	},
	methods: {
		getAvailableWarehouses: function(row){
			if (!row.reservedInWarehouses){
				return []
			}
			let payload = []
			let idArray = row.reservedInWarehouses.split(',')
			for (let i in idArray){
				if (this.dataStore.getData('warehouses', true)[idArray[i]] !== undefined){
					payload.push(this.dataStore.getData('warehouses', true)[idArray[i]])
				}
			}

			return payload
		}
	}
}
</script>
