import {trans} from "laravel-vue-i18n";

export function formatDate(date, time) {
	time = time === undefined ? true : time
	if (!date){
		return ''
	}
	return time ? window.moment(date).format('YYYY-MM-DD HH:mm') : window.moment(date).format('YYYY-MM-DD')
}

export function formatNumber(number){
	return number.toLocaleString('hu-HU')
}

export function formatNumberSI(n) {
	const unitList = ['y', 'z', 'a', 'f', 'p', 'n', 'u', 'm', '', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
	const zeroIndex = 8;
	const nn = n.toExponential(2).split(/e/);
	let u = Math.floor(+nn[1] / 3) + zeroIndex;
	if (u > unitList.length - 1) {
		u = unitList.length - 1;
	} else
	if (u < 0) {
		u = 0;
	}
	let number = nn[0] * Math.pow(10, +nn[1] - (u - zeroIndex) * 3);
	number = Math.round(number * 1000) / 1000;

	return formatNumber(number) + (' ') + unitList[u]
}

export function timeout(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
export function formatFileSize(size) {
	if (size > 1024 * 1024 * 1024 * 1024) {
		return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
	} else if (size > 1024 * 1024 * 1024) {
		return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
	} else if (size > 1024 * 1024) {
		return (size / 1024 / 1024).toFixed(2) + ' MB'
	} else if (size > 1024) {
		return (size / 1024).toFixed(2) + ' KB'
	}
	return size.toString() + ' B'
}
export function objectToArray(obj){
	let payload = []
	_.forEach(obj, (details, key) => {
		let detailsClone = _.clone(details)
		detailsClone.id = isNaN(key) ? key : parseInt(key)
		payload.push(detailsClone)
	})

	return payload
}
	export function moveValueInArray(array, valueKey, direction){
	let prev = null
	let prevItem = null
	let nextItem = null
	array.forEach(function(item, index){
		if (index === valueKey){
			prevItem = prev
		} else if (prev === valueKey){
			nextItem = index
		}
		prev = index
	})

	if (direction === 'up' && prevItem !== null){
		let tmp = array[valueKey]
		array[valueKey] = array[prevItem]
		array[prevItem] = tmp
	}
	if (direction === 'down' && nextItem !== null){
		let tmp = array[valueKey]
		array[valueKey] = array[nextItem]
		array[nextItem] = tmp
	}

	return array
}
export function tt(phrase, replacements){
	let value = trans(phrase, replacements)
	if (!value){
		value = phrase
	}
	return value
}

export function isPast(date){
	let dateObj = new Date(date)
	let now = new Date()

	return dateObj < now
}
