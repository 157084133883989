<template>
	<div class="table-row-operations dropdown">
		<VDropdown
			placement="bottom-end"
			instant-move
		>
			<button type="button" class="btn btn-secondary btn-sm">
				<span class="d-md-none"><i class="far fa-ellipsis-h"></i></span>
				<span class="d-none d-md-inline"> Műveletek <i class="far fa-angle-down"></i></span>
			</button>
			<template #popper="{ hide }">
				<div class="popper-dropdown-menu" role="menu">
					<template v-if="displayEditButton">
						<a v-if="editButtonLink" v-close-popper :href="editButtonLink" class="dropdown-item"><i class="fas fa-edit text-primary fa-fw me-1"></i> Szerkesztés</a>
						<a v-else href="#" v-close-popper @click.prevent="editButtonPressed" class="dropdown-item"><i class="fas fa-edit text-primary fa-fw me-1"></i> Szerkesztés</a>
					</template>
					<template v-if="displayViewButton">
						<a v-if="viewButtonLink" v-close-popper :href="viewButtonLink" class="dropdown-item"><i class="fas fa-eye text-primary fa-fw me-1"></i> Részletek</a>
						<a v-else href="#" v-close-popper @click.prevent="viewButtonPressed" class="dropdown-item"><i class="fas fa-eye text-primary fa-fw me-1"></i> Részletek</a>
					</template>

					<slot :row="data"></slot>

					<confirm-button
						v-if="displayDeleteButton"
						:data="data"
						text="Törlés"
						:emit-delete-event="emitDeleteEvent"
						:dropdown="true"
						:url="deleteButtonUrl"
						message="Biztos, hogy törölni akarod?"
						@confirmed="deleteButtonPressed"
					></confirm-button>
				</div>
			</template>
		</VDropdown>
	</div>
</template>

<script>
import ConfirmButton from "./ConfirmButton";
export default {
	components: {ConfirmButton},
	props: {
		data: {
			type: Object
		},
		editUrl: {
			type: String,
			default: ''
		},
		deleteUrl: {
			type: String,
			default: ''
		},
		isEditable: {
			type: [Boolean, Function],
			default: false
		},
		isDeletable: {
			type: [Boolean, Function],
			default: false
		},
		emitEditEvent: {
			type: Boolean
		},
		emitViewEvent: {
			type: Boolean
		},
		emitDeleteEvent: {
			type: Boolean
		}
	},
	data(){
		return {
			open: false
		}
	},
	emits: ['editButtonPressed', 'viewButtonPressed', 'deleteButtonPressed'],
	computed: {
		displayEditButton: {
			get(){
				if (!this.editUrl && !this.emitEditEvent){
					return false
				}
				return !(this.isEditable instanceof Function ? !this.isEditable(this.data) : !this.isEditable);
			}
		},
		editButtonLink: {
			get(){
				return this.editUrl.length ? this.editUrl + '/' + this.data.id : ''
			}
		},
		displayViewButton: {
			get(){
				if (!this.editUrl && !this.emitViewEvent){
					return false
				}
				return this.isEditable instanceof Function ? !this.isEditable(this.data) : !this.isEditable;
			}
		},
		viewButtonLink: {
			get(){
				return this.editUrl.length ? this.editUrl + '/' + this.data.id : ''
			}
		},
		displayDeleteButton: {
			get(){
				if (!this.deleteUrl && !this.emitDeleteEvent){
					return false
				}
				return this.isDeletable instanceof Function ? this.isDeletable(this.data) : this.isDeletable;
			}
		},
		deleteButtonUrl: {
			get(){
				return this.deleteUrl.length ? this.deleteUrl + '/' + this.data.id : ''
			}
		},
	},
	methods: {
		editButtonPressed: function(){
			this.$emit('editButtonPressed', this.data.id)
		},
		viewButtonPressed: function(){
			this.$emit('viewButtonPressed', this.data.id)
		},
		deleteButtonPressed: function(){
			this.$emit('deleteButtonPressed', this.data.id, this.deleteButtonUrl)
		},
	}
}
</script>

<style scoped>
.v-popper__wrapper {
	transition: none !important;
}
</style>
