<template>
	<teleport to="body" v-if="lightboxImage">
		<div class="lightbox" v-if="lightboxImage" @click.prevent="lightboxImage = ''">
			<div class="lightbox-image">
				<img :src="lightboxImage" class="img-responsive"/>
			</div>
			<button class="btn btn-secondary btn-label" @click.prevent="lightboxImage = ''">
				<i class="far fa-times label-icon"></i> Bezár
			</button>
		</div>
	</teleport>
	<div class="d-flex gap-3 align-items-center mb-2" v-if="editable">
		<VueUploadComponent
			class="btn btn-primary"
			post-action="/uploadFile"
			:extensions="allowedExtensions"
			:multiple="multiple"
			:size="1024*1024*64"
			:thread="1"
			:headers="uploadHeaders"
			:drop="true"
			:accept="allowedMimeTypes"
			v-model="uploadingFiles"
			@input-filter="uploadInputFilter"
			@input-file="uploadInputFile"
			:ref="'upload_' + fieldName"
			:input-id="'upload_' + fieldName">
			<i class="fa fa-upload"></i>
			Feltöltés
		</VueUploadComponent>

		<div class="text-muted text-info" v-if="multiple">
			Fileok feltöltéséhez kattints a gombra!
		</div>
		<div class="text-muted text-info" v-else>
			File feltöltéséhez kattints a gombra!
		</div>
	</div>
	<div class="alert alert-light" v-if="!componentValue.length && !uploadingFiles.length && !uploadedFiles.length && !disableNoFileMessage">Nincs
		feltöltve dokumentum.
	</div>
	<template v-if="errors.length">
		<div class="alert alert-danger mb-3" v-for="error in errors">{{ error }}</div>
	</template>
	<template v-if="uploadingFiles.length">
		<template v-for="(file, index) in uploadingFiles">
			<div class="card border shadow-none mb-2 file-card">
				<div class="file-card-content">
					<div class="file-card--thumbnail">
						<img class="img-responsive" v-if="file.thumb" :src="file.thumb"
							 @click="lightboxImage = file.thumb" :alt="file.name"/>
						<div class="file-icon" v-else>
							<FileIcon :filename="file.name"></FileIcon>
						</div>
						<div class="text-center mt-2" v-if="allowRemove && editable">
							<a href="#" class="text-danger" @click.prevent="uploadingFiles.splice(index, 1)"><i
								class="far fa-times"></i> töröl</a>
						</div>
					</div>
					<div class="file-card--details">
						<div class="filename fw-bold">
							{{ file.name }}
						</div>
						<div class="d-flex flex-wrap gap-2">
							<attribute icon="far fa-file">
								<file-size :value="file.size"/>
							</attribute>
							<attribute v-if="file.width" icon="far fa-image"
									   :content="file.width + ' x ' + file.height + ' px'"/>
						</div>
						<div class="progress mt-2" v-if="file.active || file.progress !== '0.00'">
							<div
								:class="{'progress-bar': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}"
								role="progressbar" :style="{width: file.progress + '%'}">{{ file.progress }}%
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</template>
	<template v-if="uploadedFiles.length">
		<template v-for="(file, index) in uploadedFiles" :key="file.id">
			<div class="card border shadow-none mb-2 file-card">
				<div class="file-card-content">
					<div class="file-card--thumbnail">
						<img class="img-responsive" v-if="file.thumb" :src="file.thumb"
							 @click="lightboxImage = file.thumb" :alt="file.name"/>
						<div class="file-icon" v-else>
							<FileIcon :filename="file.name"></FileIcon>
						</div>
					</div>
					<div class="file-card--details">
						<div class="filename fw-bold">
							{{ file.name }}
						</div>
						<div class="d-flex flex-wrap gap-2">
							<attribute icon="far fa-file">
								<file-size :value="file.size"/>
							</attribute>
							<attribute v-if="file.width" icon="far fa-image"
									   :content="file.width + ' x ' + file.height + ' px'"/>
						</div>

						<TextareaAutosize
							v-model="file.description"
							:classes="{'form-control': 1}"
							:rows="1"
							placeholder="Leírás"
							v-if="allowDescription"
						></TextareaAutosize>
					</div>
				</div>
			</div>
		</template>
	</template>
	<template v-if="componentValue.length">
		<template v-for="(file, index) in componentValue" :key="file.id">
			<div class="card border shadow-none mb-2 file-card">
				<div class="file-card-content">
					<div class="file-card--thumbnail">
						<img class="img-responsive" v-if="file.thumbnail" :src="file.thumbnail"
							 @click="lightboxImage = file.download_url" :alt="file.name + '.' + file.extension"/>
						<div class="file-icon" v-else>
							<FileIcon :filename="file.extension"></FileIcon>
						</div>
						<div class="text-center mt-2" v-if="allowRemove && editable">
							<a href="#" class="text-danger" @click.prevent="removeFile(index)"><i
								class="far fa-times"></i> töröl</a>
						</div>
					</div>
					<div class="file-card--details">
						<div class="filename fw-bold">
							<a :href="file.download_url" target="_blank" :title="file.name + '.' + file.extension"><i
								class="far fa-download"></i> {{ file.name }}</a>
						</div>
						<div class="d-flex flex-wrap gap-2">
							<attribute icon="far fa-file">
								<file-size :value="file.size"/>
							</attribute>
							<attribute v-if="file.width" icon="far fa-image"
									   :content="file.width + ' x ' + file.height + ' px'"/>
							<attribute icon="far fa-calendar-alt">
								<date-time :value="file.created_at"/>
							</attribute>
							<attribute icon="far fa-user" :content="file.created_by_user_name"/>
						</div>

						<template v-if="allowDescription">
							<template v-if="!editable">
								<div v-if="file.description" class="d-flex gap-2 align-items-center">
									<i class="far fa-comment fa-fw"></i>
									<div v-html="file.description_html"></div>
								</div>
							</template>
							<TextareaAutosize
								v-model="file.description"
								:classes="{'form-control': 1}"
								:rows="1"
								placeholder="Leírás"
								v-else
							></TextareaAutosize>
						</template>
					</div>
				</div>
			</div>
		</template>
	</template>
	<p class="text-muted text-info mt-2" v-if="info" v-html="info"></p>
</template>

<script>
import TextareaAutosize from '../components/TextareaAutosize.vue'
import {usePage} from "@inertiajs/vue3";
import FileSize from "./FileSize.vue";
import DateTime from "./DateTime.vue";
import FileIcon from "./FileIcon.vue";
import Attribute from "./Attribute.vue";
import {useDropzone} from "vue3-dropzone";
import {useFlashStoreStore} from "../stores/flashStore";

const VueUploadComponent = require('vue-upload-component')
export default {
	components: {
		Attribute,
		FileIcon,
		DateTime,
		FileSize,
		TextareaAutosize, VueUploadComponent
	},
	props: {
		url: String,
		fieldName: String,
		fileType: {
			type: String,
			default: ''
		},
		fileExtensions: {
			type: String,
			default: ''
		},
		fileMimeTypes: {
			type: String,
			default: ''
		},
		info: String,
		compact: {
			type: Boolean,
			default: false
		},
		disableNoFileMessage: {
			type: Boolean,
			default: false
		},
		allowRemove: {
			type: Boolean,
			default: true
		},
		editable: {
			type: Boolean,
			default: true
		},
		multiple: {
			type: Boolean,
			default: true
		},
		existingFiles: {
			type: Array,
			default: function () {
				return []
			}
		},
		callUrlAfterUpload: {
			type: String
		},
		modelValue: {
			type: Array,
			default: function () {
				return []
			}
		},
		allowDescription: Boolean
	},
	data() {
		const page = usePage()

		return {
			uploadedFileIdArray: this.value,
			uploadingFiles: [],
			uploadedFiles: [],
			uploadHeaders: {'X-CSRF-TOKEN': page.props.csrf_token},
			flashStore: useFlashStoreStore(),
			lightboxImage: '',
			dropzone: null,
			errors: [],
		}
	},
	mounted() {
		this.dropzone = useDropzone(this.$refs.dropzone, {
			onDrop: this.onDrop,
			onEnter: this.onEnter
		})
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		allowedExtensions: {
			get() {
				if (this.fileType === 'image') {
					return ['jpg', 'jpeg', 'png']
				}
				if (this.fileType === 'pdf') {
					return ['pdf']
				}
				if (this.fileType === 'xls') {
					return ['xls', 'xlsx']
				}
				if (this.fileExtensions.length) {
					return this.fileExtensions
				}

				return ''
			}
		},
		allowedMimeTypes: {
			get() {
				if (this.fileType === 'image') {
					return 'image/png,image/jpeg'
				}
				if (this.fileType === 'pdf') {
					return 'application/pdf'
				}
				if (this.fileType === 'xls') {
					return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				}
				if (this.fileMimeTypes.length) {
					return this.fileMimeTypes
				}

				return ''
			}
		},
	},
	methods: {
		uploadInputFilter: function (newFile, oldFile, prevent) {
			if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
				newFile.blob = ''
				let URL = (window.URL || window.webkitURL)
				if (URL) {
					newFile.blob = URL.createObjectURL(newFile.file)
				}
				newFile.thumb = ''
				if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
					newFile.thumb = newFile.blob
				}
			}

			/*if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
				newFile.error = 'image parsing'
				let img = new Image();
				img.onload = () => {
					this.$refs['upload_' + this.fieldName].update(newFile, {error: '', height: img.height, width: img.width})
				}
				img.onerror = (e) => {
					this.$refs['upload_' + this.fieldName].update(newFile, { error: 'parsing image size'})
				}
				img.src = newFile.blob
			}*/
			if (newFile) {
				this.errors = []
			}
		},
		uploadInputFile: function (newFile, oldFile) {
			if (newFile !== undefined && newFile.success) {
				if (newFile.response.status === 'error') {
					this.errors.push('Sikertelen feltöltés: ' + newFile.file.name)
					for (let i = 0; i < this.uploadingFiles.length; i++) {
						if (this.uploadingFiles[i].response.status === 'error') {
							this.uploadingFiles.splice(i, 1)
						}
					}
				} else {
					this.$refs['upload_' + this.fieldName].remove(newFile)
					newFile.fileId = newFile.response.file.id
					this.uploadedFiles.push(newFile.response.file)
					let value = []
					if (this.multiple) {
						value = _.clone(this.componentValue)
					}
					value.push(newFile.response.file)
					this.componentValue = value
					if (this.callUrlAfterUpload){
						axios.post(this.callUrlAfterUpload, {
							file: newFile.response.file
						}).then((response) => {
						})
							.catch((error) => {
								this.flashStore.addError(error)
							})
					}
				}
				this.uploadedFiles = []
			} else {
				if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
					if (!this.$refs['upload_' + this.fieldName].active) {
						this.$refs['upload_' + this.fieldName].active = true
					}
				}
			}
		},
		removeFile: function (index) {
			let tmp = this.componentValue
			tmp.splice(index, 1)

			this.$emit('update:modelValue', tmp)
		},
		getFileIconClass: function (extension, f) {
			if (extension.indexOf('.')) {
				let tmp = extension.split('.')
				extension = tmp.pop()
			}
			const icons = {
				image: 'fa-file-image',
				pdf: 'fa-file-pdf',
				word: 'fa-file-word',
				powerpoint: 'fa-file-powerpoint',
				excel: 'fa-file-excel',
				csv: 'fa-file-csv',
				audio: 'fa-file-audio',
				video: 'fa-file-video',
				archive: 'fa-file-archive',
				code: 'fa-file-code',
				text: 'fa-file-alt',
				file: 'fa-file'
			}
			const extensions = {
				gif: icons.image,
				jpeg: icons.image,
				jpg: icons.image,
				png: icons.image,

				pdf: icons.pdf,

				doc: icons.word,
				docx: icons.word,

				ppt: icons.powerpoint,
				pptx: icons.powerpoint,

				xls: icons.excel,
				xlsx: icons.excel,

				csv: icons.csv,

				aac: icons.audio,
				mp3: icons.audio,
				ogg: icons.audio,

				avi: icons.video,
				flv: icons.video,
				mkv: icons.video,
				mp4: icons.video,

				gz: icons.archive,
				zip: icons.archive,

				css: icons.code,
				html: icons.code,
				js: icons.code,

				txt: icons.text
			}

			if (extensions[extension] !== undefined) {
				return 'far ' + extensions[extension]
			}
			return 'far fa-file'
		}
	}
}
</script>

<style scoped>

</style>
