<template>
	<div :class="{'card': 1, 'd-none': !show}">
		<div class="card-body">
			<h4 class="card-title mb-4">Szerződésmódosítások</h4>
			<AdvancedDataTable
				:data-url="dataUrl"
				:columns="columns"
				sort-by="created_at"
				ref="contractAmendmentsTable"
				sort-type="desc"
				name="contractAmendments"
				@no-results-found="show = false"
				:compact="true"
			>

				<template #item-operations="{item, deleteButtonPressed}">
					<div class="operations-wrapper">
						<div class="mobile-cell">
							<div class="stacked-cell">
								<strong>{{ item.public_id }}</strong>
								{{ item.installationId ? 'Telepítés' : item.description }}
								<color-badge
									color="warning"
									icon="far fa-eye-slash"
									content="Rejtett"
									v-if="item.is_hidden"
									small
								/>
								<div class="mobile-horizontal">
									<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }">
										<span :class="'badge badge-xl bg-' + dataStore.getData('contractStatusLabels')[item.status].color">
											<i :class="dataStore.getData('contractStatusLabels')[item.status].icon"></i> {{ dataStore.getData('contractStatusLabels')[item.status].name }}
										</span>
									</data-loader>
								</div>

								<date-time-attribute :value="item.created_at" created>
									<user-badge :user-id="item.created_by_user_id" :key="'US_' + item.created_by_user_id"/>
								</date-time-attribute>
								<div class="mobile-horizontal" v-if="!item.is_hidden">
									<color-badge
										label="Nettó"
										v-if="item.total_price_net"
										>
										<Price
											:value="item.total_price_net"
											:currency-id="item.currency_id"
										/>
									</color-badge>
									<color-badge
										label="Bruttó"
										v-if="item.total_price_gross"
										>
										<Price
											:value="item.total_price_gross"
											:currency-id="item.currency_id"
										/>
									</color-badge>
								</div>
							</div>
						</div>
						<TableActionDropdown
								:data="item"
								:emit-edit-event="true"
								:emit-view-event="true"
								:emit-delete-event="true"
								:is-editable="item.status === 'draft' && item.editable && editable"
								:is-deletable="item.deletable && editable"
								@edit-button-pressed="openContract"
								@view-button-pressed="openContract"
								@delete-button-pressed="deleteContract"
								v-slot="{ row }"
						>
							<a class="dropdown-item" href="#" v-close-popper @click.prevent="generateContract(row.id)" v-if="editable && row.id && row.status === 'draft' && projectDataStore.project.editable">
								<i class="fas fa-file-contract fa-fw label-icon text-warning"></i> Lezárás
							</a>
							<a href="#" class="dropdown-item" v-close-popper @click.prevent="openResponseForm(row.id)" v-if="editable && !row.is_hidden && row.status === 'generated' && projectDataStore.project.editable">
								<i class="far fa-reply fa-fw me-1 text-warning"></i> Ügyfél válasz rögzítése
							</a>
							<a class="dropdown-item" v-close-popper :href="'/projects/' + row.project_id + '/downloadContract/' + row.id"  v-if="row.file_id && !row.is_hidden">
								<i class="far fa-download fa-fw me-1 text-secondary"></i> Letöltés
							</a>
						</TableActionDropdown>
					</div>
				</template>

				<template #item-public_id="item">
					<div class="stacked-cell">
						<strong>{{ item.public_id }}</strong>
						{{ item.installationId ? 'Telepítés' : item.description }}
						<color-badge
							color="warning"
							icon="far fa-eye-slash"
							content="Rejtett"
							v-if="item.is_hidden"
							small
							/>
					</div>
				</template>

				<template #item-total_price_net="item">
					<Price
						:value="item.total_price_net"
						:currency-id="item.currency_id"
						v-if="item.total_price_net && !item.is_hidden"
					/>
				</template>

				<template #item-total_price_gross="item">
					<Price
						:value="item.total_price_gross"
						:currency-id="item.currency_id"
						v-if="item.total_price_gross && !item.is_hidden"
					/>
				</template>

				<template #item-created_at="item">
					<date-time :value="item.created_at"/>
				</template>

				<template #item-status="item">
					<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }" v-if="item.status === 'draft' || !item.is_hidden">
						<span :class="'badge badge-xl bg-' + dataStore.getData('contractStatusLabels')[item.status].color">
							<i :class="dataStore.getData('contractStatusLabels')[item.status].icon"></i> {{ dataStore.getData('contractStatusLabels')[item.status].name }}
						</span>
					</data-loader>
				</template>
			</AdvancedDataTable>
		</div>
	</div>

	<teleport to="body" v-if="formOpen">
		<ContractAmendmentForm
				:original-contract-id="contract.id"
				:contract-id="formId"
				:is-hidden="formIsHidden"
				:editable="editable"
				:can-start-editing="canStartEditing"
				@generate="generateContract"
				@close="closeForm"
				@close-and-reload="closeFormAndUpdateList"
				@start-editing="$emit('startEditing')"
		></ContractAmendmentForm>
	</teleport>
	<teleport to="body" v-if="responseFormId">
		<ContractResponseForm
				:contract-id="responseFormId"
				@close="responseFormId = null"
				@close-and-reload="closeContractResponseFormAndUpdateList"
		></ContractResponseForm>
	</teleport>
</template>

<script>
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import Price from "../../components/Price.vue";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import ContractAmendmentForm from "./ContractAmendmentForm.vue";
import ContractResponseForm from "./ContractResponseForm.vue";
import ProjectInstallationList from "./ProjectInstallationList.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import {useProjectDataStore} from "../../stores/projectData";
import DateTime from "../../components/DateTime.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import Number from "../../components/Number.vue";
import Attribute from "../../components/Attribute.vue";
import DateTimeAttribute from "../../components/attributes/DateTimeAttribute.vue";
import UserBadge from "../../components/badges/UserBadge.vue";
import ContractIdAttribute from "../../components/attributes/ContractIdAttribute.vue";

export default {
	components: {
		ContractIdAttribute,
		UserBadge,
		DateTimeAttribute,
		Attribute,
		Number,
		ColorBadge,
		DateTime,
		DataLoader,
		ProjectInstallationList, ContractResponseForm, ContractAmendmentForm, Price, TableActionDropdown, AdvancedDataTable},
	emits: ['changed', 'startEditing'],
	props: {
		dataUrl: String,
		contract: Object,
		editable: Boolean,
		canStartEditing: Boolean,
	},
	mounted() {
		if (this.projectDataStore.activeTabCommand === 'amendment' && this.projectDataStore.activeTabParameter){
			let tmp = this.projectDataStore.activeTabParameter.split('|')
			this.openContract(tmp[1])
		}
	},
	data(){
		return {
			formOpen: false,
			formId: null,
			formIsHidden: false,
			show: true,
			projectDataStore: useProjectDataStore(),
			responseFormId: null,
			flashStore: useFlashStoreStore(),
			columns: [
				{
					text: 'Azonosító',
					value: 'public_id',
					sortable: true,
				},
				{
					text: 'Állapot',
					value: 'status',
					sortable: false,
				},
				{
					text: 'Termékek',
					value: 'productsSum',
					align: 'right',
					sortable: true,
					width: 10
				},
				{
					text: 'Nettó',
					value: 'total_price_net',
					align: 'right',
					sortable: true,
					width: 10
				},
				{
					text: 'Bruttó',
					value: 'total_price_gross',
					align: 'right',
					sortable: true,
					width: 10
				},
				{
					text: 'Létrehozva',
					value: 'created_at',
					sortable: true,
					width: 10
				},
				{
					label: '',
					value: 'operations',
					width: 5,
				}
			]
		}
	},
	methods: {
		closeFormAndUpdateList: function(){
			this.closeForm()
			this.show = true
			this.$refs.contractAmendmentsTable.getData()
		},
		closeForm: function(){
			this.formOpen = false
			this.formId = null
		},
		openContract: function(contractId, isHidden){
			isHidden = isHidden || false
			this.formId = contractId !== null ? contractId : null
			this.formOpen = true
			this.formIsHidden = !!isHidden
		},
		openResponseForm: function (id){
			this.responseFormId = id
		},
		deleteContract: function(contractId){
			axios.delete('/projects/deleteContract/' + contractId).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				if (response.data.status === 'success'){
					this.$refs.contractAmendmentsTable.getData()
					this.$emit('changed');
				}
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		generateContract: function(contractId){
			axios.put('/projects/generateContract/' + contractId).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				if (response.data.status === 'success'){
					this.closeForm()
					if (response.data.contract.is_hidden) {
						this.$emit('changed');
					}
					this.$refs.contractAmendmentsTable.getData()
				}
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		closeContractResponseFormAndUpdateList: function(){
			this.responseFormId = null
			this.$emit('changed');
			this.$refs.contractAmendmentsTable.getData()
		},
	}
}
</script>

<style scoped>

</style>
