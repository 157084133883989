<template>
	<TitleBar title="Partnerek" />

	<AdvancedDataTable
		:data-url="route('partners.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="partners"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('PartnersController_create')">
			<Link :href="route('partners.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<div class="mobile-horizontal">
							<email-address :value="item.email" :key="'partnerEmail_' + item.id"></email-address>
							<phone-number v-if="item.phone" :value="item.phone" :key="'partnerPhoneNumber_' + item.id"></phone-number>
						</div>
						<attribute v-if="item.url" icon="far fa-globe">
							{{ item.url }}
						</attribute>
					</div>

				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('partners.update')"
					:delete-url="route('partners.delete')"
					:emit-delete-event="true"
					@delete-button-pressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
		<template #item-email="item">
			<email-address v-if="item.email" :value="item.email" :key="'partnerEmail_' + item.id"></email-address>
		</template>
		<template #item-phone="item">
			<phone-number v-if="item.phone" :value="item.phone" :key="'partnerPhoneNumber_' + item.id"></phone-number>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import {Link} from "@inertiajs/vue3";
import TableActionCell from "../components/TableActionCell.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import {useApplicationStore} from "../stores/applicationStore";
import EmailAddress from "../components/EmailAddress.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import ColorBadge from "../components/ColorBadge.vue";
import ProjectTypeCell from "../modules/projects/ProjectTypeCell.vue";
import DateTime from "../components/DateTime.vue";
import Attribute from "../components/Attribute.vue";

export default {
	components: {
		Attribute,
		DateTime,
		ProjectTypeCell,
		ColorBadge,
		PhoneNumber, EmailAddress, AdvancedDataTable, TableActionCell, TitleBar, Link},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'E-mail',
				value: 'email',
				sortable: true
			},
			{
				text: 'Telefonszám',
				value: 'phone',
				sortable: true
			},
			{
				text: 'Weboldal',
				value: 'url',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
