<template>
	<div :class="{'mb-3': !fieldOnly && !noMargin}">
		<slot name="beforeLabel"></slot>
		<label :class="{required: isRequired}" v-if="!fieldOnly">{{ label }}</label>
		<div v-if="disabledValueOnly && disabled">
			<div class="d-flex gap-2">
				<span v-if="prefixValue">{{ prefixValue }}</span>
				<span v-if="prefixIcon"><i :class="prefixIcon"></i></span>
				<span>{{ componentValue }}</span>
				<span v-if="suffixIcon"><i :class="suffixIcon"></i></span>
				<span v-if="suffix">{{ suffix }}</span>
			</div>
		</div>
		<template v-else>
			<div v-if="spinnerButtons && !disabled">
				<div class="input-group">
					<button class="btn btn-secondary" type="button" @click.prevent="decrease"><i class="far fa-minus"></i></button>
					<Field
						v-model="componentValue"
						:key="inputKeyComputed"
						:name="name"
						:type="componentType"
						:readonly="disabled"
						:disabled="disabled"
						@keydown="onKeypress($event)"
						@input="onInput"
						@blur="onBlur"
						:step="stepValue"
						:min="min"
						:max="max"
						:rules="rulesValue"
						:style="inputStyle"
						:id="id"
						ref="inputField"
						:placeholder="showPlaceholder ? label : ''"
						:class="inputClass"
						:autocomplete="autocomplete"
						:tabindex="tabindex > -1 ? tabindex : null"
					/>
					<button class="btn btn-secondary" type="button" @click.prevent="increase"><i class="far fa-plus"></i></button>
					<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
				</div>
			</div>
			<div class="input-group" v-else-if="inputGroup || prefixValue || suffix || prefixIcon || suffixIcon">
				<slot name="buttonBefore"></slot>
				<span class="input-group-text" v-if="prefixValue">{{ prefixValue }}</span>
				<span class="input-group-text" v-if="prefixIcon"><i :class="prefixIcon"></i></span>
				<Field
					v-model="componentValue"
					:key="inputKeyComputed"
					:name="name"
					:type="componentType"
					:readonly="disabled"
					:disabled="disabled"
					@keydown="onKeypress($event)"
					@input="onInput"
					@blur="onBlur"
					:step="stepValue"
					ref="inputField"
					:min="min"
					:max="max"
					:rules="rulesValue"
					:style="inputStyle"
					:id="id"
					:placeholder="showPlaceholder ? label : ''"
					:class="inputClass"
					:tabindex="tabindex > -1 ? tabindex : null"
					:autocomplete="autocomplete"
				/>
				<span class="input-group-text" v-if="suffixIcon"><i :class="suffixIcon"></i></span>
				<span class="input-group-text" v-if="suffix">{{ suffix }}</span>
				<slot name="buttonAfter"></slot>
				<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
			</div>
			<template v-else>
				<Field
					v-model="componentValue"
					:key="inputKeyComputed"
					:name="name"
					:type="componentType"
					:readonly="disabled"
					:disabled="disabled"
					@keydown="onKeypress($event)"
					@blur="onBlur"
					@input="onInput"
					:step="stepValue"
					:min="min"
					:max="max"
					:rules="rulesValue"
					ref="inputField"
					:style="inputStyle"
					:id="id"
					:autocomplete="autocomplete"
					:placeholder="showPlaceholder ? label : ''"
					:class="inputClass"
					:tabindex="tabindex > -1 ? tabindex : null"
				/>
			</template>
			<div class="invalid-feedback" v-if="errors[name] && !prefixValue && !suffix && !spinnerButtons">{{ errors[name] }}</div>
			<p class="text-muted text-info mt-2" v-if="info" v-html="info"></p>

			<template v-if="multilang">
				<data-loader :data="['languages']" v-slot="{ dataStore }">
					<div v-if="hasTranslatableLanguage(dataStore.getData('languages'))" class="bg-light mt-1 p-2 d-flex gap-2 flex-column" style="border-radius: 0.25rem">
						<template v-for="language in dataStore.getData('languages')" :key="inputKeyComputed + '_' + language.code + '_wrapper'">
							<div v-if="language.active && !language.is_default">
								<div class="input-group">
									<span class="input-group-text">{{ language.name }}</span>
									<input
										type="text"
										class="form-control"
										v-model="componentTranslations[(translationFieldName ? translationFieldName : name) + '_' + language.code]"
										:readonly="disabled"
										:disabled="disabled"
										:key="inputKey + '_' + language.code"
									/>
								</div>
							</div>
						</template>
					</div>
				</data-loader>
			</template>
		</template>
	</div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import DataLoader from "../DataLoader.vue";
export default {
	components: {DataLoader, Field, ErrorMessage},
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: String,
		name: {
			type: String,
			default: ''
		},
		tabindex: {
			type: Number,
			default: -1
		},
		modelValue: [String, Number],
		translations: {
			type: Object,
			default: function(){
				return {}
			}
		},
		errors: {
			type: Object,
			default: function(){
				return {}
			}
		},
		inputGroup: {
			type: Boolean,
			default: false
		},
		prefix: {
			type: String,
			default: ''
		},
		suffix: {
			type: String,
			default: ''
		},
		prefixIcon: {
			type: String,
			default: ''
		},
		suffixIcon: {
			type: String,
			default: ''
		},
		dataType: {
			type: String,
			default: 'string'
		},
		inputType: {
			type: String,
			default: 'auto'
		},
		step: {
			type: Number,
			default: null
		},
		decimals: {
			type: Number,
			default: null
		},
		min: {
			type: Number,
			default: null
		},
		max: {
			type: Number,
			default: null
		},
		info: {
			type: String,
			default: ''
		},
		id: {
			type: String,
			default: ''
		},
		fixedWidth: {
			type: Number,
			default: null
		},
		align: {
			type: String,
			default: 'left'
		},
		translationFieldName: {
			type: String,
			default: ''
		},
		zeroAllowed: {
			type: Boolean,
			default: true
		},
        multilang: {
			type: Boolean,
			default: false
		},
		disabledValueOnly: Boolean,
		fieldOnly: Boolean,
		spinnerButtons: Boolean,
		noMargin: Boolean,
		equalTo: {
			type: String,
			default: ''
		},
		showPlaceholder: Boolean,
		autocomplete: String
	},
	data() {
		return {
			inputKey: 0,
			rulesValue: null,
			blurTimer: null
		}
	},
	mounted() {
		this.updateRules()

		if (this.dataType === 'number' && this.$refs.inputField !== undefined){
			this.$refs.inputField.$el.addEventListener('focus', (e) => {
				this.$refs.inputField.$el.addEventListener('wheel.disableScroll', this.scrollWheelEvent)
			})
			this.$refs.inputField.$el.addEventListener('blur', (e) => {
				this.$refs.inputField.$el.removeEventListener('wheel.disableScroll', this.scrollWheelEvent)
			})
		}
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		componentTranslations: {
			get() {
				return this.translations
			},
			set(value) {
				this.$emit('update:translations', value)
			}
		},
		componentType: {
			get() {
				if (this.dataType === 'color'){
					return 'color'
				}
				if (this.dataType === 'password'){
					return 'password'
				}
				return this.dataType === 'number' && this.inputType !== 'text' && !this.disabled ? 'number' : 'text'
			}
		},
		stepValue: {
			get() {
				if (this.dataType !== 'number'){
					return null
				}
				if (this.decimals !== undefined){
					return Math.pow(10, -1 * this.decimals)
				}
				return this.step ? this.step : 1
			}
		},
		isRequired: {
			get(){
				return this.rules.indexOf('required') > -1
			}
		},
		prefixValue: {
			get() {
				if (this.dataType === 'phone'){
					return '+'
				}
				return this.prefix
			}
		},
		inputStyle: {
			get() {
				let payload = {}

				if (this.fixedWidth){
					payload.width = this.fixedWidth + 'px'
					payload.paddingLeft = '4px'
					payload.paddingRight = '4px'
				}

				return payload
			}
		},
		inputClass: {
			get() {
				let payload = ['form-control']

				if (this.errors[this.name] !== undefined){
					payload.push('is-invalid')
				}
				if (this.dataType === 'color'){
					payload.push('form-control-color')
				}
				if (this.align === 'center'){
					payload.push('text-center')
				}
				if (this.align === 'right'){
					payload.push('text-end')
				}

				return payload
			}
		},
		inputKeyComputed: {
			get(){
				return this.id ? this.id + this.inputKey : this.inputKey
			}
		},
	},
	methods: {
		scrollWheelEvent: function(e){
			e.preventDefault()
		},
		hasTranslatableLanguage: function (languages){
			for (let i in languages){
				if (!languages[i].is_default && languages[i].active){
					return true
				}
			}

			return false
		},
		onKeypress: function(e){
			if (this.dataType === 'number' || this.dataType === 'phone'){
				let keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete', 'Home', 'End', 'Tab'];
				if (this.stepValue !== null && this.stepValue < 1 && (this.componentValue === null || String(this.componentValue).indexOf('.') < 0)){
					keysAllowed.push('.')
					keysAllowed.push(',')
				}
				if (this.min === null || this.min < 0){
					keysAllowed.push('-')
				}
				const keyPressed = e.key;

				if (!keysAllowed.includes(keyPressed)) {
					e.preventDefault()
				}
			}
			if (this.dataType === 'number'){
				if (this.blurTimer !== null){
					clearTimeout(this.blurTimer)
				}
				this.blurTimer = setTimeout(() => {
					this.$refs.inputField.$el.blur()
				}, 3000)
			}
		},
		onInput: function(){
			this.$emit('input')
		},
		onBlur: function (){
			if (this.dataType === 'number') {
				if (this.componentValue === null || !String(this.componentValue).length) {
					this.componentValue = null
				} else {
					this.componentValue = parseFloat(String(this.componentValue).replace(',', '.'))
					if (this.decimals !== undefined) {
						let multiplier = Math.pow(10, this.decimals)
						this.componentValue = Math.round(parseFloat(this.componentValue) * multiplier) / multiplier
					}
					if (this.min){
						this.componentValue = Math.max(this.min, this.componentValue)
					}
					if (this.max){
						this.componentValue = Math.min(this.max, this.componentValue)
					}
				}
				this.inputKey++
			}
		},
		decrease: function(){
			if (this.componentValue === null){
				return
			}
			let value = parseFloat(this.componentValue)
			value -= this.stepValue
			if (value === 0 && !this.zeroAllowed){
				value -= this.stepValue
			}
			if (this.min){
				value = Math.max(this.min, value)
			}

			this.componentValue = value
		},
		increase: function(){
			if (this.componentValue === null){
				return
			}
			let value = parseFloat(this.componentValue)
			value += this.stepValue
			if (value === 0 && !this.zeroAllowed){
				value += this.stepValue
			}
			if (this.max){
				value = Math.min(this.max, value)
			}

			this.componentValue = value
		},
		updateRules: function(){
			let tmp = this.rules.split('|')
			let rules
			if (this.dataType === 'number' || this.dataType === 'phone'){
				rules = yup.number().transform((val, orig) => orig === "" ? undefined : val)
				if (this.min > 0){
					rules = rules.test('minValue', (field) => {
							return this.$t(':field értéke minimum :min lehet', {field: this.label, min: this.min})
						}, (value, context) => {
						if ((value === null || value === undefined) && tmp.indexOf('required') < 0){
							return true
						}
						return value >= this.min
					})
				}
				if (this.max > 0){
					rules = rules.test('minValue', (field) => {
						return this.$t(':field értéke maximum :max lehet', {field: this.label, max: this.max})
					}, (value, context) => {
						if ((value === null || value === undefined) && tmp.indexOf('required') < 0){
							return true
						}
						return value <= this.max
					})
				}
			} else {
				rules = yup.string()
			}
			if (this.dataType === 'email'){
				rules = rules.email(() => {
					return this.$t(':field érvényes e-mail címnek kell lennie', {field: this.label})
				})
			}
			if (tmp.indexOf('required') > -1){
				rules = rules.required(() => {
					return this.$t(':field kitöltése kötelező', {field: this.label})
				})
			} else {
				rules = rules.notRequired()
			}
			if (this.equalTo !== ''){
				rules = rules.oneOf([this.equalTo], this.$t('A jelszavaknak meg kell egyeznie'))
			}
			_.forEach(tmp, (rule) => {
				if (rule.indexOf('min:') > -1){
					rule = rule.replace('min:', '')
					rules = rules.min(parseInt(rule), (field) => {
						return this.$t(':field minimum :min karakter hosszú legyen', {field: this.label, min: field.min})
					})
				}
			})

			this.rulesValue = rules.label(this.label)
		}
	}
}
</script>

<style scoped>

</style>
