<script setup>
import {ref} from "vue";
import {useApplicationStore} from "../../stores/applicationStore";
import {useGeneralDataStore} from "../../stores/generalData";
import ColorBadge from "../ColorBadge.vue";
import Popper from "vue3-popper";
import {Link} from "@inertiajs/vue3";
import DataLoader from "../DataLoader.vue";
import {computed} from "@vue/compat";
import Attribute from "../Attribute.vue";
import Number from "../Number.vue";
import Price from "../Price.vue";
import EmailAddress from "../EmailAddress.vue";
import PhoneNumber from "../PhoneNumber.vue";
import WarehouseBadge from "./WarehouseBadge.vue";
import ShipmentSourceCell from "../../modules/inventory/ShipmentSourceCell.vue";
import ShipmentDestinationCell from "../../modules/inventory/ShipmentDestinationCell.vue";
import DateTime from "../DateTime.vue";
import UserAttribute from "../attributes/UserAttribute.vue";
import ProductBadge from "./ProductBadge.vue";

const props = defineProps({
	shipmentId: Number,
	publicId: String,
	disableLink: Boolean,
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
})

const loaded = ref(false)
const app = useApplicationStore()
const open = ref(false)
const error = ref(false)
const dataStore = useGeneralDataStore()
const details = ref({})

function load(){
	if (!loaded.value) {
		let loadedData = dataStore.getTooltipData('inventoryShipment', props.productId)
		if (loadedData !== false && loadedData){
			details.value = loadedData
			loaded.value = true
		} else {
			axios.get('/tooltips/inventoryShipment/' + props.shipmentId, {}).then((response) => {
				details.value = response.data.details
				dataStore.addTooltip('inventoryShipment', props.productId, details.value)
				loaded.value = true
			}).catch((e) => {
				error.value = true
			})
		}
	}
}

function onOpen(){
	open.value = true
	load()
}

</script>

<template>
	<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }" v-if="shipmentId" hide-loader>
		<template v-if="!disableLink">
			<VDropdown
				@show="onOpen">
				<div class="vertical">
					<a :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" href="#">
						<i class="far fa-truck"></i> <span>{{ publicId }}</span> <i class="far fa-info-circle font-size-10"></i>
					</a>
				</div>
				<template #popper>
					<div :class="{'overview-tooltip': 1}">
						<div class="overview-tooltip-head">
							<template v-if="loaded">
								<div class="d-flex">
									<div class="flex-grow-1">
										<color-badge
											color="secondary"
											icon="far fa-truck"
											small
											content="Szállítmány"
										/>
										<h5 class="font-size-15 mt-1 mb-1">{{ details.public_id }}</h5>
										<color-badge :value="dataStore.getData('inventoryShipmentTypes')[details.type]" small></color-badge>
									</div>
									<div class="d-flex flex-column gap-2">
										<Link :href="'/inventory/shipment/' + shipmentId" class="btn btn-soft-secondary btn-sm" title="Adatlap" v-if="app.hasPermission('InventoryController_view')"><i class="far fa-external-link"></i></Link>
									</div>
								</div>
							</template>
							<template v-else>
								<div class="placeholder-glow mb-1">
									<span class="placeholder font-size-15" :style="'width:32%'"></span>
								</div>
								<div class="placeholder-glow mb-1">
									<span class="placeholder font-size-14" :style="'width:51%'"></span>
								</div>
							</template>
						</div>
						<div class="overview-tooltip-body">
							<template v-if="loaded">
								<div class="details-box">
									<attribute
										icon="far fa-arrow-from-left"
										label="Forrás"
									>
										<shipment-source-cell :item="details" small />
									</attribute>
									<attribute
										icon="far fa-arrow-to-right"
										label="Cél"
									>
										<shipment-destination-cell :item="details" small />
									</attribute>
									<attribute
										icon="far fa-lock"
										label="Lezárva"
										v-if="details.closed_at"
									>
										<date-time :value="details.closed_at"/>
									</attribute>
									<user-attribute :user-id="details.created_by_user_id" creator />
								</div>
							</template>
							<template v-else>
								<div class="p-2">
									<div class="placeholder-glow mb-2">
										<span class="placeholder font-size-16" :style="'width:51%'"></span>
									</div>
									<div class="placeholder-glow mb-2">
										<span class="placeholder font-size-16" :style="'width:32%'"></span>
									</div>
								</div>
							</template>
						</div>
						<template v-if="details.products !== undefined">
							<div class="overview-tooltip-subtitle">
								<h5 class="font-size-15 m-0">Termékek</h5>
							</div>
							<div class="overview-tooltip-body">
								<div class="details-box gap-0">
									<div v-for="(product, productId) in details.products" :key="'shipmentProduct_' + details.id + '_' + productId" class="details-box-list-item">
										<color-badge
											:color="product.quantity < 0 ? 'danger' : 'success'"
											small
											>
											<number :value="product.quantity" />
										</color-badge>
										<product-badge :product-id="parseInt(productId)" :product-manufacturer-id="product.product_manufacturer_id" :product-name="product.name" :key="'PB' + productId" />
									</div>
								</div>
							</div>
						</template>
					</div>
				</template>
			</VDropdown>
		</template>
		<color-badge
			v-else
			color="light"
			icon="far fa-truck"
			:small="small"
		>
			{{ publicId }}
		</color-badge>
	</data-loader>
</template>

<style scoped>

</style>
