<template>
	<TitleBar title="Beállítások" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('settings.index')"
		:back-url="route('settings.index')"
		:show-back-button="false"
		v-model="config"
		v-slot="{ errors }"
	>
		<div class="row">
			<template v-for="box in availableAttributes">
				<div class="col-md-6">
					<div class="card">
						<div class="card-body">
							<h4 class="card-title mb-4">{{ box.title }}</h4>
							<template v-for="(attrDetails, attrKey) in box.attributes">
								<template v-if="attrDetails.type === 'text'">
									<input-field
										:label="attrDetails.name"
										:name="attrKey"
										:info="attrDetails.info !== undefined ? attrDetails.info : ''"
										:prefix="attrDetails.prefix !== undefined ? attrDetails.prefix : ''"
										:suffix="attrDetails.suffix !== undefined ? attrDetails.suffix : ''"
										v-model="config[attrKey].value"
									></input-field>
								</template>
								<template v-if="attrDetails.type === 'number'">
									<input-field
										:label="attrDetails.name"
										:name="attrKey"
										data-type="number"
										:decimals="attrDetails.decimals !== undefined ? attrDetails.decimals : 0"
										:info="attrDetails.info !== undefined ? attrDetails.info : ''"
										:prefix="attrDetails.prefix !== undefined ? attrDetails.prefix : ''"
										:suffix="attrDetails.suffix !== undefined ? attrDetails.suffix : ''"
										v-model="config[attrKey].value"
									></input-field>
								</template>
								<template v-if="attrDetails.type === 'color'">
									<input-field
										:label="attrDetails.name"
										:name="attrKey"
										data-type="color"
										:info="attrDetails.info !== undefined ? attrDetails.info : ''"
										v-model="config[attrKey].value"
									></input-field>
								</template>
								<template v-if="attrDetails.type === 'boolean'">
									<switch-field
										:label="attrDetails.name"
										:name="attrKey"
										:info="attrDetails.info !== undefined ? attrDetails.info : ''"
										v-model="config[attrKey].value"
									></switch-field>
								</template>
								<template v-if="attrDetails.type === 'file'">
									<FileUploader
										:field-name="attrKey"
										v-model="config[attrKey].file"
										:editable="true"
										:multiple="false"
									></FileUploader>
								</template>
								<template v-if="attrDetails.type === 'image'">
									<label>{{ attrDetails.name }}</label>
									<FileUploader
										:field-name="attrKey"
										v-model="config[attrKey].file"
										:editable="true"
										:multiple="false"
										file-type="image"
									></FileUploader>
								</template>
							</template>
						</div>
					</div>
				</div>
			</template>
		</div>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import FileUploader from "../components/FileUploader.vue";
import SwitchField from "../components/form/SwitchField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";

export default {
	components: {InputField, FormWrapper, SwitchField, FileUploader, TitleBar},
	layout: AppLayout,
	props: {
		initialConfig: Object,
		availableAttributes: Object
	},
	data(){
		let config = {}
		_.forEach(this.initialConfig, (value, key) => {
			value = _.clone(value)
			if (this.getFieldType(key) === 'file' || this.getFieldType(key) === 'image'){
				if (value.file !== undefined && value.file !== null){
					value.file = [_.clone(value.file)]
				}
			}
			config[key] = value
		})
		config.editable = true

		return {
			config: config,
			loading: false
		}
	},
	mounted() {
	},
	methods: {
		getFieldType: function (fieldKey){
			let fieldDetails = null
			_.forEach(this.availableAttributes, function (box){
				_.forEach(box.attributes, function (field, key){
					if (fieldKey === key){
						fieldDetails = field
					}
				})
			})
			if (fieldDetails.type !== undefined){
				return fieldDetails.type
			}

			return ''
		}
	}
}
</script>
