<template>
	<template v-if="listOnly">
		<template v-for="user in teamStats">
			<tr :class="rowClass">
				<td>
					<div class="d-flex gap-2 align-items-center">
						<div v-if="level" :style="'width: ' + (level * 20) + 'px;'">&nbsp;</div>
						<i class="far fa-level-up fa-rotate-90" v-if="level"></i>
						<button type="button" class="btn btn-secondary btn-sm" @click="$emit('toggle', user.userId)" v-if="user.team.length">
							<i :class="{'far': 1, 'fa-angle-down': globalVisibleSubtables.indexOf(user.userId) < 0, 'fa-angle-up': globalVisibleSubtables.indexOf(user.userId) > -1}"></i>
						</button>
						<div>{{ user.userName }}</div>
					</div>
				</td>
				<td>
					<div class="d-flex gap-2 align-items-center">
						<UserLevelBadge
							:user-level-id="user.userLevelId"
							:small="true"
							:short="true"
						/>
						<i class="fas fa-arrow-right" v-if="user.targetLevelId !== null"></i>
						<UserLevelBadge
							v-if="user.targetLevelId !== null"
							:user-level-id="user.targetLevelId"
							:small="true"
							:short="true"
						/>

					</div>
				</td>
				<td class="text-end">{{ user.direct }}</td>
				<td class="text-end">{{ user.indirect }}</td>
				<td class="text-end">{{ user.sum }} ({{ user.usable }})</td>
				<td class="text-end">{{ user.team.length }}</td>
			</tr>
			<TeamTable
				:team-stats="user.team"
				:level="level + 1"
				:list-only="true"
				:global-visible-subtables="globalVisibleSubtables"
				:row-class="rowClass === 'table-secondary' ? '' : 'table-secondary'"
				v-if="globalVisibleSubtables.indexOf(user.userId) > -1"
				@toggle="handleToggleEvent"
			></TeamTable>
		</template>
	</template>

	<table class="table" v-else>
		<thead>
		<tr>
			<th>Név</th>
			<th>Szint</th>
			<th class="text-end">SE</th>
			<th class="text-end">CSE</th>
			<th class="text-end">Összes</th>
			<th class="text-end">Csoport méret</th>
		</tr>
		</thead>
		<tbody>
			<TeamTable
				:team-stats="teamStats"
				:list-only="true"
				:level="level"
				:global-visible-subtables="visibleSubtables"
				@toggle="toggleVisibility"
				></TeamTable>
		</tbody>
	</table>
</template>

<script>
import UserLevelBadge from "../../components/UserLevelBadge.vue";

export default {
	components: {UserLevelBadge},
	emits: ['toggle'],
	props: {
		teamStats: Array,
		listOnly: Boolean,
		rowClass: {
			type: String,
			default: ''
		},
		level: {
			type: Number,
			default: 0
		},
		globalVisibleSubtables: Array
	},
	data(){
		return {
			visibleSubtables: []
		}
	},
	mounted() {
	},
	methods: {
		toggleVisibility: function (userId){
			let index = this.visibleSubtables.indexOf(userId)
			if (index > -1){
				this.visibleSubtables.splice(index, 1)
			} else {
				this.visibleSubtables.push(userId)
			}
		},
		handleToggleEvent: function (userId){
			this.$emit('toggle', userId)
		}
	}
}
</script>

<style scoped>

</style>
