<template>
	<TitleBar :title="'Készletfoglalás / ' + contract.project.public_id" />

	<div class="d-flex flex-wrap gap-2 mb-3">
		<div class="d-flex p-2 gap-2">
			<Link :href="route('inventoryReservations.index')" class="btn btn-secondary btn-label"><i class="far fa-angle-left label-icon"></i> Vissza</Link>
			<data-loader :data="['warehouses']" v-slot="{ dataStore }">
				<div class="dropdown" v-if="hasReservedItems && app.hasPermission('InventoryController_createDelivery')">
					<button type="button" class="btn btn-secondary btn-label waves-effect" data-bs-toggle="dropdown" aria-expanded="false">
						<i class="far fa-truck label-icon"></i> Szállítmány létrehozása
					</button>
					<div class="dropdown-menu" role="menu">
						<div class="d-flex gap-2 flex-column">
							<template v-for="warehouseId in warehousesWithReservation" :key="'createDeliveryButton_' + warehouseId">
								<Link :href="route('inventory.create.delivery')  + '/' + contract.id + '/' + warehouseId" class="dropdown-item">{{ dataStore.getData('warehouses', true)[warehouseId].name }}</Link>
							</template>
						</div>
					</div>
				</div>
			</data-loader>
		</div>
	</div>

	<div class="card">
		<div class="card-body">
			<div class="details-box">
				<address-attribute :address="projectDetails.deploy_address"/>

				<project-id-attribute :project-id="projectDetails.id" :project-type-id="projectDetails.project_type_id" :public-id="projectDetails.public_id" />

				<contract-id-attribute
					:project-id="projectDetails.id"
					:project-type-id="projectDetails.project_type_id"
					:public-id="contractDetails.public_id"
					:contract-id="contractDetails.id"
					:client-name="contractDetails.clientName"
				/>
			</div>
		</div>
	</div>

	<AdvancedDataTable
		:data-url="route('inventoryReservations.contract.list', contract.id)"
		:columns="columns"
		sort-by="order"
		sort-type="asc"
		name="contractDeliveries"
		ref="table"
		:default-filters="defaultFilters"
		@data-loaded="tableDataLoaded"
	>

		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<product-category-select-field
					v-model="serverOptions.product_category_id"
					name="product_category_id"
					label="Kategória"
					:field-only="true"
				/>
			</div>
			<div class="col-12 col-md-2">
				<product-manufacturer-select-field
					v-model="serverOptions.product_manufacturer_id"
					name="product_manufacturer_id"
					label="Gyártó"
					:field-only="true"
				/>
			</div>
			<div class="col-md-12 d-flex gap-2 align-items-center mt-2">
				<div class="d-flex flex-wrap gap-2 p-2 align-items-center rounded bg-light">
					<button type="button" class="btn btn-primary btn-label" :disabled="!selectedRowsReserve.length" @click="reserveSelected()"><i class="far fa-plus label-icon"></i> Kijelöltek lefoglalása</button>
					<button type="button" class="btn btn-primary btn-label" :disabled="!hasReservableItems" @click="reserveAll()"><i class="far fa-plus-square label-icon"></i> Összes lefoglalása</button>
				</div>
				<div class="d-flex flex-wrap gap-2 p-2 align-items-center rounded bg-light">
					<button type="button" class="btn btn-warning btn-label" :disabled="!selectedRowsRelease.length" @click="releaseSelected()"><i class="far fa-minus label-icon"></i> Kijelöltek feloldása</button>
					<button type="button" class="btn btn-warning btn-label" :disabled="!hasReservedItems" @click="releaseAll()"><i class="far fa-minus-square label-icon"></i> Összes feloldása</button>
				</div>


				<div style="width: 250px">
					<warehouse-select-field
						v-model="warehouseId"
						name="name"
						field-only
						label="Raktár"
						@update:modelValue="tableDataLoaded"
					/>
				</div>
			</div>
		</template>

		<template #item-productName="item">
			<product-badge
				:product-id="item.product_id"
				:product-name="item.productName"
				:product-manufacturer-id="item.product_manufacturer_id"
				:deleted-at="item.deleted_at"
				:key="'PB' + item.product_id"
				desktop
			/>
		</template>
		<template #item-reserved="item">
			<span :class="'fw-bold badge badge-xl bg-' + (item.reservedQuantity >= (item.contractProductQuantity - item.deliveredQuantity) ? 'success' : 'warning')">
				<number :value="item.reservedQuantity"/>
			</span>
		</template>
		<template #item-reservedWarehouse="item">
			<number :value="getReserved(item)"/>
		</template>
		<template #item-rowSelectReserve="item">
			<div v-if="getReservableQuantity(item)" class="d-flex align-items-center gap-2">
				<div class="single-checkbox" @click.prevent="selectRowReserve(item)">
					<i :class="selectedRowsReserve.indexOf(item.id) > -1 ? 'fas fa-check-square text-primary' : 'far fa-square'"></i>
				</div>
				<div v-if="selectedRowsReserve.indexOf(item.id) > -1">
					<input-field
						label="Mennyiség"
						:field-only="true"
						:name="'quantity_' + item.id"
						data-type="number"
						input-type="text"
						:step="1"
						:min="1"
						:max="getReservableQuantity(item)"
						v-model="selectedQuantitiesReserve[item.id]"
						:errors="{}"
						:fixed-width="40"
						align="center"
					/>
				</div>
			</div>
		</template>
		<template #item-rowSelectRelease="item">
			<div v-if="getReserved(item)" class="d-flex align-items-center gap-2">
				<div class="single-checkbox" @click.prevent="selectRowRelease(item)">
					<i :class="selectedRowsRelease.indexOf(item.id) > -1 ? 'fas fa-check-square text-primary' : 'far fa-square'"></i>
				</div>
				<div v-if="selectedRowsRelease.indexOf(item.id) > -1">
					<input-field
						label="Mennyiség"
						:field-only="true"
						:name="'quantity_' + item.id"
						data-type="number"
						input-type="text"
						:step="1"
						:min="1"
						:max="getReserved(item)"
						v-model="selectedQuantitiesRelease[item.id]"
						:errors="{}"
						:fixed-width="40"
						align="center"
					/>
				</div>
			</div>
		</template>
		<template #item-demand="item">
			<number :value="item.contractProductQuantity - item.deliveredQuantity"/>
		</template>
		<template #item-inStock="item">
			<div :class="{'text-success': getStock(item) >= (item.contractProductQuantity - item.deliveredQuantity), 'fw-bold': 1}">
				<number :value="getStock(item)"/>
			</div>
		</template>
		<template #item-required_amount_paid_at="item">
			<date-time :value="item.required_amount_paid_at"/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import ProductCategorySelectField from "../components/form/ProductCategorySelectField.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import vSelect from "vue-select";
import UserBadge from "../components/badges/UserBadge.vue";
import ProductManufacturerSelectField from "../components/form/ProductManufacturerSelectField.vue";
import {useGeneralDataStore} from "../stores/generalData";
import {useFlashStoreStore} from "../stores/flashStore";
import ColorBadge from "../components/ColorBadge.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {Link} from "@inertiajs/vue3";
import DateTime from "../components/DateTime.vue";
import Number from "../components/Number.vue";
import TitleBar from "../components/TitleBar.vue";
import {useApplicationStore} from "../stores/applicationStore";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import ProductBadge from "../components/badges/ProductBadge.vue";
import Attribute from "../components/Attribute.vue";
import ProjectBadge from "../components/badges/ProjectBadge.vue";
import ProjectAttribute from "../modules/projects/ProjectAttribute.vue";
import ProjectIdAttribute from "../components/attributes/ProjectIdAttribute.vue";
import ContractIdAttribute from "../components/attributes/ContractIdAttribute.vue";
import AddressBadge from "../components/badges/AddressBadge.vue";
import AddressAttribute from "../components/attributes/AddressAttribute.vue";

export default {
	layout: AppLayout,
	components: {
		AddressAttribute,
		AddressBadge,
		ContractIdAttribute,
		ProjectIdAttribute,
		ProjectAttribute,
		ProjectBadge,
		Attribute,
		ProductBadge,
		WarehouseSelectField,
		UpdatedAttribute,
		TitleBar,
		Number,
		DateTime,
		ColorBadge,
		ProductManufacturerSelectField,
		UserBadge,
		vSelect,
		DataLoader,
		InputField,
		Link,
		AdvancedDataTable,
		ProductCategorySelectField
	},
	props: {
		projectDetails: Object,
		contractDetails: Object,
		contract: Object,
		productStockInitial: Object,
		projectType: Object,
		productReservations: Object,
		initialWarehousesWithReservation: Array,
	},
	watch: {
		warehouseId(newValue, oldValue) {
			if (newValue !== oldValue){
				this.tableDataLoaded()
			}
		}
	},
	data(){
		let columns = [
			{
				text: 'Termék',
				value: 'productName',
				sortable: true
			},
			{
				text: 'Igény',
				value: 'demand',
				align: 'right',
				sortable: true
			},
			{
				text: 'Kiszállítva',
				value: 'deliveredQuantity',
				align: 'right',
				sortable: true
			},
			{
				text: 'Raktáron',
				value: 'inStock',
				align: 'right',
				sortable: false
			},
			{
				text: 'Lefoglal',
				value: 'rowSelectReserve',
				width: 5,
			},
			{
				text: 'Lefoglalva',
				value: 'reserved',
				align: 'right',
				sortable: true
			},
			{
				text: 'Felold',
				value: 'rowSelectRelease',
				width: 5,
			},
			{
				text: 'Lefoglalva raktárból',
				value: 'reservedWarehouse',
				align: 'right',
				sortable: false
			},
		]
		return {
			columns: columns,
			defaultFilters: {
				status: 'pending'
			},
			dataStore: useGeneralDataStore(),
			warehouseId: null,
			selectedRowsReserve: [],
			flashStore: useFlashStoreStore(),
			selectedQuantitiesReserve: {},
			selectedRowsRelease: [],
			selectedQuantitiesRelease: {},
			productStock: this.productStockInitial,
			hasReservableItems: false,
			hasReservedItems: false,
			warehousesWithReservation: this.initialWarehousesWithReservation,
			app: useApplicationStore()
		}
	},
	computed: {
		warehouseOptions: {
			get() {
				let payload = this.dataStore.getData('warehouses')
				payload.unshift({name: 'Összes raktár', id: 0})

				return payload
			}
		},
	},
	methods: {
		getStock: function(item){
			if (this.productStock[item.product_id] !== undefined){
				let sum = 0
				for (let i in this.productStock[item.product_id]){
					if (this.warehouseId === parseInt(i) || !this.warehouseId){
						sum += this.productStock[item.product_id][i]
					}
				}

				return sum
			}
		},
		sendReservationRequest: function (data){
			axios.post(route('inventoryReservations.store', this.contract.id), data).then((response) => {
				this.flashStore.addFlash(response.data.messages)
				this.warehousesWithReservation = response.data.warehousesWithReservation
				this.$refs.table.getData()
				this.selectedRowsReserve = []
				this.selectedQuantitiesReserve = {}
				this.selectedRowsRelease = []
				this.selectedQuantitiesRelease = {}

				this.productStock = response.data.productStock
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		reserveSelected: function (){
			if (!this.selectedRowsReserve.length){
				return
			}

			this.sendReservationRequest({
				reserve: this.selectedQuantitiesReserve,
				warehouseId: this.warehouseId
			})
		},
		reserveAll: function (){
			this.sendReservationRequest({
				reserve: 'all',
				warehouseId: this.warehouseId
			})
		},
		releaseSelected: function (){
			if (!this.selectedRowsRelease.length){
				return
			}

			this.sendReservationRequest({
				release: this.selectedQuantitiesRelease,
				warehouseId: this.warehouseId
			})
		},
		releaseAll: function (){
			this.sendReservationRequest({
				release: 'all',
				warehouseId: this.warehouseId
			})
		},
		getReservableQuantity: function(item){
			return Math.min((item.contractProductQuantity - item.deliveredQuantity) - item.reservedQuantity, this.getStock(item))

		},
		selectRowReserve: function(item){
			if (this.selectedRowsReserve.indexOf(item.id) > -1){
				this.selectedRowsReserve.splice(this.selectedRowsReserve.indexOf(item.id), 1)
				delete this.selectedQuantitiesReserve[item.id]
			} else {
				this.selectedRowsReserve.push(item.id)
				this.selectedQuantitiesReserve[item.id] = this.getReservableQuantity(item)
			}
		},
		selectRowRelease: function(item){
			if (this.selectedRowsRelease.indexOf(item.id) > -1){
				this.selectedRowsRelease.splice(this.selectedRowsRelease.indexOf(item.id), 1)
				delete this.selectedQuantitiesRelease[item.id]
			} else {
				this.selectedRowsRelease.push(item.id)
				this.selectedQuantitiesRelease[item.id] = this.getReserved(item)
			}
		},
		tableDataLoaded: function(e){
			this.hasReservableItems = false
			this.hasReservedItems = false

			for (let i = 0; i < this.$refs.table.items.length; i++) {
				if (this.getReservableQuantity(this.$refs.table.items[i]) > 0) {
					this.hasReservableItems = true
				}
				if (this.$refs.table.items[i].reservedQuantity > 0) {
					this.hasReservedItems = true
				}
			}
		},
		getReserved: function(item){
			if (item.reservationDetails === null){
				return 0
			}
			let reservations = item.reservationDetails.split(',')
			let payload = 0

			for (let i = 0; i < reservations.length; i++){
				let tmp = reservations[i].split(':')
				let warehouseId = parseInt(tmp[0])

				if (!this.warehouseId || this.warehouseId === warehouseId){
					payload += parseInt(tmp[1])
				}
			}

			return payload
		}
	}
}
</script>
