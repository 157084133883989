<template>
	<TitleBar :title="attributes.id !== undefined ? 'Űrlap módosítása' : 'Űrlap hozzáadása'" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('forms.store', model)"
		:back-url="route('forms.index')"
		v-model="attributes"
		v-slot="{ errors }"
	>
		<div class="card mb-3" v-if="attributes.id !== undefined">
			<div class="card-body">
				<div class="details-box">
					<updated-attribute :data="attributes" created/>
					<updated-attribute :data="attributes" updated/>
				</div>
			</div>
		</div>
		<div class="card mb-3">
			<div class="card-body">
				<h4 class="card-title mb-4">Adatok</h4>
				<div class="row">
					<div class="col-sm-6">
						<input-field
							label="Név"
							name="name"
							rules="required"
							v-model="attributes.name"
							:disabled="!attributes.editable"
							:errors="errors"
						/>
						<data-loader :data="['formCategories']" v-slot="{ dataStore }">
							<select-field
								label="Kategória"
								name="form_category_id"
								rules="required"
								v-model="attributes.form_category_id"
								:disabled="!attributes.editable"
								:errors="errors"
								:options="dataStore.getData('formCategories')"
							/>
						</data-loader>
						<input-field
							label="Generált file neve"
							name="output_file_name"
							rules="required"
							v-model="attributes.output_file_name"
							:disabled="!attributes.editable"
							:errors="errors"
						/>
					</div>
					<div class="col-sm-6">
						<h4 class="card-title mb-4">File</h4>
						<FileUploader
							field-name="file"
							v-model="attributes.file"
							:multiple="false"
							file-type="pdf"
							:editable="attributes.editable"
						></FileUploader>
						<div v-if="attributes.id !== undefined">
							<a :href="route('forms.download', attributes.id)" class="btn btn-primary btn-label"><i class="far fa-download label-icon"></i> Minta letöltése</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card mb-3" v-if="attributes.id !== undefined">
			<div class="card-body">
				<h4 class="card-title mb-4">Mezők</h4>
				<div class="card border shadow-none card-body" v-for="(formField, formFieldKey) in fields" :key="'formField_' + formFieldKey">
					<h4 class="card-title mb-3" v-if="editingNames.indexOf(formField.key) < 0">{{ formField.name }} <a href="#" class="ms-2" @click.prevent="editingNames.push(formField.key)"><i class="far fa-edit"></i></a></h4>
					<input-field
						label="Megnevezés"
						:name="'field_name_' + formField.id"
						v-model="attributes.fields[formField.id].name"
						:disabled="!attributes.editable"
						:errors="errors"
						v-else
					/>
					<p class="text-muted mb-3" v-if="formField.name !== formField.key">{{ formField.key }}</p>
					<div v-if="formField.type !== 'Text'" class="mb-3">
						<strong>Lehetséges értékek</strong>
						<div class="d-flex gap-2 flex-wrap mt-1">
							<span v-for="(value, valueKey) in formField.possible_values" :key="'formField_' + formFieldKey + '_posVal_' + valueKey" class="badge bg-light badge-xl">{{ value }}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<input-field
								label="Fix érték"
								:name="'value_' + formField.id"
								v-model="attributes.fields[formField.id].value"
								:disabled="!attributes.editable"
								:errors="errors"
								v-if="formField.type === 'Text'"
							/>
							<select-field
								label="Fix érték"
								:name="'value_' + formField.id"
								v-model="attributes.fields[formField.id].value"
								:disabled="!attributes.editable"
								data-type="string"
								:errors="errors"
								:options="getFieldOptions(formField)"
								v-else
							></select-field>
						</div>
						<div class="col-md-4">
							<switch-field
								label="Kötelező"
								:name="'required_' + formField.id"
								v-model="attributes.fields[formField.id].is_required"
								:disabled="!attributes.editable"
								:errors="errors"
							></switch-field>
						</div>
						<div class="col-md-4" v-if="formField.type === 'Text'">
							<radio-field
								label="Adattípus"
								:name="'data_type_' + formField.id"
								v-model="attributes.fields[formField.id].data_type"
								:values="dataTypes"
								:disabled="!attributes.editable"
								:errors="errors"
								@input="dataTypeChanged(formField)"
							></radio-field>
						</div>
						<template v-if="attributes.fields[formField.id].data_type === 'number'">
							<div class="col-md-4">
								<input-field
									label="Tizedesjegyek"
									:name="'decimal_places_' + formField.id"
									v-model="attributes.fields[formField.id].decimal_places"
									data-type="number"
									:step="1"
									:min="0"
									:errors="errors"
								/>
							</div>
							<div class="col-md-4">
								<input-field
									label="Előtag"
									:name="'prefix_' + formField.id"
									v-model="attributes.fields[formField.id].prefix"
									:errors="errors"
									info="Szóköz beillesztéséhez használj '_' karaktert"
								/>
							</div>
							<div class="col-md-4">
								<input-field
									label="Utótag"
									:name="'suffix_' + formField.id"
									v-model="attributes.fields[formField.id].suffix"
									:errors="errors"
									info="Szóköz beillesztéséhez használj '_' karaktert"
								/>
							</div>
						</template>
					</div>

					<template v-if="!attributes.fields[formField.id].value">
						<template v-if="formField.type === 'Text'">
							<template v-for="(projectType, projectTypeKey) in projectTypeFields" :key="'formField_' + formFieldKey + '_projectType_' + projectType.id">
								<div class="bg-light p-3 mb-2" v-if="attributes.mappings[formField.id][projectType.id].length">
									<h4 class="card-title mb-3">{{ projectType.name }}</h4>

									<div class="form-value-editor">
										<div v-for="(field, fieldKey) in attributes.mappings[formField.id][projectType.id]" :key="'formField_' + formFieldKey + '_projectType_' + projectType.id + '_field_' + fieldKey" class="d-flex gap-2 align-items-center">
												<span :class="{'fw-bold': 1,
													'text-success' : field.operation === 'and',
													'text-danger' : field.operation === 'or',
													}" v-if="fieldKey > 0 && formField.data_type === 'string'" @click.prevent="removeSourceField(formField, projectType, fieldKey)">
															{{ field.operation === 'and' ? '&&' : '||'}}
														</span>
											<div class="btn-group">
												<button type="button" :class="{
														'btn': 1,
														'btn-soft-secondary': field.project_type_field_id,
														'btn-soft-warning': field.product_categories !== null && field.product_categories.length,
														'btn-soft-success': field.fixed_value,
														'btn-soft-info': field.special,
													}" @click.prevent="dataSourceIndex = fieldKey; dataSource = attributes.mappings[formField.id][projectType.id][fieldKey]; dataSourceProjectTypeId = projectType.id; dataSourceField = formField">
													<template v-if="field.product_categories !== null && field.product_categories.length">
														<div class="d-flex gap-2">
															<span v-for="category in field.product_categories" :key="'formField_' + formFieldKey + '_projectType_' + projectType.id + '_field_' + fieldKey + '_' + category">
																{{ productCategoriesById[category].name }}
															</span>
															<div v-if="allProductAttributesById[field.product_attribute] !== undefined">
																/ {{ allProductAttributesById[field.product_attribute].name }}
															</div>
														</div>
													</template>
													<template v-if="field.project_type_field_id">
														{{ getProjectTypeFieldName(field) }}
													</template>
													<template v-if="field.fixed_value">
														{{ field.fixed_value.replace(' ', '&nbsp;') }}
													</template>
													<template v-if="field.special && formField.type === 'Text'">
														<strong>{{ field.special }}</strong><span class="ms-1" v-if="field.length">{{ field.length}}</span>
													</template>
													<template v-if="formField.type !== 'Text'">
														<strong class="mx-2">{{ field.special }}</strong>
														{{ field.test_value }}
													</template>
												</button>
												<button type="button" class="btn btn-soft-secondary btn-only-on-hover" @click.prevent="moveSourceField(attributes.mappings[formField.id][projectType.id], fieldKey, 'up')">
													<i class="far fa-angle-left"></i>
												</button>
												<button type="button" class="btn btn-soft-secondary btn-only-on-hover" @click.prevent="moveSourceField(attributes.mappings[formField.id][projectType.id], fieldKey, 'down')">
													<i class="far fa-angle-right"></i>
												</button>
												<button type="button" class="btn btn-soft-danger btn-only-on-hover" @click.prevent="removeSourceField(formField, projectType, fieldKey)">
													<i class="far fa-times"></i>
												</button>
											</div>
										</div>
									</div>
									<div class="mt-3">
										<button type="button" class="btn btn-primary btn-label" @click.prevent="addSourceField(formField, projectType)"><i class="far fa-plus label-icon"></i> Hozzáad</button>
									</div>
								</div>
							</template>
						</template>
						<template v-else>
							<template v-for="projectType in projectTypeFields" :key="'formField_' + formFieldKey + '_projectType_' + projectType.id">
								<div class="card border shadow-none card-body" v-if="attributes.mappings[formField.id][projectType.id][formField.possible_values[formField.possible_values.length - 1]].length">
									<h4 class="card-title mb-3">{{ projectType.name }}</h4>

									<template v-for="(value, valueKey) in formField.possible_values" :key="'formField_' + formFieldKey + '_projectType_' + projectType.id + '_value_' + valueKey">
										<div class="bg-light p-3 mb-2">
											<h5>{{ value }}</h5>

											<div class="form-value-editor">
												<div v-for="(field, fieldKey) in attributes.mappings[formField.id][projectType.id][value]" :key="'formField_' + formFieldKey + '_projectType_' + projectType.id + '_value_' + valueKey + '_field_' + fieldKey" class="d-flex gap-2 align-items-center">
															<span :class="{'fw-bold': 1,
														'text-success' : field.operation === 'and',
														'text-danger' : field.operation === 'or',
														}" v-if="fieldKey > 0 && formField.data_type === 'string'" @click.prevent="removeSourceField(formField, projectType, fieldKey)">
																{{ field.operation === 'and' ? '&&' : '||'}}
															</span>
													<div class="btn-group">
														<button type="button"
																:class="{
															'btn': 1,
															'btn-soft-secondary': field.project_type_field_id,
															'btn-soft-warning': (field.product_categories !== null && field.product_categories.length),
															'btn-soft-success': field.fixed_value && !field.project_type_field_id && (field.product_categories === null || !field.product_categories.length),
															'btn-soft-info': field.special && !field.project_type_field_id && (field.product_categories === null || !field.product_categories.length),
														}" @click.prevent="dataSourceIndex = fieldKey; dataSource = field; dataSourceProjectTypeId = projectType.id; dataSourceField = formField">

															<template v-if="field.product_categories !== null && field.product_categories.length">
																<div class="d-flex gap-2">
																	<span v-for="category in field.product_categories" :key="'formField_' + formFieldKey + '_projectType_' + projectType.id + '_value_' + valueKey + '_field_' + fieldKey + '_' + category">
																		{{ productCategoriesById[category].name }}
																	</span>
																	<div v-if="allProductAttributesById[field.product_attribute] !== undefined">
																		/ {{ allProductAttributesById[field.product_attribute].name }}
																	</div>
																</div>
															</template>
															<template v-if="field.project_type_field_id">
																{{ getProjectTypeFieldName(field) }}
															</template>
															<template v-if="field.fixed_value">
																{{ field.fixed_value.replace(' ', '&nbsp;') }}
															</template>
															<template v-if="field.special && formField.type === 'Text'">
																<strong>{{ field.special }}</strong><span class="ms-1" v-if="field.length">{{ field.length}}</span>
															</template>
															<template v-if="formField.type !== 'Text'">
																<strong class="mx-2">{{ field.special }}</strong>
																{{ field.test_value }}
															</template>
														</button>
														<button type="button" class="btn btn-soft-secondary btn-only-on-hover" @click.prevent="moveSourceField(attributes.mappings[formField.id][projectType.id][value], fieldKey, 'up')">
															<i class="far fa-angle-left"></i>
														</button>
														<button type="button" class="btn btn-soft-secondary btn-only-on-hover" @click.prevent="moveSourceField(attributes.mappings[formField.id][projectType.id][value], fieldKey, 'down')">
															<i class="far fa-angle-right"></i>
														</button>
														<button type="button" class="btn btn-soft-danger btn-only-on-hover" @click.prevent="removeSourceField(formField, projectType, fieldKey, value)">
															<i class="far fa-times"></i>
														</button>
													</div>
												</div>
											</div>
											<div class="mt-3">
												<button type="button" class="btn btn-primary btn-label" @click.prevent="addSourceField(formField, projectType, value)"><i class="far fa-plus label-icon"></i> Hozzáad</button>
											</div>
										</div>
									</template>
								</div>
							</template>
						</template>

						<div class="d-flex gap-2 flex-wrap">
							<template v-for="projectType in projectTypeFields" :key="projectType.id">
								<template v-if="formField.type !== 'Text'">
									<button type="button" class="btn btn-secondary btn-label btn-sm"
											v-if="formField.possible_values[formField.possible_values.length - 1]
												&& attributes.mappings[formField.id][projectType.id]
												&& !attributes.mappings[formField.id][projectType.id][formField.possible_values[formField.possible_values.length - 1]].length"
											@click.prevent="addSourceField(formField, projectType, formField.possible_values[formField.possible_values.length - 1])"
									><i class="far fa-plus label-icon"></i> {{ projectType.name }}</button>
								</template>
								<template v-else>
									<button type="button" class="btn btn-secondary btn-label btn-sm"
											v-if="attributes.mappings[formField.id][projectType.id]
												&& !attributes.mappings[formField.id][projectType.id].length"
											@click.prevent="addSourceField(formField, projectType)"
									><i class="far fa-plus label-icon"></i> {{ projectType.name }}</button>
								</template>
							</template>
						</div>
					</template>
				</div>
			</div>
		</div>
		<modal
			:title="dataSourceField.name + ' / ' + projectTypeFieldsById[dataSourceProjectTypeId].name"
			:loading="loading"
			:closable="true"
			v-if="dataSourceField"
			@close="dataSourceField = null; dataSourceProjectTypeId = null; dataSourceIndex = null">

			<template #default>
				<div class="data-source-operation btn-group mb-3" v-if="dataSourceIndex > 0 && (dataSourceField.data_type === 'string' || dataSourceField.type !== 'Text')">
					<button type="button" :class="{
													'btn': 1,
													'btn-success': dataSource.operation === 'and',
													'btn-dark': dataSource.operation !== 'and'
												}"
							@click.prevent="dataSource.operation = 'and'">&& Összefűz</button>

					<button type="button" :class="{
													'btn': 1,
													'btn-danger': dataSource.operation === 'or',
													'btn-dark': dataSource.operation !== 'or'
												}"
							@click.prevent="dataSource.operation = 'or'">|| Vagy</button>
				</div>

				<select-field
					label="Projekt mező"
					:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_project_type_field_id'"
					:disabled="!attributes.editable"
					label-field="title"
					:errors="errors"
					v-model="dataSource.project_type_field_id"
					:options="projectTypeFieldsById[dataSourceProjectTypeId].fields"
					v-if="(dataSource.product_categories === null || !dataSource.product_categories.length)
							&& dataSource.project_attribute === null
							&& !dataSource.fixed_value
							&& (!dataSource.special || dataSourceField.type !== 'Text')"
				></select-field>

				<select-field
					label="Projekt tulajdonság"
					:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_project_attribute'"
					:disabled="!attributes.editable"
					label-field="label"
					:errors="errors"
					:multiple="true"
					v-model="dataSource.project_attribute"
					:options="projectAttributes"
					v-if="!dataSource.project_type_field_id
							&& !dataSource.product_categories
							&& dataSourceField.data_type === 'string'
							&& !dataSource.fixed_value
							&& !dataSource.special"
				></select-field>

				<select-field
					label="Termékkategóriák"
					:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_product_categories'"
					:disabled="!attributes.editable"
					:errors="errors"
					:multiple="true"
					v-model="dataSource.product_categories"
					:options="productCategories"
					v-if="!dataSource.project_type_field_id
							&& dataSource.project_attribute === null
							&& !dataSource.fixed_value
							&& (!dataSource.special || dataSourceField.type !== 'Text')"
				></select-field>

				<input-field
					label="Fix érték"
					v-model="dataSource.fixed_value"
					:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_fixed_value'"
					:errors="errors"
					info="Amennyiben csak egy szóközt szeretnél beszúrni, írj egy '_' karaktert."
					v-if="dataSourceField.type === 'Text'
							&& dataSource.project_attribute === null
							&& !dataSource.project_type_field_id
							&& (dataSource.product_categories === null || !dataSource.product_categories.length)
							&& !dataSource.special"
				/>

				<template v-if="dataSourceField.type === 'Text' && dataSource.project_type_field_id && dataSourceField.data_type === 'string'">
					<select-field
						label="Cím részlet"
						:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_project_type_field_attribute'"
						:disabled="!attributes.editable"
						:errors="errors"
						data-type="string"
						v-model="dataSource.project_type_field_attribute"
						:options="addressAttributeFields"
						v-if="getProjectTypeFieldType(dataSourceProjectTypeId, dataSource.project_type_field_id) === 'address'"
					></select-field>

					<input-field
						label="Kezdő karakter"
						v-model="dataSource.start_character"
						data-type="number"
						:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_start_character'"
						:step="1"
						:errors="errors"
					/>

					<input-field
						label="Hossz"
						:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_length'"
						v-model="dataSource.length"
						data-type="number"
						:step="1"
						:errors="errors"
					/>
				</template>

				<template v-if="(dataSource.product_categories !== null && dataSource.product_categories.length)">
					<input-field
						label="Termék index"
						:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_product_index'"
						v-model="dataSource.product_index"
						data-type="number"
						:step="1"
						:min="1"
						:errors="errors"
					/>
					<template v-if="dataSourceField.type === 'Text'">
						<select-field
							label="Termék tulajdonság"
							:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_product_attribute'"
							:disabled="!attributes.editable"
							:errors="errors"
							data-type="string"
							v-if="dataSourceField.data_type === 'string'"
							v-model="dataSource.product_attribute"
							:options="productStringAttributes"
						></select-field>

						<select-field
							label="Termék tulajdonság"
							:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_product_attribute'"
							:disabled="!attributes.editable"
							:errors="errors"
							data-type="string"
							v-if="dataSourceField.data_type === 'number'"
							v-model="dataSource.product_attribute"
							:options="productNumericAttributes"
						></select-field>
					</template>
					<template v-else>
						<select-field
							label="Termék tulajdonság"
							:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_product_attribute'"
							:disabled="!attributes.editable"
							:errors="errors"
							data-type="string"
							v-model="dataSource.product_attribute"
							:options="allProductAttributes"
						></select-field>
					</template>

					<switch-field
						label="Kiszállított termék"
						:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_delivered_product'"
						:disabled="!attributes.editable"
						:errors="errors"
						v-model="dataSource.delivered_product"
						/>
				</template>

				<template v-if="dataSourceField.type !== 'Text' && ((dataSource.product_categories !== null && dataSource.product_categories.length) || dataSource.project_type_field_id)">
					<select-field
						label="Kapcsolat"
						:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_special'"
						:disabled="!attributes.editable"
						label-field="label"
						:errors="errors"
						v-model="dataSource.special"
						data-type="string"
						rules="required"
						:options="getSpecialLogical(dataSource)"
					></select-field>

					<input-field
						label="Vizsgált érték"
						:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_test_value_n'"
						v-model="dataSource.test_value"
						data-type="number"
						:step="1"
						:errors="errors"
						v-if="(dataSource.project_type_field_id && getProjectTypeFieldById(dataSource.project_type_id, dataSource.project_type_field_id).type === 'number')
								|| ((dataSource.product_categories !== null && dataSource.product_categories.length) && allProductAttributesById[dataSource.product_attribute] !== undefined && allProductAttributesById[dataSource.product_attribute].type === 'number')"
					/>
					<input-field
						label="Vizsgált érték"
						:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_test_value_s'"
						v-model="dataSource.test_value"
						:errors="errors"
						v-else
					/>
				</template>

				<input-field
					label="Tizedesek"
					:name="'field_' + dataSourceField.id + '_' + dataSourceProjectTypeId + '_' + dataSourceIndex + '_' + dataSourceValue + '_length'"
					v-model="dataSource.length"
					data-type="number"
					:step="1"
					:errors="errors"
					v-if="dataSource.special
							&& (dataSource.special === 'FEL('
							|| dataSource.special === 'LE('
							|| dataSource.special === 'KEREKIT(')"
				/>
				<template v-if="dataSourceField.type === 'Text'">
					<div
						v-if="dataSourceField.data_type === 'number'
							&& !dataSource.project_type_field_id
							&& dataSource.project_attribute === null
							&& (dataSource.product_categories === null || !dataSource.product_categories.length)
							&& !dataSource.fixed_value"
						class="mt-3 d-flex gap-2 flex-wrap"
					>
						<template v-for="special in specialsNumbers">
							<button :class="{
									'btn': 1,
									'font-size-15': 1,
									'btn-secondary': !dataSource.special,
									'btn-primary': dataSource.special,
								}" v-if="!dataSource.special ||
										(dataSource.special
										&& dataSource.special === special)"
									@click.prevent="dataSource.special = special;
									dataSourceField = null; dataSourceProjectTypeId = null; dataSourceIndex = null">
								{{ special }}
							</button>
						</template>
					</div>
					<div
						v-if="dataSourceField.data_type === 'string'
							&& !dataSource.project_type_field_id
							&& dataSource.project_attribute === null
							&& (dataSource.product_categories === null || !dataSource.product_categories.length)
							&& !dataSource.fixed_value"
						class="mt-3 d-flex gap-2 flex-wrap"
					>
						<template v-for="special in specialStrings">
							<button :class="{
									'btn': 1,
									'font-size-15': 1,
									'btn-secondary': !dataSource.special,
									'btn-primary': dataSource.special,
								}" v-if="!dataSource.special ||
										(dataSource.special
										&& dataSource.special === special)"
									@click.prevent="dataSource.special = special;
									dataSourceField = null; dataSourceProjectTypeId = null; dataSourceIndex = null">
								{{ special }}
							</button>
						</template>
					</div>
				</template>
			</template>
		</modal>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import SelectField from "../components/form/SelectField.vue";
import Modal from "../components/Modal.vue";
import FileUploader from "../components/FileUploader.vue";
import SwitchField from "../components/form/SwitchField.vue";
import RadioField from "../components/form/RadioField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import {moveValueInArray} from "../functions";
import {useApplicationStore} from "../stores/applicationStore";
import DateTime from "../components/DateTime.vue";
import DataLoader from "../components/DataLoader.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {
		UpdatedAttribute,
		DataLoader,
		DateTime, InputField, FormWrapper, RadioField, SwitchField, FileUploader, Modal, SelectField, TitleBar},
	layout: AppLayout,
	computed: {
		projectTypeFieldsById: {
			get() {
				return _.keyBy(this.projectTypeFields, 'id')
			}
		},
		productCategoriesById: {
			get() {
				return _.keyBy(this.productCategories, 'id')
			}
		},
		allProductAttributes: {
			get() {
				let tmp = _.clone(this.productStringAttributes)
				_.forEach(this.productNumericAttributes, function (i){
					tmp.push(i)
				})
				return tmp
			}
		},
		allProductAttributesById: {
			get() {
				let tmp = _.clone(this.productStringAttributes)
				_.forEach(this.productNumericAttributes, function (i){
					tmp.push(i)
				})
				return _.keyBy(tmp, 'id')
			}
		}
	},
	props: {
		model: Object,
		productCategories: Array,
		productAttributes: Array,
		fields: Array,
		fieldDataSources: Array,
		projectTypeFields: Array,
	},
	data(){
		let attributes = _.clone(this.model)
		attributes.file = attributes.file !== undefined && attributes.file !== null ? [attributes.file] : []
		attributes.mappings = {}
		attributes.fields = {}

		_.forEach(this.fields, (field) => {
			attributes.mappings[field.id] = {}
			attributes.fields[field.id] = field

			_.forEach(this.projectTypeFields, (type) => {
				attributes.mappings[field.id][type.id] = []
				if (field.type !== 'Text') {
					attributes.mappings[field.id][type.id] = {}
					_.forEach(field.possible_values, (value) => {
						if (value){
							attributes.mappings[field.id][type.id][value] = []
						}
					})
				} else {
					attributes.mappings[field.id][type.id] = []
				}
			})
		})
		_.forEach(this.fieldDataSources, function (tmp){
			if (tmp.option_value){
				attributes.mappings[tmp.form_field_id][tmp.project_type_id][tmp.option_value].push(tmp)
			} else {
				attributes.mappings[tmp.form_field_id][tmp.project_type_id].push(tmp)
			}
		})

		let productStringAttributes = [
			{
				id: 'name',
				name: 'Név',
				type: 'string'
			},
			{
				id: 'sku',
				name: 'Cikkszám',
				type: 'string'
			},
			{
				id: 'manufacturer',
				name: 'Gyártó',
				type: 'string'
			},
			{
				id: 'manufacturer_code',
				name: 'Gyártói azonosító',
				type: 'string'
			},
			{
				id: 'serial_number',
				name: 'Sorozatszám',
				type: 'string'
			},
		]
		let productNumericAttributes = [
			{
				id: 'priceNet',
				name: 'Nettó ár',
				type: 'number'
			},
			{
				id: 'priceGross',
				name: 'Bruttó ár',
				type: 'number'
			},
			{
				id: 'unitPriceNet',
				name: 'Nettó egységár',
				type: 'number'
			},
			{
				id: 'unitPriceGross',
				name: 'Bruttó egységár',
				type: 'number'
			},
			{
				id: 'warranty',
				name: 'Garancia hónap',
				type: 'number'
			},
			{
				id: 'quantity',
				name: 'Termék mennyisége',
				type: 'number'
			},
		]

		_.forEach(this.productAttributes, function (attribute){
			if (attribute.type === 'number'){
				productNumericAttributes.push({
					id: 'attribute_' + attribute.id,
					name: attribute.name,
					type: 'number'
				})
				productNumericAttributes.push({
					id: 'attribute_unit_' + attribute.id,
					name: attribute.name + " (egység)",
					type: 'number'
				})
			} else {
				productStringAttributes.push({
					id: 'attribute_' + attribute.id,
					name: attribute.name,
					type: 'string'
				})
			}
		})

		return {
			attributes: attributes,
			app: useApplicationStore(),
			loading: false,
			flash: [],
			dataSource: null,
			dataSourceField: null,
			dataSourceProjectTypeId: null,
			dataSourceIndex: null,
			dataSourceValue: null,
			editingNames: [],
			productStringAttributes: productStringAttributes,
			productNumericAttributes: productNumericAttributes,
			dataTypes: {
				string: {
					name: 'Szöveg'
				},
				number: {
					name: 'Szám'
				}
			},
			specialsNumbers: ['MAX(', 'MIN(', 'FEL(', 'LE(', 'KEREKIT(', 'ELSO(', '(', ')', '+', '-', '/', '*', 'Fő tulajdonság'],
			specialStrings: ['Évszám', 'Hónap neve', 'Hónap szám', 'Nap', 'Fő tulajdonság'],
			specialLogicalNumber: ['=', '!=', '>', '>=', '<', '<='],
			specialLogicalString: ['=', '!=', 'tartalmazza', 'nem tartalmazza', 'kezdődik', 'nem kezdődik', 'végződik', 'nem végződik', 'üres', 'nem üres'],
			addressAttributeFields: [
				{
					id: 'country',
					name: 'Ország'
				},
				{
					id: 'city',
					name: 'Város'
				},
				{
					id: 'street',
					name: 'Utca'
				},
				{
					id: 'zip',
					name: 'Irányítószám'
				},
				{
					id: 'house_number',
					name: 'Házszám'
				},
				{
					id: 'floor',
					name: 'Emelet'
				},
				{
					id: 'door',
					name: 'Ajtó'
				},
			],
			projectAttributes: [
				{id: 'handlerName', label: 'Tanácsadó neve'},
				{id: 'handlerFirstName', label: 'Tanácsadó keresztneve'},
				{id: 'handlerLastName', label: 'Tanácsadó vezetékneve'},
				{id: 'handlerPhone', label: 'Tanácsadó telefonszáma'},
				{id: 'handlerEmail', label: 'Tanácsadó e-mail címe'},
				{id: 'installerName', label: 'Telepítő neve'},
				{id: 'installerFirstName', label: 'Telepítő keresztneve'},
				{id: 'installerLastName', label: 'Telepítő vezetékneve'},
				{id: 'installerPhone', label: 'Telepítő telefonszáma'},
				{id: 'installerEmail', label: 'Telepítő e-mail címe'},
			]
		}
	},
	mounted() {
	},
	methods: {
		moveValueInArray,
		getSpecialLogical: function (dataSource){
			let tmp = []
			if (dataSource.project_type_field_id){
				tmp = this.getProjectTypeFieldById(dataSource.project_type_id, dataSource.project_type_field_id).type === 'number' ? this.specialLogicalNumber : this.specialLogicalString
			}
			if ((dataSource.product_categories !== null && dataSource.product_categories.length) && dataSource.product_attribute){
				tmp = this.allProductAttributesById[dataSource.product_attribute] !== undefined && this.allProductAttributesById[dataSource.product_attribute].type === 'number' ? this.specialLogicalNumber : this.specialLogicalString
			}
			let payload = []
			_.forEach(tmp, function (t){
				payload.push({
					id: t,
					label: t
				})
			})

			return payload
		},
		getProjectTypeFieldById: function (projectTypeId, id){
			let tmp = _.keyBy(this.projectTypeFieldsById[projectTypeId].fields, 'id')

			return tmp[id]
		},
		getFieldOptions: function (field){
			let payload = []
			_.forEach(field.possible_values, function (value){
				payload.push({id: value, name: value})
			})

			return payload
		},
		updateOrders: function (){
			_.forEach(this.attributes.mappings, (formFields, formFieldId) => {
				_.forEach(formFields, (dataSources, projectTypeId) => {
					let order = 0

					_.forEach(dataSources, (dataSource, dataSourceId) => {
						this.attributes.mappings[formFieldId][projectTypeId][dataSourceId].order = order++
					})
				})
			})
		},
		getProjectTypeFieldName: function (field){
			let fields = _.keyBy(_.keyBy(this.projectTypeFields, 'id')[field.project_type_id].fields, 'id')

			if (fields[field.project_type_field_id] !== undefined){
				let title = fields[field.project_type_field_id].title
				if (field.project_type_field_attribute && fields[field.project_type_field_id].type === 'address'){
					title += ' / ' + _.keyBy(this.addressAttributeFields, 'id')[field.project_type_field_attribute].name
				}

				return title
			}

			return ''
		},
		addSourceField: function (formField, projectType, value){
			let newField = {
				project_type_id: projectType.id,
				project_type_field_id: null,
				project_attribute: null,
				product_categories: [],
				start_character: null,
				length: null,
				product_index: 1,
				product_attribute: formField.data_type === 'number' ? 'priceNet' : 'name',
				order: 0,
				fixed_value: null,
				operation: 'and',
				special: null,
				test_value: '',
				option_value: '',
				delivered_product: 0,
			}
			if (value !== undefined){
				newField.option_value = value
				this.attributes.mappings[formField.id][projectType.id][value].push(newField)
				this.dataSource = _.last(this.attributes.mappings[formField.id][projectType.id][value])
				this.dataSourceIndex = this.attributes.mappings[formField.id][projectType.id][value].length - 1
			} else {
				this.attributes.mappings[formField.id][projectType.id].push(newField)
				this.dataSource = _.last(this.attributes.mappings[formField.id][projectType.id])
				this.dataSourceIndex = this.attributes.mappings[formField.id][projectType.id].length - 1
			}

			this.updateOrders()

			this.dataSourceField = formField
			this.dataSourceProjectTypeId = projectType.id
		},
		removeSourceField: function (formField, projectType, fieldKey, optionValue){
			this.dataSourceIndex = null
			this.dataSource = null
			this.dataSourceField = null
			this.dataSourceProjectTypeId = null

			if (optionValue !== undefined){
				this.attributes.mappings[formField.id][projectType.id][optionValue].splice(fieldKey, 1)
			} else {
				this.attributes.mappings[formField.id][projectType.id].splice(fieldKey, 1)
			}
			this.updateOrders()
		},
		moveSourceField: function (array, key, direction){
			this.moveValueInArray(array, key, direction)
			this.updateOrders()
		},
		dataTypeChanged: function (formField){
			_.forEach(this.attributes.mappings[formField.id], (projectType, projectTypeId) => {
				this.attributes.mappings[formField.id][projectTypeId] = []
			})
		},
		getProjectTypeFieldType: function (projectTypeId, fieldId){
			for (let i = 0; i < this.projectTypeFieldsById[projectTypeId].fields.length; i++){
				if (this.projectTypeFieldsById[projectTypeId].fields[i].id === fieldId){
					return this.projectTypeFieldsById[projectTypeId].fields[i].type
				}
			}

			return ''
		}
	}
}
</script>

<style scoped>
.btn-only-on-hover {
	display: none;
}
.btn-group {
	border-radius: 4px !important;
	overflow: hidden;
}
.btn-group:hover .btn-only-on-hover {
	display: block;
}
.btn-group:hover {
	margin-right: -93px;
	position: relative;
	z-index: 200;
	background: var(--bs-light);
	box-shadow: 0 0 10px rgba(0,0,0,.4);
}
</style>
