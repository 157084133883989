<template>
	<data-loader :data="['projectTypes']" v-slot="{ dataStore }">
		<color-badge
			:luminosity="projectType.luminosity"
			:color="projectType.color"
			:icon="projectType.icon"
			:content="projectType.name"
			:small="small"
			></color-badge>
	</data-loader>
</template>

<script>
import ColorBadge from "../../components/ColorBadge.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import DataLoader from "../../components/DataLoader.vue";

export default {
	components: {DataLoader, ColorBadge},
	props: {
		data: Object,
		small: Boolean
	},
	data(){
		return {
			dataStore: useGeneralDataStore()
		}
	},
	computed: {
		projectType: {
			get(){
				let id = this.data.project_type_id !== undefined ? this.data.project_type_id : this.data.projectTypeId
				return this.dataStore.getData('projectTypes', true)[id]
			}
		}
	}
}
</script>

<style scoped>

</style>
