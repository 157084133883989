<template>
	<template v-if="dataStore.dataLoaded(data, callback)">
		<slot :dataStore="dataStore"></slot>
	</template>
	<template v-else>
		<div :class="{'placeholder-glow': 1, 'mb-2': !inline}" v-if="!hideLoader">
			<span class="placeholder font-size-16" :style="'width:'+loaderLength+'%'"></span>
		</div>
	</template>
</template>

<script>
import { useGeneralDataStore } from "../stores/generalData";
import data from "bootstrap/js/src/dom/data";
export default {
	props: {
		data: Array,
		callback: Function,
		inline: Boolean,
		hideLoader: Boolean
	},
	data(){
		return {
			loading: true,
			dataStore: useGeneralDataStore(),
			loaderLength: 30 + Math.round(Math.random() * 50)
		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
