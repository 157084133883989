<template>
	<modal
		:title="modalTitle"
		@close="$emit('close')"
		:loading="!loaded"
		wide
		:closable="true"
	>
		<template #default>
			<form-wrapper
				:show-top-buttons="false"
				:show-bottom-buttons="false"
				v-model="task"
				:store-url="formStoreUrl"
				@afterSubmit="taskSaved"
				:disable-redirect="true"
				:no-margin="true"
				:only-attributes="formAttributesToSave"
				error-event-enabled
				@errorsChanged="errorsChanged"
				ref="taskForm"
			>
				<template #default="{activeTab, errors, editable}">
					<div class="row">
						<div class="col-md-6">
							<input-field
								label="Feladat címe"
								v-model="task.title"
								:disabled="!task.editable"
								name="title"
								rules="required"
								:errors="errors"
							></input-field>

							<div class="mb-3" v-if="task.editable || task.description">
								<label>Feladat leírása</label>
								<ckeditor-field
									md-height
									v-model="task.description"
									:disabled="!task.editable"
									name="description"
									:errors="errors"
								/>
							</div>

							<div class="mb-3" v-if="task.answerable || (task.completed && task.result)">
								<label>Válasz</label>
								<ckeditor-field
									md-height
									name="result"
									v-model="task.result"
									:disabled="!!task.completed"
									no-margin
								/>
							</div>

							<switch-field
								label="Teljesítettnek jelöl"
								name="completed"
								v-model="task.completed"
								no-margin
								v-if="task.answerable"
							></switch-field>

						</div>
						<div class="col-md-6">
							<template v-if="task.id !== undefined">
								<div class="details-box mb-3">
									<attribute icon="far fa-clock" label="Létrehozva"><date-time :value="task.created_at"></date-time></attribute>
									<user-attribute :user-id="task.created_by_user_id" v-if="task.created_by_user_id" creator />
									<attribute icon="far fa-clock" label="Teljesítve" v-if="task.completed_at">
										<color-badge
											color="success"
											icon="far fa-check"
											small
										>
											<DateTime :value="task.completed_at"/>
										</color-badge>
									</attribute>
								</div>
								<div class="mb-3">
									<label>Felhasználó</label>
									<div class="form-control-plaintext">
										<user-badge :user-id="task.assigned_to_user_id" hide-link hide-level/>
									</div>
								</div>
								<template v-if="task.transferable">
									<div class="mb-3" v-if="task.transfer_to_user_id === componentUserId">
										<label>Átadás</label>
										<div>
											<color-badge color="light" icon="far fa-random" small>
												Átadási kérés:
												<user-badge :user-id="task.assigned_to_user_id" hide-level hide-link/>
											</color-badge>

											<div class="mt-2 d-flex gap-2 p-3 bg-light">
												<button type="button" @click.prevent="transferAnswer(true)" class="btn btn-success btn-label">
													<i class="far fa-arrow-to-right label-icon"></i> Átadás elfogadása
												</button>
												<button type="button" @click.prevent="transferAnswer(false)" class="btn btn-danger btn-label">
													<i class="far fa-arrow-left label-icon"></i> Átadás elutasítása
												</button>
											</div>
										</div>
									</div>
									<data-loader :data="['userLevels']" v-slot="{ dataStore }" v-else>
										<user-select-field
											label="Átadás felhasználónak"
											name="transfer_to_user_id"
											v-model="task.transfer_to_user_id"
											:user-levels="dataStore.getData('userLevels')"
											:excluded-id="componentUserId === 'all' ? null : componentUserId"
											:errors="errors"
										/>
									</data-loader>
								</template>
							</template>
							<div v-else class="mb-3">
								<template v-if="app.hasPermission('TasksController_createForUser')">
									<switch-field
										label="Saját magam"
										name="task_modal_to_self"
										off-label="Felhasználónak"
										on-label="Magamnak"
										inline
										v-model="task.toSelf"
										field-only
									/>
									<data-loader :data="['userLevels']" v-slot="{ dataStore }" v-if="!task.toSelf">
										<user-select-field
											label="Felhasználó"
											name="assigned_to_user_id"
											v-model="task.assigned_to_user_id"
											:user-levels="dataStore.getData('userLevels')"
											:excluded-id="componentUserId"
											field-only
											:errors="errors"
											rules="required"
										/>
									</data-loader>
								</template>
								<template v-else>
									<div class="mb-3">
										<label>Felhasználó</label>
										<input type="text" readonly class="form-control-plaintext" v-model="app.userName" />
									</div>
								</template>
							</div>
							<div class="row">
								<div class="col-md-6">
									<date-field
										label="Dátum"
										name="date"
										v-model="task.date"
										:disabled="!task.editable"
										:errors="errors"
										rules="required"
										future
									></date-field>
								</div>
								<div class="col-md-6">
									<time-picker
										label="Időpont"
										name="time"
										:disabled="!task.editable"
										v-model="task.time"
										:errors="errors"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<date-field
										label="Határidő dátum"
										name="deadline_date"
										:disabled="!task.editable"
										v-model="task.deadline_date"
										:errors="errors"
										future
									></date-field>
								</div>
								<div class="col-md-6">
									<time-picker
										label="Határidő időpont"
										name="deadline_time"
										:disabled="!task.editable"
										v-model="task.deadline_time"
										:errors="errors"
									/>
								</div>
							</div>
							<template v-if="task.type === 'project' && task.model_type === 'Project'">
								<div class="mb-3">
									<label>Projekt</label>

									<div class="mb-2">
										<project-badge :project-id="projectId || task.model_id" :small="false"  :key="'PR' + (projectId || task.model_id)" />
									</div>
									<div v-if="tabs[task.details.tab] !== undefined">
										<color-badge
											color="dark"
											:small="false"
											:icon="tabs[task.details.tab].icon"
											:content="tabs[task.details.tab].label"
										/>
									</div>
								</div>
							</template>
						</div>
					</div>

					<button type="submit" ref="taskFormSubmitButton" class="d-none">submit</button>
				</template>
			</form-wrapper>
		</template>

		<template #buttons>
			<button
				type="button"
				class="btn btn-primary btn-label waves-effect"
				@click.prevent="$refs.taskFormSubmitButton.click()"
				:disabled="Object.keys(formErrors).length > 0"
				v-if="task.id === undefined || task.editable || task.transferable || task.answerable">
				<i class="fas fa-save label-icon"></i> {{ task.id === undefined ? 'Létrehozás' : 'Módosítás' }}
			</button>
			<button type="button" class="btn btn-danger btn-label" @click.prevent="confirmingDelete = true" v-if="task.deletable && !confirmingDelete"><i class="far fa-trash-alt label-icon"></i> Törlés</button>
			<button type="button" class="btn btn-dark btn-label" @click.prevent="deleteTask" v-if="confirmingDelete"><i class="far fa-trash label-icon"></i> Biztos törölni akarom</button>
			<button type="button" class="btn btn-secondary btn-label" @click.prevent="$emit('close')"><i class="far fa-times label-icon"></i> Bezárás</button>
		</template>
	</modal>
</template>

<script>
import UserBadge from "../../components/badges/UserBadge.vue";
import Attribute from "../../components/Attribute.vue";
import DateTime from "../../components/DateTime.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import Modal from "../../components/Modal.vue";
import DateField from "../../components/form/DateField.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import UserSelectField from "../../components/form/UserSelectField.vue";
import InputField from "../../components/form/InputField.vue";
import DataLoader from "../../components/DataLoader.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import TimeField from "../../components/form/TimeField.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import SwitchField from "../../components/form/SwitchField.vue";
import attribute from "../../components/Attribute.vue";
import {useProjectDataStore} from "../../stores/projectData";
import ProjectStatusIcon from "../projects/ProjectStatusIcon.vue";
import {Link} from "@inertiajs/vue3";
import CkeditorField from "../../components/form/CkeditorField.vue";
import TimePicker from "../../components/form/TimePicker.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";
import UserAttribute from "../../components/attributes/UserAttribute.vue";

export default {
	emits: ['close', 'closeAndReload'],
	props: {
		taskId: Number,
		userId: Number,
		projectId: Number,
		projectTab: {
			type: String,
			default: 'attributes'
		},
		projectDataStore: Object,
		newTaskToSelf: {
			type: Boolean,
			default: true
		}
	},
	components: {
		UserAttribute,
		ProjectBadge,
		TimePicker,
		CkeditorField,
		ProjectStatusIcon,
		SwitchField,
		TimeField,
		FormWrapper,
		DataLoader,
		InputField,
		UserSelectField,
		ColorBadge,
		DateField,
		Link,
		Modal, TextareaField, DateTime, Attribute, UserBadge},
	data(){
		const app = useApplicationStore()

		return {
			task: {
				editable: true
			},
			loaded: this.taskId === undefined || this.taskId === null,
			taskResult: '',
			confirmingDelete: false,
			formErrors: {},
			flashStore: useFlashStoreStore(),
			componentProjectDataStore: this.projectDataStore !== undefined ? this.projectDataStore : useProjectDataStore(),
			showTransferField: false,
			app: app
		}
	},
	mounted() {
		if (this.taskId !== undefined && this.taskId !== null){
			this.load()
		} else {
			this.task = {
				editable: true,
				date: window.moment(new Date()).format('YYYY-MM-DD')
			}
			if (this.newTaskToSelf) {
				this.task.assigned_to_user_id = this.componentUserId
			}
			if (this.projectId !== undefined){
				this.task.assigned_to_user_id = this.app.userId
				this.task.type = 'project'
				this.task.model_type = 'Project'
				this.task.model_id = this.projectId
				this.task.details = {
					tab: this.projectTab
				}
			}
			this.task.toSelf = this.newTaskToSelf
		}
	},
	computed: {
		attribute() {
			return attribute
		},
		modalTitle: {
			get(){
				if (!this.loaded){
					return ''
				}
				if (this.task.id === undefined){
					return 'Feladat létrehozása'
				}

				return (this.task.type === 'personal' || this.task.type === 'project') && this.task.created_by_user_id === this.app.userId ? 'Feladat szerkesztése' : 'Feladat részletek'
			}
		},
		tabs: {
			get(){
				return _.keyBy(this.componentProjectDataStore.possibleTabs, 'name')
			}
		},
		formAttributesToSave: {
			get(){
				const payload = []
				if (this.task.id === undefined || (this.task.editable !== undefined && this.task.editable)){
					payload.push('title')
					payload.push('description')
					payload.push('assigned_to_user_id')
					payload.push('date')
					payload.push('time')
					payload.push('deadline_date')
					payload.push('deadline_time')
					payload.push('toSelf')
				}
				if (this.task.id !== undefined){
					if (this.task.transferable){
						payload.push('transfer_to_user_id')
					}
					if (this.task.answerable){
						payload.push('result')
						payload.push('completed')
					}
				} else {
					if (this.projectId !== undefined){
						payload.push('model_type')
						payload.push('model_id')
						payload.push('details')
					}
				}
				return payload
			}
		},
		componentUserId: {
			get(){
				return this.userId !== undefined && this.userId ? this.userId : this.app.userId
			}
		},
		formStoreUrl: {
			get() {
				if (this.task.id === undefined){
					return '/tasks/create'
				}
				if (!this.task.editable && !this.task.transferable && !this.task.answerable){
					return ''
				}
				return '/tasks/update/' + this.task.id
			}
		}
	},
	methods: {
		load: function (){
			axios.get('/tasks/load/' + this.taskId).then((response)=>{
				this.task = response.data.task
				this.loaded = true
			}).catch((error) => {
				this.flashStore.addError(error)
				this.$emit('close')
			})
		},
		errorsChanged: function (errors){
			this.formErrors = errors
		},
		transferAnswer: function (accept){
			axios.post('/tasks/transferAnswer/' + this.task.id, {
				accept: accept
			}).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				this.app.tasks = response.data.tasks
				this.app.taskCount = response.data.taskCount
				this.$emit('closeAndReload')
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		setCompleted: function (){
			axios.post('/tasks/answer/' + this.task.id, {
				result: this.taskResult,
				setCompleted: 1
			}).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				this.app.tasks = response.data.tasks
				this.app.taskCount = response.data.taskCount
				this.$emit('closeAndReload')
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		taskSaved: function (model, response){
			this.app.tasks = response.tasks
			this.app.taskCount = response.taskCount

			this.$emit('closeAndReload')
		},
		deleteTask: function (){
			axios.delete('/tasks/delete/' + this.task.id).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				if (response.data.success){
					this.$emit('closeAndReload')
				}
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		}
	}
}
</script>
