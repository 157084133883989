<template>
	<modal
		title="Ügyfél válasz rögzítése"
		:loading="loading"
		:closable="true"
		:z-index="5000"
		@close="$emit('close')">

		<template #buttons>
			<button type="submit" class="btn btn-primary btn-label waves-effect" :disabled="!valid" @click.prevent="saveForm">
				<i class="fas fa-save label-icon"></i> Mentés
			</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
				<i class="fas fa-times label-icon"></i> Bezár
			</button>
		</template>

		<template #default>
			<div class="card" v-if="contract.id !== undefined">
				<div class="card-body">
					<h4 class="card-title mb-3">{{ contract.public_id }} - {{ contract.title_formatted }}</h4>
					<div class="details-box details-box--narrow mb-3">
						<date-time-attribute :value="contract.created_at" created>
							<user-badge :user-id="contract.created_by_user_id" />
						</date-time-attribute>

						<date-time-attribute :value="contract.generated_at" generated />

						<div>
							<i class="far fa-coins"></i> Nettó végösszeg: <strong><Price
								:value="contract.total_price_net"
								:currency-id="contract.currency_id"
						/></strong>
						</div>
						<div>
							<i class="far fa-coins"></i> Bruttó végösszeg: <strong><Price
								:value="contract.total_price_gross"
								:currency-id="contract.currency_id"
						/></strong>
						</div>
					</div>

					<RadioField
							:values="answerRadioFieldValues"
							label="Ügyfél válasza"
							name="answer"
							v-model="answer.status"
					/>

					<template v-if="answer.status !== 'rejected'">
						<template v-if="!contract.amendment_to_contract_id">
							<SwitchField
								label="Egyedi számlázás"
									name="custom_payment"
									v-model="answer.custom_payment"
									v-if="app.hasPermission('ProjectsController_enableCustomBilling')"
							/>

							<data-loader :data="['paymentMethods']" v-slot="{ dataStore }">
								<RadioField
									:values="dataStore.getData('paymentMethods')"
									label="Fizetési mód az első díjbekérőhöz"
									name="payment_method"
									ignore-color
									v-model="answer.payment_method"
								/>
							</data-loader>

							<div class="mb-3">
								<label>Fizetési konstrukció</label>

								<input type="text" readonly class="form-control-plaintext" v-model="contract.payment_plan.name" />
							</div>
						</template>
						<template v-else>
							<data-loader :data="['paymentMethods']" v-slot="{ dataStore }">
								<RadioField
									:values="dataStore.getData('paymentMethods')"
									label="Fizetési mód"
									name="payment_method"
									ignore-color
									v-model="answer.payment_method"
								/>
							</data-loader>
						</template>

						<label class="required">Aláírt szerződés csatolása</label>
						<FileUploader
								field-name="signedPages"
								v-model="answer.signedPages"
								:editable="true"
								:multiple="true"
						></FileUploader>
					</template>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import Price from "../../components/Price.vue";
import RadioField from "../../components/form/RadioField.vue";
import FileUploader from "../../components/FileUploader.vue";
import vSelect from "vue-select";
import SwitchField from "../../components/form/SwitchField.vue";
import UserBadge from "../../components/badges/UserBadge.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import {useProjectDataStore} from "../../stores/projectData";
import {useApplicationStore} from "../../stores/applicationStore";
export default {
	components: {UserBadge, SwitchField, FileUploader, RadioField, Price, vSelect},
	emits: ['close', 'closeAndReload', 'updateStats'],
	props: {
		contractId: [Number, String],
	},
	data() {
		const app = useApplicationStore()

		return {
			loading: true,
			contract: {},
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			app: app,
			paymentPlans: [],
			answer: {
				status: 'signed',
				payment_method: 'wire_transfer',
				custom_payment: false
			},
			answerRadioFieldValues: {
				signed: {
					label: 'Aláírva',
					icon: 'far fa-check',
					color: 'success'
				},
				rejected: {
					label: 'Elutasít',
					icon: 'far fa-times',
					color: 'danger'
				}
			},
		}
	},
	computed: {
		valid: {
			get() {
				if (this.answer.status === 'rejected'){
					return true
				}
				return this.answer.signedPages !== undefined && this.answer.signedPages.length
			}
		},
		paymentPlansById: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		}
	},
	mounted() {
		this.loadData()
	},
	methods: {
		loadData: function(){
			this.loading = true

			axios.get('/projects/' + this.projectDataStore.projectId + '/contractAnswerForm/' + this.contractId).then((response)=>{
				this.handleResponse(response)
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status !== undefined && response.data.status === 'success') {
				this.$emit('closeAndReload')
			}
			if (response.data.contract !== undefined) {
				this.contract = response.data.contract
			}
			if (response.data.paymentPlans !== undefined) {
				this.paymentPlans = response.data.paymentPlans
			}
			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
		},
		saveForm: function(e){
			if (this.valid){
				this.loading = true
				axios.post('/projects/saveContractAnswer/' + this.contractId, this.answer).then((response)=>{
					this.handleResponse(response)
				}).catch((error) => {
					this.loading = false
					this.flashStore.addError(error)
				})
			}
		},
		emitClose: function(){
			this.$emit('close')
		},
	}
}
</script>

<style scoped>

</style>
<script setup>
import Modal from "../../components/Modal.vue";
import DateTime from "../../components/DateTime.vue";
import InputField from "../../components/form/InputField.vue";
import UserAttribute from "../../components/attributes/UserAttribute.vue";
import DateTimeAttribute from "../../components/attributes/DateTimeAttribute.vue";
import RadioField from "../../components/form/RadioField.vue";
import DataLoader from "../../components/DataLoader.vue";
</script>
<script setup>
</script>
