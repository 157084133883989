<template>
	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-3">Ügyfél</h4>
			<div class="d-flex flex-column gap-2">
				<div><i class="far fa-user"></i> <strong>{{ projectDataStore.client.name }}</strong></div>
				<div><EmailAddress :value="projectDataStore.client.email"></EmailAddress></div>
				<div><PhoneNumber :value="projectDataStore.client.phone"></PhoneNumber></div>
			</div>
		</div>
	</div>
	<div class="d-none d-md-block">
		<div v-for="(field, fieldKey) in projectDataStore.projectType.fields_data" :key="field.id">
			<div class="card" v-if="(field.type === 'title' || field.type === 'multirow' || (projectDataStore.project.attribute_values[field.id] !== undefined && projectDataStore.project.attribute_values[field.id].id !== undefined && field.type !== 'copyButton'))
												&& (!field.private_only || (field.private_only && !projectDataStore.client.is_company))
												&& (!field.company_only || (field.company_only && projectDataStore.client.is_company))">
				<div class="card-body">
					<h4 class="card-title mb-3">{{ field.title }}</h4>
					<template v-if="projectDataStore.project.attribute_values[field.id] !== undefined">
						<div v-if="field.type === 'address'">
							<address-badge :address="projectDataStore.project.attribute_values[field.id]" />
						</div>
						<div v-else v-html="projectDataStore.project.attribute_values[field.id].formatted_value"></div>
					</template>

					<div v-if="field.type !== 'multirow' && field.children !== undefined && field.children.length">
						<template v-for="(childField, childFieldKey) in field.children" :key="childField.id">
							<div class="mb-3 mb-last-0" :data-field-id="childField.id" v-if="(projectDataStore.project.attribute_values[childField.id] !== undefined && projectDataStore.project.attribute_values[childField.id].formatted_value)
															&& (!childField.private_only || (childField.private_only && !projectDataStore.client.is_company))
															&& (!childField.company_only || (childField.company_only && projectDataStore.client.is_company))">

								<label class="mb-0">{{ childField.title }}</label>
								<template v-if="projectDataStore.project.attribute_values[childField.id] !== undefined">
									<div v-if="childField.type === 'address'">
										<address-badge :address="projectDataStore.project.attribute_values[childField.id]" />
									</div>
									<div v-else v-html="projectDataStore.project.attribute_values[childField.id].formatted_value"></div>
								</template>
							</div>
						</template>
					</div>
					<div v-if="field.type === 'multirow' && field.children !== undefined && field.children.length">
						<template v-for="(multirowAttributes, rowKey) in projectDataStore.project.attribute_values[field.id]">
							<div class="card border shadow-none">
								<div class="card-body">
									<template v-for="(childField, childFieldKey) in field.children" :key="rowKey + '_' + childField.id">
										<div class="mb-3 mb-last-0" v-if="(multirowAttributes[childField.id] !== undefined && multirowAttributes[childField.id].formatted_value)
																	&& (!childField.private_only || (childField.private_only && !projectDataStore.client.is_company))
																	&& (!childField.company_only || (childField.company_only && projectDataStore.client.is_company))">

											<label class="mb-0">{{ childField.title }}</label>
											<template v-if="projectDataStore.project.attribute_values[childField.id] !== undefined">
												<div v-if="childField.type === 'address'">
													<address-badge :address="multirowAttributes[childField.id]" />
												</div>
												<div v-else v-html="multirowAttributes[childField.id].formatted_value"></div>
											</template>
										</div>
									</template>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EmailAddress from "../../components/EmailAddress.vue";
import PhoneNumber from "../../components/PhoneNumber.vue";
import {useProjectDataStore} from "../../stores/projectData";
import AddressBadge from "../../components/badges/AddressBadge.vue";

export default {
	components: {AddressBadge, PhoneNumber, EmailAddress},
	props: {
	},
	data(){
		return {
			projectDataStore: useProjectDataStore()
		}
	}
}
</script>

<style scoped>

</style>
