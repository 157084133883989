<template>
	<div class="d-flex gap-2 flex-wrap">
		<data-loader :data="['productAttributes']" v-slot="{ dataStore }">
			<div v-for="v in values">
				<template v-if="dataStore.getData('productAttributes', true)[productAttributeId].type === 'string'">
					<color-badge
						:small="true"
						:color="dark ? 'dark' : 'light'"
						:content="v"
					></color-badge>
				</template>
				<template v-if="dataStore.getData('productAttributes', true)[productAttributeId].type === 'number'">
					<color-badge
						:small="true"
						:color="dark ? 'dark' : 'light'"
						:content="formatNumberSI(parseFloat(v)) + dataStore.getData('productAttributes', true)[productAttributeId].unit"
					></color-badge>
				</template>
				<template v-if="dataStore.getData('productAttributes', true)[productAttributeId].type === 'select'">
					<color-badge
						:small="true"
						:color="dark ? 'dark' : 'light'"
						:content="v"
						></color-badge>
				</template>
			</div>
		</data-loader>
	</div>
</template>

<script>
import {formatNumberSI} from "./../functions";
import ColorBadge from "./ColorBadge.vue";
import DataLoader from "./DataLoader.vue";

export default {
	components: {DataLoader, ColorBadge},
	methods: {
		formatNumberSI
	},
	props: {
		value: {
			type: [String, Number]
		},
		dark: Boolean,
		productAttributeId: Number,
	},
	computed: {
		values: {
			get(){
				if (this.value === undefined || this.value === null){
					return []
				}
				if (typeof this.value === 'number'){
					return [this.value]
				}
				return this.value.split('|')
			}
		}
	}
}
</script>

<style scoped>

</style>
