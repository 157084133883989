<template>
	<div class="property">
		<slot></slot>
	</div>
</template>

<script>

export default {
	data() {
		return []
	}
}
</script>

<style scoped>
</style>
