<script setup>
import ButtonGroup from "../components/ButtonGroup.vue";
import TitleBar from "../components/TitleBar.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import InquiryTableCell from "../modules/inquiries/InquiryTableCell.vue";
import TableActionCell from "../components/TableActionCell.vue";
import {Link, usePage} from "@inertiajs/vue3";
import EmailAddress from "../components/EmailAddress.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import DateTime from "../components/DateTime.vue";
import DataLoader from "../components/DataLoader.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {useGeneralDataStore} from "../stores/generalData";
import {useApplicationStore} from "../stores/applicationStore";
import {ref} from "vue";
import Number from "../components/Number.vue";
import ColorBadge from "../components/ColorBadge.vue";
import Attribute from "../components/Attribute.vue";
import ProjectBadge from "../components/badges/ProjectBadge.vue";
import AddressAttribute from "../components/attributes/AddressAttribute.vue";
import AddressBadge from "../components/badges/AddressBadge.vue";

const columns = [
	{
		text: 'Ügyfél',
		value: 'clientName',
		sortable: true
	},
	{
		text: 'Termékek',
		value: 'returnQuantity',
		align: 'right',
		sortable: true
	},
	{
		text: 'Keletkezés',
		value: 'signed_at',
		sortable: true
	},
	{
		text: '',
		value: 'operations',
		width: 5,
	}
]
const dataStore = useGeneralDataStore()
const app = useApplicationStore()
const defaultFilters = ref({})
</script>

<template>
	<AppLayout>
		<TitleBar title="Kintlévő készlet" />

		<AdvancedDataTable
			data-url="/inventoryReturns/list"
			:columns="columns"
			sort-by="public_id"
			sort-type="desc"
			name="inquiries"
			:default-filters="defaultFilters"
			v-if="dataStore.dataLoaded('inquiryStatusLabels')"
			show-tracked-edit-mode
		>
			<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
				<div class="operations-wrapper">
					<div class="mobile-cell">
						<div class="stacked-cell">
							<strong>{{ item.clientName }}</strong>

							<address-attribute :address="item" />

							<div>
								<project-badge
									:project-id="item.project_id"
									:contract-id="item.id"
									:project-type-id="item.project_type_id"
									:key="'PR' + item.project_id + '_C_' + item.id"
								>
									{{ item.public_id }}
								</project-badge>
							</div>
							<attribute icon="far fa-clock">
								<date-time :value="item.signed_at"/>
							</attribute>

							<color-badge color="light" >
								Termékek: <number :value="item.returnQuantity" />
							</color-badge>
						</div>
					</div>
					<div class="table-row-operations d-flex gap-2">
						<Link :href="route('inventoryReturns.details', item.project_id)"
							  class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="far fa-eye"></i>
						</Link>
					</div>
				</div>
			</template>

			<template #item-clientName="item">
				<div class="stacked-cell">
					<strong>{{ item.clientName }}</strong>

					<address-badge :address="item" />

					<div>
						<project-badge
							:project-id="item.project_id"
							:contract-id="item.id"
							:project-type-id="item.project_type_id"
							:key="'PR' + item.project_id + '_C_' + item.id"
						>
							{{ item.public_id }}
						</project-badge>
					</div>
				</div>
			</template>
			<template #item-returnQuantity="item">
				<color-badge color="light" >
					<number :value="item.returnQuantity" />
				</color-badge>
			</template>
			<template #item-signed_at="item">
				<date-time :value="item.signed_at"/>
			</template>
		</AdvancedDataTable>
	</AppLayout>
</template>

<style scoped>

</style>
