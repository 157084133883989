<template>
	<div v-if="app.notifications.length" class="mb-4">
		<template v-for="(notification, notificationKey) in app.clientNotifications" :key="notificationKey">
			<div class="card d-flex flex-row align-items-center p-3 mb-2" v-if="notificationKey < 2 || showAll">
				<i :class="notification.icon + ' text-' + notification.color + ' me-3 text-center'" style="font-size:34px; width:50px;"></i>
				<div>
					<strong>{{ notification.title }}</strong>
					<div v-html="notification.description"></div>
				</div>
				<div class="ms-auto">
					<Link :href="'/clientArea/notifications/redirect/' + notification.id" class="btn btn-primary">{{ notification.link_label }}</Link>
				</div>
			</div>
		</template>
	</div>
	<div class="mt-1 mb-4 d-flex justify-content-center" v-if="app.clientNotifications.length > 2">
		<button class="btn btn-secondary btn-label" @click="showAll = !showAll" v-if="!showAll"><i class="fas fa-eye label-icon"></i> Összes értesítés mutatása</button>
		<button class="btn btn-secondary btn-label" @click="showAll = !showAll" v-else><i class="fal fa-eye label-icon"></i> Értesítések elrejtése</button>
	</div>
</template>

<script>
import RelativeTime from "./RelativeTime.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link, usePage} from "@inertiajs/vue3";

export default {
	components: {RelativeTime, Link},
	data(){
		return {
			app: useApplicationStore(),
			page: usePage(),
			showAll: false
		}
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
