<template>
	<TitleBar title="Bónuszok" />

	<AdvancedDataTable
		:data-url="route('userBonuses.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="userBonuses"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('UserBonusesController_create')">
			<Link :href="route('userBonuses.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<color-badge :value="item"></color-badge>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('userBonuses.update')"
					:delete-url="route('userBonuses.delete')"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
		<template #item-label="item">
			<color-badge :value="item"></color-badge>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TableActionCell from "../components/TableActionCell.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import ColorBadge from "../components/ColorBadge.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import TitleBar from "../components/TitleBar.vue";

export default {
	components: {TitleBar, ColorBadge, Link, AdvancedDataTable, TableActionCell},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
			},
			{
				text: 'Cimke',
				value: 'label',
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
