<script setup>
import ColorBadge from "../ColorBadge.vue";
import Price from "../Price.vue";

const model = defineModel()
const props = defineProps({
	net: Boolean,
	gross: Boolean,
	sale: Boolean,
	currencyId: Number,
	acquisition: Boolean,
	color: {
		type: String,
		default: 'light'
	},
	small: {
		type: Boolean,
		default: true
	},
})
</script>

<template>
	<color-badge
		:color="color"
		icon="far fa-coins"
		:small="small"
		>
		<span v-if="sale" class="font-size-11">Elad. <span v-if="net">N</span><span v-else>B</span></span>
		<span v-else-if="acquisition" class="font-size-11">Beszerz. <span v-if="net">N</span><span v-else>B</span></span>
		<span v-else-if="net" class="font-size-11">Nettó</span>
		<span v-else-if="gross" class="font-size-11">Bruttó</span>
		<price :value="model" :currency-id="currencyId" />
	</color-badge>
</template>

<style scoped>

</style>
