<template>
	<TitleBar title="Telepítések" />

	<AdvancedDataTable
		:data-url="'/projects/installations'"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		name="installations"
	>
		<template #filters="{serverOptions}">
			<div class="data-table-filter-item">
				<project-type-select-field
					v-model="serverOptions.project_type_id"
					name="project_type_id"
					label="Típus"
					:field-only="true"
				></project-type-select-field>
			</div>
			<div class="data-table-filter-item">
				<select-field
					v-model="serverOptions.status"
					name="status"
					label="Állapot"
					:field-only="true"
					rules="required"
					:options="[{'id': 'open', 'name': 'Nyitott'},{'id': 'all', 'name': 'Összes'}]"
				></select-field>
			</div>
		</template>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.clientName }}</strong>
						<div>{{ item.address }}</div>
					</div>

					<div class="stacked-cell">
						<div>{{ item.public_id }}</div>

						<attribute v-if="item.handlerUserName" icon="far fa-user">
							{{ item.handlerUserName }}
						</attribute>
						<attribute v-if="item.installation_date" icon="far fa-clock" label="Telepítés dátuma">
							<date-time :value="item.installation_date" :time="false"/>
						</attribute>

						<div class="mobile-horizontal">
							<ProjectTypeCell
								:data="item"
							></ProjectTypeCell>

							<color-badge
								:color="item.closed_at ? 'success' : 'warning'"
								:icon="item.closed_at ? 'far fa-check' : 'far fa-question'"
								:content="item.closed_at ? 'Lezárva' : 'Függőben'"
							/>
						</div>

						<span :class="{
							'badge': 1,
							'badge-xl': 1,
							'bg-success': parseInt(item.contractQuantity) <= parseInt(item.deliveredQuantity),
							'bg-warning': 0 < parseInt(item.deliveredQuantity),
							'bg-danger': 1 > parseInt(item.deliveredQuantity)}">
							Kiszállított termékek: {{ item.deliveredQuantity + ' / ' + item.contractQuantity}}
						</span>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('installations.update')"
					:is-deletable="false"
					v-slot="{ row }"
				>
				</TableActionCell>
			</div>
		</template>
		<template #item-closed_at="item">
			<color-badge
				:color="item.closed_at ? 'success' : 'warning'"
				:icon="item.closed_at ? 'far fa-check' : 'far fa-question'"
				:content="item.closed_at ? 'Lezárva' : 'Függőben'"
				:small="true"
				/>
		</template>
		<template #item-project="item">
			<div class="stacked-cell">
				<div>{{ item.public_id }}</div>
				<div v-if="item.handlerUserName"><i class="far fa-user"></i> {{ item.handlerUserName }}</div>
				<ProjectTypeCell
					:data="item"
					:small="true"
				></ProjectTypeCell>
			</div>
		</template>
		<template #item-client="item">
			<div class="stacked-cell">
				<strong>{{ item.clientName }}</strong>
				<div>{{ item.address }}</div>
			</div>
		</template>
		<template #item-created_at="item">
			<date-time :value="item.created_at"/>
		</template>
		<template #item-installation_date="item">
			<date-time :value="item.installation_date" :time="false"/>
		</template>
		<template #item-delivered_products="item">
			<span :class="{
				'badge': 1,
				'badge-xl': 1,
				'bg-success': parseInt(item.contractQuantity) <= parseInt(item.deliveredQuantity),
				'bg-warning': 0 < parseInt(item.deliveredQuantity),
				'bg-danger': 1 > parseInt(item.deliveredQuantity)}">
				{{ item.deliveredQuantity + ' / ' + item.contractQuantity}}
			</span>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import {Link} from "@inertiajs/vue3";
import TitleBar from "../components/TitleBar.vue";
import TableActionCell from "../components/TableActionCell.vue";
import ProjectTypeCell from "../modules/projects/ProjectTypeCell.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import ProjectTypeSelectField from "../components/form/ProjectTypeSelectField.vue";
import DateTime from "../components/DateTime.vue";
import {useApplicationStore} from "../stores/applicationStore";
import UserBadge from "../components/badges/UserBadge.vue";
import ColorBadge from "../components/ColorBadge.vue";
import Number from "../components/Number.vue";
import Attribute from "../components/Attribute.vue";
import ProjectInstallationList from "../modules/projects/ProjectInstallationList.vue";
import SelectField from "../components/form/SelectField.vue";

export default {
	layout: AppLayout,
	components: {
		SelectField,
		ProjectInstallationList,
		Attribute,
		Number, ColorBadge,
		UserBadge,
		DateTime, ProjectTypeSelectField, AdvancedDataTable, ProjectTypeCell, TableActionCell, TitleBar, Link},
	data(){
		let columns = [
			{
				text: 'Projekt',
				value: 'project'
			},
			{
				text: 'Ügyfél',
				value: 'client'
			},
			{
				text: 'Állapot',
				value: 'closed_at',
				sortable: true,
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true
			},
			{
				text: 'Telepítés dátuma',
				value: 'installation_date',
				sortable: true,
			},
			{
				text: 'Kiszállított termékek',
				value: 'delivered_products'
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
