<template>
	<TitleBar :title="attributes.id !== undefined ? 'Felhasználó módosítása' : 'Felhasználó hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="profileForm ? route('users.profileStore') : route('users.store', model.id)"
		:back-url="route('users.index')"
		:tabs="tabs"
		:display-tabs="!!attributes.id"
		v-model="attributes"
		:hidden-attributes="['roles']"
		:show-back-button="!profileForm"
	>

		<template v-slot:top>
			<div class="row-flex" v-if="attributes.id !== undefined">
				<div class="col-md-6">
					<div class="card">
						<div class="card-body">
							<div class="details-box details-box--md">
								<updated-attribute :data="attributes" created/>
								<div v-if="attributes.referer_user_id">
									<i class="far fa-users"></i> Ajánló: <UserBadge :user-id="attributes.referer_user_id"></UserBadge>
								</div>
								<updated-attribute :data="attributes" updated/>
								<div v-if="attributes.approved_by_user_id">
									<i class="far fa-user"></i> Jóváhagyta: <UserBadge :user-id="attributes.approved_by_user_id"></UserBadge>
								</div>
								<div v-if="attributes.approved_by_user_id">
									<i class="far fa-calendar-alt"></i> Jóváhagyva: <strong><date-time :value="attributes.approved_at"/></strong>
								</div>
								<div>
									<i class="far fa-calendar-alt"></i> Utolsó belépés: <strong>
									<date-time v-if="attributes.last_login" :value="attributes.last_login"/>
									<template v-else>
										Még nem lépett be
									</template>
								</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card">
						<div class="card-body">
							<div class="details-box details-box--md">
								<div>
									<i class="fas fa-user"></i> Ajánlói link magánszemély: <a :href="consumerRefererLink" target="_blank">[link]</a> <CopyToClipboard :value="consumerRefererLink" :left-margin="false" />
								</div>
								<div>
									<i class="fas fa-industry"></i> Ajánlói link céges: <a :href="companyRefererLink" target="_blank">[link]</a> <CopyToClipboard :value="companyRefererLink" :left-margin="false" />
								</div>
								<div>
									<i class="fas fa-users"></i> Munkatársi ajánlói link: <a :href="userRefererLink" target="_blank">[link]</a> <CopyToClipboard :value="userRefererLink" :left-margin="false" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div v-if="activeTab === 'form'">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title mb-4">Adatok</h4>
						<div class="row">
							<div class="col-sm-6">
								<div class="mb-3" v-if="attributes.awaiting_approval">
									<div class="alert alert-warning">
										<h4 class="alert-heading">Nem jóváhagyott felhasználó</h4>
										<p>
											Amennyiben megfelelőnek találod az új felhasználót, hagyd jóvá a regisztrációt, ellenkező esetben utasítsd azt el.
										</p>
									</div>
									<div class="d-flex gap-2 flex-wrap">
										<Link :href="route('users.storeApproval', model.id) + '?action=approve'" v-if="attributes.awaiting_approval && app.hasPermission('UsersController_canApproveUser')" class="btn btn-success btn-label"><i class="far fa-check label-icon"></i> Felhasználó jóváhagyása</Link>
										<Link :href="route('users.storeApproval', model.id) + '?action=deny'" v-if="attributes.awaiting_approval && app.hasPermission('UsersController_canApproveUser')" class="btn btn-danger btn-label"><i class="far fa-times label-icon"></i> Felhasználó elutasítása</Link>
									</div>
								</div>
								<switch-field
									label="Aktív"
									name="active"
									v-model="attributes.active"
									:disabled="!editable"
									v-if="!profileForm"
								/>

								<data-loader :data="['userTypes']" v-slot="{ dataStore }" v-if="editable && !profileForm">
									<radio-field
										label="Típus"
										name="type"
										v-model="attributes.type"
										:values="dataStore.getData('userTypes')"
										:errors="errors"
										rules="required"
										:display-badge="true"
										data-type="string"
									>
									</radio-field>
								</data-loader>

								<input-field
									label="Vezetéknév"
									name="last_name"
									rules="required"
									v-model="attributes.last_name"
									:disabled="!editable"
									:errors="errors"
								/>

								<input-field
									label="Keresztnév"
									name="first_name"
									rules="required"
									v-model="attributes.first_name"
									:disabled="!editable"
									:errors="errors"
								/>

								<data-loader :data="['roles']" v-slot="{ dataStore }" v-if="!profileForm">
									<select-field
										label="Szerepek"
										name="roleIdArray"
										v-model="attributes.roleIdArray"
										:disabled="!editable"
										:options="dataStore.getData('roles')"
										:multiple="true"
										:errors="errors"
									/>
								</data-loader>

								<radio-field
									label="E-mail értesítés"
									name="notification_email_level"
									rules="required"
									v-model="attributes.notification_email_level"
									:disabled="!editable"
									:errors="errors"
									:values="{all: {label: 'Összes'},important: {label: 'Fontos'},none: {label: 'Nincs'}}"
								/>

								<switch-field
									label="Telepítő"
									name="installer"
									v-model="attributes.installer"
									:disabled="!editable"
									v-if="!profileForm"
								/>

								<warehouse-select-field
									label="Raktár"
									name="warehouse_id"
									rules="required"
									v-model="attributes.warehouse_id"
									:disabled="!editable"
									:errors="errors"
									v-if="!profileForm && attributes.installer"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									label="E-mail cím"
									name="email"
									data-type="email"
									rules="required"
									v-model="attributes.email"
									:disabled="!editable"
									:errors="errors"
								/>

								<phone-field
									label="Telefonszám"
									name="phone"
									rules="required"
									v-model="attributes.phone"
									:disabled="!editable"
									:errors="errors"
								/>

								<input-field
									label="Cégnév"
									name="company_name"
									v-model="attributes.company_name"
									rules="required"
									:disabled="!editable"
									:errors="errors"
									v-if="['company'].indexOf(attributes.type) > -1"
								/>

								<input-field
									label="Adószám"
									name="tax_number"
									v-model="attributes.tax_number"
									rules="required"
									:disabled="!editable"
									:errors="errors"
									v-if="['independentContractor', 'company'].indexOf(attributes.type) > -1"
								/>

								<input-field
									label="Cégjegyzékszám"
									name="company_id"
									v-model="attributes.company_id"
									rules="required"
									:disabled="!editable"
									:errors="errors"
									v-if="['company'].indexOf(attributes.type) > -1"
								/>

								<input-field
									label="Bankszámlaszám"
									name="bank_account_id"
									v-model="attributes.bank_account_id"
									:disabled="!editable"
									:errors="errors"
									v-if="['independentContractor', 'company'].indexOf(attributes.type) > -1"
								/>

								<template v-if="attributes.id !== undefined">
									<input-field
										label="Új jelszó"
										name="password"
										data-type="password"
										rules="min:8"
										v-model="attributes.password"
										:disabled="!editable"
										info="Csak akkor töltsd ki, ha új jelszót akarsz beállítani"
										:errors="errors"
									/>

									<input-field
										label="Új jelszó újra"
										name="password_confirmation"
										data-type="password"
										:equal-to="attributes.password"
										v-model="attributes.password_confirmation"
										:disabled="!editable"
										info="Csak akkor töltsd ki, ha új jelszót akarsz beállítani"
										:errors="errors"
									/>
								</template>
								<template v-else>
									<input-field
										label="Jelszó"
										name="password"
										data-type="password"
										rules="min:8|required"
										v-model="attributes.password"
										:disabled="!editable"
										:errors="errors"
										autocomplete="new-password"
									/>

									<input-field
										label="Jelszó újra"
										name="password_confirmation"
										data-type="password"
										rules="required"
										:equal-to="attributes.password"
										v-model="attributes.password_confirmation"
										:disabled="!editable"
										:errors="errors"
										autocomplete="new-password"
									/>
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-if="['independentContractor', 'company'].indexOf(attributes.type) > -1">
					<div class="card-body">
						<h4 class="card-title mb-4">Cím</h4>
						<address-input
							v-model="attributes.address"
							attribute-name="address"
							:editable="editable"
							v-if="attributes.address.id"
							:required="true"
							:errors="errors"
						/>
						<div v-else>
							Nincs megadva
						</div>
					</div>
				</div>
				<div class="card" v-if="userLevelsPermission || (profileForm && attributes.user_levels_enabled)">
					<div class="card-body">
						<h4 class="card-title mb-4">Karrier</h4>
						<div class="row">
							<div class="col-sm-6">
								<template v-if="profileForm">
									<div class="mb-3">
										<h4>Felhasználói szint</h4>
										<user-level-badge :user-level-id="attributes.user_level_id"></user-level-badge>
									</div>
								</template>
								<template v-if="userLevelsPermission && !profileForm">
									<data-loader :data="['userLevels']" v-slot="{ dataStore }">
										<user-select-field
											label="Felettes"
											name="superior_user_id"
											v-model="attributes.superior_user_id"
											:disabled="!editable"
											:user-levels="dataStore.getData('userLevels')"
											:excluded-id="attributes.id"
											:errors="errors"
										/>
									</data-loader>
									<switch-field
										label="Felhasználói szintek engedélyezése"
										name="user_levels_enabled"
										v-model="attributes.user_levels_enabled"
										:disabled="!editable"
										v-if="displayUserLevelsEnableCheckbox"
									/>
									<template v-if="attributes.user_levels_enabled">
										<switch-field
											label="Fix felhasználói szint"
											name="fixed_user_level"
											v-model="attributes.fixed_user_level"
											:disabled="!editable"
										/>
										<template v-if="!attributes.fixed_user_level">
											<div v-if="attributes.user_level_id" class="mb-3">
												<h4>Felhasználói szint</h4>
												<user-level-badge :user-level-id="attributes.user_level_id"></user-level-badge>
											</div>
										</template>

										<data-loader :data="['userLevels']" v-slot="{ dataStore }" v-else>
											<select-field
												label="Felhasználói szint"
												label-field="name"
												name="user_level_id"
												rules=""
												v-model="attributes.user_level_id"
												:disabled="!editable"
												:options="dataStore.getData('userLevels')"
												:errors="errors"
											/>
										</data-loader>
									</template>
								</template>
							</div>
						</div>
					</div>
				</div>
				<discount-limits v-model="attributes" :editable="editable" :errors="errors" />
			</div>
			<div v-if="activeTab === 'commissions'">
				<div class="card">
					<div class="card-body">
						<UserCommissionList
							:user-id="model.id" />
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'payments'">
				<div class="card">
					<div class="card-body">
						<UserPaymentList
							:user-id="model.id"
							:unpaid-commission="unpaidCommission"
						/>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'sales'">
				<div class="card">
					<div class="card-body">
						<UserSaleList
							:user-id="model.id"
						/>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'pendingCommissions'">
				<div class="card">
					<div class="card-body">
						<UserPendingCommissionList
							:user-id="model.id"
						/>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'levelLog'">
				<div class="card">
					<div class="card-body">
						<UserLevelLogList
							:user-id="model.id"
							:store-url="profileForm ? route('users.profileStore') : route('users.store', model.id)"
							:user-levels-permission="userLevelsPermission"
						/>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>


<script>
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import SelectField from "../components/form/SelectField.vue";
import UserPendingCommissionList from "../modules/users/UserPendingCommissionList.vue";
import DataLoader from "../components/DataLoader.vue";
import SwitchField from "../components/form/SwitchField.vue";
import CopyToClipboard from "../components/CopyToClipboard.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import UserSelectField from "../components/form/UserSelectField.vue";
import UserSaleList from "../modules/users/UserSaleList.vue";
import UserLevelLogList from "../modules/users/UserLevelLogList.vue";
import FormWrapper from "../components/FormWrapper.vue";
import AddressInput from "../components/AddressInput.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import UserCommissionList from "../modules/users/UserCommissionList.vue";
import InputField from "../components/form/InputField.vue";
import UserPaymentList from "../modules/users/UserPaymentList.vue";
import RadioField from "../components/form/RadioField.vue";
import UserLevelBadge from "../components/UserLevelBadge.vue";
import DateTime from "../components/DateTime.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import PhoneField from "../components/form/PhoneField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import AddressBadge from "../components/badges/AddressBadge.vue";
import DiscountLimits from "../components/DiscountLimits.vue";

export default {
	layout: AppLayout,
	components: {
		DiscountLimits,
		AddressBadge,
		UpdatedAttribute,
		PhoneField,
		DateTime,
		UserLevelBadge,
		RadioField,
		UserPaymentList,
		InputField,
		UserCommissionList,
		WarehouseSelectField,
		AddressInput,
		FormWrapper,
		UserLevelLogList,
		UserSaleList,
		UserSelectField,
		UserBadge,
		CopyToClipboard,
		SwitchField,
		DataLoader,
		UserPendingCommissionList,
		SelectField,
		TitleBar,
		Link
	},
	props: {
		model: Object,
		pendingReward: Object,
		superiors: Array,
		unpaidCommission: Number,
		tab: String,
		userLevelsPermission: Boolean,
		consumerRefererLink: String,
		companyRefererLink: String,
		userRefererLink: String,
		profileForm: Boolean,
	},
	data(){
		let attributes = this.model
		attributes.roleIdArray = []
		_.forEach(attributes.roles, function(value){
			attributes.roleIdArray.push(value.id)
		})

		if (attributes.address === null){
			attributes.address = {}
		}

		let tabs = [{
			label: 'Adatlap',
			icon: 'far fa-file-spreadsheet',
			name: 'form'
		}
		]

		if (attributes.awaiting_approval === undefined || !attributes.awaiting_approval) {
			if (attributes.user_levels_enabled) {
				tabs.push({
					label: 'Jutalékok',
					icon: 'far fa-file-invoice-dollar',
					name: 'commissions',
				})
				tabs.push({
					label: 'Kifizetések',
					icon: 'far fa-money-bill-wave',
					name: 'payments',
				})
				tabs.push({
					label: 'Függőben lévő jutalékok',
					icon: 'far fa-hourglass-half',
					name: 'pendingCommissions',
				})
			}
			tabs.push({
				label: 'Eladások',
				icon: 'far fa-file-contract',
				name: 'sales',
			})
			if (attributes.user_levels_enabled) {
				tabs.push({
					label: 'Szintek',
					icon: 'far fa-level-up',
					name: 'levelLog',
				})
			}
		}

		return {
			attributes: attributes,
			loading: false,
			flash: [],
			app: useApplicationStore(),
			displayUserLevelsEnableCheckbox: !attributes.user_levels_enabled,
			tabs: tabs
		}
	},
}
</script>
