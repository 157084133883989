<script setup>
import TableActionCell from "../components/TableActionCell.vue";
import {Link} from "@inertiajs/vue3";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import TitleBar from "../components/TitleBar.vue";
import {useApplicationStore} from "../stores/applicationStore";
import AppLayout from "../layouts/AppLayout.vue";
import SaleStatusBadge from "../components/badges/SaleStatusBadge.vue";
import ClientBadge from "../components/badges/ClientBadge.vue";
import Price from "../components/Price.vue";
import MoneyBadge from "../components/badges/MoneyBadge.vue";
import SaleBadge from "../components/badges/SaleBadge.vue";
import DateTime from "../components/DateTime.vue";
import ProjectTypeSelectField from "../components/form/ProjectTypeSelectField.vue";
import ColorBadge from "../components/ColorBadge.vue";
import vSelect from "vue-select";
import SelectField from "../components/form/SelectField.vue";
import DataLoader from "../components/DataLoader.vue";
import SaleStatusSelectField from "../components/form/SaleStatusSelectField.vue";
import ClientSelect from "../components/form/ClientSelect.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import Number from "../components/Number.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import WarehouseBadge from "../components/badges/WarehouseBadge.vue";
import Mobile from "../components/responsive/Mobile.vue";

const columns = [
	{
		text: 'Infó',
		value: 'created_at',
		sortable: true
	},
	{
		text: 'Ügyfél',
		value: 'clientName',
		sortable: false
	},
	{
		text: 'Raktár',
		value: 'warehouse_id',
		sortable: false
	},
	{
		text: 'Termékek',
		value: 'productCount',
		align: 'right',
		sortable: true
	},
	{
		text: 'Végösszeg',
		value: 'total_price_gross',
		align: 'right',
		sortable: true
	},
	{
		label: '',
		value: 'operations',
		width: 5,
	}
]

const app = useApplicationStore()
</script>

<template>
	<AppLayout>
		<TitleBar title="Eladások" />

		<AdvancedDataTable
			:data-url="route('sales.list')"
			:columns="columns"
			sort-by="name"
			sort-type="asc"
			name="sales"
			controls-in-navigation
		>

			<template #filters="{serverOptions}">
				<div class="data-table-filter-item data-table-filter-item-25">
					<sale-status-select-field
						v-model="serverOptions.status"
						label="Állapot"
						:field-only="true"
						name="status"
					/>
				</div>
				<div class="data-table-filter-item data-table-filter-item-25">
					<client-select
						v-model="serverOptions.client_id"
						label="Ügyfél"
						:field-only="true"
						name="client_id"
					/>
				</div>
				<div class="data-table-filter-item data-table-filter-item-25">
					<warehouse-select-field
						v-model="serverOptions.warehouse_id"
						label="Raktár"
						:field-only="true"
						name="warehouse_id"
					/>
				</div>
			</template>

			<template #navigation v-if="app.hasPermission('SalesController_create')">
				<Link :href="route('sales.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
			</template>
			<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
				<div class="operations-wrapper">
					<mobile>
						<div class="mobile-cell">
							<div class="stacked-cell">
								<div class="horizontal">
									<sale-badge v-model="item.id" :public-id="item.public_id" />
									<sale-status-badge v-model="item.status" />
								</div>
								<updated-attribute :data="item" created hide-user/>
								<warehouse-badge :warehouse-id="item.warehouse_id" />
								<client-badge :client-id="item.client_id" :public-id="item.clientPublicId" :name="item.clientName" />
								<money-badge v-model="item.total_price_net" net />
								<money-badge v-model="item.total_price_gross" gross />
							</div>
						</div>
					</mobile>
					<TableActionCell
						:data="item"
						:edit-url="route('sales.update')"
						:delete-url="route('sales.delete')"
						:is-deletable="true"
						:emit-delete-event="true"
						@deleteButtonPressed="deleteButtonPressed"
						:tracked-edit-mode="trackedEditMode"
					></TableActionCell>
				</div>
			</template>

			<template #item-created_at="item">
				<div class="vertical">
					<div class="horizontal">
						<sale-badge v-model="item.id" :public-id="item.public_id" />
						<sale-status-badge v-model="item.status" />
					</div>
					<updated-attribute :data="item" created hide-user/>
				</div>
			</template>

			<template #item-productCount="item">
				<number :value="item.productCount"/>
			</template>

			<template #item-warehouse_id="item">
				<warehouse-badge :warehouse-id="item.warehouse_id" />
			</template>

			<template #item-total_price_gross="item">
				<div class="vertical align-items-end">
					<money-badge v-model="item.total_price_net" net />
					<money-badge v-model="item.total_price_gross" gross />
				</div>
			</template>

			<template #item-clientName="item">
				<client-badge :client-id="item.client_id" :public-id="item.clientPublicId" :name="item.clientName" />
			</template>
		</AdvancedDataTable>
	</AppLayout>
</template>

<style scoped>

</style>
