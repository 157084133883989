<template>
	<TitleBar :title="attributes.id !== undefined ? 'Hír módosítása' : 'Hír hozzáadása'" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('posts.store', attributes)"
		:back-url="route('posts.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>

					<switch-field
						label="Publikus"
						name="is_public"
						v-model="attributes.is_public"
						/>

					<div class="row">
						<div class="col-md-6">
							<input-field
								label="Cím"
								name="title"
								rules="required|max:200"
								v-model="attributes.title"
								:errors="errors"
							/>
						</div>
						<div class="col-md-6">
							<data-loader :data="['roles']" v-slot="{ dataStore }">
								<select-field
									label="Szerepek"
									name="roleIdArray"
									rules="required"
									v-model="attributes.requiredRoleIdArray"
									:options="dataStore.getData('roles')"
									:multiple="true"
									:errors="errors"
								/>
							</data-loader>
						</div>
					</div>
					<div class="mb-3">
						<textarea-field
							label="Lead"
							name="lead"
							v-model="attributes.lead"
							:rows="3"
						/>
					</div>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Tartalom</h4>
					<ckeditor-field
						name="content"
						v-model="attributes.content"
					></ckeditor-field>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Dokumentumok</h4>
					<FileUploader
						field-name="document"
						v-model="attributes.documents"
						file-type="document"
						:editable="true"
					></FileUploader>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Képek</h4>
					<FileUploader
						field-name="image"
						v-model="attributes.images"
						file-type="image"
						:editable="true"
					></FileUploader>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import {Head} from "@inertiajs/vue3";
import SelectField from "../components/form/SelectField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import TextareaField from "../components/form/TextareaField.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import FileUploader from "../components/FileUploader.vue";
import Ckeditor from "../ckeditor";
import TitleBar from "../components/TitleBar.vue";
import SwitchField from "../components/form/SwitchField.vue";
import CkeditorField from "../components/form/CkeditorField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	props: {
		model: Object
	},
	layout: AppLayout,
	components: {
		UpdatedAttribute,
		CkeditorField,
		SwitchField,
		TitleBar,
		FileUploader, DataLoader, InputField, TextareaField, FormWrapper, SelectField, Head, AppLayout},
	data(){
		let attributes = _.clone(this.model)
		attributes.requiredRoleIdArray = []
		_.forEach(attributes.required_roles, function(value){
			attributes.requiredRoleIdArray.push(value.id)
		})
		if (attributes.content === null){
			attributes.content = ''
		}
		return {
			attributes: attributes,
		}
	},
	mounted() {
	},
}
</script>

<style scoped>

</style>
