<script setup>
import {onMounted, onUnmounted, reactive, ref, toRefs} from "vue";

const visible = ref(false)
const browserResized = () => {
	visible.value = isMobile()
}

const isMobile = () => {
	return window.innerWidth <= 768
}

onMounted(() => {
	window.addEventListener('resize', browserResized)

	browserResized()
})

onUnmounted(() => {
	window.removeEventListener('resize', browserResized)
})
</script>

<template>
	<template v-if="visible">
		<slot></slot>
	</template>
</template>

<style scoped>

</style>
