<template>
	<div class="d-flex align-items-center">
		<form>
			<div class="position-relative">
				<div class="input-group main-search-input">
					<span class="input-group-text"><i class="far fa-search"></i></span>
					<input type="text" class="form-control" placeholder="Keresés" v-model="query" @focus="dropdownVisible = true" @blur="onBlur" ref="searchInput" @keydown="keyDown">
					<button class="btn btn-secondary btn-clear" type="button" v-if="query.length > 1" @click.prevent="query = ''"><i class="far fa-times"></i></button>
				</div>

				<div :class="{'dropdown-menu': 1, 'search-results': 1, 'show': dropdownVisible && query.length > 1, 'loading-absolute': loading, 'loading--light': 1, 'p-2': !results.length && !loading}">
					<div style="max-height: 500px;overflow-y:scroll;">
						<div class="search-results-list">
							<div class="alert alert-danger m-0" v-if="!results.length && !loading">Nincs találat!</div>
							<template v-for="(result, resultKey) in results" :key="result.type + '_' + result.id">
								<div :class="{'search-result': 1, 'search-result-client': 1}" v-if="result.type === 'client'">
									<div :class="{'search-result-details': 1, 'search-result-highlighted' : isHighlighted(resultKey)}" :ref="'result_' + getIndex(resultKey)">
										<div class="d-flex gap-2">
											<Property>
												<color-badge
													color="warning"
													icon="fas fa-address-book"
													:content="result.public_id"
													:small="true"
												></color-badge>
											</Property>
											<Property>
												<i class="far fa-user"></i> {{ result.name }}
											</Property>
										</div>
										<Link :href="result.url" @click="linkClicked"></Link>
									</div>

									<div class="search-result-client--projects" v-if="result.projects.length">
										<div class="search-result search-result-project" v-for="(project, projectKey) in result.projects" :key="project.id">
											<div :class="{'search-result-details-compact': 1, 'search-result-highlighted' : isHighlighted(resultKey, projectKey)}" :ref="'result_' + getIndex(resultKey, projectKey)">

												<Property>
													<color-badge
														color="primary"
														icon="fas fa-project-diagram"
														:content="project.public_id"
														:small="true"
													></color-badge>

													<color-badge
														color="primary"
														icon="fas fa-power-off"
														:small="true"
														v-if="!project.closed_at && !project.postponed_until"
													></color-badge>
													<color-badge
														color="warning"
														icon="fas fa-clock"
														:small="true"
														v-else-if="!project.closed_at && project.postponed_until"
													></color-badge>
													<color-badge
														color="success"
														icon="fas fa-check"
														:small="true"
														v-else
													></color-badge>

													<color-badge
														:color="project.projectType.color"
														:icon="project.projectType.icon"
														:luminosity="project.projectType.luminosity"
														:content="project.projectType.name"
														:small="true"
													></color-badge>
												</Property>
												<Property>
													<i class="fas fa-map-marker"></i> {{ project.address }}
												</Property>
												<Link :href="project.url" @click="linkClicked"></Link>
											</div>
										</div>
									</div>
								</div>
								<div class="search-result search-result-project" v-if="result.type === 'project'">
									<div :class="{'search-result-details': 1, 'search-result-highlighted' : isHighlighted(resultKey)}" :ref="'result_' + getIndex(resultKey)">
										<Property>
											<color-badge
												color="primary"
												icon="fas fa-project-diagram"
												:content="result.public_id"
												:small="true"
											></color-badge>

											<color-badge
												color="primary"
												icon="fas fa-power-off"
												:small="true"
												v-if="!result.closed_at && !result.postponed_until"
											></color-badge>
											<color-badge
												color="warning"
												icon="fas fa-clock"
												:small="true"
												v-else-if="!result.closed_at && result.postponed_until"
											></color-badge>
											<color-badge
												color="success"
												icon="fas fa-check"
												:small="true"
												v-else
											></color-badge>

											<color-badge
												:color="result.projectType.color"
												:icon="result.projectType.icon"
												:luminosity="result.projectType.luminosity"
												:content="result.projectType.name"
												:small="true"
											></color-badge>
										</Property>
										<Property>
											<i class="fas fa-user"></i> {{ result.client }}
										</Property>
										<Property>
											<i class="fas fa-map-marker"></i> {{ result.address }}
										</Property>
										<template v-if="result.additionalData !== undefined && result.additionalData.length">
											<Property v-for="data in result.additionalData">
												<i :class="data.icon"></i> {{ data.text }}
											</Property>
										</template>
										<Link :href="result.url" @click="linkClicked"></Link>
									</div>
								</div>
								<div class="search-result search-result-user" v-if="result.type === 'user'">
									<div :class="{'search-result-details': 1, 'search-result-highlighted' : isHighlighted(resultKey)}" :ref="'result_' + getIndex(resultKey)">
										<div class="d-flex gap-2 align-items-center mb-1">
											<color-badge
												color="dark"
												icon="fas fa-user"
												content="Felhasználó"
												:small="true"
											></color-badge>
										</div>
										<Property>
											<i class="fas fa-user"></i> {{ result.name }}
										</Property>
										<Property>
											<i class="fas fa-envelope"></i> {{ result.email }}
										</Property>
										<Property>
											<i class="fas fa-phone"></i> {{ result.phone }}
										</Property>
										<Link :href="result.url" @click="linkClicked"></Link>
									</div>
								</div>
								<div class="search-result search-result-inventory-shipment" v-if="result.type === 'inventoryShipment'">
									<div :class="{'search-result-details': 1, 'search-result-highlighted' : isHighlighted(resultKey)}" :ref="'result_' + getIndex(resultKey)">
										<div class="d-flex gap-2 align-items-center mb-1">
											<color-badge
												color="light"
												icon="fas fa-truck"
												content="Szállítmány"
												:small="true"
											></color-badge>

											<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
												<color-badge :value="dataStore.getData('inventoryShipmentTypes')[result.shipmentType]" small></color-badge>
											</data-loader>
										</div>
										<div class="horizontal">
											<Property>
												<i class="fas fa-hashtag"></i> {{ result.public_id }}
											</Property>
											<Property>
												<i class="fas fa-calendar"></i> <date-time :value="result.created_at" />
											</Property>
										</div>
										<Link :href="result.url" @click="linkClicked"></Link>
									</div>
								</div>
								<div class="search-result search-result-partner" v-if="result.type === 'partner'">
									<div :class="{'search-result-details': 1, 'search-result-highlighted' : isHighlighted(resultKey)}" :ref="'result_' + getIndex(resultKey)">
										<div class="d-flex gap-2 align-items-center mb-1">
											<color-badge
												color="light"
												icon="fas fa-handshake"
												content="Partner"
												:small="true"
											></color-badge>
										</div>
										<Property>
											<i class="far fa-user"></i> {{ result.name }}
										</Property>
										<div class="horizontal">
											<Property v-if="result.email">
												<i class="far fa-at"></i> {{ result.email }}
											</Property>
											<phone-number :value="result.phone" v-if="result.phone" class="property" :link="false" />
										</div>
										<Link :href="result.url" @click="linkClicked"></Link>
									</div>
								</div>
								<div class="search-result search-result-supplier" v-if="result.type === 'supplier'">
									<div :class="{'search-result-details': 1, 'search-result-highlighted' : isHighlighted(resultKey)}" :ref="'result_' + getIndex(resultKey)">
										<div class="d-flex gap-2 align-items-center mb-1">
											<color-badge
												color="light"
												icon="fas fa-truck"
												content="Beszállító"
												:small="true"
											></color-badge>
										</div>
										<Property>
											<i class="far fa-user"></i> {{ result.name }}
										</Property>
										<div class="horizontal">
											<Property v-if="result.email">
												<i class="far fa-at"></i> {{ result.email }}
											</Property>
											<phone-number :value="result.phone" v-if="result.phone" class="property" :link="false" />
										</div>
										<Link :href="result.url" @click="linkClicked"></Link>
									</div>
								</div>
								<div class="search-result search-result-product" v-if="result.type === 'product'">
									<div :class="{'search-result-details': 1, 'search-result-highlighted' : isHighlighted(resultKey)}" :ref="'result_' + getIndex(resultKey)">
										<div class="d-flex gap-2 align-items-center mb-1">
											<color-badge
												color="light"
												icon="fas fa-boxes"
												content="Termék"
												:small="true"
											></color-badge>

											<Property>
												{{ result.name }}
											</Property>
										</div>
										<div class="horizontal">
											<Property>
												<i class="far fa-hashtag"></i> {{ result.sku }}
											</Property>
											<Property v-if="result.productManufacturer">
												<i class="far fa-industry-alt"></i> {{ result.productManufacturer }}
											</Property>
											<Property v-if="result.productCategory">
												<i class="far fa-folder-open"></i> {{ result.productCategory }}
											</Property>
										</div>
										<Link :href="result.url" @click="linkClicked"></Link>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import ColorBadge from "./ColorBadge.vue";
import Property from "./Property.vue";
import {Link, router} from "@inertiajs/vue3";
import DateTime from "./DateTime.vue";
import DataLoader from "./DataLoader.vue";
import EmailAddress from "./EmailAddress.vue";
import PhoneNumber from "./PhoneNumber.vue";

export default {
	components: {PhoneNumber, EmailAddress, DataLoader, DateTime, Property, ColorBadge, Link},
    data() {
        return {
            query: '',
            dropdownVisible: false,
            loading: true,
            cache: {},
            results: [],
			index: -1,
			scrollTimeout: null
        }
    },
	computed: {
		maxIndex: {
			get(){
				let count = Math.max(0, this.results.length - 1)
				_.forEach(this.results, function (result){
					if (result.type === 'client'){
						count += result.projects.length
					}
				})

				return count
			}
		}
	},
    watch: {
        query: function (newV, oldV){
            if (this.query.length > 1){
                if (this.cache[this.query] !== undefined){
                    this.results = this.cache[this.query]
                } else {
                    this.loading = true
                    axios.post('/search', {
                        query: this.query
                    }).then((response)=>{
                        this.loading = false
                        this.results = response.data.results
						this.cache[this.query] = this.results
						this.index = -1
                    }).catch(function (error) {

                    })
                }
            }
        }
    },
    methods: {
        keyDown: function (e){
			if (e.key === 'Enter'){
				e.preventDefault()
				if (this.index > -1){
					const url = this.getLinkByIndex(this.index)
					if (url){
						this.dropdownVisible = false
						router.visit(url)
					}
				}
			}
			if (e.key === 'Escape'){
				if (this.query.length){
					this.query = ''
				} else {
					this.$refs.searchInput.blur()
				}
			}
			if (e.key === 'ArrowUp'){
				if (this.index < 1){
					this.index = this.maxIndex
				} else {
					this.index--
				}
			}
			if (e.key === 'ArrowDown'){
				if (this.index >= this.maxIndex){
					this.index = 0
				} else {
					this.index++
				}
			}
			if (this.scrollTimeout){
				clearTimeout(this.scrollTimeout)
			}
			this.scrollTimeout = setTimeout(() => {
				if (this.$refs['result_' + this.index] !== undefined && this.$refs['result_' + this.index].length) {
					this.$refs['result_' + this.index][0].scrollIntoView({behavior: "smooth", block: 'center'})
					this.scrollTimeout = null
				}
			}, 100)
        },
		getIndex: function (resultIndex, subIndex){
			let index = -1
			_.forEach(this.results, function (result, resultKey){
				if (resultIndex >= resultKey){
					index++

					if (result.type === 'client' && (resultIndex > resultKey || subIndex !== undefined)){
						_.forEach(result.projects, function(project, subKey){
							if (subIndex === undefined || (resultIndex > resultKey) || (resultIndex === resultKey && subIndex >= subKey)){
								index++
							}
						})
					}
				}
			})

			return index
		},
		getLinkByIndex: function (searchIndex){
			let index = -1
			for (let i = 0; i < this.results.length; i++){
				let result = this.results[i]
				index++
				if (searchIndex === index){
					return result.url
				}

				if (result.type === 'client'){
					for (let j = 0; j < result.projects.length; j++){
						let project = result.projects[j]
						index++

						if (searchIndex === index){
							return project.url
						}
					}
				}
			}

			return false
		},
		isHighlighted: function (resultIndex, subIndex){
			return this.index === this.getIndex(resultIndex, subIndex)
		},
		onBlur: function (){
			setTimeout(() => {
				//this.dropdownVisible = false
			}, 200)
		},
		linkClicked: function (){
			this.dropdownVisible = false
		}
    }
}
</script>

<style scoped>
</style>
