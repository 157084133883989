<template>
	<div class="stacked-cell">
		<div class="horizontal">
			<project-status-icon :item="item"/>
			<strong>{{ item.public_id }}</strong>
		</div>
		<div class="mobile-horizontal">
			<attribute v-if="item.handlerUserName" icon="far fa-user">
				{{ item.handlerUserName }}
			</attribute>
			<attribute icon="far fa-clock">
				<date-time :value="item.created_at"/>
			</attribute>
			<div v-if="item.locked > 0">
				<color-badge
					:color="item.locked_by_user_id === app.userId ? 'success' : 'warning'"
					icon="fas fa-edit"
					:content="item.lockedByUserName ? item.lockedByUserName : item.lockedByClientName"
					:small="true"
				></color-badge>
			</div>
		</div>
	</div>
</template>

<script>
import ColorBadge from "../../components/ColorBadge.vue";
import DateTime from "../../components/DateTime.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import Attribute from "../../components/Attribute.vue";
import ProjectStatusIcon from "./ProjectStatusIcon.vue";

export default {
	props: {
		item: Object
	},
	components: {ProjectStatusIcon, Attribute, DateTime, ColorBadge},
	data(){
		return {
			app: useApplicationStore()
		}
	}
}
</script>
