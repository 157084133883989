<template>
	<form-wrapper
			ref="addProductForm"
			v-model="componentValue"
			:submit-function="formSubmitted"
			:show-buttons="false"
			v-slot="{ errors }"
	>

		<data-loader :data="['productTypes']" v-slot="{ dataStore }">
			<select-field
				label="Típus"
				label-field="label"
				v-model="componentValue.type"
				:options="dataStore.getDataAsArray('productTypes')"
				name="addProduct_type"
				data-type="string"
				:display-badge="true"
			/>
		</data-loader>

		<input-field
				label="Név"
				name="addProduct_name"
				rules="required"
				v-model="componentValue.name"
				:errors="errors"
		/>

		<input-field
				label="Cikkszám"
				name="addProduct_sku"
				rules="required|alpha_num"
				v-model="componentValue.sku"
				:errors="errors"
		/>
		<product-category-select-field
				label="Kategória"
				name="addProduct_product_category_id"
				rules="required"
				v-model="componentValue.product_category_id"
				:errors="errors"
		/>

		<product-manufacturer-select-field
				label="Gyártó"
				name="addProduct_product_manufacturer_id"
				v-model="componentValue.product_manufacturer_id"
				:errors="errors"
		/>
		<input-field
				label="Gyártói azonosító"
				name="addProduct_addProduct_manufacturer_code"
				rules=""
				v-model="componentValue.manufacturer_code"
				:errors="errors"
				v-if="componentValue.type === 'material'"
		/>

		<input-field
				label="Mennyiségi egység"
				name="addProduct_unit"
				v-model="componentValue.unit"
				:errors="errors"
		/>
		<template v-if="componentValue.type === 'material' || componentValue.type === 'equipment'">
			<switch-field
					label="Sorozatszám követése"
					name="addProduct_track_serial_number"
					v-model="componentValue.track_serial_number"
			/>
		</template>

		<switch-field
				label="Kiszállítást igényel"
				name="addProduct_requires_delivery"
				v-model="componentValue.requires_delivery"
				v-if="componentValue.type === 'material'"
		/>
		<switch-field
				label="Opcionális"
				name="addProduct_is_optional"
				v-model="componentValue.is_optional"
                v-if="componentValue.type !== 'equipment'"
		/>
	</form-wrapper>
</template>

<script>
import SelectField from "../../components/form/SelectField.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import RadioField from "../../components/form/RadioField.vue";
import InputField from "../../components/form/InputField.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import ProductCategorySelectField from "../../components/form/ProductCategorySelectField.vue";
import ProductManufacturerSelectField from "../../components/form/ProductManufacturerSelectField.vue";

export default {
	emits: ['update:modelValue', 'formSubmitted'],
	components: {
		ProductManufacturerSelectField,
		ProductCategorySelectField, DataLoader, FormWrapper, InputField, RadioField, SwitchField, SelectField},
	props: {
		modelValue: Object,
	},
	data(){
		return {
			flashStore: useFlashStoreStore()
		}
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	methods: {
		formSubmitted: function(){
			this.$emit('formSubmitted')
		},
		validate: function (){
			return this.$refs.addProductForm.validate()
		},
	}
}
</script>

<style scoped>

</style>
