<template>
	<TitleBar :title="attributes.id !== undefined ? 'Tulajdonságcsoport módosítása' : 'Tulajdonságcsoport hozzáadása'" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('productAttributeGroups.store', attributes.id)"
		:back-url="route('productAttributeGroups.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<order-field
								v-model="attributes"
								label="Sorrend"
							/>
						</div>
						<div class="col-sm-6">
							<data-loader :data="['productAttributes']" v-slot="{ dataStore }">
								<select-field
									label="Tulajdonságok"
									:options="dataStore.getData('productAttributes')"
									rules="required"
									name="product_attributes"
									v-model="attributes.product_attributes"
									:disabled="!attributes.editable"
									multiple
									:errors="errors"
								/>
							</data-loader>

							<product-category-select-field
								label="Termékkategóriák"
								name="product_categories"
								v-model="attributes.product_categories"
								:disabled="!attributes.editable"
								:errors="errors"
								:multiple="true"
								info="Amennyiben ki van választva, csak az adott kategóriákban lévő termékekhez választható a tulajdonságcsoport"
							/>

							<product-category-select-field
								label="Termékkategóriák tiltása"
								name="disabled_product_categories"
								v-model="attributes.disabled_product_categories"
								:disabled="!attributes.editable"
								:errors="errors"
								:multiple="true"
								info="Amennyiben ki van választva, adott kategóriákban lévő termékekhez nem választható a tulajdonságcsoport"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import {moveValueInArray} from "../functions";
import ProductCategorySelectField from "../components/form/ProductCategorySelectField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import SelectField from "../components/form/SelectField.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import SwitchField from "../components/form/SwitchField.vue";
import OrderField from "../components/form/OrderField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {
		UpdatedAttribute,
		OrderField,
		SwitchField, DataLoader, InputField, SelectField, FormWrapper, ProductCategorySelectField, TitleBar},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		let attributes = _.clone(this.model)

		return {
			attributes: attributes,
			loading: false,
			flash: []
		}
	},
	methods: {
	}
}
</script>
