<template>
	<TitleBar title="Projekt adatlap"/>

	<div class="card project-card">
		<div :class="'card-header d-flex align-items-center card-header-' + project.project_type.luminosity" :style="'background: ' + project.project_type.color">
			<i :class="'row-icon big far fa-' + project.project_type.icon"></i>
			<div>
				<strong>{{ project.name }}</strong><br />
				{{ project.public_id }}
			</div>
		</div>
		<div class="project-card-attributes">
			<div class="project-card-attribute" v-if="project.deploy_address">
				<i class="row-icon far fa-map-marker"></i>
				<div>
					<div><strong>{{ $t('Telepítési cím') }}</strong></div>
					<div>{{ project.deploy_address.formatted }}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card tabs-card">
		<div class="card-body">
			<ul class="nav nav-pills nav-justified mb-0" role="tablist">
				<li class="nav-item" role="presentation">
					<a :class="{'nav-link': 1, 'active': activeTab === 'quotes'}" @click.prevent="setActiveTab('quotes')" href="#" role="tab" :aria-selected="activeTab === 'quotes' ? 'true' : 'false'">
						<div class="icon"><i class="far fa-file-invoice-dollar"></i></div>
						<div class="label">{{ $t('Árajánlatok') }}</div>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a :class="{'nav-link': 1, 'active': activeTab === 'contracts'}" @click.prevent="setActiveTab('contracts')" href="#" role="tab" :aria-selected="activeTab === 'contracts' ? 'true' : 'false'">
						<div class="icon"><i class="far fa-file-contract"></i></div>
						<div class="label">{{ $t('Szerződések') }}</div>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a :class="{'nav-link': 1, 'active': activeTab === 'invoices'}" @click.prevent="setActiveTab('invoices')" href="#" role="tab" :aria-selected="activeTab === 'invoices' ? 'true' : 'false'">
						<div class="icon"><i class="far fa-file-invoice"></i></div>
						<div class="label">{{ $t('Számlák') }}</div>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a :class="{'nav-link': 1, 'active': activeTab === 'warrantyCards'}" @click.prevent="setActiveTab('warrantyCards')" href="#" role="tab" :aria-selected="activeTab === 'warrantyCards' ? 'true' : 'false'">
						<div class="icon"><i class="far fa-badge-check"></i></div>
						<div class="label">{{ $t('Garanciajegyek') }}</div>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a :class="{'nav-link': 1, 'active': activeTab === 'documentBundles'}" @click.prevent="setActiveTab('documentBundles')" href="#" role="tab" :aria-selected="activeTab === 'documentBundles' ? 'true' : 'false'">
						<div class="icon"><i class="far fa-folder-open"></i></div>
						<div class="label">{{ $t('Dokumentumcsomagok') }}</div>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a :class="{'nav-link': 1, 'active': activeTab === 'attributes'}" @click.prevent="setActiveTab('attributes')" href="#" role="tab" :aria-selected="activeTab === 'attributes' ? 'true' : 'false'">
						<div class="icon"><i class="fas fa-file-spreadsheet"></i></div>
						<div class="label">{{ project.client_input_required ? $t('Adatok megadása') : $t('Adatok')}}</div>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a :class="{'nav-link': 1, 'active': activeTab === 'progress'}" @click.prevent="setActiveTab('progress')" href="#" role="tab" :aria-selected="activeTab === 'progress' ? 'true' : 'false'">
						<div class="icon"><i class="far fa-tasks"></i></div>
						<div class="label">{{ $t('Haladás') }}</div>
					</a>
				</li>
			</ul>
		</div>
	</div>
	<div v-if="activeTab === 'quotes'">
		<div class="card mb-3">
			<div class="card-body">
				<h4 class="card-title mb-4">{{ $t('Árajánlatok') }}</h4>
				<div class="alert alert-info mb-0" v-if="!quotes.length">
					{{ $t('Még nincs létrehozva árajánlat.') }}
				</div>
				<table class="table table-collapse-mobile" v-else>
					<thead>
					<tr>
						<th>{{ $t('Azonosító') }}</th>
						<th>{{ $t('Cím') }}</th>
						<th class="text-end">{{ $t('Érvényes') }}</th>
						<th class="text-end">{{ $t('Végösszeg') }}</th>
						<th class="text-center">{{ $t('Státusz') }}</th>
						<th width="1">&nbsp;</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="quote in quotes">
						<td :data-label="$t('Azonosító')">{{ quote.public_id }}</td>
						<td :data-label="$t('Cím')">{{ getQuoteTitle(quote) }}</td>
						<td :data-label="$t('Érvényes')" :class="{'text-end': 1, 'text-success': quote.is_valid && !quote.answered_at, 'text-danger': !quote.is_valid && !quote.answered_at}">
							<date-time :value="quote.valid_until"/>
						</td>
						<td :data-label="$t('Végösszeg')" class="text-end">
							<Price
								:value="quote.total_price_gross"
								:currency-id="quote.currency_id"
							></Price>
						</td>
						<td :data-label="$t('Státusz')" class="text-center">
							<span class="badge badge-xl bg-success" v-if="quote.answered_at && quote.status === 'accepted'"><i class="fas fa-check"></i> {{ $t('Elfogadva') }}</span>
							<span class="badge badge-xl bg-danger" v-else-if="quote.status === 'rejected'"><i class="fas fa-times"></i> {{ $t('Elutasítva') }}</span>
							<span class="badge badge-xl bg-secondary" v-else>{{ $t('Függőben') }}</span>
						</td>
						<td><button type="button" class="btn btn-primary btn-sm" @click.prevent="quoteId = quote.id">{{ $t('Megtekintés') }}</button></td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div v-if="activeTab === 'contracts'">
		<div class="card mb-3">
			<div class="card-body">
				<h4 class="card-title mb-4">{{ $t('Szerződések') }}</h4>
				<div class="alert alert-info mb-0" v-if="!contracts.length">
					{{ $t('Még nincs létrehozva szerződés.') }}
				</div>
				<table class="table table-collapse-mobile" v-else>
					<thead>
					<tr>
						<th>{{ $t('Azonosító') }}</th>
						<th class="text-end">{{ $t('Dátum') }}</th>
						<th class="text-end">{{ $t('Végösszeg') }}</th>
						<th class="text-center">{{ $t('Státusz') }}</th>
						<th class="text-center">{{ $t('Árajánlat') }}</th>
						<th width="1">&nbsp;</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="contract in contracts" :class="{'light-bg': contractId === contract.id}">
						<td :data-label="$t('Azonosító')">{{ contract.public_id }}</td>
						<td :data-label="$t('Dátum')" class="text-end">
							<date-time :value="contract.generated_at" :time="false"/>
						</td>
						<td :data-label="$t('Végösszeg')" class="text-end">
							<Price
								:value="contract.total_price_gross"
								:currency-id="contract.currency_id"
							></Price>
						</td>
						<td :data-label="$t('Státusz')" class="text-center">
							<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }">
								<color-badge :value="dataStore.getData('contractStatusLabels')[contract.status]"></color-badge>
							</data-loader>
						</td>
						<td :data-label="$t('Árajánlat')" class="text-center">{{ contract.quote ? contract.quote.public_id : '-' }}</td>
						<td><a type="button" class="btn btn-primary btn-sm" :href="route('clientArea.projects.contract', project.id) + '/' + contract.id + '/download'"><i class="far fa-download"></i> {{ $t('Letöltés') }}</a></td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div v-if="activeTab === 'invoices'">
		<div class="card mb-3">
			<div class="card-body">
				<h4 class="card-title mb-4">{{ $t('Számlák') }}</h4>
				<div class="alert alert-info mb-0" v-if="!invoices.length">
					{{ $t('Még nincs kiállítva számla.') }}
				</div>
				<table class="table table-collapse-mobile" v-else>
					<thead>
					<tr>
						<th>{{ $t('Azonosító') }}</th>
						<th class="text-end">{{ $t('Dátum') }}</th>
						<th class="text-end">{{ $t('Határidő') }}</th>
						<th class="text-end">{{ $t('Végösszeg') }}</th>
						<th class="text-center">{{ $t('Fizetve') }}</th>
						<th>{{ $t('Szerződés') }}</th>
						<th width="1">&nbsp;</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="invoice in invoices">
						<td :data-label="$t('Azonosító')">{{ invoice.invoice_number }}</td>
						<td :data-label="$t('Dátum')" class="text-end">
							<date-time :value="invoice.created_at" :time="false"/>
						</td>
						<td :data-label="$t('Határidő')" class="text-end">
							<date-time :value="invoice.deadline" :time="false"/>
						</td>
						<td :data-label="$t('Végösszeg')" class="text-end">
							<Price
								:value="invoice.amount"
								:currency-id="invoice.currency_id"
							></Price>
						</td>
						<td :data-label="$t('Státusz')" class="text-center">
							<span :class="'badge badge-xl bg-' + (invoice.paid_at === null ? 'danger' : 'success')">
								<i :class="'far fa-' + (invoice.paid_at === null ? 'hourglass' : 'check')"></i> {{ (invoice.paid_at === null ? 'Nem' : 'Igen') }}
							</span>
						</td>
						<td :data-label="$t('Szerződés')">{{ invoice.contract_public_id }}</td>
						<td><a type="button" class="btn btn-primary btn-sm" :href="route('clientArea.projects.invoice', project.id) + '/' + invoice.id + '/download'"><i class="far fa-download"></i> {{ $t('Letöltés') }}</a></td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div v-if="activeTab === 'warrantyCards'">
		<div class="card mb-3">
			<div class="card-body">
				<h4 class="card-title mb-4">{{ $t('Garanciajegy') }}</h4>
				<div class="alert alert-info mb-0" v-if="!warrantyCards.length">
					{{ $t('Még nincs létrehozva garanciajegy.') }}
				</div>
				<table class="table table-collapse-mobile" v-else>
					<thead>
					<tr>
						<th>{{ $t('Azonosító') }}</th>
						<th class="text-end">{{ $t('Kiállítva') }}</th>
						<th class="text-end">{{ $t('Szerződés') }}</th>
						<th width="1">&nbsp;</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="warrantyCard in warrantyCards">
						<td :data-label="$t('Azonosító')">{{ warrantyCard.public_id }}</td>
						<td :data-label="$t('Kiállítva')" :class="{'text-end': 1}">
							<date-time :value="warrantyCard.created_at"/>
						</td>
						<td :data-label="$t('Kiállítva')" :class="{'text-end': 1}">
							{{ warrantyCard.contract_public_id }}
						</td>
						<td><a type="button" class="btn btn-primary btn-sm" :href="'/clientArea/projects/view/' + project.id + '/downloadWarrantyCard/' + warrantyCard.id"><i class="far fa-download"></i> {{ $t('Letöltés') }}</a></td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<div v-if="activeTab === 'attributes'">
		<form-wrapper
			ref="formWrapper"
			:submit-function="saveForm"
			v-model="project"
			:error-event-enabled="true"
			:show-back-button="false"
			:show-bottom-buttons="false"
			:lock-url="route('clientArea.projects.lock', project.id) + '?action=lock'"
			:update-lock-url="route('clientArea.projects.lock', project.id) + '?action=updateLock'"
			@errors-changed="errorsChanged"
		>

			<template #default="{activeTab, errors, editable, lockStatus}">
				<div class="alert alert-danger mb-3" v-if="lockStatus.locked !== undefined && lockStatus.locked && !lockStatus.lockedByCurrentUser">
					<h4 class="alert-heading mb-0"><i class="far fa-lock"></i> {{ $t('A projektet valaki más szerkeszti! Kérjük próbálja meg kicsit később.') }}</h4>
				</div>
				<ProjectFormAttributes
					v-model="project"
					:editable="editable"
					:errors="errors"
					user-type="client"
					v-else
					ref="projectAttributes"
				></ProjectFormAttributes>
			</template>
		</form-wrapper>

	</div>
	<div v-if="activeTab === 'progress'">
		<div class="card">
			<div class="card-body">
				<table class="table mb-0  table-collapse-mobile">
					<thead class="table-light">
					<tr>
						<th>{{ $t('Mérföldkő') }}</th>
						<th>{{ $t('Időpont') }}</th>
					</tr>
					</thead>
					<tbody>
					<template v-for="milestone in project.project_type.milestones">
						<tr v-if="milestone.is_public">
							<td>
								<div :class="'milestone-badge milestone-badge-' + milestone.luminosity + (project.milestone_log[milestone.id] === undefined ? ' milestone-badge-inactive' : '')" :style="'background:' + milestone.color + ';'">
									<i :class="'far fa-' + milestone.icon"></i> {{ milestone.name }}
								</div>
							</td>
							<td>
								<div v-if="project.milestone_log[milestone.id] !== undefined">
									<date-time :value="project.milestone_log[milestone.id].created_at"/>
								</div>
							</td>
						</tr>
					</template>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div v-if="activeTab === 'documentBundles'">
		<div class="card">
			<div class="card-body">
				<h4 class="mb-2">Dokumentumcsomagok</h4>
				<div class="alert alert-info mb-0" v-if="!documentBundles.length">{{ $t('Nem található dokumentum') }}</div>
				<div :class="{
								'card': 1,
								'border': 1,
								'shadow-none': 1,
								'card-body': 1,
								'bg-soft': 1,
									'bg-primary': parseInt(documentBundleId) === documentBundle.id}"
					 v-for="(documentBundle, index) in documentBundles" :key="documentBundle.id">
					<div class="row">
						<div class="col-md-6">
							<h4 class="card-title mb-2">{{ documentBundle.document_bundle.name }}</h4>
							<attribute
								icon="far fa-hashtag"
							>
								{{ documentBundle.public_id }}
							</attribute>
							<attribute
								icon="far fa-clock"
							>
								<date-time :value="documentBundle.created_at"/>
							</attribute>
						</div>
						<div class="col-md-6">
							<h4 class="card-title mb-2 fs-6">Fileok</h4>
							<div class="d-flex gap-2 flex-wrap mt-2">
								<a :href="file.download_url" class="badge badge-xl bg-light" v-for="(file, fileIndex) in documentBundle.files" :key="fileIndex">
									{{ file.name + '.' + file.extension}} (<file-size :value="file.size"/>)
								</a>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
	<ClientAreaQuote
		v-if="quoteId"
		:quote-id="quoteId"
		@close="quoteId = 0"
		@close-and-reload="closeQuoteAndReload"
	></ClientAreaQuote>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import ClientAreaQuote from "../modules/clientArea/projects/ClientAreaQuote.vue";
import Price from "../components/Price.vue";
import ProjectFormAttributes from "../modules/projects/ProjectFormAttributes.vue";
import FormWrapper from "../components/FormWrapper.vue";
import {useFlashStoreStore} from "../stores/flashStore";
import ClientAreaLayout from "../layouts/ClientAreaLayout.vue";
import DataLoader from "../components/DataLoader.vue";
import ColorBadge from "../components/ColorBadge.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {useProjectDataStore} from "../stores/projectData";
import DateTime from "../components/DateTime.vue";
import FileSize from "../components/FileSize.vue";
import {router} from "@inertiajs/vue3";
import Attribute from "../components/Attribute.vue";

export default {
	layout: ClientAreaLayout,
	components: {
		Attribute,
		FileSize,
		DateTime, ColorBadge, DataLoader, FormWrapper, ProjectFormAttributes, Price, ClientAreaQuote, TitleBar},
	props: {
		initialQuotes: Array,
		initialContracts: Array,
		initialAttributes: Object,
		initialInvoices: Array,
		initialDocumentBundles: Array,
		initialwarrantyCards: Array,
		contractStatusLabels: Object,
		tab: String,
		tabParameters: String
	},
	data(){
		return {
			project: this.initialAttributes,
			projectAttributes: this.initialAttributes.attribute_values,
			projectFields: this.initialAttributes.project_type.fields_data,
			quotes: this.initialQuotes,
			contracts: this.initialContracts,
			invoices: this.initialInvoices,
			warrantyCards: this.initialwarrantyCards,
			activeTab: 'quotes',
			attributesEditable: false,
			editingFailed: false,
			flashStore: useFlashStoreStore(),
			app: useApplicationStore(),
			projectDataStore: useProjectDataStore(),
			unsavedChanged: false,
			quoteId: 0,
			contractId: 0,
			formErrors: [],
			documentBundleId: 0,
			documentBundles: this.initialDocumentBundles,
			projectAttributesCommand: '',
			projectAttributesParameter: '',
		}
	},
	mounted(){
		this.projectDataStore.projectId = this.project.id
		this.projectDataStore.setData(this.project)

		if (this.tab !== undefined && this.tab !== null){
			this.setActiveTab(this.tab)

			if (this.tab === 'quotes' && this.tabParameters !== undefined && this.tabParameters){
				this.quoteId = parseInt(this.tabParameters)
			}
			if (this.tab === 'contracts' && this.tabParameters !== undefined && this.tabParameters){
				this.contractId = parseInt(this.tabParameters)
			}
			if (this.tab === 'documentBundles' && this.tabParameters !== undefined && this.tabParameters){
				this.documentBundleId = parseInt(this.tabParameters)
			}
		}
	},
	beforeUnmount() {
		this.projectDataStore.clearData()
	},
	methods: {
		getQuoteTitle: function(quote){
			if (quote.title){
				return quote.title
			}
			return this.project.client.is_company ? this.project.project_type.name_in_quote_company : this.project.project_type.name_in_quote_consumer
		},
		getData: function(){
			axios.get(route('clientArea.projects.data', this.project.id)).then((response)=>{
				this.quotes = response.data.initialQuotes
				this.contracts = response.data.initialContracts
				this.invoices = response.data.initialInvoices
				this.documentBundles = response.data.initialDocumentBundles
				this.warrantyCards = response.data.initialwarrantyCards
			}).catch(function (error) {

			})
		},
		setActiveTab: function(tab){
			this.quoteId = null
			this.contractId = null
			this.documentBundleId = null
			if (this.activeTab === tab){
				return
			}
			if (this.activeTab === 'attributes'){
				if (this.unsavedChanged && !confirm(this.$t('Az oldalon el nem mentett változások vannak. Biztos, hogy el akarja hagyni az oldalt?'))){
					return false
				}
				this.attributesEditable = false
			}
			this.activeTab = tab
		},
		saveForm: function(){

			let payload = {
				attributes: JSON.parse(JSON.stringify(this.project['attribute_values'])),
			}
			axios.post(route('clientArea.projects.store', this.project.id), payload).then((response)=>{
				if (response.data.flash){
					this.flashStore.addFlash(response.data.flash)
				}
				this.$refs.formWrapper.lockResponse(response)
				if (response.data.success){
					this.$refs.formWrapper.formClean()
					this.project = response.data.project
					this.projectAttributes = this.project.attribute_values
					this.projectFields = this.project.project_type.fields_data
				}
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		closeQuoteAndReload: function(){
			this.quoteId = 0

			this.getData()
		},
		errorsChanged: function (errors){
			this.formErrors = errors

			let firstErrorField = null
			_.forEach(this.formErrors, function (msg, field){
				if (firstErrorField === null) {
					firstErrorField = field
				}
			})

			if (firstErrorField) {
				this.projectAttributesCommand = 'scroll'
				this.projectAttributesParameter = firstErrorField
			}
		}
	}
}
</script>
