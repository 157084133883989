<template>
	<i class="fas fa-power-off text-primary"
		v-if="!item.closed_at && !item.postponed_until"
	></i>
	<i
		class="fas fa-clock text-warning"
		v-else-if="!item.closed_at && item.postponed_until"
	></i>
	<i
		class="fas fa-check text-success"
		v-else
	></i>
</template>

<script>
import ColorBadge from "../../components/ColorBadge.vue";

export default {
	props: {
		item: Object
	},
	components: {ColorBadge}
}
</script>

<style scoped>

</style>
