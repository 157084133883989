import { defineStore } from 'pinia'

export const useFlashStoreStore = defineStore('flashStore', {
	state: () => {
		return {
			flash: [],
		}
	},
	getters: {
	},
	actions: {
		set(data) {
			if (data.length) {
				this.flash = data
			}
		},
		addFlash(flash){
			if (_.isArray(flash)){
				if (flash.length) {
					this.flash = flash
				}
			} else {
				if (flash) {
					this.flash = [flash]
				}
			}
		},
		addError(error){
			let content = error.response !== undefined ? error.response.data.message : error
			if (error instanceof TypeError){
				content = content.message
			}
			content = String(content)
			if (content.indexOf('Operation canceled') < 0) {
				this.flash.push({
					level: 'danger',
					message: content
				})
			}
		}
	},
})
