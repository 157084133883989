<script>
import {Head} from "@inertiajs/vue3";

export default {
	components: {Head},
	props: {
		title: String,
		hideTitle: Boolean
	}
}
</script>

<template>
	<Head :title="title"/>
	<div class="page-title-box" v-if="!hideTitle">
		<h4 class="mb-0 font-size-18">{{ title }}</h4>
	</div>
</template>

<style scoped>

</style>
