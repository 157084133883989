<template>
	<TitleBar :title="product.name + ' (' + product.sku + ')'" />
	<data-loader :data="['warehouses']" v-slot="{ dataStore }">
		<div class="row">
				<div class="col-xl-4 col-sm-6" v-for="warehouse in dataStore.getData('warehouses')">
					<div class="card p-3">
						<h5 class="pb-1">
							{{ warehouse.name }}

							<color-badge color="danger" v-if="warehouse.activeInspection">Leltár</color-badge>
						</h5>
						<div class="row">
							<div class="col-6">
								<p class="text-muted mb-2 text-truncate">Valós készlet</p>
								<h5>
									<number :value="stock[warehouse.id] !== undefined ? stock[warehouse.id]['physical'] : 0"/>
								</h5>
							</div>
							<div class="col-6">
								<p class="text-muted mb-2 text-truncate">Elérhető készlet</p>
								<h5>
									<number :value="stock[warehouse.id] !== undefined ? stock[warehouse.id]['available'] : 0"/>
								</h5>
							</div>
						</div>
					</div>
				</div>
		</div>
	</data-loader>

	<AdvancedDataTable
		:data-url="route('inventory.productList', product.id)"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		name="inventoryProduct"
	>
		<template #navigation>
			<ButtonGroup>
				<template #index>
					<Link :href="route('inventory.index')" class="btn btn-secondary btn-label"><i class="far fa-inventory label-icon"></i> Raktárkészlet</Link>
				</template>
				<template #detailed>
					<Link :href="route('inventory.detailed')" class="btn btn-secondary btn-label"><i
						class="far fa-clipboard-list label-icon"></i> Részletes készlet
					</Link>
				</template>
				<template #shipments>
					<Link :href="route('inventory.shipments')" class="btn btn-secondary btn-label"><i
						class="far fa-truck label-icon"></i> Szállítmányok
					</Link>
				</template>
				<template #delivered>
					<Link :href="route('inventory.delivered')" class="btn btn-secondary btn-label"><i
						class="far fa-truck-loading label-icon"></i> Kiszállított áruk
					</Link>
				</template>
			</ButtonGroup>
		</template>
		<template #filters="{serverOptions}">
			<div class="data-table-filter-item">
				<warehouse-select-field
					v-model="serverOptions.warehouse_id"
					:field-only="true"
					name="warehouseId"
					label="Raktár"
					/>
			</div>
			<div class="data-table-filter-item">
				<v-select
					:options="statuses"
					v-model="serverOptions.status"
					:reduce="item => item.id"
					label="name"
					placeholder="Státusz"
				/>
			</div>
		</template>
		<template #item-operations="{item, deleteButtonPressed}">

			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong><date-time :value="item.created_at"/></strong>

						<div class="mobile-horizontal">
							<color-badge
								:color="item.quantity > item.previous_quantity ? 'success' : 'danger'"
								:icon="'far fa-' + (item.quantity > item.previous_quantity ? 'plus' : 'minus')"
							>
								<number :value="Math.abs(item.quantity - item.previous_quantity)"/>
							</color-badge>

							<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
								<template v-if="dataStore.getData('inventoryShipmentTypes')[item.eventType] !== undefined">
									<color-badge :value="dataStore.getData('inventoryShipmentTypes')[item.eventType]"></color-badge>
								</template>
								<template v-else>
									<span class="badge badge-xl bg-dark"><i class="far fa-scanner-gun"></i> Leltár</span>
								</template>
							</data-loader>

							<ShipmentMovementCell :item="item" small />
						</div>

						<color-badge
							color="dark"
							icon="far fa-tags"
							:content="item.serial_number"
							v-if="item.serial_number"
						/>
						<inventory-shipment-badge :shipment-id="item.inventory_shipment_id" :public-id="item.shipmentId" />
					</div>
				</div>
				<div class="table-row-operations d-flex gap-2">
				</div>
			</div>
		</template>
		<template #item-quantity="item">
			{{ item.quantity }}
		</template>
		<template #item-serial_number="item">
			{{ item.serial_number }}
		</template>
		<template #item-previous_quantity="item">
			{{ item.previous_quantity }}
		</template>
		<template #item-change="item">
			<color-badge
				:color="item.quantity > item.previous_quantity ? 'success' : 'danger'"
				:icon="'far fa-' + (item.quantity > item.previous_quantity ? 'plus' : 'minus')"
				small
			>
				<number :value="Math.abs(item.quantity - item.previous_quantity)"/>
			</color-badge>
		</template>
		<template #item-created_at="item">
			<date-time :value="item.created_at"/>
		</template>
		<template #item-shipmentId="item">
			<inventory-shipment-badge :shipment-id="item.inventory_shipment_id" :public-id="item.shipmentId" />
		</template>
		<template #item-source="item">
			<ShipmentMovementCell :item="item" small />
		</template>
		<template #item-userName="item">
			<user-badge :user-id="item.created_by_user_id" hide-level />
		</template>
		<template #item-warehouse_id="item">
			<WarehouseBadge :warehouse-id="item.warehouse_id" />
		</template>
		<template #item-eventType="item">
			<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
				<template v-if="dataStore.getData('inventoryShipmentTypes')[item.eventType] !== undefined">
					<color-badge :value="dataStore.getData('inventoryShipmentTypes')[item.eventType]" small></color-badge>
				</template>
			</data-loader>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import DataLoader from "../components/DataLoader.vue";
import Number from "../components/Number.vue";
import {Link} from "@inertiajs/vue3";
import AddShipmentButton from "../modules/inventory/AddShipmentButton.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import vSelect from "vue-select";
import ColorBadge from "../components/ColorBadge.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import DateTime from "../components/DateTime.vue";
import TitleBar from "../components/TitleBar.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import ShipmentSourceCell from "../modules/inventory/ShipmentSourceCell.vue";
import ShipmentMovementCell from "../modules/inventory/ShipmentMovementCell.vue";
import WarehouseBadge from "../components/badges/WarehouseBadge.vue";
import InventoryShipmentBadge from "../components/badges/InventoryShipmentBadge.vue";
import UserBadge from "../components/badges/UserBadge.vue";

export default {
	components: {
		UserBadge,
		InventoryShipmentBadge,
		WarehouseBadge,
		ShipmentMovementCell,
		ShipmentSourceCell,
		ButtonGroup,
		TitleBar,
		DateTime,
		WarehouseSelectField,
		ColorBadge, vSelect, AdvancedDataTable, AddShipmentButton, Number, DataLoader, Link},
	layout: AppLayout,
	props: {
		stock: Object,
		product: Object,
	},
	data(){
		let columns = [
			{
				text: "Típus",
				value: "eventType",
				sortable: true
			},
			{
				text: "Időpont",
				value: "created_at",
				sortable: true
			},
			{
				text: "Raktár",
				value: "warehouse_id",
				sortable: true
			},
			{
				text: "Előző mennyiség",
				value: "previous_quantity",
				align: 'right',
				sortable: true,
			},
			{
				text: "Változás",
				value: "change",
				align: 'right',
				sortable: false,
			},
			{
				text: "Új mennyiség",
				value: "quantity",
				align: 'right',
				sortable: true,
			}
		]

		if (this.product.track_serial_number) {
			columns.push({
				text: "Sorozatszám",
				value: "serial_number",
				sortable: true,
			})
		}
		columns.push({
			text: "Mozgás",
			value: "source",
			sortable: false
		})

		columns.push({
			text: "Azon.",
			value: "shipmentId",
			sortable: true,
		})

		columns.push({
			text: "Felhasználó",
			value: "userName",
			sortable: true
		})
		columns.push({
			text: "",
			value: "operations",
			sortable: false
		})


		return {
			columns: columns,
			statuses: [
				{
					id: 'inStock',
					name: 'Készleten'
				},
				{
					id: 'removed',
					name: 'Kiszállítva'
				}
			]
		}
	},
	methods: {
		getMovement: function(item){
			let source = ''
			let destination = ''
			if (item.eventType === 'incoming') {
				source = item.supplierName
			}
			if (item.eventType === 'transfer' || item.eventType === 'delivery') {
				source = item.sourceWarehouse
			}
			if (item.eventType === 'delivery') {
				destination = item.clientName
			}
			if (item.eventType === 'return') {
				source = item.clientName || item.sourceShipmentName
			}

			if (item.eventType === 'incoming' || item.eventType === 'transfer' || item.eventType === 'return') {
				destination = item.destinationWarehouse
			}
			if (item.eventType === 'delivery' && !destination){
				destination = item.customDestinationName
			}

			if (item.eventType === 'installation'){
				return item.sourceWarehouse + ' <i class="far fa-at"></i> ' + item.clientName
			}

			if (!source && !destination){
				return ''
			}
			if (!source){
				return destination
			}
			if (!destination){
				return source
			}
			return source + ' <i class="far fa-arrow-right"></i> ' + destination
		},
		getLink: function(item){
			if (item.eventType === 'assign') {
				return '-'
			}
			if (item.eventType === 'inspection') {
				return '<a href="' + route('inventory.inspection') + '/' + item.inventory_inspection_id + '"><i class="far fa-link"></i> ' + item.inventory_inspection_id + '</a>'
			} else {
				return '<a href="' + route('inventory.shipment') + '/' + item.inventory_shipment_id + '"><i class="far fa-link"></i> ' + item.shipmentId + '</a>'
			}
		}
	}
}
</script>


<style scoped>

</style>
