<script setup>
import Attribute from "../Attribute.vue";
import DateTime from "../DateTime.vue";
import {computed} from "@vue/compat";
import UserBadge from "../badges/UserBadge.vue";
import ColorBadge from "../ColorBadge.vue";

const props = defineProps({
	value: Object|String,
	hideDate: Boolean,
	hideTime: Boolean,
	hideLabel: Boolean,
	displayIfEmpty: Boolean,
	label: {
		type: String,
		default: 'Időpont'
	},
	icon: {
		type: String,
		default: ''
	},
	created: Boolean,
	updated: Boolean,
	approved: Boolean,
	sent: Boolean,
	answered: Boolean,
	validity: Boolean,
	deadline: Boolean,
	terminated: Boolean,
	signed: Boolean,
	generated: Boolean,
	closed: Boolean,
	read: Boolean,
	clientRead: Boolean,
	clientInput: Boolean,
})

const labelComputed = computed(() => {
	if (props.hideLabel){
		return ''
	}
	if (props.created){
		return 'Létrehozva'
	}
	if (props.updated){
		return 'Módosítva'
	}
	if (props.approved){
		return 'Jóváhagyva'
	}
	if (props.sent){
		return 'Küldve'
	}
	if (props.answered){
		return 'Válasz rögzítve'
	}
	if (props.validity){
		return 'Érvényesség'
	}
	if (props.deadline){
		return 'Határidő'
	}
	if (props.terminated){
		return 'Felbontva'
	}
	if (props.signed){
		return 'Aláírva'
	}
	if (props.generated){
		return 'Generálva'
	}
	if (props.closed){
		return 'Lezárva'
	}
	if (props.read){
		return 'Elolvasva'
	}
	if (props.clientRead){
		return 'Ügyfél elolvasta'
	}
	if (props.clientInput){
		return 'Ügyfél adatkitöltés'
	}

	return props.label
})

const iconComputed = computed(() => {
	if (props.icon !== ''){
		return props.icon
	}
	if (props.updated || props.clientInput){
		return 'far fa-calendar-edit'
	}
	if (props.approved || props.signed || props.closed || props.read || props.clientRead){
		return 'far fa-calendar-check'
	}
	if (props.validity){
		return 'far fa-calendar-day'
	}
	if (props.deadline){
		return 'far fa-calendar-star'
	}
	if (props.terminated){
		return 'far fa-calendar-times'
	}
	if (props.created || props.sent || props.answered || props.generated){
		return 'far fa-calendar-plus'
	}

	return 'far fa-calendar-alt'
})
</script>

<template>
	<attribute
		:icon="iconComputed"
		:label="labelComputed"
		v-if="value || displayIfEmpty"
	>
		<date-time :value="value" :date="!hideDate" :time="!hideTime" v-if="value" />
		<color-badge v-else
					 icon="far fa-times"
					 color="warning"
					 content="Nincs" />

		<slot></slot>
	</attribute>
</template>

<style scoped>

</style>
