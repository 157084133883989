<script setup>
import {Link} from "@inertiajs/vue3";
import InventoryShipmentBadge from "../../components/badges/InventoryShipmentBadge.vue";
import ShipmentDestinationCell from "../inventory/ShipmentDestinationCell.vue";
import DateTime from "../../components/DateTime.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import ShipmentSourceCell from "../inventory/ShipmentSourceCell.vue";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import DataLoader from "../../components/DataLoader.vue";
import {useApplicationStore} from "../../stores/applicationStore";

const model = defineModel()
const app = useApplicationStore()

let columns = [
	{
		text: "Azon.",
		value: "public_id",
		sortable: true
	},
	{
		text: "Típus",
		value: "type",
		sortable: true
	},
	{
		text: "Létrehozva",
		value: "created_at",
		wrap: false,
		sortable: true
	},
	{
		text: "Lezárva",
		value: "closed_at",
		sortable: true
	},
	{
		text: "Mozgás",
		value: "source",
		sortable: false
	},
	{
		label: '',
		value: 'operations',
		width: 5,
	}
]
</script>

<template>
	<AdvancedDataTable
		:data-url="'/sales/inventoryShipments/' + model"
		:columns="columns"
		sort-by="created_at"
		ref="table"
		sort-type="desc"
		name="saleShipments"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<inventory-shipment-badge :shipment-id="item.id" :public-id="item.public_id"/>

						<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
							<color-badge :value="dataStore.getData('inventoryShipmentTypes')[item.type]"></color-badge>
						</data-loader>

						<div class="mobile-horizontal">
							<shipment-source-cell :item="item" small />
							<shipment-destination-cell :item="item" small />
						</div>

						<color-badge
							v-if="item.closed_at !== null"
							color="success"
							icon="far fa-check">
							Lezárva: <DateTime :value="item.closed_at"/>
						</color-badge>
						<color-badge
							v-else
							color="light"
							icon="far fa-lock-open">
							Nincs lezárva
						</color-badge>
					</div>
				</div>
				<div class="table-row-operations d-flex gap-2">
					<Link :href="route('inventory.shipment', item.id)" v-if="item.editable && app.hasPermission('InventoryController_view')" class="btn btn-primary btn-sm waves-effect" title="Szerkesztés">
						<i class="far fa-edit label-icon"></i>
					</Link>
					<Link :href="route('inventory.shipment', item.id)" v-else-if="app.hasPermission('InventoryController_view')" class="btn btn-primary btn-sm waves-effect" title="Részletek">
						<i class="far fa-eye label-icon"></i>
					</Link>
				</div>
			</div>
		</template>
		<template #item-type="item">
			<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
				<color-badge :value="dataStore.getData('inventoryShipmentTypes')[item.type]" small></color-badge>
			</data-loader>
		</template>
		<template #item-created_at="item">
			<DateTime :value="item.created_at"/>
		</template>
		<template #item-public_id="item">
			<inventory-shipment-badge :shipment-id="item.id" :public-id="item.public_id"/>
		</template>
		<template #item-closed_at="item">
			<color-badge
				v-if="item.closed_at !== null"
				color="success"
				small
				icon="far fa-check">
				<DateTime :value="item.closed_at"/>
			</color-badge>
			<color-badge
				v-else
				color="light"
				small
				icon="far fa-lock-open"
				content="Nincs lezárva"
			/>
		</template>
		<template #item-source="item">
			<div class="stacked-cell">
				<shipment-source-cell :item="item" small />
				<shipment-destination-cell :item="item" small />
			</div>
		</template>
	</AdvancedDataTable>
</template>

<style scoped>

</style>
