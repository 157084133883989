
<template>
	<div class="card">
		<div :class="{'card-body': 1, 'loading': loading}">
			<h4 class="card-title mb-4">{{ title }}</h4>

			<VueApexCharts
				type="line"
				:height="300"
				:options="apexOptions"
				:series="data"
				v-if="loaded"
			/>
		</div>
	</div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
export default {
	components: {VueApexCharts},
	props: {
		title: String,
		type: String,
		dataUrl: String,
		useDateRange: Boolean,
		dateRangeStart: Date,
		dateRangeEnd: Date,
	},
	data(){
		let colors = ['#4CC1EF', '#f46a6a', '#f1b44c', '#34c38f', '#6f42c1']
		return {
			loading: true,
			loaded: false,
			data: [],
			colors: colors,
			dateRange: {start: this.dateRangeStart, end: this.dateRangeEnd},
			apexOptions: {
				chart: {
					type: 'line',
					toolbar: {
						show: false
					},
					zoom: {
						enabled: false,
					}
				},
				stroke: {
					curve: 'smooth',
				},
				colors: colors,
				plotOptions: {
					radialBar: {
					}
				}
			}
		}
	},
	mounted() {
		this.loadData()
	},
	methods: {
		loadData: function(){
			this.loading = true
			axios.get(this.dataUrl).then((response)=>{
				this.data = response.data.data
				this.apexOptions.xaxis = {
					categories: response.data.xAxis
				}
				this.loading = false
				this.loaded = true
			}).catch(function (error) {

			})
		}
	}
}
</script>

<style scoped>

</style>
