<template>
	<div :class="{'card': 1}">
		<div class="card-body">
			<div class="mb-3 d-flex gap-2" v-if="canCreate">
				<div class="flex-grow-1">
					<TextareaAutosize
						v-model="newMessage"
						placeholder="Üzenet írása"
						/>
				</div>
				<button type="button" class="btn btn-primary" :disabled="!newMessage.length" @click.prevent="saveItem">
					<span class="d-none d-md-inline">Küldés </span><i class="far fa-paper-plane"></i>
				</button>
			</div>

			<div class="alert alert-info" v-if="!comments.length">
				Nincs Üzenet
			</div>

			<template v-for="comment in comments">
				<div :class="'card shadow-none border comment' + (highlightedComment === comment.id ? ' border-primary' : '')">
					<div class="card-body">
						<div class="d-flex mb-2 gap-2 comment-info align-items-center">
							<date-time-attribute :value="comment.created_at" created hide-label>
								<user-badge :user-id="comment.user_id" :key="'US_' + comment.user_id" />
							</date-time-attribute>
						</div>
						<div v-html="comment.content_html"></div>
					</div>
				</div>
			</template>

			<div class="mt-4">
				<nav>
					<ul class="pagination justify-content-center">
						<template v-for="link in paginationLinks">
							<li :class="{'page-item': 1, 'active': link.active}" v-if="link.url !== null">
								<a class="page-link" href="#" @click.prevent="getData(link.url)" v-html="link.label"></a>
							</li>
							<li class="page-item disabled" v-else>
								<a class="page-link" v-html="link.label"></a>
							</li>
						</template>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>

<script>
import TextareaAutosize from "./TextareaAutosize.vue";
import DateTime from "./DateTime.vue";
import Attribute from "./Attribute.vue";
import UserBadge from "./badges/UserBadge.vue";
import DateTimeAttribute from "./attributes/DateTimeAttribute.vue";
export default {
	components: {DateTimeAttribute, UserBadge, Attribute, DateTime, TextareaAutosize},
	emits: ['commentCount'],
	props: {
		dataUrl: String,
		highlightComment: Number,
		canCreate: Boolean,
		activeTabCommand: String,
		activeTabParameter: String,
	},
	data(){
		return {
			newMessage: '',
			comments: [],
			paginationLinks: []
		}
	},
	mounted() {
		this.getData()
	},
	computed: {
		highlightedComment: {
			get() {
				return this.activeTabCommand !== null && this.activeTabCommand === 'comment' && this.activeTabParameter ? parseInt(this.activeTabParameter) : 0
			}
		}
	},
	methods: {
		getData: function (url){
			if (url === undefined) {
				url = this.dataUrl
			}
			axios.get(url).then((response) => {
				this.handleResponse(response)
			}).catch(function (error) {
			})
		},
		handleResponse: function(response){
			this.comments = response.data.data
			this.paginationLinks = response.data.meta.links

			//this.$emit('commentCount', response.data.meta.total)
		},
		saveItem: function(){
			axios.post(this.dataUrl, {content: this.newMessage}).then((response) => {
				this.getData()
			}).catch(function (error) {
			})
		},
	}
}
</script>

<style scoped>

</style>
