<template>
	<div class="d-flex align-items-center gap-2">
		<div class="flex-grow-1">
			<select-field
				:label="label"
				name="copyVariable"
				:field-only="true"
				v-model="insertVariable"
				:options="componentOptions"
				:errors="[]"
				@change="change"
			>
			</select-field>
		</div>
		<span class="badge badge-xl bg-success" v-if="message !== ''">{{ message }}</span>
	</div>
</template>

<script>
import SelectField from "./SelectField.vue";
import vSelect from "vue-select";
import UserLevelBadge from "../UserLevelBadge.vue";

export default {
	emits: ['change'],
	components: {UserLevelBadge, vSelect, SelectField},
	props: {
		label: String,
		options: [Array, Object],
	},
	mounted() {
	},
	data(){
		let componentOptions = this.options
		if (!_.isArray(componentOptions)){
			componentOptions = []
			_.forEach(this.options, function (v, k){
				componentOptions.push({
					id: k,
					name: v
				})
			})
		}
		return {
			insertVariable: null,
			message: '',
			timeout: null,
			componentOptions: componentOptions
		}
	},
	methods: {
		change: function (e){
			if (this.insertVariable) {
				let variable = this.insertVariable
				if (variable.indexOf('{') !== 0){
					variable = '{' + variable + '}'
				}
				navigator.clipboard.writeText(variable)
				this.message = 'Vágólapra másolva: ' + variable

				if (this.timeout !== null){
					clearTimeout(this.timeout)
				}
				this.timeout = setTimeout(() => {
					this.message = ''
					this.timeout = null
				}, 5000)
			}

			this.insertVariable = null
		}
	}
}
</script>

<style scoped>

</style>
