<template>
	<select-field
		:label="label"
		:name="name"
		:rules="rules"
		:get-option-label="formatLabel"
		v-model="componentValue"
		:options="options"
		:search="search"
		:errors="errors"
		:display-search-info-bottom="true"
	/>
</template>

<script>
import SelectField from "./SelectField.vue";
import {formatDate} from "../../functions";

export default {
	emits: ['update:modelValue'],
	components: {SelectField},
	props: {
		modelValue: Number,
		errors: Object,
		name: String,
		label: String,
		typeFilter: String,
		rules: {
			type: String,
			default: ''
		},
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	mounted() {
		this.search(null, '')
	},
	data(){
		return {
			options: []
		}
	},
	methods: {
		search: function (loading, search){
			let url = '/inventory/shipmentSearch?q=' + search
			if (this.typeFilter){
				url += '&type=' + this.typeFilter
			}

			fetch(
				url
			).then(res => {
				res.json().then(json => (this.options = json.results));
				if (loading !== null) {
					loading(false)
				}
			});
		},
		formatLabel: function(item){
			let source, destination

			if (item.public_id === undefined){
				return ''
			}

			if (item.supplier){
				source = item.supplier
			} else if (item.sourceWarehouse){
				source = item.sourceWarehouse
			} else if (item.clientName){
				source = item.clientName
			} else if (item.returnFromName){
				source = item.returnFromName
			}

			if (item.destinationWarehouse){
				destination = item.destinationWarehouse
			} else if (item.clientName){
				destination = item.clientName
			} else if (item.destination_name){
				destination = item.destination_name
			}

			return item.public_id + ' - ' + formatDate(item.created_at, false) + ' (' + source + ' -> ' + destination + ')'
		}
	}
}
</script>

<style scoped>

</style>
