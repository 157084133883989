<template>
	<div class="d-lg-none">
		<color-badge :color="color" :icon="icon">
			<attribute :label="label">
				<slot name="default" v-if="$slots.mobile === undefined"></slot>
				<slot name="mobile"></slot>
			</attribute>
		</color-badge>
	</div>
	<div class="d-none d-lg-block">
		<slot name="default"></slot>
	</div>
</template>

<script>
import ColorBadge from "./ColorBadge.vue";
import Attribute from "./Attribute.vue";

export default {
	components: {Attribute, ColorBadge},
	props: {
		color: String,
		icon: String,
		label: {
			type: String,
			default: ''
		}
	}
}
</script>

<style scoped>

</style>
