<template>
	{{ formattedValue }}
</template>

<script>
export default {
	props: {
		value: null,
	},
	computed: {
		formattedValue: {
			get(){
				if (this.value === null || !this.value){
					return ''
				}
				let seconds = Math.floor(this.value)
				let h, m, s, result=''
				h = Math.floor(seconds/3600)
				seconds -= h*3600
				if(h){
					result = h<10 ? '0'+h+':' : h+':'
				}
				m = Math.floor(seconds/60)
				seconds -= m * 60
				result += m < 10 ? '0'+m+':' : m+':'

				s = seconds % 60
				result += s<10 ? '0'+s : s
				return result
			}
		}
	}
}
</script>

<style scoped>

</style>
