<template>
	<modal
		:title="getModalTitle()"
		:loading="loading"
		:full-width="true"
		:buttons-on-top="true"
		:closable="true"
		:required-data="['products', 'productManufacturers', 'currencies']"
		@close="$emit('close')">

		<template #buttons>
			<button type="submit" class="btn btn-primary btn-label waves-effect" :disabled="!allCategoriesValid" v-if="isEditable
				&& ((!quote.id && projectDataStore.can('quotes', 'create'))
					|| (quote.id && projectDataStore.can('quotes', 'update')))
			" @click.prevent="saveForm">
				<i class="fas fa-save label-icon"></i> Mentés
			</button>
			<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing && quote.status === 'draft'" @click.prevent="$emit('startEditing')">
				<i class="fas fa-pencil label-icon"></i> Szerkesztés
			</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
				<i class="fas fa-times label-icon"></i> Bezár
			</button>

			<button-group :buttons-to-show="0" desktop>
				<template #approve v-if="app.hasPermission('ProjectsController_approveQuotes') && quote.id && editable && quote.status === 'draft'">
					<button type="submit" class="btn btn-success btn-label waves-effect" :disabled="!allCategoriesValid" @click.prevent="approveQuote">
						<i class="fas fa-check label-icon"></i> Jóváhagyás
					</button>
				</template>
				<template #duplicate v-if="quote.id && editable && projectDataStore.project.editable && projectDataStore.can('quotes', 'create')">
					<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="cloneQuote">
						<i class="far fa-clone label-icon"></i> Duplikálás
					</button>
				</template>
				<template #download v-if="quote.id && quote.status !== 'draft'">
					<a class="btn btn-secondary btn-label waves-effect" :href="'/projects/' + projectDataStore.projectId + '/downloadQuote/' + quote.id">
						<i class="far fa-download label-icon"></i> Letöltés
					</a>
				</template>
				<template #send v-if="quote.status === 'approved' && editable">
					<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="sendQuote">
						<i class="fas fa-envelope label-icon"></i> Küldés az ügyfélnek
					</button>
				</template>
			</button-group>

			<LockMessage :lock="projectDataStore.lock" :margin-bottom="false" :margin-left="true"></LockMessage>
		</template>

		<template #default>
			<div class="row">
				<div class="col-md-3">
					<ProjectInfoSidebar></ProjectInfoSidebar>
				</div>
				<div class="col-md-9">
					<div class="card">
						<div class="card-body">
							<div class="details-box">
								<div v-if="quote.id !== undefined">
									<i class="far fa-hashtag"></i> Azonosító:
									<strong>{{ quote.public_id }}</strong>
								</div>

								<data-loader :data="['quoteStatusLabels']" v-slot="{ dataStore }">
									<div v-if="dataStore.getData('quoteStatusLabels')[quote.status] !== undefined">
										<i class="far fa-question"></i> Státusz:
										<color-badge :value="dataStore.getData('quoteStatusLabels')[quote.status]"></color-badge>
									</div>
								</data-loader>
								<div v-if="quote.valid_until !== undefined && quote.valid_until">
									<i class="far fa-calendar-check"></i> Érvényesség:
									<strong><date-time :value="quote.valid_until" :time="false"/></strong>
								</div>
								<div v-if="projectTypeMainAttributeValue !== null && projectTypeMainAttributeValue[0]">
									<i class="far fa-ruler"></i> {{ projectTypeMainAttributeValue[4] }}:
									<strong>{{ projectTypeMainAttributeValue[0] }} {{ projectTypeMainAttributeValue[1] }}</strong>
								</div>

								<template v-for="currency in dataStore.getData('currencies')" v-if="quote.currency_values !== undefined">
									<div v-if="quote.currency_values['display_' + currency.id] !== undefined">
										<i class="far fa-coins"></i> {{ currency.name }} árfolyam:
										<strong>
											<Price
												:value="quote.currency_values['display_' + currency.id]"
												:decimals="2"
											/>
										</strong>
									</div>
								</template>
								<div v-if="quote.id !== undefined">
									<i class="far fa-language"></i> Nyelv:

									<data-loader :data="['languages']" v-slot="{ dataStore }">
										<strong>{{ dataStore.getData('languages', true)[quote.language_id].name }}</strong>
									</data-loader>
								</div>
								<template v-if="quote.id !== undefined">
									<div class="break"></div>

									<date-time-attribute :value="quote.created_at" created>
										<user-badge :user-id="quote.created_by_user_id" />
									</date-time-attribute>

									<date-time-attribute :value="quote.updated_at" updated>
										<user-badge :user-id="quote.updated_by_user_id" />
									</date-time-attribute>

									<template v-if="quote.approved_at">
										<div class="break"></div>

										<date-time-attribute :value="quote.approved_at" approved>
											<user-badge :user-id="quote.approved_by_user_id" />
										</date-time-attribute>
									</template>

									<template v-if="quote.sent_at">
										<div class="break"></div>
										<date-time-attribute :value="quote.sent_at" sent>
											<user-badge :user-id="quote.sent_by_user_id" />
										</date-time-attribute>

										<div class="break"></div>

										<date-time-attribute :value="quote.read_at" read display-if-empty />

										<date-time-attribute :value="quote.answered_at" answered>
											<user-badge :user-id="quote.answered_by_user_id" />
										</date-time-attribute>
									</template>
								</template>
							</div>
							<div v-if="quote.files && quote.files.length" class="mt-3">
								<FileList :files="quote.files" title="Aláírt ajánlat" />
							</div>
							<div class="mt-3">
								<InputField
									label="Ajánlat címe"
									name="quote_name"
									v-model="quote.title"
									:disabled="!isEditable"
									:errors="formErrors"
								/>
							</div>
							<div class="row">
								<div class="col-md-4">
									<InputField
										label="Kedvezmény"
										name="quote_discount"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="quote.discount"
										:disabled="quote.discount_limits.global === 0 || !isEditable"
										:errors="formErrors"
										:max="quote.discount_limits.global"
										@change="productChangedDebounced"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény anyag"
										name="quote_discount_material"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="quote.discount_material"
										:disabled="quote.discount_limits.material === 0 || !isEditable"
										:errors="formErrors"
										:max="quote.discount_limits.material"
										@change="productChangedDebounced"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény munka"
										name="quote_discount_work"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="quote.discount_work"
										:disabled="quote.discount_limits.work === 0 || !isEditable"
										:errors="formErrors"
										:max="quote.discount_limits.work"
										@change="productChangedDebounced"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény szolgáltatás"
										name="quote_discount_service"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="quote.discount_service"
										:disabled="quote.discount_limits.service === 0 || !isEditable"
										:errors="formErrors"
										:max="quote.discount_limits.service"
										@change="productChangedDebounced"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény közvetített szolgáltatás"
										name="quote_discount_relayed_service"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="quote.discount_relayed_service"
										:disabled="quote.discount_limits.relayedService === 0 || !isEditable"
										:errors="formErrors"
										:max="quote.discount_limits.relayedService"
										@change="productChangedDebounced"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Cél ár"
										name="target_price"
										data-type="number"
										:decimals="0"
										:suffix="dataStore.getData('currencies', true)[quote.currency_id].suffix !== null ? dataStore.getData('currencies', true)[quote.currency_id].suffix.replace('_', '') : ''"
										:prefix="dataStore.getData('currencies', true)[quote.currency_id].prefix !== null ? dataStore.getData('currencies', true)[quote.currency_id].prefix.replace('_', '') : ''"
										v-model="targetPrice"
										v-if="quote.discount_limits.global === null || quote.discount_limits.global !== 0 && isEditable"
										:errors="formErrors"
										@change="targetPriceChanged"
									/>
								</div>
								<div class="col-md-4" v-if="projectDataStore.projectType.has_custom_pricing">
									<switch-field
										label="Egyedi árszámítás engedélyezése"
										name="quote_use_project_type_pricing"
										v-model="quote.use_project_type_pricing"
										:disabled="!isEditable"
										:errors="formErrors"
										@input="productChanged"
									/>
								</div>
							</div>
							<div>
								<label>Megjegyzés/leírás</label>
								<TextareaAutosize
									v-model="quote.description"
									classes="form-control"
									:editable="isEditable"
									:rows="1"
								/>
							</div>
						</div>
					</div>

					<ProjectProductList
						:data-url="'/projects/' + projectDataStore.projectId + '/calculateQuotePrices'"
						:is-editable="isEditable"
						v-model="quoteProducts"
						:parent-model="quote"
						:product-versions="quote.product_versions"
						:initial-stats="priceStats"
						:initial-prices="productPrices"
						@validationChanged="validationChanged"
						@projectTypeMainAttributeValueChanged="projectTypeMainAttributeValueChanged"
						mode="quote"
						ref="productList"
					>

						<div v-if="quote.payment_plan">
							<label>Fizetési konstrukció</label>
							<h5>{{ quote.payment_plan.name }}</h5>
							<p class="text-muted mt-2" v-if="quote.payment_plan.description" v-html="quote.payment_plan.description_html"></p>
						</div>
					</ProjectProductList>

					<div class="card">
						<div class="card-body">
							<h4 class="card-title d-flex align-items-center mb-4">
								Paraméterek

								<button type="button" class="btn btn-primary btn-label btn-sm ms-auto" v-if="isEditable" @click.prevent="addAttribute"><i class="far fa-plus label-icon"></i> Paraméter hozzáadása</button>
							</h4>
							<table class="table">
								<thead>
								<tr>
									<th width="49%">Paraméter</th>
									<th width="49%">Érték</th>
									<th width="2%" v-if="isEditable">&nbsp;</th>
								</tr>
								</thead>
								<tbody>
								<template v-for="(attribute, attributeKey) in quote.quote_attributes">
									<tr>
										<td>
											<input type="text" class="form-control" v-model="attribute.label" v-if="isEditable" />
											<template v-else>
												{{ attribute.label }}
											</template>
										</td>
										<td>
											<input type="text" class="form-control" v-model="attribute.value" v-if="isEditable" />
											<template v-else>
												{{ attribute.value }}
											</template>
										</td>
										<td v-if="isEditable">
											<div class="d-flex gap-2">
												<button type="button" @click.prevent="moveAttribute(attributeKey, 'up')" class="btn btn-secondary mx-1"><i class="far fa-arrow-up"></i></button>
												<button type="button" @click.prevent="moveAttribute(attributeKey, 'down')" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
												<button type="button" @click.prevent="removeAttribute(attributeKey)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
											</div>
										</td>
									</tr>
								</template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import PhoneNumber from '../../components/PhoneNumber.vue';
import EmailAddress from '../../components/EmailAddress.vue';
import Price from '../../components/Price.vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import TextareaAutosize from "../../components/TextareaAutosize.vue";
import InputField from "../../components/form/InputField.vue";
import {moveValueInArray} from "../../functions";
import File from "../../components/File.vue";
import FileList from "../../components/FileList.vue";
import ProjectProductList from "./ProjectProductList.vue";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import LockMessage from "../../components/LockMessage.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import UserBadge from "../../components/badges/UserBadge.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useProjectDataStore} from "../../stores/projectData";
import {useGeneralDataStore} from "../../stores/generalData";
import Modal from "../../components/Modal.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import DateTime from "../../components/DateTime.vue";
import ButtonGroup from "../../components/ButtonGroup.vue";
import UserAttribute from "../../components/attributes/UserAttribute.vue";
import DateTimeAttribute from "../../components/attributes/DateTimeAttribute.vue";
export default {
	emits: ['close', 'closeAndReload', 'approve', 'send', 'clone', 'updateStats', 'startEditing', 'terminateQuote'],
	components: {
		DateTimeAttribute,
		UserAttribute,
		ButtonGroup,
		DateTime,
		Modal,
		ColorBadge, DataLoader,
		UserBadge,
		SwitchField,
		LockMessage,
		TableActionDropdown,
		ProjectInfoSidebar,
		ProjectProductList,
		FileList, File, InputField, PhoneNumber, EmailAddress, vSelect, Price, TextareaAutosize},
	props: {
		editable: Boolean,
		canStartEditing: Boolean,
		formErrors: Object,
		quoteId: {
			type: [Number, String],
			default: 0
		},
	},
	mounted() {
		this.dataStore.loadData(['products'], () => {
			this.loadData()
		})
	},
	data() {
		return {
			quote: {},
			quoteProducts: [],
			flashStore: useFlashStoreStore(),
			dataStore: useGeneralDataStore(),
			projectDataStore: useProjectDataStore(),
			app: useApplicationStore(),
			productPrices: {},
			priceStats: {},
			loading: true,
			targetPrice: null,
			allCategoriesValid: false,
			projectTypeMainAttributeValue: null
		};
	},
	computed: {
		isEditable: {
			get(){
				if (this.quote.id === undefined && !this.projectDataStore.can('quotes', 'create')){
					return false
				}
				if (this.quote.id !== undefined && !this.projectDataStore.can('quotes', 'update')){
					return false
				}
				if (!this.editable){
					return false
				}
				return this.quote.editable
			}
		},
	},
	methods: {
		moveValueInArray: moveValueInArray,
		loadData: function(){
			this.loading = true

			axios.get('/projects/' + this.projectDataStore.projectId + '/quote' + (this.quoteId ? '/' + this.quoteId : '')).then((response)=>{
				this.handleResponse(response)
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status === undefined || response.data.status === 'success') {
				this.quote = response.data.quote
				this.quoteProducts = this.quote.products_pivot

				this.productPrices = response.data.prices
				this.priceStats = response.data.stats

				if (response.data.projectTypeMainAttributeValue !== undefined){
					this.projectTypeMainAttributeValue = response.data.projectTypeMainAttributeValue
				}

				if (response.data.status !== undefined && response.data.status === 'success') {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}
		},
		approveQuote: function(){
			this.$emit('approve', this.quoteId);
		},
		sendQuote: function(){
			this.$emit('send', this.quoteId);
		},
		cloneQuote: function(){
			this.$emit('clone', this.quoteId);
		},
		emitClose: function(){
			this.$emit('close')
		},
		saveForm: function(e){
			if (this.allCategoriesValid){
				this.loading = true

				let quote = _.clone(this.quote)

				quote.optionals_included = _.filter(_.map(quote.optionals_included, function(i, k) { return i !== null && i > 0 ? k : null }), function(i, k) { return i !== null && i > 0 })

				let payload = {
					quote: quote,
					products: this.$refs.productList.getFilteredProducts()
				}

				axios.post('/projects/' + this.projectDataStore.projectId + '/saveQuote' + (this.quoteId ? '/' + this.quoteId : ''), payload).then((response)=>{
					this.handleResponse(response)
				}).catch((error) => {
					this.flashStore.addError(error)
				})
			}
		},
		productChanged: function(){
			if (this.dataStore.dataLoaded('products') !== undefined) {
				this.$refs.productList.productChanged()
			}
		},
		moveAttribute: function(key, dir){
			this.quote.quote_attributes = this.moveValueInArray(this.quote.quote_attributes, key, dir)
		},
		removeAttribute: function(key){
			this.quote.quote_attributes.splice(key, 1)
		},
		addAttribute: function(){
			this.quote.quote_attributes.push({
				label: '',
				value: ''
			})
		},
		getModalTitle: function (){
			if (this.quoteId){
				return this.editable ? 'Árajánlat szerkesztése' : 'Árajánlat'
			}

			return 'Árajánlat létrehozása'
		},
		validationChanged: function (valid){
			this.allCategoriesValid = valid
		},
		projectTypeMainAttributeValueChanged: function (value){
			this.projectTypeMainAttributeValue = value
		},
		productChangedDebounced: function (){
			let func = _.debounce(() => {
				this.productChanged()
			}, 10)

			func()
		},

		targetPriceChanged: function (){
			let func = _.debounce(() => {
				let current = this.$refs.productList.getTotalPrice(true, false)
				let percent = (1 - (this.targetPrice / current)) * 100

				this.targetPrice = null
				this.quote.discount = Math.round(percent * 1000000) / 1000000
				this.quote.discount_material = null
				this.quote.discount_work = null
				this.quote.discount_service = null
				this.quote.discount_relayed_service = null

				this.productChanged()
			}, 10)

			func()
		}
	}
}
</script>

<style scoped>

</style>
