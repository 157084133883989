<template>
	<TitleBar :title="selectedWarehouse ? selectedWarehouse.name + ' - Raktárkészlet' : 'Raktárkészlet'"/>

	<AdvancedDataTable
		:data-url="route('inventory.list') + (selectedWarehouse ? '/' + selectedWarehouse.id : '')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="inventory"
	>
		<template #navigation>
			<ButtonGroup>
				<template #add>
					<AddShipmentButton/>
				</template>
				<template #scan>
					<button type="button" class="btn btn-warning btn-label" v-if="!barcodeScannerActive"
							@click.prevent="enableBarcodeScanner"><i class="far fa-barcode-scan label-icon"></i>
						Vonalkódbeolvasás
					</button>
					<form v-else v-on:submit.prevent="barcodeSubmit">
						<input type="text" class="form-control" style="width: 300px" placeholder="Vonalkód"
							   ref="barcodeInput" v-model="barcode" @blur="disableBarcodeScanner"/>
					</form>
				</template>
				<template #detailed>
					<Link :href="route('inventory.detailed')" class="btn btn-secondary btn-label"><i
						class="far fa-clipboard-list label-icon"></i> Részletes készlet
					</Link>
				</template>
				<template #shipments>
					<Link :href="route('inventory.shipments')" class="btn btn-secondary btn-label"><i
						class="far fa-truck label-icon"></i> Szállítmányok
					</Link>
				</template>
				<template #delivered>
					<Link :href="route('inventory.delivered')" class="btn btn-secondary btn-label"><i
						class="far fa-truck-loading label-icon"></i> Kiszállított áruk
					</Link>
				</template>
				<template #export>
					<Link :href="route('inventory.export')"
							class="btn btn-secondary btn-label"><i class="far fa-file-export label-icon"></i> Export
					</Link>
				</template>
			</ButtonGroup>
		</template>

		<template #filters="{serverOptions}">
			<div class="data-table-filter-item" v-if="!selectedWarehouse">
				<warehouse-select-field
					v-model="serverOptions.warehouse_id"
					name="warehouse_id"
					label="Raktárak"
					:field-only="true"
				/>
			</div>
			<div class="data-table-filter-item">
				<product-category-select-field
					v-model="serverOptions.product_category_id"
					name="product_category_id"
					label="Kategória"
					:field-only="true"
				/>
			</div>
			<div class="data-table-filter-item">
				<product-manufacturer-select-field
					v-model="serverOptions.product_manufacturer_id"
					name="product_manufacturer_id"
					label="Gyártó"
					:field-only="true"
				/>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<product-badge
							:product-id="item.id"
							:product-manufacturer-id="item.product_manufacturer_id"
							:product-name="item.name"
							:product-category-id="item.product_category_id"
							:key="'PB' + item.id"
							mobile
						/>
						<div>
							<color-badge
								color="dark"
								icon="far fa-hashtag"
								:content="item.sku"/>
						</div>
						<div class="mobile-horizontal">
							<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
								<div v-if="dataStore.getData('productManufacturers', true)[item.product_manufacturer_id] !== undefined">
									<color-badge
										color="light"
										icon="far fa-industry"
										:content="dataStore.getData('productManufacturers', true)[item.product_manufacturer_id].name"
									></color-badge>
								</div>
							</data-loader>
							<data-loader :data="['productCategories']" v-slot="{ dataStore }">
								<div v-if="dataStore.getData('productCategories', true)[item.product_category_id] !== undefined">
									<color-badge
										color="light"
										icon="far fa-folder-open"
										:content="dataStore.getData('productCategories', true)[item.product_category_id].name"
									></color-badge>
								</div>
							</data-loader>
						</div>
						<div class="mobile-horizontal">
							<color-badge :color="item.quantity > 0 ? 'info' : 'danger'">
								Mennyiség: <number :value="item.quantity"/>
							</color-badge>
							<color-badge :color="item.reserved > 0 ? 'warning' : 'secondary'">
								Foglalva: <number :value="item.reserved"/>
							</color-badge>
							<color-badge :color="parseInt(item.quantity) > parseInt(item.reserved) ? 'success' : 'warning'">
								Szabad: <number :value="item.quantity - item.reserved"/>
							</color-badge>
						</div>
					</div>
				</div>
				<div class="table-row-operations d-flex gap-2">
					<Link :href="route('inventory.product') + '/' + item.id"
						  class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="far fa-eye"></i>
					</Link>
				</div>
			</div>
		</template>
		<template #item-category="item">
			<data-loader :data="['productCategories']" v-slot="{ dataStore }">
				{{ dataStore.getData('productCategories', true)[item.product_category_id].name }}
			</data-loader>
		</template>
		<template #item-quantity="item">
			<color-badge :color="item.quantity > 0 ? 'info' : 'danger'">
				<number :value="item.quantity"/>
			</color-badge>
		</template>
		<template #item-name="item">
			<product-badge
				:product-id="item.id"
				:product-manufacturer-id="item.product_manufacturer_id"
				:product-name="item.name"
				:product-category-id="item.product_category_id"
				:key="'PB' + item.id"
				desktop
			/>
		</template>
		<template #item-reserved="item">
			<color-badge :color="item.reserved > 0 ? 'warning' : 'secondary'">
				<number :value="item.reserved"/>
			</color-badge>
		</template>
		<template #item-available="item">
			<color-badge :color="parseInt(item.quantity) > parseInt(item.reserved) ? 'success' : 'warning'">
				<number :value="item.quantity - item.reserved"/>
			</color-badge>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import ProductCategorySelectField from "../components/form/ProductCategorySelectField.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {usePage, Link} from "@inertiajs/vue3";
import TitleBar from "../components/TitleBar.vue";
import {useGeneralDataStore} from "../stores/generalData";
import AddShipmentButton from "../modules/inventory/AddShipmentButton.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import ColorBadge from "../components/ColorBadge.vue";
import Price from "../components/Price.vue";
import Number from "../components/Number.vue";
import ProductManufacturerSelectField from "../components/form/ProductManufacturerSelectField.vue";
import ProjectBadge from "../components/badges/ProjectBadge.vue";
import ProductBadge from "../components/badges/ProductBadge.vue";
import Mobile from "../components/responsive/Mobile.vue";
import Desktop from "../components/responsive/Desktop.vue";

export default {
	components: {
		Desktop,
		Mobile,
		ProductBadge,
		ProjectBadge,
		ProductManufacturerSelectField,
		Number,
		Price, ColorBadge,
		ButtonGroup,
		AddShipmentButton,
		TitleBar, DataLoader, AdvancedDataTable, WarehouseSelectField, ProductCategorySelectField, Link
	},
	props: {
		selectedWarehouse: Object
	},
	layout: AppLayout,
	data() {
		let columns = [
			{
				text: 'Cikkszám',
				value: 'sku',
				sortable: true
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Mennyiség',
				value: 'quantity',
				sortable: true,
				align: 'right'
			},
			{
				text: 'Egység',
				value: 'unit',
				sortable: true
			},
			{
				text: 'Lefoglalva',
				value: 'reserved',
				align: 'right',
				sortable: true
			},
			{
				text: 'Szabad készlet',
				value: 'available',
				align: 'right',
				sortable: false
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]
		const page = usePage()

		return {
			columns: columns,
			barcode: '',
			barcodeScannerActive: false,
			successSound: '',
			errorSound: '',
			application: useApplicationStore(),
			dataStore: useGeneralDataStore(),
			csrf: page.props.csrf_token,
		}
	},
	created() {
		this.successSound = new Audio('/audio/inventory-scan-success.mp3')
		this.successSound.preload = true

		this.errorSound = new Audio('/audio/inventory-scan-error.mp3')
		this.errorSound.preload = true
	},
	methods: {
		enableBarcodeScanner: function () {
			this.barcode = ''
			this.barcodeScannerActive = true

			setTimeout(() => {
				this.$refs.barcodeInput.focus()
			}, 100)
		},
		disableBarcodeScanner: function () {
			this.barcode = ''
			this.barcodeScannerActive = false
		},
		playSound: function (audio) {
			if (!audio.paused) {
				audio.pause()
			}
			audio.currentTime = 0
			audio.play()
		},
		barcodeSubmit: function () {
			if (this.barcode.length < 6) {
				return
			}
			axios.get('/inventory/searchByBarcode?barcode=' + this.barcode, {}).then((response) => {
				if (!response.data.success) {
					this.playSound(this.errorSound)
				}

				if (response.data.redirect !== undefined) {
					location.href = response.data.redirect
				}

				this.flash = response.data.flash
			})
				.catch((error) => {
					this.barcode = ''

					this.flash = [{message: 'Hiba történt', level: 'danger'}]
				})
		},
	}
}
</script>
