<script setup>
import InputField from "./form/InputField.vue";

const props = defineProps({
	editable: Boolean,
	errors: Object
})

const model = defineModel()
</script>

<template>
	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-4">Adható kedvezmények</h4>

			<p class="text-muted text-info mt-2">
				A 0 érték letiltja a kedvezmény adását, egyéb érték limitálja az adható kedvezményt. Amennyiben a mező üres, nem befolyásolja az adható kedvezményt.<br />
				Amennyiben több helyről (szerepek és felhasználói szint) van limitálva a kedvezmény, a legmagasabb érték lesz használva.<br />
				A felhasználó adatlapján beállított limitek felülírják a szerepek és a felhasználói szint limitjeit.<br />
			</p>

			<div class="row">
				<div class="col-sm-6">
					<InputField
						label="Max kedvezmény"
						name="discount_limit"
						data-type="number"
						:decimals="2"
						suffix-icon="far fa-percent"
						v-model="model.discount_limit"
						:disabled="!editable"
						:errors="errors"
					/>
				</div>
				<div class="col-sm-6">
					<InputField
						label="Max kedvezmény anyag"
						name="discount_limit_material"
						data-type="number"
						:decimals="2"
						suffix-icon="far fa-percent"
						v-model="model.discount_limit_material"
						:disabled="!editable"
						:errors="errors"
					/>
				</div>
				<div class="col-sm-6">
					<InputField
						label="Max kedvezmény munka"
						name="discount_limit_work"
						data-type="number"
						:decimals="2"
						suffix-icon="far fa-percent"
						v-model="model.discount_limit_work"
						:disabled="!editable"
						:errors="errors"
					/>
				</div>
				<div class="col-sm-6">
					<InputField
						label="Max kedvezmény szolgáltatás"
						name="discount_limit_service"
						data-type="number"
						:decimals="2"
						suffix-icon="far fa-percent"
						v-model="model.discount_limit_service"
						:disabled="!editable"
						:errors="errors"
					/>
				</div>
				<div class="col-sm-6">
					<InputField
						label="Max kedvezmény közvetített szolgáltatás"
						name="discount_limit_relayed_service"
						data-type="number"
						:decimals="2"
						suffix-icon="far fa-percent"
						v-model="model.discount_limit_relayed_service"
						:disabled="!editable"
						:errors="errors"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>

</style>
