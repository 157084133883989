<template>
	<div :class="{'mb-3': !fieldOnly}">
		<label v-if="!fieldOnly" :class="{required: isRequired}">{{ label }}</label>

		<div :class="{'date-invalid': errors[name]}">
			<Field
				v-model="inputValue"
				:name="name"
				:readonly="disabled"
				:disabled="disabled"
				:rules="validatePhoneField"
				v-slot="{ field }"
				mode="eager"
				:class="{'d-none': 1, 'is-invalid': errors[name]}"
			>
				<vue-tel-input
					v-model="inputValue"
					@validate="onValidate"
					@on-input="onInput"
					:valid-characters-only="true"
					:disabled="disabled"
					mode="international"
					:input-options="{placeholder: 'Adjon meg telefonszámot'}"
					default-country="hu"
					ref="phoneInput"
				/>
			</Field>
			<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
			<p class="text-muted mt-2 text-info" v-if="info" v-html="info"></p>
		</div>
	</div>
</template>

<script>
import {VueTelInput} from "vue-tel-input";
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import 'vue-tel-input/vue-tel-input.css';
export default {
	components: {VueTelInput, Field, ErrorMessage },
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		errors: {
			type: Object,
			default: function(){
				return {}
			}
		},
		label: String,
		name: String,
		modelValue: [String, Date],
		info: {
			type: String,
			default: ''
		},
		fieldOnly: Boolean
	},
	data() {
		return {
			phoneObj: {},
			inputValue: this.modelValue
		}
	},
	mounted() {
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		isRequired: {
			get(){
				return this.rules.indexOf('required') > -1
			}
		},
		rulesValue: {
			get() {
				let tmp = this.rules.split(',')
				let rules = yup.string()
				if (tmp.indexOf('required') > -1){
					rules = rules.required(() => {
						return this.$t(':field kitöltése kötelező', {field: this.label})
					})
				} else {
					rules = rules.notRequired()
				}
				rules = rules.label(this.label)

				return rules
			}
		},
	},
	methods: {
		onValidate: function(obj){
			this.phoneObj = obj
		},
		validatePhoneField: function (value){
			if (this.rules.indexOf('required') > -1 && (value === undefined || value === null || !value.trim())){
				return this.$t(':field kitöltése kötelező', {field: this.label})
			}
			if (value === undefined || value === null){
				return true
			}

			if (value.trim()){
				if (!this.$refs.phoneInput.phoneObject.valid){
					return this.$t(':field érvénytelen', {field: this.label})
				}
			}

			return true
		},
		onInput: function (value, obj){
			this.componentValue = obj.number !== undefined ? obj.number : value
		}
	}
}
</script>

<style scoped>
.mx-datepicker {
	display: block !important;
	width: 100% !important;
}
</style>
