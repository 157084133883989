<template>
	<TitleBar :title="attributes.id !== undefined ? 'Szerep módosítása' : 'Szerep hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('roles.store', model.id)"
		:back-url="route('roles.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<switch-field
								label="Szuperadmin"
								name="is_superadmin"
								v-model="attributes.is_superadmin"
								:disabled="!attributes.editable"
							/>
						</div>
					</div>
				</div>
			</div>
			<discount-limits v-model="attributes" :editable="editable" :errors="errors" />
			<template v-if="!attributes.is_superadmin">
				<div v-for="module in modulePermissions">
					<div class="card mb-3" v-if="module.permissions.length">
						<div class="card-body">
							<h4 class="card-title mb-4"><i :class="module.icon"></i> {{ module.name }}</h4>
							<div class="row">
								<div class="col-md-3" v-for="permission in module.permissions">
									<div class="form-check form-switch form-check-inline mb-2">
										<input class="form-check-input" v-model="attributes.permissions" type="checkbox" :id="'permission_' + permission.id" :value="permission.id">
										<label class="form-check-label" :for="'permission_' + permission.id">{{permission.actionName}}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import DiscountLimits from "../components/DiscountLimits.vue";

export default {
	components: {DiscountLimits, UpdatedAttribute, SwitchField, InputField, FormWrapper, TitleBar},
	layout: AppLayout,
	props: {
		model: Object,
		modulePermissions: Array
	},
	data(){
		return {
			attributes: this.model,
			loading: false
		}
	},
	created: function () {
		this.moment = window.moment;
		let tmp = []
		for (let i in this.attributes.permissions){
			tmp.push(this.attributes.permissions[i].id)
		}
		this.attributes.permissions = tmp

	},
}
</script>
