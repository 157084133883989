<template>
	<div class="p-2 d-flex gap-2" v-if="canCreate">
		<div class="flex-grow-1">
			<TextareaAutosize
				v-model="newMessage"
				placeholder="Üzenet írása"
			/>
		</div>
		<button type="button" class="btn btn-primary" :disabled="!newMessage.length" @click.prevent="saveItem">
			<span class="d-none d-md-inline">Küldés </span><i class="far fa-paper-plane"></i>
		</button>
	</div>

	<template v-for="comment in comments">
		<div class="embedded-comment">
			<div class="embedded-comment--icon">
				{{ comment.initials }}
			</div>
			<div :class="{'embedded-comment--message': 1, 'is-me': comment.is_me}">
				<div class="embedded-comment--message-head">
					<strong>{{ comment.user_name }}</strong> <p class="text-muted"><date-time :value="comment.created_at"/></p>
				</div>
				<div v-html="comment.content_html"></div>
			</div>
		</div>
	</template>
</template>

<script>
import TextareaAutosize from "./TextareaAutosize.vue";
import DateTime from "./DateTime.vue";
import Attribute from "./Attribute.vue";
export default {
	components: {Attribute, DateTime, TextareaAutosize},
	emits: ['commentCount'],
	props: {
		dataUrl: String,
		highlightComment: Number,
		canCreate: Boolean,
		activeTabCommand: String,
		activeTabParameter: String,
		embeded: Boolean
	},
	data(){
		return {
			newMessage: '',
			comments: [],
			paginationLinks: []
		}
	},
	mounted() {
		this.getData()
	},
	computed: {
		highlightedComment: {
			get() {
				return this.activeTabCommand !== null && this.activeTabCommand === 'comment' && this.activeTabParameter ? parseInt(this.activeTabParameter) : 0
			}
		}
	},
	methods: {
		getData: function (url){
			if (url === undefined) {
				url = this.dataUrl
			}
			axios.get(url).then((response) => {
				this.handleResponse(response)
			}).catch(function (error) {
			})
		},
		handleResponse: function(response){
			this.comments = response.data.data
			this.paginationLinks = response.data.meta.links

			this.$emit('commentCount', response.data.meta.total)
		},
		saveItem: function(){
			axios.post(this.dataUrl, {content: this.newMessage}).then((response) => {
				this.getData()
			}).catch(function (error) {
			})
		},
	}
}
</script>

<style scoped>

</style>
