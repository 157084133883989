<template>
	<TitleBar title="Regisztráció" />

	<div>
		<p class="text-muted">Ajánló: {{ referer.name }}</p>
	</div>
	<div class="mt-4">
		<div class="alert alert-success" v-if="showSuccess">
			Profilod sikeresen létrehozva. Amint munkatársaink jóváhagyták regisztrációdat, e-mailben értesítünk.
		</div>

		<form-wrapper
			v-else
			ref="formWrapper"
			:store-url="'/register/' + refererHash"
			v-model="attributes"
			:show-back-button="false"
			:show-top-buttons="false"
			:show-bottom-buttons="true"
			:disable-redirect="true"
			v-slot="{ errors }"
			@after-submit="userCreated"
		>
			<div class="row">
				<div class="col-sm-6">
					<radio-field
						label="Típus"
						name="type"
						v-model="attributes.type"
						:values="typeLabels"
						:errors="errors"
						rules="required"
						:display-badge="true"
						data-type="string"
						:vertical="true"
						:full-width="true"
					>
					</radio-field>

					<input-field
						label="Vezetéknév"
						name="last_name"
						rules="required"
						v-model="attributes.last_name"
						:errors="errors"
					/>

					<input-field
						label="Keresztnév"
						name="first_name"
						rules="required"
						v-model="attributes.first_name"
						:errors="errors"
					/>

					<input-field
						label="Cégnév"
						name="company_name"
						v-model="attributes.company_name"
						rules="required"
						:errors="errors"
						v-if="['company'].indexOf(attributes.type) > -1"
					/>

					<input-field
						label="Bankszámlaszám"
						name="bank_account_id"
						v-model="attributes.bank_account_id"
						:errors="errors"
						v-if="['independentContractor', 'company'].indexOf(attributes.type) > -1"
					/>
				</div>
				<div class="col-sm-6">
					<input-field
						label="E-mail cím"
						name="email"
						data-type="email"
						rules="required"
						v-model="attributes.email"
						:errors="errors"
					/>

					<input-field
						label="Jelszó"
						name="password"
						data-type="password"
						rules="min:8|required"
						v-model="attributes.password"
						:errors="errors"
					/>

					<input-field
						label="Jelszó újra"
						name="password_confirmation"
						data-type="password"
						rules="required"
						:equal-to="attributes.password"
						v-model="attributes.password_confirmation"
						:errors="errors"
					/>

					<phone-field
						label="Telefonszám"
						name="phone"
						rules="required"
						v-model="attributes.phone"
						:errors="errors"
					/>

					<input-field
						label="Adószám"
						name="tax_number"
						v-model="attributes.tax_number"
						rules="required"
						:errors="errors"
						v-if="['independentContractor', 'company'].indexOf(attributes.type) > -1"
					/>

					<input-field
						label="Cégjegyzékszám"
						name="company_id"
						v-model="attributes.company_id"
						rules="required"
						:errors="errors"
						v-if="['company'].indexOf(attributes.type) > -1"
					/>
				</div>
			</div>
			<div class="mt-3" v-if="['independentContractor', 'company'].indexOf(attributes.type) > -1">
				<h4 class="card-title mb-4">Cím</h4>
				<address-input
					v-model="attributes.address"
					attribute-name="address"
					:required="true"
					:errors="errors"
					:editable="true"
					:narrow="true"
				/>
			</div>
		</form-wrapper>
	</div>
</template>

<script>
import SwitchField from "../components/form/SwitchField.vue";
import TitleBar from "../components/TitleBar.vue";
import InputField from "../components/form/InputField.vue";
import {Link} from "@inertiajs/vue3";
import FormWrapper from "../components/FormWrapper.vue";
import AddressInput from "../components/AddressInput.vue";
import RadioField from "../components/form/RadioField.vue";
import DataLoader from "../components/DataLoader.vue";
import AuthWideLayout from "../layouts/AuthWideLayout.vue";
import PhoneField from "../components/form/PhoneField.vue";

export default {
	components: {PhoneField, DataLoader, RadioField, AddressInput, FormWrapper, Link, InputField, TitleBar, SwitchField},
	layout: AuthWideLayout,
	props: {
		referer: Object,
		refererHash: String,
		initialModel: Object,
		typeLabels: Object,
		countries: Array
	},
	data(){
		let attributes = this.initialModel

		attributes.editable = 1
		if (attributes.address === null || attributes.address === undefined){
			attributes.address = {}
		}

		return {
			attributes: attributes,
			showSuccess: false
		}
	},
	methods: {
		userCreated: function (){
			this.showSuccess = true
		}
	}
}
</script>

<style scoped>

</style>
