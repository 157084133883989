<template>
	<div class="mb-3" v-if="app.environment === 'staging' || app.environment === 'local'">
		<span class="env-marker staging" v-if="app.environment === 'staging'">Staging</span>
		<span class="env-marker local" v-if="app.environment === 'local'">Local</span>
	</div>
</template>

<script>
import {useApplicationStore} from "../stores/applicationStore";
export default {
	data(){
		return {
			app: useApplicationStore()
		}
	},
}
</script>
