<template>
	<TitleBar :title="attributes.id !== undefined ? 'Projekttípus módosítása' : 'Projekttípus hozzáadása'" />

	<div class="project-type-form">
		<form-wrapper
			ref="formWrapper"
			:back-url="route('projectTypes.index')"
			v-model="attributes"
			:submit-function="saveForm"
			:tabs="tabs"
			:display-tabs="!!attributes.id"
		>
			<template v-slot:top>
				<div class="card mb-3" v-if="attributes.id !== undefined">
					<div class="card-body">
						<div class="details-box">
							<updated-attribute :data="attributes" created/>
							<updated-attribute :data="attributes" updated/>
						</div>
					</div>
				</div>
			</template>

			<template #default="{activeTab, errors, editable}">
				<template v-if="activeTab === 'attributes' && loaded">
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">Típus adatok</h4>
							<div class="row">
								<div class="col-md-6">
									<switch-field
										label="Aktív"
										name="active"
										v-model="attributes.active"
									></switch-field>

									<input-field
										label="Név"
										name="name"
										v-model="attributes.name"
										rules="required"
										:multilang="true"
										:languages="languages"
										v-model:translations="attributes.translations"
									/>

									<input-field
										label="Publikus név"
										name="name_public"
										v-model="attributes.name_public"
										rules="required"
										:multilang="true"
										:languages="languages"
										v-model:translations="attributes.translations"
									/>

									<input-field
										label="Név árajánlatban magánszemélynél"
										name="name_in_quote_consumer"
										v-model="attributes.name_in_quote_consumer"
										rules="required"
										:multilang="true"
										:languages="languages"
										v-model:translations="attributes.translations"
									/>

									<input-field
										label="Név árajánlatban cégnél"
										name="name_in_quote_company"
										v-model="attributes.name_in_quote_company"
										:multilang="true"
										:languages="languages"
										v-model:translations="attributes.translations"
										rules="required"
									/>

									<input-field
										label="Weboldal page ID"
										name="website_page_id"
										v-model="attributes.website_page_id"
									/>

									<order-field
										v-model="attributes"
										label="Sorrend"
									/>

									<input-field
										label="Minimum befizetés"
										name="minimum_payment_percent"
										v-model="attributes.minimum_payment_percent"
										data-type="number"
										rules="required"
										:min="1"
										:max="100"
										suffix="%"
									/>

									<input-field
										label="Minimum garanciaidő"
										name="min_warranty_months"
										v-model="attributes.min_warranty_months"
										data-type="number"
										:min="0"
										suffix="hónap"
									/>
								</div>
								<div class="col-md-6">
									<switch-field
										label="Publikus"
										name="public"
										v-model="attributes.public"
									></switch-field>

									<iconpicker-field
										label="Ikon"
										name="icon"
										v-model="attributes.icon"
										rules="required"
									/>

									<input-field
										label="Szín"
										name="color"
										v-model="attributes.color"
										data-type="color"
										rules="required"
									/>

									<radio-field
										label="Betüszín"
										name="luminosity"
										v-model="attributes.luminosity"
										:values="{dark: {name: 'Világos'},light: {name: 'Sötét'},}"
									/>

									<input-field
										label="Árajánlat érvényesség"
										name="quote_expiration"
										v-model="attributes.quote_expiration"
										data-type="number"
										rules="required"
										:step="1"
										:min="1"
										suffix="nap"
									/>

									<select-field
										label="Adatbekérő küldése az ügyfélnek"
										v-model="attributes.client_input_notification"
										name="client_input_notification"
										data-type="string"
										:options="clientInputNotificationSelectOptions"
										rules="required"
										:reduce="item => item.value"
									></select-field>

									<select-field
										label="Termékek kiszállíthatóak"
										v-model="attributes.product_deliverability"
										name="product_deliverability"
										data-type="string"
										:options="[{id: 'always', name: 'Mindig'}, {id: 'minimumPaid', name: 'Minimum összeg befizetve'}, {id: 'paid', name: 'Teljes összeg fizetve'}]"
										rules="required"
									></select-field>

									<div class="alert alert-info">
										Az alábbi két mező szabja meg, hogy az árajánlatok és szerződések mellett milyen terméktulajdonság jelenik meg összesítve.
									</div>

									<select-field
										label="Fő tulajdonság termékkategória"
										v-model="attributes.main_attribute_product_category_id"
										name="main_attribute_product_category_id"
										:options="productCategories"
									></select-field>

									<select-field
										label="Fő tulajdonság"
										v-model="attributes.main_attribute_product_attribute_id"
										name="main_attribute_product_attribute_id"
										:options="productAttributes"
										rules="required"
									></select-field>
								</div>
							</div>
						</div>
					</div>
				</template>

				<div v-if="activeTab === 'fields'">
					<div class="mb-3 mt-3">
						<div class="d-flex flex-wrap gap-2 align-items-center">
							<button type="button" class="btn btn-secondary btn-label" @click.prevent="openAllFields"><i class="far fa-eye label-icon"></i> Mind kinyit</button>
							<button type="button" class="btn btn-secondary btn-label" @click.prevent="closeAllFields"><i class="far fa-eye-slash label-icon"></i> Mind becsuk</button>
						</div>
					</div>
					<div class="project-type--list">
						<div v-for="(field, fieldKey) in fields" :key="'fieldWrapper_' + fieldKey">
							<div class="project-type--field" @mouseenter="mouseEntersField(fieldKey, $event)">
								<ProjectTypeField
									v-model="fields[fieldKey]"
									:field-key="fieldKey"
									:isOpen="openFields[fieldKey]"
									:ref="'field_' + fieldKey"
									:key="'field_' + fieldKey"
									:field-types="fieldTypes"
									:parent-order="0"
									:parent-key="null"
									:languages="languages"
									:fields="fields"
									:errors="errors"
									@move-field="moveField"
									@remove-field="removeField"
									@move-field-value="moveFieldValue"
									@remove-field-value="removeFieldValue"
									@add-empty-field-value="addEmptyFieldValue"
									@toggle-field-visibility="toggleFieldVisibility"
								></ProjectTypeField>

								<div v-if="field.children !== undefined && field.children.length" class="project-type--subfield-list">
									<div v-for="(subfield, subfieldKey) in field.children" :key="'fieldWrapper_' + fieldKey + '_' + subfieldKey">
										<div class="project-type--field project-type--subfield">
											<ProjectTypeField
												v-model="fields[fieldKey].children[subfieldKey]"
												:field-key="subfieldKey"
												:isOpen="openFields[fieldKey + '_' + subfieldKey]"
												:ref="'field_' + fieldKey + '_' + subfieldKey"
												:key="'field_' + fieldKey + '_' + subfieldKey"
												:parent-order="field.order"
												:parent-key="fieldKey"
												:field-types="fieldTypes"
												:languages="languages"
												:fields="fields"
												:errors="errors"
												@move-field="moveField"
												@remove-field="removeField"
												@move-field-value="moveFieldValue"
												@remove-field-value="removeFieldValue"
												@add-empty-field-value="addEmptyFieldValue"
												@toggle-field-visibility="toggleFieldVisibility"
											></ProjectTypeField>
										</div>
									</div>
								</div>
								<div class="project-type--add-field" v-if="openFields[fieldKey]">
									<div><i class="far fa-plus btn-label"></i> Mező hozzáadása alá</div>
									<template v-for="(typeDetails, type) in fieldTypes">
										<a class="btn btn-secondary" v-if="fields[fieldKey].type !== 'multirow' || ['text', 'textarea', 'number', 'select', 'bool', 'date'].indexOf(type) > -1" href="#" @click.prevent="addSubfield(type, fieldKey, $event)" :data-type="type">
											<i :class="typeDetails.icon + ' label-icon'"></i> {{ typeDetails.name }}
										</a>
									</template>
								</div>
							</div>
							<div class="project-type--add-field" v-if="hoveredItem === fieldKey">
								<div><i class="far fa-plus"></i> Mező hozzáadása</div>
								<template v-for="(typeDetails, type) in fieldTypes">
									<a class="btn btn-secondary btn-label" href="#" @click.prevent="addField(type, fieldKey, $event)" :data-type="type">
										<i :class="typeDetails.icon + ' label-icon'"></i> {{ typeDetails.name }}
									</a>
								</template>
							</div>
						</div>
					</div>
					<div class="project-type--add-field" v-if="fields.length < 1">
						<div><i class="far fa-plus btn-label"></i> Mező hozzáadása</div>
						<template v-for="(typeDetails, type) in fieldTypes">
							<a class="btn btn-secondary" href="#" @click.prevent="addField(type, 0, $event)" :data-type="type">
								<i :class="typeDetails.icon + ' label-icon'"></i> {{ typeDetails.name }}
							</a>
						</template>
					</div>
					<div class="mt-3">
						<div class="d-flex flex-wrap gap-2 align-items-center">
							<button type="button" class="btn btn-secondary btn-label" @click.prevent="openAllFields"><i class="far fa-eye label-icon"></i> Mind kinyit</button>
							<button type="button" class="btn btn-secondary btn-label" @click.prevent="closeAllFields"><i class="far fa-eye-slash label-icon"></i> Mind becsuk</button>
						</div>
					</div>
				</div>

				<div v-if="activeTab === 'documents'">
					<ProjectTypeDocumentTypes
						v-model="documentTypes"
						:languages="languages"
						:errors="errors"
					></ProjectTypeDocumentTypes>
				</div>

				<template v-if="activeTab === 'documentBundles'">
					<ProjectTypeDocumentBundles
						v-model="documentBundles"
						:languages="languages"
						:errors="errors"
						:forms="forms"
						:milestones="milestones"
						:document-categories="documentTypes"
						:fields="fields"
						:document-tags="documentTags"
					></ProjectTypeDocumentBundles>
				</template>

				<div v-if="activeTab === 'milestones'">
					<ProjectTypeMilestones
						v-model="milestones"
						:document-types="documentTypes"
						:roles="userRoles"
						:forms="forms"
						:languages="languages"
						:errors="errors"
						:document-bundles="documentBundles"
					></ProjectTypeMilestones>
				</div>

				<div v-if="activeTab === 'products'">
					<ProjectTypeProducts
						v-model="productCategoryAttributes"
						:product-categories="productCategories"
						v-if="productCategoryAttributes !== undefined && Object.keys(productCategoryAttributes).length > 1"
						:product-tags="productTags"
						:optionals-selected="attributes.optionals_selected"
						@update:optionals-selected="updateOptionalsSelected"
						:tags="tags"
						:products="products"
					></ProjectTypeProducts>
				</div>

				<div v-if="activeTab === 'paymentPlans'">
					<ProjectTypePaymentPlans
						v-model="paymentPlans"
						@validate="validateForm"
						:languages="languages"
						:fields="fields"
						:errors="errors"
					></ProjectTypePaymentPlans>
				</div>

				<div v-if="activeTab === 'roles'">
					<ProjectTypeRoles
						v-model="roles"
						:errors="errors"
						:role-rights="roleRights"
						:role-notifications="roleNotifications"
						@validate="validateForm"
					></ProjectTypeRoles>
				</div>

				<div v-if="activeTab === 'pricing' && attributes.pricing !== undefined">
					<ProjectTypePricing
						v-model="attributes.pricing"
						:product-types="productTypes"
						@validate="validateForm"
					></ProjectTypePricing>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import SelectField from "../components/form/SelectField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import IconpickerField from "../components/form/IconpickerField.vue";
import ProjectTypeRoles from "../modules/projectTypes/ProjectTypeRoles.vue";
import ProjectTypeProducts from "../modules/projectTypes/ProjectTypeProducts.vue";
import FormWrapper from "../components/FormWrapper.vue";
import ProjectTypePricing from "../modules/projectTypes/ProjectTypePricing.vue";
import ProjectTypeField from "../modules/projectTypes/ProjectTypeField.vue";
import InputField from "../components/form/InputField.vue";
import RadioField from "../components/form/RadioField.vue";
import ProjectTypePaymentPlans from "../modules/projectTypes/ProjectTypePaymentPlans.vue";
import ProjectTypeDocumentTypes from "../modules/projectTypes/ProjectTypeDocumentTypes.vue";
import ProjectTypeMilestones from "../modules/projectTypes/ProjectTypeMilestones.vue";
import ProjectTypeDocumentBundles from "../modules/projectTypes/ProjectTypeDocumentBundles.vue";
import {useFlashStoreStore} from "../stores/flashStore";
import moment from "moment/moment";
import {moveValueInArray} from "../functions";
import OrderField from "../components/form/OrderField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	layout: AppLayout,
	components: {
		UpdatedAttribute,
		OrderField,
		ProjectTypeDocumentBundles,
		ProjectTypeMilestones,
		ProjectTypeDocumentTypes,
		ProjectTypePaymentPlans,
		RadioField,
		InputField,
		ProjectTypeField,
		ProjectTypePricing,
		FormWrapper,
		ProjectTypeProducts,
		ProjectTypeRoles,
		IconpickerField,
		SwitchField,
		SelectField,
		TitleBar},
	props: {
		formId: Number,
		fieldTypes: Object,
		userRoles: Array,
		productCategories: Array,
		productTypes: Object,
		roleRights: Object,
		roleNotifications: Object,
		languages: Array,
		tags: Array,
		products: Object,
		documentTags: Array,
		productAttributes: Array,
	},
	data(){
		return {
			fields: [],
			clientInputNotification: '',
			websitePageId: '',
			loaded: false,
			attributes: {
				priority: 0
			},
			flash: [],
			projectTypeId: this.formId,
			alertTimeout: null,
			hoveredItem: -1,
			flashStore: useFlashStoreStore(),
			fieldsOpen: [],
			forms: [],
			activeTab: 'fields',
			documentTypes: [],
			documentBundles: [],
			milestones: [],
			paymentPlans: [],
			roles: [],
			productTags: [],
			productCategoryAttributes: {},
			tabs: [
				{
					name: 'attributes',
					icon: 'fas fa-file',
					label: 'Adatok'
				},
				{
					name: 'fields',
					icon: 'fas fa-file-spreadsheet',
					label: 'Mezők'
				},
				{
					name: 'documents',
					icon: 'fas fa-folder-open',
					label: 'Dokumentumok'
				},
				{
					name: 'documentBundles',
					icon: 'fas fa-box-check',
					label: 'Dokumentum csomagok'
				},
				{
					name: 'milestones',
					icon: 'fas fa-flag',
					label: 'Mérföldkövek'
				},
				{
					name: 'products',
					icon: 'fas fa-boxes',
					label: 'Termékek'
				},
				{
					name: 'paymentPlans',
					icon: 'fas fa-coins',
					label: 'Fizetési konstrukciók'
				},
				{
					name: 'roles',
					icon: 'fas fa-users',
					label: 'Jogosultságok'
				},
				{
					name: 'pricing',
					icon: 'fas fa-coins',
					label: 'Árazás'
				},
			],
			clientInputNotificationSelectOptions: [
				{
					value: 'enabled',
					name: 'Projekt mentés'
				},
				{
					value: 'quoteAccepted',
					name: 'Árajánlat elfogadva'
				},
			],
		};
	},
	mounted() {
	},
	created: function () {
		this.getData()
	},
	updated(){
	},
	computed: {
		openFields: {
			get() {
				let payload = {}
				let key
				for (let itemIndex = 0; itemIndex < this.fields.length; itemIndex++) {
					key = this.getFieldKey(itemIndex, null)
					payload[key] = this.fieldsOpen.indexOf(key) > -1
					if (this.fields[itemIndex].children !== undefined && this.fields[itemIndex].children.length) {
						for (let childIndex = 0; childIndex < this.fields[itemIndex].children.length; childIndex++) {
							key = this.getFieldKey(childIndex, itemIndex)
							payload[key] = this.fieldsOpen.indexOf(key) > -1
						}
					}
				}

				return payload
			}
		},
	},
	methods: {
		moveValueInArray: moveValueInArray,
		addField: function (type, fieldKey){
			this.saveCurrentFieldKeys()
			let field = this.getEmptyField(type)
			if (this.fields.length > fieldKey){
				fieldKey++
			}
			this.fields.splice(fieldKey, 0, field)

			this.updateOrder()
			this.updateNewFieldKeys()
			this.toggleFieldVisibility(fieldKey, null, 'open')
		},
		addSubfield: function(type, fieldKey){

			this.saveCurrentFieldKeys()
			let field = this.getEmptyField(type)
			if (this.fields[fieldKey].children === undefined){
				this.fields[fieldKey].children = []
			}
			this.fields[fieldKey].children.push(field)

			this.updateOrder()
			this.updateNewFieldKeys()
			this.toggleFieldVisibility(this.fields[fieldKey].children.length - 1, fieldKey, 'open')
		},
		getEmptyField: function(type){
			let field = {
				type: type,
				required: 1,
				client_can_see: 1,
				title: '',
				conditionFieldKey: '',
				field_number: this.getHighestFieldNumber() + 1,
				translations: {}
			}
			if (type === 'select'){
				field.multiple = false
				field.options = [
					{id: null, value: '', order: 1, translations: {}},
					{id: null, value: '', order: 2, translations: {}},
					{id: null, value: '', order: 3, translations: {}},
					{id: null, value: '', order: 4, translations: {}},
					{id: null, value: '', order: 5, translations: {}},
				]
			}
			if (type === 'table'){
				field.rows = [
					{id: null, value: '', translations: {}},
					{id: null, value: '', translations: {}},
					{id: null, value: '', translations: {}},
					{id: null, value: '', translations: {}},
					{id: null, value: '', translations: {}},
				]
				field.columns = [
					{id: null, value: '', translations: {}},
					{id: null, value: '', translations: {}},
					{id: null, value: '', translations: {}},
					{id: null, value: '', translations: {}},
					{id: null, value: '', translations: {}},
				]
			}
			if (type === 'copyButton'){
				field.config = {
					fields: []
				}
			}

			return field
		},
		moveFieldValue: function (fieldKey, arrayName, valueKey, direction, parentKey){
			if (parentKey !== null){
				this.fields[parentKey].children[fieldKey][arrayName] = this.moveValueInArray(this.fields[parentKey].children[fieldKey][arrayName], valueKey, direction)
				this.$refs['field_' + parentKey + '_' + fieldKey][0].$forceUpdate()
			} else {
				this.fields[fieldKey][arrayName] = this.moveValueInArray(this.fields[fieldKey][arrayName], valueKey, direction)
				this.$refs['field_' + fieldKey][0].$forceUpdate()
			}
		},
		moveField: function (fieldKey, direction, parentKey){

			this.saveCurrentFieldKeys()
			if (parentKey !== null){
				this.fields[parentKey].children = this.moveValueInArray(this.fields[parentKey].children, fieldKey, direction)
			} else {
				this.fields = this.moveValueInArray(this.fields, fieldKey, direction)
			}

			this.updateOrder()
			this.updateNewFieldKeys()
		},
		addEmptyFieldValue: function (fieldKey, arrayName, parentKey){
			let order = 0
			if (parentKey !== null){
				for (let i in this.fields[parentKey].children[fieldKey][arrayName]){
					if (this.fields[parentKey].children[fieldKey][arrayName][i]['order'] > order){
						order = this.fields[parentKey].children[fieldKey][arrayName][i]['order']
					}
				}
				order++
				this.fields[parentKey].children[fieldKey][arrayName].push({id: null, value: '', order: order})
			} else {
				for (let i in this.fields[fieldKey][arrayName]){
					if (this.fields[fieldKey][arrayName][i]['order'] > order){
						order = this.fields[fieldKey][arrayName][i]['order']
					}
				}
				order++
				this.fields[fieldKey][arrayName].push({id: null, value: '', order: order, translations: {}})
			}
		},
		toggleFieldVisibility: function (fieldKey, parentKey, setTo){
			setTo = setTo || null
			let key = this.getFieldKey(fieldKey, parentKey)

			if (this.fieldsOpen.indexOf(key) > -1){
				if (setTo !== 'open') {
					this.fieldsOpen.splice(this.fieldsOpen.indexOf(key), 1)
				}
			} else {
				if (setTo !== 'close') {
					this.fieldsOpen.push(key)
				}
			}
		},
		getFieldKey: function (fieldKey, parentKey){
			return parentKey !== null ? parentKey + '_' + fieldKey : fieldKey
		},
		removeFieldValue: function (fieldKey, arrayName, valueKey, parentKey){

			if (parentKey !== null){
				this.fields[parentKey].children[fieldKey][arrayName].splice(valueKey, 1);
			} else {
				this.fields[fieldKey][arrayName].splice(valueKey, 1);
			}
		},
		removeField: function (fieldKey, parentKey){

			this.saveCurrentFieldKeys()
			if (parentKey !== null){
				this.fields[parentKey].children.splice(fieldKey, 1);
			} else {
				this.fields.splice(fieldKey, 1);
			}

			this.updateOrder()
			this.updateNewFieldKeys()
		},
		saveCurrentFieldKeys: function (){
			for (let itemIndex = 0; itemIndex < this.fields.length; itemIndex++){
				this.fields[itemIndex].oldKey = this.getFieldKey(itemIndex, null)
				if (this.fields[itemIndex].children !== undefined && this.fields[itemIndex].children.length){
					for (let childIndex = 0; childIndex < this.fields[itemIndex].children.length; childIndex++){
						this.fields[itemIndex].children[childIndex].oldKey = this.getFieldKey(childIndex, itemIndex)
					}
				}
			}
		},
		updateNewFieldKeys: function (){
			for (let itemIndex = 0; itemIndex < this.fields.length; itemIndex++) {
				if (this.fields[itemIndex].conditionFieldKey !== '') {
					this.fields[itemIndex].conditionFieldKey = this.getNewFieldKey(this.fields[itemIndex].conditionFieldKey)
				}

				if (this.fields[itemIndex].children !== undefined && this.fields[itemIndex].children.length) {
					for (let childIndex = 0; childIndex < this.fields[itemIndex].children.length; childIndex++) {
						if (this.fields[itemIndex].children[childIndex].conditionFieldKey !== '') {
							this.fields[itemIndex].children[childIndex].conditionFieldKey = this.getNewFieldKey(this.fields[itemIndex].children[childIndex].conditionFieldKey)
						}
					}
				}
			}
			let tmpArray = []
			for (let i = 0; i < this.fieldsOpen.length; i++){
				let newKey = this.getNewFieldKey(this.fieldsOpen[i])
				if (newKey !== ''){
					tmpArray.push(newKey)
				}
			}
			this.fieldsOpen = tmpArray
		},
		getNewFieldKey: function(fieldKey){
			for (let itemIndex = 0; itemIndex < this.fields.length; itemIndex++){
				if (this.fields[itemIndex].oldKey !== undefined && this.fields[itemIndex].oldKey === fieldKey){
					return this.getFieldKey(itemIndex, null)
				}
				if (this.fields[itemIndex].children !== undefined && this.fields[itemIndex].children.length){
					for (let childIndex = 0; childIndex < this.fields[itemIndex].children.length; childIndex++){
						if (this.fields[itemIndex].children[childIndex].oldKey !== undefined && this.fields[itemIndex].children[childIndex].oldKey === fieldKey){
							return this.getFieldKey(childIndex, itemIndex)
						}
					}
				}
			}

			return ''
		},
		updateOrder: function (){
			let order = 1;
			this.fields.forEach(function(item){
				item.order = order++;

				if (item.children !== undefined && item.children){
					let subOrder = 1

					item.children.forEach(function(item) {
						item.order = subOrder++
					})
				}
			});

			this.updateAvailableConditions()
			if (this.hoveredItem === -1 && this.fields.length){
				this.hoveredItem = this.fields.length - 1
			}
		},
		getData: function (){
			axios.get(route('projectTypes.form', this.projectTypeId)).then((response)=>{
				this.handleResponse(response);
			}).catch(function (error) {
			})
		},
		mouseEntersField: function(fieldKey, e){
			this.hoveredItem = fieldKey
		},
		saveForm: function (){
			axios.post(route('projectTypes.form', this.projectTypeId), {
				fields: this.fields,
				projectType: _.pick(this.attributes, [
					'active',
					'public',
					'name',
					'name_public',
					'color',
					'luminosity',
					'icon',
					'main_attribute_product_category_id',
					'main_attribute_product_attribute_id',
					'name_in_quote_consumer',
					'name_in_quote_company',
					'client_input_notification',
					'website_page_id',
					'quote_expiration',
					'priority',
					'minimum_payment_percent',
					'translations',
					'pricing',
					'min_warranty_months',
					'optionals_selected',
				]),
				documentTypes: this.documentTypes,
				documentBundles: this.documentBundles,
				milestones: this.milestones,
				paymentPlans: this.paymentPlans,
				productCategories: this.productCategoryAttributes,
				productTags: this.productTags,
				roles: this.roles,
				set_order: this.attributes.set_order,
				set_order_index: this.attributes.set_order_index,
			}).then((response) => {
				this.handleResponse(response);

				this.$refs.formWrapper.formClean()
			}).catch(function (error) {

			})
		},
		handleResponse: function (response){
			if (response.data.redirect !== undefined){
				this.$refs.formWrapper.formClean()
				location.href = response.data.redirect
			} else {
				this.loaded = true

				if (response.data.status === 'success') {
					this.attributes = response.data.projectType
					this.fields = response.data.projectType.fields_data
					this.documentTypes = response.data.projectType.document_types
					this.documentBundles = response.data.projectType.document_bundles
					this.milestones = response.data.projectType.milestones
					this.paymentPlans = response.data.projectType.payment_plans
					this.roles = response.data.projectType.roles
					this.productTags = response.data.projectType.product_tags
					this.forms = response.data.forms
					this.productCategoryAttributes = {}


					if (Array.isArray(this.attributes.translations) && !this.attributes.translations.length) {
						this.attributes.translations = {}
					}

					_.forEach(this.fields, (field) => {
						this.addTranslationsToField(field)
						if (field.children !== undefined && field.children !== null){
							_.forEach(field.children, (child) => {
								this.addTranslationsToField(child)
							})
						}
					})

					_.forEach(this.documentTypes, function(documentType) {
						if ((Array.isArray(documentType.translations) && !documentType.translations.length) || documentType.translations === null) {
							documentType.translations = {}
						}
					})

					_.forEach(this.documentBundles, function(documentBundle) {
						if ((Array.isArray(documentBundle.translations) && !documentBundle.translations.length) || documentBundle.translations === null) {
							documentBundle.translations = {}
						}
					})

					_.forEach(this.milestones, function(milestone) {
						if ((Array.isArray(milestone.translations) && !milestone.translations.length) || milestone.translations === null) {
							milestone.translations = {}
						}
					})

					_.forEach(this.paymentPlans, function(paymentPlan) {
						if ((Array.isArray(paymentPlan.translations) && !paymentPlan.translations.length) || paymentPlan.translations === null) {
							paymentPlan.translations = {}
						}
						_.forEach(paymentPlan.steps, function(step){
							if ((Array.isArray(step.translations) && !step.translations.length) || step.translations === null){
								step.translations = {}
							}
						})
					})

					if (this.attributes.pricing === undefined || this.attributes.pricing === null){
						this.attributes.pricing = {}
					}
					_.forEach(this.productTypes, (type, typeName) => {
						if (this.attributes.pricing[typeName] === undefined){
							this.attributes.pricing[typeName] = {
								percent: 0
							}
						}
					})

					let number = this.getHighestFieldNumber() + 1

					this.assignFieldNumbers()

					_.forEach(response.data.projectType.product_categories, (item) => {
						this.productCategoryAttributes[item.id] = item.pivot
						this.productCategoryAttributes[item.id].required = !!this.productCategoryAttributes[item.id].required
						this.productCategoryAttributes[item.id].without_quote = !!this.productCategoryAttributes[item.id].without_quote
						this.productCategoryAttributes[item.id].with_quote = !!this.productCategoryAttributes[item.id].with_quote
					})
					_.forEach(this.productCategories, (item) => {
						if (this.productCategoryAttributes[item.id] === undefined){
							this.productCategoryAttributes[item.id] = {}
						}
					})
					this.updateOrder()
				}

				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
			}
		},
		addTranslationsToField: function (field){
			if ((Array.isArray(field.translations) && !field.translations.length) || field.translations === null){
				field.translations = {}
			}
			if (field.options !== undefined && field.options !== null){
				_.forEach(field.options, function(option){
					if ((Array.isArray(option.translations) && !option.translations.length) || option.translations === null){
						option.translations = {}
					}
				})
			}
			if (field.rows !== undefined && field.rows !== null){
				_.forEach(field.rows, function(row){
					if ((Array.isArray(row.translations) && !row.translations.length) || row.translations === null){
						row.translations = {}
					}
				})
			}
			if (field.columns !== undefined && field.columns !== null){
				_.forEach(field.columns, function(column){
					if ((Array.isArray(column.translations) && !column.translations.length) || column.translations === null){
						column.translations = {}
					}
				})
			}
		},
		assignFieldNumbers: function(){
			for (let i = 0; i < this.fields.length; i++){
				if (!this.fields[i].field_number){
					this.fields[i].field_number = this.getHighestFieldNumber() + 1
				}
				if (this.fields[i].children){
					for (let j = 0; j < this.fields[i].children.length; j++){
						if (!this.fields[i].children[j].field_number){
							this.fields[i].children[j].field_number = this.getHighestFieldNumber() + 1
						}

					}
				}
			}
		},
		getHighestFieldNumber: function(){
			let max = 0
			for (let i = 0; i < this.fields.length; i++){
				if (this.fields[i].field_number > max){
					max = this.fields[i].field_number
				}
				if (this.fields[i].children){
					for (let j = 0; j < this.fields[i].children.length; j++){
						if (this.fields[i].children[j].field_number > max){
							max = this.fields[i].children[j].field_number
						}
					}
				}
			}

			return max
		},
		updateAvailableConditions: function(){
			for (let i = 0; i < this.fields.length; i++){
				this.fields[i].availableConditions = this.getAvailableConditionsForField(i)

				if (this.fields[i].children){
					for (let j = 0; j < this.fields[i].children.length; j++){
						this.fields[i].children[j].availableConditions = this.getAvailableConditionsForField(i, j)
					}
				}
			}
		},
		getAvailableConditionsForField: function(fieldIndex, childFieldIndex){
			childFieldIndex = childFieldIndex || null
			let availableConditions = [];

			for (let itemIndex = 0; itemIndex < this.fields.length; itemIndex++) {
				let item = this.fields[itemIndex]
				if ((itemIndex <= fieldIndex && childFieldIndex !== null) || (itemIndex < fieldIndex && childFieldIndex === null)){
					if (item.type === 'bool') {
						availableConditions.push({label: item.order + '.', code: itemIndex})
					}

					if (item.children !== undefined) {
						for (let childIndex = 0; childIndex < item.children.length; childIndex++) {
							let child = item.children[childIndex]
							if (itemIndex < fieldIndex || (itemIndex === fieldIndex && childFieldIndex !== null && childFieldIndex > childIndex)) {
								if (child.type === 'bool') {
									availableConditions.push({
										label: item.order + '.' + child.order + '.',
										code: itemIndex + '_' + childIndex
									})
								}
							}
						}
					}
				}
			}

			return availableConditions
		},
		openAllFields: function (){
			for (let itemIndex = 0; itemIndex < this.fields.length; itemIndex++) {
				this.toggleFieldVisibility(itemIndex, null, 'open')

				if (this.fields[itemIndex].children !== undefined && this.fields[itemIndex].children.length) {
					for (let childIndex = 0; childIndex < this.fields[itemIndex].children.length; childIndex++) {
						this.toggleFieldVisibility(childIndex, itemIndex, 'open')
					}
				}
			}
		},
		closeAllFields: function (){
			for (let itemIndex = 0; itemIndex < this.fields.length; itemIndex++) {
				this.toggleFieldVisibility(itemIndex, null, 'close')

				if (this.fields[itemIndex].children !== undefined && this.fields[itemIndex].children.length) {
					for (let childIndex = 0; childIndex < this.fields[itemIndex].children.length; childIndex++) {
						this.toggleFieldVisibility(childIndex, itemIndex, 'close')
					}
				}
			}
		},
		parent(icon) {
			if (icon.styles.indexOf("regular") > -1) {
				return "fa";
			} else if (icon.styles.indexOf("solid") > -1) {
				return "fas";
			} else if (icon.styles.indexOf("brands") > -1) {
				return "fab";
			}
			return "";
		},
		validateForm: function(e){
			this.$refs.formWrapper.validate()
		},
		updateOptionalsSelected: function (val){
			this.attributes.optionals_selected = val
		}
	}
}
</script>

<style scoped>
.project-type-form {
	margin-bottom: 40px;
}
.project-type--field {
	padding: 10px;
	margin-bottom: 10px;
	background: white;
	border-radius: 0.25rem;
}
.project-type--field > .project-type--field-inner {
	display: flex;
	flex-wrap: wrap;
}
.col-form-label {
	flex-basis: 150px;
	flex-grow: 0;
}
.project-type--add-field {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	background: white;
	border-radius: 0.25rem;
	flex-wrap: wrap;
	flex-basis: 100%;
	flex-shrink: 0;
	padding: 10px;
	gap: 0.5rem;
}
.project-type--field .project-type--add-field {
	padding: 10px;
	margin: 20px -10px -10px;
	opacity: .3;
}
.project-type--field .project-type--add-field:hover {
	opacity: 1;
}
.project-type--add-field div {
	font-size: 15px;
}
.project-type--subfield-list {
	flex-basis: 100%;
	margin-top: 10px;
	padding: 0 0 0 20px;
	border-left: 100px solid rgba(85,110,230,.1)
}
.alert-wrapper {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
	background: white;
	padding: 15px;
	z-index: 9900;
	box-shadow: 0 0 15px rgba(0,0,0,.1);
}
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
