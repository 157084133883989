<template>
	<div :class="(mdHeight ? 'ckeditor-height-md' : '')" v-if="!disabled">
		<ckeditor :editor="editor" v-model="componentValue"></ckeditor>
	</div>
	<div v-html="componentValue" v-else class="ckeditor-disabled-content"></div>
</template>

<script>
import Ckeditor from '../../ckeditor/build/ckeditor'
export default {
	props: {
		modelValue: String,
		name: String,
		mdHeight: Boolean,
		disabled: Boolean
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		inputId: {
			get(){
				return this.name + '_editor'
			}
		}
	},
	watch: {
		modelValue(oldv, newv){
			if (this.componentValue === null){
				this.componentValue = ''
			}
		}
	},
	data(){
		return {
			editor: Ckeditor,
			editorConfig: {
				toolbar: {
					items: [
						'bold',
						'italic',
						'link',
						'undo',
						'redo'
					]
				}
			}
		}
	},
	created() {
		if (this.componentValue === null){
			this.componentValue = ''
		}
	},
	mounted() {
	},
}
</script>

<style scoped>

</style>
