<template>
	<div class="timeline-wrapper" ref="timelineItemsWrapper">
		<div class="timeline-wrapper--items">
			<template v-for="entry in entities" :key="'timeline_' + entry.id">
				<template v-if="entry.type === 'entity'">
					<timeline-entry
						v-if="entry.entity.post !== undefined"
						:entry="entry"
						:delayable="entry.entity.delayable"
						:closable="entry.entity.closable"
						:comments="comments"
						:visit-url="route('posts.view', entry.entity.post_id)"
						@reload-from="loadTimeline"
						@comment-count="updateCommentCount">

						<template #head>
							<div class="timeline-item-head--icon">
								<i class="fas fa-newspaper"></i>
							</div>
							<div class="timeline-item-head--content">
								<div class="d-flex gap-2 align-items-center">
									<h4>
										{{ entry.entity.post.title }}
									</h4>
								</div>
								<div class="d-flex gap-3 align-items-center flex-wrap">

									<attribute
										icon="far fa-check text-success"
										v-if="entry.entity.seen_at"
									>
										<date-time :value="entry.entity.seen_at"/>
									</attribute>
									<attribute
										icon="far fa-clock"
									>
										<date-time :value="entry.entity.post.public_date"/>
									</attribute>
									<attribute
										icon="far fa-user"
										:content="entry.entity.post.created_by_user_name"
									/>
								</div>
							</div>
						</template>

						<template #default>
							<div class="p-3" v-if="entry.entity.post.lead">
								<div v-html="entry.entity.post.lead_html"></div>
							</div>
						</template>
					</timeline-entry>
				</template>
				<template v-if="entry.type === 'project'">
					<timeline-project-entry
						:comments="comments"
						:entry="entry"
						:project-event-types="projectEventTypes"
						@loadTimeline="loadTimeline"
						@commentCount="updateCommentCount"
						/>
				</template>
			</template>
			<div class="text-center mt-2 mb-2" v-if="loading">
				<p>Továbbiak betöltése...</p>
			</div>
			<div class="text-center mt-5 mb-5" v-if="!entities.length">
				<h3>Nincs mit megjeleníteni.</h3>
				<p v-if="!showAll">Ha látni szeretnél minden eseményt, kapcsold be a "mindent mutat" kapcsolót.</p>
			</div>
			<div class="text-center mt-5 mb-5" v-else-if="endReached">
				<h3>Itt a vége.</h3>
				<p>Elérted a hírfolyamod végét.</p>
				<p v-if="!showAll">Ha látni szeretnél minden eseményt, kapcsold be a "mindent mutat" kapcsolót.</p>
			</div>
		</div>
	</div>
</template>

<script>
import Attribute from "../../components/Attribute.vue";
import {Link} from "@inertiajs/vue3";
import DateTime from "../../components/DateTime.vue";
import TimelineEntry from "./TimelineEntry.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import ProjectStatusIcon from "../projects/ProjectStatusIcon.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import FileSize from "../../components/FileSize.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import {usePermanentStore} from "../../stores/permanentStore";
import {useInfiniteScroll} from "@vueuse/core";
import TimelineProjectEntry from "./TimelineProjectEntry.vue";

export default {
	components: {
		TimelineProjectEntry,
		FileSize,
		TableActionDropdown,
		ProjectStatusIcon, ColorBadge, ProjectTypeCell, TimelineEntry, DateTime, Link, Attribute},
	props: {
		projectTypes: Object,
		projectEventTypes: Object,
		showAll: [Boolean, Number],
		userId: [String, Number]
	},
	data(){
		return {
			entities: [],
			comments: {},
			updateInterval: null,
			loadedUserId: null,
			loadedShowAll: this.showAll,
			showSeenEvents: false,
			infiniteScroll: null,
			firstLoad: true,
			endReached: false,
			loading: false,
			app: useApplicationStore(),
			store: usePermanentStore(),
			componentUserId: this.userId
		}
	},
	mounted() {
		this.loadTimeline()
		this.updateInterval = setInterval(() => {
			this.loadTimeline()
		}, 30000);

		this.$refs.timelineItemsWrapper.scroll(0, 0)
	},
	beforeUnmount() {
		clearInterval(this.updateInterval)
	},
	watch: {
		userId(oldVal, newVal){
			this.settingsChanged()
		},
		showAll(oldVal, newVal){
			this.settingsChanged()
		}
	},
	methods: {
		loadTimeline: function (from){
			let params = []
			if (this.userId){
				params.push('userId=' + this.userId)
			}
			if (from !== undefined){
				params.push('from=' + from)
			}
			if (this.showAll){
				params.push('showAll=1')
			}

			this.loading = true

			axios.get('/dashboard/timeline' + (params.length ? '?' + params.join('&') : '')).then((response) => {
				let fromDate = new Date(response.data.from)
				let toDate = new Date(response.data.to)
				let firstItem = _.first(this.entities)
				let firstDate = firstItem ? new Date(firstItem.sort_date) : null
				let inserted = false
				let buffer = []
				if (response.data.endReached){
					this.endReached = true
				}
				if (firstDate === null || firstDate < toDate){
					inserted = true
					_.forEach(response.data.entities, function (item){
						buffer.push(item)
					})
				}
				_.forEach(this.entities, (val, key) => {
					let entityDate = new Date(val.sort_date)
					if (entityDate >= fromDate && entityDate <= toDate){
						if (!inserted){
							inserted = true

							_.forEach(response.data.entities, function (item){
								buffer.push(item)
							})
						}

						delete this.entities[key]
					} else {
						buffer.push(val)
					}
				})
				if (!inserted){
					_.forEach(response.data.entities, function (item){
						buffer.push(item)
					})
				}
				this.entities = buffer

				_.forEach(response.data.comments, (item) => {
					this.comments[item.model_type + '_' + item.model_id] = item.count
				})

				if (this.firstLoad){
					this.firstLoad = false
					setTimeout(() => {
						this.infiniteScroll = useInfiniteScroll(this.$refs.timelineItemsWrapper, this.loadMore)
					}, 100)
				}
				this.loading = false

			}).catch((error) => {
			})
		},
		updateCommentCount: function (key, value){
			this.comments[key] = value
		},
		settingsChanged(){
			if (this.showAll !== this.loadedShowAll){
				this.store.set('timelineShowAll', this.showAll)
				this.loadedShowAll = this.showAll

				this.entities = []
				this.comments = {}
				this.loadTimeline()
			}
			if (this.componentUserId !== this.userId){
				this.componentUserId = this.userId

				this.entities = []
				this.comments = {}
				this.loadTimeline()
			}
		},
		loadMore: function (e){
			if (!this.loading && !this.endReached){
				const last = _.last(this.entities)
				if (last){
					this.loadTimeline(last.sort_date)
				}
			}
		}
	}
}
</script>


<style scoped>

</style>
