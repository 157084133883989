<template>
	<div class="card shadow-none border file p-2 m-0">
		<div class="d-flex">
			<div style="width: 100px;" class="me-3 d-flex align-items-center justify-content-center">
				<a href="#" v-if="file.thumbnail" class="d-block" @click.prevent="displayLightbox = true">
					<img class="img-fluid" :src="file.thumbnail" />
				</a>
				<div class="file-icon" v-else>
					<FileIcon :filename="file.extension"></FileIcon>
				</div>
			</div>
			<div>
				<div class="filename fw-bold">
					<a :href="file.download_url" target="_blank" :title="file.name + '.' + file.extension"><i class="far fa-download"></i> {{file.name}}</a>
				</div>
				<div class="d-flex flex-wrap gap-2">
					<attribute icon="far fa-file">
						<file-size :value="file.size"/>
					</attribute>
					<attribute v-if="file.width" icon="far fa-image"
							   :content="file.width + ' x ' + file.height + ' px'"/>
					<attribute icon="far fa-calendar-alt">
						<date-time :value="file.created_at"/>
					</attribute>
					<attribute icon="far fa-user" :content="file.created_by_user_name"/>
				</div>
			</div>
		</div>
	</div>
	<teleport to="body" v-if="displayLightbox">
		<div class="lightbox" @click.prevent="displayLightbox = false">
			<div class="lightbox-image">
				<img :src="file.download_url" class="img-responsive" />
			</div>
			<button class="btn btn-secondary btn-label" @click.prevent="displayLightbox = false">
				<i class="far fa-times label-icon"></i> Bezár
			</button>
		</div>
	</teleport>
</template>

<script>
import FileIcon from "./FileIcon.vue";
import FileSize from "./FileSize.vue";
import DateTime from "./DateTime.vue";
import Attribute from "./Attribute.vue";

export default {
	components: {Attribute, DateTime, FileSize, FileIcon},
	props: {
		file: Object
	},
	data(){
		return {
			displayLightbox: false
		}
	},
}
</script>

<style scoped>

</style>
