import { defineStore } from 'pinia'
import {objectToArray} from "../functions";
import {useApplicationStore} from "./applicationStore";

export const useProjectDataStore = defineStore('projectData', {
	state: () => {
		return {
			projectId: null,
			client: null,
			project: null,
			projectType: null,
			inquiry: null,
			roles: [],
			commissions: [],
			loaded: false,
			editable: false,
			interval: null,
			documents: {},
			systemDocuments: {},
			generatedForms: [],
			documentBundles: [],
			timelineEntities: [],
			generatedDocumentBundles: [],
			app: useApplicationStore(),
			possibleTabs: [
				{
					name: 'attributes',
					label: 'Adatlap',
					icon: 'far fa-file-spreadsheet'
				},
				{
					name: 'progress',
					label: 'Haladás',
					icon: 'far fa-tasks'
				},
				{
					name: 'comments',
					label: 'Üzenőfal',
					icon: 'far fa-comments'
				},
				{
					name: 'documents',
					label: 'Dokumentumok',
					icon: 'far fa-folder-open'
				},
				{
					name: 'quotes',
					label: 'Árajánlatok',
					icon: 'far fa-file-invoice-dollar'
				},
				{
					name: 'contracts',
					label: 'Szerződések',
					icon: 'far fa-file-contract'
				},
				{
					name: 'invoices',
					label: 'Számlák',
					icon: 'far fa-file-invoice'
				},
				{
					name: 'roles',
					label: 'Jogosultságok',
					icon: 'far fa-users'
				}
			],
			forms: [],
			uploadedFiles: {},
			documentsLoaded: false,
			activeTab: 'attributes',
			activeTabCommand: null,
			activeTabParameter: null,
			lockAcquired: false,
			lock: {
				updated: false,
				locked: false,
				lockedByCurrentUser: false,
				lockedByUserName: '',
				lockedByClientName: '',
				lockedAt: false,
			},
		}
	},
	actions: {
		loadData(callback) {
			if (this.projectId && this.project.id !== undefined && this.project.id === this.projectId){
				if (callback !== undefined){
					callback()
				}
				return
			}
			axios.get('/projects/load/' + this.projectId).then((response) => {
				this.project = response.data.project
				this.projectType = response.data.projectType
				this.client = response.data.client
				this.inquiry = response.data.inquiry
				this.roles = response.data.roles
				this.commissions = response.data.commissions
				this.timelineEntities = response.data.timelineEntities

				this.loading = false
				this.loaded = true

				if (callback !== undefined){
					callback()
				}
			}).catch( (error) => {
				this.loading = false
			});
		},
		setData(project){
			this.project = project
			this.projectType = project.project_type
			if (project.client !== undefined){
				this.client = project.client
			}

			this.loading = false
			this.loaded = true
		},
		clearData(){
			this.projectId = null
			this.project = {}
			this.projectType = null
			this.client = null
			this.inquiry = null
			this.roles = []
			this.commissions = []

			this.documents = []
			this.systemDocuments = []
			this.generatedForms = []
			this.documentBundles = []
			this.generatedDocumentBundles = []
			this.timelineEntities = []
			this.forms = []
			this.uploadedFiles = {}

			this.documentsLoaded = false
			this.loaded = false
		},
		projectSaveResponse(response){
			this.project = response.data.project
			if (this.documentsLoaded){
				this.loadDocuments()
			}
		},
		loadDocuments(){
			axios.get('/projects/loadDocuments/' + this.projectId).then((response) => {
				this.documents = response.data.documents
				this.systemDocuments = response.data.systemDocuments
				this.generatedForms = response.data.generatedForms
				this.documentBundles = response.data.documentBundles
				this.generatedDocumentBundles = response.data.generatedDocumentBundles
				this.forms = response.data.forms
				this.uploadedFiles = {}

				for (let i = 0; i < this.projectType.document_types.length; i++) {
					this.uploadedFiles[this.projectType.document_types[i].id] = []
				}

				this.loading = false
				this.documentsLoaded = true
			}).catch((error) => {
				this.loading = false
			});
		},
		getUrl(type, id = null){
			if (this.urls[type] === undefined){
				return ''
			}
			if (id !== null && this.urls[type].urlWithId !== undefined){
				return this.urls[type].urlWithId.replace('{id}', id)
			}
			if (this.projectId !== null && this.urls[type].urlWithId !== undefined){
				return this.urls[type].urlWithId.replace('{id}', this.projectId)
			}

			return this.urls[type].url !== undefined ? this.urls[type].url : ''
		},
		can(category, action){
			return this.project.rights[category] !== undefined && this.project.rights[category].indexOf(action) > -1;
		},
		getFormById(formId){
			for (let i = 0; i < this.forms.length; i++){
				for (let j = 0; j < this.forms[i].forms.length; j++){
					if (this.forms[i].forms[j].id === formId){
						return this.forms[i].forms[j]
					}
				}
			}

			return null
		},
		getMilestoneById(milestoneId){
			for (let i = 0; i < this.projectType.milestones.length; i++){
				if (this.projectType.milestones[i].id === milestoneId){
					return this.projectType.milestones[i]
				}
			}

			return null
		},
		getDocumentTypeById(documentTypeId){
			for (let i = 0; i < this.projectType.document_types.length; i++){
				if (this.projectType.document_types[i].id === documentTypeId){
					return this.projectType.document_types[i]
				}
			}

			return null
		}
	},
})
