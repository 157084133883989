<template>
	<AdvancedDataTable
		:data-url="listDataUrl + '?mode=' + mode"
		:columns="columns"
		sort-by="public_id"
		ref="dataTable"
		sort-type="desc"
		:name="embeded ? 'projectsEmbeded' : 'projects'"
		:compact="embeded"
		:compact-with-pagination="embeded"
		:run-at-init="tableInited"
	>
		<template #filters="{serverOptions}" v-if="!embeded">
              <div class="col-12 col-md-3">
				  <project-type-select-field
					  v-model="serverOptions.project_type_id"
					  label="Típus"
					  :field-only="true"
					  name="project_type_id"
				  />
              </div>
              <div class="col-12 col-md-3">
				  <data-loader :data="['milestones']" v-slot="{ dataStore }">
					  <v-select
						  v-model="serverOptions.project_type_milestone_id"
						  :options="getMilestoneSelectOptions(serverOptions.project_type_id)"
						  :reduce="option => option.id"
						  placeholder="Mérföldkő"
						  label="name"
						  :multiple="false"
						  :clearable="true"
					  >
						  <template v-slot:option="option">
							  <template v-if="serverOptions.project_type_id">
								  <span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
							  </template>
							  <template v-else>
								  {{ getProjectTypeById(option.project_type_id).name }}
								  <div class="mt-2">
									  <span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
								  </div>
							  </template>
						  </template>
						  <template v-slot:selected-option="option">
							  <template v-if="serverOptions.project_type_id">
								  <span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
							  </template>
							  <template v-else>
								  <div>
									  {{ getProjectTypeById(option.project_type_id).name }}
									  <div class="mt-2">
										<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
									  </div>
								  </div>
							  </template>
						  </template>
					  </v-select>
				  </data-loader>
              </div>
			<div class="col-12 col-md-3">
				<select-field
					v-model="serverOptions.status"
					:options="projectStatuses"
					label="Állapot"
					rules="required"
					name="status"
					:field-only="true"
					:display-badge="true"
				>
				</select-field>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="isEditable"
				:is-deletable="isDeletable"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
			>
				<a :href="restoreUrl + '/' + item.id" class="btn btn-secondary btn-sm" title="Visszaállít" v-if="mode === 'deleted'"><i class="far fa-trash-undo"></i></a>
			</TableActionCell>
		</template>
		<template #item-public_id="item">
			<div class="fw-bold d-flex gap-1 align-items-center">
				<color-badge
					color="primary"
					icon="fas fa-power-off"
					:small="true"
					v-if="!item.closed_at && !item.postponed_until"
				></color-badge>
				<color-badge
					color="warning"
					icon="fas fa-clock"
					:small="true"
					v-else-if="!item.closed_at && item.postponed_until"
				></color-badge>
				<color-badge
					color="success"
					icon="fas fa-check"
					:small="true"
					v-else
				></color-badge>
				<div>{{ item.public_id }}</div>
			</div>
			<div v-if="item.handlerUserName"><i class="far fa-user"></i> {{ item.handlerUserName }}</div>
			<div><i class="far fa-clock"></i> <date-time :value="item.created_at"/></div>
			<div v-if="item.locked">
				<span :class="'badge badge-xl bg-' + (item.locked_by_user_id === userId ? 'success' : 'warning')"><i class="fas fa-edit"></i> {{ item.lockedByUserName ? item.lockedByUserName : item.lockedByClientName }}</span>
			</div>
		</template>
		<template #item-clientName="item">
			<ClientCell
				:data="item"></ClientCell>
		</template>
		<template #item-type="item">
			<ProjectTypeCell
				:data="item"
			></ProjectTypeCell>
		</template>
		<template #item-milestones="item">
			<ProjectMilestonesCell
				:data="item"
			></ProjectMilestonesCell>
		</template>
	</AdvancedDataTable>
</template>
<script>
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import ProjectTypeCell from './ProjectTypeCell'
import ProjectMilestonesCell from './ProjectMilestonesCell.vue'
import ClientCell from "./ClientCell.vue";
import vSelect from 'vue-select'
import ColorBadge from "../../components/ColorBadge.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import SelectField from "../../components/form/SelectField.vue";
import DataLoader from "../../components/DataLoader.vue";
import ProjectTypeSelectField from "../../components/form/ProjectTypeSelectField.vue";
import DateTime from "../../components/DateTime.vue";

export default {
	components: {
		DateTime,
		ProjectTypeSelectField,
		DataLoader, SelectField,
		ColorBadge,
		AdvancedDataTable,
		ClientCell,
		ProjectMilestonesCell,
		ProjectTypeCell,
		TableActionCell,
		vSelect,
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		restoreUrl: String,
		userId: Number,
		embeded: Boolean,
		mode: {
			type: String,
			default: 'normal'
		},
		clientId: {
			type: Number,
			default: 0
		},
	},
	data(){
		let columns = [{
			text: 'Adatok',
			value: 'public_id',
			sortable: true,
		}]
		if (this.clientId < 1){
			columns.push({
				text: 'Ügyfél',
				value: 'clientName',
				sortable: true,
			})
		}
		columns.push({
			text: 'Típus',
			value: 'type',
			sortable: true,
		})
		columns.push({
			text: 'Haladás',
			value: 'milestones',
			sortable: false,
		})
		columns.push({
			label: '',
			value: 'operations',
			width: 5,
		})

		let listDataUrl = this.dataUrl
		if (this.clientId){
			listDataUrl += '/' + this.clientId
		}

		return {
			columns: columns,
			listDataUrl: listDataUrl,
			dataStore: useGeneralDataStore(),
			projectStatuses: [
				{
					id: 'all',
					label: 'Összes',
					icon: 'fas fa-eye',
					color: 'light'
				},
				{
					id: 'active',
					label: 'Aktív',
					icon: 'fas fa-power-off',
					color: 'primary'
				},
				{
					id: 'closed',
					label: 'Lezárt',
					icon: 'fas fa-check',
					color: 'success'
				},
				{
					id: 'postponed',
					label: 'Elhalasztva',
					icon: 'fas fa-clock',
					color: 'warning'
				},
			]
		}
	},
	methods: {
		isEditable: function(row){
			return row.editable && this.mode !== 'deleted' && !row.clientDeletedAt
		},
		isDeletable: function(row){
			return row.deletable && this.mode !== 'deleted'
		},
		getProjectTypeById: function (id){
			for (let i = 0; i < this.dataStore.getData('projectTypes').length; i++){
				if (this.dataStore.getData('projectTypes')[i].id === id){
					return this.dataStore.getData('projectTypes')[i]
				}
			}
		},
		getMilestoneSelectOptions: function(typeId){
			let payload = []
			if (typeId !== null && typeId){
				for (let i = 0; i < this.dataStore.getData('milestones').length; i++){
					if (this.dataStore.getData('milestones')[i].project_type_id === typeId){
						payload.push(this.dataStore.getData('milestones')[i])
					}
				}
			} else {
				payload = this.dataStore.getData('milestones')
			}

			return payload
		},
		tableInited: function (){
			if (this.$refs.dataTable.componentOptions.status === undefined || !this.$refs.dataTable.componentOptions.status){
				this.$refs.dataTable.componentOptions.status = 'active'
			}
		}
	}
}
</script>

<style scoped>
</style>
