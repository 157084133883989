<script setup>
import {ref} from "vue";
import {useApplicationStore} from "../../stores/applicationStore";
import {useGeneralDataStore} from "../../stores/generalData";
import ColorBadge from "../ColorBadge.vue";
import Popper from "vue3-popper";
import {Link} from "@inertiajs/vue3";
import DataLoader from "../DataLoader.vue";
import {computed} from "@vue/compat";
import Attribute from "../Attribute.vue";
import Number from "../Number.vue";
import Price from "../Price.vue";
import EmailAddress from "../EmailAddress.vue";
import PhoneNumber from "../PhoneNumber.vue";
import WarehouseBadge from "./WarehouseBadge.vue";
import SaleStatusBadge from "./SaleStatusBadge.vue";
import UpdatedAttribute from "../UpdatedAttribute.vue";
import UserAttribute from "../attributes/UserAttribute.vue";
import MoneyAttribute from "../attributes/MoneyAttribute.vue";
import ProductBadge from "./ProductBadge.vue";
import ClientBadge from "./ClientBadge.vue";

const model = defineModel()

const props = defineProps({
	publicId: String,
	clientName: String,
	disableLink: Boolean,
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
	desktop:{
		type: Boolean,
		default: false
	},
	mobile:{
		type: Boolean,
		default: false
	},
})

const loaded = ref(false)
const app = useApplicationStore()
const open = ref(false)
const error = ref(false)
const dataStore = useGeneralDataStore()
const details = ref({})

function load(){
	if (!loaded.value) {
		let loadedData = dataStore.getTooltipData('sale', model)
		if (loadedData !== false && loadedData){
			details.value = loadedData
			loaded.value = true
		} else {
			axios.get('/tooltips/sale/' + model.value, {}).then((response) => {
				details.value = response.data.details
				dataStore.addTooltip('sale', model.value, details.value)
				loaded.value = true
			}).catch((e) => {
				error.value = true
			})
		}
	}
}

function onOpen(){
	open.value = true
	load()
}

</script>

<template>
	<template v-if="!disableLink">
		<VDropdown
			@show="onOpen">
			<div class="vertical">
				<a :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" href="#">
					<i class="far fa-cash-register"></i>
					<span class="colored-badge-content-wrap">{{ publicId + (clientName ? ' - ' + clientName : '') }}</span>
					<i class="far fa-info-circle font-size-10"></i>
				</a>
			</div>
			<template #popper>
				<div :class="{'overview-tooltip': 1}">
					<div class="overview-tooltip-head">
						<template v-if="loaded">
							<div class="d-flex">
								<div class="flex-grow-1">
									<color-badge
										color="secondary"
										icon="far fa-cash-register"
										small
										content="Eladás"
									/>
									<h5 class="font-size-15 mt-1 mb-1">{{ details.public_id }}</h5>
									<client-badge :client-id="details.client_id" :public-id="details.clientPublicId" :name="details.clientName" />
								</div>
								<div class="d-flex flex-column gap-2">
									<Link :href="'/sales/edit/' + model" class="btn btn-soft-secondary btn-sm" title="Adatlap" v-if="app.hasPermission('SalesController_viewAll') || (app.hasPermission('SalesController_view') && details.handler_user_id === app.userId)"><i class="far fa-external-link"></i></Link>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="placeholder-glow mb-1">
								<span class="placeholder font-size-15" :style="'width:32%'"></span>
							</div>
							<div class="placeholder-glow mb-1">
								<span class="placeholder font-size-14" :style="'width:51%'"></span>
							</div>
						</template>
					</div>
					<div class="overview-tooltip-body">
						<template v-if="loaded">
							<div class="details-box">
								<attribute label="Állapot" icon="far fa-bolt">
									<sale-status-badge v-model="details.status" />
								</attribute>
								<updated-attribute :data="details" created />
								<user-attribute :user-id="details.handler_user_id" handler />
								<money-attribute v-model="details.total_price_net" total net />
								<money-attribute v-model="details.total_price_gross" total gross />
								<attribute label="Termékek száma">{{ details.productCount }}</attribute>
							</div>
						</template>
						<template v-else>
							<div class="p-2">
								<div class="placeholder-glow mb-2">
									<span class="placeholder font-size-16" :style="'width:51%'"></span>
								</div>
								<div class="placeholder-glow mb-2">
									<span class="placeholder font-size-16" :style="'width:32%'"></span>
								</div>
							</div>
						</template>
					</div>
					<template v-if="details.products !== undefined">
						<div class="overview-tooltip-subtitle">
							<h5 class="font-size-15 m-0">Termékek</h5>
						</div>
						<div class="overview-tooltip-body">
							<div class="details-box gap-0">
								<div v-for="(product, productId) in details.products" :key="'saleProduct_' + details.id + '_' + productId" class="details-box-list-item">
									<color-badge
										:color="product.quantity < 0 ? 'danger' : 'success'"
										small
									>
										<number :value="product.quantity" />
									</color-badge>
									<product-badge
										:product-id="parseInt(productId)"
										:product-manufacturer-id="product.product_manufacturer_id"
										:product-category-id="product.product_category_id"
										:product-name="product.name"
										:sku="product.sku"
										:key="'PB' + productId"
									/>
								</div>
							</div>
						</div>
					</template>
				</div>
			</template>
		</VDropdown>
	</template>
	<div class="vertical" v-else>
		<color-badge
			color="light"
			icon="far fa-cash-register"
			:small="small"
		>
			<span class="colored-badge-content-wrap">{{ publicId + (clientName ? ' - ' + clientName : '') }}</span>
		</color-badge>
	</div>
</template>

<style scoped>

</style>
