<template>
	<TitleBar title="Pénznemek" />

	<AdvancedDataTable
		:data-url="route('currencies.list')"
		:columns="columns"
		ref="dataTable"
		sort-by="order"
		sort-type="asc"
		name="currencies"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('CurrenciesController_create')">
			<Link :href="route('currencies.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<div>{{ item.value }}</div>

						<boolean-badge
							v-model="item.primary"
							:key="'primaryM_' + item.id"
							primary
						/>

						<order-cell :model="item" @changed="$refs.dataTable.getData" :key="'orderCell_' + item.id + '_' + item.order"/>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('currencies.update')"
					:emit-delete-event="true"
					:tracked-edit-mode="trackedEditMode"
					@deleteButtonPressed="deleteButtonPressed"
				></TableActionCell>
			</div>
		</template>
		<template #item-primary="item">
			<boolean-badge
				v-model="item.primary"
				:key="'primary_' + item.id"
			></boolean-badge>
		</template>
		<template #item-order="item">
			<order-cell :model="item" @changed="$refs.dataTable.getData" :key="'orderCell_' + item.id + '_' + item.order"/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import TableActionCell from "../components/TableActionCell.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import ColorBadge from "../components/ColorBadge.vue";
import Attribute from "../components/Attribute.vue";
import Number from "../components/Number.vue";
import OrderCell from "../components/OrderCell.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";

export default {
	components: {
		BooleanBadge,
		OrderCell,
		Number, Attribute, ColorBadge, AdvancedDataTable, TableActionCell, TitleBar, Link},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Sorrend',
				value: 'order',
				width: 15,
			},
			{
				text: 'Elsődleges',
				value: 'primary',
				sortable: true,
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Érték',
				value: 'value',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
