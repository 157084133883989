<script setup>
import ColorBadge from "../ColorBadge.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import DataLoader from "../DataLoader.vue";
import Popper from "vue3-popper";
import ProjectOverviewTooltip from "../../modules/projects/ProjectOverviewTooltip.vue";
import {onMounted, ref} from "vue";
import {Link} from "@inertiajs/vue3";
import ProjectTypeCell from "../../modules/projects/ProjectTypeCell.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import Number from "../Number.vue";
import Attribute from "../Attribute.vue";
import ProjectMilestonesCell from "../../modules/projects/ProjectMilestonesCell.vue";
import PhoneNumber from "../PhoneNumber.vue";
import EmailAddress from "../EmailAddress.vue";

const props = defineProps({
	supplierId: Number,
	disableLink: Boolean,
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
})
const loaded = ref(false)
const app = useApplicationStore()
const open = ref(false)
const error = ref(false)
const dataStore = useGeneralDataStore()
const details = ref({})

function load(){
	if (!loaded.value) {
		let loadedData = dataStore.getTooltipData('supplier', props.supplierId)
		if (loadedData !== false && loadedData){
			details.value = loadedData
			loaded.value = true
		} else {
			axios.get('/tooltips/supplier/' + props.supplierId, {}).then((response) => {
				details.value = response.data.details
				dataStore.addTooltip('supplier', props.supplierId, details.value)
				loaded.value = true
			}).catch((e) => {
				error.value = true
			})
		}
	}
}

function onOpen(){
	open.value = true
	load()
}

</script>

<template>
	<data-loader :data="['suppliers']" v-slot="{ dataStore }" v-if="supplierId">
		<template v-if="dataStore.getData('suppliers', true)[supplierId] !== undefined">
			<template v-if="!disableLink">
				<VDropdown
					@show="onOpen">
					<a :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" href="#">
						<i class="far fa-truck"></i> {{ dataStore.getData('suppliers', true)[supplierId].name }} <i class="far fa-info-circle font-size-10"></i>
					</a>
					<template #popper>
						<div :class="{'overview-tooltip': 1}">
							<div class="overview-tooltip-head">
								<template v-if="loaded">
									<div class="d-flex">
										<div class="flex-grow-1">
											<color-badge
												color="secondary"
												icon="far fa-truck"
												small
												content="Beszállító"
											/>
											<h5 class="font-size-15 mt-1">{{ dataStore.getData('suppliers', true)[supplierId].name }}</h5>
											<h4 class="font-size-14 mt-1 mb-0">{{ dataStore.getData('suppliers', true)[supplierId].address }}</h4>
										</div>
										<div class="d-flex flex-column gap-2">
											<Link :href="'/suppliers/edit/' + supplierId" class="btn btn-soft-secondary btn-sm" title="Adatlap" v-if="app.hasPermission('SuppliersController_view')"><i class="far fa-external-link"></i></Link>
											<Link :href="'/inventory/shipments?supplierId=' + supplierId" class="btn btn-soft-secondary btn-sm" title="Szállítmányok" v-if="app.hasPermission('InventoryController_view')"><i class="far fa-truck"></i></Link>
										</div>
									</div>
								</template>
								<template v-else>
									<div class="placeholder-glow mb-1">
										<span class="placeholder font-size-15" :style="'width:32%'"></span>
									</div>
									<div class="placeholder-glow mb-1">
										<span class="placeholder font-size-14" :style="'width:51%'"></span>
									</div>
								</template>
							</div>
							<div class="overview-tooltip-body">
								<template v-if="loaded">
									<div class="details-box">
										<div v-if="details.phone">
											<phone-number :value="details.phone"/>
										</div>
										<div v-if="details.email">
											<email-address :value="details.email"/>
										</div>
										<div v-if="details.url">
											<attribute
												icon="far fa-globe"
												label="Weboldal"
												>
												<a :href="details.url" target="_blank">{{ details.url }}</a>
											</attribute>
										</div>
									</div>
								</template>
								<template v-else>
									<div class="p-2">
										<div class="placeholder-glow mb-2">
											<span class="placeholder font-size-16" :style="'width:51%'"></span>
										</div>
										<div class="placeholder-glow mb-2">
											<span class="placeholder font-size-16" :style="'width:32%'"></span>
										</div>
									</div>
								</template>
							</div>
							<div class="overview-tooltip-subtitle" v-if="details.contact !== undefined && details.contact.length">
								<h5 class="font-size-15 m-0">Kapcsolat</h5>
							</div>
							<div class="overview-tooltip-body" v-if="details.contact !== undefined && details.contact.length">
								<div class="details-box">
									<div v-for="(contact, contactKey) in details.contact" :key="'contact_' + details.id + '_' + contactKey">
										<div class="d-flex flex-column align-items-start gap-1">
											<strong v-if="contact.name">{{ contact.name }} <span v-if="contact.position"> ({{ contact.position }})</span></strong>
											<phone-number :value="contact.phone" v-if="contact.phone" />
											<email-address :value="contact.email" v-if="contact.email" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</VDropdown>
			</template>
			<color-badge
				v-else
				color="light"
				icon="far fa-truck"
				:small="small"
				:content="dataStore.getData('suppliers', true)[supplierId].name"
			/>
		</template>
		<template v-else>
			<color-badge
				color="light"
				text-color="danger"
				icon="far fa-truck"
				:small="small"
				content="Törölt beszállító"
			/>
		</template>
	</data-loader>
</template>
