<template>
	<TitleBar :title="mode === 'deleted' ? 'Törölt ügyfelek' : 'Ügyfelek'" />

	<AdvancedDataTable
		:data-url="route('clients.list') + '?mode=' + mode"
		:columns="columns"
		sort-by="public_id"
		sort-type="desc"
		name="clients"
		show-tracked-edit-mode
	>
		<template #navigation>
			<ButtonGroup>
				<template #create v-if="app.hasPermission('ClientsController_create')">
					<Link :href="route('clients.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
				</template>
				<template #invite v-if="app.hasPermission('ClientsController_invite')">
					<Link :href="route('clients.invite')" class="btn btn-warning btn-label"><i class="fas fa-envelope label-icon"></i> Meghívó küldése</Link>
				</template>
				<template #deleted v-if="app.hasPermission('ClientsController_deleted') && mode !== 'deleted'">
					<Link :href="route('clients.deleted')"  class="btn btn-danger btn-label"><i class="fas fa-trash label-icon"></i> Törölt ügyfelek</Link>
				</template>
				<template #deleted v-if="mode === 'deleted'">
					<Link :href="route('clients.index')"  class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza</Link>
				</template>
			</ButtonGroup>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<div>{{ item.public_id }}</div>

						<div class="mobile-horizontal">
							<attribute v-if="item.handlerUserName" icon="far fa-user">
								{{ item.handlerUserName }}
							</attribute>
							<attribute icon="far fa-clock">
								<date-time :value="item.created_at"/>
							</attribute>
						</div>

						<color-badge
							:color="item.is_company ? 'dark' : 'light'"
							:icon="item.is_company ? 'far fa-industry' : 'far fa-user'"
							:content="item.is_company ? 'Cég' : 'Magánszemély'"
							:small="true"/>

						<div class="mobile-horizontal">
							<template v-if="item.phone">
								<phone-number :value="item.phone" :key="'clientPhoneNumber_' + item.id"/>
							</template>
							<template v-if="item.email">
								<email-address :value="item.email" :key="'clientEmail_' + item.id"/>
							</template>
						</div>
					</div>

					<div class="text-danger" v-if="item.deleted_at">
						<date-time :value="item.deleted_at"/>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="app.hasPermission('ClientsController_update') && mode !== 'deleted' ? route('clients.update') : ''"
					:delete-url="app.hasPermission('ClientsController_delete') && mode !== 'deleted' ? route('clients.delete') : ''"
					:is-editable="isEditable"
					:is-deletable="isDeletable"
					:tracked-edit-mode="trackedEditMode"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
				>
					<Link :href="route('clients.deleted', item.id)" class="btn btn-secondary btn-sm" title="Visszaállít" v-if="mode === 'deleted' && app.hasPermission('ClientsController_deleted')"><i class="far fa-trash-undo"></i></Link>
				</TableActionCell>
			</div>
		</template>
		<template #item-public_id="item">
			<div class="fw-bold">{{ item.public_id }}</div>
			<div v-if="item.handlerUserName"><i class="far fa-user"></i> {{ item.handlerUserName }}</div>
			<div><i class="far fa-clock"></i> <date-time :value="item.created_at"/></div>
		</template>
		<template #item-is_company="item">
			<color-badge
				:color="item.is_company ? 'dark' : 'light'"
				:icon="item.is_company ? 'far fa-industry' : 'far fa-user'"
				:content="item.is_company ? 'Cég' : 'Magánszemély'"
				:small="true"/>
		</template>
		<template #item-phone="item">
			<div v-if="item.phone">
				<phone-number :value="item.phone" :key="'clientPhoneNumber_' + item.id"/>
			</div>
		</template>
		<template #item-email="item">
			<div v-if="item.email">
				<email-address :value="item.email" :key="'clientEmail_' + item.id"/>
			</div>
		</template>
		<template #item-deleted_at="item">
			<div class="text-danger">
				<date-time :value="item.deleted_at"/>
			</div>
		</template>
	</AdvancedDataTable>

</template>

<script>
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import TableActionCell from "../components/TableActionCell.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import DateTime from "../components/DateTime.vue";
import ColorBadge from "../components/ColorBadge.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import ProjectTypeCell from "../modules/projects/ProjectTypeCell.vue";
import ClientCell from "../modules/projects/ClientCell.vue";
import ProjectMilestonesCell from "../modules/projects/ProjectMilestonesCell.vue";
import ProjectTableProjectCell from "../modules/projects/ProjectTableProjectCell.vue";
import EmailAddress from "../components/EmailAddress.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import Attribute from "../components/Attribute.vue";
export default {
	components: {
		Attribute,
		ButtonGroup,
		EmailAddress,
		ProjectTableProjectCell,
		ProjectMilestonesCell,
		ClientCell,
		ProjectTypeCell,
		PhoneNumber, ColorBadge, DateTime, TableActionCell, TitleBar, AdvancedDataTable, Link},
	props: {
		mode: String
	},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'public_id',
				sortable: true
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Típus',
				value: 'is_company',
				sortable: true
			},
			{
				text: 'E-mail cím',
				value: 'email',
				sortable: true
			},
			{
				text: 'Telefonszám',
				value: 'phone',
				sortable: true
			}
		]

		if (this.mode === 'deleted'){
			columns.push(
				{
					text: 'Törlés időpontja',
					value: 'deleted_at',
				})
		}

		columns.push(
			{
				text: '',
				value: 'operations',
				width: 5,
			})

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
	methods: {
		isEditable: function(row){
			return row.editable && this.mode !== 'deleted'
		},
		isDeletable: function(row){
			return row.deletable && this.mode !== 'deleted'
		}
	}
}
</script>

<style scoped>

</style>
