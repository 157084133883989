<template>
	<TitleBar title="Karrier" />

	<div class="card bg-secondary text-white">
		<div class="card-body">
			<h4 class="card-title mb-4">Ajánlói linkek</h4>
			<p>Az alábbi linkek egyikét küld el leendő ügyfeleidnek, hogy be tudják magukat regisztrálni.</p>
			<div class="row">
				<div class="col-md-6">
					<div>
						<i class="fas fa-user me-2"></i> Ajánlói link magánszemély: <a :href="consumerRefererLink" target="_blank">[link]</a> <CopyToClipboard :value="consumerRefererLink" />
					</div>
				</div>
				<div class="col-md-6">
					<div>
						<i class="fas fa-industry me-2"></i> Ajánlói link céges: <a :href="companyRefererLink" target="_blank">[link]</a> <CopyToClipboard :value="companyRefererLink" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="mb-4 card bg-primary bg-soft" v-if="currentPendingReward !== undefined && currentPendingReward.user_level_id !== undefined">
		<div class="card-body">
			<data-loader :data="['userLevels', 'currencies']" v-slot="{ dataStore }">
				<h4><i class="far fa-exclamation-circle"></i> Szintet léptél: {{ dataStore.getData('userLevels', true)[currentPendingReward.user_level_id].name }}</h4>
				<template v-if="currentPendingReward.reward_gift || currentPendingReward.reward_money">
					<p>Az alábbi gombokkal válaszd ki, melyik jutalmat kéred:</p>
					<div class="horizontal">
						<button type="button" class="btn btn-secondary" @click.prevent="selectLevelReward('money')" v-if="currentPendingReward.reward_money">
							<Price :value="currentPendingReward.reward_money" />
						</button>
						<button type="button" class="btn btn-secondary" @click.prevent="selectLevelReward('gift')" v-if="currentPendingReward.reward_gift">
							{{ currentPendingReward.reward_gift }}
						</button>
					</div>
				</template>
			</data-loader>
		</div>
	</div>
	<div class="card" v-if="userStats !== null">
		<div class="card-body">
			<h4 class="card-title mb-4">{{ userStats.userName }}</h4>
			<div class="row">
				<div class="col-3">
					<div class="mb-1">
						<data-loader :data="['userLevels']" v-slot="{ dataStore }">
							<color-badge
								:value="dataStore.getData('userLevels', true)[userStats.userLevelId]"
							></color-badge>
						</data-loader>
					</div>
					<p class="text-muted">Jelenlegi szint</p>
				</div>
				<div class="col-3">
					<h3 class="text-success fw-bold mb-0">
						<price :value="Math.round(statistics.paidCommission)"></price>
					</h3>
					<p class="text-muted">Kifizetett jutalék</p>
				</div>
				<div class="col-3">
					<h3 class="text-warning fw-bold mb-0">
						<price :value="Math.round(statistics.unpaidCommission)"></price>
					</h3>
					<p class="text-muted">Kifizetetlen jutalék</p>
				</div>
				<div class="col-3">
					<h3 class="text-danger fw-bold mb-0">
						<price :value="Math.round(statistics.pendingCommission)"></price>
					</h3>
					<p class="text-muted">Függőben lévő jutalék</p>
				</div>
			</div>
		</div>
	</div>
	<div class="row" v-if="userStats !== null">
		<div class="col-lg-6">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title mb-4">Teljesítmény</h4>
					<p class="text-muted">Utolsó 3 hónap</p>
					<div class="row">
						<div class="col-6">
							<h3 class="text-primary fw-bold mb-0">
								<number :value="userStats.direct"></number>
							</h3>
							<p class="text-muted">Saját egység</p>
						</div>
						<div class="col-6">
							<h3 class="text-warning fw-bold mb-0">
								<number :value="userStats.indirect"></number>
							</h3>
							<p class="text-muted">Csoport egység</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6" v-if="userStats.targetLevelId">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title mb-4">Következő szint</h4>
					<div class="row">
						<div class="col-sm-6">
							<data-loader :data="['userLevels']" v-slot="{ dataStore }">
								<p class="text-muted">{{ dataStore.getData('userLevels', true)[userStats.targetLevelId].name }}</p>
								<h3 class="text-success fw-bold mb-0">
									<number :value="dataStore.getData('userLevels', true)[userStats.targetLevelId].required_units"></number>
								</h3>
								<p class="text-muted">Egység</p>
							</data-loader>
						</div>
						<div class="col-sm-6">
							<div class="mt-4 mt-sm-0">
								<VueApexCharts
									type="radialBar"
									:height="'auto'"
									:options="progressChartOptions"
									:series="[userStats.targetProgress]"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<DynamicChart
		:data-url="route('career.chart') + '?type=performance'"
		title="Teljesítmény"
	></DynamicChart>

	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-4">Team</h4>
			<TeamTable
				:team-stats="teamStats"
			></TeamTable>
		</div>
	</div>
	<h4 class="card-title mb-4">Jutalékok</h4>
	<UserCommissionList
		:user-id="app.userId"
		ref="commissionList"
	/>
	<h4 class="card-title mb-4">Eladások</h4>
	<UserSaleList
		:user-id="app.userId"
	/>
	<h4 class="card-title mb-4">Függőben lévő jutalékok</h4>
	<UserPendingCommissionList
		:user-id="app.userId"
	/>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import Price from "../components/Price.vue";
import UserSaleList from "../modules/users/UserSaleList.vue";
import DynamicChart from "../components/DynamicChart.vue";
import UserCommissionList from "../modules/users/UserCommissionList.vue";
import UserPendingCommissionList from "../modules/users/UserPendingCommissionList.vue";
import DataLoader from "../components/DataLoader.vue";
import CopyToClipboard from "../components/CopyToClipboard.vue";
import ColorBadge from "../components/ColorBadge.vue";
import TeamTable from "../modules/career/TeamTable.vue";
import {useFlashStoreStore} from "../stores/flashStore";
import AppLayout from "../layouts/AppLayout.vue";
import {useApplicationStore} from "../stores/applicationStore";
import Number from "../components/Number.vue";
import TitleBar from "../components/TitleBar.vue";
import {router} from "@inertiajs/vue3";

export default {
	layout: AppLayout,
	components: {
		TitleBar,
		Number,
		TeamTable,
		ColorBadge,
		CopyToClipboard,
		DataLoader,
		UserPendingCommissionList,
		UserCommissionList,
		DynamicChart,
		UserSaleList,
		Price,
		VueApexCharts
	},
	props: {
		consumerRefererLink: String,
		companyRefererLink: String,
		pendingReward: Object,
		userLevelsEnabled: Boolean,
		canCreateCommission: Boolean,
		userStats: Object,
		teamStats: Array,
		statistics: Object,
	},
	data(){
		return {
			flashStore: useFlashStoreStore(),
			app: useApplicationStore(),
			currentPendingReward: this.pendingReward,
			progressChartOptions: {
				labels: ['Haladás'],
				chart: {
					type: 'radialBar',
					offsetY: -15,
					sparkline: {
						enabled: true
					}
				},
				plotOptions: {
					radialBar: {
						startAngle: -90,
						endAngle: 90,
						track: {
							background: "#e7e7e7",
							strokeWidth: '97%',
							margin: 2, // margin is in pixels
						},
						dataLabels: {
							name: {
								show: false
							},
							value: {
								offsetY: -1,
								fontSize: '20px'
							}
						}
					}
				}
			},
		}
	},
	methods: {
		selectLevelReward: function(type){
			axios.post('/users/selectLevelReward', {reward: type}).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				if (response.data.success !== undefined && response.data.success) {
					this.currentPendingReward = response.data.pendingReward

					this.$refs.commissionList.reload()
				}
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		}
	}
}
</script>

<style scoped>

</style>
