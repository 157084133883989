<script setup>
import ColorBadge from "../ColorBadge.vue";
import {computed} from "@vue/compat";

const model = defineModel()
const props = defineProps({
	trueLabel: {
		type: String,
		default: 'Igen'
	},
	falseLabel: {
		type: String,
		default: 'Nem'
	},
	icon: String,
	alternativeColors: Boolean,
	active: Boolean,
	public: Boolean,
	default: Boolean,
	company: Boolean,
	completed: Boolean,
	primary: Boolean,
	hgv: Boolean
})

const iconComputed = computed(() => {
	if (props.icon !== undefined){
		return props.icon
	}

	return model.value ? 'far fa-check' : 'far fa-times'
})

const colorComputed = computed(() => {
	if (props.alternativeColors !== undefined && props.alternativeColors){
		return model.value ? 'light' : 'dark'
	}

	return model.value ? 'success' : 'danger'
})
const trueLabelComputed = computed(() => {
	if (props.active){
		return 'Aktív'
	}
	if (props.public){
		return 'Publikus'
	}
	if (props.default){
		return 'Alapértelmezett'
	}
	if (props.company){
		return 'Cég'
	}
	if (props.completed){
		return 'Teljesítve'
	}
	if (props.primary){
		return 'Elsődleges'
	}
	if (props.hgv){
		return 'Nehéz tehergépjármű'
	}

	return props.trueLabel
})
const falseLabelComputed = computed(() => {
	if (props.active){
		return 'Inaktív'
	}
	if (props.public){
		return 'Nem publikus'
	}
	if (props.default){
		return 'Nem alapértelmezett'
	}
	if (props.company){
		return 'Magánszemély'
	}
	if (props.completed){
		return 'Nincs teljesítve'
	}
	if (props.primary){
		return 'Nincs elsődleges'
	}
	if (props.hgv){
		return 'Normál tehergépjármű'
	}

	return props.trueLabel
})
</script>

<template>
	<color-badge
		:color="colorComputed"
		:icon="iconComputed"
		:content="model ? trueLabelComputed : falseLabelComputed"
		small
		/>
</template>

<style scoped>

</style>
