<template>
	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-3">Termékkategóriák</h4>
			<table class="table">
				<thead>
				<tr>
					<th width="10%">Árajánlat nélkül</th>
					<th width="10%">Árajánlattal</th>
					<th width="10%">Kötelező</th>
					<th>Kategória</th>
					<th width="15%">Min termék</th>
					<th width="15%">Min termék fajta</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="category in productCategories">
					<td>
						<div>
							<input
								type="checkbox"
								:id="'productCategory_without_quote_' + category.id"
								switch="bool"
								v-model="componentValue[category.id].without_quote"
								value="1"
							>
							<label :for="'productCategory_without_quote_' + category.id" data-on-label="" data-off-label=""></label>
						</div>
					</td>
					<td>
						<div>
							<input
								type="checkbox"
								:id="'productCategory_with_quote_' + category.id"
								switch="bool"
								v-model="componentValue[category.id].with_quote"
								value="1"
							>
							<label :for="'productCategory_with_quote_' + category.id" data-on-label="" data-off-label=""></label>
						</div>
					</td>
					<td>
						<div v-if="componentValue[category.id].with_quote">
							<input
								type="checkbox"
								:id="'productCategory_required_' + category.id"
								switch="bool"
								v-model="componentValue[category.id].required"
								value="1"
							>
							<label :for="'productCategory_required_' + category.id" data-on-label="" data-off-label=""></label>
						</div>
					</td>
					<td>
						{{ category.name }}
					</td>
					<td>
						<input v-if="componentValue[category.id].required" type="text" class="form-control" v-model="componentValue[category.id].min_product_quantity" />
					</td>
					<td>
						<input v-if="componentValue[category.id].required" type="text" class="form-control" v-model="componentValue[category.id].min_product_type" />
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-3">Opcionális termékek bekapcsolása</h4>
			<select-field
				label="Termékek"
				:multiple="true"
				:name="'optionalsSelected'"
				:options="optionalProductArray"
				v-model="componentOptionalsSelected"
			>
				<template v-slot:option="option">
					({{ option.option.sku }}) {{ option.option.name }}
				</template>
				<template v-slot:selected-option="option">
					({{ option.option.sku }}) {{ option.option.name }}
				</template>
			</select-field>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-3">Termék cimkék</h4>
			<table class="table">
				<thead>
				<tr>
					<th width="30%">Termék</th>
					<th width="20%">Cimke</th>
					<th width="20%">Publikus (PDF, client area)</th>
					<th>&nbsp;</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(tag, tagIndex) in componentProductTags">
					<td>
						<select-field
							label="Termék"
							:field-only="true"
							:name="'projectTypeProductTag_product_id_' + tagIndex"
							:options="productArray"
							v-model="tag.product_id"
							v-if="!tag.product_id"
							>
							<template v-slot:option="option">
								({{ option.option.sku }}) {{ option.option.name }}
							</template>
							<template v-slot:selected-option="option">
								({{ option.option.sku }}) {{ option.option.name }}
							</template>
						</select-field>
						<div v-else>
							({{ products[tag.product_id].sku }}) {{ products[tag.product_id].name }}
						</div>
					</td>
					<td>
						<select-field
							label="Cimke"
							:field-only="true"
							:name="'projectTypeProductTag_tag_id_' + tagIndex"
							:options="tags"
							label-field="label"
							v-model="tag.tag_id"
						></select-field>
					</td>
					<td>
						<switch-field
							:name="'projectTypeProductTag_use_in_quote_' + tagIndex"
							v-model="tag.use_in_quote"
							label="Publikus"
							:field-only="true"
							></switch-field>
					</td>
					<td>
						<button type="button" class="btn btn-danger btn-sm" title="Törlés" @click.prevent="removeProductTag(tagIndex)"><i class="far fa-times"></i></button>
					</td>
				</tr>
				</tbody>
			</table>

			<div class="mt-3">
				<button type="button" class="btn btn-secondary btn-label" @click.prevent="addProductTag">
					<i class="far fa-plus label-icon"></i> Hozzáad
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import SwitchField from "../../components/form/SwitchField.vue";
import SelectField from "../../components/form/SelectField.vue";
import ProductSelectField from "../../components/form/ProductSelectField.vue";
import {objectToArray} from "../../functions";
export default {
	emits: ['update:modelValue', 'update:productTags', 'update:optionalsSelected'],
	components: {
		ProductSelectField,
		SelectField,
		SwitchField,
	},
	props: {
		modelValue: Object,
		productTags: Array,
		tags: Array,
		products: Object,
		productCategories: Array,
		optionalsSelected: Array,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue ? this.modelValue : {}
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
		componentProductTags: {
			get() {
				return this.productTags ? this.productTags : []
			},
			set(val) {
				this.$emit('update:productTags', val)
			}
		},
		componentOptionalsSelected: {
			get() {
				return this.optionalsSelected ? this.optionalsSelected : []
			},
			set(val) {
				this.$emit('update:optionalsSelected', val)
			}
		},
		productArray: {
			get() {
				return this.products
			}
		},
		optionalProductArray: {
			get() {
				let tmp = _.filter(this.products, function (t){
					return t.is_optional > 0
				})
				return tmp
			}
		},
	},
	data(){
		return {
		}
	},
	methods: {
		addProductTag: function (){
			this.componentProductTags.push({
				product_id: '',
				tag_id: ''
			})
		},
		removeProductTag: function (index){
			this.componentProductTags.splice(index, 1)
		}
	}
}
</script>

<style scoped>

</style>
