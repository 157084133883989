<template>
	<div :class="{'mb-3': !fieldOnly && !noMargin}">
		<label :class="{required: isRequired}" v-if="!fieldOnly">{{ label }}</label>
		<slot></slot>
		<Field
			v-model="componentValue"
			:name="name"
			:readonly="disabled"
			:disabled="disabled"
			@input="onInput"
			:rules="rulesValue">
			<TextareaAutosize
				v-model="componentValue"
				:editable="!disabled"
				:class="{'form-control': 1, 'is-invalid': errors[name]}"
				:rows="rows"
				:placeholder="fieldOnly ? label : null"
			></TextareaAutosize>
		</Field>
		<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
		<p class="text-muted mt-2 text-info" v-if="info" v-html="info"></p>

        <div v-if="multilang && hasTranslatableLanguage" class="bg-light mt-1 p-2 d-flex gap-2 flex-column" style="border-radius: 0.25rem">
            <template v-for="language in languages">
                <div v-if="language.active && !language.is_default">
                    <div class="input-group">
                        <span class="input-group-text">{{ language.name }}</span>
                        <TextareaAutosize
                            v-model="componentTranslations[(translationFieldName ? translationFieldName : name) + '_' + language.code]"
                            :editable="!disabled"
                            :rows="rows"
                        ></TextareaAutosize>
                    </div>
                </div>
            </template>
        </div>
	</div>
</template>

<script>
import TextareaAutosize from "../TextareaAutosize.vue";
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
export default {
	components: {Field, ErrorMessage, TextareaAutosize},
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: String,
		name: {
			type: String,
			default: ''
		},
		modelValue: [String, Number],
		errors: {
			type: Object,
			default: function(){
				return {}
			}
		},
        translations: [Object],
        multilang: {
            type: Boolean,
            default: true
        },
        translationFieldName: {
            type: String,
            default: ''
        },
        languages: {
            type: Array,
            default: function(){
                return []
            }
        },
		info: {
			type: String,
			default: ''
		},
		fieldOnly: Boolean,
		noMargin: Boolean,
		rows: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
		}

	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		isRequired: {
			get(){
				return this.rules.indexOf('required') > -1
			}
		},
        hasTranslatableLanguage: {
            get() {
                for (let i in this.languages){
                    if (!this.languages[i].is_default && this.languages[i].active){
                        return true
                    }
                }

                return false
            }
        },
        componentTranslations: {
            get() {
                return this.translations
            },
            set(value) {
                this.$emit('update:translations', value)
            }
        },
		rulesValue: {
			get() {
				let tmp = this.rules.split('|')
				let rules = yup.string()

				if (tmp.indexOf('required') > -1){
					rules = rules.required(() => {
						return this.$t(':field kitöltése kötelező', {field: this.label})
					})
				} else {
					rules = rules.nullable()
				}
				_.forEach(tmp, (rule) => {
					if (rule.indexOf('min:') > -1){
						rule = rule.replace('min:', '')
						rules = rules.min(parseInt(rule), function(field){
							return this.$t(':field minimum :min karakter hosszú legyen', {field: this.label, min: field.min})
						})
					}
				})
				rules = rules.label(this.label)

				return rules
			}
		},
	},
	methods: {
		onInput: function(){
			this.$emit('input')
		},
	}
}
</script>

<style scoped>

</style>
