<script setup>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import {Link} from "@inertiajs/vue3";
import Number from "../components/Number.vue";
import ColorBadge from "../components/ColorBadge.vue";
import DataLoader from "../components/DataLoader.vue";
import {useApplicationStore} from "../stores/applicationStore";
import ProjectIdAttribute from "../components/attributes/ProjectIdAttribute.vue";

const app = useApplicationStore()
const props = defineProps({
	projectDetails: Object,
	products: Array
})

</script>

<template>
	<AppLayout>
		<TitleBar :title="'Kintlévő készlet'" />

		<div class="d-flex flex-wrap gap-2 mb-3">
			<div class="d-flex p-2 gap-2">
				<Link :href="route('inventoryReturns.index')" class="btn btn-secondary btn-label"><i class="far fa-angle-left label-icon"></i> Vissza</Link>
				<data-loader :data="['warehouses']" v-slot="{ dataStore }">
					<div class="dropdown" v-if="products.length && app.hasPermission('InventoryController_createDelivery')">
						<button type="button" class="btn btn-secondary btn-label waves-effect" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="far fa-truck label-icon"></i> Szállítmány létrehozása
						</button>
						<div class="dropdown-menu" role="menu">
							<div class="d-flex gap-2 flex-column">
								<template v-for="warehouse in dataStore.getData('warehouses')" :key="'createDeliveryButton_' + warehouse.id">
									<Link :href="'/inventory/create/return/' + projectDetails.id + '/' + warehouse.id" class="dropdown-item" v-if="!warehouse.mobile">
										{{ warehouse.name }}
									</Link>
								</template>
							</div>
						</div>
					</div>
				</data-loader>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="details-box">
					<project-id-attribute :project-id="projectDetails.id" :public-id="projectDetails.public_id" :project-type-id="projectDetails.project_type_id" />
				</div>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<h4 class="card-title mb-3">Termékek</h4>
				<table class="table table-collapse-mobile-auto mb-3">
					<thead>
					<tr>
						<th>Termék</th>
						<th>Sorozatszám</th>
						<th class="text-end">Mennyiség</th>
					</tr>
					</thead>
					<tbody>
					<template v-for="(item, itemKey) in products" :key="'product_' + itemKey">
						<tr>
							<td>
								<div>{{ item.name }}</div>
								<div class="horizontal">
									<color-badge
										icon="fas fa-hashtag"
										color="light"
										small
									>
										{{ item.sku }}
									</color-badge>
									<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
										<color-badge
											icon="fas fa-industry-alt"
											color="light"
											small
											v-if="item.product_manufacturer_id"
										>
											{{ dataStore.getData('productManufacturers', true)[item.product_manufacturer_id].name }}
										</color-badge>
									</data-loader>
								</div>
							</td>

							<td class="text-end">
								{{ item.serial_number }}
							</td>
							<td class="text-end">
								{{ item.to_return }} {{ item.unit }}
							</td>
						</tr>
					</template>
					</tbody>
				</table>
			</div>
		</div>
	</AppLayout>
</template>

<style scoped>

</style>
