<template>
	<div class="px-3">
		<div class="card mx-0">
			<div class="p-2">
				<div class="d-flex mb-2 gap-2 align-items-center">
					<button type="button" class="btn btn-primary btn-label mobile-icon" @click.prevent="createNewTask(true)">
						<i class="far fa-plus label-icon"></i> <span>Feladat hozzáadása</span>
					</button>
					<button type="button" class="btn btn-secondary btn-label mobile-icon" @click.prevent="createNewTask(false)" v-if="app.hasPermission('TasksController_create')">
						<i class="far fa-user-plus label-icon"></i> <span>Feladat kiosztása</span>
					</button>
					<div class="dropdown">
						<button type="button" class="btn btn-secondary btn-label" data-bs-toggle="dropdown" aria-expanded="false" style="height: 41px">
							<span class="d-flex gap-2 align-items-center">
								<i class="far fa-eye label-icon"></i>
								<span class="d-none d-lg-inline">Adatok:</span>
								<template v-for="type in availableTypes">
									<i :class="type.icon" v-if="settings.selectedTypes !== undefined && (settings.selectedTypes.indexOf(type.id) > -1 || !settings.selectedTypes.length)"></i>
								</template>
								<i class="far fa-angle-down"></i>
							</span>
						</button>
						<ul class="dropdown-menu">
							<template v-for="type in availableTypes">
								<li>
									<a class="dropdown-item" href="#" @click.prevent="toggleType(type.id)">
										<i :class="type.icon + (settings.selectedTypes === undefined || settings.selectedTypes.indexOf(type.id) > -1 ? ' text-primary' : '')"></i> {{ type.name }}
									</a>
								</li>
							</template>
						</ul>
					</div>
					<div class="ms-auto">
						<div class="btn-group">
							<button type="button" :class="{'btn':1, 'btn-light':1, 'active': settings.periodLength === 'week'}" @click.prevent="settings.periodLength = 'week'">
								hét
							</button>
							<button type="button" :class="{'btn':1, 'btn-light':1, 'active': settings.periodLength === 'month'}" @click.prevent="settings.periodLength = 'month'">
								hónap
							</button>
						</div>
					</div>
				</div>
				<CalendarView
					:show-date="settings.date"
					:display-period-uom="settings.periodLength"
					:starting-day-of-week="1"
					class="theme-default">

					<template #header="{ headerProps }">
						<calendar-view-header slot="header" :header-props="headerProps" @input="setShowDate" />
					</template>
					<template #day-content="{ day }">
						<div class="cv-day-number-mobile">
							<div class="">{{ day.getFullYear() }} {{ day.toLocaleString('default', { month: 'long' }) }} {{ day.getDate() }}</div>
							<strong>{{ day.toLocaleString('default', { weekday: 'long' }) }}</strong>
						</div>
						<div class="calendar-day">
							<template v-for="event in getDayEvents(day)" :key="event.type + '_' + event.model.id">
								<template v-if="event.type === 'task' && (event.model.type === 'personal' || event.model.type === 'project')">
									<div class="calendar-task" @click.prevent="showTaskForm(event.model)">
										<div class="calendar-task-details">
											<i :class="{
												'far': 1,
												'fa-list': event.model.type === 'personal',
												'fa-project-diagram': event.model.type === 'project',
												'text-warning': event.model.type === 'personal',
												'text-primary': event.model.type === 'project',
											}"></i>
											<attribute v-if="event.model.time && !isDeadline(day, event.model)"
													   icon="far fa-clock"
													   :content="event.model.time.substring(0, 5)"
											/>
											<template v-if="event.model.deadline_date">
												<color-badge
													:color="getTaskBadgeColor(event.model)"
													:small="true"
												>
													<date-time
														v-if="event.model.deadline_time"
														:value="event.model.deadline_date + ' ' + event.model.deadline_time"
													/>
													<date-time
														v-else
														:time="false"
														:value="event.model.deadline_date"
													/>
												</color-badge>
											</template>
											<template v-if="event.model.transfer_to_user_id">
												<color-badge v-if="event.model.assigned_to_user_id === componentUserId" color="light" icon="far fa-random" small>
													<user-badge :user-id="event.model.transfer_to_user_id" hide-link hide-level/>
												</color-badge>
												<color-badge v-if="event.model.transfer_to_user_id === componentUserId" color="warning" icon="far fa-random" small>
													<user-badge :user-id="event.model.assigned_to_user_id" hide-link hide-level/>
												</color-badge>
											</template>
											<color-badge
												v-if="event.model.completed"
												color="success"
												icon="far fa-check"
												small
											>
												<DateTime :value="event.model.completed_at"/>
											</color-badge>
											<div class="calendar-task-text">
												<div v-if="event.model.type === 'project'" class="mb-1 fw-semibold">
													{{ event.model.details_as_array.clientName }}
												</div>
												<div v-html="truncate(event.model.title, 100, true)"></div>
											</div>
										</div>
									</div>
								</template>
								<template v-if="event.type === 'installation'">
									<div class="calendar-installation" @click.prevent="redirectToInstallation(event.model)">
										<div class="d-flex gap-2 mb-2">
											<i class="far fa-tools text-success"></i>
											<strong>Telepítés</strong>
										</div>
										<div class="d-flex gap-2 flex-wrap">
											<div :class="'calendar-project-title' + (projectTypes[projectsById[event.model.project_id].project_type_id].luminosity === 'dark' ? ' text-white' : '')"
												 :style="projectTypes[projectsById[event.model.project_id].project_type_id].color ? 'background:' + projectTypes[projectsById[event.model.project_id].project_type_id].color : ''">
												<i :class="'far fa-' + (projectTypes[projectsById[event.model.project_id].project_type_id].icon ? projectTypes[projectsById[event.model.project_id].project_type_id].icon : 'project-diagram')"></i>
												<div>{{ projectsById[event.model.project_id].clientName }}</div>
											</div>
											<attribute v-if="event.model.installer_user_id" icon="far fa-user">
												<user-badge :user-id="event.model.installer_user_id" hide-level hide-link />
											</attribute>
											<div v-if="event.model.comment">
												{{ event.model.comment }}
											</div>
										</div>
									</div>
								</template>
							</template>
							<div v-for="project in getProjectsByDate(day)" :key="'project_' + project.id + '_' + day">
								<div class="calendar-project">
									<div :class="'calendar-project-head' + (projectTypes[project.project_type_id].luminosity === 'dark' ? ' text-white' : '')" @click="redirectToProject(project)" :style="projectTypes[project.project_type_id].color ? 'background:' + projectTypes[project.project_type_id].color : ''">
										<i :class="'far fa-' + (projectTypes[project.project_type_id].icon ? projectTypes[project.project_type_id].icon : 'project-diagram')"></i>
										<div>{{ project.clientName }}</div>
									</div>
									<div class="calendar-project-events">
										<template v-for="(event, eventIndex) in getProjectEventsByDate(project, day)" :key="'project_' + project.id + '_' + event.id + '_' + day.getTime()">
											<div class="calendar-project-event">
												<project-timeline-entry
													:event="event"
													:show-seen-events="true"
													compact
													:show-date="false"
													:fade-seen="false"
													vertical
													icon-color-only
												/>
											</div>
										</template>
									</div>
								</div>
							</div>
						</div>
					</template>
				</CalendarView>
			</div>
		</div>
	</div>
	<task-modal
		v-if="taskFormOpen"
		:new-task-to-self="taskToSelf"
		:task-id="taskId"
		:user-id="userId !== 'all' ? userId : null"
		@close="taskFormOpen = false"
		@close-and-reload="closeTaskFormAndReload"
		/>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
import {usePermanentStore} from "../../stores/permanentStore";
import {useFlashStoreStore} from "../../stores/flashStore";
import TaskModal from "./TaskModal.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import Attribute from "../../components/Attribute.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import DateTime from "../../components/DateTime.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";
import ProjectStatusIcon from "../projects/ProjectStatusIcon.vue";
import {router} from "@inertiajs/vue3";
import UserBadge from "../../components/badges/UserBadge.vue";
import ProjectTimelineEntry from "./ProjectTimelineEntry.vue";

export default {
	props: {
		showProjects: Boolean,
		projectEventTypes: Object,
		projectTypeMilestones: Object,
		projectTypes: Object,
		availableTypes: Array,
		userId: [String, Number]
	},
	data() {
		let today = new Date()
		const store = usePermanentStore()
		return {
			date: today,
			events: {},
			settings: store.get('calendarSettings', {
				periodLength: 'week',
				date: new Date(),
				showProjects: false,
				selectedTypes: []
			}),
			flashStore: useFlashStoreStore(),
			taskFormOpen: false,
			taskToSelf: false,
			taskId: null,
			store: store,
			projects: [],
			app: useApplicationStore(),
		}
	},
	mounted() {
		this.loadEvents()
	},
	watch: {
		showProjects(oldv, newv){
			this.settings.showProjects = this.showProjects
		},
		userId(oldv, newv){
			this.loadEvents()
		},
		settings: {
			handler(newVal, oldVal){
				this.store.set('calendarSettings', this.settings)
				this.loadEvents()
			},
			deep: true
		}
	},
	components: {
		ProjectTimelineEntry,
		UserBadge,
		ProjectStatusIcon,
		ProjectTypeCell,
		DateTime,
		ColorBadge,
		Attribute,
		TaskModal,
		CalendarView,
		CalendarViewHeader,
	},
	computed: {
		currentDate: {
			get() {
				return window.moment(this.date).format('YYYY-MM-DD')
			}
		},
		projectsById: {
			get(){
				return _.keyBy(this.projects, 'id')
			}
		},
		componentUserId: {
			get() {
				return this.userId !== null && this.userId !== 'all' ? this.userId : this.app.userId
			}
		}
	},
	methods: {
		setShowDate: function (date){
			this.settings.date = date
		},
		jumpToToday: function (){
			this.setShowDate()
		},
		createNewTask: function (toSelf){
			this.taskId = null
			this.taskToSelf = toSelf
			this.taskFormOpen = true
		},
		showTaskForm: function (task){
			this.taskId = task ? task.id : null
			this.taskFormOpen = true
		},
		loadEvents: function (){
			let params = []
			if (this.showProjects){
				params.push('showProjects=true')
			}
			if (this.userId){
				params.push('userId=' + this.userId)
			}
			if (this.settings.selectedTypes !== undefined){
				params.push('types=' + (this.settings.selectedTypes.join(',')))
			}

			const dateFormatted = window.moment(this.settings.date).format('YYYY-MM-DD')
			axios.get('/dashboard/calendar/' + this.settings.periodLength + '/' + dateFormatted + (params.length ? '?' + params.join('&') : '')).then((response)=>{
				this.events = response.data.events
				this.projects = response.data.projects
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		getDayEvents: function (date){
			let payload = []
			const dateFormatted = window.moment(date).format('YYYY-MM-DD')
			_.forEach(this.events, function (dayEvents, key){
				if (key === dateFormatted){
					payload = dayEvents
				}
			})

			return payload
		},
		isDeadline: function (date, item){
			if (item.deadline_date){
				return window.moment(item.deadline_date).format('YYYY-MM-DD') === window.moment(date).format('YYYY-MM-DD')
			}

			return false
		},
		closeTaskFormAndReload: function (){
			this.taskFormOpen = false
			this.loadEvents()
		},
		truncate: function( str, n, useWordBoundary){
			if (str.length <= n) { return str; }
			const subString = str.slice(0, n-1);
			return (useWordBoundary
				? subString.slice(0, subString.lastIndexOf(" "))
				: subString) + "&hellip;";
		},
		getProjectsByDate: function (date){
			let payload = []
			const dateString = window.moment(date).format('YYYY-MM-DD')
			if (this.events[dateString] !== undefined){
				_.forEach(this.events[dateString], (event) => {
					if (event.type === 'project'){
						if (this.projectsById[event.model.project_id] !== undefined) {
							payload.push(this.projectsById[event.model.project_id])
						}
					}
				})
			}
			return _.uniq(payload)
		},
		getProjectEventsByDate: function (project, date){
			let payload = []
			const dateString = window.moment(date).format('YYYY-MM-DD')
			if (this.events[dateString] !== undefined){
				_.forEach(this.events[dateString], function (event){
					if (event.type === 'project' && event.model.project_id === project.id){
						payload.push(event.model)
					}
				})
			}
			return payload
		},
		redirectToProject: function (project){
			router.visit('/projects/edit/' + project.id)
		},
		redirectToInstallation: function (model){
			if (this.app.hasPermission('InstallationsController_view')){
				router.visit('/installations/edit/' + model.id)
			} else {
				router.visit('/projects/edit/' + model.project_id)
			}
		},
		getTaskBadgeColor: function (event){
			if (event.deadline_date !== null && !event.completed){
				if (event.deadline_time){
					if (window.moment(event.deadline_date + ' ' + event.deadline_time) < new Date()){
						return 'danger'
					}
				} else {
					if (window.moment(event.deadline_date) < new Date()){
						return 'danger'
					}
				}
			}

			return event.completed ? 'success' : 'warning'
		},
		toggleType: function (id){
			if (this.settings.selectedTypes === undefined){
				this.settings.selectedTypes = []
			}
			if (!this.settings.selectedTypes.length){
				_.forEach(this.availableTypes, (type) => {
					if (type.id !== id) {
						this.settings.selectedTypes.push(type.id)
					}
				})
			} else {
				if (this.settings.selectedTypes.indexOf(id) > -1) {
					this.settings.selectedTypes.splice(this.settings.selectedTypes.indexOf(id), 1)
				} else {
					this.settings.selectedTypes.push(id)
				}
			}

			this.loadEvents()
		},

	}
}
</script>
