<template>
	<div class="mb-3" v-if="creatingEnabled">
		<div class="d-flex gap-3 align-items-center mb-2">
			<VueUploadComponent
					class="btn btn-primary"
					post-action="/uploadFile"
					:extensions="documentType.file_types"
					:multiple="true"
					:size="1024*1024*64"
					:thread="1"
					:headers="uploadHeaders"
					:drop="true"
					:accept="documentType.file_types_formatted"
					v-model="uploadingFiles"
					@input-filter="uploadInputFilter"
					@input-file="uploadInputFile"
					:input-id="'documentCategoryUpload' + documentType.id"
					:ref="'upload' + documentType.id">
				<i class="fa fa-upload"></i>
				Feltöltés
			</VueUploadComponent>
			<div class="text-muted text-info">
				Fileok feltöltéséhez kattints a gombra!
			</div>
		</div>
	</div>
	<div class="alert alert-info" v-if="!getDocumentTypeCount(documentType) && !uploadingFiles.length">Nincs feltöltve dokumentum.</div>
	<template v-if="typeof documentType === 'object' && (getDocumentTypeCount(documentType) || uploadingFiles.length)">
		<template v-for="(file, index) in uploadingFiles">
			<div class="card border shadow-none mb-2 file-card" v-if="file.documentTypeId === documentType.id">
				<div class="file-card-content">
					<div class="file-card--thumbnail">
						<img class="img-responsive" v-if="file.thumb" :src="file.thumb" @click="lightboxImage = file.thumb" :alt="file.name" />
						<div class="file-icon" v-else>
							<FileIcon :filename="file.name"></FileIcon>
						</div>
						<div class="text-center mt-2" v-if="projectDataStore.project.editable && editable && projectDataStore.can('documents', 'delete')">
							<a href="#" class="text-danger" @click.prevent="uploadingFiles.splice(index, 1)"><i class="far fa-times"></i> töröl</a>
						</div>
					</div>
					<div class="file-card--details">
						<div class="filename fw-bold">
							{{file.name}}
						</div>
						<div class="d-flex flex-wrap gap-2">
							<attribute icon="far fa-file">
								<file-size :value="file.size"/>
							</attribute>
							<attribute v-if="file.width" icon="far fa-image" :content="file.width + ' x ' + file.height + ' px'" />
						</div>
						<div class="progress mt-2" v-if="file.active || file.progress !== '0.00'">
							<div :class="{'progress-bar': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template v-for="(file, index) in projectDataStore.uploadedFiles[documentType.id]" :key="file.id">
			<div class="card border shadow-none mb-2 file-card">
				<div class="file-card-content">
					<div class="file-card--thumbnail">
						<img class="img-responsive" v-if="file.thumb" :src="file.thumb" @click="lightboxImage = file.thumb" :alt="file.name" />
						<div class="file-icon" v-else>
							<FileIcon :filename="file.name"></FileIcon>
						</div>
						<div class="text-center mt-2" v-if="projectDataStore.project.editable && editable && projectDataStore.can('documents', 'delete')">
							<a href="#" class="text-danger" @click.prevent="projectDataStore.uploadedFiles[documentType.id].splice(index, 1)"><i class="far fa-times"></i> töröl</a>
						</div>
					</div>
					<div class="file-card--details">
						<div class="filename fw-bold">
							{{file.name}}
						</div>
						<div class="d-flex flex-wrap gap-2">
							<attribute icon="far fa-file">
								<file-size :value="file.size"/>
							</attribute>
							<attribute v-if="file.width" icon="far fa-image" :content="file.width + ' x ' + file.height + ' px'" />
						</div>

						<data-loader :data="['documentTags']" v-slot="{ dataStore }">
							<div class="d-flex gap-2 align-items-center">
								<i class="far fa-tags fa-fw"></i>
								<div class="attribute-label">Cimkék:</div>
								<template v-for="tag in file.tags">
									<color-badge
										color="secondary"
										:content="dataStore.getData('documentTags', true)[tag].label"
										:remove-button="projectDataStore.project.editable && editable && projectDataStore.can('documents', 'update')"
										@removePressed="removeTagFromFile(file, tag)"
									/>
								</template>

								<div class="dropdown" v-if="getAvailableTags(file).length && projectDataStore.project.editable && editable && projectDataStore.can('documents', 'update')">
									<button type="button" class="btn btn-secondary btn-sm waves-effect" data-bs-toggle="dropdown" :id="'addFileTag_' + file.id" aria-expanded="false"><i class="far fa-plus"></i></button>
									<div class="dropdown-menu dropdown-menu-end" :aria-labelledby="'addFileTag_' + file.id" role="menu">
										<div class="d-flex gap-2 flex-column">
											<template v-for="tag in getAvailableTags(file)">
												<a href="#" class="dropdown-item" @click.prevent="addTagToFile(file, tag)">{{ tag.label }}</a>
											</template>
										</div>
									</div>
								</div>
							</div>
						</data-loader>

						<TextareaAutosize
							v-model="file.description"
							:classes="{'form-control': 1}"
							:rows="1"
							placeholder="Leírás"
						></TextareaAutosize>
					</div>
				</div>
			</div>
		</template>
	</template>


	<template v-for="(file, index) in projectDataStore.documents[documentType.id]" :key="file.id">
		<div class="card border shadow-none mb-2 file-card">
			<div class="file-card-content">
				<div class="file-card--thumbnail">
					<img class="img-responsive" v-if="file.thumbnail" :src="file.thumbnail" @click="lightboxImage = file.download_url" :alt="file.name + '.' + file.extension"/>
					<div class="file-icon" v-else>
						<FileIcon :filename="file.extension"></FileIcon>
					</div>
					<div class="text-center mt-2" v-if="projectDataStore.project.editable && editable && projectDataStore.can('documents', 'delete')">
						<a href="#" class="text-danger" @click.prevent="projectDataStore.documents[documentType.id].splice(index, 1)"><i class="far fa-times"></i> töröl</a>
					</div>
				</div>
				<div class="file-card--details">
					<div class="filename fw-bold">
						<a :href="file.download_url" target="_blank" :title="file.name + '.' + file.extension"><i class="far fa-download"></i> {{file.name}}</a>
					</div>
					<div class="d-flex flex-wrap gap-2">
						<attribute icon="far fa-file">
							<file-size :value="file.size"/>
						</attribute>
						<attribute v-if="file.width" icon="far fa-image" :content="file.width + ' x ' + file.height + ' px'" />
						<attribute icon="far fa-calendar-alt">
							<date-time :value="file.created_at"/>
						</attribute>
						<attribute icon="far fa-user" :content="file.created_by_user_name" />
					</div>

					<data-loader :data="['documentTags']" v-slot="{ dataStore }" v-if="(projectDataStore.project.editable && editable && projectDataStore.can('documents', 'update')) || file.tags.length">
						<div class="d-flex gap-2 align-items-center">
							<i class="far fa-tags fa-fw"></i>
							<div class="attribute-label">Cimkék:</div>
							<template v-for="tag in file.tags">
								<color-badge
									color="secondary"
									:content="dataStore.getData('documentTags', true)[tag].label"
									:remove-button="projectDataStore.project.editable && editable && projectDataStore.can('documents', 'update')"
									@removePressed="removeTagFromFile(file, tag)"
								/>
							</template>

							<div class="dropdown" v-if="getAvailableTags(file).length && projectDataStore.project.editable && editable && projectDataStore.can('documents', 'update')">
								<button type="button" class="btn btn-secondary btn-sm waves-effect" data-bs-toggle="dropdown" :id="'addFileTag_' + file.id" aria-expanded="false"><i class="far fa-plus"></i></button>
								<div class="dropdown-menu dropdown-menu-end" :aria-labelledby="'addFileTag_' + file.id" role="menu">
									<div class="d-flex gap-2 flex-column">
										<template v-for="tag in getAvailableTags(file)">
											<a href="#" class="dropdown-item" @click.prevent="addTagToFile(file, tag)">{{ tag.label }}</a>
										</template>
									</div>
								</div>
							</div>
						</div>
					</data-loader>

					<template v-if="!(projectDataStore.project.editable && editable && projectDataStore.can('documents', 'update'))">
						<div v-if="file.description" class="d-flex gap-2 align-items-start">
							<i class="far fa-comment fa-fw"></i>
							<div v-html="file.description_html"></div>
						</div>
					</template>
					<TextareaAutosize
						v-model="file.description"
						:classes="{'form-control': 1}"
						:rows="1"
						placeholder="Leírás"
						@update:modelValue="$emit('valueChanged')"
						v-else
					></TextareaAutosize>
				</div>
			</div>
		</div>
	</template>
	<teleport to="body" v-if="lightboxImage">
		<div class="lightbox" @click.prevent="lightboxImage = ''">
			<div class="lightbox-image">
				<img :src="lightboxImage" class="img-responsive" />
			</div>
			<button class="btn btn-secondary btn-label" @click.prevent="lightboxImage = ''">
				<i class="far fa-times label-icon"></i> Bezár
			</button>
		</div>
	</teleport>
</template>

<script>
import FileIcon from "../../components/FileIcon.vue";
import VueUploadComponent from "vue-upload-component";
import TextareaAutosize from "../../components/TextareaAutosize.vue";
import SelectField from "../../components/form/SelectField.vue";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useProjectDataStore} from "../../stores/projectData";
import {Link, usePage} from "@inertiajs/vue3";
import FileSize from "../../components/FileSize.vue";
import DateTime from "../../components/DateTime.vue";
import Attribute from "../../components/Attribute.vue";
import {useGeneralDataStore} from "../../stores/generalData";

export default {
	emits: ['update:existingFiles', 'valueChanged'],
	components: {
		Link,
		Attribute,
		DateTime, FileSize, ColorBadge, DataLoader, SelectField, VueUploadComponent, FileIcon, TextareaAutosize},
	props: {
		editable: Boolean,
		creatingEnabled: Boolean,
		userType: {
			type: String,
			default: 'handler'
		},
		systemDocuments: {
			type: Object,
			default: function(){
				return {}
			}
		},
		errors: Object,
		documentType: Object,
	},
	computed: {
	},
	data(){
		const page = usePage()

		return {
			lightboxImage: '',
			uploadHeaders: {'X-CSRF-TOKEN': page.props.csrf_token},
			uploadingFiles: [],
			uploadCounter: 0,
			projectDataStore: useProjectDataStore(),
			dataStore: useGeneralDataStore()
		}
	},
	mounted() {
		if (this.projectDataStore.uploadedFiles[this.documentType.id] === undefined){
			this.projectDataStore.uploadedFiles[this.documentType.id] = []
		}
	},
	methods: {
		uploadInputFilter: function(newFile, oldFile, prevent){
			if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
				newFile.blob = ''
				let URL = (window.URL || window.webkitURL)
				if (URL) {
					newFile.blob = URL.createObjectURL(newFile.file)
				}
				newFile.thumb = ''
				if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
					newFile.thumb = newFile.blob
				}
			}

			if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
				newFile.error = 'image parsing'
				let img = new Image();
				img.onload = () => {
					this.$refs['upload' + this.documentType.id].update(newFile, {error: '', height: img.height, width: img.width})
				}
				img.onerror = (e) => {
					this.$refs['upload' + this.documentType.id].update(newFile, { error: 'parsing image size'})
				}
				img.src = newFile.blob
			}
		},
		uploadInputFile: function(newFile, oldFile, prevent){
			if (newFile !== undefined && newFile.success){
				this.$refs['upload' + this.documentType.id].remove(newFile)
				newFile.fileId = parseInt(newFile.response.file.id)
				delete newFile.response
				delete newFile.headers
				try {
					this.projectDataStore.uploadedFiles[this.documentType.id].push(newFile)
				} catch (e){
				}

				this.$emit('valueChanged')
			} else {
				if (newFile !== null && (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error)) {
					if (!this.$refs['upload' + this.documentType.id].active) {
						newFile.documentTypeId = this.documentType.id
						this.$refs['upload' + this.documentType.id].active = true
					}
				}
			}
		},
		getDocumentTypeCount: function(type){
			if (type === null || type === 'forms'){
				return 0
			}
			if (typeof type === 'object') {
				return this.projectDataStore.documents[this.documentType.id].length + this.projectDataStore.uploadedFiles[this.documentType.id].length
			}

			return this.getSystemDocuments(type).length
		},
		getSystemDocuments: function(type){
			if (this.systemDocuments[type] !== undefined){
				return this.systemDocuments[type]
			}

			return []
		},
		getAvailableTags: function (file){
			let payload = []
			_.forEach(this.dataStore.getData('documentTags'), function (tag){
				if (file.tags !== undefined && file.tags.length){
					if (file.tags.indexOf(tag.id) < 0){
						payload.push(tag)
					}
				} else {
					payload.push(tag)
				}
			})

			return payload
		},
		addTagToFile: function (file, tag){
			if (file.tags === undefined){
				file.tags = []
			}
			this.$emit('valueChanged')
			file.tags.push(tag.id)
		},
		removeTagFromFile: function (file, tag){
			if (file.tags.indexOf(tag) > -1){
				this.$emit('valueChanged')
				file.tags.splice(file.tags.indexOf(tag), 1)
			}
		}
	}
}
</script>

<style scoped>

</style>
