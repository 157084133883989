<template>
	<modal
		title="Árajánlat"
		:full-width="true"
		:buttons-on-top="true"
		:closable="true"
		@close="$emit('close')">

		<template #buttons>
			<template v-if="canSendAnswer">
				<button type="button" class="btn btn-success btn-label" @click.prevent="saveAnswer(true)">
					<i class="far fa-check label-icon"></i> {{ $t('Ajánlat elfogadása') }}
				</button>
				<button type="button" class="btn btn-danger btn-label" @click.prevent="saveAnswer(false)">
					<i class="far fa-ban label-icon"></i> {{ $t('Ajánlat elutasítása') }}
				</button>
			</template>
			<button type="button" class="btn-label btn btn-secondary" @click.prevent="emitClose">
				<i class="far fa-times label-icon"></i> {{ $t('Bezárás') }}
			</button>
		</template>

		<template #default>
			<div v-if="quote !== null">
				<div class="row mb-4">
					<div class="col-md-4 pb-3 align-items-center d-flex justify-content-center">
						<svg enable-background="new 0 0 150 25" version="1.1" viewBox="0 0 150 25" width="150px" height="25px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
							<path style="fill:#2A3042;" d="m37.12 0c-1.01 0-1.83 0.82-1.83 1.83 0 2.96-1.64 7.53-6.11 12-4.47-4.47-6.11-9.04-6.11-12 0-1.01-0.82-1.83-1.83-1.83s-1.83 0.82-1.83 1.83c0 4.91 2.86 10.47 7.72 15.12 0.36 0.34 0.72 0.68 1.1 1.01 0 0 0.01 0 0.01 0.01 0.54 0.46 1.35 0.46 1.88 0 0 0 0.01 0 0.01-0.01 0.38-0.33 0.74-0.67 1.1-1.01 4.86-4.65 7.72-10.21 7.72-15.12 0-1.01-0.82-1.83-1.83-1.83z"></path>
							<path style="fill:#2A3042;" d="m18.27 8.38v-0.04-0.05-0.01c-0.23-2.25-1.27-4.32-2.94-5.85-1.69-1.54-3.88-2.39-6.16-2.39-5.06 0.01-9.17 4.12-9.17 9.16 0 5.05 4.11 9.15 9.15 9.15 2.44 0 4.74-0.95 6.47-2.68 0.71-0.71 0.71-1.87 0-2.59-0.71-0.71-1.87-0.71-2.59 0-1.04 1.04-2.42 1.61-3.88 1.61-2.18 0-4.06-1.28-4.95-3.12l12.43-1.28c0.01 0 0.03 0 0.04-0.01 0.02 0 0.03 0 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.01-0.01 0.03-0.01 0.04-0.02 0.01 0 0.03-0.01 0.04-0.02s0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.02-0.01 0.04-0.02c0.01-0.01 0.03-0.02 0.04-0.02 0.01-0.01 0.02-0.02 0.04-0.02 0.01-0.01 0.03-0.02 0.04-0.02 0.01-0.01 0.02-0.02 0.03-0.02 0.01-0.01 0.03-0.02 0.04-0.03l0.03-0.03c0.01-0.01 0.02-0.02 0.04-0.03l0.03-0.03c0.01-0.01 0.02-0.02 0.04-0.03l0.09-0.09c0.01-0.01 0.02-0.02 0.03-0.04 0.01-0.01 0.02-0.02 0.02-0.03 0.01-0.01 0.02-0.03 0.03-0.04s0.01-0.02 0.02-0.03 0.02-0.03 0.03-0.04 0.01-0.02 0.02-0.03 0.02-0.03 0.03-0.04 0.01-0.02 0.02-0.03c0.01-0.02 0.02-0.03 0.03-0.05 0.01-0.01 0.01-0.02 0.02-0.03 0.01-0.02 0.02-0.03 0.03-0.05 0.01-0.01 0.01-0.02 0.02-0.03 0.01-0.02 0.02-0.03 0.02-0.05 0-0.01 0.01-0.02 0.01-0.03 0.01-0.02 0.02-0.03 0.02-0.05 0-0.01 0.01-0.02 0.01-0.03 0.01-0.02 0.01-0.03 0.02-0.05 0-0.01 0.01-0.02 0.01-0.03 0.01-0.02 0.01-0.04 0.02-0.05 0-0.01 0.01-0.02 0.01-0.03 0.01-0.02 0.01-0.04 0.01-0.05s0.01-0.02 0.01-0.03c0-0.02 0.01-0.04 0.01-0.05s0-0.02 0.01-0.04c0-0.02 0.01-0.04 0.01-0.05v-0.04c0-0.02 0-0.04 0.01-0.05v-0.04-0.05-0.04-0.05-0.04c0.02-0.01 0.02-0.03 0.02-0.05zm-14.46-0.44c0.57-2.42 2.75-4.22 5.34-4.22 2.2 0 4.1 1.27 4.98 3.16l-10.32 1.06z"></path>
							<path style="fill:#2A3042;" d="m58.58 8.38v-0.06-0.03-0.01c-0.23-2.24-1.27-4.32-2.94-5.84-1.69-1.54-3.88-2.39-6.16-2.39-5.05 0-9.15 4.11-9.15 9.15 0 5.05 4.11 9.15 9.15 9.15 2.44 0 4.74-0.95 6.47-2.68 0.71-0.71 0.71-1.87 0-2.59-0.71-0.71-1.87-0.71-2.59 0-1.04 1.04-2.42 1.61-3.88 1.61-2.18 0-4.06-1.28-4.95-3.12l12.43-1.28c0.01 0 0.03 0 0.04-0.01 0.02 0 0.03 0 0.05-0.01 0.02 0 0.03-0.01 0.05-0.01s0.03-0.01 0.05-0.01c0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.01-0.01 0.03-0.01 0.04-0.02s0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.02 0.04-0.02c0.01-0.01 0.03-0.01 0.04-0.02s0.03-0.02 0.04-0.02c0.01-0.01 0.02-0.02 0.04-0.02 0.01-0.01 0.02-0.02 0.04-0.03 0.01-0.01 0.02-0.02 0.04-0.03l0.31-0.31c0.01-0.01 0.02-0.02 0.03-0.04 0.01-0.01 0.02-0.02 0.02-0.03 0.01-0.01 0.02-0.02 0.03-0.04 0.01-0.01 0.01-0.02 0.02-0.04 0.01-0.01 0.02-0.03 0.02-0.04 0.01-0.01 0.01-0.02 0.02-0.04 0.01-0.01 0.02-0.03 0.02-0.04 0.01-0.01 0.01-0.02 0.02-0.04 0.01-0.01 0.01-0.03 0.02-0.04s0.01-0.03 0.02-0.04 0.01-0.03 0.02-0.04 0.01-0.03 0.02-0.04 0.01-0.03 0.02-0.04 0.01-0.03 0.01-0.04 0.01-0.03 0.01-0.04 0.01-0.03 0.01-0.04 0.01-0.03 0.01-0.04c0-0.02 0.01-0.03 0.01-0.05 0-0.01 0.01-0.03 0.01-0.04 0-0.02 0.01-0.03 0.01-0.05 0-0.01 0-0.03 0.01-0.04 0-0.02 0-0.03 0.01-0.05v-0.04-0.05-0.04-0.06-0.03-0.06c0.01-0.03 0.01-0.04 0.01-0.05zm-14.45-0.44c0.57-2.42 2.75-4.22 5.34-4.22 2.2 0 4.1 1.27 4.98 3.16l-10.32 1.06z"></path>
							<path style="fill:#2A3042;" d="m80.64 0.05c-5.06 0-9.18 4.12-9.18 9.18s4.12 9.18 9.18 9.18c1.71 0 3.31-0.47 4.68-1.29l-0.36 3.23-9.64 1c-1.01 0.1-1.74 1-1.63 2.01 0.09 0.94 0.88 1.64 1.81 1.64 0.06 0 0.13 0 0.19-0.01l11.13-1.15c0.96-0.1 1.67-0.92 1.64-1.88l1.34-11.9c0.01-0.06 0.01-0.13 0.01-0.19 0-0.04 0-0.09-0.01-0.13 0.01-0.17 0.02-0.34 0.02-0.52 0-5.05-4.12-9.17-9.18-9.17zm0 14.7c-3.04 0-5.52-2.48-5.52-5.52s2.48-5.52 5.52-5.52 5.52 2.48 5.52 5.52-2.48 5.52-5.52 5.52z"></path>
							<path style="fill:#2A3042;" d="m101.22 0.05c-5.06 0-9.18 4.12-9.18 9.18s4.12 9.18 9.18 9.18 9.18-4.12 9.18-9.18-4.12-9.18-9.18-9.18zm0 14.7c-3.04 0-5.52-2.48-5.52-5.52s2.48-5.52 5.52-5.52 5.52 2.48 5.52 5.52c-0.01 3.04-2.48 5.52-5.52 5.52z"></path>
							<path style="fill:#2A3042;" d="m69.72 0.53c-5.06 0-9.18 4.12-9.18 9.18v0.11 0.11 6.14c0 1.01 0.82 1.83 1.83 1.83s1.83-0.82 1.83-1.83v-6.14-0.11-0.11c0-3.04 2.48-5.52 5.52-5.52 1.01 0 1.83-0.82 1.83-1.83s-0.82-1.83-1.83-1.83z"></path>
							<path style="fill:#2A3042;" d="m112.99 0.87h-0.37c-0.01 0-0.02 0.01-0.02 0.02v1.49c0 0.15 0.03 0.25 0.08 0.31s0.13 0.08 0.24 0.08h0.05c0.03 0 0.05 0.02 0.05 0.05v0.21c0 0.03-0.02 0.05-0.05 0.05h-0.12c-0.19 0-0.34-0.04-0.43-0.12-0.1-0.08-0.15-0.24-0.15-0.47v-1.6c0-0.01-0.01-0.02-0.02-0.02h-0.19c-0.03 0-0.05-0.02-0.05-0.05v-0.16c0-0.03 0.02-0.05 0.05-0.05h0.19c0.01 0 0.02-0.01 0.02-0.02v-0.54c0-0.03 0.02-0.05 0.05-0.05h0.24c0.03 0 0.05 0.02 0.05 0.05v0.54c0 0.01 0.01 0.02 0.02 0.02h0.37c0.03 0 0.05 0.02 0.05 0.05v0.16c-0.02 0.03-0.03 0.05-0.06 0.05z"></path>
							<path style="fill:#2A3042;" d="m115.88 0.79c0.11 0.14 0.16 0.34 0.16 0.59v1.64c0 0.03-0.02 0.05-0.05 0.05h-0.24c-0.03 0-0.05-0.02-0.05-0.05v-1.61c0-0.17-0.03-0.3-0.1-0.39-0.07-0.1-0.16-0.14-0.27-0.14-0.12 0-0.22 0.05-0.29 0.14s-0.11 0.22-0.11 0.38v1.62c0 0.03-0.02 0.05-0.05 0.05h-0.25c-0.03 0-0.05-0.02-0.05-0.05v-1.61c0-0.17-0.04-0.3-0.1-0.39-0.07-0.1-0.16-0.14-0.27-0.14-0.12 0-0.22 0.05-0.29 0.14s-0.11 0.22-0.11 0.38v1.62c0 0.03-0.02 0.05-0.05 0.05h-0.24c-0.03 0-0.05-0.02-0.05-0.05v-2.36c0-0.03 0.02-0.05 0.05-0.05h0.24c0.03 0 0.05 0.02 0.05 0.05v0.15c0 0.01 0 0.01 0.01 0.01 0 0 0.01 0 0.02-0.01 0.06-0.08 0.13-0.14 0.22-0.18s0.17-0.06 0.27-0.06c0.12 0 0.23 0.03 0.32 0.09s0.16 0.14 0.21 0.25c0 0.01 0.01 0.01 0.01 0.01 0.01 0 0.01 0 0.01-0.01 0.06-0.12 0.14-0.2 0.24-0.26s0.21-0.08 0.32-0.08c0.19 0.01 0.34 0.08 0.44 0.22z"></path>
							<path style="fill:#7288A2;" d="m122.1 12.32c0.33-0.6 0.78-1.07 1.36-1.4s1.24-0.5 1.99-0.5c0.96 0 1.76 0.24 2.39 0.72s1.05 1.16 1.27 2.02h-2.11c-0.11-0.34-0.3-0.6-0.57-0.79s-0.6-0.29-0.99-0.29c-0.56 0-1 0.2-1.33 0.61s-0.49 0.98-0.49 1.73c0 0.74 0.16 1.31 0.49 1.71 0.33 0.41 0.77 0.61 1.33 0.61 0.79 0 1.31-0.35 1.55-1.06h2.11c-0.21 0.84-0.64 1.51-1.27 2-0.63 0.5-1.43 0.74-2.38 0.74-0.75 0-1.41-0.17-1.99-0.5s-1.03-0.8-1.36-1.4-0.49-1.3-0.49-2.11 0.16-1.48 0.49-2.09z"></path>
							<path style="fill:#7288A2;" d="m133.49 10.79c0.41-0.23 0.87-0.35 1.39-0.35v2.06h-0.52c-0.62 0-1.08 0.15-1.39 0.43-0.31 0.29-0.47 0.79-0.47 1.51v3.86h-1.96v-7.76h1.96v1.2c0.25-0.39 0.58-0.72 0.99-0.95z"></path>
							<path style="fill:#7288A2;" d="m148.13 11.32c0.58 0.58 0.88 1.4 0.88 2.44v4.55h-1.96v-4.28c0-0.61-0.15-1.07-0.46-1.39s-0.73-0.48-1.26-0.48-0.95 0.16-1.27 0.48c-0.31 0.32-0.47 0.79-0.47 1.39v4.28h-1.96v-4.28c0-0.61-0.15-1.07-0.46-1.39s-0.73-0.48-1.26-0.48c-0.54 0-0.97 0.16-1.28 0.48s-0.47 0.79-0.47 1.39v4.28h-1.96v-7.76h1.96v0.94c0.25-0.33 0.58-0.58 0.97-0.77 0.4-0.19 0.83-0.28 1.31-0.28 0.61 0 1.15 0.13 1.62 0.39s0.84 0.62 1.11 1.1c0.25-0.45 0.62-0.81 1.1-1.08s1-0.41 1.56-0.41c0.95 0 1.72 0.29 2.3 0.88z"></path>
						</svg>
					</div>
					<div class="col-md-4 pb-3">
						<div class="card mb-0">
							<div class="card-body">
								<h4 class="card-title mb-2">{{ $t('Ajánlatot készítette') }}</h4>
								<div class="d-flex flex-column gap-1 align-items-start">
									<div>{{ app.companyName }} - {{ quote.creator.name }}</div>
									<div>{{ app.companyAddress }}</div>
									<PhoneNumber :value="quote.creator.phone"></PhoneNumber>
									<EmailAddress :value="quote.creator.email"/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4 pb-3">
						<div class="card mb-0">
							<div class="card-body">
								<h4 class="card-title mb-2">{{ $t('Ajánlatot kérte') }}</h4>
								<div class="d-flex flex-column gap-1 align-items-start">
									<div>{{ projectDataStore.client.name }}</div>
									<div>{{ projectDataStore.client.address_text }}</div>
									<PhoneNumber :value="projectDataStore.client.phone"></PhoneNumber><br />
									<EmailAddress :value="projectDataStore.client.email"/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card shadow-sm mb-3">
					<div class="card-body">
						<h2>{{ $t(':title - árajánlat', {'title': quote.title_formatted}) }}
							<span :style="'color: ' + projectDataStore.projectType.color" v-if="quoteAttributes.powerAmount">{{ quoteAttributes.powerAmount }} {{ quoteAttributes.powerUnit }}</span> {{ $t('névleges teljesítmény') }}
						</h2>
						{{ quote.public_id }}
					</div>
				</div>
				<div v-if="quote.status === 'accepted'" class="alert alert-success">
					<h4 class="alert-heading"><i class="far fa-check-circle"></i> {{ $t('Ajánlat elfogadva') }}</h4>
					<i class="far fa-clock"></i> <date-time :value="quote.answered_at"/>
				</div>
				<div v-else-if="quote.status === 'rejected'" class="alert alert-danger">
					<h4 class="alert-heading"><i class="far fa-times-circle"></i> {{ $t('Ajánlat elutasítva') }}</h4>
					<i class="far fa-clock"></i> <date-time :value="quote.answered_at"/>
				</div>
				<div v-else-if="!quote.is_valid" class="alert alert-danger">
					<h4 class="alert-heading"><i class="far fa-times"></i> {{ $t('Ajánlat lejárt') }}</h4>
					<i class="far fa-clock"></i> <date-time :value="quote.valid_until"/>
				</div>
				<div class="mb-3" v-if="quoteAttributes.materialProducts.length">
					<div class="card">
						<div class="card-body">
							<h4 class="card-title d-md-none mb-3">{{ $t('Anyag jellegü tételek') }}</h4>
							<table class="table table-collapse-mobile">
								<thead>
								<tr>
									<th>{{ $t('Anyag jellegü tételek') }}</th>
									<th class="text-end" width="9%">{{ $t('Mennyiség') }}</th>
									<th class="text-end" width="14%">{{ $t('Nettó egységár') }}</th>
									<th class="text-end" width="11%">{{ $t('Nettó ár') }}</th>
									<th class="text-end" width="11%">{{ $t('ÁFA') }}</th>
									<th class="text-end" width="14%">{{ $t('Teljes bruttó ár') }}</th>
								</tr>
								</thead>
								<tbody>
									<template v-for="(quoteProduct, quoteProductIndex) in quoteAttributes.materialProducts">
										<tr class="tag-row border-top border-secondary" v-if="quoteProductIndex > 0
										&& quoteAttributes.materialProducts[quoteProductIndex - 1].tag_id !== quoteProduct.tag_id
										&& quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteProductIndex - 1].tag_id] !== undefined">
											<td class="tag-name" colspan="3">
												{{ quoteAttributes.tags[quoteAttributes.materialProducts[quoteProductIndex - 1].tag_id] }}
											</td>
											<td class="text-end" :data-label="$t('Nettó egységár')">
												<Price :value="quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteProductIndex - 1].tag_id].finalNet" />
											</td>
											<td class="text-end" :data-label="$t('ÁFA')">
												<Price :value="quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteProductIndex - 1].tag_id].finalGross - quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteProductIndex - 1].tag_id].finalNet" />
											</td>
											<td class="text-end" :data-label="$t('Teljes bruttó ár')">
												<Price :value="quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteProductIndex - 1].tag_id].finalGross" />
											</td>
										</tr>
										<tr>
											<td :class="{'product-name': 1, 'divider': displayTopBorder(quoteAttributes.materialProducts, quoteProductIndex)}" :data-label="$t('Termék')">
												<div class="text-sm me-2"><small>{{ quoteProduct.product.product_category.name_public ? quoteProduct.product.product_category.name_public : quoteProduct.product.product_category.name }}</small></div>
												{{ quoteProduct.product.name }}
											</td>
											<td :class="{'product-name': 1, 'text-end': 1, 'divider': displayTopBorder(quoteAttributes.materialProducts, quoteProductIndex)}" :data-label="$t('Mennyiség')">{{ quoteProduct.quantity }} {{ quoteProduct.product.unit }}</td>
											<td :class="{'product-name': 1, 'text-end': 1, 'divider': displayTopBorder(quoteAttributes.materialProducts, quoteProductIndex)}" :data-label="$t('Nettó egységár')">
												<Price :value="quoteProduct.product.calculated_price.finalNet" />
											</td>
											<td :class="{'product-name': 1, 'text-end': 1, 'divider': displayTopBorder(quoteAttributes.materialProducts, quoteProductIndex)}" :data-label="$t('Nettó ár')">
												<Price :value="quoteProduct.product.calculated_price.finalNet * quoteProduct.quantity" />
											</td>
											<td :class="{'product-name': 1, 'text-end': 1, 'divider': displayTopBorder(quoteAttributes.materialProducts, quoteProductIndex)}" :data-label="$t('ÁFA')">
												<Price :value="(quoteProduct.product.calculated_price.finalGross - quoteProduct.product.calculated_price.finalNet) * quoteProduct.quantity" />
											</td>
											<td :class="{'product-name': 1, 'text-end': 1, 'divider': displayTopBorder(quoteAttributes.materialProducts, quoteProductIndex)}" :data-label="$t('Teljes bruttó ár')">
												<Price :value="quoteProduct.product.calculated_price.finalGross * quoteProduct.quantity" />
											</td>
										</tr>
									</template>

									<tr class="tag-row border-top border-secondary" v-if="quoteAttributes.materialProducts.length > 1
										&& quoteAttributes.materialProducts[quoteAttributes.materialProducts.length - 1].tag_id
										&& quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteAttributes.materialProducts.length - 1].tag_id] !== undefined">
										<td class="tag-name" colspan="3">
											{{ quoteAttributes.tags[quoteAttributes.materialProducts[quoteAttributes.materialProducts.length - 1].tag_id] }}
										</td>
										<td class="text-end" :data-label="$t('Nettó egységár')">
											<Price :value="quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteAttributes.materialProducts.length - 1].tag_id].finalNet" />
										</td>
										<td class="text-end" :data-label="$t('ÁFA')">
											<Price :value="quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteAttributes.materialProducts.length - 1].tag_id].finalGross - quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteAttributes.materialProducts.length - 1].tag_id].finalNet" />
										</td>
										<td class="text-end" :data-label="$t('Teljes bruttó ár')">
											<Price :value="quoteAttributes.materialPrice['tags'][quoteAttributes.materialProducts[quoteAttributes.materialProducts.length - 1].tag_id].finalGross" />
										</td>
									</tr>
								</tbody>
								<tfoot>
								<tr>
									<td colspan="3" class="text-end fw-bold">{{ $t('Anyag jellegü tételek összesen') }}</td>
									<td class="text-end" :data-label="$t('Nettó ár')">
										<Price :value="quoteAttributes.materialPrice.finalNet" />
									</td>
									<td class="text-end" :data-label="$t('ÁFA')">
										<Price :value="quoteAttributes.materialPrice.finalGross - quoteAttributes.materialPrice.finalNet" />
									</td>
									<td class="text-end fw-bold" :data-label="$t('eljes bruttó ár')">
										<Price :value="quoteAttributes.materialPrice.finalGross" />
									</td>
								</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>

				<div class="mb-3" v-if="quoteAttributes.workProducts.length">
					<div class="card">
						<div class="card-body">
							<table class="table table-collapse-mobile">
								<thead>
								<tr>
									<th>{{ $t('Munka jellegü tételek') }}</th>
									<th class="text-end" width="11%">{{ $t('Nettó ár') }}</th>
									<th class="text-end" width="11%">{{ $t('ÁFA') }}</th>
									<th class="text-end" width="14%">{{ $t('Teljes bruttó ár') }}</th>
								</tr>
								</thead>
								<tbody>
									<template v-for="(quoteProduct, quoteProductIndex) in quoteAttributes.workProducts">
										<tr class="tag-row" v-if="quoteProductIndex > 0
											&& quoteAttributes.workProducts[quoteProductIndex - 1].tag_id !== quoteProduct.tag_id
											&& quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteProductIndex - 1].tag_id] !== undefined">
											<td class="tag-name">
												{{ quoteAttributes.tags[quoteAttributes.workProducts[quoteProductIndex - 1].tag_id] }}
											</td>
											<td class="text-end" :data-label="$t('Nettó')">
												<Price :value="quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteProductIndex - 1].tag_id].finalNet" />
											</td>
											<td class="text-end" :data-label="$t('ÁFA')">
												<Price :value="quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteProductIndex - 1].tag_id].finalGross - quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteProductIndex - 1].tag_id].finalNet" />
											</td>
											<td class="text-end" :data-label="$t('Bruttó')">
												<Price :value="quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteProductIndex - 1].tag_id].finalGross" />
											</td>
										</tr>
										<tr>
											<td class="product-name" :data-label="$t('Termék')">
												{{ quoteProduct.product.name }}
											</td>
											<td class="text-end" :data-label="$t('Nettó ár')">
												<Price :value="quoteProduct.product.calculated_price.finalNet * quoteProduct.quantity" />
											</td>
											<td class="text-end" :data-label="$t('ÁFA')">
												<Price :value="(quoteProduct.product.calculated_price.finalGross - quoteProduct.product.calculated_price.finalNet) * quoteProduct.quantity" />
											</td>
											<td class="text-end" :data-label="$t('Teljes bruttó ár')">
												<Price :value="quoteProduct.product.calculated_price.finalGross * quoteProduct.quantity" />
											</td>
										</tr>
									</template>

									<tr class="tag-row" v-if="quoteAttributes.workProducts.length > 1
										&& quoteAttributes.workProducts[quoteAttributes.workProducts.length - 1].tag_id
										&& quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteAttributes.workProducts.length - 1].tag_id] !== undefined">
										<td class="tag-name" colspan="3">
											{{ quoteAttributes.tags[quoteAttributes.workProducts[quoteAttributes.workProducts.length - 1].tag_id] }}
										</td>
										<td class="text-end" :data-label="$t('Nettó')">
											<Price :value="quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteAttributes.workProducts.length - 1].tag_id].finalNet" />
										</td>
										<td class="text-end" :data-label="$t('ÁFA')">
											<Price :value="quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteAttributes.workProducts.length - 1].tag_id].finalGross - quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteAttributes.workProducts.length - 1].tag_id].finalNet" />
										</td>
										<td class="text-end" :data-label="$t('Bruttó')">
											<Price :value="quoteAttributes.workPrice['tags'][quoteAttributes.workProducts[quoteAttributes.workProducts.length - 1].tag_id].finalGross" />
										</td>
									</tr>
								</tbody>
								<tfoot>
								<tr>
									<td class="text-end fw-bold">{{ $t('Munka jellegü tételek összesen') }}</td>
									<td class="text-end" :data-label="$t('Nettó ár')">
										<Price :value="quoteAttributes.workPrice.finalNet" />
									</td>
									<td class="text-end" :data-label="$t('ÁFA')">
										<Price :value="quoteAttributes.workPrice.finalGross - quoteAttributes.workPrice.finalNet" />
									</td>
									<td class="text-end fw-bold" :data-label="$t('Teljes bruttó ár')">
										<Price :value="quoteAttributes.workPrice.finalGross" />
									</td>
								</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>

				<div class="mb-3" v-if="quoteAttributes.serviceProducts.length">
					<div class="card">
						<div class="card-body">
							<table class="table table-collapse-mobile">
								<thead>
								<tr>
									<th>{{ $t('Szolgáltatás jellegü tételek') }}</th>
									<th class="text-end" width="11%">{{ $t('Nettó ár') }}</th>
									<th class="text-end" width="11%">{{ $t('ÁFA') }}</th>
									<th class="text-end" width="14%">{{ $t('Teljes bruttó ár') }}</th>
								</tr>
								</thead>
								<tbody>
								<template v-for="(quoteProduct, quoteProductIndex) in quoteAttributes.serviceProducts">
									<tr class="tag-row" v-if="quoteProductIndex > 0
											&& quoteAttributes.serviceProducts[quoteProductIndex - 1].tag_id !== quoteProduct.tag_id
											&& quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteProductIndex - 1].tag_id] !== undefined">
										<td class="tag-name">
											{{ quoteAttributes.tags[quoteAttributes.serviceProducts[quoteProductIndex - 1].tag_id] }}
										</td>
										<td class="text-end" :data-label="$t('Nettó')">
											<Price :value="quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteProductIndex - 1].tag_id].finalNet" />
										</td>
										<td class="text-end" :data-label="$t('ÁFA')">
											<Price :value="quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteProductIndex - 1].tag_id].finalGross - quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteProductIndex - 1].tag_id].finalNet" />
										</td>
										<td class="text-end" :data-label="$t('Bruttó')">
											<Price :value="quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteProductIndex - 1].tag_id].finalGross" />
										</td>
									</tr>
									<tr>
										<td class="product-name" :data-label="$t('Termék')">
											{{ quoteProduct.product.name }}
										</td>
										<td class="text-end" :data-label="$t('Nettó ár')">
											<Price :value="quoteProduct.product.calculated_price.finalNet * quoteProduct.quantity" />
										</td>
										<td class="text-end" :data-label="$t('ÁFA')">
											<Price :value="(quoteProduct.product.calculated_price.finalGross - quoteProduct.product.calculated_price.finalNet) * quoteProduct.quantity" />
										</td>
										<td class="text-end" :data-label="$t('Teljes bruttó ár')">
											<Price :value="quoteProduct.product.calculated_price.finalGross * quoteProduct.quantity" />
										</td>
									</tr>
								</template>

								<tr class="tag-row" v-if="quoteAttributes.serviceProducts.length > 1
										&& quoteAttributes.serviceProducts[quoteAttributes.serviceProducts.length - 1].tag_id
										&& quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteAttributes.serviceProducts.length - 1].tag_id] !== undefined">
									<td class="tag-name" colspan="3">
										{{ quoteAttributes.tags[quoteAttributes.serviceProducts[quoteAttributes.serviceProducts.length - 1].tag_id] }}
									</td>
									<td class="text-end" :data-label="$t('Nettó')">
										<Price :value="quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteAttributes.serviceProducts.length - 1].tag_id].finalNet" />
									</td>
									<td class="text-end" :data-label="$t('ÁFA')">
										<Price :value="quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteAttributes.serviceProducts.length - 1].tag_id].finalGross - quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteAttributes.serviceProducts.length - 1].tag_id].finalNet" />
									</td>
									<td class="text-end" :data-label="$t('Bruttó')">
										<Price :value="quoteAttributes.servicePrice['tags'][quoteAttributes.serviceProducts[quoteAttributes.serviceProducts.length - 1].tag_id].finalGross" />
									</td>
								</tr>
								</tbody>
								<tfoot>
								<tr>
									<td class="text-end fw-bold">{{ $t('Szolgáltatás jellegü tételek összesen') }}</td>
									<td class="text-end" :data-label="$t('Nettó ár')">
										<Price :value="quoteAttributes.servicePrice.finalNet" />
									</td>
									<td class="text-end" :data-label="$t('ÁFA')">
										<Price :value="quoteAttributes.servicePrice.finalGross - quoteAttributes.servicePrice.finalNet" />
									</td>
									<td class="text-end fw-bold" :data-label="$t('Teljes bruttó ár')">
										<Price :value="quoteAttributes.servicePrice.finalGross" />
									</td>
								</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<div class="card" v-if="quoteAttributes.tags !== undefined && quoteAttributes.tags.length">
					<div class="card-body">
						<table class="table m-0">
							<thead>
							<tr>
								<th>Cimke</th>
								<th width="15%">Arány</th>
								<th width="15%" class="text-end">Nettó</th>
								<th width="15%" class="text-end">ÁFA</th>
								<th width="15%" class="text-end">Bruttó</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(stat) in quoteAttributes.tags">
								<td>
									{{ tags[stat.tag_id].label }}
								</td>
								<td>
									<number :value="stat.percent" suffix="%"/>
								</td>
								<td class="text-end">
									<Price
										:value="stat.net"
										:currencies="currencies"
									/>
								</td>
								<td class="text-end">
									<Price
										:value="stat.gross - stat.net"
										:currencies="currencies"
									/>
								</td>
								<td class="text-end">
									<Price
										:value="stat.gross"
										:currencies="currencies"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="mb-3">
					<div class="card shadow-sm">
						<div class="card-body">
							<table class="table table-borderless font-size-14 table-collapse-mobile mb-0">
								<tbody>
								<tr v-if="quoteAttributes.materialPrice.discountNet < quoteAttributes.materialPrice.net">
									<th width="64%" class="label">{{ $t('Kedvezmény az anyag jellegű tételekből') }}</th>
									<td width="11%" class="text-end" :data-label="$t('Nettó ár')">
										<Price :value="quoteAttributes.materialPrice.net - quoteAttributes.materialPrice.discountNet" />
									</td>
									<td width="11%" class="text-end" :data-label="$t('ÁFA')">
										<Price :value="
										(quoteAttributes.materialPrice.gross - quoteAttributes.materialPrice.discountGross)
										- (quoteAttributes.materialPrice.net - quoteAttributes.materialPrice.discountNet)" />
									</td>
									<td width="14%" class="text-end fw-bold" :data-label="$t('Teljes bruttó ár')">
										<Price :value="quoteAttributes.materialPrice.gross - quoteAttributes.materialPrice.discountGross" />
									</td>
								</tr>
								<tr v-if="quoteAttributes.workPrice.discountNet < quoteAttributes.workPrice.net">
									<th width="64%" class="label">{{ $t('Kedvezmény a munka jellegű tételekből') }}</th>
									<td width="11%" class="text-end" :data-label="$t('Nettó ár')">
										<Price :value="quoteAttributes.workPrice.net - quoteAttributes.workPrice.discountNet" />
									</td>
									<td width="11%" class="text-end" :data-label="$t('ÁFA')">
										<Price :value="
										(quoteAttributes.workPrice.gross - quoteAttributes.workPrice.discountGross)
										- (quoteAttributes.workPrice.net - quoteAttributes.workPrice.discountNet)" />
									</td>
									<td width="14%" class="text-end fw-bold" :data-label="$t('Teljes bruttó ár')">
										<Price :value="quoteAttributes.workPrice.gross - quoteAttributes.workPrice.discountGross" />
									</td>
								</tr>
								<tr v-if="quoteAttributes.servicePrice.discountNet < quoteAttributes.servicePrice.net">
									<th width="64%" class="label">{{ $t('Kedvezmény a szolgáltatás jellegű tételekből') }}</th>
									<td width="11%" class="text-end" :data-label="$t('Nettó ár')">
										<Price :value="quoteAttributes.servicePrice.net - quoteAttributes.servicePrice.discountNet" />
									</td>
									<td width="11%" class="text-end" :data-label="$t('ÁFA')">
										<Price :value="
										(quoteAttributes.servicePrice.gross - quoteAttributes.servicePrice.discountGross)
										- (quoteAttributes.servicePrice.net - quoteAttributes.servicePrice.discountNet)" />
									</td>
									<td width="14%" class="text-end fw-bold" :data-label="$t('Teljes bruttó ár')">
										<Price :value="quoteAttributes.servicePrice.gross - quoteAttributes.servicePrice.discountGross" />
									</td>
								</tr>
								<tr>
									<th width="64%" class="label">{{ $t('A teljes rendszer összesen') }}</th>
									<td width="11%" class="text-end" :data-label="$t('Nettó ár')">
										<Price :value="
										quoteAttributes.materialPrice.finalNet + quoteAttributes.servicePrice.finalNet + quoteAttributes.workPrice.finalNet + this.optionalPrices.finalNet" />
									</td>
									<td width="11%" class="text-end" :data-label="$t('ÁFA')">
										<Price :value="
										(quoteAttributes.materialPrice.finalGross + quoteAttributes.servicePrice.finalGross + quoteAttributes.workPrice.finalGross + this.optionalPrices.finalGross) -
										(quoteAttributes.materialPrice.finalNet + quoteAttributes.servicePrice.finalNet + quoteAttributes.workPrice.finalNet + this.optionalPrices.finalNet)" />
									</td>
									<td width="14%" :data-label="$t('Teljes bruttó ár')" :class="'text-end fw-bold ' + (projectDataStore.projectType.luminosity === 'dark' ? 'text-white' : '')" :style="'background:' + projectDataStore.projectType.color">
										<Price :value="
										quoteAttributes.materialPrice.finalGross + quoteAttributes.servicePrice.finalGross + quoteAttributes.workPrice.finalGross + this.optionalPrices.finalGross" />
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="card shadow-sm" id="paymentPlanRadio">
						<div class="card-body">
							<h4 class="card-title required mb-3">{{ $t('Fizetési konstrukció') }}</h4>
							<p>{{ $t('Kérjük válasszon fizetési konstrukciót!') }}</p>
							<div v-if="canSendAnswer" class="d-flex gap-2 flex-column align-items-start">
								<RadioField
										:values="paymentPlansRadioValue"
										name="project_type_payment_plan_id"
										v-model="answer.project_type_payment_plan_id"
										value-type="integer"
										:field-only="true"
										:hide-non-active="!canSendAnswer"
								/>
								<div class="alert alert-danger p-2 mb-0" v-if="formValidated && !answer.project_type_payment_plan_id">{{ $t('Kérem válasszon fizetési konstrukciót') }}</div>
							</div>
							<h5 v-else>{{ answer.project_type_payment_plan_id ? paymentPlansById[answer.project_type_payment_plan_id].name : '' }}</h5>
							<p class="text-muted mt-2" v-if="answer.project_type_payment_plan_id && paymentPlansById[answer.project_type_payment_plan_id].description" v-html="paymentPlansById[answer.project_type_payment_plan_id].description_html"></p>
						</div>
					</div>
				</div>
				<div class="mb-3" v-if="quoteAttributes.optionalProducts.length">
					<div class="card">
						<div class="card-body">
							<h4 class="card-title required mb-3">{{ $t('Opcionális termékek és szolgáltatások') }}</h4>
							<p>{{ $t('Kérjük válasszon opcionális terméket!') }}</p>
							<table class="table table-collapse-mobile">
								<thead>
								<tr>
									<th>{{ $t('Tétel') }}</th>
									<th class="text-end" width="11%">{{ $t('Mennyiség') }}</th>
									<th class="text-end" width="14%">{{ $t('Nettó egységár') }}</th>
									<th class="text-end" width="11%">{{ $t('Nettó ár') }}</th>
									<th class="text-end" width="11%">{{ $t('ÁFA') }}</th>
									<th class="text-end" width="14%">{{ $t('Teljes bruttó ár') }}</th>
								</tr>
								</thead>
								<tbody>
									<template v-for="quoteProduct in quoteAttributes.optionalProducts">
										<tr :id="'optionalProductRow_' + quoteProduct.id">
											<td class="product-name" :data-label="$t('Tétel')">
												{{ quoteProduct.product.name }}
											</td>
											<td class="text-end" :data-label="$t('Mennyiség')">{{ quoteProduct.quantity }} {{ quoteProduct.product.unit }}</td>
											<td class="text-end" :data-label="$t('Nettó egységár')">
												<Price :value="quoteProduct.product.calculated_price.finalNet" />
											</td>
											<td class="text-end" :data-label="$t('Nettó ár')">
												<Price :value="quoteProduct.product.calculated_price.finalNet * quoteProduct.quantity" />
											</td>
											<td class="text-end" :data-label="$t('ÁFA')">
												<Price :value="(quoteProduct.product.calculated_price.finalGross - quoteProduct.product.calculated_price.finalNet) * quoteProduct.quantity" />
											</td>
											<td class="text-end" :data-label="$t('Teljes bruttó ár')">
												<Price :value="quoteProduct.product.calculated_price.finalGross * quoteProduct.quantity" />
											</td>
										</tr>
										<tr>
											<td class="optional" colspan="6">
												<div :class="{'radio-button-invalid': formValidated && answer.optionalProducts[quoteProduct.id] === null, 'd-flex': 1, 'gap-2': 1}">
													<RadioField
														:values="optionalProductRadioFieldValues"
														:name="'pr_' + quoteProduct.id"
														v-model="answer.optionalProducts[quoteProduct.id]"
														value-type="boolean"
														:field-only="true"
														@input="calculateOptional"
														:hide-non-active="!canSendAnswer"
													/>
													<div class="alert alert-danger p-2 mb-0" v-if="formValidated && answer.optionalProducts[quoteProduct.id] === null">{{ $t('Kérem válassza ki, hogy kéri-e az opcionális terméket!') }}</div>
												</div>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h4 class="card-title mb-3">{{ $t('Ajánlat paraméterei') }}</h4>
						<div class="row">
							<div class="col-md-6">
								<table class="table attribute-table-collapse-mobile">
									<tbody>
										<tr v-for="attribute in quote.quote_attributes">
											<th>{{ attribute['label'] }}</th>
											<td>{{ attribute['value'] }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="col-md-6">
								<table class="table attribute-table-collapse-mobile">
									<tbody>
										<tr>
											<th>{{ $t('Ajánlat kelte:') }}</th>
											<td><date-time :value="quote.approved_at"/></td>
										</tr>
										<template v-for="currency in currencies">
											<template v-if="quote.currency_values['display_' + currency.id] !== undefined">
												<tr>
													<th>{{ $t('Ajánlatkor érvényes MNB :currency árfolyam:', {currency: currency.name}) }}</th>
													<td><Price :value="quote.currency_values['display_' + currency.id]" :decimals="2" /></td>
												</tr>
											</template>
										</template>
										<tr>
											<th>{{ $t('Ajánlat érvényessége:') }}</th>
											<td>{{ $t(':days nap', {days: projectDataStore.projectType.quote_expiration}) }}, <date-time :value="quote.valid_until"/>{{ $t('-ig') }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-3 card card-body" v-if="quote.description">
					<div v-html="quote.description_html"></div>
				</div>
				<div class="mb-3 card card-body text-sm" v-if="templateContent">
					<div v-html="templateContent"></div>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import PhoneNumber from "../../../components/PhoneNumber.vue";
import Price from "../../../components/Price.vue";
import RadioField from "../../../components/form/RadioField.vue";
import vSelect from 'vue-select'
import {useFlashStoreStore} from "../../../stores/flashStore";
import {useProjectDataStore} from "../../../stores/projectData";
import DateTime from "../../../components/DateTime.vue";
import {useApplicationStore} from "../../../stores/applicationStore";
import Modal from "../../../components/Modal.vue";
import EmailAddress from "../../../components/EmailAddress.vue";
import Number from "../../../components/Number.vue";

export default {
	emits: ['close', 'closeAndReload'],
	components: {Number, EmailAddress, Modal, DateTime, Price, PhoneNumber, RadioField, vSelect},
	props: {
		quoteId: Number,
		currencies: Object,
		companyName: String,
		companyAddress: String,
	},
	data(){
		let defaultOptionalPrices = {
			net: 0,
			gross: 0,
			discountNet: 0,
			discountGross: 0,
			finalNet: 0,
			finalGross: 0
		}

		return {
			paymentPlans: [],
			quote: null,
			quoteAttributes: null,
			templateContent: null,
			projectDataStore: useProjectDataStore(),
			flashStore: useFlashStoreStore(),
			app: useApplicationStore(),
			answer: {
				status: 'accepted',
				optionalProducts: {},
				project_type_payment_plan_id: null
			},
			defaultOptionalPrices: defaultOptionalPrices,
			formValidated: false,
			optionalPrices: defaultOptionalPrices,
			optionalProductRadioFieldValues: {
				yes: {
					label: this.$t('Kérem'),
					icon: 'far fa-check',
					color: 'success'
				},
				no: {
					label: this.$t('Nem kérem'),
					icon: 'far fa-times',
					color: 'danger'
				}
			}
		}
	},
	computed: {
		canSendAnswer: {
			get(){
				if (this.quote === null || !this.quote.is_valid){
					return false
				}
				if (this.quote.status === 'accepted' || this.quote.status === 'rejected'){
					return false
				}
				return true
			}
		},
		paymentPlansById: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		},
		paymentPlansRadioValue: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		getData: function() {
			axios.get(route('clientArea.projects.quote', this.projectDataStore.projectId) + '/' + this.quoteId).then((response)=>{
				if (response.data.flash){
					this.flashStore.addFlash(response.data.flash)
				}
				if (response.data.quote !== undefined){
					this.quote = response.data.quote
					this.quoteAttributes = response.data.quoteAttributes
					this.templateContent = response.data.templateContent
					this.paymentPlans = response.data.paymentPlans

					if (this.quote.project_type_payment_plan_id !== null && this.quote.project_type_payment_plan_id !== false){
						//this.answer.project_type_payment_plan_id = this.quote.project_type_payment_plan_id
					}
					if ((this.answer.project_type_payment_plan_id === null || this.answer.project_type_payment_plan_id === false) && this.paymentPlans.length){
						//this.answer.project_type_payment_plan_id = _.first(this.paymentPlans).id
					}

					this.answer.optionalProducts = {}
					if (this.quote.optionals_selected !== undefined) {
						for (let i in this.quoteAttributes.optionalProducts){
							this.answer.optionalProducts[this.quoteAttributes.optionalProducts[i].id] = null

							if (this.quote.optionals_selected[this.quoteAttributes.optionalProducts[i].product_id] !== undefined
								&& this.quote.optionals_selected[this.quoteAttributes.optionalProducts[i].product_id]){

								this.answer.optionalProducts[this.quoteAttributes.optionalProducts[i].id] = 1
							}
						}
					}
				}
			}).catch(function (error) {

			})
		},
		calculateOptional: function(){
			this.optionalPrices = _.clone(this.defaultOptionalPrices)
			for (let i in this.quoteAttributes.optionalProducts) {
				if (this.answer.optionalProducts[this.quoteAttributes.optionalProducts[i].id] !== undefined && this.answer.optionalProducts[this.quoteAttributes.optionalProducts[i].id]){
					this.optionalPrices.net += this.quoteAttributes.optionalProducts[i].product.calculated_price.net
					this.optionalPrices.gross += this.quoteAttributes.optionalProducts[i].product.calculated_price.gross
					this.optionalPrices.discountNet += this.quoteAttributes.optionalProducts[i].product.calculated_price.discountNet
					this.optionalPrices.discountGross += this.quoteAttributes.optionalProducts[i].product.calculated_price.discountGross
					this.optionalPrices.finalNet += this.quoteAttributes.optionalProducts[i].product.calculated_price.finalNet
					this.optionalPrices.finalGross += this.quoteAttributes.optionalProducts[i].product.calculated_price.finalGross
				}
			}
		},
		emitClose: function (){
			this.$emit('close')
		},
		saveAnswer: function(accept){
			this.answer.status = accept ? 'accepted' : 'rejected'

			if (this.answer.status === 'accepted'){
				this.formValidated = true
				if (!this.answer.project_type_payment_plan_id){
					document.getElementById('paymentPlanRadio').scrollIntoView({ behavior: 'smooth' })
					return false
				}

				for (let i in this.quoteAttributes.optionalProducts) {
					if (this.answer.optionalProducts[this.quoteAttributes.optionalProducts[i].id] === null){
						document.getElementById('optionalProductRow_' + this.quoteAttributes.optionalProducts[i].id).scrollIntoView({ behavior: 'smooth' })

						return false
					}
				}
			} else {
				this.formValidated = false
			}

			axios.post(route('clientArea.projects.quote', this.projectDataStore.projectId) + '/' + this.quoteId, this.answer).then((response)=>{
				this.flashStore.addFlash(response.data.flash)
				if (response.data.status === 'success'){
					this.$emit('closeAndReload')
				}
			}).catch(function (error) {

			})
		},
		displayTopBorder: function (array, index){
			let current = array[index]
			if (index < 1){
				return false
			}
			let prev = array[index - 1]

			return prev.tag_id !== current.tag_id && !prev.tag_id
		}
	}
}
</script>

<style scoped>
td.divider {
	border-top: 1px solid var(--bs-secondary);
}
</style>
