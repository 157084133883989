<template>
	<data-loader :data="['vehicles']" v-slot="{ dataStore }" v-if="vehicleId">
		<color-badge
			color="light"
			:icon="dataStore.getData('vehicles', true)[vehicleId].is_hgv ? 'far fa-truck' : 'far fa-car-side'"
			:small="small"
		>
			<strong>{{ dataStore.getData('vehicles', true)[vehicleId].licence_plate }}</strong>
			<div v-if="dataStore.getData('vehicles', true)[vehicleId].name">{{ dataStore.getData('vehicles', true)[vehicleId].name }}</div>
			<template v-else-if="dataStore.getData('vehicles', true)[vehicleId].make">
				<span>{{ dataStore.getData('vehicles', true)[vehicleId].make }}</span>
				<span v-if="dataStore.getData('vehicles', true)[vehicleId].model">{{ dataStore.getData('vehicles', true)[vehicleId].model }}</span>
			</template>
		</color-badge>
	</data-loader>
</template>

<script>
import UserLevelBadge from "./UserLevelBadge.vue";
import ColorBadge from "./ColorBadge.vue";
import DataLoader from "./DataLoader.vue";
import {useGeneralDataStore} from "../stores/generalData";

export default {
	components: {DataLoader, ColorBadge, UserLevelBadge},
	props: {
		vehicleId: Number,
		small: Boolean
	},
	data(){
		return {
			dataStore: useGeneralDataStore(),
		}
	},
	methods: {
	},
}
</script>

<style scoped>

</style>
