<template>
	<div class="card timeline-card">
		<div class="timeline-item-head">
			<slot name="head"></slot>

			<Link :href="visitUrl" class="visit" title="Megtekint" v-if="visitUrl !== undefined && visitUrl !== null"></Link>

			<div class="timeline-card--controls">
				<div class="dropdown" v-if="delayable">
					<button type="button" class="btn btn-light btn-sm button-group-toggle-btn" title="Elhalaszt" data-bs-toggle="dropdown" aria-expanded="false">
						<i class="far fa-hourglass"></i>
					</button>

					<div class="dropdown-menu dropdown-menu-end" role="menu">
						<div class="px-2 py-1">
							<label>Óra</label>
							<input type="number" :step="1" :min="0" class="form-control" v-model="delayHours" />
						</div>
						<div class="dropdown-divider"></div>
						<div class="px-2 py-1">
							<label>Nap</label>
							<input type="number" :step="1" :min="0" class="form-control" v-model="delayDays" />
						</div>
						<div class="dropdown-divider"></div>
						<div class="px-2 py-1">
							<label>Hónap</label>
							<input type="number" :step="1" :min="0" class="form-control" v-model="delayMonths" />
						</div>
						<div class="dropdown-divider"></div>
						<div class="px-2 py-1">
							<button type="button" class="btn btn-secondary" @click.prevent="saveDelay"><i class="far fa-hourglass"></i> Elhalaszt</button>
						</div>
					</div>
				</div>

				<button type="button" class="btn btn-light btn-sm" title="Bezár" v-if="closable" @click.prevent="close"><i class="far fa-times"></i></button>
			</div>
		</div>

		<slot name="default"></slot>

		<div v-if="(entity && entity.post_id) || project || $slots.footer" class="timeline-item-footer">
			<slot name="footer"></slot>
			<button type="button" class="btn btn-light timeline-item-comment-toggle" @click.prevent="showComments = !showComments" v-if="(entity && entity.post_id) || project">
				<span class="position-relative">
					<i :class="'fa' + (commentCount ? 's' : 'r') + ' fa-comment'"></i>
					<span class="d-lg-none badge badge-sm bg-danger position-absolute top-0 start-100 font-size-10 translate-middle-y" v-if="commentCount">{{ commentCount }}</span>
				</span>
				<span class="d-none d-md-inline">
					<span>{{ commentCount ? commentCount + ' hozzászólás' : 'Nincs hozzászólás' }}</span>
					<i :class="{'fa': 1, 'fa-angle-down': !showComments, 'fa-angle-up': showComments}"></i>
				</span>
			</button>
		</div>

		<div v-if="(entity && entity.post_id) || project">
			<div class="timeline-item-comments">
				<template v-if="showComments">
					<EmbeddedComments
						v-if="entity && entity.post_id"
						:data-url="route('posts.comments', entity.post_id)"
						can-create
						@commentCount="emitPostCommentCount"
					></EmbeddedComments>
					<EmbeddedComments
						v-if="project"
						:data-url="route('projects.comments', project.id)"
						can-create
						@commentCount="emitProjectCommentCount"
					></EmbeddedComments>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import {Link} from "@inertiajs/vue3";
import InputField from "../../components/form/InputField.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import Comments from "../../components/Comments.vue";
import EmbeddedComments from "../../components/EmbeddedComments.vue";
import UserBadge from "../../components/badges/UserBadge.vue";

export default {
	emits: ['reloadFrom', 'commentCount'],
	components: {UserBadge, EmbeddedComments, Comments, InputField, Link},
	props: {
		entry: Object,
		comments: Object,
		delayable: Boolean,
		closable: Boolean,
		visitUrl: String
	},
	data(){
		return {
			delayHours: 1,
			delayDays: 0,
			delayMonths: 0,
			flash: useFlashStoreStore(),
			showComments: false,
		}
	},
	computed: {
		entity: {
			get(){
				return this.entry.type === 'entity' ? this.entry.entity : null
			}
		},
		project: {
			get(){
				return this.entry.type === 'project' ? this.entry.project : null
			}
		},
		commentCount: {
			get() {
				let key = ''
				if (this.entity !== null && this.entity.post_id !== null){
					key = 'post_' + this.entity.post_id
				}
				if (this.project !== null){
					key = 'project_' + this.project.id
				}

				return this.comments[key] !== undefined ? this.comments[key] : 0
			}
		},
	},
	methods: {
		saveDelay: function (){
			if (this.delayHours > 0 || this.delayDays > 0 || this.delayMonths > 0){
				axios.post('/dashboard/delay/' + this.entry.type + '/' + this.entry.id, {
					hours: this.delayHours,
					days: this.delayDays,
					months: this.delayMonths,
				}).then((response)=>{
					this.$emit('reloadFrom', this.entry.sort_date)
				}).catch((error) => {
					this.flash.addError(error)
				})
			}
		},
		close: function (){
			axios.post('/dashboard/close/' + this.entry.type + '/' + this.entry.id).then((response)=>{
				this.$emit('reloadFrom', this.entry.sort_date)
			}).catch((error) => {
				this.flash.addError(error)
			})
		},
		emitPostCommentCount: function (count){
			this.$emit('commentCount', 'post_' + this.entity.post_id, count)
		},
		emitProjectCommentCount: function (count){
			this.$emit('commentCount', 'project_' + this.project.id, count)
		},
	}
}
</script>
