<template>
	<TitleBar :title="attributes.id !== undefined ? 'Űrlap kategória módosítása' : 'Űrlap kategória hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('formCategories.store', model)"
		:back-url="route('formCategories.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<order-field
								v-model="attributes"
								label="Sorrend"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import SwitchField from "../components/form/SwitchField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import DateTime from "../components/DateTime.vue";
import TitleBar from "../components/TitleBar.vue";
import Attribute from "../components/Attribute.vue";
import OrderField from "../components/form/OrderField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {UpdatedAttribute, OrderField, Attribute, TitleBar, DateTime, InputField, FormWrapper, SwitchField},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		return {
			attributes: this.model,
		}
	},
}
</script>
