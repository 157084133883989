<template>
	<modal
		:title="getModalTitle()"
		:loading="loading"
		loading-keeps-content
		:full-width="true"
		:buttons-on-top="true"
		:closable="true"
		:z-index="2200"
		:required-data="['products', 'userLevels', 'productManufacturers']"
		@close="$emit('close')">

		<template #buttons>
			<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable" @click.prevent="$refs.installationForm.submit()">
				<i class="fas fa-save label-icon"></i> Mentés
			</button>
			<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing" @click.prevent="$emit('startEditing')">
				<i class="fas fa-pencil label-icon"></i> Szerkesztés
			</button>
			<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="closeInstallation" :disabled="!canCloseInstallation" v-if="isEditable && installation.id && !installation.closed_at">
				<i class="fas fa-check label-icon"></i> Lezárás
			</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
				<i class="fas fa-times label-icon"></i> Bezár
			</button>

			<LockMessage :lock="projectDataStore.lock" :margin-bottom="false" :margin-left="true"></LockMessage>
		</template>

		<template #default>

			<form-wrapper
				ref="installationForm"
				:submit-function="saveForm"
				v-model="installation"
				:store="projectDataStore"
				:show-top-buttons="false"
			>
				<template #default="{activeTab, errors, editable}">
					<data-loader :data="['products']" v-slot="{ dataStore }">
						<div>
							<div class="row">
								<div class="col-lg-6">
									<div class="card" v-if="installation.id">
										<div class="card-body">
											<div class="details-box mb-0">
												<contract-id-attribute
													v-if="installation.contract_id !== undefined"
													:project-id="installation.contract.project_id"
													:project-type-id="projectDataStore.projectType.id"
													:public-id="installation.contract.public_id"
													:contract-id="installation.contract.id"
												/>

												<date-time-attribute :value="installation.created_at" created>
													<user-badge :user-id="installation.created_by_user_id" />
												</date-time-attribute>

												<div>
													<i class="far fa-question"></i> Státusz:
													<color-badge
															:color="installation.closed_at ? 'success' : 'warning'"
															:icon="installation.closed_at ? 'far fa-check' : 'far fa-hourglass'"
															:content="installation.closed_at ? 'Lezárva' : 'Folyamatban'"
															></color-badge>
												</div>

												<date-time-attribute :value="installation.closed_at" closed>
													<user-badge :user-id="installation.closed_by_user_id" />
												</date-time-attribute>
											</div>
										</div>
									</div>

									<div class="card">
										<div class="card-body">
											<div class="row">
												<div class="col-md-6">
													<DateField
														label="Telepítés dátuma"
														name="installation_date"
														v-model="installation.installation_date"
														:disabled="!isEditable"
														rules="required"
														:errors="errors"
													/>
												</div>
												<div class="col-md-6">
													<data-loader :data="['userLevels']" v-slot="{ dataStore }">
														<user-select-field
															label="Telepítő"
															name="installer_user_id"
															rules="required"
															type-filter="installer"
															v-model="installation.installer_user_id"
															:disabled="!isEditable || isInstaller"
															:errors="errors"
															:user-levels="dataStore.getData('userLevels')"
															@change="installerChanged"
														/>
													</data-loader>
												</div>
												<div class="col-12">
													<TextareaField
															name="comment"
															:rows="4"
															v-model="installation.comment"
															:disabled="!isEditable"
															label="Megjegyzés"
													></TextareaField>
												</div>
											</div>
										</div>
									</div>
									<template v-for="documentType in projectDataStore.projectType.document_types" :key="'installationDocumentCategory_' + documentType.id">
										<div class="card" v-if="documentType.installer_can_view && documentType.installation">
											<div class="card-body">
												<h4 :class="{
													'card-title': 1,
													'mb-0': 1,
													'd-flex': 1,
													'align-items-center': 1,
													'text-danger': !validateDocumentType(documentType) && documentType.required_for_installation !== null && documentType.required_for_installation,
													'text-success': validateDocumentType(documentType) && documentType.required_for_installation !== null && documentType.required_for_installation,
													}">
													<i v-if="documentType.icon" :class="'far fa-' + documentType.icon + ' fa-fw me-2'"></i>

													{{ documentType.name }}

													<span class="badge bg-danger badge-xl ms-3 me-3" v-if="!validateDocumentType(documentType) && documentType.required_for_installation !== null && documentType.required_for_installation">Hiányzó fileok</span>
													<span class="d-md-none ms-auto">
														<button class="btn btn-secondary" type="button" @click.prevent="openDocumentType(documentType)">
															<i class="far fa-angle-up font-size-18" v-if="openDocumentTypeCategory === documentType.id"></i>
															<i class="far fa-angle-down font-size-18" v-else></i>
														</button>
													</span>
												</h4>

												<div v-if="projectDataStore.documentsLoaded" :class="{'hide-on-mobile': 1, 'mt-4': 1, 'd-block': openDocumentTypeCategory === documentType.id}">
													<ProjectFormDocumentCategory
															v-if="installation.documents !== undefined"
															:editable="editable && isEditable"
															:creating-enabled="editable && isEditable"
															:document-type="documentType"
															user-type="installer"
															:ref="'documentCategory_' + documentType.id"
													></ProjectFormDocumentCategory>
												</div>
											</div>
										</div>
									</template>
								</div>
								<div class="col-lg-6">
									<h4 class="mb-3 font-size-18">Fogyóeszköz felhasználás</h4>
									<div class="mb-3">
										<template v-for="(product, productKey) in productList">
											<template v-if="getProduct(product).type === 'consumable'
											&& ((stock[product.productId] !== undefined && stock[product.productId] > 0 && isProductEditable) || product.deliveredQuantity || productList[productKey].quantity !== 0)">
												<ProjectInstallationProduct
													v-model="productList[productKey]"
													:product="getProduct(product)"
													:contract-product="getContractProduct(product)"
													:product-list="productList"
													:stock="stock"
													:editable="isProductEditable"
													:min="getMinQuantity(productKey)"
													:product-key="productKey"
													:scanner-product-index="scannerProductIndex"
													:scanner-mode="scannerMode"
													:delivered-products="deliveredProducts"
													:installation-products="installationProducts"
													:installation="installation"
													v-if="installationProducts !== undefined"
													@product-changed="productsChanged"
													@quantity-changed="quantityChanged"
													@show-scanner="showScanner"
													@save-delivery-requirement="saveDeliveryRequirement"
													@remove-serial-number="removeSerialNumber"
													@barcode-submit="barcodeSubmit"
													/>
											</template>
										</template>
									</div>
									<h4 class="mb-3 font-size-18">Szerződés termékei</h4>
									<div class="mb-3">
										<template v-for="(product, productKey) in productList">
											<template v-if="getProduct(product).type === 'material' && !product.asComponent">
												<ProjectInstallationProduct
													v-model="productList[productKey]"
													:product="getProduct(product)"
													:contract-product="getContractProduct(product)"
													:product-list="productList"
													:stock="stock"
													:editable="isProductEditable"
													:min="getMinQuantity(productKey)"
													:product-key="productKey"
													:scanner-product-index="scannerProductIndex"
													:scanner-mode="scannerMode"
													:delivered-products="deliveredProducts"
													:installation-products="installationProducts"
													:installation="installation"
													v-if="installationProducts !== undefined"
													@product-changed="productsChanged"
													@quantity-changed="quantityChanged"
													@show-scanner="showScanner"
													@save-delivery-requirement="saveDeliveryRequirement"
													@remove-serial-number="removeSerialNumber"
													@barcode-submit="barcodeSubmit"
													/>
											</template>
										</template>
									</div>
									<h4 class="mb-3 font-size-18">Alkatrészek</h4>
									<div class="mb-3">
										<template v-for="(product, productKey) in productList">
											<template v-if="getProduct(product).type === 'material' && product.asComponent">
												<ProjectInstallationProduct
													v-model="productList[productKey]"
													:product="getProduct(product)"
													:contract-product="getContractProduct(product)"
													:product-list="productList"
													:stock="stock"
													:editable="isProductEditable"
													:min="getMinQuantity(productKey)"
													:product-key="productKey"
													:scanner-product-index="scannerProductIndex"
													:scanner-mode="scannerMode"
													:delivered-products="deliveredProducts"
													:installation-products="installationProducts"
													:installation="installation"
													v-if="installationProducts !== undefined"
													@product-changed="productsChanged"
													@quantity-changed="quantityChanged"
													@show-scanner="showScanner"
													@save-delivery-requirement="saveDeliveryRequirement"
													@remove-serial-number="removeSerialNumber"
													@barcode-submit="barcodeSubmit"
													/>
											</template>
										</template>
									</div>

									<div class="card" v-if="editable">
										<div class="card-body">
											<product-select-field
												label="Termék hozzáadása"
												name="addProduct"
												:field-only="true"
												:type-filter="['material']"
												:excluded="addProductSelectExcluded"
												v-model="addProduct"
												></product-select-field>
										</div>
									</div>
								</div>
							</div>
						</div>
					</data-loader>
				</template>
			</form-wrapper>
		</template>
	</modal>
</template>

<script>
import {moveValueInArray} from "../../functions";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import InputField from "../../components/form/InputField.vue";
import ProjectProductList from "./ProjectProductList.vue";
import Price from "../../components/Price.vue";
import vSelect from "vue-select";
import FileList from "../../components/FileList.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import DateField from "../../components/form/DateField.vue";
import UserSelectField from "../../components/form/UserSelectField.vue";
import ProductSelectField from "../../components/form/ProductSelectField.vue";
import FileIcon from "../../components/FileIcon.vue";
import VueUploadComponent from "vue-upload-component";
import ProjectFormDocumentCategory from "./ProjectFormDocumentCategory.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import LockMessage from "../../components/LockMessage.vue";
import ProjectInstallationProduct from "./ProjectInstallationProduct.vue";
import UserBadge from "../../components/badges/UserBadge.vue";
import DataLoader from "../../components/DataLoader.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import {useProjectDataStore} from "../../stores/projectData";
import {useGeneralDataStore} from "../../stores/generalData";
import Modal from "../../components/Modal.vue";
import DateTime from "../../components/DateTime.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import ProjectAttribute from "./ProjectAttribute.vue";
import ProjectIdAttribute from "../../components/attributes/ProjectIdAttribute.vue";
import ContractIdAttribute from "../../components/attributes/ContractIdAttribute.vue";
import DateTimeAttribute from "../../components/attributes/DateTimeAttribute.vue";
import UserAttribute from "../../components/attributes/UserAttribute.vue";

export default {
	components: {
		UserAttribute,
		DateTimeAttribute,
		ContractIdAttribute,
		ProjectIdAttribute,
		ProjectAttribute,
		FormWrapper,
		DateTime,
		Modal,
		DataLoader,
		UserBadge,
		ProjectInstallationProduct,
		LockMessage,
		ColorBadge,
		SwitchField,
		ProjectFormDocumentCategory,
		VueUploadComponent,
		FileIcon,
		ProductSelectField,
		UserSelectField,
		DateField,
		TextareaField,
		FileList,
		Price,
		ProjectProductList,
		InputField,
		ProjectInfoSidebar,
		vSelect
	},
	emits: ['close', 'closeAndReload', 'generate', 'updateStats', 'startEditing', 'loadData'],
	props: {
		productCategories: [Array, Object],
		editable: Boolean,
		canStartEditing: Boolean,
		formErrors: Object,
		contractId: Number,
		installationId: {
			type: [Number, String],
			default: 0
		}
	},
	mounted() {
		this.dataStore.loadData('products', this.loadData)
		if (!this.projectDataStore.documentsLoaded){
			this.projectDataStore.loadDocuments()
		}
	},
	created() {
		this.successSound = new Audio('/audio/inventory-scan-success.mp3')
		this.successSound.preload = true

		this.errorSound = new Audio('/audio/inventory-scan-error.mp3')
		this.errorSound.preload = true

		window.addEventListener("keypress", this.onKeyPress);
	},
	beforeUnmount() {
		window.removeEventListener("keypress", this.onKeyPress);
	},
	computed: {
		isEditable: {
			get(){
				if (!this.editable){
					return false
				}
				return this.installation.editable
			}
		},
		isProductEditable: {
			get(){
				if (!this.editable || !this.installation.id){
					return false
				}
				return this.installation.editable
			}
		},
		canCloseInstallation: {
			get() {
				if (!this.installation.installation_date || !this.installation.installer_user_id){
					return false
				}
				for (let i = 0; i < this.projectDataStore.projectType.document_types.length; i++){
					if (!this.validateDocumentType(this.projectDataStore.projectType.document_types[i])){
						return false
					}
				}

				return true
			}
		},
		productsById: {
			get() {
				return this.dataStore.getData('products', true)
			}
		},
		contractProductsById: {
			get() {
				return _.keyBy(this.contractProducts, 'id')
			}
		},
		addProductSelectExcluded: {
			get(){
				let excluded = []
				_.forEach(this.productList, function(item, key){
					excluded.push(item.productId)
				})
				return excluded
			}
		}
	},
	watch: {
		addProduct: {
			handler(newValue, oldValue) {
				if (newValue){
					let order = 1
					_.forEach(this.productList, function (item, key){
						key = parseInt(key)
						if (key >= order){
							order = key + 1
						}
					})

					this.productList[order] = {
						contractProductId: null,
						quantity: 0,
						contractQuantity: 0,
						deliveredQuantity: 0,
						order: order,
						productId: newValue,
						installationProducts: [],
						requiresDelivery: this.productsById[newValue].requires_delivery
					}
				}

				this.addProduct = null
			}
		},
	},
	data() {
		let uploadedFiles = {}
		const projectDataStore = useProjectDataStore()
		_.forEach(projectDataStore.projectType.document_types, function(type){
			uploadedFiles[type.id] = []
		})
		return {
			installation: {},
			productList: {},
			installationProducts: [],
			consumableProducts: [],
			loading: false,
			stock: {},
			contractProducts: [],
			deliveredProducts: {},
			loaded: false,
			isInstaller: false,
			dataStore: useGeneralDataStore(),
			flashStore: useFlashStoreStore(),
			projectDataStore: projectDataStore,
			barcode: '',
			successSound: '',
			errorSound: '',
			scannerProductIndex: null,
			openDocumentTypeCategory: null,
			scannerMode: 'remove',
			quantityChangeTimeout: null,
			addProduct: null,
			uploadedFiles: uploadedFiles,
		};
	},
	methods: {
		onKeyPress: function (e){
			if (e.target.tagName.toLowerCase() !== 'input'){
				if (e.key.match(/^[0-9a-z]+$/)){
					this.barcode = e.key
					if (document.getElementById('barcodeInput')) {
						document.getElementById('barcodeInput').focus()
					}
				}
			}
		},
		moveValueInArray: moveValueInArray,
		getDataUrl: function(){
			let url = '/projects/' + this.projectDataStore.projectId + '/installation/' + this.contractId
			if (this.installationId) {
				url += '/' + this.installationId
			}

			return url
		},
		loadData: function () {
			this.loading = true

			axios.get(this.getDataUrl()).then((response) => {
				this.handleResponse(response)
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		emitClose: function(){
			this.$emit('close')
		},
		closeInstallation: function (e){
			this.saveForm(e, true)
		},
		saveForm: function(e, close){
			this.saveProducts(() => {
				this.saveFormRequest(close)
			})
		},
		saveFormRequest: function (close){
			close = close || false
			this.loading = true

			if (this.installation.installation_date !== undefined && this.installation.installation_date instanceof Date) {
				this.installation.installation_date.setHours(12)
			}
			let documents = {}
			_.forEach(this.installation.documents, function(item, key){
				documents[key] = _.map(item, function(document){
					return _.pick(document, ['id', 'description', 'tags'])
				})
			})
			let payload = {
				installation: {
					installation_date: this.installation.installation_date,
					installer_user_id: this.installation.installer_user_id,
					comment: this.installation.comment,
					documents: documents,
				},
				uploadedFiles: this.projectDataStore.uploadedFiles,
				close: close
			}

			axios.post(this.getDataUrl(), payload).then((response)=>{
				this.handleResponse(response)
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.success === undefined || response.data.success) {
				this.installation = response.data.installation
				if (response.data.contractProducts !== undefined) {
					this.contractProducts = response.data.contractProducts
				}
				if (response.data.deliveredProducts !== undefined) {
					this.deliveredProducts = response.data.deliveredProducts
				}
				if (response.data.installationProducts !== undefined) {
					this.installationProducts = response.data.installationProducts
				}
				if (response.data.isInstaller !== undefined) {
					this.isInstaller = response.data.isInstaller
				}
				if (response.data.consumableProducts !== undefined) {
					this.consumableProducts = response.data.consumableProducts
				}
				if (response.data.stock !== undefined) {
					this.stock = response.data.stock
				}

				this.setDefaultProducts()

				this.parseInstallationProducts()

				for (let i = 0; i < this.projectDataStore.projectType.document_types.length; i++){
					this.projectDataStore.uploadedFiles[this.projectDataStore.projectType.document_types[i].id] = []
				}

				this.loaded = true

				if (response.data.success !== undefined) {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}
		},
		getProduct: function(installationProduct){
			if (installationProduct.productId && this.productsById[installationProduct.productId] !== undefined){
				return this.productsById[installationProduct.productId]
			}

			return {}
		},
		getContractProduct: function(installationProduct){
			if (installationProduct.contractProductId && this.contractProductsById[installationProduct.contractProductId] !== undefined){
				return this.contractProductsById[installationProduct.contractProductId]
			}

			return {}
		},
		checkQuantityErrors: function(){
			_.forEach(this.productList, (product) => {
				let available = this.stock[product.productId] !== undefined ? this.stock[product.productId] : 0
				if (product.quantity > available){
					product.quantityError = product.quantity
					product.quantity = available
				} else {
					product.quantityError = null
				}
			})
		},
		setDefaultProducts: function(){
			let payload = {}
			let order = 0
			_.forEach(this.contractProducts, (product) => {
				let current = _.find(payload, function(item){
					return item.productId === parseInt(product.product_id)
				})
				if (current === undefined) {
					payload[order] = {
						contractProductId: product.id,
						quantity: 0,
						contractQuantity: product.quantity,
						deliveredQuantity: 0,
						changed: false,
						order: order++,
						productPriority: this.productsById[product.product_id].priority,
						productId: product.product_id,
						installationProducts: [],
						shipBack: false,
						asComponent: product.as_component,
						requiresDelivery: this.productsById[product.product_id].requires_delivery
					}
				} else {
					current.contractQuantity += product.quantity
				}
			})
			_.forEach(this.deliveredProducts, (deliveredProductItem) => {
				let current = _.find(payload, function(item){
					return item.productId === parseInt(deliveredProductItem.product_id)
				})
				let product = this.productsById[deliveredProductItem.product_id]

				if (product.track_serial_number){
					if (current === undefined) {
						payload[order] = {
							contractProductId: null,
							quantity: 0,
							contractQuantity: 0,
							changed: false,
							deliveredQuantity: deliveredProductItem.quantity,
							order: order++,
							productPriority: product.priority,
							productId: product.id,
							installationProducts: [],
							shipBack: false,
							asComponent: false,
							requiresDelivery: product.requires_delivery
						}
					} else {
						current.deliveredQuantity += deliveredProductItem.quantity
					}
				} else {
					if (current === undefined) {
						payload[order] = {
							contractProductId: null,
							quantity: 0,
							contractQuantity: 0,
							changed: false,
							deliveredQuantity: deliveredProductItem.quantity,
							order: order++,
							productPriority: product.priority,
							productId: product.id,
							installationProducts: [],
							shipBack: false,
							asComponent: false,
							requiresDelivery: product.requires_delivery
						}
					} else {
						current.deliveredQuantity += deliveredProductItem.quantity
					}
				}
			})
			_.forEach(this.installationProducts, (product) => {
				let current = _.find(payload, function(item){
					return item.productId === product.product_id
				})

				if (!current) {
					payload[order] = {
						contractProductId: null,
						quantity: product.quantity,
						contractQuantity: 0,
						deliveredQuantity: 0,
						changed: false,
						order: order++,
						productPriority: this.productsById[product.product_id].priority,
						productId: product.product_id,
						installationProducts: [],
						shipBack: product.ship_back,
						asComponent: false,
						requiresDelivery: product.requires_delivery
					}
				} else {
					current.shipBack = product.ship_back
					current.requiresDelivery = product.requires_delivery
				}
			})

			_.forEach(this.consumableProducts, function(product){
				let current = _.find(payload, function(item){
					return item.productId === product.id
				})
				if (!current) {
					payload[order] = {
						contractProductId: null,
						quantity: 0,
						contractQuantity: 0,
						deliveredQuantity: 0,
						productPriority: product.priority,
						order: order++,
						productId: product.id,
						installationProducts: [],
						shipBack: product.ship_back,
						asComponent: false,
						requiresDelivery: product.requires_delivery
					}
				}
			})

			payload = _.reverse(_.sortBy(payload, (item) => {
				return item.productPriority
			}))

			_.forEach(payload, (item, index) => {
				item.order = index
			})

			this.productList = payload

			this.checkQuantityErrors()
		},
		showScanner: function(productKey, action){
			this.scannerProductIndex = productKey
			this.scannerMode = action
			this.barcode = ''
		},
		getMinQuantity: function (installationProductKey){
			if (this.productList[installationProductKey].deliveredQuantity){
				return -1 * this.productList[installationProductKey].deliveredQuantity
			}

			return 0
		},
		playSound: function(audio){
			if (!audio.paused){
				audio.pause()
			}
			audio.currentTime = 0
			audio.play()
		},
		parseInstallationProducts: function (){
			_.forEach(this.productList, (product) => {
				product.installationProducts = []
				product.quantity = 0
				_.forEach(this.installationProducts, (installationProduct) => {
					if (installationProduct.product_id === product.productId){
						product.installationProducts.push(installationProduct)
						product.quantity += installationProduct.quantity
					}
				})
			})
		},
		barcodeSubmit: function(barcode, installationProduct){
			if (barcode.length < 6){
				return
			}

			this.loading = true

			axios.put('/projects/installation/scan/' + this.installation.id + '/' + installationProduct.productId, {
					barcode: barcode,
					remove: this.scannerMode === 'remove'
			}).then((response) => {
				this.loading = false
				if (!response.data.success){
					this.playSound(this.errorSound)
				} else {
					this.playSound(this.successSound)
				}
				this.installationProducts = response.data.installationProducts
				this.parseInstallationProducts()

				this.flashStore.addFlash(response.data.flash)
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		removeSerialNumber: function (product, serialNumber){
			axios.put('/projects/installation/removeProduct/' + this.installation.id, {
				barcode: serialNumber,
				productId: product.productId
			}).then((response) => {
				this.loading = false

				if (response.data.success){
					this.playSound(this.successSound)
				}

				this.installationProducts = response.data.installationProducts
				this.parseInstallationProducts()

				this.flashStore.addFlash(response.data.flash)
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		quantityChanged: function(){
			if (this.quantityChangeTimeout !== null){
				clearTimeout(this.quantityChangeTimeout)
			}
			this.quantityChangeTimeout = setTimeout(() => {
				this.saveProducts()
			}, 1000)
		},
		productsChanged: function(){
			this.saveProducts()
		},
		saveProducts: function (callback){
			let payload = {}
			_.forEach(this.productList, function (product){
				if (product.changed !== undefined && product.changed){
					payload[product.productId] = {
						quantity: product.quantity,
						ship_back: product.shipBack,
						requires_delivery: product.requiresDelivery
					}
					product.changed = false
				}
			})
			if (Object.keys(payload).length){
				axios.put('/projects/installation/products/' + this.installation.id, {
					products: payload
				}).then((response) => {
					if (response.data.flash !== undefined){
						this.flashStore.addFlash(response.data.flash)
					}
					if (callback !== undefined){
						callback()
					}
				}).catch((error) => {
					this.flashStore.addError(error)
				})
			} else {
				if (callback !== undefined){
					callback()
				}
			}
		},
		saveDeliveryRequirement: function (product){
			axios.put('/projects/installation/saveDeliveryRequirement/' + this.installation.id + '/' + product.productId, {
				requiresDelivery: product.requiresDelivery
			}).then((response) => {
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		getDocumentTypeCount: function (documentType){
			let count = 0
			if (this.installation.documents[documentType.id] !== undefined){
				count += this.installation.documents[documentType.id].length
			}
			if (this.projectDataStore.uploadedFiles[documentType.id] !== undefined){
				count += this.projectDataStore.uploadedFiles[documentType.id].length
			}

			return count
		},
		validateDocumentType: function (documentType){
			if (documentType.required_for_installation === null || documentType.required_for_installation < 1){
				return true
			}
			return this.getDocumentTypeCount(documentType) >= documentType.required_for_installation;


		},
		installerChanged: function (installer){
			if (!this.installation.id){
				return
			}
			axios.put('/projects/installation/setInstaller/' + this.installation.id, {
				installerId: installer
			}).then((response) => {
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		openDocumentType: function (type){
			if (this.openDocumentTypeCategory === type.id){
				this.openDocumentTypeCategory = null
			} else {
				this.openDocumentTypeCategory = type.id
			}
		},
		getModalTitle: function (){
			if (this.installation !== undefined || this.installation.id !== undefined){
				return this.editable ? 'Telepítés szerkesztése' : 'Telepítés'
			}

			return 'Telepítés létrehozása'
		},
	}
}
</script>

<style scoped>
@media (max-width: 600px) {
	.hide-on-mobile {
		display: none;
	}
}
</style>
