<template>
	<TitleBar :title="attributes.id !== undefined ? 'Raktár módosítása' : 'Raktár hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('warehouses.store', attributes.id)"
		:back-url="route('warehouses.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<switch-field
								label="Aktív"
								name="active"
								v-model="attributes.active"
							></switch-field>
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<switch-field
								label="Mozgó raktár"
								name="mobile"
								v-model="attributes.mobile"
							></switch-field>
							<input-field
								label="Cégnév"
								name="company_name"
								rules="required"
								v-model="attributes.company_name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="card" v-if="!attributes.mobile">
				<div class="card-body">
					<h4 class="card-title mb-4">Cím</h4>
					<address-input
						v-model="attributes.address"
						attribute-name="addressComponent"
						editable="attributes.editable"
						:errors="errors"
					/>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import AddressInput from "../components/AddressInput.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import AddressBadge from "../components/badges/AddressBadge.vue";

export default {
	layout: AppLayout,
	props: {
		model: Object
	},
	components: {AddressBadge, UpdatedAttribute, AddressInput, TitleBar, SwitchField, InputField, FormWrapper},
	data(){
		let attributes = this.model
		if (attributes.address === null){
			attributes.address = {}
		}
		return {
			attributes: attributes,
		}
	},
}
</script>
