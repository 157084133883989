<template>
	<TitleBar :title="$t('Új jelszó beállítása')" />

	<div>
		<h5 class="text-primary">{{ $t('Új jelszó beállítása') }}</h5>
		<p class="text-muted">{{ $t('Add meg új jelszavadat!') }}</p>
	</div>

	<div class="mt-4">
		<form-wrapper
			v-model="attributes"
			:store-url="route('clientArea.auth.resetPassword', token)"
			:back-url="route('clientArea.auth.login')"
			:show-bottom-buttons="false"
			:show-top-buttons="false"
		>
			<template #default="{activeTab, errors, editable}">
				<input-field
					:label="$t('E-mail cím')"
					name="email"
					v-model="attributes.email"
					rules="required"
					:multilang="false"
					data-type="email"
					:errors="errors"
				/>

				<input-field
					:label="$t('Jelszó')"
					name="password"
					data-type="password"
					rules="min:8|required"
					:multilang="false"
					v-model="attributes.password"
					:errors="errors"
				/>

				<input-field
					:label="$t('Jelszó újra')"
					name="password_confirmation"
					data-type="password"
					:multilang="false"
					rules="required"
					:equal-to="attributes.password"
					v-model="attributes.password_confirmation"
					:errors="errors"
				/>

				<div class="mt-3 d-grid gap-2">
					<button class="btn btn-primary waves-effect waves-light" tabindex="2" type="submit">{{ $t('Elküld') }}</button>
					<Link :href="route('clientArea.auth.login')" class="btn btn-secondary waves-effect waves-light" tabindex="3">{{ $t('Vissza') }}</Link>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import ClientAreaAuthLayout from "../layouts/ClientAreaAuthLayout.vue";
import {Link} from "@inertiajs/vue3";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import {useApplicationStore} from "../stores/applicationStore";

export default {
	layout: ClientAreaAuthLayout,
	components: {InputField, FormWrapper, Link, TitleBar},
	props: {
		token: String
	},
	data() {
		return {
			app: useApplicationStore(),
			attributes: {
				email: '',
				password: '',
				password_confirmation: '',
				token: this.token
			}
		}
	}
}
</script>
