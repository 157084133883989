<template>
	<div class="input-group">
		<button type="button" class="btn btn-light" title="Legelőre" @click.prevent="store(1, 'top')"><i class="far fa-arrow-to-top"></i></button>
		<button type="button" class="btn btn-light" title="Előre" @click.prevent="store(model.order - 1, 'custom')"><i class="far fa-arrow-up"></i></button>
		<input type="text" class="form-control text-center" v-model="order" style="width: 50px;" @keyup="keyup" />
		<button type="button" class="btn btn-light" title="Hátra" @click.prevent="store(model.order + 1, 'custom')"><i class="far fa-arrow-down"></i></button>
		<button type="button" class="btn btn-light" title="Leghátulra" @click.prevent="store(1, 'bottom')"><i class="far fa-arrow-to-bottom"></i></button>
	</div>
</template>

<script>
import ConfirmButton from "./ConfirmButton";
import {Link, router} from "@inertiajs/vue3";
import ButtonGroup from "./ButtonGroup.vue";
import {useFlashStoreStore} from "../stores/flashStore";
export default {
	emits: ['changed'],
	components: {ButtonGroup, ConfirmButton, Link},
	props: {
		model: Object,
	},
	data(){
		return {
			order: this.model.order,
			flash: useFlashStoreStore()
		}
	},
	methods: {
		keyup: function (e){
			if (e.key === 'Enter'){
				this.store(this.order, 'custom')
			}
		},
		store: function (index, position){
			let payload = {
			}
			if (index !== undefined && index !== null){
				payload.set_order_index = Math.max(0, index)
			}
			if (position !== undefined && position !== null){
				payload.set_order = position
			}
			let tmp = route().current().split('.')

			axios.post(route(tmp[0] + '.storeOrder', this.model.id), payload).then((response)=>{
				this.$emit('changed')
			}).catch((error) => {
				this.flash.addError(error)
			})
		}
	}
}
</script>

<style scoped>
.input-group {
	flex-wrap: nowrap !important;
	border: 1px solid var(--bs-input-border-color);
	border-radius: 0.25rem;
}
.input-group .form-control {
	border: 0 none !important;
}
.input-group .btn {
	padding-left: 8px;
	padding-right: 8px;
}
</style>
