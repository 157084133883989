<script>
export default {
	props: {
		task: Object
	}
}
</script>

<template>
	<template v-if="task.type === 'personal'">
		{{ task.title }}
	</template>
	<template v-else-if="task.type === 'project'">
		{{ task.details_as_array.clientName }}
	</template>
	<template v-else-if="task.type === 'documentBundleError'">
		Sikertelen generálás: {{ task.details_as_array.bundleName }}
	</template>
	<template v-else-if="task.type === 'approveDocumentBundle'">
		Jóváhagyás: {{ task.details_as_array.bundleName }}
	</template>
	<template v-else>
		{{ task.name }}
	</template>
</template>

<style scoped>

</style>
