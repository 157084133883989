<template>
	<modal
		:title="getModalTitle()"
		:loading="loading"
		:full-width="true"
		:buttons-on-top="true"
		:closable="true"
		:z-index="2100"
		@close="$emit('close')">

		<template #buttons>
			<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable" :disabled="!allCategoriesValid" @click.prevent="saveForm">
				<i class="fas fa-save label-icon"></i> Mentés
			</button>
			<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing" @click.prevent="$emit('startEditing')">
				<i class="fas fa-pencil label-icon"></i> Szerkesztés
			</button>
			<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="generateContract" v-if="editable && contract.id && contract.status === 'draft'">
				<i class="fas fa-check label-icon"></i> Lezárás
			</button>
			<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="enableDelivery" v-if="editable && contract.id && !contract.is_hidden && contract.status === 'signed' && !contract.delivery_enabled && app.hasPermission('ProjectsController_setDeliveryForContract')">
				<i class="fas fa-truck label-icon"></i> Kiszállítás engedélyezése
			</button>
			<a class="btn btn-secondary btn-label waves-effect" v-if="contract.id && contract.status !== 'draft' && contract.file_id" :href="'/projects/' + contract.project_id + '/downloadContract/' + contract.id">
				<i class="far fa-download label-icon"></i> Letöltés
			</a>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
				<i class="fas fa-times label-icon"></i> Bezár
			</button>

			<LockMessage :lock="projectDataStore.lock" :margin-bottom="false" :margin-left="true"></LockMessage>
		</template>

		<template #default>
			<div v-if="loaded">
				<div class="card">
					<div class="card-body">
						<div class="details-box mb-3" v-if="contract.id !== undefined">
							<div>
								<i class="far fa-hashtag"></i> Azonosító:
								<strong>{{ contract.public_id }}</strong>
							</div>

							<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }">
								<div v-if="dataStore.getData('contractStatusLabels')[contract.status] !== undefined">
									<i class="far fa-question"></i> Státusz:
									<span :class="'badge badge-xl bg-' + dataStore.getData('contractStatusLabels')[contract.status].color"><i :class="dataStore.getData('contractStatusLabels')[contract.status].icon"></i> {{ dataStore.getData('contractStatusLabels')[contract.status].name }}</span>
								</div>
							</data-loader>

							<date-time-attribute :value="contract.created_at" created>
								<user-badge :user-id="contract.created_by_user_id" />
							</date-time-attribute>

							<date-time-attribute :value="contract.updated_at" updated />

							<attribute v-if="originalContract !== null"
									   icon="fa-file-contract"
									   label="Eredeti szerződés"
									   >
								<project-badge
									:project-id="contract.project_id"
									:contract-id="originalContract.id"
									:project-type-id="projectDataStore.projectType.id"
									:key="'PR' + contract.project_id + '_C_' + originalContract.id"
								>
									{{ originalContract.public_id }}
								</project-badge>
							</attribute>
						</div>
						<div class="row">
							<div class="col-md-4" v-if="!contract.is_hidden">
								<InputField
										label="Kedvezmény"
										name="discount"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount"
										:disabled="!isEditable"
										:errors="{}"
										@input="productChanged"
								/>
							</div>
							<div class="col-md-4" v-if="!contract.is_hidden">
								<InputField
										label="Kedvezmény anyag"
										name="discount_material"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_material"
										:disabled="!isEditable"
										:errors="{}"
										@input="productChanged"
								/>
							</div>
							<div class="col-md-4" v-if="!contract.is_hidden">
								<InputField
										label="Kedvezmény munka"
										name="discount_work"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_work"
										:disabled="!isEditable"
										:errors="{}"
										@input="productChanged"
								/>
							</div>
							<div class="col-12">
								<TextareaField
										name="description"
										:rows="4"
										v-model="contract.description"
										:disabled="!isEditable"
										label="Megjegyzés"
								></TextareaField>
							</div>
						</div>
					</div>
				</div>
				<ProjectProductList
						:data-url="'/projects/' + projectDataStore.projectId + '/calculateContractPrices'"
						:is-editable="isEditable"
						v-model="contractProducts"
						:parent-model="contract"
						:product-versions="contract.product_versions"
						:limited-product-quantities="limitedProductQuantities"
						:initial-prices="productPrices"
						mode="contractAmendment"
						ref="productList"
						:hide-summary="!!contract.is_hidden"
						@validationChanged="validationChanged"
				/>

				<data-loader :data="['products']" v-slot="{ dataStore }">
					<div class="card border" v-if="originalContract !== null && contract.status === 'draft'">
						<div class="card-body">
							<h4 class="card-title mb-4">{{ contract.status !== 'draft' ? 'Eredeti szerződésben foglalt termékek' : 'Szerződésben foglalt termékek' }}</h4>

							<table class="table">
								<thead>
								<tr>
									<th>Termék</th>
									<th width="160" class="text-end">Menny.</th>
									<th width="1%" v-if="editable"></th>
									<th width="15%">Típus</th>
									<th width="10%" class="text-end text-nowrap">Nettó egys.</th>
									<th width="10%" class="text-end">Bruttó ár</th>
								</tr>
								</thead>
								<tbody>
								<template v-for="product in originalProducts">
									<tr v-if="product.quantity > 0">
										<td>
											<product-badge
												:product-id="product.product_id"
												:product-name="dataStore.getData('products', true)[product.product_id].name"
												:product-manufacturer-id="dataStore.getData('products', true)[product.product_id].product_manufacturer_id"
												:key="'PB' + product.product_id"
											/>
										</td>
										<td class="text-end">{{ product.quantity }} {{ dataStore.getData('products', true)[product.product_id] !== undefined ? dataStore.getData('products', true)[product.product_id].unit : 'db' }}</td>
										<td v-if="editable">
											<div class="d-flex gap-2">
												<button type="button" class="btn btn-danger btn-sm btn-label" @click.prevent="modifyProductQuantity(product.product_id, -1)">
													<i class="far fa-minus label-icon"></i> Elvesz
												</button>
												<button type="button" class="btn btn-success btn-sm btn-label" @click.prevent="modifyProductQuantity(product.product_id, 1)">
													<i class="far fa-plus label-icon"></i> Hozzáad
												</button>
											</div>
										</td>
										<td>
											<data-loader :data="['productTypes']" v-slot="{ dataStore }">
												<div v-if="product.product_id">
													<color-badge
														:value="dataStore.getData('productTypes')[dataStore.getData('products', true)[product.product_id].type]"
														small
													>

													</color-badge>
												</div>
											</data-loader>
										</td>
										<td class="text-end vertical-align-middle">
											<Price
												v-if="product.product_id && originalProductPrices[(product.order + 1)] !== undefined"
												:value="originalProductPrices[(product.order + 1)].discountNet || originalProductPrices[(product.order + 1)].net"
												:loading="pricesLoading"
											/>
										</td>
										<td class="text-end vertical-align-middle">
											<Price
												v-if="product.product_id && originalProductPrices[(product.order + 1)] !== undefined"
												:value="(originalProductPrices[(product.order + 1)].discountGross || originalProductPrices[(product.order + 1)].gross) * product.quantity"
												:loading="pricesLoading"
											/>
										</td>
									</tr>
								</template>
								</tbody>
							</table>
						</div>
					</div>
				</data-loader>
			</div>
		</template>
	</modal>
</template>

<script>
import {moveValueInArray} from "../../functions";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import InputField from "../../components/form/InputField.vue";
import ProjectProductList from "./ProjectProductList.vue";
import Price from "../../components/Price.vue";
import vSelect from "vue-select";
import FileList from "../../components/FileList.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import LockMessage from "../../components/LockMessage.vue";
import UserBadge from "../../components/badges/UserBadge.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import {useProjectDataStore} from "../../stores/projectData";
import {useGeneralDataStore} from "../../stores/generalData";

export default {
	components: {
		ColorBadge,
		DataLoader,
		UserBadge,
		LockMessage, TextareaField, FileList, Price, ProjectProductList, InputField, ProjectInfoSidebar, vSelect},
	emits: ['close', 'closeAndReload', 'generate', 'updateStats', 'startEditing'],
	props: {
		editable: Boolean,
		originalContractId: Number,
		canStartEditing: Boolean,
		isHidden: Boolean,
		contractId: {
			type: [Number, String],
			default: 0
		}
	},
	mounted() {
		this.dataStore.loadData('products', () => {
			this.loadData()
		})
	},
	computed: {
		isEditable: {
			get(){
				if (!this.editable){
					return false
				}
				return this.contract.editable
			}
		},
		limitedProductQuantities: {
			get(){
				let payload = {}
				if (this.originalProducts !== null && this.originalProducts !== undefined) {
					_.forEach(this.originalProducts, function(product){
						payload[product.product_id] = {
							min: -1 * product.quantity
						}
					})
				}

				return payload
			}
		},
	},
	data() {
		return {
			contract: {
				product_versions: [],
				is_hidden: this.isHidden ? 1 : 0
			},
			loading: false,
			installation: {},
			productPrices: {},
			originalProductPrices: {},
			allCategoriesValid: false,
			pricesLoading: false,
			pricesLoadingCancelToken: null,
			priceCalculationRequired: false,
			contractProducts: [],
			paymentPlans: [],
			originalContract: null,
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			dataStore: useGeneralDataStore(),
			app: useApplicationStore(),
			originalProducts: null,
			loaded: false
		};
	},
	methods: {
		moveValueInArray: moveValueInArray,
		getDataUrl: function(){
			let url = '/projects/' + this.projectDataStore.projectId + '/contractAmendment'
			if (this.contractId) {
				url += '/' + this.contractId
			} else if (this.originalContractId) {
				url += '/' + this.originalContractId
			}

			return url
		},
		loadData: function () {
			this.loading = true

			axios.get(this.getDataUrl()).then((response) => {
				this.handleResponse(response)
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		productChanged: function(){
			this.$refs.productList.productChanged()
		},
		generateContract: function(){
			this.loading = true
			this.$emit('generate', this.contractId)
		},
		enableDelivery: function(){
			this.loading = true
			this.$emit('enableDelivery', this.contractId)
		},
		emitClose: function(){
			this.$emit('close')
		},
		saveForm: function(e){
			if (this.allCategoriesValid){
				this.loading = true

				let payload = {
					contract: this.contract,
					products: this.$refs.productList.getFilteredProducts()
				}

				axios.post(this.getDataUrl(), payload).then((response)=>{
					this.handleResponse(response)
				}).catch((error) => {
					this.flashStore.addError(error)
				})
			} else {
				this.flashStore.addFlash({level: 'danger', message: 'Kérlek töltsd ki a pirossal jelölt mezőket!'})
			}
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status === undefined || response.data.status === 'success') {
				this.loaded = true
				this.originalContract = response.data.originalContract
				this.originalProductPrices = response.data.originalPrices
				this.originalProducts = response.data.originalProducts
				this.contract = response.data.contract
				this.productPrices = response.data.prices
				this.contractProducts = this.contract.products_pivot
				this.paymentPlans = response.data.paymentPlans
				if (this.contract.id === undefined && this.isHidden){
					this.contract.is_hidden = this.isHidden
				}
				if (response.data.installation !== undefined){
					this.installation = response.data.installation
				}

				//this.$refs.productList.setProductPrices(response.data.prices)

				if (response.data.status !== undefined && response.data.status === 'success') {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}
		},
		getModalTitle: function (){
			if (this.contract.id !== undefined){
				if (this.contract.is_hidden){
					return this.editable ? 'Rejtett szerződésmódosítás szerkesztése' : 'Rejtett szerződésmódosítás'
				}
				return this.editable ? 'Szerződésmódosítás szerkesztése' : 'Szerződésmódosítás'
			}

			return this.isHidden ? 'Rejtett szerződésmódosítás létrehozása' : 'Szerződésmódosítás létrehozása'
		},
		validationChanged: function (valid){
			this.allCategoriesValid = valid
		},
		modifyProductQuantity: function (productId, quantityModifier){
			this.$refs.productList.modifyProductQuantity(productId, quantityModifier)
		}
	}
}
</script>

<style scoped>

</style>
<script setup>
import Modal from "../../components/Modal.vue";
import DateTime from "../../components/DateTime.vue";
import ProductBadge from "../../components/badges/ProductBadge.vue";
import Attribute from "../../components/Attribute.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";
import UserAttribute from "../../components/attributes/UserAttribute.vue";
import DateTimeAttribute from "../../components/attributes/DateTimeAttribute.vue";
</script>
