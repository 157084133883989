<template>
	<global-flash-display :initial="[]"></global-flash-display>
	<div class="container-fluid p-0">
		<div class="row g-0">

			<div class="col-xxl-6 col-lg-6">
				<div class="auth-full-bg pt-lg-5 p-4">
					<div class="w-100">
						<div class="bg-overlay"></div>
					</div>
				</div>
			</div>

			<div class="col-xxl-6 col-lg-6">
				<div class="auth-full-page-content p-md-5 p-4">
					<div class="w-100">

						<div class="d-flex flex-column h-100">
							<div class="mb-4 mb-md-5">
								<svg enable-background="new 0 0 150 25" version="1.1" viewBox="0 0 150 25" width="150px" height="25px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
									<path style="fill:#2A3042;" d="m37.12 0c-1.01 0-1.83 0.82-1.83 1.83 0 2.96-1.64 7.53-6.11 12-4.47-4.47-6.11-9.04-6.11-12 0-1.01-0.82-1.83-1.83-1.83s-1.83 0.82-1.83 1.83c0 4.91 2.86 10.47 7.72 15.12 0.36 0.34 0.72 0.68 1.1 1.01 0 0 0.01 0 0.01 0.01 0.54 0.46 1.35 0.46 1.88 0 0 0 0.01 0 0.01-0.01 0.38-0.33 0.74-0.67 1.1-1.01 4.86-4.65 7.72-10.21 7.72-15.12 0-1.01-0.82-1.83-1.83-1.83z"></path>
									<path style="fill:#2A3042;" d="m18.27 8.38v-0.04-0.05-0.01c-0.23-2.25-1.27-4.32-2.94-5.85-1.69-1.54-3.88-2.39-6.16-2.39-5.06 0.01-9.17 4.12-9.17 9.16 0 5.05 4.11 9.15 9.15 9.15 2.44 0 4.74-0.95 6.47-2.68 0.71-0.71 0.71-1.87 0-2.59-0.71-0.71-1.87-0.71-2.59 0-1.04 1.04-2.42 1.61-3.88 1.61-2.18 0-4.06-1.28-4.95-3.12l12.43-1.28c0.01 0 0.03 0 0.04-0.01 0.02 0 0.03 0 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.01-0.01 0.03-0.01 0.04-0.02 0.01 0 0.03-0.01 0.04-0.02s0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.02-0.01 0.04-0.02c0.01-0.01 0.03-0.02 0.04-0.02 0.01-0.01 0.02-0.02 0.04-0.02 0.01-0.01 0.03-0.02 0.04-0.02 0.01-0.01 0.02-0.02 0.03-0.02 0.01-0.01 0.03-0.02 0.04-0.03l0.03-0.03c0.01-0.01 0.02-0.02 0.04-0.03l0.03-0.03c0.01-0.01 0.02-0.02 0.04-0.03l0.09-0.09c0.01-0.01 0.02-0.02 0.03-0.04 0.01-0.01 0.02-0.02 0.02-0.03 0.01-0.01 0.02-0.03 0.03-0.04s0.01-0.02 0.02-0.03 0.02-0.03 0.03-0.04 0.01-0.02 0.02-0.03 0.02-0.03 0.03-0.04 0.01-0.02 0.02-0.03c0.01-0.02 0.02-0.03 0.03-0.05 0.01-0.01 0.01-0.02 0.02-0.03 0.01-0.02 0.02-0.03 0.03-0.05 0.01-0.01 0.01-0.02 0.02-0.03 0.01-0.02 0.02-0.03 0.02-0.05 0-0.01 0.01-0.02 0.01-0.03 0.01-0.02 0.02-0.03 0.02-0.05 0-0.01 0.01-0.02 0.01-0.03 0.01-0.02 0.01-0.03 0.02-0.05 0-0.01 0.01-0.02 0.01-0.03 0.01-0.02 0.01-0.04 0.02-0.05 0-0.01 0.01-0.02 0.01-0.03 0.01-0.02 0.01-0.04 0.01-0.05s0.01-0.02 0.01-0.03c0-0.02 0.01-0.04 0.01-0.05s0-0.02 0.01-0.04c0-0.02 0.01-0.04 0.01-0.05v-0.04c0-0.02 0-0.04 0.01-0.05v-0.04-0.05-0.04-0.05-0.04c0.02-0.01 0.02-0.03 0.02-0.05zm-14.46-0.44c0.57-2.42 2.75-4.22 5.34-4.22 2.2 0 4.1 1.27 4.98 3.16l-10.32 1.06z"></path>
									<path style="fill:#2A3042;" d="m58.58 8.38v-0.06-0.03-0.01c-0.23-2.24-1.27-4.32-2.94-5.84-1.69-1.54-3.88-2.39-6.16-2.39-5.05 0-9.15 4.11-9.15 9.15 0 5.05 4.11 9.15 9.15 9.15 2.44 0 4.74-0.95 6.47-2.68 0.71-0.71 0.71-1.87 0-2.59-0.71-0.71-1.87-0.71-2.59 0-1.04 1.04-2.42 1.61-3.88 1.61-2.18 0-4.06-1.28-4.95-3.12l12.43-1.28c0.01 0 0.03 0 0.04-0.01 0.02 0 0.03 0 0.05-0.01 0.02 0 0.03-0.01 0.05-0.01s0.03-0.01 0.05-0.01c0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.02 0 0.03-0.01 0.05-0.01 0.01 0 0.03-0.01 0.04-0.01 0.01-0.01 0.03-0.01 0.04-0.02s0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.01 0.04-0.02 0.03-0.02 0.04-0.02c0.01-0.01 0.03-0.01 0.04-0.02s0.03-0.02 0.04-0.02c0.01-0.01 0.02-0.02 0.04-0.02 0.01-0.01 0.02-0.02 0.04-0.03 0.01-0.01 0.02-0.02 0.04-0.03l0.31-0.31c0.01-0.01 0.02-0.02 0.03-0.04 0.01-0.01 0.02-0.02 0.02-0.03 0.01-0.01 0.02-0.02 0.03-0.04 0.01-0.01 0.01-0.02 0.02-0.04 0.01-0.01 0.02-0.03 0.02-0.04 0.01-0.01 0.01-0.02 0.02-0.04 0.01-0.01 0.02-0.03 0.02-0.04 0.01-0.01 0.01-0.02 0.02-0.04 0.01-0.01 0.01-0.03 0.02-0.04s0.01-0.03 0.02-0.04 0.01-0.03 0.02-0.04 0.01-0.03 0.02-0.04 0.01-0.03 0.02-0.04 0.01-0.03 0.01-0.04 0.01-0.03 0.01-0.04 0.01-0.03 0.01-0.04 0.01-0.03 0.01-0.04c0-0.02 0.01-0.03 0.01-0.05 0-0.01 0.01-0.03 0.01-0.04 0-0.02 0.01-0.03 0.01-0.05 0-0.01 0-0.03 0.01-0.04 0-0.02 0-0.03 0.01-0.05v-0.04-0.05-0.04-0.06-0.03-0.06c0.01-0.03 0.01-0.04 0.01-0.05zm-14.45-0.44c0.57-2.42 2.75-4.22 5.34-4.22 2.2 0 4.1 1.27 4.98 3.16l-10.32 1.06z"></path>
									<path style="fill:#2A3042;" d="m80.64 0.05c-5.06 0-9.18 4.12-9.18 9.18s4.12 9.18 9.18 9.18c1.71 0 3.31-0.47 4.68-1.29l-0.36 3.23-9.64 1c-1.01 0.1-1.74 1-1.63 2.01 0.09 0.94 0.88 1.64 1.81 1.64 0.06 0 0.13 0 0.19-0.01l11.13-1.15c0.96-0.1 1.67-0.92 1.64-1.88l1.34-11.9c0.01-0.06 0.01-0.13 0.01-0.19 0-0.04 0-0.09-0.01-0.13 0.01-0.17 0.02-0.34 0.02-0.52 0-5.05-4.12-9.17-9.18-9.17zm0 14.7c-3.04 0-5.52-2.48-5.52-5.52s2.48-5.52 5.52-5.52 5.52 2.48 5.52 5.52-2.48 5.52-5.52 5.52z"></path>
									<path style="fill:#2A3042;" d="m101.22 0.05c-5.06 0-9.18 4.12-9.18 9.18s4.12 9.18 9.18 9.18 9.18-4.12 9.18-9.18-4.12-9.18-9.18-9.18zm0 14.7c-3.04 0-5.52-2.48-5.52-5.52s2.48-5.52 5.52-5.52 5.52 2.48 5.52 5.52c-0.01 3.04-2.48 5.52-5.52 5.52z"></path>
									<path style="fill:#2A3042;" d="m69.72 0.53c-5.06 0-9.18 4.12-9.18 9.18v0.11 0.11 6.14c0 1.01 0.82 1.83 1.83 1.83s1.83-0.82 1.83-1.83v-6.14-0.11-0.11c0-3.04 2.48-5.52 5.52-5.52 1.01 0 1.83-0.82 1.83-1.83s-0.82-1.83-1.83-1.83z"></path>
									<path style="fill:#2A3042;" d="m112.99 0.87h-0.37c-0.01 0-0.02 0.01-0.02 0.02v1.49c0 0.15 0.03 0.25 0.08 0.31s0.13 0.08 0.24 0.08h0.05c0.03 0 0.05 0.02 0.05 0.05v0.21c0 0.03-0.02 0.05-0.05 0.05h-0.12c-0.19 0-0.34-0.04-0.43-0.12-0.1-0.08-0.15-0.24-0.15-0.47v-1.6c0-0.01-0.01-0.02-0.02-0.02h-0.19c-0.03 0-0.05-0.02-0.05-0.05v-0.16c0-0.03 0.02-0.05 0.05-0.05h0.19c0.01 0 0.02-0.01 0.02-0.02v-0.54c0-0.03 0.02-0.05 0.05-0.05h0.24c0.03 0 0.05 0.02 0.05 0.05v0.54c0 0.01 0.01 0.02 0.02 0.02h0.37c0.03 0 0.05 0.02 0.05 0.05v0.16c-0.02 0.03-0.03 0.05-0.06 0.05z"></path>
									<path style="fill:#2A3042;" d="m115.88 0.79c0.11 0.14 0.16 0.34 0.16 0.59v1.64c0 0.03-0.02 0.05-0.05 0.05h-0.24c-0.03 0-0.05-0.02-0.05-0.05v-1.61c0-0.17-0.03-0.3-0.1-0.39-0.07-0.1-0.16-0.14-0.27-0.14-0.12 0-0.22 0.05-0.29 0.14s-0.11 0.22-0.11 0.38v1.62c0 0.03-0.02 0.05-0.05 0.05h-0.25c-0.03 0-0.05-0.02-0.05-0.05v-1.61c0-0.17-0.04-0.3-0.1-0.39-0.07-0.1-0.16-0.14-0.27-0.14-0.12 0-0.22 0.05-0.29 0.14s-0.11 0.22-0.11 0.38v1.62c0 0.03-0.02 0.05-0.05 0.05h-0.24c-0.03 0-0.05-0.02-0.05-0.05v-2.36c0-0.03 0.02-0.05 0.05-0.05h0.24c0.03 0 0.05 0.02 0.05 0.05v0.15c0 0.01 0 0.01 0.01 0.01 0 0 0.01 0 0.02-0.01 0.06-0.08 0.13-0.14 0.22-0.18s0.17-0.06 0.27-0.06c0.12 0 0.23 0.03 0.32 0.09s0.16 0.14 0.21 0.25c0 0.01 0.01 0.01 0.01 0.01 0.01 0 0.01 0 0.01-0.01 0.06-0.12 0.14-0.2 0.24-0.26s0.21-0.08 0.32-0.08c0.19 0.01 0.34 0.08 0.44 0.22z"></path>
									<path style="fill:#7288A2;" d="m122.1 12.32c0.33-0.6 0.78-1.07 1.36-1.4s1.24-0.5 1.99-0.5c0.96 0 1.76 0.24 2.39 0.72s1.05 1.16 1.27 2.02h-2.11c-0.11-0.34-0.3-0.6-0.57-0.79s-0.6-0.29-0.99-0.29c-0.56 0-1 0.2-1.33 0.61s-0.49 0.98-0.49 1.73c0 0.74 0.16 1.31 0.49 1.71 0.33 0.41 0.77 0.61 1.33 0.61 0.79 0 1.31-0.35 1.55-1.06h2.11c-0.21 0.84-0.64 1.51-1.27 2-0.63 0.5-1.43 0.74-2.38 0.74-0.75 0-1.41-0.17-1.99-0.5s-1.03-0.8-1.36-1.4-0.49-1.3-0.49-2.11 0.16-1.48 0.49-2.09z"></path>
									<path style="fill:#7288A2;" d="m133.49 10.79c0.41-0.23 0.87-0.35 1.39-0.35v2.06h-0.52c-0.62 0-1.08 0.15-1.39 0.43-0.31 0.29-0.47 0.79-0.47 1.51v3.86h-1.96v-7.76h1.96v1.2c0.25-0.39 0.58-0.72 0.99-0.95z"></path>
									<path style="fill:#7288A2;" d="m148.13 11.32c0.58 0.58 0.88 1.4 0.88 2.44v4.55h-1.96v-4.28c0-0.61-0.15-1.07-0.46-1.39s-0.73-0.48-1.26-0.48-0.95 0.16-1.27 0.48c-0.31 0.32-0.47 0.79-0.47 1.39v4.28h-1.96v-4.28c0-0.61-0.15-1.07-0.46-1.39s-0.73-0.48-1.26-0.48c-0.54 0-0.97 0.16-1.28 0.48s-0.47 0.79-0.47 1.39v4.28h-1.96v-7.76h1.96v0.94c0.25-0.33 0.58-0.58 0.97-0.77 0.4-0.19 0.83-0.28 1.31-0.28 0.61 0 1.15 0.13 1.62 0.39s0.84 0.62 1.11 1.1c0.25-0.45 0.62-0.81 1.1-1.08s1-0.41 1.56-0.41c0.95 0 1.72 0.29 2.3 0.88z"></path>
								</svg>
								<EnvironmentBadge />
								<data-loader :data="['activeLanguages']" v-slot="{ dataStore }">
									<div class="d-flex gap-2 mt-2 language-bar" v-if="dataStore.getData('activeLanguages').length > 1">
										<Link
											:href="'?setLanguage=' + language.id"
											v-for="language in dataStore.getData('activeLanguages')"
											:only="['locale']"
											:class="{'text-uppercase': 1, 'fw-bold': 1, 'active': language.code === app.locale}">
											{{ language.short_name ? language.short_name : language.name }}
										</Link>
									</div>
								</data-loader>
							</div>
							<div class="my-auto">
								<slot/>
							</div>

							<div class="mt-4 mt-md-5 text-center">
								<p class="mb-0">© 2023 {{ app.appName }}</p>
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EnvironmentBadge from "./EnvironmentBadge.vue";
import GlobalFlashDisplay from "../components/GlobalFlashDisplay.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link, router, usePage} from "@inertiajs/vue3";
import DataLoader from "../components/DataLoader.vue";
import {loadLanguageAsync} from "laravel-vue-i18n";

export default {
	components: {DataLoader, GlobalFlashDisplay, EnvironmentBadge, Link},
	data(){
		return {
			app: useApplicationStore(),
			page: usePage()
		}
	},
	watch: {
		'page.props': {
			handler(newVal, oldVal){
				this.app.updateFromInertia(this.page.props)
				if (this.page.props.locale !== undefined){
					loadLanguageAsync(this.page.props.locale)
				}
			},
			deep: true
		}
	},
	mounted() {
		this.app.updateFromInertia(this.page.props)
	},
}
</script>
