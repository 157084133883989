<script setup>
import Price from "../../components/Price.vue";
import DateTime from "../../components/DateTime.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import Attribute from "../../components/Attribute.vue";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import DataLoader from "../../components/DataLoader.vue";

const model = defineModel()
let columns = [
	{
		text: 'Azonosító',
		value: 'invoice_number',
		sortable: true,
	},
	{
		text: 'Összeg',
		value: 'amount',
		align: 'right',
		sortable: true,
	},
	{
		text: 'Típus',
		value: 'type',
		sortable: true,
	},
	{
		text: 'Fizetés',
		value: 'payment_method',
		sortable: true,
	},
	{
		text: 'Dátum',
		value: 'date',
		sortable: true,
	},
	{
		text: 'Határidő',
		value: 'deadline',
		sortable: true,
	},
	{
		text: 'Fizetve',
		value: 'paid_at',
		sortable: true,
	},
	{
		label: '',
		value: 'operations',
		width: 5,
	}
]
</script>

<template>
	<AdvancedDataTable
		:data-url="'/sales/invoices/' + model"
		:columns="columns"
		sort-by="created_at"
		ref="table"
		sort-type="desc"
		name="invoices"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.invoice_number }}</strong>

						<attribute icon="far fa-clock" label="Dátum">
							<date-time :value="item.date" :time="false" />
						</attribute>
						<attribute icon="far fa-clock" :color="item.expired ? 'danger' : 'success'" label="Határidő">
							<date-time :value="item.deadline" :time="false"/>
						</attribute>

						<div class="mobile-horizontal">
							<data-loader :data="['invoiceTypeLabels']" v-slot="{ dataStore }">
								<color-badge :value="dataStore.getData('invoiceTypeLabels')[item.type]"></color-badge>
							</data-loader>

							<data-loader :data="['paymentMethods']" v-slot="{ dataStore }">
								<color-badge :value="dataStore.getData('paymentMethods')[item.payment_method]"></color-badge>
							</data-loader>

							<color-badge
								:color="item.paid_at === null ? 'warning' : 'success'"
								:icon="item.paid_at === null ? 'far fa-hourglass' : 'far fa-check'"
							>
								<template v-if="item.paid_at === null">
									Nincs fizetve
								</template>
								<template v-else>
									<date-time :value="item.paid_at" :time="false" />
								</template>
							</color-badge>
						</div>
					</div>
				</div>
				<div class="table-row-operations d-flex gap-2">
					<template v-if="item.file_id">
						<a class="btn btn-secondary btn-sm waves-effect" :href="'/sales/downloadInvoice/' + item.id" title="Letöltés">
							<i class="far fa-download label-icon"></i>
						</a>
					</template>
					<span class="badge bg-warning" v-else>Generálásra vár</span>
				</div>
			</div>
		</template>
		<template #item-invoice_number="item">
			{{item.invoice_number}}
		</template>
		<template #item-type="item">
			<data-loader :data="['invoiceTypeLabels']" v-slot="{ dataStore }">
				<color-badge :value="dataStore.getData('invoiceTypeLabels')[item.type]" small></color-badge>
			</data-loader>
		</template>
		<template #item-payment_method="item">
			<data-loader :data="['paymentMethods']" v-slot="{ dataStore }">
				<color-badge :value="dataStore.getData('paymentMethods')[item.payment_method]" small></color-badge>
			</data-loader>
		</template>
		<template #item-amount="item">
			<Price
				:value="item.amount"
				:currency-id="item.currency_id"
			/>
		</template>
		<template #item-paid_at="item">
			<color-badge
				:color="item.paid_at === null ? 'warning' : 'success'"
				:icon="item.paid_at === null ? 'far fa-hourglass' : 'far fa-check'"
				small
			>
				<template v-if="item.paid_at !== null">
					<date-time :value="item.paid_at" :time="false" />
				</template>
			</color-badge>
		</template>
		<template #item-created_at="item">
			<date-time :value="item.date" :time="false" />
		</template>
		<template #item-deadline="item">
			<attribute :color="item.expired ? 'danger' : 'success'">
				<date-time :value="item.deadline" :time="false"/>
			</attribute>
		</template>
	</AdvancedDataTable>
</template>

<style scoped>

</style>
