<template>
	<TitleBar title="Űrlap kategóriák" />

	<AdvancedDataTable
		:data-url="route('formCategories.list')"
		:columns="columns"
		ref="dataTable"
		sort-by="order"
		sort-type="asc"
		name="formCategories"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('FormCategoriesController_create')">
			<Link :href="route('formCategories.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>

		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<order-cell :model="item" @changed="$refs.dataTable.getData" :key="'orderCell_' + item.id + '_' + item.order"/>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('formCategories.update')"
					:delete-url="route('formCategories.delete')"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
		<template #item-order="item">
			<order-cell :model="item" @changed="$refs.dataTable.getData" :key="'orderCell_' + item.id + '_' + item.order"/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import TableActionCell from "../components/TableActionCell.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import {Link} from "@inertiajs/vue3";
import {useApplicationStore} from "../stores/applicationStore";
import ColorBadge from "../components/ColorBadge.vue";
import Attribute from "../components/Attribute.vue";
import Number from "../components/Number.vue";
import OrderCell from "../components/OrderCell.vue";

export default {
	components: {OrderCell, Number, Attribute, ColorBadge, AdvancedDataTable, TableActionCell, TitleBar, Link},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Sorrend',
				value: 'order',
				width: 15
			},
			{
				text: 'Név',
				value: 'name',
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
