<template>
	<TitleBar title="Kiszállítás" />

	<AdvancedDataTable
		:data-url="route('deliveryRoutes.list')"
		:columns="columns"
		sort-by="date"
		sort-type="desc"
		name="deliveryRoutes"
		show-tracked-edit-mode
		ref="table"
	>
		<template #navigation v-if="app.hasPermission('DeliveryRoutesController_create')">
			<Link :href="route('deliveryRoutes.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong><date-time :value="item.date" :time="false"/></strong>
						<div class="mobile-horizontal">
							<vehicle-label :vehicle-id="item.vehicle_id"/>
							<warehouse-badge :warehouse-id="item.warehouse_id"/>
						</div>

						<boolean-badge v-model="item.is_completed" completed/>

						<div class="mobile-horizontal">
							<attribute label="Megállók" icon="far fa-map-marker">
								<number :value="item.stops" />
							</attribute>
							<attribute icon="far fa-user" label="Sofőr">
								{{ item.driver }}
							</attribute>
						</div>
						<div class="mobile-horizontal">
							<attribute icon="far fa-ruler" label="Távolság">
								<number :value="item.distance_total" suffix="km"/>
							</attribute>
							<attribute icon="far fa-stopwatch" label="Utazási idő">
								<duration :value="item.duration_total"/>
							</attribute>
						</div>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('deliveryRoutes.update')"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
		<template #item-total_distance="item">
			<div class="stacked-cell">
				<attribute icon="far fa-ruler">
					<number :value="item.distance_total / 1000" suffix="km" round/>
				</attribute>
				<attribute icon="far fa-stopwatch">
					<duration :value="item.duration_total"/>
				</attribute>
			</div>
		</template>
		<template #item-date="item">
			<date-time :value="item.date" :time="false"/>
		</template>
		<template #item-vehicle_id="item">
			<vehicle-label :vehicle-id="item.vehicle_id"/>
		</template>
		<template #item-warehouse_id="item">
			<warehouse-badge :warehouse-id="item.warehouse_id"/>
		</template>
		<template #item-is_completed="item">
			<boolean-badge v-model="item.is_completed"/>
		</template>
		<template #item-stops="item">
			<number :value="item.stops" />
		</template>
	</AdvancedDataTable>
</template>

<script>
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import vSelect from "vue-select";
import {useGeneralDataStore} from "../stores/generalData";
import {useApplicationStore} from "../stores/applicationStore";
import Number from "../components/Number.vue";
import DateTime from "../components/DateTime.vue";
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {Link} from "@inertiajs/vue3";
import ColorBadge from "../components/ColorBadge.vue";
import Attribute from "../components/Attribute.vue";
import TableActionCell from "../components/TableActionCell.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import Duration from "../components/Duration.vue";
import VehicleLabel from "../components/VehicleLabel.vue";
import WarehouseBadge from "../components/badges/WarehouseBadge.vue";

export default {
	layout: AppLayout,
	components: {
		WarehouseBadge,
		VehicleLabel,
		Duration,
		BooleanBadge,
		TableActionCell,
		Attribute, ColorBadge, TitleBar, DateTime, Link, Number, vSelect, DataLoader, AdvancedDataTable},
	data(){
		let columns = [
			{
				text: 'Teljesítve',
				value: 'is_completed'
			},
			{
				text: 'Dátum',
				value: 'date'
			},
			{
				text: 'Jármű',
				value: 'vehicle_id',
				sortable: true
			},
			{
				text: 'Raktár',
				value: 'warehouse_id',
				sortable: true,
			},
			{
				text: 'Sofőr',
				value: 'driver',
				sortable: true
			},
			{
				text: 'Állomások',
				value: 'stops',
				sortable: true
			},
			{
				text: 'Teljes úthossz',
				value: 'total_distance',
				sortable: true
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]
		return {
			columns: columns,
			dataStore: useGeneralDataStore(),
			app: useApplicationStore(),
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>
