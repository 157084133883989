<template>
	<button type="button" class="btn btn-sm px-3 font-size-16 header-item" @click.prevent="toggle">
		<i class="fa fa-fw fa-bars"></i>
	</button>
</template>

<script>
import {useApplicationStore} from "../stores/applicationStore";
import {usePermanentStore} from "../stores/permanentStore";

export default {
	data(){
		return {
			app: useApplicationStore(),
			store: usePermanentStore()
		}
	},
	methods: {
		toggle: function(){
			this.app.mobileMenuOpen = !this.app.mobileMenuOpen
			this.app.menuOpen = !this.app.menuOpen
			this.store.set('menuOpen', this.app.menuOpen);
		}
	}
}
</script>

<style scoped>

</style>
