<template>
	<div class="dropdown d-inline-block">
		<button type="button" class="btn header-item noti-icon waves-effect topbar-button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i class="far fa-list"></i>
			<span class="badge bg-primary rounded-pill" v-if="app.taskCount">{{ app.taskCount }}</span>
		</button>
		<div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
			 aria-labelledby="page-header-tasks-dropdown">
			<div class="p-3">
				<div class="row align-items-center">
					<div class="col">
						<h6 class="m-0" key="t-tasks"> Feladatok </h6>
					</div>
				</div>
			</div>
			<div style="max-height: 300px;overflow-y:scroll;">
				<div class="d-flex flex-column gap-1">
					<template v-for="task in app.tasks">
						<task-list-item :task="task"/>
					</template>
				</div>
				<div class="alert alert-success m-3" v-if="!app.tasks.length">
					Jelenleg nincs feladatod.
				</div>
			</div>
			<div class="p-2 border-top d-grid gap-3 flex-column align-items-center justify-content-center">
				<a class="text-primary" href="#" v-if="app.hasPermission('TasksController_create') || app.hasPermission('TasksController_createForUser')" @click.prevent="addFormVisible = true">
					<i class="far fa-plus"></i> Feladat létrehozása
				</a>
				<Link class="text-secondary" href="/tasks">
					<i class="far fa-angle-right me-1"></i> <span key="t-view-more">Összes megtekintése</span>
				</Link>
			</div>
		</div>
	</div>
	<task-modal
		v-if="addFormVisible"
		@close="addFormVisible = false"
		@closeAndReload="addFormVisible = false"
	/>
</template>

<script>
import RelativeTime from "./RelativeTime.vue";
import UserSelectField from "./form/UserSelectField.vue";
import FormWrapper from "./FormWrapper.vue";
import { useGeneralDataStore } from "../stores/generalData";
import DataLoader from "./DataLoader.vue";
import DateField from "./form/DateField.vue";
import TextareaField from "./form/TextareaField.vue";
import InputField from "./form/InputField.vue";
import { usePage, Link } from '@inertiajs/vue3';
import {useApplicationStore} from "../stores/applicationStore";
import UserBadge from "./badges/UserBadge.vue";
import ColorBadge from "./ColorBadge.vue";
import TaskModal from "../modules/dashboard/TaskModal.vue";
import TaskListItem from "./TaskListItem.vue";

export default {
	components: {
		TaskListItem,
		TaskModal,
		ColorBadge, UserBadge,
		InputField,
		TextareaField, DateField, DataLoader, FormWrapper, UserSelectField, RelativeTime, Link},
	props: {
		selectedTaskId: Number
	},
	data(){
		const page = usePage()
		console.log(this.selectedTaskId)
		return {
			addFormVisible: false,
			page: page,
			dataStore: useGeneralDataStore(),
			app: useApplicationStore()
		}
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
