import { defineStore } from 'pinia'
import {useFlashStoreStore} from "./flashStore";
import {router} from "@inertiajs/vue3";
import {usePermanentStore} from "./permanentStore";

export const useApplicationStore = defineStore('applicationStore', {
	state: () => {
		const store = usePermanentStore()

		return {
			loaded: false,
			loading: true,
			permissions: [],
			modules: [],
			userId: null,
			userInitials: null,
			userName: null,
			clientUserId: null,
			clientUserName: null,
			isClientArea: false,
			environment: null,
			appName: null,
			customerServicePhone: '',
			customerServiceEmail: '',
			companyName: '',
			companyAddress: '',
			locale: 'hu',
			notifications: [],
			notificationCount: 0,
			clientNotifications: [],
			clientNotificationCount: 0,
			requestCounter: 0,
			tasks: [],
			flash: [],
			taskCount: 0,
			badges: {},
			auth: false,
			flashStore: useFlashStoreStore(),
			loadedCallbacks: [],
			mobileMenuOpen: false,
			isMobile: window.innerWidth < 768,
			menuOpen: store.get('menuOpen', false),
			timeout: null
		}
	},
	getters: {
		isLoading() {
			return !!this.loading
		}
	},
	actions: {
		hasPermission(names){
			if (!_.isArray(names)){
				names = [names]
			}
			for (let i in names){
				if (this.permissions.indexOf(names[i]) > -1){
					return true
				}
			}
			return false
		},
		handleResize() {
			this.isMobile = window.innerWidth < 768
		},
		updateFromInertia(properties){
			let attributes = [
				'auth',
				'locale',
				'flash',
				'permissions',
				'badges',
				'userId',
				'userInitials',
				'userName',
				'clientUserId',
				'clientUserName',
				'isClientArea',
				'modules',
				'environment',
				'appName',
				'notifications',
				'notificationCount',
				'clientNotifications',
				'clientNotificationCount',
				'tasks',
				'taskCount',
				'customerServicePhone',
				'customerServiceEmail',
				'companyName',
				'companyAddress',
			]
			for (let i = 0; i < attributes.length; i++){
				if (properties[attributes[i]] !== undefined){
					this[attributes[i]] = properties[attributes[i]]
				}
			}
			if (properties.flash !== undefined && properties.flash.length){
				this.flashStore.addFlash(properties.flash)
			}

			if (this.auth !== false){
				this.loading = false
				if (!this.loaded) {
					this.loaded = true

					this.initialLoadCompleted()
				}

				_.forEach(this.loadedCallbacks, function (cb){
					cb()
				})

				this.loadedCallbacks = []
			}
		},
		onLoaded(callback){
			if (this.loaded){
				callback()
			}

			this.loadedCallbacks.push(callback)
		},
		initialLoadCompleted(){
			try {
				axios.interceptors.request.use( (config) => {
					this.incRequestCounter()
					return config;
				}, (error) => {
					this.decRequestCounter()
					return Promise.reject(error);
				});

				axios.interceptors.response.use((response) => {
					this.decRequestCounter()
					return response;
				}, (error) => {
					this.decRequestCounter()
					return Promise.reject(error);
				});

				document.addEventListener('click', (e) => {
					if (this.mobileMenuOpen) {
						if (!e.target.closest('.vertical-menu') && !e.target.closest('header')) {
							this.mobileMenuOpen = false
						}
					}
				})

				setInterval(() => {
					if (this.auth !== 'guest' && location.pathname !== '/') {
						this.reloadTasks()
						this.reloadNotifications()
					}
				}, 60000)

				window.addEventListener('resize', this.handleResize);
			} catch (e){
				console.log(e)
			}
		},
		incRequestCounter(payload){
			this.requestCounter++

			if (this.timeout === null) {
				this.timeout = setTimeout(() => {
					this.loading = true
				}, 400)
			}
		},
		decRequestCounter(payload){
			if (this.requestCounter){
				this.requestCounter--
			}
			if (!this.requestCounter){
				this.loading = false
				if (this.timeout !== null) {
					clearTimeout(this.timeout)
					this.timeout = null
				}
			}
		},
		reloadTasks() {
			const t = this
			axios.get('/tasks/get').then(function (response){
				t.tasks = response.data.tasks
				t.taskCount = response.data.taskCount
			})
		},
		reloadNotifications() {
			const t = this
			axios.get('/notifications/get').then(function (response){
				t.notifications = response.data.notifications
				t.notificationCount = response.data.notificationCount
			})
		},
		responsiveDisplayable(allowMobile, allowDesktop){
			allowDesktop = allowDesktop || false
			allowMobile = allowMobile || false

			if (allowDesktop && this.isMobile){
				return false
			}
			if (allowMobile && !this.isMobile){
				return false
			}
			return true
		}
	},
})
