<template>
	<TitleBar title="Dokumentumok" />

	<div class="card">
		<div class="card-body">
			<div class="d-flex flex-wrap gap-2 mb-3">
				<Link :href="route('documents.create')" v-if="app.hasPermission('DocumentsController_create')" class="btn btn-primary btn-label"><i class="fas fa-file-plus label-icon"></i> File feltöltés</Link>
				<Link :href="route('documents.category')" v-if="app.hasPermission('DocumentsController_categories')" class="btn btn-secondary btn-label"><i class="fas fa-folder-plus label-icon"></i> Kategória hozzáadása</Link>
			</div>

			<div class="row mt-4">
				<div class="col-md-4">
					<ul class="list-group document-types-list-group">
						<template v-for="category in categories">
							<Link :href="route('documents.index', category.id)"
							   :key="category.id"
							   v-if="category.can_access"
							   :class="{'list-group-item': 1, 'list-group-item-action': 1, 'list-group-item-action-active': activeCategory === category.id}">
								<span class="list-group-item-border" :style="'background:' + (category.color || '#eff2f7') + ';'"></span>
								<i :class="'me-2 far fa-' + category.icon" v-if="category.icon"></i>{{ category.name }}
							</Link>
						</template>
					</ul>
				</div>
				<div class="col-md-8">
					<div v-if="app.hasPermission('DocumentsController_categories')" class="mb-4 d-flex gap-2">
						<Link :href="route('documents.category') + '/' + activeCategory" class="btn btn-primary btn-label waves-effect"><i class="far fa-edit label-icon"></i> Kategória szerkesztése</Link>
						<ConfirmButton
							:url="route('documents.deleteCategory') + '/' + activeCategory"
							text="Kategória törlése"
							:small="false"
						></ConfirmButton>
					</div>
					<div>
						<div class="alert alert-info" v-if="!files.length">Nincs feltöltve file</div>
						<div class="card shadow-none border document" v-for="file in files">
							<div class="card-body p-3">
								<div class="d-flex align-items-center">
									<div class="me-4">
										<FileIcon :filename="file.file.extension" font-size="30px"></FileIcon>
									</div>
									<div>
										<a :href="file.file.download_url" target="_blank" class="fw-bold font-size-18">{{ file.name }}</a>
										<div class="d-flex gap-2">
											<div><i class="far fa-hashtag"></i> {{ file.public_id }}</div>
											<div class="file-size"><i class="far fa-file"></i> <file-size :value="file.file.size"/></div>
											<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.file.width}} x {{file.file.height}} px</div>
											<div><i class="far fa-calendar-alt"></i> <date-time :value="file.file.created_at"/></div>
										</div>
									</div>
									<div v-if="app.hasPermission('DocumentsController_update') || app.hasPermission('DocumentsController_delete')" class="ms-auto d-flex gap-2">
										<Link :href="route('documents.update') + '/' + file.id" class="btn btn-sm btn-primary" v-if="app.hasPermission('DocumentsController_update')"><i class="far fa-edit"></i></Link>
										<ConfirmButton
											v-if="app.hasPermission('DocumentsController_delete')"
											:url="route('documents.delete') + '/' + file.id"
										></ConfirmButton>
									</div>
								</div>
								<div v-if="file.description" class="mt-3" v-html="file.description_html"></div>
							</div>
						</div>
						<div class="mt-4">
							<nav>
								<ul class="pagination justify-content-center">
									<template v-for="link in paginationLinks">
										<li :class="{'page-item': 1, 'active': link.active}" v-if="link.url !== null">
											<a class="page-link" href="#" @click.prevent="getData(null, link.url)" v-html="link.label"></a>
										</li>
										<li class="page-item disabled" v-else>
											<a class="page-link" v-html="link.label"></a>
										</li>
									</template>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import ConfirmButton from "../components/ConfirmButton.vue";
import FileIcon from "../components/FileIcon.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import FileSize from "../components/FileSize.vue";
import DateTime from "../components/DateTime.vue";

export default {
	components: {DateTime, FileSize, FileIcon, ConfirmButton, TitleBar, Link},
	layout: AppLayout,
	props: {
		categories: Array,
		initialActiveCategory: Object
	},
	data() {
		return {
			activeCategory: this.categories.length ? this.categories[0].id : 0,
			files: [],
			app: useApplicationStore(),
			paginationLinks: [],
		}
	},
	mounted() {
		if (this.initialActiveCategory !== undefined && this.initialActiveCategory !== null){
			this.activeCategory = this.initialActiveCategory.id
		}
		if (this.activeCategory){
			this.getData(this.activeCategory)
		}
	},
	methods: {
		getData: function (categoryId, url){
			if (url !== undefined){
				axios.get(url).then((response) => {
					this.handleResponse(response)
				}).catch(function (error) {
				})
			} else {
				this.activeCategory = categoryId

				axios.get(route('documents.list') + '/' + this.activeCategory).then((response) => {
					this.handleResponse(response)
				}).catch(function (error) {
				})
			}
		},
		handleResponse: function(response){
			this.files = response.data.data
			this.paginationLinks = response.data.meta.links
		}
	}
}
</script>


<style scoped>

</style>
