import { defineStore } from 'pinia'

export const usePermanentStore = defineStore('permanent', {
	state: () => {
		return {
		}
	},
	getters: {
	},
	actions: {
		get(key, defaultValue) {
			const value = localStorage.getItem(key)

			return value ? JSON.parse(value) : (defaultValue || null)
		},
		set(key, value){
			localStorage.setItem(key, JSON.stringify(value))
		}
	},
})
