<template>
	<timeline-entry
		:entry="entry"
		:comments="comments"
		:closable="hasClosableEvent"
		:delayable="hasDelayableEvent"
		:visit-url="'/projects/edit/' + entry.project.id"
		@comment-count="updateCommentCount"
		@reload-from="loadTimeline">

		<template #head>
			<div class="timeline-item-head--icon">
				{{ entry.project.handlerInitials }}
			</div>
			<div class="timeline-item-head--content">
				<div class="d-flex align-items-start align-items-lg-center flex-lg-row flex-column gap-1 gap-lg-2">
					<project-type-cell :data="entry.project" small/>
					<h4>{{ entry.project.clientName }}</h4>
				</div>
				<div class="d-flex align-items-start align-items-lg-center flex-lg-row flex-column gap-1 gap-lg-2">
					<div class="d-flex gap-1 align-items-center">
						<project-status-icon :item="entry.project"/>
						<strong>{{ entry.project.public_id }}</strong>
					</div>
					<div>{{ entry.project.address }}</div>
				</div>
			</div>
		</template>

		<template #default>
			<div class="timeline-item-event-list">
				<template v-for="task in entry.tasks" :key="'timeline_task' + task.id">
					<div class="timeline-item-task">
						<div class="timeline-item-task-content">
							<div class="d-flex gap-1">
								<attribute
									icon="far fa-clock"
								>
									<date-time :value="task.created_at"/>
								</attribute>

								<attribute
									icon="far fa-user"
									v-if="task.created_by_user_name && (task.type === 'personal' || task.type === 'project')"
								>
									{{ task.created_by_user_name }}
								</attribute>
							</div>
							<div class="d-flex flex-column gap-1 align-items-start">
								<div class="d-flex gap-2 align-items-center">
									<i class="far fa-list text-primary"></i>
									<task-list-item-title :task="task" />
								</div>
								<div v-if="task.description && (task.type === 'personal' || task.type === 'project')" v-html="task.description_formatted"></div>
								<task-list-item-details :task="task" />
							</div>
						</div>
						<div class="ms-auto">
							<div class="dropdown">
								<button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
									<i class="far fa-ellipsis-h"></i>
								</button>
								<div class="dropdown-menu dropdown-menu-end" role="menu">
									<a class="dropdown-item" href="#" @click.prevent="showTaskForm = task">
										<i class="fas fa-check text-success fa-fw"></i> Teljesítettnek jelöl
									</a>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-for="(event, eventIndex) in entry.events" :key="'timeline_event' + event.id">
					<project-timeline-entry :event="event" :show-seen-events="(showSeenEvents || (!hasUnseenEvent && !showSeenEvents && eventIndex < 1))" @loadTimeline="loadTimeline"/>
				</template>
			</div>
		</template>
		<template #footer>
			<button type="button" class="btn btn-light" v-if="hasSeenEvent" @click.prevent="showSeenEvents = !showSeenEvents">
				<i :class="'far fa-eye' + (showSeenEvents ? '-slash' : '')"></i>
				<span class="d-none d-md-inline">{{ showSeenEvents ? 'Látottak elrejtése' : 'Összes mutatása' }}</span>
			</button>
		</template>
	</timeline-entry>
	<project-task-answer-modal
		:task="showTaskForm"
		v-if="showTaskForm"
		@close="showTaskForm = null"
		@closeAndUpdate="showTaskForm = null; $emit('loadTimeline')"
	/>
</template>

<script>
import FileSize from "../../components/FileSize.vue";
import Attribute from "../../components/Attribute.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import TimelineEntry from "./TimelineEntry.vue";
import DateTime from "../../components/DateTime.vue";
import ProjectStatusIcon from "../projects/ProjectStatusIcon.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";
import ProjectTaskAnswerModal from "../projects/ProjectTaskAnswerModal.vue";
import ProjectTimelineEntry from "./ProjectTimelineEntry.vue";
import TaskListItemTitle from "../../components/TaskListItemTitle.vue";
import TaskListItemDetails from "../../components/TaskListItemDetails.vue";
import UserBadge from "../../components/badges/UserBadge.vue";

export default {
	emits: ['loadTimeline', 'commentCount'],
	props: {
		entry: Object,
		comments: Object,
		projectEventTypes: Object,
	},
	components: {
		UserBadge,
		TaskListItemDetails,
		TaskListItemTitle,
		ProjectTimelineEntry,
		ProjectTaskAnswerModal,
		ProjectTypeCell, ProjectStatusIcon, DateTime, TimelineEntry, ColorBadge, Attribute, FileSize},
	data(){
		return {
			showSeenEvents: false,
			showTaskForm: null
		}
	},
	computed: {
		hasSeenEvent: {
			get(){
				for (let i = 0; i < this.entry.events.length; i++){
					if (this.entry.events[i].seen_at){
						return true
					}
				}

				return false
			}
		},
		hasUnseenEvent: {
			get(){
				for (let i = 0; i < this.entry.events.length; i++){
					if (!this.entry.events[i].seen_at){
						return true
					}
				}

				return false
			}
		},
		hasClosableEvent: {
			get(){
				for (let i = 0; i < this.entry.events.length; i++){
					if (this.entry.events[i].project_type_milestone_id){
						return true
					}
					if (this.entry.events[i].event !== null && !this.entry.events[i].seen_at && this.projectEventTypes[this.entry.events[i].event].closable){
						return true
					}
				}

				return false
			}
		},
		hasDelayableEvent: {
			get(){
				for (let i = 0; i < this.entry.events.length; i++){
					if (this.entry.events[i].event !== null && !this.entry.events[i].seen_at && this.projectEventTypes[this.entry.events[i].event].delayable){
						return true
					}
				}

				return false
			}
		},
	},
	methods: {
		loadTimeline: function (from){
			this.$emit('loadTimeline', from)
		},
		updateCommentCount: function (key, value){
			this.$emit('commentCount', key, value)
		},
	}
}
</script>
