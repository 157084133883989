<template>
	<div :class="{'mb-3': !fieldOnly && !noMargin}">
		<label v-if="!fieldOnly" :class="{required: isRequired}">{{ label }}</label>
		<div v-if="disabledValueOnly && disabled">
			<slot name="selected-option" :option="valueObject"></slot>
		</div>
		<div :class="{'select-invalid': errors[name]}" v-else>
			<Field
				v-model="componentValue"
				:name="name"
				:rules="rulesValue"
				v-slot="{ field }"
				:class="{'form-control': 1}"
			>
				<v-select
					:options="parsedOptions"
					v-model="componentValue"
					:reduce="reduce"
					:label="labelField"
					:clearable="!isRequired"
					:placeholder="label"
					:disabled="disabled"
					:multiple="multiple"
					:get-option-label="getOptionLabel"
					:filter="filterOptions"
					:searchable="searchable"
					:selectable="selectable"
					:tabindex="tabindex > -1 ? tabindex : null"
					@search="onSearch"
				>
					<template #no-options="{ search, searching, loading }">
						Nincs találat
					</template>

					<template #option="option">
						<div v-if="displayBadge">
							<color-badge :value="option" :small="smallBadge"></color-badge>
						</div>
						<slot name="option" :option="option"></slot>
					</template>
					<template #selected-option="option">
						<div v-if="displayBadge">
							<color-badge :value="option" :small="smallBadge"></color-badge>
						</div>
						<slot name="selected-option" :option="option"></slot>
					</template>
					<template #list-footer v-if="displaySearchInfoBottom">
						<li class="p-1 text-center fw-bold">Kezdj el gépelni további találatokért!</li>
					</template>
				</v-select>
			</Field>
			<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
			<p class="text-muted mt-2 text-info" v-if="info" v-html="info"></p>
		</div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from "yup";
import ColorBadge from "../ColorBadge.vue";
import {objectToArray} from "../../functions";

export default {
	emits: ['update:modelValue', 'change'],
	components: {ColorBadge, Field, ErrorMessage, vSelect},
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		tabindex: {
			type: Number,
			default: -1
		},
		noMargin: Boolean,
		disabledValueOnly: Boolean,
		errors: {
			type: Object,
			default: function(){
				return {}
			}
		},
		label: String,
		name: String,
		modelValue: [String, Number, Array, Object],
		info: {
			type: String,
			default: ''
		},
		dataType: {
			type: String,
			default: 'integer'
		},
		options: [Array, Object],
		selectable: {
			type: Function,
			default: (e) => !0
		},
		labelField: {
			type: String,
			default: 'name'
		},
		multiple: {
			type: Boolean,
			default: false
		},
		searchable: {
			type: Boolean,
			default: true
		},
		fieldOnly: Boolean,
		displayBadge: Boolean,
		smallBadge: Boolean,
		reduce: {
			type: Function,
			default: item => item.id
		},
		search: {
			type: Function
		},
		displaySearchInfoBottom: Boolean,
		getOptionLabel: {
			type: Function,
			default: function(option){
				if (typeof option === 'object') {
					if (!option.hasOwnProperty(this.label)) {
						return option
					}
					return option[this.label]
				}
				return option
			}
		},
		filterOptions: {
			type: Function,
			default: function(options, search) {
				return options.filter(option => {
					let label = this.getOptionLabel(option);
					if (typeof label === "number") {
						label = label.toString();
					}
					return this.filterBy(option, label, search);
				});
			}
		}
	},
	watch: {
		rules: {
			handler(newValue, oldValue) {
				this.parseRules()
			},
		}
	},
	computed: {
		componentValue: {
			get() {
				if (this.isRequired && !this.modelValue){
					let first = _.first(this.options)
					if (first){
						this.$emit('update:modelValue', first.id)
						this.$emit('change', first.id)
						return first.id
					}
				}
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
				this.$emit('change', value)
			}
		},
		parsedOptions: {
			get(){
				if (_.isArray(this.options)){
					return this.options
				}
				if (_.isObject(this.options)){
					return objectToArray(this.options)
				}
			}
		},
		valueObject: {
			get(){
				if (!this.componentValue){
					return {}
				}
				for (let i = 0; i < this.options.length; i++){
					if (this.options[i].id === this.componentValue){
						return this.options[i]
					}
				}
				return {}
			}
		}
	},
	data(){
		return {
			rulesValue: null,
			isRequired: false
		}
	},
	created() {
		this.parseRules()
	},
	methods: {
		onInput: function(){
		},
		onSearch(search, loading) {
			if (search.length && this.search !== undefined) {
				loading(true);
				this.search(loading, search, this);
			}
		},
		parseRules: function() {
			let tmp = this.rules.split(',')
			this.isRequired = tmp.indexOf('required') > -1

			if (this.multiple){
				this.rulesValue = yup.array()
			} else if (this.dataType === 'integer'){
				this.rulesValue = yup.number()
			} else {
				this.rulesValue = yup.string()
			}
			if (this.isRequired){
				this.rulesValue = this.rulesValue.required(() => {
					return this.$t(':field kitöltése kötelező', {field: this.label})
				})
			} else {
				this.rulesValue = this.rulesValue.notRequired()
			}
			this.rulesValue = this.rulesValue.label(this.label)
		}
	}
}
</script>

<style scoped>

</style>
