<template>
	<TitleBar title="Termékek" />

	<AdvancedDataTable
		:data-url="route('products.list')"
		:columns="columns"
		sort-by="sku"
		sort-type="asc"
		ref="dataTable"
		name="products"
		:run-at-init="updateColumns"
		v-if="dataLoaded"
		show-tracked-edit-mode
		controls-in-navigation
	>
		<template #navigation>
			<Link :href="route('products.create')" class="btn btn-primary btn-label" v-if="app.hasPermission('ProductsController_create')"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>

		<template #filters="{serverOptions}">
			<div class="data-table-filter-item data-table-filter-item-25">
				<product-category-select-field
					v-model="serverOptions.product_category_id"
					label="Kategória"
					name="product_category_id"
					:field-only="true"
				/>
			</div>
			<div class="data-table-filter-item data-table-filter-item-25">
				<product-manufacturer-select-field
					v-model="serverOptions.product_manufacturer_id"
					name="product_manufacturer_id"
					label="Gyártó"
					:field-only="true"
				/>
			</div>
			<div class="data-table-filter-item data-table-filter-item-25">
				<product-type-select-field
					v-model="serverOptions.type"
					label="Típus"
					name="type"
					:field-only="true"
				/>
			</div>
			<div class="data-table-filter-item data-table-filter-item-25">
				<product-attribute-select-field
					v-model="serverOptions.product_attribute_id"
					name="product_attribute_id"
					label="Tulajdonság"
					:field-only="true"
					@update:modelValue="updateColumns"
				>
				</product-attribute-select-field>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed, editButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<color-badge
							icon="fas fa-hashtag"
							color="light"
						>
							{{ item.sku }}
						</color-badge>
						<div class="mobile-horizontal">
							<data-loader :data="['productTypes']" v-slot="{ dataStore }">
								<color-badge
									:value="dataStore.getData('productTypes')[item.type]"
								></color-badge>
							</data-loader>
							<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
								<div v-if="dataStore.getData('productManufacturers', true)[item.product_manufacturer_id] !== undefined">
									<color-badge
										color="light"
										icon="far fa-industry"
										:content="dataStore.getData('productManufacturers', true)[item.product_manufacturer_id].name"
									></color-badge>
								</div>
							</data-loader>
							<data-loader :data="['productCategories']" v-slot="{ dataStore }">
								<div v-if="dataStore.getData('productCategories', true)[item.product_category_id] !== undefined">
									<color-badge
										color="light"
										icon="far fa-folder-open"
										:content="dataStore.getData('productCategories', true)[item.product_category_id].name"
									></color-badge>
								</div>
							</data-loader>
						</div>
						<div class="mobile-horizontal">
							<color-badge
								:color="item.track_inventory ? 'success' : 'danger'"
								:icon="'far fa-' + (item.track_inventory ? 'check': 'times')"
								content="Készlet követése"
							></color-badge>
							<color-badge
								v-if="item.type === 'material'"
								:color="item.requires_delivery ? 'success' : 'danger'"
								:icon="'far fa-' + (item.requires_delivery ? 'check': 'times')"
								content="Kiszállítás"
							></color-badge>
						</div>
						<div class="mobile-horizontal">
							<color-badge color="secondary" icon="far fa-coins" v-if="item.price_in_primary_currency">
								<Price
									:value="item.price_in_primary_currency"
									:key="'m_price_in_primary_currency_' + item.id"
								></Price>
							</color-badge>
							<color-badge color="light" icon="far fa-coins" v-if="item.price" small>
								<Price
									:value="item.price"
									:currency-id="item.currency_id"
									:key="'m_price_' + item.id"
								></Price>
							</color-badge>
							<color-badge color="light" icon="far fa-weight-hanging" v-if="item.weight_kg">
								<number :value="item.weight_kg" unit="kg"/>
							</color-badge>
						</div>
						<color-badge
							v-if="item.allow_using_as_component"
							color="light"
							icon="far fa-cog"
							:small="true"
							content="Alkatrészként használható"
						></color-badge>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('products.update')"
					:delete-url="route('products.delete')"
					:is-editable="item.editable"
					:is-deletable="item.deletable"
					:emit-delete-event="true"
					:tracked-edit-mode="trackedEditMode"
					@deleteButtonPressed="deleteButtonPressed"
					@edit-button-pressed="editButtonPressed"
					@view-button-pressed="editButtonPressed"
					v-slot="{ row }"
				>
					<Link type="button" class="btn btn-secondary btn-sm waves-effect" title="Duplikálás" v-if="app.hasPermission('ProductsController_create')" :href="route('products.clone', row.id)"><i class="far fa-clone"></i></Link>
				</TableActionCell>
			</div>
		</template>
		<template #item-product_manufacturer_id="item">
			<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
				<div v-if="dataStore.getData('productManufacturers', true)[item.product_manufacturer_id] !== undefined">
					{{dataStore.getData('productManufacturers', true)[item.product_manufacturer_id].name}}
				</div>
			</data-loader>
		</template>
		<template #item-product_category_id="item">
			<div class="horizontal">
				<color-badge
					:color="item.track_inventory ? 'success' : 'danger'"
					:icon="'far fa-' + (item.track_inventory ? 'check': 'times')"
					:small="true"
					content="Készlet követése"
				></color-badge>
				<color-badge
					v-if="item.type === 'material'"
					:color="item.requires_delivery ? 'success' : 'danger'"
					:icon="'far fa-' + (item.requires_delivery ? 'check': 'times')"
					:small="true"
					content="Kiszállítás"
				></color-badge>
				<color-badge
					v-if="item.allow_using_as_component"
					color="light"
					icon="far fa-cog"
					:small="true"
					content="Alkatrészként használható"
				></color-badge>
			</div>
		</template>
		<template #item-name="item">
			<div class="stacked-cell">
				<product-badge
					:product-id="item.id"
					:product-category-id="item.product_category_id"
					:product-manufacturer-id="item.product_manufacturer_id"
					:sku="item.sku"
					:product-name="item.name"
					/>
			</div>
		</template>
		<template #item-price_in_primary_currency="item">
			<div class="stacked-cell">
				<money-badge
					v-model="item.price_in_primary_currency"
					v-if="item.price_in_primary_currency > 0"
					:key="'price_' + item.id"
					color="secondary"
					net
					sale
					/>
				<money-badge
					v-model="item.price"
					v-if="item.price > 0"
					:currency-id="item.currency_id"
					:key="'priceAcq_' + item.id"
					color="light"
					net
					acquisition
					/>

				<color-badge color="light" icon="far fa-weight-hanging" v-if="item.weight_kg" small>
					<number :value="item.weight_kg" unit="kg"/>
				</color-badge>
			</div>
		</template>
		<template #item-type="item">
			<data-loader :data="['productTypes']" v-slot="{ dataStore }">
				<color-badge
					:value="dataStore.getData('productTypes')[item.type]" small
				></color-badge>
			</data-loader>
		</template>
		<template #item-attribute="item">
			<product-attribute
				:value="item.attribute"
				:product-attribute-id="item.product_attribute_id"
			></product-attribute>
		</template>
	</AdvancedDataTable>
</template>

<script>
import Price from "../components/Price.vue";
import TableActionCell from "../components/TableActionCell.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import ProductCategorySelectField from "../components/form/ProductCategorySelectField.vue";
import ProductAttributeSelectField from "../components/form/ProductAttributeSelectField.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import ProductAttribute from "../components/ProductAttribute.vue";
import ProductTypeSelectField from "../components/form/ProductTypeSelectField.vue";
import ColorBadge from "../components/ColorBadge.vue";
import ProductManufacturerSelectField from "../components/form/ProductManufacturerSelectField.vue";
import {useGeneralDataStore} from "../stores/generalData";
import {Link} from "@inertiajs/vue3";
import {useApplicationStore} from "../stores/applicationStore";
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import ProjectTypeCell from "../modules/projects/ProjectTypeCell.vue";
import ClientCell from "../modules/projects/ClientCell.vue";
import ProjectMilestonesCell from "../modules/projects/ProjectMilestonesCell.vue";
import ProjectTableProjectCell from "../modules/projects/ProjectTableProjectCell.vue";
import Number from "../components/Number.vue";
import ProductBadge from "../components/badges/ProductBadge.vue";
import MoneyBadge from "../components/badges/MoneyBadge.vue";

export default {
	layout: AppLayout,
	components: {
		MoneyBadge,
		ProductBadge,
		Number,
		ProjectTableProjectCell, ProjectMilestonesCell, ClientCell, ProjectTypeCell,
		TitleBar,
		ProductManufacturerSelectField,
		ColorBadge,
		ProductTypeSelectField,
		ProductAttribute,
		DataLoader,
		AdvancedDataTable,
		ProductAttributeSelectField,
		ProductCategorySelectField,
		BooleanBadge,
		TableActionCell,
		Price,
		Link
	},
	props: {
	},
	data(){
		return {
			columns: this.getColumns(),
			dataStore: useGeneralDataStore(),
			app: useApplicationStore(),
			dataLoaded: false
		}
	},
	mounted() {
		this.dataStore.loadData(['productAttributes', 'productCategories', 'productManufacturers', 'productTypes'], () => {
			this.dataLoaded = true
		})
	},
	methods: {
		getColumns: function (){
			let columns = [
				{
					text: 'Típus',
					value: 'type',
					sortable: true
				},
				{
					text: 'Termék',
					value: 'name',
					sortable: true
				},
				{
					text: 'Információk',
					value: 'product_category_id',
					sortable: false,
				},
			]

			if (this.$refs.dataTable !== undefined && this.$refs.dataTable.componentOptions.product_attribute_id !== undefined && this.$refs.dataTable.componentOptions.product_attribute_id){
				columns.push({
					text: this.dataStore.getData('productAttributes', true)[this.$refs.dataTable.componentOptions.product_attribute_id].name,
					value: 'attribute',
					sortable: true,
				})
			}

			columns.push({
				text: 'Értékek',
				value: 'price_in_primary_currency',
			})
			columns.push({
				text: '',
				value: 'operations',
				width: 5,
			})

			return columns
		},
		updateColumns: function (){
			this.columns = this.getColumns()
		}
	}
}
</script>

<style scoped>

</style>
