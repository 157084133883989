<template>
	<div v-if="dataLoaded">
		<template v-if="editable">
			<div class="row">
				<div :class="{'col-md-3': !narrow, 'col-md-6': narrow}">
					<div class="mb-3">
						<label :class="{'required' : required}">Ország</label>

						<div :class="{'select-invalid': errors[attributeName + '.countryId']}">
							<Field
								v-model="countryId"
								:name="attributeName + '.countryId'"
								:rules="countryRules"
								v-slot="{ field }"
								:class="{'form-control': 1}"
							>
								<v-select
									:options="dataStore.getData('countries')"
									:reduce="item => item.id"
									label="name"
									:clearable="false"
									v-model="countryId"
									@option:selected="countryChanged"
								/>
							</Field>
							<div class="invalid-feedback" v-if="errors[attributeName + '.countryId']">{{ errors[attributeName + '.countryId'] }}</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="mb-3">
						<label :class="{'required' : required}">Település</label>

						<div :class="{'select-invalid': errors[attributeName + '.city_id']}">
							<Field
								v-model="componentValue.city"
								:name="attributeName + '.city'"
								:rules="cityRules"
								v-slot="{ field }"
								:class="{'form-control': 1}"
							>
								<v-select
									:options="citySelectOptions"
									placeholder="Kezd el gépelni a keresést"
									label="formatted_name"
									:clearable="!required"
									:select-on-tab="true"
									v-model="componentValue.city"
									@option:selected="fieldChanged"
									@search="loadCities"
								/>
							</Field>
							<div class="invalid-feedback" v-if="errors[attributeName + '.city_id']">{{ errors[attributeName + '.city_id'] }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="mb-3">
						<label :class="{'required' : required}">Utca</label>
						<Field
							v-model="componentValue.street"
							:name="attributeName + '.street'"
							:rules="streetRules"
							v-slot="{ field }"
							:class="{'form-control': 1}"
							@change="fieldChanged"
						>
							<input v-bind="field" type="text" :class="{'form-control': 1, 'is-invalid': errors[attributeName + '.street']}" />
						</Field>
						<div class="invalid-feedback" v-if="errors[attributeName + '.street']">{{ errors[attributeName + '.street'] }}</div>
					</div>
				</div>
				<div :class="{'col-sm-4': 1, 'col-md-6': narrow, 'col-md-2': !narrow}">
					<div class="mb-3">
						<label :class="{'required' : required}">Házszám</label>
						<Field
							v-model="componentValue.house_number"
							:name="attributeName + '.house_number'"
							:rules="houseNumberRules"
							v-slot="{ field }"
							:class="{'form-control': 1}"
							@change="fieldChanged"
						>
							<input v-bind="field" type="text" :class="{'form-control': 1, 'is-invalid': errors[attributeName + '.house_number']}" />
						</Field>
						<div class="invalid-feedback" v-if="errors[attributeName + '.house_number']">{{ errors[attributeName + '.house_number'] }}</div>
					</div>
				</div>
				<div :class="{'col-sm-4': 1, 'col-md-6': narrow, 'col-md-2': !narrow}">
					<div class="mb-3">
						<label>Emelet</label>
						<input v-model="componentValue.floor" type="text" :class="{'form-control': 1, 'is-invalid': errors.length}" />
					</div>
				</div>
				<div :class="{'col-sm-4': 1, 'col-md-6': narrow, 'col-md-2': !narrow}">
					<div class="mb-3">
						<label>Ajtó</label>
						<input type="text" :class="{'form-control': 1, 'is-invalid': errors.length}" />
					</div>
				</div>
			</div>

			<address-geocoder
				v-model="componentValue"
				ref="geocoder"
				:countries="dataStore.getData('countries')"
				:editable="true"
				:attribute-name="attributeName"
				></address-geocoder>
		</template>
		<template v-else>
			<address-badge :address="componentValue" />
			<!--
			<div class="address-display" v-if="componentValue.city !== undefined && componentValue.city !== null">
				<i class="fas fa-map-marker-alt"></i>
				<color-badge
					v-if="componentValue.country_id"
					color="dark"
					:content="dataStore.getData('countries', true)[componentValue.country_id].name + ' '" />
				<color-badge
					v-if="componentValue.city_id"
					color="secondary"
					:content="componentValue.city.zip_code + ' ' + componentValue.city.name + ' '" />
				<color-badge
					v-if="componentValue.street"
					color="light"
					:content="componentValue.street + ' ' + componentValue.house_number + (componentValue.floor ? ', ' + componentValue.floor : '') + (componentValue.door ? ', ' + componentValue.door : '')" />
				<a :href="'geo:' + componentValue.lat + ',' + componentValue.lng + '?q=' + componentValue.formatted" class="btn btn-secondary btn-sm navigation-link"><i class="far fa-route"></i> Útvonaltervezés</a>
				<button class="map-link btn btn-secondary btn-sm" @click.prevent="showMapReadOnly = !showMapReadOnly"><i class="far fa-map"></i> Térkép</button>
			</div>
			<address-geocoder
				v-model="componentValue"
				ref="geocoder"
				v-if="showMapReadOnly"
				:countries="dataStore.getData('countries')"
				:editable="false"
				:map-only="true"
				:attribute-name="attributeName"
			></address-geocoder>-->
		</template>
	</div>
</template>

<script>
import { Field, ErrorMessage, useField, useForm } from 'vee-validate';
import vSelect from "vue-select";
import * as yup from 'yup';
import "leaflet/dist/leaflet.css";
import {LMap, LMarker, LTileLayer} from "@vue-leaflet/vue-leaflet";
import AddressGeocoder from "./AddressGeocoder.vue";
import {useGeneralDataStore} from "../stores/generalData";
import ColorBadge from "./ColorBadge.vue";
import AddressBadge from "./badges/AddressBadge.vue";
export default {
	components: {AddressBadge, ColorBadge, AddressGeocoder, LMarker, LTileLayer, LMap, Field, ErrorMessage, useField, useForm, vSelect },
	props: {
		modelValue: Object,
		cities: Array,
		required: Boolean,
		attributeName: String,
		narrow: Boolean,
		editable: Boolean,
		errors: {
			type: Object
		},
	},
	data() {
		let countryId = null
		if (this.modelValue.city){
			countryId = this.modelValue.city.country_id
		}
		let citySelectOptions = this.modelValue.city ? [this.modelValue.city] : []

		return {
			countryRules: this.required ? yup.number().required().label('Ország') : yup.number().notRequired().label('Ország'),
			cityRules: this.required ? yup.object().required().label('Település') : yup.object().notRequired().label('Település'),
			streetRules: this.required ? yup.string().required().label('Utca') : yup.string().notRequired().label('Utca'),
			houseNumberRules: this.required ? yup.string().required().label('Házszám') : yup.string().notRequired().label('Házszám'),
			error: '',
			countryId: countryId,
			citySelectOptions: citySelectOptions,
			dataLoaded: false,
			showMapReadOnly: false,
			dataStore: useGeneralDataStore(),
		}
	},
	mounted() {
		this.dataStore.loadData(['countries'], () => {
			if (this.countryId === null){
				this.countryId = _.head(this.dataStore.getData('countries')).id
			}
			this.dataLoaded = true
		})
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	methods: {
		emitChange: function(){
			//this.$emit('fieldChange', this.content)
		},
		isNumber: function(e){
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			const keyPressed = e.key;

			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},
		countryChanged: function(e){
			this.citySelectOptions = []
			this.componentValue.city = null

			this.fieldChanged()
		},
		fieldChanged: function(e){
			this.$refs.geocoder.geocode();
		},
		loadCities: function (search, loading){
			if(search.length) {
				loading(true);

				let func = _.debounce(() => {
					axios.get('/citySelectData/' + this.countryId + '?query=' + search).then((response) => {
						this.citySelectOptions = response.data.results
						loading(false);
					}).catch((error) => {
						this.citySelectOptions = []
						loading(false);
					})
				}, 350)

				func()
			}
		}
	}
}
</script>

<style scoped>

</style>
