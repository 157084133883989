<template>
	<div>
		<AdvancedDataTable
			:data-url="'/projects/' + projectDataStore.projectId + '/quotes'"
			:columns="columns"
			sort-by="created_at"
			ref="table"
			sort-type="desc"
			name="quotes"
		>
			<template #navigation v-if="editable && projectDataStore.project.editable && projectDataStore.can('quotes', 'create')">
				<button type="button" class="btn btn-primary btn-label" @click.prevent="quoteFormId = null; formOpen = true"><i class="far fa-plus label-icon"></i> Árajánlat létrehozása</button>
			</template>
			<template #item-operations="{item, deleteButtonPressed}">
				<div class="operations-wrapper">
					<div class="mobile-cell">
						<div class="stacked-cell">
							<strong>{{ item.public_id }}</strong>
							<div>{{ item.title }}</div>
							<attribute icon="far fa-clock">
								<date-time :value="item.created_at"/>
							</attribute>
							<attribute icon="far fa-file-contract" label="Szerződés" v-if="item.contract_public_id">
								<a href="#" @click.prevent="emitOpenContract(item.contract_id)">{{ item.contract_public_id }}</a>
							</attribute>

							<div class="mobile-horizontal">
								<data-loader :data="['quoteStatusLabels']" v-slot="{ dataStore }">
									<color-badge :value="dataStore.getData('quoteStatusLabels')[item.status]" :small="true"></color-badge>
								</data-loader>
								<color-badge color="warning" icon="calculator" content="Egyedi árazás" :small="true" v-if="item.use_project_type_pricing"></color-badge>
								<color-badge color="light" icon="check" content="Jóváhagyás alatt" :small="true" v-if="item.approveQuoteRequestId"></color-badge>
							</div>
							<div class="mobile-horizontal">
								<color-badge color="light">
									Nettó: <Price
										:value="item.total_price_net"
										:currency-id="item.currency_id"
									/>
								</color-badge>
								<color-badge color="dark">
									Bruttó: <Price
										:value="item.total_price_gross"
										:currency-id="item.currency_id"
									/>
								</color-badge>
							</div>
						</div>
					</div>
					<TableActionDropdown
						:data="item"
						:emit-edit-event="true"
						:emit-view-event="true"
						:emit-delete-event="true"
						:is-editable="item.status === 'draft' && item.editable && editable && projectDataStore.can('quotes', 'update')"
						:is-deletable="app.hasPermission('ProjectsController_deleteQuotes') || (item.status === 'draft' && item.deletable && editable && projectDataStore.can('quotes', 'delete'))"
						@edit-button-pressed="openQuote"
						@view-button-pressed="openQuote"
						@delete-button-pressed="deleteQuote"
						v-slot="{ row }"
					>
						<a href="#" class="dropdown-item" v-close-popper @click.prevent="openResponseForm(row.id)" v-if="editable && (row.status === 'approved' || row.status === 'sent' || row.status === 'read') && projectDataStore.project.editable && projectDataStore.can('quotes', 'answer')">
							<i class="far fa-reply fa-fw me-1 text-warning"></i> Ügyfél válasz rögzítése
						</a>
						<a href="#" class="dropdown-item" v-close-popper @click.prevent="cloneQuote(row.id)" v-if="editable && projectDataStore.project.editable && projectDataStore.can('quotes', 'create')">
							<i class="far fa-clone fa-fw me-1 text-secondary"></i> Duplikálás
						</a>
						<a href="#" class="dropdown-item" v-close-popper @click.prevent="reopenQuote(row.id)" v-if="editable && app.hasPermission('ProjectsController_reopenQuotes') && !row.contract_id && (row.status === 'accepted' || row.status === 'rejected')">
							<i class="far fa-lock-open fa-fw me-1 text-secondary"></i> Újranyitás
						</a>
						<a href="#" class="dropdown-item" v-close-popper @click.prevent="approveQuote(row.id)" v-if="editable && row.status === 'draft' && app.hasPermission('ProjectsController_approveQuotes')">
							<i class="far fa-check fa-fw me-1 text-success"></i> Jóváhagyás
						</a>
						<a href="#" class="dropdown-item" v-close-popper @click.prevent="requestQuoteApproval(row.id)" v-if="canSendApprovalRequest(row)">
							<i class="far fa-check fa-fw me-1 text-warning"></i> Jóváhagyás igénylése
						</a>
						<a href="#" class="dropdown-item" v-close-popper @click.prevent="contractFromQuote(row.id)" v-if="editable && row.status === 'accepted' && !row.contract_id && projectDataStore.project.editable && projectDataStore.can('contracts', 'create')">
							<i class="far fa-file-contract fa-fw me-1 text-success"></i> Szerződés generálás
						</a>
						<a class="dropdown-item" v-close-popper :href="'/projects/' + projectDataStore.projectId + '/downloadQuote/' + row.id"  v-if="row.file_id">
							<i class="far fa-download fa-fw me-1 text-secondary"></i> Letöltés
						</a>
						<a href="#" class="dropdown-item" v-close-popper @click.prevent="sendQuote(row.id)" v-if="editable && row.status === 'approved'">
							<i class="far fa-envelope fa-fw me-1 text-warning"></i> Küldés az ügyfélnek
						</a>
					</TableActionDropdown>
				</div>
			</template>
			<template #item-public_id="item">
				<div v-if="highlight === item.id" class="highlighted">
					{{item.public_id}} <i class="far fa-exclamation-triangle ms-2"></i>
				</div>
				<template v-else>
					{{item.public_id}}
				</template>
			</template>
			<template #item-title="item">
				<template v-if="item.title">
					{{ item.title }}
				</template>
				<template v-else>
					{{ projectDataStore.client.is_company ? projectDataStore.projectType.name_in_quote_company : projectDataStore.projectType.name_in_quote_consumer }}
				</template>
			</template>
			<template #item-status="item">
				<div class="d-flex gap-2 flex-column align-items-start">
					<data-loader :data="['quoteStatusLabels']" v-slot="{ dataStore }">
						<color-badge :value="dataStore.getData('quoteStatusLabels')[item.status]" :small="true"></color-badge>
					</data-loader>
					<color-badge color="warning" icon="calculator" content="Egyedi árazás" :small="true" v-if="item.use_project_type_pricing"></color-badge>
					<color-badge color="light" icon="check" content="Jóváhagyás alatt" :small="true" v-if="item.approveQuoteRequestId"></color-badge>
				</div>
			</template>
			<template #item-contract_public_id="item">
				<a href="#" @click.prevent="emitOpenContract(item.contract_id)">{{ item.contract_public_id }}</a>
			</template>
			<template #item-total_price_net="item">
				<Price
					:value="item.total_price_net"
					:currency-id="item.currency_id"
					/>
			</template>
			<template #item-total_price_gross="item">
				<Price
					:value="item.total_price_gross"
					:currency-id="item.currency_id"
					/>
			</template>
			<template #item-created_at="item">
				<date-time :value="item.created_at"/>
			</template>
		</AdvancedDataTable>

		<QuoteForm
			v-if="formOpen"
			:quote-id="quoteFormId"
			:editable="editable"
			@close="formOpen = false"
			@close-and-reload="closeQuoteFormAndUpdateQuotes"
			@approve="approveQuote"
			@send="sendQuote"
			:can-start-editing="canStartEditing"
			@start-editing="$emit('startEditing')"
			@update-stats="updateStats"
			:form-errors="formErrors"
		></QuoteForm>
		<teleport to="body" v-if="responseFormOpen">
			<QuoteResponseForm
				:quote-id="quoteFormId"
				@close="responseFormOpen = false"
				@close-and-reload="closeQuoteResponseFormAndUpdateQuotes"
				></QuoteResponseForm>
		</teleport>
	</div>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import QuoteForm from './QuoteForm.vue'
import Price from "../../components/Price.vue";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import QuoteResponseForm from "./QuoteResponseForm.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useProjectDataStore} from "../../stores/projectData";
import Modal from "../../components/Modal.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import Attribute from "../../components/Attribute.vue";
import Number from "../../components/Number.vue";
import DateTime from "../../components/DateTime.vue";
import TextareaField from "../../components/form/TextareaField.vue";

export default {
	components: {
		TextareaField,
		DateTime,
		Number, Attribute,
		Link,
		Modal,
		ColorBadge,
		DataLoader,
		TableActionDropdown,
		QuoteResponseForm,
		Price,
		TableActionCell,
		QuoteForm,
		AdvancedDataTable,
	},
	emits: ['quoteCount', 'updateStats', 'openContract', 'startEditing'],
	mounted() {
		if (this.projectDataStore.activeTabCommand === 'quote' && this.projectDataStore.activeTabParameter){
			setTimeout(() => {
				this.openQuote(this.projectDataStore.activeTabParameter)
			}, 300)
		}
	},
	props: {
		editable: Boolean,
		canStartEditing: Boolean,
		formErrors: Object
	},
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'public_id',
				sortable: true,
				width: 10
			},
			{
				text: 'Név',
				value: 'title',
				sortable: false,
			},
			{
				text: 'Állapot',
				value: 'status',
				sortable: false,
			},
			{
				text: 'Nettó',
				value: 'total_price_net',
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				text: 'Bruttó',
				value: 'total_price_gross',
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				text: 'Szerződés',
				value: 'contract_public_id',
				sortable: true,
				width: 10
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				wrap: false,
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]
		let highlight = null;
		if (location.hash && location.hash.length > 1){
			let tmp = location.hash.substring(1).split('-')
			if (tmp[0] !== undefined && tmp[0] === 'quotes' && tmp[1] !== undefined && tmp[1].indexOf('highlight') > -1) {
				highlight = parseInt(tmp[1].replace('highlight:', ''))
			}
		}

		return {
			highlight: highlight,
			columns: columns,
			formOpen: false,
			quoteFormId: null,
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			app: useApplicationStore(),
			responseFormOpen: false,
		}
	},
	methods: {
		isEditable: function(row){
			return row.editable
		},
		openQuote: function(quoteId){
			this.quoteFormId = quoteId
			this.formOpen = true
		},
		deleteQuote: function(quoteId){
			this.loading = true
			axios.delete('/projects/deleteQuote/' + quoteId, {}).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				if (response.data.stats !== undefined) {
					this.updateStats(response.data.stats);
				}
				this.$refs.table.getData()
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		closeQuoteFormAndUpdateQuotes: function(){
			this.formOpen = false
			this.$refs.table.getData()
		},
		closeQuoteResponseFormAndUpdateQuotes: function(){
			this.responseFormOpen = false
			this.$refs.table.getData()
		},
		approveQuote: function (quoteId){
			axios.put('/projects/approveQuote/' + quoteId).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		canSendApprovalRequest: function(row){
			if (row.status !== 'draft' || this.app.hasPermission('ProjectsController_approveQuotes')){
				return false
			}

			return row.approveQuoteRequestId < 1
		},
		requestQuoteApproval: function (quoteId){
			axios.put('/projects/requestQuoteApproval/' + quoteId).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		sendQuote: function (quoteId){
			axios.put('/projects/sendQuoteToClient/' + quoteId).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		cloneQuote: function (quoteId){
			axios.put('/projects/cloneQuote/' + quoteId).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				if (response.data.stats !== undefined) {
					this.updateStats(response.data.stats);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		reopenQuote: function (quoteId){
			axios.put('/projects/reopenQuote/' + quoteId).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		contractFromQuote: function (quoteId){
			axios.put('/projects/createContractFromQuote/' + quoteId).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
				if (response.data.stats !== undefined) {
					this.$emit('updateStats', response.data.stats)
				}
			}).catch((error) =>  {
				this.flashStore.addError(error)
			})
		},
		openResponseForm: function (quoteId){
			this.quoteFormId = quoteId
			this.responseFormOpen = true
		},
		updateStats: function(stats){
			this.$emit('updateStats', stats);
		},
		emitOpenContract: function(id){
			this.$emit('openContract', id);
		}
	}
}
</script>

<style scoped>
</style>
