<template>
	<TitleBar title="Feladatok"/>

	<AdvancedDataTable
		:data-url="route('tasks.list')"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		name="tasks"
		:search="false"
	>
		<template #item-name="item">
			<task-list-item :task="item" absolute-time/>
		</template>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<task-list-item :task="item" absolute-time/>
				</div>
			</div>
		</template>
	</AdvancedDataTable>

	<task-modal
		v-if="viewTaskId !== null"
		:task-id="viewTaskId"
		@close="viewTaskId = null"
		@closeAndReload="viewTaskId = null"
	/>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DateTime from "../components/DateTime.vue";
import ColorBadge from "../components/ColorBadge.vue";
import {Link} from "@inertiajs/vue3";
import Attribute from "../components/Attribute.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import {useApplicationStore} from "../stores/applicationStore";
import TaskListItem from "../components/TaskListItem.vue";
import TaskModal from "../modules/dashboard/TaskModal.vue";

export default {
	components: {TaskModal, TaskListItem, UserBadge, Attribute, ColorBadge, DateTime, AdvancedDataTable, Link, TitleBar},
	layout: AppLayout,
	props: {
		selectedTaskId: Number
	},
	data() {
		let columns = [
			{
				text: 'Részletek',
				value: 'name',
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore(),
			viewTaskId: this.selectedTaskId || null,
		}
	},
}
</script>
