<template>
	<div class="alert alert-info" v-if="!componentValue.length">Nincs létrehozva dokumentumcsomag</div>
	<template v-for="(bundle, bundleKey) in componentValue" :key="'bundle_' + bundleKey">
		<div class="card mb-3">
			<a class="card-header" href="#" @click.prevent="toggleBundleVisibility(bundleKey)">
				<h4 class="card-title mb-0"><i :class="'far fa-eye' + (visibleBundles.indexOf(bundleKey) < 0 ? '-slash' : '')"></i> {{ bundle.name || 'Névtelen' }}</h4>
			</a>
			<div :class="{'card-body': 1, 'd-none': visibleBundles.indexOf(bundleKey) < 0}">
				<div class="row">
					<div class="col-md-6">
						<input-field
							label="Név"
							:name="'bundle_name_' + bundleKey"
							v-model="bundle.name"
							rules="required"
							:errors="errors"
						/>
						<switch-field
							label="Jóváhagyás szükséges"
							:name="'bundle_needs_approval_' + bundleKey"
							v-model="bundle.needs_approval"
							:errors="errors"
						/>
					</div>
					<div class="col-md-6">
						<switch-field
							label="Küldés tanácsadónak"
							:name="'bundle_send_to_handler_' + bundleKey"
							v-model="bundle.send_to_handler"
							:errors="errors"
							/>
						<switch-field
							label="Küldés ügyfélnek"
							:name="'bundle_send_to_client_' + bundleKey"
							v-model="bundle.send_to_client"
							:errors="errors"
							/>
						<switch-field
							label="E-mail küldése"
							:name="'bundle_send_email_' + bundleKey"
							v-model="bundle.send_email"
							:errors="errors"
							/>
					</div>
				</div>
				<div class="card border shadow-none card-body" v-if="bundle.send_email">
					<div class="row">
						<div class="col-md-6">
							<h4 class="card-title">Címzettek</h4>
							<table class="table">
								<thead>
								<tr>
									<th width="20%">Típus</th>
									<th width="48%">E-mail cím</th>
									<th width="48%">Név</th>
									<th width="2%"></th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="(recipient, recipientKey) in bundle.email_recipients" :key="'recipient_' + bundleKey + '_' + recipientKey">
									<td>
										<select-field
											label="Típus"
											:field-only="true"
											:name="'recipient_type_' + bundleKey + '_' + recipientKey"
											v-model="recipient.type"
											:options="recipientTypes"
											rules="required"
											:errors="errors"
											:searchable="false"
											data-type="string"
										/>
									</td>
									<td>
										<input-field
											label="E-mail cím"
											:name="'recipient_email_' + bundleKey + '_' + recipientKey"
											v-model="recipient.email"
											rules="required"
											:field-only="true"
											data-type="email"
											:errors="errors"
										/>
									</td>
									<td>
										<input-field
											label="Név"
											:field-only="true"
											:name="'recipient_name_' + bundleKey + '_' + recipientKey"
											v-model="recipient.name"
											:errors="errors"
										/>
									</td>
									<td>
										<button class="btn btn-danger" type="button" @click.prevent="removeRecipient(bundleKey, recipientKey)"><i class="far fa-times"></i></button>
									</td>
								</tr>
								</tbody>
								<tfoot>
								<tr>
									<th colspan="4">
										<button type="button" class="btn btn-primary btn-label" @click.prevent="addRecipient(bundleKey)"><i class="far fa-plus label-icon"></i> Hozzáad</button>
									</th>
								</tr>
								</tfoot>
							</table>
							<h4 class="card-title">Küldő</h4>
							<switch-field
								label="Küldő postafiók megadása"
								:name="'use_custom_email_server_' + bundleKey"
								v-model="bundle.use_custom_email_server"
								></switch-field>

							<template v-if="bundle.use_custom_email_server">
								<input-field
									label="Felhasználónév"
									:name="'bundle_email_user_' + bundleKey"
									v-model="bundle.email_user"
									rules="required"
									:errors="errors"
								/>
								<input-field
									label="Jelszó"
									:name="'bundle_email_password_' + bundleKey"
									v-model="bundle.email_password"
									rules="required"
									:errors="errors"
								/>
								<input-field
									label="Szerver"
									:name="'bundle_email_server_' + bundleKey"
									v-model="bundle.email_server"
									rules="required"
									:errors="errors"
								/>
								<input-field
									label="Port"
									:name="'bundle_email_server_port_' + bundleKey"
									v-model="bundle.email_server_port"
									rules="required"
									:errors="errors"
									info="Alapértelmezett portok: TLS - 465, SSL - 587, nincs - 25"
								/>
								<select-field
									label="Titkosítás"
									:name="'bundle_email_server_encryption_' + bundleKey"
									v-model="bundle.email_server_encryption"
									rules="required"
									data-type="string"
									:options="[{id: 'tls', name: 'TLS'}, {id: 'ssl', name: 'SSL'}, {id: '-', name: 'nincs'}]"
									:errors="errors"
								/>
								<input-field
									label="Küldő címe"
									:name="'bundle_email_from_address_' + bundleKey"
									v-model="bundle.email_from_address"
									rules="required"
									:errors="errors"
								/>
								<input-field
									label="Küldő neve"
									:name="'bundle_email_from_name_' + bundleKey"
									v-model="bundle.email_from_name"
									rules="required"
									:errors="errors"
								/>
							</template>
						</div>
						<div class="col-md-6">
							<h4 class="card-title">E-mail tartalom</h4>

							<input-field
								label="Tárgy"
								:name="'bundle_email_subject_' + bundleKey"
								v-model="bundle.email_subject"
								:errors="errors"
							/>
							<label>Tartalom</label>
							<div class="mb-3 bg-light p-2">
								<variable-select
									label="Változó beszúrása"
									:field-only="true"
									:options="flattenedFields"
									:errors="errors"
								/>
							</div>
							<ckeditor-field
								:name="'content_' + bundleKey"
								v-model="bundle.email_content"
							></ckeditor-field>
						</div>
					</div>
				</div>
				<h4 class="card-title">Dokumentumok</h4>
				<div class="alert alert-info mb-3">Amennyiben a kiválasztott űrlap nincs legenerálva a csomag készítésekor, az automatikusan generálásra kerül.</div>
				<table class="table">
					<thead>
					<tr>
						<th width="40%">Űrlap</th>
						<th width="40%">Csatolt dokumentum</th>
						<th width="40%">Cimke</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(document, documentKey) in bundle.documents" :key="'document_' + bundleKey + '_' + documentKey">
						<td>
							<select-field
								label="Űrlap"
								:field-only="true"
								:name="'bundle_form_' + bundleKey + '_' + documentKey"
								v-model="document.form_id"
								:options="forms"
								:errors="errors"
								v-if="!document.project_type_document_type_id"
							/>
						</td>
						<td>
							<select-field
								label="Csatolt dokumentum"
								:field-only="true"
								:name="'bundle_document_' + bundleKey + '_' + documentKey"
								v-model="document.project_type_document_type_id"
								:options="documentCategories"
								:errors="errors"
								v-if="!document.form_id"
							/>
						</td>
						<td>
							<select-field
								label="Dokumentum cimke"
								:field-only="true"
								:name="'bundle_tag_id_' + bundleKey + '_' + documentKey"
								v-model="document.tag_id"
								:options="documentTags"
								label-field="label"
								:errors="errors"
								v-if="!document.form_id"
							/>
						</td>
						<td>
							<button class="btn btn-danger" type="button" @click.prevent="removeDocument(bundleKey, documentKey)"><i class="far fa-times"></i></button>
						</td>
					</tr>
					</tbody>
					<tfoot>
					<tr>
						<th colspan="4">
							<button type="button" class="btn btn-primary btn-label" @click.prevent="addDocument(bundleKey)"><i class="far fa-plus label-icon"></i> Hozzáad</button>
						</th>
					</tr>
					</tfoot>
				</table>
				<h4 class="card-title">Triggerek</h4>
				<div class="card shadow-none border file p-3 mb-2 pb-0" v-for="(trigger, triggerKey) in bundle.triggers" :key="'trigger_' + bundleKey + '_' + triggerKey">
					<div class="d-flex gap-2 mb-3 align-items-center">
						<h4 class="card-title m-0">Trigger {{ triggerKey + 1 }}</h4>
						<div class="data-source-operation flex-grow btn-group" v-if="triggerKey > 0">
							<button type="button" :class="{
													'btn': 1,
													'btn-success': trigger.operation === 'and',
													'btn-dark': trigger.operation !== 'and'
												}"
									@click.prevent="trigger.operation = 'and'">&& És</button>

							<button type="button" :class="{
													'btn': 1,
													'btn-danger': trigger.operation === 'or',
													'btn-dark': trigger.operation !== 'or'
												}"
									@click.prevent="trigger.operation = 'or'">|| Vagy</button>
						</div>
						<button class="btn btn-danger ms-auto" type="button" @click.prevent="removeTrigger(bundleKey, triggerKey)"><i class="far fa-times"></i></button>
					</div>
					<div class="row">
						<div class="col-md-6" v-if="!trigger.special && !trigger.project_type_document_type_id && !trigger.project_type_milestone_id && !trigger.project_type_milestone_id">
							<select-field
								label="Űrlap"
								:name="'bundle_trig_form_' + bundleKey + '_' + triggerKey"
								v-model="trigger.form_id"
								:options="forms"
								:errors="errors"
							/>
						</div>
						<div class="col-md-6" v-if="!trigger.special && !trigger.form_id && !trigger.project_type_milestone_id && !trigger.project_type_milestone_id">
							<select-field
								label="Csatolt dokumentum"
								:name="'bundle_trig_document_' + bundleKey + '_' + triggerKey"
								v-model="trigger.project_type_document_type_id"
								:options="documentCategories"
								:errors="errors"
							/>
						</div>
						<div class="col-md-6" v-if="trigger.project_type_document_type_id">
							<select-field
								label="Dokumentum cimke"
								:name="'bundle_trig_tag_id_' + bundleKey + '_' + triggerKey"
								v-model="trigger.tag_id"
								:options="documentTags"
								label-field="label"
								:errors="errors"
							/>
						</div>
						<div class="col-md-6" v-if="!trigger.special && !trigger.form_id && !trigger.project_type_document_type_id">
							<select-field
								label="Mérföldkő"
								:name="'bundle_trig_milestone_' + bundleKey + '_' + triggerKey"
								v-model="trigger.project_type_milestone_id"
								:options="filteredMilestones"
								:errors="errors"
							/>
						</div>
						<div class="col-md-6" v-if="!trigger.form_id && !trigger.project_type_document_type_id && !trigger.project_type_milestone_id">
							<select-field
								label="Speciális"
								:name="'bundle_trig_special_' + bundleKey + '_' + triggerKey"
								v-model="trigger.special"
								:options="specialTriggers"
								:errors="errors"
							/>
						</div>
						<div class="col-md-6" v-if="trigger.project_type_document_type_id">
							<input-field
								label="Min darabszám"
								:name="'bundle_trig_document_required_quantity_' + bundleKey + '_' + triggerKey"
								v-model="trigger.required_quantity"
								:errors="errors"
								data-type="integer"
								:min="1"
								:step="1"
							/>
						</div>
					</div>
				</div>

				<button type="button" class="btn btn-secondary btn-label" @click.prevent="addTrigger(bundleKey)"><i class="far fa-plus label-icon"></i> Hozzáad</button>
			</div>
		</div>
	</template>
	<div class="mt-3">
		<button type="button" class="btn btn-secondary btn-label" @click.prevent="addDocumentBundle"><i class="far fa-plus label-icon"></i> Hozzáad</button>
	</div>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import BooleanBadge from "../../components/badges/BooleanBadge.vue";
import InputField from "../../components/form/InputField.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import CkeditorField from "../../components/form/CkeditorField.vue";
import SelectField from "../../components/form/SelectField.vue";
import VariableSelect from "../../components/form/VariableSelect.vue";
import TextareaField from "../../components/form/TextareaField.vue";

export default {
	components: {TextareaField, VariableSelect, SelectField, CkeditorField, SwitchField, InputField},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		filteredMilestones: {
			get() {
				return _.filter(this.milestones, function (i){
					return i.id !== undefined
				})
			}
		},
		flattenedFields: {
			get() {
				let payload = []
				_.forEach(this.fields, function (field){
					payload.push({
						'id': '{mező_' + field.field_number + '}',
						'name': field.field_number + '. ' + field.title
					})
					if (field.children !== undefined && field.children.length){
						_.forEach(field.children, function (child){
							payload.push({
								'id': '{mező_' + child.field_number + '}',
								'name': child.field_number + '. ' + field.title + ' / ' + child.title
							})
						})
					}
				});

				return payload
			}
		},
		emailVariables: {
			get() {
				let payload = this.flattenedFields
				payload.push({
					id: '{címzett_név}',
					label: 'Címzett neve'
				})
				payload.push({
					id: '{címzett_email}',
					label: 'Címzett e-mail címe'
				})
				payload.push({
					id: '{csomag_név}',
					label: 'Dokumentumcsomag név'
				})
				payload.push({
					id: '{csomag_azon}',
					label: 'Dokumentumcsomag azonosító'
				})
				payload.push({
					id: '{projekt_azon}',
					label: 'Projekt azonosító'
				})
				payload.push({
					id: '{projekt_típus}',
					label: 'Projekt típus'
				})
			}
		}
	},
	props: {
		canApproveDocumentBundle: Boolean,
		modelValue: Array,
		dataUrl: String,
		errors: Object,
		fields: Object,
		languages: Array,
		forms: Array,
		documentCategories: Array,
		milestones: Array,
		documentTags: Array,
	},
	data(){
		return {
			recipientTypes: [
				{
					'id': 'to',
					'name': 'Címzett'
				},
				{
					'id': 'cc',
					'name': 'CC'
				},
				{
					'id': 'bcc',
					'name': 'BCC'
				},
			],
			specialTriggers: [
				{
					id: 'inventoryShipmentGenerated',
					name: 'Szállítólevél generálva'
				},
				{
					id: 'installationPlanned',
					name: 'Telepítés kitűzve'
				},
			],
			visibleBundles: []
		}
	},
	methods: {
		addRecipient: function (bundleKey){
			this.componentValue[bundleKey].email_recipients.push({
				email: '',
				name: '',
				type: this.componentValue[bundleKey].email_recipients.length ? 'cc' : 'to'
			})
		},
		removeRecipient: function (bundleKey, index){
			this.componentValue[bundleKey].email_recipients.splice(index, 1)
		},
		addDocument: function (bundleKey){
			this.componentValue[bundleKey].documents.push({
				project_type_document_type_id: null,
				form_id: null,
				tag_id: null,
				required_quantity: 1,
				operation: 'and',
			})
		},
		removeDocument: function (bundleKey, index){
			this.componentValue[bundleKey].documents.splice(index, 1)
		},
		addTrigger: function (bundleKey){
			this.componentValue[bundleKey].triggers.push({
				project_type_milestone_id: null,
				form_id: null,
				operation: 'and',
			})
		},
		removeTrigger: function (bundleKey, index){
			this.componentValue[bundleKey].triggers.splice(index, 1)
		},
		addDocumentBundle: function (){
			this.componentValue.push({
				'name': '',
				'send_to_client': 0,
				'send_to_handler': 0,
				'send_email': 0,
				'email_recipients': [],
				'needs_approval': 0,
				'email_content': '',
				'auto_trigger': 0,
				'documents': [],
				'triggers': []
			})
		},
		toggleBundleVisibility: function (index){
			if (this.visibleBundles.indexOf(index) < 0){
				this.visibleBundles.push(index)
			} else {
				this.visibleBundles.splice(this.visibleBundles.indexOf(index), 1)
			}
		}
	}
}
</script>

<style scoped>
</style>
