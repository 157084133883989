<script setup>

import Attribute from "../Attribute.vue";
import {computed} from "@vue/compat";
import Price from "../Price.vue";
import ColorBadge from "../ColorBadge.vue";

const model = defineModel()
const props = defineProps({
	net: Boolean,
	gross: Boolean,
	total: Boolean,
	vat: Boolean,
	currencyId: Number,
})

const label = computed(() => {
	if (props.net){
		return props.total ? 'Nettó végösszeg' : 'Nettó'
	}
	if (props.gross){
		return props.total ? 'Bruttó végösszeg' : 'Bruttó'
	}
	if (props.vat){
		return 'ÁFA'
	}

	return 'Ár'
})
</script>

<template>
	<attribute
		icon="far fa-coins"
		:label="label"
		>
		<color-badge
			color="light"
			small
		>
			<price :value="model" :currency-id="currencyId" />
		</color-badge>
	</attribute>
</template>

<style scoped>

</style>
