<template>
	<div style="height: 400px;overflow-y:scroll;" id="notificationList">
		<div class="d-flex flex-column gap-1">
			<div :class="{'text-reset': 1, 'notification-item': 1, 'read': notification.read_at}" v-for="notification in app.notifications" :key="notification.id">
				<div class="d-flex">
					<div class="avatar-xs me-3">
						<span :class="'avatar-title bg-' + notification.color + ' rounded-circle font-size-16'">
							<i :class="notification.icon"></i>
						</span>
					</div>
					<div class="flex-grow-1">
						<h6 class="mt-0 mb-1">{{ notification.title }}</h6>
						<div class="font-size-12 text-muted">
							<p class="mb-1">{{ notification.description }}</p>
							<div class="d-flex gap-2">
								<div>
									<i class="far fa-clock"></i> <RelativeTime v-model="notification.created_at" />
								</div>
								<div v-if="notification.read_at" class="text-success">
									<i class="far fa-check"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Link :href="'/notifications/redirect/' + notification.id"></Link>
			</div>
		</div>

		<div class="alert alert-primary" v-if="!app.notifications.length">Nincs értesítésed</div>
	</div>
	<div class="pt-3 border-top d-grid">
		<Link class="btn btn-sm btn-link font-size-14 text-center" href="/notifications">
			<i class="far fa-angle-right me-1"></i> <span key="t-view-more">Összes megtekintése</span>
		</Link>
	</div>
</template>

<script>
import RelativeTime from "../../components/RelativeTime.vue";
import { usePage, Link } from '@inertiajs/vue3';
import {useApplicationStore} from "../../stores/applicationStore";

export default {
	components: {RelativeTime, Link},
	data(){
		return {
			page: usePage(),
			app: useApplicationStore()
		}
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
