<script setup>
import ProjectBadge from "../badges/ProjectBadge.vue";
import Attribute from "../Attribute.vue";
import InquiryBadge from "../badges/InquiryBadge.vue";
import {computed} from "@vue/compat";
import WarehouseBadge from "../badges/WarehouseBadge.vue";

const props = defineProps({
	warehouseId: Number,
	source: Boolean,
	destination: Boolean,
})

const label = computed(() => {
	if (props.source){
		return 'Raktárból'
	}
	if (props.destination){
		return 'Raktárba'
	}

	return 'Raktár'
})
</script>

<template>
	<attribute
		:label="label"
		icon="far fa-warehouse-alt"
		v-if="warehouseId"
	>
		<warehouse-badge :warehouse-id="warehouseId" :key="'WH' + warehouseId"/>
	</attribute>
</template>

<style scoped>

</style>
