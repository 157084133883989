<template>
	<TitleBar title="Irányítópult"/>

	<ClientAreaNotifications/>

	<div v-if="!projects.length" class="alert alert-primary">
		{{ $t('Még nincs projektje.') }}
	</div>
	<div class="row" v-else>
		<div class="col-md-6 col-xl-4" v-for="project in projects">
			<div class="card project-card">
				<div :class="'card-header d-flex align-items-center card-header-' + project.project_type.luminosity"
					 :style="'background: ' + project.project_type.color">
					<i :class="'row-icon big far fa-' + project.project_type.icon"></i>
					<div>
						<strong>{{ project.name }}</strong><br/>
						{{ project.public_id }}
					</div>
				</div>
				<div class="px-2">
					<div class="project-card-address">
						<div><strong>{{ $t('Telepítési cím') }}</strong></div>
						<div>{{ project.deploy_address ? project.deploy_address.formatted : $t('nincs megadva') }}</div>
					</div>
					<Link :href="route('clientArea.projects.view', project.id)"
					   class="btn btn-link">{{ $t('Projekt részletei') }} <i class="far fa-angle-right ms-1"></i></Link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ClientAreaLayout from "../layouts/ClientAreaLayout.vue";
import {Link} from "@inertiajs/vue3";
import TitleBar from "../components/TitleBar.vue";
import ClientAreaNotifications from "../components/ClientAreaNotifications.vue";

export default {
	layout: ClientAreaLayout,
	components: {ClientAreaNotifications, TitleBar, Link},
	props: {
		projects: Array,
		client: Object
	}
}
</script>

<style scoped>
.project-card .card-header {
	padding: 20px;
}

.project-card-address {
	padding: 0 20px;
	height: 90px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.project-card .btn-link {
	padding: 20px;
	display: block;
	color: var(--bs-gray-600)
}

.project-card .btn-link:hover {
	color: var(--bs-primary);
}
</style>
