<template>

	<div>
		<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
			<TitleBar :title="dataStore.getData('inventoryShipmentTypes')[attributes.type].name + ' hozzáadása'" />
		</data-loader>
		<form-wrapper
			ref="formWrapper"
			:store-url="route('inventory.create.' + attributes.type)"
			:back-url="route('inventory.shipments')"
			v-model="attributes"
			save-button-label="Tovább"
			save-button-icon="fas fa-angle-right"
			:disable-redirect="true"
		>
			<template #default="{activeTab, errors}">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title mb-3">{{ attributes.type === 'inspection' ? 'Leltár adatai' : 'Szállítmány adatai'}}</h4>
						<div class="row">
							<div class="col-md-6">
								<template v-if="attributes.type === 'incoming'">
									<supplier-select-field
										label="Beszállító"
										name="supplier_id"
										rules="required"
										v-model="attributes.supplier_id"
										:errors="errors"
									/>

									<warehouse-select-field
										label="Raktár"
										name="destination_warehouse_id"
										rules="required"
										v-model="attributes.destination_warehouse_id"
										:errors="errors"
									/>
									<input-field
										label="Szállítólevél azonosító"
										name="foreign_id"
										rules="required"
										v-model="attributes.foreign_id"
										:errors="errors"
									/>
								</template>
								<template v-if="attributes.type === 'transfer'">
									<warehouse-select-field
										label="Forrás raktár"
										name="source_warehouse_id"
										rules="required"
										v-model="attributes.source_warehouse_id"
										:errors="errors"
									/>
									<warehouse-select-field
										label="Cél raktár"
										name="destination_warehouse_id"
										rules="required"
										v-if="attributes.source_warehouse_id"
										v-model="attributes.destination_warehouse_id"
										:excluded-id="attributes.source_warehouse_id"
										:errors="errors"
									/>
								</template>
								<template v-if="attributes.type === 'delivery'">
									<warehouse-select-field
										label="Raktár"
										name="source_warehouse_id"
										rules="required"
										v-model="attributes.source_warehouse_id"
										:errors="errors"
									/>

									<template v-if="app.hasPermission('InventoryController_createDeliveryWithoutContract')">
										<contract-select
											label="Szerződés"
											name="contract_id"
											v-model="attributes.contract_id"
											:errors="errors"
										/>
										<template v-if="!attributes.contract_id">
											<input-field
												label="Címzett neve"
												name="destination_name"
												rules="required"
												v-model="attributes.destination_name"
												:errors="errors"
											/>
											<input-field
												label="Címzett címe"
												name="destination_address"
												rules="required"
												v-model="attributes.destination_address"
												:errors="errors"
											/>
										</template>
									</template>
									<template v-else>
										<contract-select
											label="Szerződés"
											name="contract_id"
											v-model="attributes.contract_id"
											:errors="errors"
											rules="required"
										/>
									</template>

									<date-field
										label="Kiszállítás dátuma"
										name="foreign_id"
										v-model="attributes.shipment_date"
										:errors="errors"
										info="Akkor kell megadni, amikor nem a szállítólevél készítésének napján történik a szállítás, így a megrendelő a megfelelő dátumot kapja értesítésben."
										future
									/>
								</template>
								<template v-if="attributes.type === 'return'">
									<warehouse-select-field
										label="Raktár"
										name="destination_warehouse_id"
										rules="required"
										v-model="attributes.destination_warehouse_id"
										:errors="errors"
									/>
									<template v-if="app.hasPermission('InventoryController_createDeliveryWithoutContract')">
										<contract-select
											label="Szerződés"
											name="contract_id"
											v-model="attributes.contract_id"
											:errors="errors"
											v-if="!attributes.inventory_shipment_id"
										/>
										<inventory-shipment-select
											label="Szállítmány"
											type-filter="delivery"
											name="inventory_shipment_id"
											v-model="attributes.inventory_shipment_id"
											:errors="errors"
											v-if="!attributes.contract_id"
										/>
									</template>
									<template v-else>
										<contract-select
											label="Szerződés"
											name="contract_id"
											v-model="attributes.contract_id"
											:errors="errors"
											v-if="!attributes.inventory_shipment_id"
											rules="required"
										/>
									</template>
								</template>
								<template v-if="attributes.type === 'correction'">
									<warehouse-select-field
										label="Raktár"
										name="source_warehouse_id"
										rules="required"
										v-model="attributes.source_warehouse_id"
										:errors="errors"
									/>

									<data-loader :data="['inventoryCorrectionTypes']" v-slot="{ dataStore }">
										<select-field
											label="Típus"
											name="correction_type"
											rules="required"
											data-type="string"
											v-model="attributes.correction_type"
											:options="dataStore.getDataAsArray('inventoryCorrectionTypes')"
											:errors="errors"
										/>

										<inventory-shipment-select
											label="Szállítmány"
											name="inventory_shipment_id"
											rules="required"
											type-filter="incoming"
											v-model="attributes.inventory_shipment_id"
											:errors="errors"
											v-if="attributes.correction_type && dataStore.getData('inventoryCorrectionTypes', true)[attributes.correction_type].hasInventoryShipment"
										/>
									</data-loader>
								</template>
								<template v-if="attributes.type === 'inspection'">
									<warehouse-select-field
										label="Raktár"
										name="source_warehouse_id"
										rules="required"
										v-model="attributes.source_warehouse_id"
										:errors="errors"
									/>
								</template>
							</div>

							<div class="col-md-6">
								<TextareaField
									name="comment"
									:rows="4"
									v-model="attributes.comment"
									label="Megjegyzés"
								></TextareaField>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-if="attributes.type === 'incoming'">
					<div class="card-body">
						<h4 class="card-title mb-3">Dokumentumok</h4>
						<FileUploader
							field-name="file"
							v-model="attributes.files"
							:editable="true"
							file-type="file"
						></FileUploader>
					</div>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import ProductSelectField from "../components/form/ProductSelectField.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import InventoryAddProductForm from "../modules/inventory/InventoryAddProductForm.vue";
import vSelect from "vue-select";
import UserBadge from "../components/badges/UserBadge.vue";
import FileList from "../components/FileList.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {useGeneralDataStore} from "../stores/generalData";
import {useFlashStoreStore} from "../stores/flashStore";
import printJS from "print-js";
import {Link} from "@inertiajs/vue3";
import InventoryShipmentForm from "../modules/inventory/InventoryShipmentForm.vue";
import {useApplicationStore} from "../stores/applicationStore";
import Number from "../components/Number.vue";
import DateTime from "../components/DateTime.vue";
import TitleBar from "../components/TitleBar.vue";
import ColorBadge from "../components/ColorBadge.vue";
import FormWrapper from "../components/FormWrapper.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import Modal from "../components/Modal.vue";
import Attribute from "../components/Attribute.vue";
import SelectField from "../components/form/SelectField.vue";
import InventoryShipmentSelect from "../components/form/InventoryShipmentSelect.vue";
import ContractSelect from "../components/form/ContractSelect.vue";
import TextareaField from "../components/form/TextareaField.vue";
import FileUploader from "../components/FileUploader.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import SupplierSelectField from "../components/form/SupplierSelectField.vue";
import DateField from "../components/form/DateField.vue";

export default {
	layout: AppLayout,
	components: {
		DateField,
		SupplierSelectField,
		WarehouseSelectField, FileUploader, TextareaField, ContractSelect, InventoryShipmentSelect, SelectField,
		Attribute,
		Modal,
		ButtonGroup,
		FormWrapper,
		ColorBadge,
		TitleBar,
		DateTime,
		Number,
		InventoryShipmentForm,
		FileList, UserBadge, vSelect, Link, InventoryAddProductForm, DataLoader, InputField, ProductSelectField},
	props: {
		model: Object,
		contracts: Array,
		shipments: Array,
		warehouses: Array,
	},
	data(){
		return {
			attributes: this.model,
			dataStore: useGeneralDataStore(),
			app: useApplicationStore()
		}
	},
	created(){
	},
	computed: {
		shipmentSearchUrlFormatted: {
			get(){
				if (this.attributes.correction_type && this.dataStore.getData('inventoryCorrectionTypes')[this.attributes.correction_type].hasInventoryShipment){
					return route('inventory.shipmentSearch') + '?type=' + this.dataStore.getData('inventoryCorrectionTypes')[this.attributes.correction_type].hasInventoryShipment + '&q='
				}

				return route('inventory.shipmentSearch') + '?q='
			}
		},
		destinationWarehouses: {
			get(){
				let payload = _.filter(this.warehouses, (item) => {
					return item.id !== this.attributes.source_warehouse_id
				})

				if (this.attributes.source_warehouse_id === this.attributes.destination_warehouse_id){
					let first = _.first(payload)
					if (first) {
						this.attributes.destination_warehouse_id = first.id
					} else {
						this.attributes.destination_warehouse_id = ''
					}
				}

				return payload
			}
		}
	},
	methods: {

	}
}
</script>
