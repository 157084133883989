<template>
	<TitleBar title="Szállítmányok" />

	<AdvancedDataTable
		:data-url="route('inventory.shipments.list')"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		name="inventoryShipments"
		:set-options="setTableOptions"
	>
		<template #navigation>
			<ButtonGroup>
				<template #add>
					<AddShipmentButton/>
				</template>
				<template #index>
					<Link :href="route('inventory.index')" class="btn btn-secondary btn-label"><i class="far fa-inventory label-icon"></i> Raktárkészlet</Link>
				</template>
				<template #detailed>
					<Link :href="route('inventory.detailed')" class="btn btn-secondary btn-label"><i
						class="far fa-clipboard-list label-icon"></i> Részletes készlet
					</Link>
				</template>
				<template #delivered>
					<Link :href="route('inventory.delivered')" class="btn btn-secondary btn-label"><i
						class="far fa-truck-loading label-icon"></i> Kiszállított áruk
					</Link>
				</template>
			</ButtonGroup>
		</template>
		<template #filters="{serverOptions}">
			<div class="data-table-filter-item">
				<warehouse-select-field
					v-model="serverOptions.warehouseId"
					name="warehouseId"
					label="Raktár"
					:field-only="true"
				/>
			</div>
			<div class="data-table-filter-item">
				<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
					<select-field
						v-model="serverOptions.type"
						label="Típus"
						name="type"
						:field-only="true"
						:display-badge="true"
						:options="dataStore.getDataAsArray('inventoryShipmentTypes')"
					>
					</select-field>
				</data-loader>
			</div>
			<div class="data-table-filter-item">
				<supplier-select-field
					v-model="serverOptions.supplierId"
					label="Beszállító"
					name="supplierId"
					:field-only="true"
				>
				</supplier-select-field>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<inventory-shipment-badge :shipment-id="item.id" :public-id="item.foreign_id ? item.foreign_id : item.public_id" />

						<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
							<color-badge :value="dataStore.getData('inventoryShipmentTypes')[item.type]"></color-badge>
						</data-loader>

						<div class="mobile-horizontal">
							<shipment-source-cell :item="item" small />
							<shipment-destination-cell :item="item" small />
						</div>

						<div class="mobile-horizontal text">
							<attribute icon="far fa-user">
								{{ item.createdByUserName }}
							</attribute>
							<attribute icon="far fa-clock">
								<date-time :value="item.created_at"/>
							</attribute>
						</div>

						<color-badge
							v-if="item.closed_at !== null"
							color="success"
							icon="far fa-check">
							Lezárva: <DateTime :value="item.closed_at"/>
						</color-badge>
						<color-badge
							v-else
							color="danger"
							icon="far fa-lock-open">
							Nincs lezárva
						</color-badge>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('inventory.shipment')"
					:delete-url="route('inventory.shipments.delete')"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
				>
					<a :href="'/inventory/shipment/download/' + item.id" v-if="item.file_id" class="btn btn-warning btn-sm" title="Szállítólevél letöltése"><i class="far fa-download"></i></a>
				</TableActionCell>
			</div>
		</template>
		<template #item-created_at="item">
			<div class="stacked-cell">
				<inventory-shipment-badge :shipment-id="item.id" :public-id="item.foreign_id ? item.foreign_id : item.public_id" />
				<date-time-attribute :value="item.created_at" created/>

				<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
					<color-badge :value="dataStore.getData('inventoryShipmentTypes')[item.type]" small></color-badge>
				</data-loader>
				<div v-if="item.type === 'correction' && item.inventory_shipment_id">
					<Link :href="'/inventory/shipment/' + item.inventory_shipment_id"><i class="far fa-link"></i> {{ item.correction_public_id }}</Link>
				</div>
			</div>
		</template>
		<template #item-closed_at="item">
			<color-badge
				v-if="item.closed_at !== null"
				color="success"
				small
				icon="far fa-check">
				<DateTime :value="item.closed_at"/>
			</color-badge>
			<color-badge
				v-else
				color="danger"
				small
				icon="far fa-lock-open">
				Nincs lezárva
			</color-badge>
		</template>
		<template #item-source="item">
			<div class="stacked-cell">
				<shipment-source-cell :item="item" small />
				<shipment-destination-cell :item="item" small />
			</div>
		</template>
		<template #item-createdByUserName="item">
			<user-badge :user-id="item.created_by_user_id" hide-level :key="'US_' + item.created_by_user_id" />
		</template>
	</AdvancedDataTable>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import AddShipmentButton from "../modules/inventory/AddShipmentButton.vue";
import {Link} from "@inertiajs/vue3";
import TableActionCell from "../components/TableActionCell.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import SelectField from "../components/form/SelectField.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import ColorBadge from "../components/ColorBadge.vue";
import DateTime from "../components/DateTime.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import Number from "../components/Number.vue";
import {useGeneralDataStore} from "../stores/generalData";
import Attribute from "../components/Attribute.vue";
import ShipmentSourceCell from "../modules/inventory/ShipmentSourceCell.vue";
import ShipmentDestinationCell from "../modules/inventory/ShipmentDestinationCell.vue";
import SupplierSelectField from "../components/form/SupplierSelectField.vue";
import InventoryShipmentBadge from "../components/badges/InventoryShipmentBadge.vue";
import DateTimeAttribute from "../components/attributes/DateTimeAttribute.vue";
import UserBadge from "../components/badges/UserBadge.vue";

export default {
	layout: AppLayout,
	components: {
		UserBadge,
		DateTimeAttribute,
		InventoryShipmentBadge,
		SupplierSelectField,
		ShipmentDestinationCell,
		ShipmentSourceCell,
		Attribute,
		Number,
		ButtonGroup,
		DateTime,
		ColorBadge,
		DataLoader,
		AdvancedDataTable,
		SelectField,
		WarehouseSelectField,
		TableActionCell,
		AddShipmentButton, TitleBar, Link
	},
	props: {
		supplierId: Number
	},
	mounted(){
	},
	data(){
		let columns = [
			{
				text: "Adatok",
				value: "created_at",
				wrap: false,
				sortable: true
			},
			{
				text: "Lezárva",
				value: "closed_at",
				sortable: true
			},
			{
				text: "Forrás/cél",
				value: "source",
				sortable: false
			},
			{
				text: "Felhasználó",
				value: "createdByUserName",
				sortable: true
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			dataStore: useGeneralDataStore()
		}
	},
	methods: {
		setTableOptions: function (){
			let payload = {}

			if (this.supplierId){
				payload.supplierId = this.supplierId
			}

			return payload
		}
	}
}
</script>


<style scoped>

</style>
