<template>
	<TitleBar :title="getTitle()" />

	<AdvancedDataTable
		:data-url="route('projects.list') + '?mode=' + mode"
		:columns="columns"
		sort-by="public_id"
		ref="dataTable"
		sort-type="desc"
		name="projects"
		:run-at-init="tableInited"
		:required-data="['projectTypes', 'milestones']"
		:map-marker-icon="getMarkerIcon"
		:map-marker-color="getMarkerColor"
		:map-visit-url="route('projects.update')"
		:set-options="setTableOptions"
		controls-in-navigation
		has-map-view
	>
		<template #navigation>
			<ButtonGroup>
				<template #index v-if="mode === 'deleted'">
					<Link :href="route('projects.index')"
						  class="btn btn-secondary btn-label">
						<i class="fas fa-angle-left label-icon"></i> Vissza
					</Link>
				</template>
				<template #index v-if="selectedUser">
					<Link :href="route('projects.index')"
						  class="btn btn-secondary btn-label">
						<i class="fas fa-angle-left label-icon"></i> Összes projekt
					</Link>
				</template>
				<template #create v-if="mode !== 'deleted' && app.hasPermission('ProjectsController_create')">
					<Link :href="route('projects.create')"
						  v-if="mode !== 'deleted' && app.hasPermission('ProjectsController_create')"
						  class="btn btn-primary btn-label">
						<i class="fas fa-plus label-icon"></i> Hozzáadás
					</Link>
				</template>
				<template #deleted v-if="app.hasPermission('ProjectsController_deleted') && mode !== 'deleted'">
					<Link :href="route('projects.deleted')"
						  v-if="mode !== 'deleted' && app.hasPermission('ProjectsController_deleted')"
						  class="btn btn-secondary btn-label">
						<i class="fas fa-trash label-icon"></i> Törölt projektek
					</Link>
				</template>
			</ButtonGroup>
		</template>

		<template #filters="{serverOptions}">
			<div class="data-table-filter-item data-table-filter-item-25">
				<project-type-select-field
					v-model="serverOptions.project_type_id"
					label="Típus"
					:field-only="true"
					name="project_type_id"
				/>
			</div>
			<div class="data-table-filter-item data-table-filter-item-25">
				<data-loader :data="['milestones']" v-slot="{ dataStore }">
					<v-select
						v-model="serverOptions.project_type_milestone_id"
						:options="getMilestoneSelectOptions(serverOptions.project_type_id)"
						:reduce="option => option.id"
						placeholder="Mérföldkő"
						label="name"
						:multiple="false"
						:clearable="true"
					>
						<template v-slot:option="option">
							<template v-if="serverOptions.project_type_id">
								<color-badge :value="option" small/>
							</template>
							<template v-else>
								{{ getProjectTypeById(option.project_type_id).name }}
								<div class="mt-2">
									<color-badge :value="option" small />
								</div>
							</template>
						</template>
						<template v-slot:selected-option="option">
							<template v-if="serverOptions.project_type_id">
								<color-badge :value="option" small/>
							</template>
							<template v-else>
								<div>
									{{ getProjectTypeById(option.project_type_id).name }}
									<div class="mt-2">
										<color-badge :value="option" small/>
									</div>
								</div>
							</template>
						</template>
					</v-select>
				</data-loader>
			</div>
			<div class="data-table-filter-item data-table-filter-item-25">
				<select-field
					v-model="serverOptions.status"
					:options="projectStatuses"
					label="Állapot"
					rules="required"
					name="status"
					field-only
					display-badge
					small-badge
				>
				</select-field>
			</div>
			<div class="data-table-filter-item data-table-filter-item-25">
				<select-field
					v-model="serverOptions.event"
					:options="projectEventsArray"
					label="Esemény"
					data-type="string"
					name="events"
					field-only
					display-badge
					small-badge
				>
				</select-field>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div>
						<ClientCell :data="item" v-if="!clientId"/>
					</div>
					<div>
						<ProjectTableProjectCell :item="item"/>
					</div>
					<div>
						<ProjectTypeCell :data="item" :small="true"/>
					</div>
					<ProjectMilestonesCell :data="item"/>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="mode !== 'deleted' ? route('projects.update') : ''"
					:delete-url="mode !== 'deleted' ? route('projects.delete') : ''"
					:is-editable="isEditable"
					:is-deletable="isDeletable"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
				>
					<Link :href="route('projects.restore') + '/' + item.id" class="btn btn-secondary btn-sm" title="Visszaállít" v-if="mode === 'deleted'"><i class="far fa-trash-undo"></i></Link>
				</TableActionCell>
			</div>
		</template>
		<template #item-public_id="item">
			<ProjectTableProjectCell :item="item"/>
		</template>
		<template #item-clientName="item">
			<ClientCell :data="item"/>
		</template>
		<template #item-type="item">
			<ProjectTypeCell :data="item" :small="true"/>
		</template>
		<template #item-milestones="item">
			<ProjectMilestonesCell :data="item"/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TableActionCell from "../components/TableActionCell.vue";
import ProjectTypeCell from "../modules/projects/ProjectTypeCell.vue";
import SelectField from "../components/form/SelectField.vue";
import DataLoader from "../components/DataLoader.vue";
import vSelect from "vue-select";
import ColorBadge from "../components/ColorBadge.vue";
import ProjectMilestonesCell from "../modules/projects/ProjectMilestonesCell.vue";
import ProjectTypeSelectField from "../components/form/ProjectTypeSelectField.vue";
import ClientCell from "../modules/projects/ClientCell.vue";
import TitleBar from "../components/TitleBar.vue";
import {useGeneralDataStore} from "../stores/generalData";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import ProjectTableProjectCell from "../modules/projects/ProjectTableProjectCell.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import {objectToArray} from "../functions";
import FormWrapper from "../components/FormWrapper.vue";

export default {
	components: {
		FormWrapper,
		ButtonGroup,
		ProjectTableProjectCell,
		TitleBar,
		AdvancedDataTable,
		ClientCell,
		ProjectTypeSelectField,
		ProjectMilestonesCell,
		ColorBadge,
		vSelect,
		DataLoader,
		SelectField,
		ProjectTypeCell,
		Link,
		TableActionCell
	},
	layout: AppLayout,
	props: {
		mode: String,
		projectEvents: Object,
		clientId: {
			type: Number,
			default: 0
		},
		selectedUser: Object
	},
	data(){
		let columns = [{
			text: 'Adatok',
			value: 'public_id',
			sortable: true,
		}]
		if (this.clientId < 1){
			columns.push({
				text: 'Ügyfél',
				value: 'clientName',
				sortable: true,
			})
		}
		columns.push({
			text: 'Típus',
			value: 'type',
			sortable: true,
		})
		columns.push({
			text: 'Haladás',
			value: 'milestones',
			sortable: false,
		})
		columns.push({
			label: '',
			value: 'operations',
			width: 5,
		})

		let listDataUrl = this.dataUrl
		if (this.clientId){
			listDataUrl += '/' + this.clientId
		}

		return {
			columns: columns,
			listDataUrl: listDataUrl,
			dataStore: useGeneralDataStore(),
			app: useApplicationStore(),
			projectStatuses: [
				{
					id: 'all',
					label: 'Összes',
					icon: 'fas fa-eye',
					color: 'light'
				},
				{
					id: 'active',
					label: 'Aktív',
					icon: 'fas fa-power-off',
					color: 'primary'
				},
				{
					id: 'closed',
					label: 'Lezárt',
					icon: 'fas fa-check',
					color: 'success'
				},
				{
					id: 'postponed',
					label: 'Elhalasztva',
					icon: 'fas fa-clock',
					color: 'warning'
				},
			]
		}
	},
	computed: {
		projectEventsArray: {
			get() {
				return objectToArray(this.projectEvents, true)
			}
		},
	},
	methods: {
		isEditable: function(row){
			return row.editable && this.mode !== 'deleted' && !row.clientDeletedAt
		},
		isDeletable: function(row){
			return row.deletable && this.mode !== 'deleted'
		},
		getProjectTypeById: function (id){
			for (let i = 0; i < this.dataStore.getData('projectTypes').length; i++){
				if (this.dataStore.getData('projectTypes')[i].id === id){
					return this.dataStore.getData('projectTypes')[i]
				}
			}
		},
		getMilestoneSelectOptions: function(typeId){
			let payload = []
			if (typeId !== null && typeId){
				for (let i = 0; i < this.dataStore.getData('milestones').length; i++){
					if (this.dataStore.getData('milestones')[i].project_type_id === typeId){
						payload.push(this.dataStore.getData('milestones')[i])
					}
				}
			} else {
				payload = this.dataStore.getData('milestones')
			}

			return payload
		},
		tableInited: function (){
			if (this.$refs.dataTable.componentOptions.status === undefined || !this.$refs.dataTable.componentOptions.status){
				this.$refs.dataTable.componentOptions.status = 'active'
			}
		},
		getMarkerIcon: function (item){
			if (this.dataStore.getData('projectTypes', true)[item.project_type_id] === undefined){
				return ''
			}
			let path = this.dataStore.getData('projectTypes', true)[item.project_type_id].iconPath
			let width = this.dataStore.getData('projectTypes', true)[item.project_type_id].iconWidth
			let height = this.dataStore.getData('projectTypes', true)[item.project_type_id].iconHeight

			if (path !== ''){
				return '<g transform="translate(-' + (width / 2) + ', -' + (height / 2) + ')"><path d="' + path + '"></path></g>'
			}

			return ''
		},
		getMarkerColor: function (item){
			if (item.closed_at){
				if (item.postponed_until){
					return '#f1b44c'
				} else {
					return '#34C38F'
				}
			}

			return '#4CC1EF'
		},
		getTitle(){
			if (this.selectedUser !== undefined && this.selectedUser !== null){
				return this.selectedUser.name + ' projektjei'
			}
			return this.mode === 'deleted' ? 'Törölt projektek' : 'Projektlista'
		},
		setTableOptions: function (){
			let payload = {}

			if (this.selectedUser !== undefined && this.selectedUser !== null){
				payload.handler_user_id = this.selectedUser.id
			}

			return payload
		}
	}
}
</script>

<style scoped>
</style>
