<template>
	<TitleBar title="Hírek" />

	<AdvancedDataTable
		:data-url="route('posts.list')"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		name="posts"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('PostsController_modify')">
			<Link :href="route('posts.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.title }}</strong>
						<attribute icon="far fa-clock">
							<date-time :value="item.created_at"/>
						</attribute>

						<data-loader :data="['roles']" v-slot="{ dataStore }">
							{{ getRoles(item, dataStore.getData('roles')) }}
						</data-loader>
					</div>

				</div>
				<TableActionCell
					:data="item"
					:edit-url="app.hasPermission('PostsController_modify') ? route('posts.update') : ''"
					:delete-url="route('posts.delete')"
					:is-editable="app.hasPermission('PostsController_modify')"
					:is-deletable="app.hasPermission('PostsController_modify')"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				>
					<Link :href="route('posts.view') + '/' + item.id" class="btn btn-info btn-sm" title="Olvas"><i class="far fa-eye"></i></Link>
				</TableActionCell>
			</div>
		</template>
		<template #item-is_public="item">
			<color-badge
				:color="item.is_public ? 'success' : 'light'"
				:icon="item.is_public ? 'far fa-check' : 'far fa-hourglass'"
				:content="item.is_public ? 'Publikus' : 'Rejtett'"
				/>
		</template>
		<template #item-created_at="item">
			<date-time :value="item.created_at"/>
		</template>
		<template #item-public_date="item">
			<date-time v-if="item.is_public" :value="item.public_date"/>
		</template>
		<template #item-roles="item">
			<data-loader :data="['roles']" v-slot="{ dataStore }">
				{{ getRoles(item, dataStore.getData('roles')) }}
			</data-loader>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import {Head, Link} from "@inertiajs/vue3";
import TableActionCell from "../components/TableActionCell.vue";
import DataLoader from "../components/DataLoader.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import TitleBar from "../components/TitleBar.vue";
import {useApplicationStore} from "../stores/applicationStore";
import DateTime from "../components/DateTime.vue";
import EmailAddress from "../components/EmailAddress.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import Attribute from "../components/Attribute.vue";
import ColorBadge from "../components/ColorBadge.vue";

export default {
	components: {
		ColorBadge,
		Attribute,
		PhoneNumber,
		EmailAddress,
		DateTime, TitleBar, DataLoader, TableActionCell, Head, AppLayout, AdvancedDataTable, Link},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Publikus',
				value: 'is_public',
				sortable: true
			},
			{
				text: 'Cím',
				value: 'title',
				sortable: true
			},
			{
				text: 'Publikus dátum',
				value: 'public_date',
				sortable: true
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true
			}
		]

		if (this.canModify){
			columns.push({
				text: 'Szerepek',
				value: 'roles',
				sortable: false
			})
		}
		columns.push({
			label: '',
			value: 'operations',
			width: 5,
		})

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
	methods: {
		getRoles: function(item, roles) {
			if (item.requiredRoles === null){
				return ''
			}
			let roleIdArray = item.requiredRoles.split(',')
			let payload = []
			for (let i in roleIdArray){
				if (roles[roleIdArray[i]] !== undefined){
					payload.push(roles[roleIdArray[i]].name)
				}
			}

			return payload.join(', ')
		}
	},
}
</script>

<style scoped>

</style>
