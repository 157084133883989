<template>
	<TitleBar title="Beszállítók" />

	<AdvancedDataTable
		:data-url="route('suppliers.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="suppliers"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('SuppliersController_create')">
			<Link :href="route('suppliers.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>

						<boolean-badge
							v-model="item.active"
							:key="'activeM_' + item.id"
							active
						/>

						<div class="mobile-horizontal">
							<phone-number :value="item.phone" v-if="item.phone" :key="'supplierPhoneNumber_' + item.id"/>
							<email-address :value="item.email" v-if="item.email" :key="'supplierEmail_' + item.id"/>
						</div>
						<attribute v-if="item.url" icon="far fa-globe">
							{{ item.url }}
						</attribute>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('suppliers.update')"
					:delete-url="route('suppliers.delete')"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
		<template #item-active="item">
			<boolean-badge
				v-model="item.active"
				:key="'active_' + item.id"
			/>
		</template>
		<template #item-phone="item">
			<phone-number :value="item.phone" v-if="item.phone" :key="'supplierPhoneNumber_' + item.id"/>
		</template>
		<template #item-email="item">
			<email-address :value="item.email" v-if="item.email" :key="'supplierEmail_' + item.id"/>
		</template>
	</AdvancedDataTable>
</template>
<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import TableActionCell from "../components/TableActionCell.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import DataLoader from "../components/DataLoader.vue";
import ColorBadge from "../components/ColorBadge.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import EmailAddress from "../components/EmailAddress.vue";
import Attribute from "../components/Attribute.vue";

export default {
	components: {
		Attribute,
		EmailAddress,
		PhoneNumber, ColorBadge, DataLoader, AdvancedDataTable, BooleanBadge, TableActionCell, TitleBar, Link},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Aktív',
				value: 'active',
				sortable: true,
				width: 10
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'E-mail',
				value: 'email',
				sortable: true
			},
			{
				text: 'Telefonszám',
				value: 'phone',
				sortable: true
			},
			{
				text: 'Weboldal',
				value: 'url',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
