<template>
	<select-field
		:label="label"
		:name="name"
		v-model="componentValue"
		:options="options"
		:errors="errors"
		:filter-options="filterOptions"
		:search="search"
		:display-search-info-bottom="true"
	>
		<template v-slot:option="option">
			<div class="stacked-cell">
				<div class="horizontal">
					<color-badge color="dark" icon="fas fa-project-diagram" :content="option.option.projectPublicId" :small="true"/>
					<color-badge color="light" icon="far fa-file-contract" :content="option.option.public_id" :small="true"/>
					<span>{{ option.option.clientName }}</span>
				</div>
				<attribute icon="far fa-map-marker" :content="option.option.address" />
			</div>
		</template>
		<template v-slot:selected-option="option">
			<div class="stacked-cell">
				<div class="horizontal">
					<color-badge color="dark" icon="fas fa-project-diagram" :content="option.option.projectPublicId" :small="true"/>
					<color-badge color="light" icon="far fa-file-contract" :content="option.option.public_id" :small="true"/>
					<span>{{ option.option.clientName }}</span>
				</div>
				<attribute icon="far fa-map-marker" :content="option.option.address" />
			</div>
		</template>
	</select-field>
</template>

<script>
import SelectField from "./SelectField.vue";
import ColorBadge from "../ColorBadge.vue";
import Attribute from "../Attribute.vue";

export default {
	emits: ['update:modelValue'],
	components: {Attribute, ColorBadge, SelectField},
	props: {
		modelValue: Number,
		errors: Object,
		name: String,
		label: String,
		typeFilter: String,
		rules: {
			type: String,
			default: ''
		},
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	mounted() {
		this.search(null, '')
	},
	data(){
		return {
			options: []
		}
	},
	methods: {
		search: function (loading, search){
			let url = '/projects/contractSearch?q=' + search
			if (this.typeFilter){
				url += '&type=' + this.typeFilter
			}
			if (this.componentValue){
				url += '&id=' + this.componentValue
			}

			fetch(
				url
			).then(res => {
				res.json().then(json => (this.options = json.results));
				if (loading !== null) {
					loading(false)
				}
			});
		},
		filterOptions: function(options, search){
			return options
		}
	}
}
</script>

<style scoped>

</style>
