<template>
	<TitleBar :title="attributes.id !== undefined ? 'Érdeklődő módosítása' : 'Érdeklődő hozzáadása'" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('inquiries.store', attributes.id)"
		:back-url="route('inquiries.index')"
		v-model="attributes"
		:required-data="['inquiryStatusLabels', 'projectTypes']"
	>
		<template v-slot:buttons>
			<Link :href="route('clients.create', attributes.id)" v-if="!client && attributes.id !== undefined && app.hasPermission('ClientsController_create')" class="btn btn-warning btn-label"><i class="far fa-user-plus label-icon"></i> Ügyfél létrehozása</Link>
		</template>
		<template v-slot:top>
			<div class="card mb-3" v-if="client || attributes.id !== undefined">
				<div class="card-body">
					<div v-if="attributes.id !== undefined" class="details-box mb-0">
						<div v-if="attributes.public_id">
							<i class="far fa-hashtag"></i> Azonosító: <strong>{{ attributes.public_id }}</strong>
						</div>

						<user-attribute :user-id="attributes.handler_user_id" handler />

						<updated-attribute :data="attributes" created/>

						<updated-attribute :data="attributes" updated/>

						<div v-if="attributes.source">
							<i class="far fa-globe"></i> Forrás: <strong>{{ attributes.source }}</strong>
						</div>

						<client-attribute :client-id="client.id" :name="client.name" :public-id="client.public_id" />
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<user-select-field
								v-model="attributes.handler_user_id"
								name="handler_user_id"
								label="Tanácsadó"
								:errors="errors"
								rules="required"
								v-if="app.hasPermission('InquiriesController_updateAll')"
							>
							</user-select-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6">
							<radio-field
								label="Típus"
								v-model="attributes.is_company"
								:disabled="!attributes.editable"
								:values="typeValues"
								:hide-non-active="attributes.id !== undefined"
							/>

							<input-field
								label="Vezetéknév"
								name="last_name"
								rules="required"
								v-model="attributes.last_name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Keresztnév"
								name="first_name"
								rules="required"
								v-model="attributes.first_name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Cégnév"
								name="company_name"
								rules="required"
								v-model="attributes.company_name"
								:disabled="!attributes.editable"
								v-if="attributes.is_company"
								:errors="errors"
							/>

							<data-loader :data="['projectTypes']" v-slot="{ dataStore }">
								<select-field
									label="Projekt típus"
									name="project_type_id"
									rules="required"
									v-model="attributes.project_type_id"
									:disabled="!attributes.editable || attributes.id !== undefined"
									:options="dataStore.getData('projectTypes')"
									:errors="errors"
								/>
							</data-loader>
						</div>
						<div class="col-sm-6">
							<RadioField
								label="Állapot"
								name="status"
								v-model="attributes.status"
								:values="filteredStatusLabels"
							></RadioField>
							<DateField
								label="Emlékeztető időpontja"
								name="postponed_until"
								v-model="attributes.postponed_until"
								v-if="attributes.status === 'postponed'"
							></DateField>
							<input-field
								label="E-mail cím"
								name="email"
								rules="required"
								data-type="email"
								v-model="attributes.email"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<phone-field
								label="Telefonszám"
								name="phone"
								rules="required"
								v-model="attributes.phone"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
						</div>
					</div>
					<textarea-field
						label="Üzenet"
						name="message"
						v-model="attributes.message"
						:disabled="true"
					/>

					<textarea-field
						label="Megjegyzés"
						name="comment"
						v-model="attributes.comment"
						:disabled="!attributes.editable"
						:rows="3"
					/>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import FormWrapper from "../components/FormWrapper.vue";
import SelectField from "../components/form/SelectField.vue";
import DateField from "../components/form/DateField.vue";
import TextareaField from "../components/form/TextareaField.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import RadioField from "../components/form/RadioField.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import UserSelectField from "../components/form/UserSelectField.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {useGeneralDataStore} from "../stores/generalData";
import TitleBar from "../components/TitleBar.vue";
import {Link} from "@inertiajs/vue3";
import {useApplicationStore} from "../stores/applicationStore";
import DateTime from "../components/DateTime.vue";
import PhoneField from "../components/form/PhoneField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import DateTimeAttribute from "../components/attributes/DateTimeAttribute.vue";
import Attribute from "../components/Attribute.vue";
import ClientBadge from "../components/badges/ClientBadge.vue";
import UserAttribute from "../components/attributes/UserAttribute.vue";
import ClientAttribute from "../components/attributes/ClientAttribute.vue";

export default {
	layout: AppLayout,
	props: {
		model: Object,
		client: Object,
	},
	components: {
		ClientAttribute,
		UserAttribute,
		ClientBadge, Attribute,
		DateTimeAttribute,
		UpdatedAttribute,
		PhoneField,
		DateTime,
		TitleBar,
		UserSelectField,
		UserBadge,
		RadioField,
		DataLoader,
		InputField,
		TextareaField,
		DateField,
		SelectField,
		Link,
		FormWrapper
	},
	data(){
		return {
			attributes: this.model,
			loading: false,
			dataStore: useGeneralDataStore(),
			app: useApplicationStore(),
			flash: [],
			typeValues: [
				{
					label: 'Magánszemély',
					icon: 'far fa-user'
				},
				{
					label: 'Cég',
					icon: 'far fa-industry'
				},
			]
		}
	},
	computed: {
		filteredStatusLabels: {
			get(){
				let payload = _.clone(this.dataStore.getData('inquiryStatusLabels'))
				if (this.attributes.status !== 'pending'){
					delete payload.pending
				}
				if (this.client){
					delete payload.deleted
					delete payload.postponed
				} else {
					delete payload.client
				}

				return payload
			}
		}
	},
}
</script>
