<script setup>
import {onMounted, ref} from "vue";
import SelectField from "./SelectField.vue";
import SupplierBadge from "../badges/SupplierBadge.vue";
import ProjectBadge from "../badges/ProjectBadge.vue";
import MoneyAttribute from "../attributes/MoneyAttribute.vue";
import MoneyBadge from "../badges/MoneyBadge.vue";
import Price from "../Price.vue";

const model = defineModel()
const props = defineProps({
	projectId: Number,
	accepted: Boolean,
	errors: Object,
	name: String,
	label: String,
	fieldOnly: Boolean,
	disabled: Boolean,
	showPrice: Boolean,
	showName: Boolean,
	info: String,
	rules: {
		type: String,
		default: ''
	},
})

const options = ref([])
onMounted(async () => {
	console.log('mount')
	load()
})

function load(){
	let url = '/data/select/quote/' + props.projectId
	if (props.accepted){
		url += '?accepted=1'
	}

	fetch(
		url
	).then(res => {
		res.json().then(json => (options.value = json.results));
	});
}
</script>

<template>
	<select-field
		:label="label"
		:name="name"
		v-model="model"
		:options="options"
		:errors="errors"
		:rules="rules"
		:info="info"
		:field-only="fieldOnly"
		:disabled="disabled"
	>
		<template v-slot:option="option">
			<project-badge
				:quote-id="option.option.id"
				:project-id="option.option.project_id"
				:project-type-id="option.option.project_type_id"
				disable-link
			>
				{{ option.option.public_id }}
				<span v-if="showName && option.option.clientName">
					- {{ option.option.clientName }}
				</span>
				<span v-if="showPrice && option.option.total_price_gross">
					- <price :value="option.option.total_price_gross" :currency-id="option.option.currency_id" />
				</span>
			</project-badge>
		</template>
		<template v-slot:selected-option="option">
			<project-badge
				:quote-id="option.option.id"
				:project-id="option.option.project_id"
				:project-type-id="option.option.project_type_id"
				disable-link
			>
				{{ option.option.public_id }}
				<span v-if="showName && option.option.clientName">
					- {{ option.option.clientName }}
				</span>
				<span v-if="showPrice && option.option.total_price_gross">
					- <price :value="option.option.total_price_gross" :currency-id="option.option.currency_id" />
				</span>
			</project-badge>
		</template>
	</select-field>
</template>

<style scoped>

</style>
