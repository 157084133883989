<script setup>
import AppLayout from "../layouts/AppLayout.vue";
import InputField from "../components/form/InputField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import TitleBar from "../components/TitleBar.vue";
import FormWrapper from "../components/FormWrapper.vue";
import ClientSelect from "../components/form/ClientSelect.vue";
import {ref, useTemplateRef} from "vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import CurrencySelectField from "../components/form/CurrencySelectField.vue";
import UserSelectField from "../components/form/UserSelectField.vue";
import {useApplicationStore} from "../stores/applicationStore";
import ProjectProductList from "../modules/projects/ProjectProductList.vue";
import {Link} from "@inertiajs/vue3";
import TextareaField from "../components/form/TextareaField.vue";
import ClientBadge from "../components/badges/ClientBadge.vue";
import ClientAttribute from "../components/attributes/ClientAttribute.vue";
import WarehouseBadge from "../components/badges/WarehouseBadge.vue";
import WarehouseAttribute from "../components/attributes/WarehouseAttribute.vue";
import CurrencyAttribute from "../components/attributes/CurrencyAttribute.vue";
import UserAttribute from "../components/attributes/UserAttribute.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import Attribute from "../components/Attribute.vue";
import SaleStatusBadge from "../components/badges/SaleStatusBadge.vue";
import QuoteBadge from "../components/badges/QuoteBadge.vue";
import RadioField from "../components/form/RadioField.vue";
import DataLoader from "../components/DataLoader.vue";
import SaleInvoiceList from "../modules/sales/SaleInvoiceList.vue";
import MoneyAttribute from "../components/attributes/MoneyAttribute.vue";
import SaleInventoryShipmentList from "../modules/sales/SaleInventoryShipmentList.vue";

const app = useApplicationStore()

const props = defineProps({
	model: Object,
	quote: Object,
	prices: Object,
	info: Object
})

const attributes = ref(_.cloneDeep(props.model))
if (attributes.value.products === undefined || attributes.value.products === null){
	attributes.value.products = []
}
if (attributes.value.products_pivot !== undefined){
	attributes.value.products = attributes.value.products_pivot
}

const priceStats = ref({})
const productPrices = ref(_.cloneDeep(props.prices))
const productList = useTemplateRef('productList')
const form = useTemplateRef('formWrapper')
const formExtraAttributes = ref({})
const tabs = [
	{
		label: 'Adatlap',
		icon: 'far fa-file-spreadsheet',
		name: 'form'
	},
	{
		name: 'invoices',
		label: 'Számlák',
		icon: 'far fa-file-invoice'
	},
	{
		label: 'Szállítmányok',
		icon: 'far fa-truck',
		name: 'shipments'
	},
]

function productChangedDebounced (){
	let func = _.debounce(() => {
		productList.value.productChanged()
	}, 10)

	func()
}

function validationChanged(){

}

function saveWithExtraParameter(parameter){
	formExtraAttributes.value = {}
	formExtraAttributes.value[parameter] = 1

	form.value.submit()
}
</script>

<template>
	<AppLayout>
		<TitleBar title="Eladás" />

		<form-wrapper
			ref="formWrapper"
			:store-url="route('sales.store', model)"
			:back-url="route('sales.index')"
			v-model="attributes"
			:tabs="tabs"
			:display-tabs="!!attributes.id"
			initial-tab="form"
			:extra-attributes="formExtraAttributes"
			:required-data="['products', 'productCategories', 'productManufacturers', 'userLevels', 'warehouses', 'currencies', 'saleStatusLabels', 'productTypes', 'productAttributes']"
		>
			<template v-slot:top>
				<div class="card mb-3" v-if="attributes.id !== undefined">
					<div class="card-body">
						<div class="details-box">
							<attribute label="Állapot" icon="far fa-bolt">
								<sale-status-badge v-model="attributes.status" />
							</attribute>
							<client-attribute :client-id="attributes.client_id" :public-id="info.clientPublicId" :name="info.clientName" />
							<warehouse-attribute :warehouse-id="attributes.warehouse_id" />
							<currency-attribute v-model="attributes.currency_id" />
							<user-attribute :user-id="attributes.handler_user_id" handler />
							<updated-attribute :data="attributes" created />
							<attribute label="Árajánlat" icon="far fa-file-invoice-dollar" v-if="info.quoteId">
								<quote-badge v-model="info.quoteId" :public-id="info.quotePublicId" />
							</attribute>
							<money-attribute v-model="model.total_price_net" net total v-if="model.status !== 'draft'" />
							<money-attribute v-model="model.total_price_gross" gross total v-if="model.status !== 'draft'" />
						</div>
					</div>
				</div>
			</template>

			<template #buttons>
				<button type="button" @click.prevent="saveWithExtraParameter('checkout')" class="btn btn-success btn-label waves-effect" v-if="model.editable && app.hasPermission('SalesController_checkout')">
					<i class="far fa-cash-register label-icon"></i> Eladva
				</button>
				<button type="button"  @click.prevent="saveWithExtraParameter('generateQuote')" class="btn btn-warning btn-label waves-effect" v-if="model.editable && app.hasPermission('SalesController_createQuote')">
					<i class="far fa-file-invoice-dollar label-icon"></i> Árajánlat generálása
				</button>
				<Link :href="'/inventory/createFromSale/' + model.id" class="btn btn-warning btn-label waves-effect" v-if="model.paid_at && !info.hasShipment && app.hasPermission('InventoryController_createDelivery')">
					<i class="far fa-truck label-icon"></i> Szállítólevél létrehozása
				</Link>
				<a :href="'/sales/downloadQuote/' + info.quoteId" class="btn btn-secondary btn-label waves-effect" v-if="info.quoteId">
					<i class="far fa-download label-icon"></i> Árajánlat letöltése
				</a>
				<Link :href="'/sales/acceptQuote/' + info.quoteId" class="btn btn-success btn-label waves-effect" v-if="model.status === 'quote' && info.quoteId">
					<i class="far fa-check label-icon"></i> Árajánlat elfogadása
				</Link>
				<Link :href="'/sales/rejectQuote/' + info.quoteId" class="btn btn-danger btn-label waves-effect" v-if="model.status === 'quote' && info.quoteId">
					<i class="far fa-times label-icon"></i> Árajánlat elutasítása
				</Link>
			</template>

			<template #default="{activeTab, errors}">
				<div :class="{'d-none': activeTab !== 'form'}">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Adatok</h4>
							<div class="row">
								<div class="col-sm-4" v-if="!attributes.id">
									<client-select
										label="Ügyfél"
										name="client_id"
										v-model="attributes.client_id"
										:errors="errors"
										:disabled="!attributes.editable"
										rules="required"
										/>
								</div>
								<div class="col-sm-4" v-if="!attributes.id">
									<warehouse-select-field
										label="Raktár"
										name="warehouse_id"
										v-model="attributes.warehouse_id"
										:errors="errors"
										:disabled="!attributes.editable"
										rules="required"
									/>
								</div>
								<div class="col-sm-4" v-if="!attributes.id">
									<currency-select-field
										label="Pénznem"
										name="currency_id"
										v-model="attributes.currency_id"
										:errors="errors"
										:disabled="!attributes.editable"
										rules="required"
									/>
								</div>
								<div class="col-sm-4" v-if="!attributes.id">
									<user-select-field
										label="Tanácsadó"
										name="handler_user_id"
										v-model="attributes.handler_user_id"
										:errors="errors"
										:disabled="!attributes.editable || !app.hasPermission('SalesController_updateAll')"
										rules="required"
									/>
								</div>
								<div class="col-sm-4">
									<input-field
										label="Árajánlat érvényessége"
										name="quote_expiration"
										v-model="attributes.quote_expiration"
										rules="required"
										data-type="number"
										suffix="nap"
										:errors="errors"
										:disabled="!attributes.editable"
									/>
								</div>
								<div class="col-sm-4">
									<switch-field
										label="Kiszállítást igényel"
										name="requires_delivery"
										v-model="attributes.requires_delivery"
										:errors="errors"
										:disabled="!attributes.editable"
									/>
								</div>
								<div class="col-sm-4">
									<data-loader :data="['paymentMethods']" v-slot="{ dataStore }">
										<RadioField
											:values="dataStore.getData('paymentMethods')"
											label="Fizetési mód"
											name="payment_method"
											ignore-color
											v-model="attributes.payment_method"
										/>
									</data-loader>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<textarea-field
										label="Nyilvános megjegyzés"
										name="comment_public"
										v-model="attributes.comment_public"
										info="Rákerül az árajánlatra"
										:disabled="!attributes.editable"
										/>
								</div>
								<div class="col-md-6">
									<textarea-field
										label="Megjegyzés"
										name="comment"
										v-model="attributes.comment"
										:disabled="!attributes.editable"
										/>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<InputField
										label="Kedvezmény"
										name="quote_discount"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="attributes.discount"
										:disabled="attributes.discount_limits.global === 0 || !attributes.editable"
										:errors="errors"
										:max="attributes.discount_limits.global"
										@change="productChangedDebounced"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény anyag"
										name="quote_discount_material"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="attributes.discount_material"
										:disabled="attributes.discount_limits.material === 0 || !attributes.editable"
										:errors="errors"
										:max="attributes.discount_limits.material"
										@change="productChangedDebounced"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény munka"
										name="quote_discount_work"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="attributes.discount_work"
										:disabled="attributes.discount_limits.work === 0 || !attributes.editable"
										:errors="errors"
										:max="attributes.discount_limits.work"
										@change="productChangedDebounced"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény szolgáltatás"
										name="quote_discount_service"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="attributes.discount_service"
										:disabled="attributes.discount_limits.service === 0 || !attributes.editable"
										:errors="errors"
										:max="attributes.discount_limits.service"
										@change="productChangedDebounced"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény közvetített szolgáltatás"
										name="quote_discount_relayed_service"
										data-type="number"
										:decimals="6"
										suffix-icon="far fa-percent"
										v-model="attributes.discount_relayed_service"
										:disabled="attributes.discount_limits.relayedService === 0 || !attributes.editable"
										:errors="errors"
										:max="attributes.discount_limits.relayedService"
										@change="productChangedDebounced"
									/>
								</div>
							</div>
						</div>
					</div>

					<ProjectProductList
						:data-url="'/sales/calculatePrices'"
						:is-editable="attributes.editable"
						v-model="attributes.products"
						:parent-model="attributes"
						:product-versions="[]"
						:initial-stats="priceStats"
						:initial-prices="productPrices"
						@validationChanged="validationChanged"
						mode="sale"
						ref="productList"
					>
					</ProjectProductList>
				</div>

				<sale-invoice-list v-model="attributes.id" v-if="activeTab === 'invoices'"/>
				<sale-inventory-shipment-list v-model="attributes.id" v-if="activeTab === 'shipments'"/>
			</template>
		</form-wrapper>
	</AppLayout>
</template>

<style scoped>

</style>
