<script setup>
import Attribute from "../Attribute.vue";
import CurrencyBadge from "../badges/CurrencyBadge.vue";

const model = defineModel()
</script>

<template>
	<attribute
		label="Pénznem"
		icon="far fa-dollar-sign"
		v-if="model"
	>
		<currency-badge v-model="model" />
	</attribute>
</template>

<style scoped>

</style>
