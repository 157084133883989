<template>
	<TitleBar title="Dokumentumsablonok" />

	<AdvancedDataTable
		:data-url="route('documentTemplates.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="documentTemplates"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<color-badge
							color="light"
							:content="types[item.type]"/>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('documentTemplates.update')"
				></TableActionCell>
			</div>
		</template>
		<template #item-type="item">
			<color-badge
				color="light"
				:content="types[item.type]"/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import TableActionCell from "../components/TableActionCell.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import AppLayout from "../layouts/AppLayout.vue";
import ColorBadge from "../components/ColorBadge.vue";
import Attribute from "../components/Attribute.vue";
import Number from "../components/Number.vue";

export default {
	layout: AppLayout,
	components: {Number, Attribute, ColorBadge, AdvancedDataTable, TableActionCell, TitleBar},
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Típus',
				value: 'type',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			types: {
				'email': 'E-mail',
				'document': 'Dokumentum',
				'web': 'Weboldal'
			}
		}
	},
}
</script>
