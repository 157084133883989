<template>
	<div class="card">
		<div class="card-body">
			<div class="alert alert-info mb-3">
				Amennyiben itt megadsz százalékos megoszlást, a generált árajánlatokban a kalkulált végösszeg ez alapján lesz elosztva az egyes terméktípusok között.
			</div>

			<table class="table">
				<thead>
				<tr>
					<th>Terméktípus</th>
					<th>Maximum százalékos arány</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(type, typeName) in productTypes" :key="typeName">
					<td><color-badge :value="type"></color-badge></td>
					<td><input-field
						v-model="modelValue[typeName].percent"
						data-type="number"
						suffix-icon="far fa-percent"
						:name="'pricing_' + typeName + '_percent'"
						:min="0"
						:max="100"
						:step="0.01"
						></input-field>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue";
import ColorBadge from "../../components/ColorBadge.vue";
export default {
	emits: ['update:modelValue', 'validate'],
	components: {
		ColorBadge,
		InputField,
	},
	props: {
		modelValue: Object,
		productTypes: Object,
		errors: Object,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
	},
	mounted() {
	},
	data(){
		return {
		}
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
