<template>
	<TitleBar :title="attributes.id !== undefined ? 'Tulajdonság módosítása' : 'Tulajdonság hozzáadása'" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('productAttributes.store', attributes.id)"
		:back-url="route('productAttributes.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
								:multilang="true"
								v-model:translations="attributes.translations"
							/>
							<input-field
								label="Publikus név"
								name="name_public"
								rules="required"
								v-model="attributes.name_public"
								:disabled="!attributes.editable"
								:errors="errors"
								:multilang="true"
								v-model:translations="attributes.translations"
							/>
							<order-field
								v-model="attributes"
								label="Sorrend"
							/>
							<switch-field
								label="Több darab megadható"
								name="allow_multiple"
								v-model="attributes.allow_multiple"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<switch-field
								label="Árajánlatban mutat"
								name="show_in_quote"
								v-model="attributes.show_in_quote"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<product-category-select-field
								label="Termékkategóriák"
								name="product_categories"
								v-model="attributes.product_categories"
								:disabled="!attributes.editable"
								:errors="errors"
								:multiple="true"
								info="Amennyiben ki van választva, csak az adott kategóriákban lévő termékekhez választható a tulajdonság"
							/>
							<product-category-select-field
								label="Termékkategóriák tiltása"
								name="disabled_product_categories"
								v-model="attributes.disabled_product_categories"
								:disabled="!attributes.editable"
								:errors="errors"
								:multiple="true"
								info="Amennyiben ki van választva, adott kategóriákban lévő termékekhez nem választható a tulajdonság"
							/>
						</div>
						<div class="col-sm-6">
							<data-loader :data="['productAttributeTypes']" v-slot="{ dataStore }">
								<select-field
									label="Típus"
									:options="dataStore.getDataAsArray('productAttributeTypes')"
									rules="required"
									name="type"
									data-type="string"
									v-model="attributes.type"
									:disabled="!attributes.editable"
									:errors="errors"
									:display-badge="true"
								/>
							</data-loader>
							<div class="alert alert-info">
								A mértékegység szorzó nélkül legyen megadva, a rendszer automatikusan hozzáadja az SI prefixeket. Pl.: 'kW' helyett 'W'. A "beviteli mező szorzó" mezőben megadható, hogy értékmegadáskor pl 'kW'-ot lehessen megadni 'W' helyett.
							</div>
							<input-field
								label="Mértékegység"
								name="unit"
								rules="required"
								v-model="attributes.unit"
								v-if="attributes.type === 'number'"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<data-loader :data="['siPrefixes']" v-slot="{ dataStore }">
								<select-field
									label="Beviteli mező szorzó"
									:options="dataStore.getDataAsArray('siPrefixes')"
									rules="required"
									name="input_field_prefix"
									data-type="string"
									v-model="attributes.input_field_prefix"
									v-if="attributes.type === 'number'"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
							</data-loader>

							<table class="table m-0" v-if="attributes.type === 'select'">
								<thead>
								<tr>
									<th width="50%">Érték</th>
									<th width="1%" v-if="attributes.editable"></th>
								</tr>
								</thead>
								<tbody>
								<template v-for="(item, itemKey) in attributes.values" :key="itemKey">
									<tr>
										<td>
											<input-field
												v-model="item.value_string"
												:field-only="true"
												:disabled="!attributes.editable"
												:name="'attribute_value_' + itemKey"
											></input-field>
										</td>
										<td v-if="attributes.editable">
											<div class="d-flex gap-2">

												<button type="button" @click.prevent="moveValue(itemKey, 'up')" class="btn btn-secondary"><i class="far fa-arrow-up"></i></button>
												<button type="button" @click.prevent="moveValue(itemKey, 'down')" class="btn btn-secondary"><i class="far fa-arrow-down"></i></button>
												<button class="btn btn-danger" title="Töröl" type="button" @click.prevent="removeValue(itemKey)"><i class="far fa-times"></i></button>
											</div>
										</td>
									</tr>
								</template>
								</tbody>
								<tfoot v-if="attributes.editable">
								<tr>
									<td colspan="3">
										<button class="btn btn-secondary btn-label" type="button" @click.prevent="addValue"><i class="far fa-plus label-icon"></i> Hozzáad</button>
									</td>
								</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import {moveValueInArray} from "../functions";
import ProductCategorySelectField from "../components/form/ProductCategorySelectField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import SelectField from "../components/form/SelectField.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import SwitchField from "../components/form/SwitchField.vue";
import OrderField from "../components/form/OrderField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {
		UpdatedAttribute,
		OrderField,
		SwitchField, DataLoader, InputField, SelectField, FormWrapper, ProductCategorySelectField, TitleBar},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		let attributes = _.clone(this.model)

		if (Array.isArray(attributes.translations) && !attributes.translations.length){
			attributes.translations = {}
		}
		if (attributes.values === undefined || attributes.values === null){
			attributes.values = []
		}
		if (attributes.input_field_prefix === undefined || attributes.input_field_prefix === null || !attributes.input_field_prefix){
			attributes.input_field_prefix = '-'
		}
		return {
			attributes: attributes,
			loading: false,
			flash: []
		}
	},
	methods: {
		addValue: function (){
			this.attributes.values.push({
				value_string: '',
				order: 0
			})
			this.reorderValues()
		},
		removeValue: function (index){
			this.attributes.values.splice(index, 1)
			this.reorderValues()
		},
		moveValue: function(index, direction){
			this.attributes.values = moveValueInArray(this.attributes.values, index, direction)
			this.reorderValues()
		},
		reorderValues(){
			let order = 0
			this.attributes.values.forEach(function(item, index){
				item.order = order
				order++
			})
		}
	}
}
</script>
