<script setup>
import {Link} from "@inertiajs/vue3";
import ColorBadge from "../ColorBadge.vue";
import DataLoader from "../DataLoader.vue";
import {ref} from "vue";
import {useApplicationStore} from "../../stores/applicationStore";
import {useGeneralDataStore} from "../../stores/generalData";
import Price from "../Price.vue";
import DateTime from "../DateTime.vue";
import Attribute from "../Attribute.vue";
import UpdatedAttribute from "../UpdatedAttribute.vue";

const model = defineModel()

const props = defineProps({
	publicId: String,
	disableLink: Boolean,
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
	desktop:{
		type: Boolean,
		default: false
	},
	mobile:{
		type: Boolean,
		default: false
	},
})

const loaded = ref(false)
const app = useApplicationStore()
const open = ref(false)
const error = ref(false)
const dataStore = useGeneralDataStore()
const details = ref({})

function load(){
	if (!loaded.value) {
		let loadedData = dataStore.getTooltipData('quote', model.value)
		if (loadedData !== false && loadedData){
			details.value = loadedData
			loaded.value = true
		} else {
			axios.get('/tooltips/quote/' + model.value, {}).then((response) => {
				details.value = response.data.details
				dataStore.addTooltip('quote', model.value, details.value)
				loaded.value = true
			}).catch((e) => {
				error.value = true
			})
		}
	}
}

function onOpen(){
	open.value = true
	load()
}
</script>

<template>
	<template v-if="!disableLink">
		<VDropdown
			@show="onOpen">
			<div class="vertical">
				<a :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" href="#">
					<i class="far fa-file-invoice-dollar"></i>
					<span class="colored-badge-content-wrap">{{ publicId }}</span>
					<i class="far fa-info-circle font-size-10"></i>
				</a>
			</div>
			<template #popper>
				<div :class="{'overview-tooltip': 1}">
					<div class="overview-tooltip-head">
						<template v-if="loaded">
							<div class="d-flex">
								<div class="flex-grow-1">
									<h5 class="font-size-15 mb-1">{{ details.public_id }}</h5>
									<h4 class="font-size-16 mb-1" v-if="details.title">{{ details.title }}</h4>
									<div class="horizontal">
										<color-badge icon="far fa-file-invoice-dollar" color="light" content="Árajánlat" small />
										<data-loader :data="['quoteStatusLabels']" v-slot="{ dataStore }" hide-loader>
											<color-badge
												v-if="dataStore.getData('quoteStatusLabels')[details.status] !== undefined"
												:color="dataStore.getData('quoteStatusLabels')[details.status].color"
												:icon="dataStore.getData('quoteStatusLabels')[details.status].icon"
												:content="dataStore.getData('quoteStatusLabels')[details.status].name"
												small
											/>
										</data-loader>
									</div>
								</div>
								<div class="d-flex flex-column gap-2">
									<Link :href="'/sales/edit/' + details.sale_id" class="btn btn-soft-secondary btn-sm" title="Adatlap"
										  v-if="app.hasPermission('SalesController_view') || app.hasPermission('SalesController_viewAll')"><i class="far fa-external-link"></i></Link>
									<a :href="'/sales/downloadQuote/' + details.id" class="btn btn-soft-secondary btn-sm" title="Letöltés"
										  v-if="app.hasPermission('SalesController_view') || app.hasPermission('SalesController_viewAll')"><i class="far fa-download"></i></a>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="placeholder-glow mb-1">
								<span class="placeholder font-size-15" :style="'width:32%'"></span>
							</div>
							<div class="placeholder-glow mb-1">
								<span class="placeholder font-size-14" :style="'width:51%'"></span>
							</div>
						</template>
					</div>
					<div class="overview-tooltip-body">
						<template v-if="loaded">
							<div class="details-box">
								<updated-attribute :data="details" created />
								<attribute
									icon="far fa-coins"
									label="Bruttó végösszeg"
								>
									<price :value="details.total_price_gross" :currency-id="details.currency_id"/>
								</attribute>
								<attribute
									icon="far fa-clock"
									label="Elolvasva"
								>
									<span class="text-success" v-if="details.read_at">
										<date-time :value="details.read_at"/>
									</span>
									<color-badge v-else
												 color="danger"
												 icon="far fa-times"
												 content="nincs"/>
								</attribute>
								<attribute
									icon="far fa-clock"
									label="Megválaszolva"
								>
									<span class="text-success" v-if="details.answered_at">
										<date-time :value="details.answered_at"/>
									</span>
									<color-badge v-else
												 color="danger"
												 icon="far fa-times"
												 content="nincs"/>
								</attribute>
							</div>
						</template>
						<template v-else>
							<div class="p-2">
								<div class="placeholder-glow mb-2">
									<span class="placeholder font-size-16" :style="'width:51%'"></span>
								</div>
								<div class="placeholder-glow mb-2">
									<span class="placeholder font-size-16" :style="'width:32%'"></span>
								</div>
							</div>
						</template>
					</div>
				</div>
			</template>
		</VDropdown>
	</template>
	<div class="vertical" v-else>
		<color-badge
			color="light"
			icon="far fa-file-invoice-dollar"
			:small="small"
		>
			<span class="colored-badge-content-wrap">{{ publicId }}</span>
		</color-badge>
	</div>
</template>

<style scoped>

</style>
