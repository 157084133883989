<template>
	<TitleBar title="Elfelejtett jelszó" />
	<div>
		<p class="text-muted">Amennyiben elfelejtetted a jelszavad, add meg e-mail címed és küldünk egy linket, mely segítségével átállíthatod jelszavad.</p>
	</div>
	<div class="mt-4">
		<div class="alert alert-success" v-if="showSuccess">
			Üzenet elküldve. Nyitsd meg az e-mail fiókodat és kövesd a benne található utasítást.
		</div>
		<form-wrapper
			v-model="attributes"
			:store-url="route('auth.lostPassword')"
			:show-bottom-buttons="false"
			:show-top-buttons="false"
			:disable-redirect="true"
			@after-submit="showSuccess = true"
			v-else
		>
			<template #default="{activeTab, errors, editable}">
				<input-field
					label="E-mail cím"
					name="email"
					v-model="attributes.email"
					rules="required"
					:multilang="false"
					data-type="email"
					:errors="errors"
				/>

				<div class="mt-3 d-grid">
					<button class="btn btn-primary waves-effect waves-light" tabindex="2" type="submit">Elküldés</button>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import {Link} from "@inertiajs/vue3";
import EnvironmentBadge from "../layouts/EnvironmentBadge.vue";
import {useApplicationStore} from "../stores/applicationStore";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import AuthLayout from "../layouts/AuthLayout.vue";
import TitleBar from "../components/TitleBar.vue";
export default {
	layout: AuthLayout,
	components: {TitleBar, SwitchField, InputField, FormWrapper, EnvironmentBadge, Link},
	data(){
		return {
			app: useApplicationStore(),
			showSuccess: false,
			attributes: {
				email: '',
				password: '',
				remember: false
			}
		}
	}
}
</script>

<style scoped>

</style>
