<template>
	<TitleBar title="Járművek" />

	<AdvancedDataTable
		:data-url="route('vehicles.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="vehicles"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('VehiclesController_create')">
			<Link :href="route('vehicles.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.licence_plate }}</strong>
						<div v-if="item.name">{{ item.name }}</div>
						<div v-if="item.make"><strong class="me-1">{{ item.make }}</strong> <span v-if="item.model">{{ item.model }}</span></div>

						<div class="mobile-horizontal">
							<boolean-badge
								v-model="item.is_active"
								active
							/>
							<boolean-badge
								v-model="item.is_hgv"
								hgv
								alternative-colors
							/>
						</div>
						<attribute v-if="item.registration_valid_to" :color="isPast(item.registration_valid_to) ? 'danger' : ''" label="Forgalmi lejárat">
							<date-time :value="item.registration_valid_to" :time="false"/>
						</attribute>
						<attribute v-if="item.next_oil_change" :color="isPast(item.next_oil_change) ? 'danger' : ''" label="Olajcsere">
							<date-time :value="item.next_oil_change" :time="false"/>
						</attribute>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('vehicles.update')"
					:delete-url="route('vehicles.delete')"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
		<template #item-licence_plate="item">
			<div class="stacked-cell">
				<strong>{{ item.licence_plate }}</strong>
				<div v-if="item.name">{{ item.name }}</div>
				<div v-if="item.make"><strong class="me-1">{{ item.make }}</strong> <span v-if="item.model">{{ item.model }}</span></div>
			</div>
		</template>
		<template #item-total_distance="item">
			<number v-if="item.total_distance > 0" :value="item.total_distance" suffix=" km"/>
		</template>
		<template #item-registration_valid_to="item">
			<attribute v-if="item.registration_valid_to" :color="isPast(item.registration_valid_to) ? 'danger' : ''">
				<date-time :value="item.registration_valid_to" :time="false"/>
			</attribute>
		</template>
		<template #item-next_oil_change="item">
			<attribute v-if="item.next_oil_change" :color="isPast(item.next_oil_change) ? 'danger' : ''">
				<date-time :value="item.next_oil_change" :time="false"/>
			</attribute>
		</template>
		<template #item-is_active="item">
			<boolean-badge
				v-model="item.is_active"
			/>
		</template>
		<template #item-is_hgv="item">
			<boolean-badge
				:value="item.is_hgv"
			></boolean-badge>
		</template>
	</AdvancedDataTable>
</template>

<script>
import ColorBadge from "../components/ColorBadge.vue";
import TableActionCell from "../components/TableActionCell.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import Attribute from "../components/Attribute.vue";
import Number from "../components/Number.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import DateTime from "../components/DateTime.vue";
import {isPast} from "../functions";

export default {
	methods: {isPast},
	layout: AppLayout,
	components: {
		DateTime,
		BooleanBadge, Number, Attribute, TitleBar, AdvancedDataTable, TableActionCell, ColorBadge, Link},
	props: {
	},
	data(){
		let columns = [
			{
				text: 'Adatok',
				value: 'licence_plate',
				sortable: true
			},
			{
				text: 'Aktív',
				value: 'is_active',
				sortable: true
			},
			{
				text: 'Nehéz tehergépjármű',
				value: 'is_hgv',
				sortable: true
			},
			{
				text: 'Futott km',
				value: 'total_distance',
				sortable: true
			},
			{
				text: 'Forgalmi lejárat',
				value: 'registration_valid_to',
				sortable: true
			},
			{
				text: 'Következő olajcsere',
				value: 'next_oil_change',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
