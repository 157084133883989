<template>
	<AdvancedDataTable
			:data-url="route('users.saleList', userId)"
			:columns="columns"
			sort-by="created_at"
			sort-type="desc"
			name="userSales"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<mobile>
					<div class="mobile-cell">
						<div class="stacked-cell">
							<project-badge :project-id="item.project_id" :project-type-id="item.project_type_id" :contract-id="item.contract_id">
								{{ item.clientName }} - {{ item.projectPublicId }}
							</project-badge>

							<strong>
								<Price
									:value="item.amount"
								/>
							</strong>

							<attribute
								icon="far fa-clock"
							>
								<DateTime :value="item.created_at"/>
							</attribute>
						</div>
					</div>
				</mobile>
			</div>
		</template>
		<template #item-created_at="item">
			<DateTime :value="item.created_at" :time="false"/>
		</template>
		<template #item-amount="item">
			<Price
				:value="item.amount"
			/>
		</template>
		<template #item-projectPublicId="item">
			<project-badge :project-id="item.project_id" :project-type-id="item.project_type_id" :contract-id="item.contract_id">
				{{ item.clientName }} - {{ item.projectPublicId }}
			</project-badge>
		</template>
	</AdvancedDataTable>
</template>
<script>
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/badges/BooleanBadge.vue";
import UserLevelBadge from "../../components/UserLevelBadge.vue";
import Price from "../../components/Price.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import DateTime from "../../components/DateTime.vue";
import Attribute from "../../components/Attribute.vue";
import ProjectStatusIcon from "../projects/ProjectStatusIcon.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";
import BackgroundActionButton from "../../components/BackgroundActionButton.vue";
import AddressAttribute from "../../components/attributes/AddressAttribute.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";
import Mobile from "../../components/responsive/Mobile.vue";

export default {
	components: {
		Mobile,
		ProjectBadge,
		AddressAttribute,
		BackgroundActionButton,
		ProjectTypeCell, ProjectStatusIcon, Attribute,
		DateTime,
		Price,
		UserLevelBadge,
		AdvancedDataTable,
		BooleanBadge,
		TableActionCell,
	},
	props: {
		userId: Number,
	},
	data(){
		let columns = [
			{
				text: 'Időpont',
				value: 'created_at',
				sortable: true,
			},
			{
				text: 'Összeg',
				value: 'amount',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Projekt',
				value: 'projectPublicId',
				sortable: true,
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
	methods: {
	}
}
</script>

<style scoped>
</style>
