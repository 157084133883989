<template>
	<TitleBar title="Nyelvek" />

	<AdvancedDataTable
		:data-url="route('translations.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="currencies"
	>
		<template #navigation v-if="app.hasPermission('LanguagesController_create')">
			<Link :href="route('translations.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>

						<div class="mobile-horizontal">
							<boolean-badge
								v-model="item.is_default"
								:key="'is_defaultM_' + item.id"
								default
							/>
							<boolean-badge
								v-model="item.active"
								:key="'activeM_' + item.id"
								active
							/>
						</div>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('translations.update')"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
				>
					<a :href="route('translations.downloadTranslationFile', item.id)" v-if="!item.is_default" class="btn btn-warning btn-sm waves-effect" title="Fordítások letöltése"><i class="far fa-download"></i></a>
				</TableActionCell>
			</div>
		</template>
		<template #item-is_default="item">
			<boolean-badge
				v-model="item.is_default"
				:key="'is_default_' + item.id"
			/>
		</template>
		<template #item-active="item">
			<boolean-badge
				v-model="item.active"
				:key="'active_' + item.id"
			/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import TableActionCell from "../components/TableActionCell.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import ColorBadge from "../components/ColorBadge.vue";
import Attribute from "../components/Attribute.vue";
import Number from "../components/Number.vue";

export default {
	components: {Number, Attribute, ColorBadge, AdvancedDataTable, TableActionCell, BooleanBadge, TitleBar, Link},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Alapértelmezett',
				value: 'is_default',
				sortable: true,
				width: 5
			},
			{
				text: 'Aktív',
				value: 'active',
				sortable: true,
				width: 5
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
