<template>
	<TitleBar :title="model.id !== undefined ? 'Kategória szerkesztése' : 'Kategória létrehozása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('documents.category', model.id)"
		:back-url="route('documents.index')"
		v-model="attributes"
		:buttons="buttons"
	>
		<template #default="{activeTab, errors}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:errors="errors"
							/>

							<data-loader :data="['roles']" v-slot="{ dataStore }">
								<select-field
									label="Szerepek"
									name="roleIdArray"
									v-model="attributes.roleIdArray"
									:options="dataStore.getData('roles')"
									:multiple="true"
									:errors="errors"
								/>
							</data-loader>

							<iconpicker-field
								v-model="attributes.icon"
								label="Ikon"
								name="icon"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								label="Prioritás"
								name="priority"
								rules="required"
								v-model="attributes.priority"
								data-type="number"
								:step="1"
								:min="0"
								:errors="errors"
							/>

							<input-field
								label="Szín"
								name="color"
								v-model="attributes.color"
								data-type="color"
								:errors="errors"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>
<script>
import AppLayout from "../layouts/AppLayout.vue";
import SelectField from "../components/form/SelectField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import IconpickerField from "../components/form/IconpickerField.vue";
import TitleBar from "../components/TitleBar.vue";

export default {
	components: {TitleBar, IconpickerField, DataLoader, InputField, FormWrapper, SelectField},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		let buttons = []
		let attributes = _.clone(this.model)
		attributes.roleIdArray = []
		_.forEach(attributes.roles, function(value){
			attributes.roleIdArray.push(value.id)
		})
		return {
			attributes: attributes,
			loading: false,
			flash: [],
			buttons: buttons
		}
	},
}
</script>
