<template>
	<div :class="{'mb-3': !fieldOnly && !noMargin}">
		<label :class="{required: isRequired}" v-if="!fieldOnly">{{ label }}</label>
			<Field
				v-model="componentValue"
				:key="inputKeyComputed"
				:name="name"
				type="time"
				:readonly="disabled"
				:disabled="disabled"
				@keydown="onKeypress($event)"
				@input="onInput"
				:rules="rulesValue"
				:style="inputStyle"
				:id="id"
				:placeholder="showPlaceholder ? label : ''"
				:class="inputClass"
				:tabindex="tabindex > -1 ? tabindex : null"
			/>
	</div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import DataLoader from "../DataLoader.vue";
export default {
	components: {DataLoader, Field, ErrorMessage},
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: String,
		name: {
			type: String,
			default: ''
		},
		tabindex: {
			type: Number,
			default: -1
		},
		modelValue: [String, Number],
		translations: {
			type: Object,
			default: function(){
				return {}
			}
		},
		errors: {
			type: Object,
			default: function(){
				return {}
			}
		},
		info: {
			type: String,
			default: ''
		},
		id: {
			type: String,
			default: ''
		},
		fixedWidth: {
			type: Number,
			default: null
		},
		align: {
			type: String,
			default: 'left'
		},
		disabledValueOnly: Boolean,
		fieldOnly: Boolean,
		noMargin: Boolean,
		showPlaceholder: Boolean
	},
	data() {
		return {
			inputKey: 0,
			rulesValue: null
		}
	},
	mounted() {
		this.updateRules()
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		isRequired: {
			get(){
				return this.rules.indexOf('required') > -1
			}
		},
		inputStyle: {
			get() {
				let payload = {}

				if (this.fixedWidth){
					payload.width = this.fixedWidth + 'px'
					payload.paddingLeft = '4px'
					payload.paddingRight = '4px'
				}

				return payload
			}
		},
		inputClass: {
			get() {
				let payload = ['form-control']

				if (this.errors[this.name] !== undefined){
					payload.push('is-invalid')
				}
				if (this.align === 'center'){
					payload.push('text-center')
				}
				if (this.align === 'right'){
					payload.push('text-end')
				}

				return payload
			}
		},
		inputKeyComputed: {
			get(){
				return this.id ? this.id + this.inputKey : this.inputKey
			}
		},
	},
	methods: {
		onKeypress: function(e){
			let keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete'];
			if (this.step !== null && this.step < 1){
				keysAllowed.push('.')
			}
			if (this.min === null || this.min < 0){
				keysAllowed.push('-')
			}
			const keyPressed = e.key;

			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},
		onInput: function(){
			this.$emit('input')
		},
		updateRules: function(){
			let tmp = this.rules.split('|')
			let rules

			rules = yup.string()

			if (tmp.indexOf('required') > -1){
				rules = rules.required(() => {
					return this.$t(':field kitöltése kötelező', {field: this.label})
				})
			} else {
				rules = rules.notRequired()
			}

			this.rulesValue = rules.label(this.label)
		}
	}
}
</script>

<style scoped>

</style>
