<template>
	<template v-for="(documentType, documentTypeKey) in componentValue" v-bind:key="'documentType_' + documentType.order">
		<div class="card">
			<div class="card-body">
				<div class="d-flex">
					<div class="sortable-form-card--left">
						<button type="button" @click.prevent="moveDocumentType(documentTypeKey, 'up')" class="btn btn-secondary mx-1 mt-2"><i class="far fa-arrow-up"></i></button>
						<button type="button" @click.prevent="moveDocumentType(documentTypeKey, 'down')" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
						<button type="button" @click.prevent="removeDocumentType(documentTypeKey)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
					</div>
					<div class="sortable-form-card--right">
						<div class="row">
							<div class="col-md-6">
								<InputField
									label="Név"
									:name="'documentType_name' + documentType.order"
									v-model="documentType.name"
									rules="required"
									:errors="errors"
									:multilang="true"
									:languages="languages"
									v-model:translations="documentType.translations"
									translation-field-name="name"
								/>

								<iconpicker-field
										v-model="documentType.icon"
										label="Ikon"
										:name="'documentType_icon' + documentType.order + 'Checkbox'"
								/>

								<SwitchField
										label="Telepítő által írható"
										:name="'documentType_installation' + documentType.order + 'Checkbox'"
										v-model="documentType.installation"
								/>

								<InputField
										label="Telepítéshez kötelező"
										suffix="db file"
										data-type="number"
										v-if="documentType.installation"
										:name="'documentType_required_for_installation' + documentType.order"
										v-model="documentType.required_for_installation"
								/>
							</div>
							<div class="col-md-6">
								<div class="form-group mb-2">
									<label>Szín</label>
									<input type="color" v-model="documentType.color" class="form-control form-control-color">
								</div>

								<div class="form-group mb-2">
									<label>File típusok</label>
									<input type="text" v-model="documentType.file_types" class="form-control">
									<div class="form-text">
										File kiterjesztések vesszővel elválasztva. Amennyiben nincs megadva, bármilyen típusú file feltölthető.
									</div>
								</div>

								<SwitchField
										label="Telepítő láthatja"
										:name="'documentType_installer_can_view' + documentType.order + 'Checkbox'"
										v-model="documentType.installer_can_view"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</template>

	<div class="mt-3">
		<button type="button" class="btn btn-secondary btn-label" @click.prevent="addDocumentType">
			<i class="far fa-plus label-icon"></i> Hozzáad
		</button>
	</div>
</template>

<script>
import SwitchField from "../../components/form/SwitchField.vue";
import {moveValueInArray} from "../../functions";
import IconpickerField from "../../components/form/IconpickerField.vue";
import InputField from "../../components/form/InputField.vue";
export default {
	emits: ['update:modelValue'],
	components: {
		InputField,
		IconpickerField,
		SwitchField,
	},
	props: {
		modelValue: Array,
		productCategories: Array,
		languages: Array,
		errors: Object,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
	},
	data(){
		return {
		}
	},
	methods: {
		moveValueInArray,
		addDocumentType: function(){
			let maxOrder = 0

			this.componentValue.forEach(function(item, index){
				if (item.order > maxOrder){
					maxOrder = item.order
				}
			})
			this.componentValue.push({order: maxOrder + 1, translations: {}})
		},
		moveDocumentType: function(index, direction){
			this.componentValue = this.moveValueInArray(this.componentValue, index, direction)
			let order = 0
			this.componentValue.forEach(function(item, index){
				item.order = order
				order++
			})
		},
		removeDocumentType: function(index){
			this.componentValue.splice(index, 1);
		},
	}
}
</script>

<style scoped>
</style>
