<template>
	<div v-if="tasks.length && projectDataStore.activeTab" class="d-flex flex-column gap-3 mb-3">
		<div :class="'card border p-3 m-0 border-' + (task.details.tab === projectDataStore.activeTab ? (task.completed_at ? 'success' : task.color) : 'secondary opacity-50')" v-for="task in tasks" :key="'task' + task.id">
			<div class="gap-4 d-flex align-items-center flex-row">
				<div class="flex-shrink-0 align-self-center">
					<div :class="'mini-stat-icon avatar-sm rounded-circle bg-' + task.color">
						<span :class="'avatar-title bg-' + (task.completed_at ? 'success' : task.color)">
							<i :class="'font-size-24 ' + task.icon"></i>
						</span>
					</div>
				</div>

				<div class="flex-grow-1">
					<h5 class="mb-0">{{ task.title }}</h5>
					<p class="text-muted fw-medium mb-0" v-html="task.description"></p>
					<div class="horizontal">
						<attribute icon="far fa-clock"><date-time :value="task.created_at"></date-time></attribute>
						<attribute icon="far fa-user"><user-badge :user-id="task.created_by_user_id" hide-level hide-link></user-badge></attribute>
					</div>
					<div class="horizontal" v-if="task.completed">
						<attribute icon="far fa-exclamation-triangle" label="Határidő" v-if="task.deadline"><date-time :value="task.deadline" :time="false"/></attribute>
						<attribute icon="far fa-clock" label="Teljesítve"><date-time :value="task.completed_at"></date-time></attribute>
					</div>
					<attribute icon="far fa-comment" v-if="task.result" label="Válasz" :content="task.result"></attribute>
				</div>
				<div v-if="task.details.tab === projectDataStore.activeTab && !showFormId && !task.completed" class="d-flex gap-2">
					<button type="button" class="btn btn-outline-success" @click.prevent="showForm(task.id)"><i class="far fa-check"></i> Késznek jelöl</button>
				</div>
			</div>

			<template v-if="task.details.tab === projectDataStore.activeTab && showFormId === task.id">
				<project-task-answer-modal
					:task="task"
					@close="showFormId = null"
					@closeAndUpdate="showFormId = null; update()"
					/>
			</template>
		</div>
	</div>
</template>

<script>
import {useFlashStoreStore} from "../../stores/flashStore";
import Number from "../../components/Number.vue";
import Attribute from "../../components/Attribute.vue";
import DateTime from "../../components/DateTime.vue";
import UserBadge from "../../components/badges/UserBadge.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import ProjectTaskAnswerModal from "./ProjectTaskAnswerModal.vue";

export default {
	components: {ProjectTaskAnswerModal, ColorBadge, TextareaField, UserBadge, DateTime, Attribute, Number},
	props: {
		projectDataStore: Object,
		featuredTask: Object
	},
	data(){
		return {
			tasks: [],
			flashStore: useFlashStoreStore(),
			showFormId: null,
			taskResult: ''
		}
	},
	mounted() {
		this.update()
	},
	methods: {
		update: function (){
			axios.get('/projects/' + this.projectDataStore.projectId + '/tasks').then((response)=>{
				this.tasks = response.data.tasks
				if (this.featuredTask){
					let added = false
					for (let i = 0; i < this.tasks.length; i++){
						if (this.tasks[i].id === this.featuredTask.id){
							added = true
						}
					}
					if (!added) {
						this.tasks.unshift(t.featuredTask)
					}
				}

				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		showForm: function (id){
			this.showFormId = id
			this.taskResult = ''
		},
	}
}
</script>

<style scoped>

</style>
