<template>
	<TitleBar :title="attributes.id !== undefined ? 'Kiszállítás módosítása' : 'Kiszállítás hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('deliveryRoutes.store', model)"
		:back-url="route('deliveryRoutes.index')"
		v-model="attributes"
		:required-data="['warehouses', 'vehicles']"
	>
		<template #buttons="{editable}">
			<ButtonGroup :buttons-to-show="1">
				<template #close v-if="app.hasPermission('DeliveryRoutesController_update') && (attributes.closed_at === undefined || attributes.closed_at === null) && hasValidRoute">
					<button
						type="button"
						@click.prevent="closeDelivery"
						class="btn btn-label btn-warning">
						<i class="far fa-lock label-icon"></i> Lezárás
					</button>
				</template>
				<template #completed v-if="app.hasPermission('DeliveryRoutesController_update') && model.is_completed !== 1 && model.id !== undefined">
					<Link
						:href="route('deliveryRoutes.setCompleted', model.id)"
						class="btn btn-success btn-label">
						<i class="far fa-check label-icon"></i> Fuvar teljesítve
					</Link>
				</template>
				<template #download v-if="model.file_id !== undefined && model.file_id != null">
					<a
						:href="route('deliveryRoutes.download', model)"
						class="btn btn-warning btn-label">
						<i class="far fa-download label-icon"></i> Összesítő letöltése
					</a>
				</template>
				<template #downloadAll v-if="model.file_id !== undefined && model.file_id != null">
					<a
						:href="route('deliveryRoutes.downloadAll', model)"
						class="btn btn-warning btn-label">
						<i class="far fa-folder-download label-icon"></i> Dokumentumok letöltése
					</a>
				</template>
			</ButtonGroup>
		</template>

		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<attribute icon="far fa-hashtag" label="Azonosító">
							{{ attributes.public_id }}
						</attribute>
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
						<attribute
							icon="far fa-lock"
							label="Lezárás"
						>
							<color-badge
								:color="attributes.closed_at ? 'success' : 'warning'"
								:icon="attributes.closed_at ? 'far fa-check' : 'far fa-hourglass'"
								>
								<date-time v-if="attributes.closed_at" :value="attributes.closed_at"/>
								<template v-else>Nincs lezárva</template>
							</color-badge>
						</attribute>
						<attribute
							label="Teljesítve"
							icon="far fa-check"
						>
							<color-badge
								:icon="attributes.is_completed ? 'far fa-check' : 'far fa-hourglass'"
								:color="attributes.is_completed ? 'success' : 'warning'"
								>
								{{ attributes.is_completed ? 'Igen' : 'Nem' }}
							</color-badge>
						</attribute>
					</div>
				</div>
			</div>
		</template>

		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<date-field
								label="Dátum"
								name="date"
								rules="required"
								v-model="attributes.date"
								:disabled="!editable"
								:errors="errors"
								future
							/>
							<warehouse-select-field
								label="Raktár"
								name="warehouse_id"
								v-model="attributes.warehouse_id"
								:disabled="!editable"
								:errors="errors"
								:require-address="true"
								:show-mobile="false"
								rules="required"
							/>
						</div>
						<div class="col-sm-6">
							<vehicle-select-field
								label="Jármű"
								name="vehicle_id"
								v-model="attributes.vehicle_id"
								:disabled="!editable"
								:errors="errors"
								rules="required"
								/>

							<user-select-field
								label="Sofőr"
								name="driver_user_id"
								v-model="attributes.driver_user_id"
								:disabled="!editable"
								:errors="errors"
								rules="required"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3" v-if="attributes.closed_at">
				<div class="card-body">
					<h4 class="card-title mb-4">Szállítólevelek</h4>
					<DeliveryRouteInventoryShipmentList
						:model="attributes"
						/>
				</div>
			</div>
			<template v-if="warehouse !== null">
				<div class="row">
					<div class="col-md-6">
						<div class="card mb-3">
							<div class="card-body">
								<h4 class="card-title mb-4">Állomások</h4>
								<select-field
									label="Állomás hozzáadása"
									name="addContractId"
									v-model="addContractId"
									:options="filteredContracts"
									:errors="errors"
									@update:modelValue="addStop"
									v-if="editable"
								>
									<template v-slot:option="option">
										<div class="stacked-cell">
											<div class="horizontal">
												<color-badge color="dark" icon="fas fa-project-diagram" :content="option.option.projectPublicId" :small="true"/>
												<color-badge color="light" icon="far fa-file-contract" :content="option.option.public_id" :small="true"/>
												<span>{{ option.option.clientName }}</span>
											</div>
											<attribute icon="far fa-map-marker" :content="option.option.address" />
											<color-badge color="warning" v-if="option.option.reservedQuantity !== undefined" icon="far fa-cubes" small :content="option.option.reservedQuantity + ' lefoglalt termék'"/>
											<color-badge color="warning" v-if="option.option.toReturn !== undefined" icon="far fa-cubes" small :content="option.option.toReturn + ' szállítandó termék'"/>
										</div>
									</template>
									<template v-slot:selected-option="option">
										<div class="stacked-cell">
											<div class="horizontal">
												<color-badge color="dark" icon="fas fa-project-diagram" :content="option.option.projectPublicId" :small="true"/>
												<color-badge color="light" icon="far fa-file-contract" :content="option.option.public_id" :small="true"/>
												<span>{{ option.option.clientName }}</span>
											</div>
											<attribute icon="far fa-map-marker" :content="option.option.address" />
											<color-badge color="warning" v-if="option.option.reservedQuantity !== undefined" icon="far fa-cubes" small :content="option.option.reservedQuantity + ' lefoglalt termék'"/>
											<color-badge color="warning" v-if="option.option.toReturn !== undefined" icon="far fa-cubes" small :content="option.option.toReturn + ' szállítandó termék'"/>
										</div>
									</template>
								</select-field>
								<div :class="{'bg-dark': 1, 'text-white': 1, 'p-2': 1, 'mt-2': 1, 'opacity-25': highlightedStop !== false}">
									<div class="d-flex gap-2 align-items-center">
										<i class="far fa-home font-size-20 me-2"></i>
										<div class="flex-grow-1">
											<h4 class="mb-0">{{ warehouse.name }}</h4>
											<div>{{ warehouse.address }}</div>
										</div>
									</div>
								</div>
								<div v-for="(stop, stopKey) in attributes.stops" :class="{'bg-light': 1, 'mt-2': 1, 'opacity-25': (highlightedStop !== false && highlightedStop !== stopKey)}" :key="stopKey">
									<div class="d-flex">
										<div class="p-2 font-size-22 fw-bold text-white d-flex align-items-center" :style="'background: ' + stop.color">
											{{ stop.number }}
										</div>
										<div class="d-flex gap-2 flex-grow-1 p-2">
											<div class="flex-grow-1">
												<h4 class="mb-0">{{ contractsById[stop.contract_id].clientName }}</h4>
												<div v-if="contractsById[stop.contract_id].address !== undefined">{{ contractsById[stop.contract_id].address }}</div>

												<attribute label="Lefoglalt termékek" v-if="contractsById[stop.contract_id].reservedQuantity !== undefined">
													<number :value="contractsById[stop.contract_id].reservedQuantity"/>
												</attribute>
												<attribute label="Visszaszállítandó termékek" v-if="contractsById[stop.contract_id].toReturn !== undefined">
													<number :value="contractsById[stop.contract_id].toReturn"/>
												</attribute>

												<div class="d-flex gap-2 mt-2">
													<div v-if="stop.distance_from_previous !== undefined" class="d-flex gap-2 align-items-center">
														<i class="far fa-tachometer-alt font-size-20 me-1"></i>
														<div class="fw-bold text-primary font-size-15"><number :value="Math.round(stop.distance_total / 100) / 10" suffix="km"/></div>
														<div class="text-muted">(<number :value="Math.round(stop.distance_from_previous / 100) / 10" suffix="km"/>)</div>
													</div>
													<div v-if="stop.duration_from_previous !== undefined" class="d-flex gap-2 align-items-center">
														<i class="far fa-clock font-size-20 me-1"></i>
														<div class="fw-bold text-success font-size-15"><duration :value="stop.duration_total"/></div>
														<div class="text-muted">(<duration :value="stop.duration_from_previous"/>)</div>
													</div>
												</div>
											</div>
											<div class="d-flex gap-2 align-self-start flex-column" v-if="editable">
												<button type="button" class="btn btn-secondary py-1" title="Feljebb" @click.prevent="moveStop(stopKey, 'up')"><i class="far fa-angle-up"></i></button>
												<button type="button" class="btn btn-secondary py-1" title="Lejjebb" @click.prevent="moveStop(stopKey, 'down')"><i class="far fa-angle-down"></i></button>
												<button type="button" class="btn btn-danger py-1" title="Törlés" @click.prevent="removeStop(stopKey)"><i class="far fa-times"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div :class="{'bg-dark': 1, 'text-white': 1, 'mt-2': 1, 'opacity-25': highlightedStop !== false && highlightedStop !== null}" v-if="attributes.stops.length">
									<div class="d-flex">
										<div class="p-2 font-size-22 fw-bold text-white d-flex align-items-center" v-if="lastSegmentNumber" :style="'background: ' + lastSegmentColor">
											{{ lastSegmentNumber }}
										</div>
										<div class="d-flex gap-2 flex-grow-1 p-2 align-items-center">
											<i class="far fa-home font-size-20 me-2"></i>
											<div class="flex-grow-1">
												<h4 class="mb-0">{{ warehouse.name }}</h4>
												<div>{{ warehouse.address }}</div>

												<div class="d-flex gap-2 mt-2">
													<div v-if="distanceFromLast > 0" class="d-flex gap-2 align-items-center">
														<i class="far fa-tachometer-alt font-size-20 me-1"></i>
														<div class="fw-bold text-primary font-size-15"><number :value="Math.round(attributes.distance_total / 100) / 10" suffix="km"/></div>
														<div>(<number :value="Math.round(distanceFromLast / 100) / 10" suffix="km"/>)</div>
													</div>
													<div v-if="durationFromLast > 0" class="d-flex gap-2 align-items-center">
														<i class="far fa-clock font-size-20 me-1"></i>
														<div class="fw-bold text-success font-size-15"><duration :value="attributes.duration_total"/></div>
														<div>(<duration :value="durationFromLast"/>)</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<div style="height:500px" class="mt-3" v-if="mapBounds[0] !== 0">
									<l-map ref="map" :zoom="10" :max-bounds="mapBounds" :center="mapCenter">
										<l-marker
											v-for="(routeSegment, routeSegmentKey) in routeSegments"
											:lat-lng="routeSegment.stopCoords"
											:title="routeSegment.stopName"
											:icon="getIcon(routeSegment)"
											@click="markerClicked(routeSegment.contract_id)"
											:key="'mapMarker_' + routeSegment.contract_id"
										/>
										<l-polyline
											:lat-lngs="routeSegment.coordinates"
											:color="routeSegment.color"
											v-for="(routeSegment, routeSegmentKey) in routeSegments"
											:key="'mapRoute_' + routeSegment.contract_id"
											:opacity=".5"
										/>

										<l-tile-layer
											url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
											layer-type="base"
											name="OpenStreetMap"
										></l-tile-layer>
									</l-map>
								</div>
								<div v-else class="alert alert-info m-0">
									Adj hozzá állomásokat!
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="vehicle">
					<div class="col-md-6 col-xl-3">
						<div class="card">
							<div class="card-body">
								<div class="d-flex">
									<div class="flex-grow-1">
										<p class="text-muted fw-medium">Jármű maximum terhelés</p>
										<h4 class="mb-0"><number :value="vehicle.max_total_weight - vehicle.own_weight" unit="kg"/></h4>
									</div>

									<div class="flex-shrink-0 align-self-center">
										<div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
										<span class="avatar-title">
											<i class="fas fa-truck font-size-24"></i>
										</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6 col-xl-3">
						<div class="card">
							<div class="card-body">
								<div class="d-flex">
									<div class="flex-grow-1">
										<p class="text-muted fw-medium">Összes teher</p>
										<h4 :class="'mb-0 text-' + (vehicle.max_total_weight - vehicle.own_weight > totalProductWeight ? 'success' : 'danger')"><number :value="totalProductWeight" unit="kg" /></h4>
									</div>

									<div class="flex-shrink-0 align-self-center">
										<div class="mini-stat-icon avatar-sm rounded-circle">
										<span :class="'avatar-title bg-' + (vehicle.max_total_weight - vehicle.own_weight > totalProductWeight ? 'success' : 'danger')">
											<i class="fas fa-weight-hanging font-size-24"></i>
										</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<template v-for="stop in attributes.stops" :key="'deliverableItems_' + stop.contract_id">
					<template v-if="deliverableItems[stop.contract_id] !== undefined && deliverableItems[stop.contract_id].length">
						<div class="card" :style="'border-top: 5px solid ' + stop.color">
							<div class="card-body">
								<h4 class="card-title mb-2">{{ stop.number }}. {{ contractsById[stop.contract_id].clientName }}</h4>
								<div v-if="contractsById[stop.contract_id].address !== undefined">{{ contractsById[stop.contract_id].address }}</div>
								<div class="row mt-2">
									<div class="col-md-6 col-lg-4" v-for="deliverableItem in deliverableItems[stop.contract_id]" :key="'deliverableItem_' + stop.contract_id + '_' + deliverableItem.productId">
										<div class="card border shadow-none mb-2 file-card p-2">
											<h5 class="font-size-15 mb-2">{{ deliverableItem.productName }}</h5>
											<div class="horizontal mb-2">
												<color-badge
													v-if="deliverableItem.quantityToDeliver > 0"
													color="primary"
													icon="far fa-arrow-right"
													small
													>
													Lefoglalva: <number :value="deliverableItem.quantityToDeliver"/> {{ deliverableItem.productUnit }}
												</color-badge>
												<color-badge
													v-if="deliverableItem.quantityToReturn > 0"
													color="warning"
													icon="far fa-arrow-left"
													small
													>
													Visszaszállítandó: <number :value="deliverableItem.quantityToReturn"/> {{ deliverableItem.productUnit }}
												</color-badge>
											</div>
											<div v-if="deliverableItem.productWeight" class="mb-1"><attribute icon="fas fa-weight-hanging"><number :value="deliverableItem.productWeight" unit="kg" /> / {{ deliverableItem.productUnit }}</attribute></div>
											<div class="row" v-if="deliverableItem.quantityToDeliver">
												<div class="col-6">
													<switch-field
														label="Kiszállít"
														:name="'reservedItem_' + stop.contract_id + '_' + deliverableItem.productId + '_deliver'"
														v-model="stop.products[deliverableItem.productId].deliver"
														:disabled="!editable"
														no-margin
													/>
												</div>
												<div class="col-6" v-if="stop.products[deliverableItem.productId].deliver">
													<input-field
														label="Mennyiség"
														:name="'reservedItem_' + stop.contract_id + '_' + deliverableItem.productId + '_quantityToDeliver'"
														v-model="stop.products[deliverableItem.productId].quantity_to_deliver"
														rules="required"
														:disabled="!editable"
														no-margin
														:step="1"
														:min="0"
														:max="deliverableItem.quantityToDeliver"
														data-type="number"
													/>
												</div>
											</div>
											<div class="row" v-if="deliverableItem.quantityToReturn">
												<div class="col-6">
													<switch-field
														label="Visszaszállít"
														:name="'reservedItem_' + stop.contract_id + '_' + deliverableItem.productId + '_return'"
														v-model="stop.products[deliverableItem.productId].return"
														no-margin
														:disabled="!editable"
													/>
												</div>
												<div class="col-6" v-if="stop.products[deliverableItem.productId].return">
													<input-field
														label="Mennyiség"
														:name="'reservedItem_' + stop.contract_id + '_' + deliverableItem.productId + '_quantityToReturn'"
														v-model="stop.products[deliverableItem.productId].quantity_to_return"
														rules="required"
														no-margin
														:step="1"
														:min="0"
														:max="deliverableItem.quantityToReturn"
														data-type="number"
														:disabled="!editable"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</template>
			</template>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import DateTime from "../components/DateTime.vue";
import Attribute from "../components/Attribute.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import DateField from "../components/form/DateField.vue";
import VehicleSelectField from "../components/form/VehicleSelectField.vue";
import UserSelectField from "../components/form/UserSelectField.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import SelectField from "../components/form/SelectField.vue";
import {useGeneralDataStore} from "../stores/generalData";
import {LMap, LMarker, LPolyline, LTileLayer} from "@vue-leaflet/vue-leaflet";
import Number from "../components/Number.vue";
import Duration from "../components/Duration.vue";
import {useFlashStoreStore} from "../stores/flashStore";
import ColorBadge from "../components/ColorBadge.vue";
import {moveValueInArray} from "../functions";
import SwitchField from "../components/form/SwitchField.vue";
import {Link} from "@inertiajs/vue3";
import {useApplicationStore} from "../stores/applicationStore";
import DeliveryRouteInventoryShipmentList from "../modules/deliveryRoutes/DeliveryRouteInventoryShipmentList.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {
		UpdatedAttribute,
		ButtonGroup,
		DeliveryRouteInventoryShipmentList,
		Link,
		SwitchField,
		ColorBadge,
		Duration,
		Number,
		LMap,
		LMarker,
		LPolyline,
		LTileLayer,
		SelectField,
		WarehouseSelectField,
		UserSelectField,
		VehicleSelectField, DateField, UserBadge, Attribute, DateTime, InputField, FormWrapper, TitleBar},
	layout: AppLayout,
	props: {
		model: Object,
		contractsDelivery: Array,
		contractsReturn: Array,
		initialDeliverableItems: Object
	},
	computed: {
		filteredContracts: {
			get(){
				let usedContracts = _.map(this.attributes.stops, function(item){
					return item.contract_id
				})
				let payload = _.filter(this.contractsDelivery, function (item){
					return usedContracts.indexOf(item.id) < 0
				})
				payload = _.keyBy(payload, 'id')
				_.forEach(this.contractsReturn, function (item){
					if (usedContracts.indexOf(item.id) > -1){
						return
					}
					if (payload[item.id] === undefined){
						payload[item.id] = item
					} else {
						payload[item.id].toReturn = item.toReturn
					}
				})

				return payload
			}
		},
		warehouse: {
			get: function () {
				if (this.attributes.warehouse_id === undefined || this.attributes.warehouse_id === null) {
					return null
				}

				return this.dataStore.getData('warehouses', true)[this.attributes.warehouse_id]
			}
		},
		vehicle: {
			get: function () {
				if (this.attributes.vehicle_id === undefined || this.attributes.vehicle_id === null) {
					return null
				}

				return this.dataStore.getData('vehicles', true)[this.attributes.vehicle_id]
			}
		},
		totalProductWeight: {
			get: function (){
				let weight = 0
				_.forEach(this.attributes.stops, (stop) => {
					if (this.deliverableItems[stop.contract_id] !== undefined && this.deliverableItems[stop.contract_id].length){
						_.forEach(this.deliverableItems[stop.contract_id], function (item){
							if (item.productWeight && stop.products[item.productId].deliver){
								weight += (stop.products[item.productId].quantity_to_deliver * item.productWeight)
							}
							if (item.productWeight && stop.products[item.productId].return){
								weight += (stop.products[item.productId].quantity_to_return * item.productWeight)
							}
						})
					}
				})

				return weight
			}
		},
		contractsById: {
			get: function (){
				let payload = _.keyBy(this.contractsDelivery, 'id')
				_.forEach(this.contractsReturn, function (contract){
					if (payload[contract.id] !== undefined){
						payload[contract.id].toReturn = contract.toReturn
					} else {
						payload[contract.id] = contract
					}
				})
				return payload
			}
		}
	},
	mounted() {
		if (this.attributes.stops.length){
			this.updateRoute()
		}
	},
	data(){
		let attributes = this.model
		if (attributes.id !== undefined){
			attributes.stops = attributes.current_stops
			_.forEach(attributes.stops, (stop, stopKey) => {
				_.forEach(this.initialDeliverableItems[stop.contract_id], (item) => {
					if (attributes.stops[stopKey].products[item.productId] === undefined) {
						attributes.stops[stopKey].products[item.productId] = {
							deliver: false,
							quantity: item.quantity
						}
					}
				})
			})
		}

		return {
			attributes: attributes,
			addContractId: null,
			highlightedStop: false,
			dataStore: useGeneralDataStore(),
			flash: useFlashStoreStore(),
			app: useApplicationStore(),
			colors: ['#6610F2', '#92235A', '#FD7E14', '#FFC107', '#6F42C1', '#1B722F', '#0D6EFD',  '#20C997',  '#17A2B8', '#96242F'],
			routeDetails: {},
			routeSegments: [],
			routeCoordinates: [],
			deliverableItems: this.initialDeliverableItems !== undefined ? this.initialDeliverableItems : {},
			lastSegmentColor: '',
			lastSegmentNumber: 0,
			distanceFromLast: 0,
			durationFromLast: 0,
			mapCenter: [0, 0],
			mapBounds: [0,0,0,0],
			hasValidRoute: false
		}
	},
	methods: {
		moveValueInArray,
		addStop: function (){
			if (this.addContractId){
				this.attributes.stops.push({
					contract_id: parseInt(this.addContractId),
					order: this.attributes.stops.length,
					products: {}
				})

				this.deliverableItems[this.addContractId] = []

				this.loadDeliverableItems(this.addContractId)
				this.addContractId = null
				this.updateRoute()
			}
		},
		removeStop: function(itemKey){
			this.attributes.stops.splice(itemKey, 1)
			this.updateRoute()
		},
		moveStop: function (stopKey, dir){
			this.attributes.stops = this.moveValueInArray(this.attributes.stops, stopKey, dir)
			this.updateRoute()
		},
		getColor: function(index){
			if (index > this.colors.length - 1){
				index = index % this.colors.length
			}

			return this.colors[index]
		},
		getIcon: function(segment){
			return L.divIcon({
				iconSize: [40, 60],
				iconAnchor: [20, 60],
				className: 'customIcon',
				html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="60" width="40">
				  <g transform="translate(-814.59595,-274.38623)">
					<g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
					  <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="fill:${segment.color};stroke:${segment.color};"/>
					  <circle r="3.0355" cy="288.25278" cx="823.03064" id="path3049" style="display:none;fill:#000"/>
					  <text x="823.03064" y="289.25278" dominant-baseline="middle" text-anchor="middle" style="fill:#fff;font-weight:bold;font-size:12px;">${segment.number}</text>
					</g>
				  </g>
				</svg>`
			});
		},
		updateStopMeta: function (){
			let order = 0
			this.attributes.stops.forEach(function(item, index){
				item.order = order
				order++
			})
			this.distanceFromLast = 0
			this.attributes.distance_total = 0
			this.durationFromLast = 0
			this.attributes.duration_total = 0
			this.lastSegmentColor = ''

			if (this.routeSegments.length){
				_.forEach(this.attributes.stops, function (tmp){
					tmp.distance_total = 0
					tmp.distance_from_previous = 0
					tmp.duration_total = 0
					tmp.duration_from_previous = 0
					tmp.color = '#fff'
				})

				let order = 1
				_.forEach(this.routeSegments, (segment, segmentIndex) => {
					let stopIndex = _.findIndex(this.attributes.stops, function (stop){
						return stop.contract_id === segment.contract_id
					})
					let color = this.getColor(segmentIndex)
					this.attributes.distance_total += segment.distance
					this.attributes.duration_total += segment.duration

					this.distanceFromLast = segment.distance
					this.durationFromLast = segment.duration

					this.routeSegments[segmentIndex].color = color
					this.routeSegments[segmentIndex].number = order++

					if (this.attributes.stops[stopIndex] !== undefined) {
						this.attributes.stops[stopIndex].distance_total = this.attributes.distance_total
						this.attributes.stops[stopIndex].distance_from_previous = segment.distance
						this.attributes.stops[stopIndex].duration_total = this.attributes.duration_total
						this.attributes.stops[stopIndex].duration_from_previous = segment.duration
						this.attributes.stops[stopIndex].color = color
						this.attributes.stops[stopIndex].number = this.routeSegments[segmentIndex].number

						this.routeSegments[segmentIndex].stopName = this.contractsById[this.attributes.stops[stopIndex].contract_id].clientName
						this.routeSegments[segmentIndex].stopCoords = [
							this.contractsById[this.attributes.stops[stopIndex].contract_id].lat,
							this.contractsById[this.attributes.stops[stopIndex].contract_id].lng
						]
					} else {
						this.routeSegments[segmentIndex].stopName = this.warehouse.name
						this.routeSegments[segmentIndex].stopCoords = [
							this.warehouse.lat,
							this.warehouse.lng
						]

						this.lastSegmentColor = color
						this.lastSegmentNumber = this.routeSegments[segmentIndex].number
					}
				})
			}
		},
		updateRoute: function (){
			axios.post(route('deliveryRoutes.planRoute'), {
				stops: this.attributes.stops,
				vehicleId: this.attributes.vehicle_id,
				warehouseId: this.attributes.warehouse_id,
			}).then((response)=>{
				if (response.data.success){
					this.mapBounds = response.data.bounds
					this.routeSegments = response.data.routes
					this.mapCenter = response.data.center

					this.updateStopMeta()

					this.hasValidRoute = true
				} else {
					this.flash.addFlash(response.data.flash)
					this.routeDetails = {}
					this.hasValidRoute = false
				}
			}).catch((error) => {
				this.flash.addError(error)
			})
		},
		getStopIndex: function (contractId){
			let index = null
			_.forEach(this.attributes.stops, function (stop, stopIndex){
				if (stop.contract_id === contractId){
					index = stopIndex
				}
			})

			return index
		},
		loadDeliverableItems: function (contractId){
			contractId = parseInt(contractId)
			axios.get(route('deliveryRoutes.loadDeliverableItems', [contractId, this.attributes.warehouse_id])).then((response)=>{
				this.deliverableItems[contractId] = response.data.deliverableItems
				this.attributes.products = {}
				_.forEach(this.deliverableItems[contractId], (item) => {
					this.attributes.stops[this.getStopIndex(contractId)].products[item.productId] = {
						deliver: item.quantityToDeliver > 0,
						return: item.quantityToReturn > 0,
						quantity_to_deliver: item.quantityToDeliver,
						quantity_to_return: item.quantityToReturn,
					}
				})
			}).catch((error) => {
				this.flash.addError(error)
			})
		},
		markerClicked: function (contractId){
			let stopIndex = null;
			_.forEach(this.attributes.stops, function (stop, index){
				if (stop.contract_id === contractId){
					stopIndex = index
				}
			})

			if (stopIndex !== this.highlightedStop){
				this.highlightedStop = stopIndex
			} else {
				this.highlightedStop = false
			}
		},
		closeDelivery: function (){
			this.attributes.close = true
			this.$refs.formWrapper.submit()
		}
	}
}
</script>
