<template>
	<div>
		<div class="d-flex flex-wrap gap-2">
			<template v-if="grouped">
				<div class="d-flex gap-2 flex-wrap p-2">
					<template v-if="showSubmitButton">
						<button type="submit" class="btn btn-primary btn-label" ref="saveButton" :disabled="invalid" v-if="!preventSubmit && editable && (!lockable || (lockable && lockAcquired))">
							<i :class="(saveButtonIcon ? saveButtonIcon : 'fas fa-save') + ' label-icon'"></i> {{ saveButtonLabel ? saveButtonLabel : 'Mentés'}}
						</button>
						<button type="button" class="btn btn-primary btn-label" ref="saveButton" :disabled="invalid" @click.prevent="submitPressed" v-if="preventSubmit && editable && (!lockable || (lockable && lockAcquired))">
							<i :class="(saveButtonIcon ? saveButtonIcon : 'fas fa-save') + ' label-icon'"></i> {{ saveButtonLabel ? saveButtonLabel : 'Mentés'}}
						</button>
					</template>
					<button type="button" class="btn btn-warning btn-label" @click.prevent="editPressed" v-if="lockable && editable && !lockAcquired">
						<i class="fas fa-pencil label-icon"></i> Szerkesztés
					</button>
					<template v-if="showBackButton">
						<Link :href="backUrl" v-if="backUrl" class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza</Link>
						<button type="button" @click.prevent="backPressed" v-else class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza</button>
					</template>
				</div>
				<slot></slot>
			</template>
			<template v-else>
				<template v-if="showSubmitButton">
					<button type="submit" class="btn btn-primary btn-label" ref="saveButton" :disabled="invalid" v-if="!preventSubmit && editable && (!lockable || (lockable && lockAcquired))">
						<i :class="(saveButtonIcon ? saveButtonIcon : 'fas fa-save') + ' label-icon'"></i> {{ saveButtonLabel ? saveButtonLabel : 'Mentés'}}
					</button>
					<button type="button" class="btn btn-primary btn-label" ref="saveButton" :disabled="invalid" @click.prevent="submitPressed" v-if="preventSubmit && editable && (!lockable || (lockable && lockAcquired))">
						<i :class="(saveButtonIcon ? saveButtonIcon : 'fas fa-save') + ' label-icon'"></i> {{ saveButtonLabel ? saveButtonLabel : 'Mentés'}}
					</button>
				</template>
				<button type="button" class="btn btn-warning btn-label" @click.prevent="editPressed" v-if="lockable && editable && !lockAcquired">
					<i class="fas fa-pencil label-icon"></i> Szerkesztés
				</button>
				<template v-if="showBackButton">
					<Link :href="backUrl" v-if="backUrl" class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza</Link>
					<button type="button" @click.prevent="backPressed" v-else class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza</button>
				</template>
				<slot></slot>
			</template>
		</div>
	</div>
</template>

<script>
import {Link} from "@inertiajs/vue3";
export default {
	components: {Link},
	props: {
		invalid: {
			type: Boolean,
			default: true
		},
		backUrl: {
			type: String
		},
		preventSubmit: {
			type: Boolean,
			default: false
		},
		buttons: {
			type: Array,
			default: function(){
				return []
			}
		},
		editable: {
			type: Boolean,
			default: true
		},
		showSubmitButton: {
			type: Boolean,
			default: true
		},
		showBackButton: {
			type: Boolean,
			default: true
		},
		lockable: {
			type: Boolean,
			default: false
		},
		lockAcquired: {
			type: Boolean,
			default: false
		},
		saveButtonLabel: {
			type: String,
			default: ''
		},
		saveButtonIcon: {
			type: String,
			default: ''
		},
		grouped: Boolean
	},
	updated() {
	},
	emits: ['backPressed', 'submitPressed', 'editPressed'],
	methods: {
		backPressed: function(){
			this.$emit('backPressed')
		},
		submitPressed: function(){
			this.$emit('submitPressed')
		},
		editPressed: function(){
			this.$emit('editPressed')
		},
		triggerSubmit: function (){
			if (this.$refs.saveButton !== undefined){
				this.$refs.saveButton.click()
			}
		}
	}
}
</script>

<style scoped>

</style>
