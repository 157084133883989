<template>
	<modal
		:title="(invoice.payment_method === 'cash' ? 'Számla létrehozása' : 'Díjbekérő létrehozása') + ': ' + contract.public_id + ' - ' + contract.title_formatted"
		:loading="loading"
		:closable="true"
		@close="$emit('close')">

		<template #buttons>
			<button type="submit" class="btn btn-primary btn-label waves-effect" @click.prevent="saveForm" :disabled="!valid" v-if="contract.id !== undefined">
				<i class="fas fa-save label-icon"></i>
				<template v-if="invoice.payment_method === 'cash'">
					Számla létrehozása
				</template>
				<template v-else-if="invoice.payment_method === 'wire_transfer'">
					Díjbekérő létrehozása
				</template>
			</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
				<i class="fas fa-times label-icon"></i> Mégse
			</button>
		</template>

		<template #default>
			<div class="card">
				<div class="card-body">
					<template v-if="contract.id !== undefined">
						<data-loader :data="['paymentMethods']" v-slot="{ dataStore }">
							<RadioField
								:values="dataStore.getData('paymentMethods')"
								label="Fizetési mód"
								name="payment_method"
								ignore-color
								v-model="invoice.payment_method"
							/>
						</data-loader>

						<switch-field
							label="Díjbekérő kiállítása"
							name="create_proforma"
							v-model="invoice.create_proforma"
							v-if="invoice.payment_method === 'wire_transfer'"
						></switch-field>

						<template v-if="!contract.custom_payment && !invoice.custom_payment">
							<div class="mb-3">
								<label>Következő részlet</label>
								<div class="form-control-plaintext">{{ nextPaymentPlanStep.name }}</div>
							</div>

							<div class="mb-3">
								<label>Következő részlet összege</label>
								<div class="form-control-plaintext">
									<Price
											:value="nextPaymentPlanStepPrice"
											:currency-id="contract.currency_id"
									></Price>
								</div>
							</div>
						</template>
						<template v-else>
							<switch-field
								label="Ügyfél értesítés tiltása"
								name="disable_client_notification"
								v-model="invoice.disable_client_notification"
							></switch-field>

							<input-field
								label="Tétel megnevezése"
								name="overrideProductName"
								v-model="invoice.overrideProductName"
							/>

							<input-field
								label="Tétel leírása"
								name="overrideProductDescription"
								v-model="invoice.overrideProductDescription"
							></input-field>

							<input-field
								label="Billingo számla letöltése URL"
								name="billingoDownloadUrl"
								rules="required"
								v-model="invoice.billingoDownloadUrl"
								v-if="invoice.payment_method === 'existingInvoice'"
								info="A Billingo felületen kattints a számla letöltése ikonra jobb gombbal, majd másold ki a hivatkozás linkjét."
							/>

							<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }" v-else>
								<input-field
									label="Összeg"
									name="amount"
									v-model="invoice.amount"
									data-type="number"
									:min="1"
									:max="Math.max(0, contract.total_price_gross - contract.paid_amount)"
									:step="1"
									rules="required"
									:prefix="String(dataStore.getData('currencies', true)[contract.currency_id].prefix || '').replace('_', '')"
									:suffix="String(dataStore.getData('currencies', true)[contract.currency_id].suffix || '').replace('_', '')"
									/>
							</data-loader>

							<textarea-field
								label="Megjegyzés"
								:name="'createContractPaymentComment'"
								v-model="invoice.comment"
							>
								<div class="mb-3 bg-light p-2">
									<variable-select
										label="Változó beszúrása"
										:field-only="true"
										:options="textVariables"
									/>
								</div>
							</textarea-field>
						</template>

						<SwitchField
								label="Egyedi számlázás"
								name="custom_payment"
								v-model="invoice.custom_payment"
								v-if="app.hasPermission('ProjectsController_enableCustomBilling') && !contract.custom_payment"
						/>
					</template>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import Price from "../../components/Price.vue";
import RadioField from "../../components/form/RadioField.vue";
import FileUploader from "../../components/FileUploader.vue";
import vSelect from "vue-select";
import SwitchField from "../../components/form/SwitchField.vue";
import InputField from "../../components/form/InputField.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useProjectDataStore} from "../../stores/projectData";
import {useApplicationStore} from "../../stores/applicationStore";
export default {
	components: {ColorBadge, DataLoader, InputField, SwitchField, FileUploader, RadioField, Price, vSelect},
	emits: ['close', 'closeAndReload', 'updateStats'],
	props: {
		dataUrl: String,
		contractId: [Number, String],
	},
	data() {
		const app = useApplicationStore()

		return {
			loading: true,
			contract: {},
			nextPaymentPlanStep: null,
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			app: app,
			nextPaymentPlanStepPrice: null,
			invoice: {
				payment_method: 'wire_transfer',
				custom_payment: false,
				amount: 0,
				create_proforma: true
			},
		}
	},
	computed: {
		valid: {
			get() {
				if (this.contract.custom || this.invoice.custom_payment){
					return this.invoice.amount > 0
				}

				return true
			}
		},
		textVariables: {
			get(){
				let variables = {
					'bruttó_összeg': 'Bruttó összeg',
					'bruttó_összeg_szöveg': 'Bruttó összeg szövegesen'
				}
				_.forEach(this.projectDataStore.projectType.fields_data, function (field){
					if (['title', 'copyButton'].indexOf(field.type) < 0){
						variables['mező_' + field.field_number] = field.field_number + '. ' + field.title
					}
					if (field.children !== undefined){
						_.forEach(field.children, function (child){
							if (['title', 'copyButton'].indexOf(child.type) < 0){
								variables['mező_' + child.field_number] = child.field_number + '. ' + field.title + ' / ' + child.title
							}
						})
					}
				})

				return variables
			}
		}
	},
	mounted() {
		this.loadData()
	},
	methods: {
		loadData: function(){
			this.loading = true

			axios.get('/projects/' + this.projectDataStore.projectId + '/contractInvoiceForm/' + this.contractId).then((response)=>{
				this.handleResponse(response)
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status !== undefined && response.data.status === 'success') {
				this.$emit('closeAndReload')
			}
			if (response.data.contract !== undefined) {
				this.contract = response.data.contract
				this.invoice.amount = Math.max(0, this.contract.total_price_gross - this.contract.paid_amount)
				if (this.contract.custom_payment){
					this.invoice.custom_payment = 1
				}
			}
			if (response.data.nextPaymentPlanStep !== undefined) {
				this.nextPaymentPlanStep = response.data.nextPaymentPlanStep
				this.nextPaymentPlanStepPrice = response.data.nextPaymentPlanStepPrice
			}
			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
		},
		saveForm: function(e){
			if (this.valid){
				this.loading = true
				axios.post('/projects/createContractInvoice/' + this.contractId, this.invoice).then((response)=>{
					this.handleResponse(response)
				}).catch((error) => {
					this.loading = false
					this.flashStore.addError(error)
				})
			}
		},
		emitClose: function(){
			this.$emit('close')
		},
	}
}
</script>

<style scoped>

</style>
<script setup>
import Modal from "../../components/Modal.vue";
import InputField from "../../components/form/InputField.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import VariableSelect from "../../components/form/VariableSelect.vue";
import RadioField from "../../components/form/RadioField.vue";
import DataLoader from "../../components/DataLoader.vue";
</script>
