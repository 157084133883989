import './bootstrap';
import { createApp, h } from 'vue'
import {createInertiaApp, router} from '@inertiajs/vue3'
import { createPinia } from 'pinia'
import { PiniaDebounce } from '@pinia/plugin-debounce'
import { debounce } from 'lodash'
import { i18nVue, trans } from 'laravel-vue-i18n'
import CKEditor from '@ckeditor/ckeditor5-vue';
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

const pinia = createPinia()
pinia.use(PiniaDebounce(debounce))

const app = createInertiaApp({
	resolve: name => require(`./pages/${name}`),
	title: title => `${title} - evergo CRM`,
	progress: false,
	setup({ el, App, props, plugin }) {
		createApp({ render: () => h(App, props) })
			.use(plugin)
			.use(pinia)
			.use(FloatingVue, {})
			.use(CKEditor)
			.use(i18nVue, {
				resolve: lang => import(`../lang/${lang}.json`),
			})
			.mixin({ methods: { route } })
			.mount(el)
	}
})

function checkJsVersion(){
	axios.get('/mix-manifest.json').then(response => {
		if (response.data['/js/app.js'] !== undefined) {
			let version = response.data['/js/app.js'].replace('/js/app.js?id=', '')
			if (window.jsVersion === undefined) {
				window.jsVersion = version
			} else {
				if (window.jsVersion !== version) {
					if (!document.getElementsByClassName('reload-overlay-bg').length) {
						const dialog = document.createElement('div')
						dialog.innerHTML = '<div class="reload-overlay-bg">' +
							'<div class="reload-overlay">' +
							'<p>A rendszer verziófrissítésen esett át, kérlek frissítsd az oldalt!</p>' +
							'<div class="mt-3 text-center"><button type="button" class="btn btn-primary btn-label" onClick="window.location.href=window.location.href"><i class="far fa-sync-alt label-icon"></i>Oldal újratöltése</button></div>' +
							'</div>' +
							'</div>'

						document.body.appendChild(dialog)
					}
				}
			}
		}
	})
}

checkJsVersion()
setInterval(checkJsVersion, 60 * 1000)

let stale = false;

window.addEventListener('popstate', () => {
	stale = true;
});

router.on('navigate', (event) => {
	const page = event.detail.page;
	if (stale) {
		router.get(page.url, {}, { replace: true, preserveState: false });
	}
	stale = false;
});

