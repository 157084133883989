<template>
	<data-loader :data="['products']" v-slot="{ dataStore }">
		<div :key="'productList_' + wrapperKey" v-if="!productError">
			<template v-for="productCategory in computedProductCategories" :key="productCategory.id">
				<div v-if="displayCategory(productCategory)" :class="{
									'card': 1,
									'border': 1,
									'border-danger': !validateProductCategory(productCategory) && isCategoryRequired(productCategory),
									'border-success': validateProductCategory(productCategory) && categoryHasProduct(productCategory.id)
								}">
					<div class="card-body">
						<h4 :class="{
											'card-title': 1,
											'mb-4': 1,
											'd-flex': 1,
											'align-items-center': 1,
											'text-danger': !validateProductCategory(productCategory) && isCategoryRequired(productCategory),
											'text-success': validateProductCategory(productCategory) && categoryHasProduct(productCategory.id),
											}">
							<i class="far fa-check font-size-18 me-3" v-if="validateProductCategory(productCategory) && categoryHasProduct(productCategory.id)"></i>

							{{ productCategory.name }}

							<span class="badge bg-danger badge-xl ms-3" v-if="!validateProductCategory(productCategory) && isCategoryRequired(productCategory)">Hiányzó termékek</span>

							<button type="button" class="btn btn-primary btn-label btn-sm ms-auto" v-if="isEditable" @click.prevent="addProduct(productCategory.id)"><i class="far fa-plus label-icon"></i> Termék hozzáadása</button>
						</h4>

						<table class="table table-collapse-mobile-auto">
							<thead>
							<tr>
								<th>Termék</th>
								<th width="160" v-if="parentModel.amendments !== undefined && parentModel.amendments.length">Eredeti menny.</th>
								<th width="160">Menny.</th>
								<th width="80" v-if="displayStock">Raktáron</th>
								<th width="90">Infó</th>
								<th width="10%" class="text-end text-nowrap">Nettó egys.</th>
								<th width="10%" class="text-end">Bruttó ár</th>
								<th width="1%" v-if="isEditable">&nbsp;</th>
							</tr>
							</thead>
							<tbody>
							<tr v-if="!categoryHasProduct(productCategory.id)">
								<td colspan="6">Nincs termék hozzáadva</td>
							</tr>
							<template v-for="(product, productIndex) in componentValue">
								<template v-if="(!product.product_id || dataStore.getProductDetails(product.product_id)) && productDisplayableInCategory(product, productCategory)">
									<tr :class="{'text-muted': dataStore.getProductDetails(product.product_id) !== false
										&& dataStore.getProductDetails(product.product_id).is_optional
										&& (mode === 'quote' || mode === 'foreignContract')
										&& parentModel.optionals_selected[product.product_id] === undefined
										&& parentModel.optionals_included[product.product_id] === undefined}">
										<td>
											<v-select
												v-model="product.product_id"
												:options="filteredProducts(productCategory.id, product.product_id)"
												:reduce="option => option.id"
												label="name"
												:multiple="false"
												:clearable="false"
												v-if="isEditable && (!product.product_id || !dataStore.getProductDetails(product.product_id).deleted_at)"
												@option:selected="productChanged(productIndex)"
											>
												<template v-slot:option="option">
													<div>
														<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
															<template v-if="option.product_manufacturer_id !== null && dataStore.getData('productManufacturers', true)[option.product_manufacturer_id] !== undefined">
																<strong>{{ dataStore.getData('productManufacturers', true)[option.product_manufacturer_id].name }}</strong>
																<span>&nbsp;-&nbsp;</span>
															</template>
															{{ option.name }}
														</data-loader>
													</div>
												</template>
												<template v-slot:selected-option="option">
													<div>
														<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
															<template v-if="option.product_manufacturer_id !== null && dataStore.getData('productManufacturers', true)[option.product_manufacturer_id] !== undefined">
																<strong>{{ dataStore.getData('productManufacturers', true)[option.product_manufacturer_id].name }}</strong>
																<span>&nbsp;-&nbsp;</span>
															</template>
															{{ option.name }}
														</data-loader>
													</div>
												</template>
											</v-select>
											<div v-else>
												<product-badge
													:product-id="product.product_id"
													:product-name="dataStore.getProductDetails(product.product_id).name"
													:product-manufacturer-id="dataStore.getProductDetails(product.product_id).product_manufacturer_id"
													:key="'PB' + product.product_id"
												/>
											</div>
											<div v-if="['quote', 'sale'].indexOf(mode) > -1 && dataStore.getProductDetails(product.product_id) !== false && dataStore.getProductDetails(product.product_id).is_optional" class="mt-2">
												<switch-field
													:inline="true"
													v-model="parentModel.optionals_included[product.product_id]"
													label="Opcionális"
													off-label="Opcionális"
													on-label="Fix"
													@input="productChanged(-1)"
													:name="'bundleProductOptionalSelect_' + [productIndex, product.product_id].join('_')"
													:colored-background="true"
													v-if="parentModel.status === 'draft' && isEditable"
												/>
												<template v-else-if="parentModel.optionals_included[product.product_id] === undefined || !parentModel.optionals_included[product.product_id]">
													<span class="badge badge-xl bg-warning">Opcionális</span>
													<div v-if="parentModel.status === 'accepted'" class="mt-1">
														<span class="badge badge-xl bg-success" v-if="parentModel.optionals_included[product.product_id] !== undefined
															&& parentModel.optionals_included[product.product_id]">Kéri</span>
														<span class="badge badge-xl bg-danger" v-else>Nem kéri</span>
													</div>
												</template>
											</div>
										</td>
										<td v-if="parentModel.amendments !== undefined && parentModel.amendments.length">
											{{ product.original_quantity }} {{ dataStore.getProductDetails(product.product_id).bundle_unit }}
										</td>
										<td>
											<template v-if="dataStore.getProductDetails(product.product_id) !== false
												&& dataStore.getProductDetails(product.product_id).type === 'bundle'
												&& dataStore.getProductDetails(product.product_id).bundle_use_custom_unit">
												<InputField
													v-model="product.quantity"
													v-if="isEditable"
													data-type="number"
													label="Mennyiség"
													:name="getInputName(productCategory, productIndex, 'quantity') + '_b'"
													:key="getInputName(productCategory, productIndex, 'quantity') + '_b'"
													:step="1"
													:min="getProductMinQuantity(product)"
													:max="getProductMaxQuantity(product)"
													:field-only="true"
													:zero-allowed="false"
													:disabled="!isEditable"
													:suffix="dataStore.getProductDetails(product.product_id).bundle_unit"
													@update:modelValue="productChanged(-1)"
												>
												</InputField>
												<template v-else>
													<span :class="{'fw-bold': parentModel.amendments !== undefined && parentModel.amendments.length && product.quantity !== product.original_quantity}">
														{{ product.quantity }} {{ dataStore.getProductDetails(product.product_id).bundle_unit }}
													</span>
												</template>
											</template>
											<template v-else>
												<InputField
													v-model="product.quantity"
													v-if="isEditable"
													data-type="number"
													label="Mennyiség"
													:name="getInputName(productCategory, productIndex, 'quantity')"
													:key="getInputName(productCategory, productIndex, 'quantity')"
													:field-only="true"
													:zero-allowed="false"
													:step="1"
													:min="getProductMinQuantity(product)"
													:max="getProductMaxQuantity(product)"
													:disabled="!isEditable"
													:spinner-buttons="true"
													@update:modelValue="productChanged(-1)"
												>
												</InputField>
												<template v-else>
													{{ product.quantity }} {{ dataStore.getProductDetails(product.product_id) !== false ? dataStore.getProductDetails(product.product_id).unit : 'db' }}
												</template>
											</template>
										</td>
										<td v-if="displayStock" class="text-nowrap vertical-align-middle text-end fw-bold border-bottom-0">
											<color-badge
												v-if="getStock(product.index) !== null"
												:color="getStock(product.index) >= product.quantity ? 'success' : 'danger'"
												small
												>
												{{ getStock(product.index) }} {{ dataStore.getProductDetails(product.product_id) !== false ? dataStore.getProductDetails(product.product_id).unit : 'db' }}
											</color-badge>
										</td>
										<td>
											<div class="d-flex gap-2 flex-column">
												<div v-if="product.product_id">
													<data-loader :data="['productTypes']" v-slot="{ dataStore }">
														<color-badge :value="dataStore.getData('productTypes')[dataStore.getProductDetails(product.product_id).type]" small/>
													</data-loader>
												</div>
												<ProductAttribute
													v-if="product.product_id && dataStore.getProductDetails(product.product_id).main_attribute !== undefined && dataStore.getProductDetails(product.product_id).main_attribute.amount"
													:product-attribute-id="dataStore.getProductDetails(product.product_id).main_attribute.id"
													:value="dataStore.getProductDetails(product.product_id).main_attribute.amount * Math.abs(product.quantity)"
												></ProductAttribute>

												<div v-if="mode === 'quote' && dataStore.getProductDetails(product.product_id) !== false && dataStore.getProductDetails(product.product_id).is_optional" class="mt-2">
													<color-badge color="warning" small>Opcionális</color-badge>
													<div v-if="parentModel.status === 'accepted'" class="mt-1">
														<color-badge
															:color="parentModel.optionals_selected[product.product_id] !== undefined ? 'success' : 'danger'"
															small
															:content="parentModel.optionals_selected[product.product_id] !== undefined ? 'Kéri' : 'Nem kéri'"/>
													</div>
												</div>
											</div>
										</td>
										<td class="text-end vertical-align-middle">
											<mobile-attribute
												label="Nettó egységár"
												color="secondary"
											>
												<template #default>
													<Price
														v-if="product.product_id"
														:value="(product.quantity < 0 ? -1 : 1) * getUnitPrice(product.index, false, true)"
														:loading="pricesLoading"
													/>
												</template>
											</mobile-attribute>
											<div
												v-if="product.product_id && getUnitPrice(product.index, false, false, null, true)"
												class="mb-1 font-size-10 text-muted">
												<mobile-attribute
													label="Eredeti nettó egységár"
													color="dark"
												>
													<template #default>
														<Price
															:value="(product.quantity < 0 ? -1 : 1) * (getUnitPrice(product.index, false, true, null, true))"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</div>
										</td>
										<td class="text-end vertical-align-middle">
											<mobile-attribute
												label="Bruttó ár"
												color="secondary"
											>
												<template #default>
													<Price
														v-if="product.product_id"
														:value="getProductTotalPrice(product, true, true)"
														:loading="pricesLoading"
													/>
												</template>
											</mobile-attribute>
											<div
												v-if="product.product_id && getUnitPrice(product.index, false, true, null, true)"
												class="mb-1 font-size-10 text-muted">
												<mobile-attribute
													label="Eredeti bruttó ár"
													color="dark"
												>
													<template #default>
														<Price
															:value="getProductTotalPrice(product, true, true, true)"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</div>
										</td>
										<td class="text-end vertical-align-middle" v-if="isEditable">
											<button type="button" @click.prevent="removeProduct(product.index)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
										</td>
									</tr>
									<template v-if="dataStore.getProductDetails(product.product_id) !== false && dataStore.getProductDetails(product.product_id).type === 'bundle'">
										<template v-for="bundleProduct in getBundleProducts(product.product_id, product.version)">
											<template v-if="bundleProduct.pivot.selectable && (getBundleProductId(product, bundleProduct) || isEditable)">
												<template v-if="getBundleItemIsDisplayable(product, bundleProduct.pivot.order)">
													<tr :class="{'text-muted': bundleProduct.optional && !isOptionalSelected(bundleProduct)}" :key="'bundleProduct_' + [productIndex, product.product_id, bundleProduct.pivot.order].join('_')">
														<td class="border-bottom-0 bg-light">
															<v-select
																v-model="product.bundle_products[bundleProduct.pivot.order]"
																:options="getBundleItemSelectableProducts(bundleProduct.productIds)"
																:reduce="option => option.id"
																label="name"
																:placeholder="bundleProduct.pivot.label"
																:multiple="false"
																:clearable="bundleProduct.pivot.optional === 1"
																:disabled="!isEditable"
																@update:modelValue="productChanged(-1)"
																v-if="isEditable"
															>
																<template v-slot:option="option">
																	<div>
																		<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
																			<template v-if="option.product_manufacturer_id !== null && dataStore.getData('productManufacturers', true)[option.product_manufacturer_id] !== undefined">
																				<strong>{{ dataStore.getData('productManufacturers', true)[option.product_manufacturer_id].name }}</strong>
																				<span>&nbsp;-&nbsp;</span>
																			</template>
																			{{ option.name }}
																		</data-loader>
																	</div>
																</template>
																<template v-slot:selected-option="option">
																	<div>
																		<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
																			<template v-if="option.product_manufacturer_id !== null && dataStore.getData('productManufacturers', true)[option.product_manufacturer_id] !== undefined">
																				<strong>{{ dataStore.getData('productManufacturers', true)[option.product_manufacturer_id].name }}</strong>
																				<span>&nbsp;-&nbsp;</span>
																			</template>
																			{{ option.name }}
																		</data-loader>
																	</div>
																</template>
															</v-select>
															<template v-else-if="getBundleProductId(product, bundleProduct)">
																<product-badge
																	:product-id="product.product_id"
																	:product-name="dataStore.getProductDetails(getBundleProductId(product, bundleProduct)).name"
																	:product-manufacturer-id="dataStore.getProductDetails(getBundleProductId(product, bundleProduct)).product_manufacturer_id"
																	:key="'PB' + product.product_id"
																/>
															</template>
															<div v-if="['quote', 'sale'].indexOf(mode) > -1 && bundleProduct.optional && product.bundle_products[bundleProduct.pivot.order]" class="mt-1">
																<switch-field
																	:inline="true"
																	v-model="parentModel.optionals_included[product.bundle_products[bundleProduct.pivot.order]]"
																	label="Opcionális"
																	off-label="Opcionális"
																	on-label="Fix"
																	:name="'bundleProductOptionalSelect_' + [productIndex, product.product_id, bundleProduct.pivot.order].join('_')"
																	:colored-background="true"
																	@input="productChanged(-1)"
																	v-if="parentModel.status === 'draft' && isEditable"
																/>
																<template v-else-if="parentModel.optionals_included[product.bundle_products[bundleProduct.pivot.order]] === undefined || !parentModel.optionals_included[product.bundle_products[bundleProduct.pivot.order]]">
																	<span class="badge badge-xl bg-warning">Opcionális</span>
																	<div v-if="parentModel.status === 'accepted'" class="mt-1">
																		<span class="badge badge-xl bg-success" v-if="isOptionalSelected(bundleProduct)">Kéri</span>
																		<span class="badge badge-xl bg-danger" v-else>Nem kéri</span>
																	</div>
																</template>
															</div>
														</td>
														<td class="text-nowrap vertical-align-middle text-end fw-bold border-bottom-0 bg-light">
															<div v-if="getBundleProductId(product, bundleProduct)">
																{{ getBundleItemQuantity(product, bundleProduct.pivot.order) }} {{ getBundleItemUnit(getBundleProductId(product, bundleProduct)) }}
															</div>
														</td>
														<td v-if="displayStock" class="text-nowrap vertical-align-middle text-end fw-bold border-bottom-0 bg-light">
															<color-badge
																v-if="getStock(product.index, bundleProduct.pivot.order) !== null"
																:color="getStock(product.index, bundleProduct.pivot.order) >= product.quantity ? 'success' : 'danger'"
																small
															>
																{{ getStock(product.index, bundleProduct.pivot.order) }} {{ getBundleItemUnit(getBundleProductId(product, bundleProduct)) }}
															</color-badge>
														</td>
														<td class="text-nowrap vertical-align-middle fw-bold border-bottom-0 bg-light">
															<div v-if="getBundleProductId(product, bundleProduct)">
																<ProductAttribute
																	v-if="dataStore.getProductDetails(getBundleProductId(product, bundleProduct)).main_attribute && dataStore.getProductDetails(getBundleProductId(product, bundleProduct)).main_attribute.amount"
																	:product-attribute-id="dataStore.getProductDetails(getBundleProductId(product, bundleProduct)).main_attribute.id"
																	:value="dataStore.getProductDetails(getBundleProductId(product, bundleProduct)).main_attribute.amount * getBundleItemQuantity(product, bundleProduct.pivot.order)"
																	:dark="true"
																	></ProductAttribute>
															</div>
														</td>
														<td class="border-bottom-0 bg-light text-end">
															<mobile-attribute
																label="Nettó egységár"
																color="secondary"
															>
																<template #default>
																	<Price
																		v-if="getBundleProductId(product, bundleProduct)"
																		:value="getUnitPrice(product.index, false, true, bundleProduct.pivot.order)"
																		:loading="pricesLoading"
																	/>
																</template>
															</mobile-attribute>
															<div
																v-if="getUnitPrice(product.index, false, true, bundleProduct.pivot.order, true)"
																class="mb-1 font-size-10 text-muted">
																<mobile-attribute
																	label="Eredeti nettó egységár"
																	color="dark"
																>
																	<template #default>
																		<Price
																			:value="getUnitPrice(product.index, false, true, bundleProduct.pivot.order, true)"
																			:loading="pricesLoading"
																		/>
																	</template>
																</mobile-attribute>
															</div>
														</td>
														<td class="border-bottom-0 bg-light text-end">
															<mobile-attribute
																label="Bruttó ár"
																color="secondary"
															>
																<template #default>
																	<Price
																		v-if="getBundleProductId(product, bundleProduct)"
																		:value="(getUnitPrice(product.index, true, true, bundleProduct.pivot.order)) * getBundleItemQuantity(product, bundleProduct.pivot.order)"
																		:loading="pricesLoading"
																	/>
																</template>
															</mobile-attribute>
															<div
																v-if="getUnitPrice(product.index, false, true, bundleProduct.pivot.order, true)"
																class="mb-1 font-size-10 text-muted">
																<mobile-attribute
																	label="Eredeti bruttó ár"
																	color="dark"
																>
																	<template #default>
																		<Price
																			:value="getUnitPrice(product.index, true, true, bundleProduct.pivot.order, true) * getBundleItemQuantity(product, bundleProduct.pivot.order)"
																			:loading="pricesLoading"
																		/>
																	</template>
																</mobile-attribute>
															</div>
														</td>
														<td class="border-bottom-0 bg-light mobile-hidden" v-if="isEditable">

														</td>
													</tr>
												</template>
											</template>
											<template v-else>
												<template v-if="getBundleItemIsDisplayable(product, bundleProduct.pivot.order) && (isEditable || bundleProduct.productId !== undefined)">
													<tr :class="{'text-muted': bundleProduct.optional && isOptionalSelected(bundleProduct)}">
														<td class="border-bottom-0 bg-light">
															<div v-if="dataStore.getProductDetails(bundleProduct.productId) !== false">
																<product-badge
																	:product-id="bundleProduct.productId"
																	:product-name="dataStore.getProductDetails(bundleProduct.productId).name"
																	:product-manufacturer-id="dataStore.getProductDetails(bundleProduct.productId).product_manufacturer_id"
																	:key="'PB' + bundleProduct.productId"
																/>
															</div>

															<div v-if="['quote', 'sale'].indexOf(mode) > -1 && bundleProduct.optional && bundleProduct.productId !== undefined" class="mt-1">
																<switch-field
																	:inline="true"
																	v-model="parentModel.optionals_included[bundleProduct.productId]"
																	label="Opcionális"
																	off-label="Opcionális"
																	on-label="Fix"
																	:name="'bundleProductOptionalSelect_' + [productIndex, product.product_id, bundleProduct.pivot.order].join('_')"
																	:colored-background="true"
																	@input="productChanged(-1)"
																	v-if="parentModel.status === 'draft' && isEditable"
																/>
																<template v-else-if="parentModel.optionals_included[bundleProduct.productId] === undefined || !parentModel.optionals_included[bundleProduct.productId]">
																	<span class="badge badge-xl bg-warning">Opcionális</span>
																	<div v-if="parentModel.status === 'accepted'" class="mt-1">
																		<span class="badge badge-xl bg-success" v-if="isOptionalSelected(bundleProduct)">Kéri</span>
																		<span class="badge badge-xl bg-danger" v-else>Nem kéri</span>
																	</div>
																</template>
															</div>
														</td>
														<td class="text-nowrap vertical-align-middle text-end fw-bold border-bottom-0 bg-light">
															{{ getBundleItemQuantity(product, bundleProduct.pivot.order) }} {{ getBundleItemUnit(bundleProduct.productId) }}
														</td>
														<td v-if="displayStock" class="text-nowrap vertical-align-middle text-end fw-bold border-bottom-0 bg-light">
															<color-badge
																v-if="getStock(product.index, bundleProduct.pivot.order) !== null"
																:color="getStock(product.index, bundleProduct.pivot.order) >= product.quantity ? 'success' : 'danger'"
																small
															>
																{{ getStock(product.index, bundleProduct.pivot.order) }} {{ getBundleItemUnit(bundleProduct.productId) }}
															</color-badge>
														</td>
														<td class="text-nowrap vertical-align-middle fw-bold border-bottom-0 bg-light">
															<ProductAttribute
																v-if="bundleProduct.productId !== undefined && dataStore.getProductDetails(bundleProduct.productId).main_attribute !== undefined && dataStore.getProductDetails(bundleProduct.productId).main_attribute.amount"
																:product-attribute-id="dataStore.getProductDetails(bundleProduct.productId).main_attribute.id"
																:value="dataStore.getProductDetails(bundleProduct.productId).main_attribute.amount * getBundleItemQuantity(product, bundleProduct.pivot.order)"
																:dark="true"
															></ProductAttribute>
														</td>
														<td class="border-bottom-0 bg-light text-end">
															<mobile-attribute
																label="Nettó egységár"
																color="secondary"
															>
																<template #default>
																	<Price
																		:value="getUnitPrice(product.index, false, true, bundleProduct.pivot.order)"
																		:loading="pricesLoading"
																	/>
																</template>
															</mobile-attribute>
															<div
																v-if="getUnitPrice(product.index, false, true, bundleProduct.pivot.order, true)"
																class="mb-1 font-size-10 text-muted">
																<mobile-attribute
																	label="Eredeti nettó egységár"
																	color="dark"
																>
																	<template #default>
																		<Price
																			:value="getUnitPrice(product.index, false, true, bundleProduct.pivot.order, true)"
																			:loading="pricesLoading"
																		/>
																	</template>
																</mobile-attribute>
															</div>
														</td>
														<td class="border-bottom-0 bg-light text-end">
															<mobile-attribute
																label="Bruttó ár"
																color="secondary"
															>
																<template #default>
																	<Price
																		:value="(getUnitPrice(product.index, true, true, bundleProduct.pivot.order)) * getBundleItemQuantity(product, bundleProduct.pivot.order)"
																		:loading="pricesLoading"
																	/>
																</template>
															</mobile-attribute>
															<div
																v-if="getUnitPrice(product.index, false, true, bundleProduct.pivot.order, true)"
																class="mb-1 font-size-10 text-muted">
																<mobile-attribute
																	label="Eredeti bruttó ár"
																	color="dark"
																>
																	<template #default>
																		<Price
																			:value="getUnitPrice(product.index, true, true, bundleProduct.pivot.order, true) * getBundleItemQuantity(product, bundleProduct.pivot.order)"
																			:loading="pricesLoading"
																		/>
																	</template>
																</mobile-attribute>
															</div>
														</td>
														<td class="border-bottom-0 bg-light mobile-hidden" v-if="isEditable">

														</td>
													</tr>
												</template>
											</template>
										</template>
									</template>
								</template>
							</template>
							</tbody>
							<tfoot>
							<tr>
								<th :colspan="displayStock ? 5 : 4" class="text-end">Végösszeg</th>
								<th class="text-end">
									<mobile-attribute
										color="light"
									>
										<template #default>
											<Price
												:value="getCategoryTotalPrice(productCategory.id, true)"
												:loading="pricesLoading"
											/>
										</template>
									</mobile-attribute>
								</th>
								<th v-if="isEditable">&nbsp;</th>
							</tr>
							</tfoot>
						</table>
						<div class="mt-2" v-if="isEditable">
							<button type="button" class="btn btn-primary btn-label btn-sm ms-auto" @click.prevent="addProduct(productCategory.id)"><i class="far fa-plus label-icon"></i> Termék hozzáadása</button>
						</div>
					</div>
				</div>
			</template>
			<div class="card" v-if="stats !== undefined && stats.tags !== undefined && stats.tags.length">
				<div class="card-body">
					<table class="table m-0 table-collapse-mobile-auto">
						<thead>
						<tr>
							<th>Cimke</th>
							<th width="15%">Arány</th>
							<th width="15%" class="text-end">Nettó</th>
							<th width="15%" class="text-end">ÁFA</th>
							<th width="15%" class="text-end">Bruttó</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(stat) in stats.tags">
							<td>
								<data-loader :data="['productTags']" v-slot="{ dataStore }">
									{{ dataStore.getData('productTags', true)[stat.tag_id].label }}
								</data-loader>
							</td>
							<td>
								<mobile-attribute
									label="Arány"
									color="light"
								>
									<template #default>
										<number :value="stat.percent" suffix="%"/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Nettó"
									color="light"
								>
									<template #default>
										<Price
											:value="stat.net"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="ÁFA"
									color="light"
								>
									<template #default>
										<Price
											:value="stat.gross - stat.net"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Bruttó"
									color="light"
								>
									<template #default>
										<Price
											:value="stat.gross"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card" v-if="stats !== undefined && stats.types !== undefined && stats.types.length">
				<div class="card-body">
					<table class="table m-0 table-collapse-mobile-auto">
						<thead>
						<tr>
							<th>Típus</th>
							<th width="15%">Arány</th>
							<th width="15%" class="text-end">Nettó</th>
							<th width="15%" class="text-end">ÁFA</th>
							<th width="15%" class="text-end">Bruttó</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="stat in stats.types">
							<td>
								<data-loader :data="['productTypes']" v-slot="{ dataStore }">
									<color-badge :value="dataStore.getData('productTypes')[stat.type]"/>
								</data-loader>
							</td>
							<td>
								<mobile-attribute
									label="Arány"
									color="light"
								>
									<template #default>
										<number :value="stat.percent" suffix="%"/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Nettó"
									color="light"
								>
									<template #default>
										<Price
											:value="stat.net"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="ÁFA"
									color="light"
								>
									<template #default>
										<Price
											:value="stat.gross - stat.net"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Bruttó"
									color="light"
								>
									<template #default>
										<Price
											:value="stat.gross"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card" v-if="!hideSummary">
				<div class="card-body">
					<table class="table table-collapse-mobile-auto">
						<thead>
						<tr>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
							<th width="15%" class="text-end">Nettó</th>
							<th width="15%" class="text-end">ÁFA</th>
							<th width="15%" class="text-end">Bruttó</th>
						</tr>
						</thead>
						<tbody>
						<tr v-if="getTotalPrice(false, false, 'work') - getTotalPrice(false, true, 'work') > 0">
							<td class="text-end" colspan="2">
								<strong>Kedvezmény a munka jellegű tételekből</strong>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Nettó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(false, false, 'work') - getTotalPrice(false, true, 'work')"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="ÁFA"
									color="light"
								>
									<template #default>
										<Price
											:value="(getTotalPrice(true, false, 'work') - getTotalPrice(true, true, 'work')) - (getTotalPrice(false, false, 'work') - getTotalPrice(false, true, 'work'))"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Bruttó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(true, false, 'work') - getTotalPrice(true, true, 'work')"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
						</tr>
						<tr v-if="getTotalPrice(false, false, 'material') - getTotalPrice(false, true, 'material') > 0">
							<td class="text-end" colspan="2">
								<strong>Kedvezmény az anyag jellegű tételekből</strong>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Nettó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(false, false, 'material') - getTotalPrice(false, true, 'material')"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="ÁFA"
									color="light"
								>
									<template #default>
										<Price
											:value="(getTotalPrice(true, false, 'material') - getTotalPrice(true, true, 'material')) - (getTotalPrice(false, false, 'material') - getTotalPrice(false, true, 'material'))"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Bruttó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(true, false, 'material') - getTotalPrice(true, true, 'material')"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
						</tr>
						<tr v-if="getTotalPrice(false, false, 'service') - getTotalPrice(false, true, 'service') > 0">
							<td class="text-end" colspan="2">
								<strong>Kedvezmény a szolgáltatás jellegű tételekből</strong>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Nettó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(false, false, 'service') - getTotalPrice(false, true, 'service')"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="ÁFA"
									color="light"
								>
									<template #default>
										<Price
											:value="(getTotalPrice(true, false, 'service') - getTotalPrice(true, true, 'service')) - (getTotalPrice(false, false, 'service') - getTotalPrice(false, true, 'service'))"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Bruttó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(true, false, 'service') - getTotalPrice(true, true, 'service')"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
						</tr>
						<tr v-if="getTotalPrice(false, false, 'relayedService') - getTotalPrice(false, true, 'relayedService') > 0">
							<td class="text-end" colspan="2">
								<strong>Kedvezmény a közvetített szolgáltatás jellegű tételekből</strong>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Nettó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(false, false, 'relayedService') - getTotalPrice(false, true, 'relayedService')"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="ÁFA"
									color="light"
								>
									<template #default>
										<Price
											:value="(getTotalPrice(true, false, 'relayedService') - getTotalPrice(true, true, 'relayedService')) - (getTotalPrice(false, false, 'relayedService') - getTotalPrice(false, true, 'relayedService'))"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Bruttó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(true, false, 'relayedService') - getTotalPrice(true, true, 'relayedService')"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
						</tr>
						<tr>
							<td class="text-end" colspan="2">
								<strong>Végösszeg</strong>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Nettó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(false, true)"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="ÁFA"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(true, true) - getTotalPrice(false, true)"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end">
								<mobile-attribute
									label="Bruttó"
									color="light"
								>
									<template #default>
										<Price
											:value="getTotalPrice(true, true)"
											:loading="pricesLoading"
										/>
									</template>
								</mobile-attribute>
							</td>
						</tr>
						</tbody>
					</table>

					<slot></slot>
				</div>
			</div>
		</div>
		<div class="alert alert-danger" v-else>
			Az ajánlat egyik terméke megváltozott, ezért az ajánlatot nem lehet betölteni.
		</div>
	</data-loader>
</template>

<script>
import Price from '../../components/Price.vue';
import vSelect from "vue-select";
import InputField from "../../components/form/InputField.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import RadioField from "../../components/form/RadioField.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import ProductAttribute from "../../components/ProductAttribute.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import {useProjectDataStore} from "../../stores/projectData";
import {useGeneralDataStore} from "../../stores/generalData";
import Number from "../../components/Number.vue";
import MobileAttribute from "../../components/MobileAttribute.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";
import ProductBadge from "../../components/badges/ProductBadge.vue";
import SelectField from "../../components/form/SelectField.vue";
import projectTypePaymentPlans from "../projectTypes/ProjectTypePaymentPlans.vue";
export default {
	components: {
		SelectField,
		ProductBadge,
		ProjectBadge,
		MobileAttribute,
		Number, DataLoader, ProductAttribute, SwitchField, RadioField, ColorBadge, Price, vSelect, InputField},
	emits: ['update:modelValue', 'validationChanged', 'projectTypeMainAttributeValueChanged'],
	props: {
		dataUrl: String,
		products: Array,
		isEditable: Boolean,
		modelValue: Array,
		parentModel: Object,
		productVersions: Object,
		hideSummary: Boolean,
		initialStats: {
			type: Object,
			default: function(){
				return {}
			}
		},
		initialPrices: Object,
		limitedProductQuantities: {
			type: Object,
			default: function(){
				return {}
			}
		},
		mode: {
			type: String,
			default: 'quote'
		}
	},
	mounted() {
		this.allCategoriesValid = this.checkAllCategories()

		this.$emit('validationChanged', this.allCategoriesValid)
	},
	data(){
		return {
			productPrices: this.initialPrices,
			pricesLoading: false,
			stats: this.initialStats,
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			dataStore: useGeneralDataStore(),
			pricesLoadingCancelToken: null,
			priceCalculationTimeout: null,
			wrapperKey: 0,
			allCategoriesValid: false,
			projectTypeMainAttributeValue: null,
			selectedCategories: [],
			productError: false,
			optionalRadioValues: {
				0: {
					name: 'Opcionális',
					icon: 'fas fa-question',
					color: 'warning'
				},
				1: {
					name: 'Fix',
					icon: 'fas fa-check',
					color: 'success'
				}
			}
		}
	},
	watch: {
		modelValue: {
			handler(newValue, oldValue) {
				if (this.allCategoriesValid !== this.checkAllCategories()){
					this.allCategoriesValid = this.checkAllCategories()

					this.$emit('validationChanged', this.allCategoriesValid)
				}
			},
			deep: true
		},
	},
	computed: {
		computedProductCategories: {
			get() {
				if (this.mode === 'contractAmendment' || this.mode === 'sale'){
					return [{
						id: null,
						name: 'Termékek'
					}]
				}
				if (this.mode === 'sale'){
					return this.dataStore.getData('productCategories')
				}

				return this.projectDataStore.projectType.product_categories
			}
		},
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		displayStock: {
			get() {
				return this.mode === 'sale' && ['draft', 'quote'].indexOf(this.parentModel.status) > -1
			},
		},
	},
	methods: {
		validateProductCategory: function(productCategory){
			if (!this.displayCategory(productCategory)){
				return true
			}
			if (this.mode === 'contractAmendment' || this.mode === 'foreignContract' || this.mode === 'sale'|| productCategory.pivot.required){
				let stats = this.getProductStatForCategory(productCategory.id)
				if (!stats.count){
					return false
				}
				if (this.mode === 'foreignContract' || this.mode === 'sale'){
					return true
				}
				if (productCategory.id !== null) {
					if (productCategory.pivot.min_product_type && productCategory.pivot.min_product_type > stats.count) {
						return false
					}
					if (productCategory.pivot.min_product_quantity && productCategory.pivot.min_product_quantity > stats.quantity) {
						return false
					}
				}
			}

			return true
		},
		displayCategory: function(category){
			/*if (this.mode === 'sale'){
				if (this.selectedCategories.indexOf(category.id) > -1){
					return true
				}

				let found = false
				_.forEach(this.componentValue, (productRow) => {
					if (this.dataStore.getData('products', true)[productRow.product_id] !== undefined
						&& this.dataStore.getData('products', true)[productRow.product_id].product_category_id === category.id) {
						found = true
					}
				})
				return found
			}*/
			if (this.mode === 'foreignContract' || this.mode === 'contractAmendment' || this.mode === 'sale'){
				return true
			}
			if (this.mode === 'quote' || (this.mode === 'contract' && this.parentModel.quote_id)){
				return category.pivot.with_quote === 1
			}

			return category.pivot.without_quote === 1
		},
		checkAllCategories: function(){
			if (this.mode === 'foreignContract' || this.mode === 'sale'){
				return true
			}
			for (let i = 0; i < this.computedProductCategories.length; i++) {
				if (!this.validateProductCategory(this.computedProductCategories[i])) {
					return false
				}
			}
			return true
		},
		filteredProducts: function(categoryId, productId){
			let productIds = [];
			_.forEach(this.componentValue, function(row){
				if (row.product_id && row.product_id !== productId){
					productIds.push(row.product_id)
				}
			})
			let payload = _.filter(this.dataStore.getData('products'), (product) => {
				if (product === undefined || product.deleted_at){
					return false
				}
				if (this.mode === 'sale'){
					return true
				}
				if (categoryId === null){
					return product.type !== 'bundle'
				}
				return product.product_category_id === categoryId
			})

			return payload
		},
		categoryHasProduct: function(categoryId){
			let result = false
			_.forEach(this.componentValue, (row) => {
				if (
					this.mode === 'contractAmendment' || this.mode === 'sale' ||
					(this.dataStore.getData('products', true)[row.product_id] !== undefined && this.dataStore.getData('products', true)[row.product_id].product_category_id === categoryId) ||
					(row.product_category_id !== undefined && row.product_category_id === categoryId)){
					result = true
				}
			})

			return result
		},
		productChanged: function(index){
			if (index !== undefined && index !== null && index > -1) {
				const productId = this.componentValue[index].product_id
				this.componentValue[index].version = this.dataStore.getData('products', true)[productId].version

				if (this.dataStore.getData('products', true)[productId].type === 'bundle') {
					this.componentValue[index].bundle_products = []
					this.dataStore.getProductsDetailsWithCallback(productId, () => {
						_.forEach(this.dataStore.getProductDetails(productId).bundle_products, (tmp) => {
							if (tmp.pivot.selectable
								&& (this.componentValue[index].bundle_products[tmp.pivot.order] === undefined
									|| !this.componentValue[index].bundle_products[tmp.pivot.order])
								&& (!tmp.pivot.optional || (tmp.pivot.optional && tmp.pivot.select_first_by_default))) {
								this.componentValue[index].bundle_products[tmp.pivot.order] = tmp.id
							}
						})
					})
				}
			}

			this.calculatePricesDebounce()
		},
		calculatePricesDebounce: function(){
			this.pricesLoading = true

			if (this.priceCalculationTimeout !== null){
				clearTimeout(this.priceCalculationTimeout)
				this.priceCalculationTimeout = null
			}

			this.priceCalculationTimeout = setTimeout(this.calculatePrices, 300)
		},
		calculatePrices: function(){
			let url = this.dataUrl
			if (this.parentModel.id){
				url += '/' + this.parentModel.id
			}

			if (this.pricesLoadingCancelToken !== null) {
				this.pricesLoadingCancelToken.cancel("Művelet megszakítva új kérés miatt.")
			}

			this.pricesLoadingCancelToken = axios.CancelToken.source()

			let filteredProducts = this.getFilteredProducts()
			if (!filteredProducts.length){
				return
			}
			let payload = {}
			if (this.parentModel.status === 'draft'){
				payload = {
					products: filteredProducts,
				}
				let key = this.mode === 'quote' ? 'quote' : 'contract'
				if (this.mode === 'sale'){
					key = 'sale'
				}
				payload[key] = {
					discount: this.parentModel.discount || 0,
					discount_material: this.parentModel.discount_material || 0,
					discount_work: this.parentModel.discount_work || 0,
					discount_service: this.parentModel.discount_service || 0,
					discount_relayed_service: this.parentModel.discount_relayed_service || 0,
					use_project_type_pricing: this.parentModel.use_project_type_pricing || 0,
					is_foreign: this.parentModel.is_foreign !== undefined ? this.parentModel.is_foreign : 0,
					optionals_included: _.filter(_.map(this.parentModel.optionals_included, function(i, k) { return i !== null && i > 0 ? k : null }), function(i, k) { return i !== null && i > 0 })
				}
				if (this.mode === 'sale'){
					payload[key].warehouse_id = this.parentModel.warehouse_id
				}
				if (this.mode === 'contractAmendment'){
					payload.contract.amendment_to_contract_id = this.parentModel.amendment_to_contract_id
				}
			}

			axios.post(url, payload,
				{
					cancelToken: this.pricesLoadingCancelToken.token
				}).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				if (response.data.prices !== undefined) {
					this.pricesLoading = false
					this.productPrices = response.data.prices
					this.stats = response.data.stats
				}
				if (response.data.projectTypeMainAttributeValue !== undefined) {
					this.projectTypeMainAttributeValue = response.data.projectTypeMainAttributeValue

					this.$emit('projectTypeMainAttributeValueChanged', this.projectTypeMainAttributeValue)
				}
				if (response.data.status === 'error'){
					this.flashStore.addFlash(response.data.flash);
				}
			}).catch( (error) => {
				this.flashStore.addError(error)
			})
		},
		getBundleItemSelectableProducts: function(productIds){
			let payload = []
			for (let i = 0; i < productIds.length; i++){
				for (let j = 0; j < this.dataStore.getData('products').length; j++){
					if (this.dataStore.getData('products')[j].id === productIds[i]){
						payload.push(this.dataStore.getData('products')[j])
					}
				}
			}

			return payload
		},
		getCategoryTotalPrice: function(categoryId, gross) {
			let total = 0
			_.forEach(this.componentValue, (productRow) => {
				if (this.dataStore.getData('products', true)[productRow.product_id] !== undefined
					&& (categoryId === null || this.dataStore.getData('products', true)[productRow.product_id].product_category_id === categoryId)) {
					if (!this.dataStore.getData('products', true)[productRow.product_id].is_optional || this.mode === 'contractAmendment' || this.mode === 'sale'
						|| (this.parentModel.status === 'accepted' && this.parentModel.optionals_selected[productRow.product_id] === undefined)) {

						total += this.getProductTotalPrice(productRow, gross, true)
					}
				}
			})

			return total
		},
		getTotalPrice: function(gross, discount, type) {
			type = type || 'all'
			let total = 0

			_.forEach(this.productPrices, function(productPrice, productPriceKey){
				if (productPrice.bundle || (type !== 'all' && productPrice.type !== type) || (productPrice.optional && !productPrice.optionalSelected)){
					return
				}
				if (discount && productPrice.discountGross){
					total += (gross ? productPrice.discountGross : productPrice.discountNet) * productPrice.quantity
				} else {
					total += (gross ? productPrice.gross : productPrice.net) * productPrice.quantity
				}
			})

			return total
		},
		calculateTotalPrice: function(type){
			let base, subtract

			if (type === 'net'){
				base = this.getTotalPrice(false, false)
				subtract = this.getTotalPrice(false, true)
			}
			if (type === 'gross'){
				base = this.getTotalPrice(true, false)
				subtract = this.getTotalPrice(true, true)
			}
			if (type === 'vat'){
				base = this.calculateTotalPrice('gross')
				subtract = this.calculateTotalPrice('net')
			}
			if (base > 0){
				return base - subtract
			}

			return -1 * (Math.abs(base) - Math.abs(subtract))
		},
		getBundleItemQuantity(productRow, order){
			let key = productRow.index + '_' + order
			if (this.productPrices !== undefined && this.productPrices[key] !== undefined){
				return this.productPrices[key].quantity
			}
			return 0
		},
		getBundleItemIsDisplayable(productRow, order){
			let key = productRow.index + '_' + order
			if (this.productPrices !== undefined && this.productPrices[key] !== undefined){
				if (this.productPrices[key].quantity < 1){
					return false
				}
				if (!this.isEditable && (this.productPrices[key].hidden && !this.productPrices[key].optional)){
					return false
				}
				if (this.isEditable && (this.productPrices[key].hidden && !this.productPrices[key].optional)){
					return false
				}
				return true
			}
			return false
		},
		getBundleItemPower(productRow, order){
			let product = this.getBundleItemProduct(productRow, order)
			if (product === null || ((product.power * this.getBundleItemQuantity(productRow, order)) < 1 ) || !product.power_unit){
				return ''
			}

			return (product.power * this.getBundleItemQuantity(productRow, order)) + ' ' + product.power_unit
		},
		getBundleItemUnit(productId){
			if (!productId || !this.dataStore.getData('products', true)[productId]){
				return 'db'
			}
			const product = this.dataStore.getProductDetails(productId)

			return product.type === 'bundle'
			&& product.bundle_use_custom_unit
				? product.bundle_unit
				: product.unit
		},
		addProduct: function(categoryId) {
			this.componentValue.push({
				index: this.getNewProductIndex(),
				product_id: null,
				quantity: 1,
				product_category_id: categoryId,
				bundle_products: {}
			})
		},
		modifyProductQuantity: function (productId, quantityModifier, categoryId){
			categoryId = categoryId || null

			for (let i = 0; i < this.componentValue.length; i++){
				if (this.componentValue[i].product_id === productId){
					this.componentValue[i].quantity += quantityModifier

					if (this.getProductMinQuantity({product_id: productId}) !== null) {
						this.componentValue[i].quantity = Math.max(this.componentValue[i].quantity, this.getProductMinQuantity({product_id: productId}))
					}
					if (this.getProductMaxQuantity({product_id: productId}) !== null) {
						this.componentValue[i].quantity = Math.min(this.componentValue[i].quantity, this.getProductMaxQuantity({product_id: productId}))
					}

					this.productChanged(i)
					return true
				}
			}

			this.componentValue.push({
				index: this.getNewProductIndex(),
				product_id: productId,
				quantity: quantityModifier,
				product_category_id: categoryId,
				bundle_products: {}
			})

			this.productChanged(this.componentValue.length - 1)
		},
		getNewProductIndex: function (){
			let index = 1
			for (let i in this.componentValue){
				if (this.componentValue[i].index >= index){
					index = this.componentValue[i].index + 1
				}
			}

			return index
		},
		removeProduct: function(productIndex){
			for (let i in this.componentValue){
				if (this.componentValue[i].index === productIndex){
					this.componentValue.splice(i, 1)
				}
			}

			this.calculatePricesDebounce()
		},
		getFilteredProducts: function(){
			let products = _.clone(this.componentValue)
			for (let i in products){
				products[i].quantity = parseInt(products[i].quantity)
				if (products[i] !== undefined && (products[i].product_id === undefined || !products[i].product_id)){
					delete products[i]
				}
				if (products[i] !== undefined && products[i].id !== undefined) {
					delete products[i].price_gross
					delete products[i].price_net
					delete products[i].quote_id
					delete products[i].contract_id
					delete products[i].id
					delete products[i].updated_at
					delete products[i].created_at
					delete products[i].product_category_id
				}
			}

			return _.compact(_.values(products))
		},
		getBundleProducts: function(productId, version){
			let tmp = {}
			const product = this.dataStore.getProductDetails(productId)
			let bundleProducts = product.bundle_products
			if (version !== undefined && version !== null && this.productVersions[productId + '_' + version] !== undefined){
				bundleProducts = this.productVersions[productId + '_' + version]
			}

			for (let i in bundleProducts){
				if (bundleProducts[i].pivot.selectable){
					if (tmp[bundleProducts[i].pivot.order] === undefined){
						tmp[bundleProducts[i].pivot.order] = {
							productIds: [bundleProducts[i].id],
							pivot: bundleProducts[i].pivot,
							optional: bundleProducts[i].is_optional,
							optionalStatus: 'optional'
						}
					} else {
						tmp[bundleProducts[i].pivot.order].productIds.push(bundleProducts[i].id)
					}
				} else {
					tmp[bundleProducts[i].pivot.order] = {
						productId: bundleProducts[i].id,
						pivot: bundleProducts[i].pivot,
						optional: bundleProducts[i].is_optional,
						optionalStatus: 'optional'
					}
				}
			}

			return tmp
		},
		getUnitPrice: function(productIndex, gross, discount, order, original){
			discount = discount || false
			original = original || false
			let key = productIndex
			if (order !== undefined && order !== null){
				key += '_' + order
			}

			if (this.productPrices === undefined || this.productPrices[key] === undefined) {
				return 0
			}
			if (original){
				if (this.productPrices[key].originalNet === undefined){
					return 0
				}
				return gross ? this.productPrices[key].originalGross : this.productPrices[key].originalNet
			}
			if (discount && this.productPrices[key].discountGross > 0){
				return gross ? this.productPrices[key].discountGross : this.productPrices[key].discountNet
			}
			return gross ? this.productPrices[key].gross : this.productPrices[key].net
		},
		getStock: function(productIndex, order){
			let key = productIndex
			if (order !== undefined && order !== null){
				key += '_' + order
			}

			if (this.productPrices === undefined || this.productPrices[key] === undefined) {
				return 0
			}
			return this.productPrices[key].inStock
		},
		getProductTotalPrice: function(productRow, gross, discount, original){
			if (!productRow.product_id){
				return 0
			}
			return this.getUnitPrice(productRow.index, gross, discount, null, original) * productRow.quantity
		},
		getProductStatForCategory: function (categoryId){
			let payload = {
				count: 0,
				quantity: 0
			}
			_.forEach(this.componentValue, (row) => {
				if (row.product_id && (categoryId === null || this.dataStore.getData('products', true)[row.product_id].product_category_id === categoryId)){
					payload.count++

					payload.quantity += Math.ceil(row.quantity)
				}
			})

			return payload
		},
		getBundleItemProduct(productRow, order){
			const product = this.dataStore.getProductDetails(productRow.product_id)
			let bundleProducts = product.bundle_products

			if (productRow.version !== undefined && productRow.version !== null && this.productVersions[productRow.product_id + '_' + productRow.version] !== undefined){
				bundleProducts = this.productVersions[productRow.product_id + '_' + productRow.version]
			}

			for (let i in bundleProducts){
				if (bundleProducts[i].pivot.order === order){
					if (!bundleProducts[i].pivot.selectable
						|| (productRow.bundle_products[order] !== undefined && productRow.bundle_products[order] === bundleProducts[i].id)) {
						return bundleProducts[i]
					}
				}
			}

			return null
		},
		isCategoryRequired: function(productCategory){
			if (this.mode === 'foreignContract' || this.mode === 'sale'){
				return false
			}
			if (this.mode === 'contractAmendment' || this.mode === 'sale'){
				return true
			}
			if (this.mode === 'contract' && this.parentModel.quote_id){
				return false
			}
			return productCategory.pivot.required
		},
		getBundleProductId: function(product, bundleProduct){
			if (product.bundle_products[bundleProduct.pivot.order] === undefined){
				if (!bundleProduct.pivot.optional && !bundleProduct.pivot.selectable) {
					this.productError = true;
				}

				return 0
			}
			return product.bundle_products[bundleProduct.pivot.order]
		},
		getInputName: function(productCategory, productIndex, name){
			return name + '_' + productCategory.id + '_' + productIndex
		},
		isOptionalSelected: function(bundleProduct){
			if (bundleProduct.productId !== undefined){
				return this.parentModel.optionals_selected[bundleProduct.productId] !== undefined
			}
			if (bundleProduct.productIds !== undefined){
				for (let i = 0; i < bundleProduct.productIds.length; i++){
					if (this.parentModel.optionals_selected[bundleProduct.productIds[i]] !== undefined){
						return true
					}
				}
			}

			return false
		},
		productDisplayableInCategory: function(product, productCategory){
			if (this.mode === 'contractAmendment' || this.mode === 'sale'){
				return true
			}
			if (product.product_id !== undefined && product.product_id && this.dataStore.getProductDetails(product.product_id) !== false){
				return this.dataStore.getProductDetails(product.product_id).product_category_id === productCategory.id
			}

			return product.product_category_id !== undefined && product.product_category_id === productCategory.id
		},
		getProductMinQuantity: function(product){
			if (product.product_id === undefined || !product.product_id){
				return 1
			}
			if (this.limitedProductQuantities[product.product_id] !== undefined && this.limitedProductQuantities[product.product_id].min !== undefined){
				return this.limitedProductQuantities[product.product_id].min
			}

			return 1
		},
		getProductMaxQuantity: function(product){
			if (product.product_id === undefined || !product.product_id || this.dataStore.getProductDetails(product.product_id) === false){
				return 0
			}
			return this.dataStore.getProductDetails(product.product_id).max_quantity
		},
	}
}
</script>

<style scoped>

</style>
