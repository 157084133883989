<template>
	<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
		<div v-if="canCreateShipment(dataStore)" class="dropdown">
			<button type="button" class="btn btn-primary btn-label text-nowrap" data-bs-toggle="dropdown" id="createDropdown" aria-expanded="false"><i class="far fa-plus label-icon"></i> Hozzáadás <i class="far fa-angle-down"></i></button>
			<ul class="dropdown-menu" aria-labelledby="createDropdown">
				<template v-for="(typeDetails, type) in dataStore.getData('inventoryShipmentTypes')">
					<li v-if="typeDetails.manual && app.hasPermission('InventoryController_create' + type[0].toUpperCase() + type.slice(1))">
						<Link :class="'dropdown-item'" :href="'/inventory/create/' + type">
							<i :class="typeDetails.icon + ' fa-fw me-1 text-' + typeDetails.color"></i> {{ typeDetails.name }}
						</Link>
					</li>
				</template>
			</ul>
		</div>
	</data-loader>
</template>

<script>
import {useApplicationStore} from "../../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import DataLoader from "../../components/DataLoader.vue";

export default {
	components: {DataLoader, Link},
	data(){
		return {
			app: useApplicationStore()
		}
	},
	methods: {
		canCreateShipment: function (dataStore){
			let result = false
			_.forEach(dataStore.getData('inventoryShipmentTypes'), (typeDetails, type) => {
				if (this.app.hasPermission('InventoryController_create' + type[0].toUpperCase() + type.slice(1))){
					result = true
				}
			})

			return result
		}
	}
}
</script>

<style scoped>

</style>
