<template>
	<TitleBar title="Hírlevelek" />

	<AdvancedDataTable
		:data-url="route('newsletters.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		show-tracked-edit-mode
		name="newsletters"
	>
		<template #navigation v-if="app.hasPermission('PartnersController_create')">
			<Link :href="route('newsletters.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>

		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<div>{{ item.subject }}</div>
						<attribute icon="far fa-clock">
							<date-time :value="item.created_at"/>
						</attribute>

						<color-badge :color="item.sent_to ? 'success' : 'light'" >
							<number :value="item.sent_to" />
						</color-badge>
						<color-badge :color="item.sent_at ? 'success' : 'danger'" >
							<template v-if="item.sent_at">
								Küldve: <DateTime :value="item.sent_at"/>
							</template>
							<template v-else>
								Nincs küldve
							</template>
						</color-badge>
						<color-badge v-if="!item.sent_at && item.sent_by_user_id"
									 icon="far fa-hourglass"
									 color="warning"
						>Küldés elindítva</color-badge>
					</div>

				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('newsletters.update')"
					:delete-url="route('newsletters.delete')"
					:emit-delete-event="true"
					:tracked-edit-mode="trackedEditMode"
					@delete-button-pressed="deleteButtonPressed"
				>
					<Link :href="route('newsletters.send') + '/' + item.id" v-if="!item.sent_at && !item.sent_by_user_id && app.hasPermission('NewslettersController_send')" class="btn btn-success btn-sm" title="Küldés"><i class="far fa-paper-plane"></i></Link>
				</TableActionCell>
			</div>
		</template>
		<template #item-sent_at="item">
			<color-badge :color="item.sent_at ? 'success' : 'danger'" >
				<template v-if="item.sent_at">
					<DateTime :value="item.sent_at"/>
				</template>
				<template v-else>
					Nincs küldve
				</template>
			</color-badge>
			<color-badge v-if="!item.sent_at && item.sent_by_user_id"
				 icon="far fa-hourglass"
				 color="warning"
			>Küldés elindítva</color-badge>

		</template>
		<template #item-created_at="item">
			<DateTime :value="item.created_at"/>
		</template>
		<template #item-sent_to="item">
			<color-badge :color="item.sent_to ? 'success' : 'light'" >
				<number :value="item.sent_to" />
			</color-badge>
		</template>
		<template #item-sent_by_user_id="item">
			<user-badge :user-id="item.sent_by_user_id" />
		</template>
	</AdvancedDataTable>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import TableActionCell from "../components/TableActionCell.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DateTime from "../components/DateTime.vue";
import {Link} from "@inertiajs/vue3";
import {useApplicationStore} from "../stores/applicationStore";
import AppLayout from "../layouts/AppLayout.vue";
import DataLoader from "../components/DataLoader.vue";
import Attribute from "../components/Attribute.vue";
import ColorBadge from "../components/ColorBadge.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import Number from "../components/Number.vue";

export default {
	name: "NewsletterList",
	layout: AppLayout,
	components: {
		Number,
		UserBadge,
		ColorBadge, Attribute, DataLoader, DateTime, AdvancedDataTable, TableActionCell, TitleBar, Link},
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Tárgy',
				value: 'subject',
				sortable: true
			},
			{
				text: 'Elküldve',
				value: 'sent_at',
				sortable: true
			},
			{
				text: 'Küldött menny.',
				value: 'sent_to',
				sortable: true
			},
			{
				text: 'Elküldte',
				value: 'sent_by_user_id',
				sortable: true
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
