<script setup>
import ColorBadge from "../ColorBadge.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import DataLoader from "../DataLoader.vue";
import {ref} from "vue";
import {Link} from "@inertiajs/vue3";
import {useApplicationStore} from "../../stores/applicationStore";
import AddressAttribute from "../attributes/AddressAttribute.vue";
import BooleanBadge from "./BooleanBadge.vue";

const props = defineProps({
	warehouseId: Number,
	disableLink: Boolean,
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
})
const app = useApplicationStore()
const open = ref(false)
const dataStore = useGeneralDataStore()
</script>

<template>
	<data-loader :data="['warehouses']" v-slot="{ dataStore }" v-if="warehouseId">
		<template v-if="dataStore.getData('warehouses', true)[warehouseId] !== undefined">
			<template v-if="!disableLink">
				<VDropdown
					@show="open = true"
					@hide="open = false">
					<a :class="{'colored-badge': 1,
						'bg-light': !dark,
						'bg-secondary': dark,
						'colored-badge-sm': small,
					 	'text-dark': !dark && dataStore.getData('warehouses', true)[warehouseId].active,
					  	'text-white': dark && dataStore.getData('warehouses', true)[warehouseId].active,
					  	'text-danger': !dataStore.getData('warehouses', true)[warehouseId].active
					}" href="#">
						<i class="far fa-warehouse-alt"></i> {{ dataStore.getData('warehouses', true)[warehouseId].name }} <i class="far fa-info-circle font-size-10"></i>
					</a>
					<template #popper>
						<div :class="{'overview-tooltip': 1}">
							<div class="overview-tooltip-head">
								<div class="d-flex">
									<div class="flex-grow-1">
										<color-badge
											color="secondary"
											icon="far fa-warehouse-alt"
											small
											content="Raktár"
										/>
										<h5 class="font-size-15 mt-1">{{ dataStore.getData('warehouses', true)[warehouseId].name }}</h5>
									</div>
									<div class="d-flex flex-column gap-2">
										<Link :href="'/warehouses/edit/' + warehouseId" class="btn btn-soft-secondary btn-sm" title="Adatlap" v-if="app.hasPermission('WarehousesController_view')"><i class="far fa-external-link"></i></Link>
										<Link :href="'/inventory/warehouse/' + warehouseId" class="btn btn-soft-secondary btn-sm" title="Raktárkészlet" v-if="app.hasPermission('InventoryController_view')"><i class="far fa-inventory"></i></Link>
									</div>
								</div>
							</div>
							<div class="overview-tooltip-body">
								<div class="details-box">
									<boolean-badge
										v-model="dataStore.getData('warehouses', true)[warehouseId].active"
										true-label="Aktív"
										false-label="Nem aktív"
										/>
									<address-attribute
										:address="{formatted: dataStore.getData('warehouses', true)[warehouseId].address}"
										:lat="dataStore.getData('warehouses', true)[warehouseId].lat"
										:lng="dataStore.getData('warehouses', true)[warehouseId].lng"
										v-if="dataStore.getData('warehouses', true)[warehouseId].lat"
									/>

									<color-badge
										v-if="dataStore.getData('warehouses', true)[warehouseId].activeInspection"
										color="warning"
										icon="far fa-search"
										small
										>
										Leltár alatt
									</color-badge>
								</div>
							</div>
						</div>
					</template>
				</VDropdown>
			</template>
			<color-badge
				v-else
				color="light"
				icon="far fa-warehouse-alt"
				:text-color="!dataStore.getData('warehouses', true)[warehouseId].active ? 'danger' : null"
				:small="small"
				:content="dataStore.getData('warehouses', true)[warehouseId].name"
			/>
		</template>
		<color-badge
			v-else
			color="light"
			icon="far fa-warehouse-alt"
			text-color="danger"
			:small="small"
			content="Törölt raktár"
		/>
	</data-loader>
</template>
