<template>
	<div :class="{
		'mb-3': marginBottom,
		'mb-0': !marginBottom,
		'mt-3': marginTop,
		'mt-0': !marginTop,
		'ms-auto': marginLeft,
		'd-flex': 1,
	}" v-if="lock.locked">
		<div :class="{
			'alert': 1,
			'alert-danger': !lock.lockedByCurrentUser,
			'alert-success': lock.lockedByCurrentUser,
			'mb-0': 1,
			'd-flex': 1,
			'align-items-center': 1,
			'flex-wrap': 1,
			'lock-message': 1,
		}">
			<i :class="{
				'fas': lock.lockedByCurrentUser,
				'fal': !lock.lockedByCurrentUser,
				'fa-lock': 1,
				'font-size-20': 1
			}"></i> <span class="me-2">Éppen szerkeszti:</span> <strong class="me-2">{{ lock.lockedByClientName ? 'Ügyfél: ' + lock.lockedByClientName : lock.lockedByUserName }}</strong> <span class="me-2">(<date-time :value="lock.lockedAt"/>)</span>
		</div>
	</div>
</template>

<script>
import DateTime from "./DateTime.vue";

export default {
	components: {DateTime},
	props: {
		lock: Object,
		marginLeft: Boolean,
		marginTop: Boolean,
		marginBottom: {
			type: Boolean,
			default: true
		}
	}
}
</script>

<style scoped>
	.alert {
		position: relative;
		padding-left: 50px;
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.alert i {
		position: absolute;
		left: 25px;
		top: 50%;
		transform: translate(-50%, -50%);
	}
</style>
