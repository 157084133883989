<template>
	<div style="height: 400px;overflow-y:scroll;" id="postList">
		<div class="card shadow-none border" v-for="post in posts">
			<div class="card-body p-3">
				<h4 class="card-title mb-2">
					<a :href="viewUrl + '/' + post.id">{{ post.title}}</a>
				</h4>

				<div class="d-flex mb-3 gap-2">
					<div><i class="far fa-clock"></i> <date-time :value="post.created_at"/></div>
					<div><i class="far fa-user"></i> {{ post.created_by_user_name }}</div>
				</div>
				<div v-if="post.lead" v-html="post.lead_html"></div>
			</div>
		</div>
		<div class="alert alert-primary" v-if="!posts.length">Nincs hír</div>
	</div>
	<div class="pt-3 border-top d-grid">
		<a class="btn btn-sm btn-link font-size-14 text-center" href="/posts">
			<i class="far fa-angle-right me-1"></i> <span key="t-view-more">Összes megtekintése</span>
		</a>
	</div>
</template>

<script>
import RelativeTime from "../../components/RelativeTime.vue";
import DateTime from "../../components/DateTime.vue";

export default {
	components: {DateTime, RelativeTime},
	props: {
		listUrl: String,
		viewUrl: String,
	},
	data(){
		return {
			posts: [],
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		getData: function(){
			axios.get(this.listUrl).then((response) => {
				this.posts = response.data.data
			}).catch(function (error) {
			})
		},
	}
}
</script>

<style scoped>

</style>
