<script setup>
import Attribute from "../Attribute.vue";
import {computed} from "@vue/compat";
import ColorBadge from "../ColorBadge.vue";
import Popper from "vue3-popper";
import {LMap, LMarker, LTileLayer} from "@vue-leaflet/vue-leaflet";
import {ref} from "vue";

const props = defineProps({
	address: String|Object,
	lat: Number,
	lng: Number,
	hideLink: Boolean,
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
})
const open = ref(false)
let mapObject = null
const labelComputed = computed(() => {
	return props.label
})
const addressComputed = computed(() => {
	if (props.address instanceof Object && props.address.formatted !== undefined){
		return props.address.formatted
	}

	return props.address
})

const latComputed = computed(() => {
	if (props.address instanceof Object && props.address.lat !== undefined){
		return props.address.lat
	}

	return props.lat
})

const lngComputed = computed(() => {
	if (props.address instanceof Object && props.address.lng !== undefined){
		return props.address.lng
	}

	return props.lng
})
</script>

<template>
	<template v-if="address && address.formatted">
		<template v-if="!hideLink && latComputed">
			<VDropdown
				@show="open = true"
				@hide="open = false">
				<a :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" href="#">
					<i class="far fa-map-marker-alt"></i> <span class="colored-badge-content-wrap">{{ addressComputed }}</span> <i class="far fa-info-circle font-size-10"></i>
				</a>
				<template #popper>
					<div class="map-tooltip" v-if="open">
						<div class="overview-tooltip-head">
							<h5 class="font-size-15 mt-1"><i class="far fa-map-marker-alt"></i> {{ addressComputed }}</h5>
							<div class="d-md-none mt-2 d-flex gap-2 w-100">
								<a :href="'geo:' + latComputed + ',' + lngComputed + '?q=' + addressComputed" class="btn btn-secondary btn-label flex-grow-1">
									<i class="far fa-map label-icon"></i> Navigáció
								</a>
							</div>
						</div>
						<div class="map-tooltip-map">
							<l-map ref="map"
								   :zoom="12"
								   :center="[latComputed, lngComputed]"
							>
								<l-marker
									:lat-lng="[latComputed, lngComputed]"
									:title="labelComputed"
								>
								</l-marker>
								<l-tile-layer
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									layer-type="base"
									name="OpenStreetMap"
								></l-tile-layer>
							</l-map>
						</div>
					</div>
				</template>
			</VDropdown>
		</template>
		<color-badge
			v-else
			color="light"
			icon="far fa-map-marker-alt"
			:small="small"
		>
			<span class="colored-badge-content-wrap">{{ addressComputed }}</span>
		</color-badge>
	</template>
</template>

<style scoped>

</style>
