<template>
	<template v-if="formattedValue.length">
		<a :href="'tel:+' + internationalValue" v-if="link" :class="class"><i :class="icon"></i> {{ content.length ? content : formattedValue }}</a>
		<div :class="class" v-else><i :class="icon"></i> {{ content.length ? content : formattedValue }}</div>
	</template>
</template>

<script>
export default {
	props: {
		value: String,
		class: {
			type: String,
			default: 'badge bg-light badge-xl'
		},
		icon: {
			type: String,
			default: 'far fa-phone'
		},
		content: {
			type: String,
			default: ''
		},
		link: {
			type: Boolean,
			default: true
		},
	},
	data(){
		let value = this.value !== null ? this.value : ''
		value = value.replaceAll(' ', '')
		value = value.replaceAll('/', '')
		value = value.replaceAll('-', '')
		value = value.replaceAll('+', '')

		return {
			internationalValue: value
		}
	},
	computed: {
		formattedValue: {
			get(){
				if (!this.internationalValue.length){
					return ''
				}
				if (this.internationalValue.length === 11){
					return '+' + this.internationalValue.substring(0, 2) + ' ' + this.internationalValue.substring(2, 4) + ' ' + this.internationalValue.substring(4, 7) + ' ' + this.internationalValue.substring(7, 11)
				}
				if (this.internationalValue.substring(2, 3) === "1"){
					return '+' + this.internationalValue.substring(0, 2) + ' ' + this.internationalValue.substring(2, 3) + ' ' + this.internationalValue.substring(3, 6) + ' ' + this.internationalValue.substring(6, 10)
				}
				if (this.internationalValue.length === 10) {
					return '+' + this.internationalValue.substring(0, 2) + ' ' + this.internationalValue.substring(2, 4) + ' ' + this.internationalValue.substring(4, 7) + ' ' + this.internationalValue.substring(7, 10)
				}

				return '+' + this.internationalValue
			}
		},
	}
}
</script>

<style scoped>

</style>
