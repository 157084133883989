<template>
	{{prefix}}{{ numberValue.toLocaleString('hu-HU') }}{{ unit !== '' ? ' ' + unit : ''}}{{suffix}}
</template>

<script>
export default {
	props: {
		value: [Number, String],
		prefix: {
			type: String,
			default: ''
		},
		suffix: {
			type: String,
			default: ''
		},
		unit: {
			type: String,
			default: ''
		},
		round: Boolean
	},
	computed: {
		numberValue: {
			get() {
				let value = parseFloat(this.value)
				if (this.round){
					value = Math.round(value)
				}
				return value
			}
		}
	}
}
</script>
