<script setup>

import Number from "../Number.vue";
import Attribute from "../Attribute.vue";
import WarehouseBadge from "../badges/WarehouseBadge.vue";
import SaleBadge from "../badges/SaleBadge.vue";

const model = defineModel()

const props = defineProps({
	publicId: String,
	clientName: String
})
</script>

<template>
	<attribute
		label="Eladás"
		icon="far fa-cash-register"
		v-if="model"
	>
		<sale-badge v-model="model" :public-id="publicId" :client-name="clientName" :key="'SA' + model"/>
	</attribute>
</template>

<style scoped>

</style>
