<template>
	<TitleBar :title="attributes.id !== undefined ? 'Jármű módosítása' : 'Jármű hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('vehicles.store', model.id)"
		:back-url="route('vehicles.index')"
		v-model="attributes"
		:tabs="tabs"
		:tab-badges="tabBadges"
		:display-tabs="attributes.id !== undefined"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
			<template v-if="attributes.total_distance">
				<div class="row">
					<div class="col-sm-3">
						<div class="card">
							<div class="card-body">
								<div class="d-flex">
									<div class="flex-grow-1">
										<p class="text-muted fw-medium">Futott km (<date-time :value="attributes.total_distance_updated_at"/>)</p>
										<h4 class="mb-0"><number :value="attributes.total_distance" suffix=" km"/></h4>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="card">
							<div class="card-body">
								<div class="d-flex">
									<div class="flex-grow-1">
										<p class="text-muted fw-medium">Becsült km</p>
										<h4 class="mb-0"><number :value="attributes.total_distance_calculated" suffix=" km"/></h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</template>

		<template #default="{activeTab, errors, editable}">
			<div v-if="activeTab === 'form'">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Alapadatok</h4>
						<div class="row">
							<div class="col-sm-6">
								<switch-field
									label="Aktív"
									name="is_active"
									v-model="attributes.is_active"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<switch-field
									label="Nehéz tehergépjármű"
									name="is_hgv"
									v-model="attributes.is_hgv"
									:disabled="!attributes.editable"
									:errors="errors"
									info="> 3.5T "
								/>

								<warehouse-select-field
									label="Raktár"
									name="warehouse_id"
									v-model="attributes.warehouse_id"
									:errors="errors"
									:disabled="!attributes.editable"
									:show-normal="false"
									/>

								<user-select-field
									label="Sofőr"
									name="user_id"
									v-model="attributes.user_id"
									:errors="errors"
									:disabled="!attributes.editable"
									/>

								<input-field
									label="Név"
									name="name"
									v-model="attributes.name"
									:disabled="!attributes.editable"
									:errors="errors"
								/>

								<input-field
									label="Gyártó"
									name="make"
									v-model="attributes.make"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<input-field
									label="Modell"
									name="model"
									v-model="attributes.model"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<input-field
									label="Szín"
									name="color"
									v-model="attributes.color"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									label="Rendszám"
									name="licence_plate"
									rules="required"
									v-model="attributes.licence_plate"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<input-field
									label="Alvázszám"
									name="vin_number"
									v-model="attributes.vin_number"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<input-field
									label="Forgalmi száma"
									name="registration_number"
									v-model="attributes.registration_number"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<input-field
									label="Gyártási év"
									name="year_of_production"
									v-model="attributes.year_of_production"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<input-field
									label="Teljesítmény"
									name="rated_power"
									v-model="attributes.rated_power"
									suffix="kW"
									data-type="number"
									:step="1"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<input-field
									label="Öntömeg"
									name="own_weight"
									v-model="attributes.own_weight"
									suffix="kg"
									data-type="number"
									:step="1"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<input-field
									label="Max megengedett tömeg"
									name="max_total_weight"
									v-model="attributes.max_total_weight"
									suffix="kg"
									data-type="number"
									:step="1"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Használat</h4>
						<div class="row">
							<div class="col-sm-6">
								<date-field
									label="Forgalmi lejárati dátum"
									name="registration_valid_to"
									v-model="attributes.registration_valid_to"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<date-field
									label="Utolsó szervíz dátum"
									name="last_serviced_at"
									v-model="attributes.last_serviced_at"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-6">
								<date-field
									label="Következő olajcsere dátuma"
									name="next_oil_change"
									v-model="attributes.next_oil_change"
									:disabled="!attributes.editable"
									:errors="errors"
								/>
								<input-field
									label="Km óra állás rögzítése"
									name="set_total_distance"
									v-model="attributes.set_total_distance"
									:disabled="!attributes.editable"
									:errors="errors"
									suffix="km"
									data-type="number"
									:step="1"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="activeTab === 'comments'">
				<Comments
					:data-url="route('vehicles.comments', attributes.id)"
					:can-create="attributes.editable"
					@comment-count="commentCountUpdated"
				></Comments>
			</div>
			<div v-if="activeTab === 'documents'">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-3">Dokumentumok</h4>
						<FileUploader
							field-name="document"
							v-model="attributes.documents"
							file-type="document"
							:editable="attributes.editable"
						></FileUploader>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'images'">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-3">Képek</h4>
						<FileUploader
							field-name="image"
							v-model="attributes.images"
							file-type="image"
							:editable="attributes.editable"
						></FileUploader>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import SelectField from "../components/form/SelectField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import {objectToArray} from "../functions";
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import DateTime from "../components/DateTime.vue";
import Number from "../components/Number.vue";
import FileUploader from "../components/FileUploader.vue";
import Comments from "../components/Comments.vue";
import DateField from "../components/form/DateField.vue";
import ColorBadge from "../components/ColorBadge.vue";
import SwitchField from "../components/form/SwitchField.vue";
import Attribute from "../components/Attribute.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import UserSelectField from "../components/form/UserSelectField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	layout: AppLayout,
	components: {
		UpdatedAttribute,
		UserSelectField,
		WarehouseSelectField,
		Attribute,
		SwitchField,
		ColorBadge,
		DateField, Comments, FileUploader, Number, DateTime, TitleBar, InputField, FormWrapper, SelectField},
	props: {
		model: Object,
		initialStats: Object,
	},
	data(){
		return {
			attributes: this.model,
			tabs: [
				{
					label: 'Adatlap',
					icon: 'far fa-file-spreadsheet',
					name: 'form'
				},
				{
					label: 'Dokumentumok',
					icon: 'far fa-file',
					name: 'documents'
				},
				{
					label: 'Képek',
					icon: 'far fa-file-image',
					name: 'images'
				},
				{
					label: 'Megjegyzések',
					icon: 'far fa-comments',
					name: 'comments',
				},
			],
			tabBadges: {
				comments: this.initialStats.comments,
				images: this.initialStats.images,
				documents: this.initialStats.documents,
			},
		}
	},
	methods: {
		commentCountUpdated: function(count){
			this.tabBadges.comments = count
		}
	}
}
</script>
