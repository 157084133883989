<script setup>
import ColorBadge from "../ColorBadge.vue";
import DataLoader from "../DataLoader.vue";
const model = defineModel()
const props = defineProps({
	dark: Boolean,
	currencyId: Number,
	small: {
		type: Boolean,
		default: true
	},
})
</script>

<template>
	<data-loader :data="['currencies']" v-slot="{ dataStore }">
		<color-badge
			:color="dark ? 'secondary' : 'light'"
			icon="far fa-coins"
			:small="small"
		>
			{{ dataStore.getData('currencies', true)[model].name }}
		</color-badge>
	</data-loader>
</template>

<style scoped>

</style>
