<template>
	<div id="taskList" class="dashboard-task-list">
		<div class="d-flex flex-column px-2">
			<template v-for="task in app.tasks">
				<task-list-item :task="task"/>
			</template>
		</div>
		<div class="p-2" v-if="!app.tasks.length">
			<div class="alert alert-primary">Nincs feladatod</div>
		</div>
	</div>
</template>

<script>
import RelativeTime from "../../components/RelativeTime.vue";
import { usePage, Link } from '@inertiajs/vue3';
import {useApplicationStore} from "../../stores/applicationStore";
import ColorBadge from "../../components/ColorBadge.vue";
import {useProjectDataStore} from "../../stores/projectData";
import attribute from "../../components/Attribute.vue";
import Attribute from "../../components/Attribute.vue";
import TaskListItem from "../../components/TaskListItem.vue";

export default {
	components: {TaskListItem, Attribute, ColorBadge, RelativeTime, Link},
	computed: {
		tabs: {
			get(){
				return _.keyBy(this.componentProjectDataStore.possibleTabs, 'name')
			}
		},
	},
	data(){
		return {
			app: useApplicationStore(),
			page: usePage(),
			componentProjectDataStore: this.projectDataStore !== undefined ? this.projectDataStore : useProjectDataStore(),
		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
