<template>
	<div :class="{'btn-loader': 1, 'btn-loading': loading}">
		<slot :click="clicked"></slot>
	</div>
	<template v-if="modalActive">
		<confirm-modal
			title="Megerősítés"
			:content="'<p class=\'mb-0\'>' + confirmMessage + '</p>'"
			@yes="emitCallback">
		</confirm-modal>
	</template>
</template>

<script>
import ConfirmModal from "./ConfirmModal.vue";

export default {
	components: {ConfirmModal},
	emits: ['call'],
	props: {
		callback: Function,
		confirmMessage: String
	},
	computed: {
	},
	data() {
		return {
			loading: false,
			modalActive: false
		}
	},
	methods: {
		clicked: function (){
			if (this.confirmMessage && this.confirmMessage.length){
				this.modalActive = true
			} else {
				this.emitCallback()
			}
		},
		clearLoading: function (){
			this.loading = false
		},
		emitCallback: function (){
			this.loading = true
			this.$emit('call', this.clearLoading)
		}
	}
}
</script>

<style scoped>
.btn-loader {
	position: relative;
	overflow: hidden;
	border-radius: 3px;
}
.btn-loading:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255,255,255,.3);
	z-index: 1000;
}
.btn-loading::after {
	left: 50% !important;
	top: 50% !important;
}
</style>