<template>
	<modal
		title="Ügyfél válasz rögzítése"
		:loading="loading"
		:closable="true"
		@close="$emit('close')">

		<template #buttons>
			<button type="submit" class="btn btn-primary btn-label waves-effect" :disabled="!valid" @click.prevent="saveForm">
				<i class="fas fa-save label-icon"></i> Mentés
			</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
				<i class="fas fa-times label-icon"></i> Bezár
			</button>
		</template>

		<template #default>
			<div class="card">
				<div class="card-body">
					<h4 class="card-title mb-3">{{ quote.public_id }} - {{ quote.title_formatted }}</h4>
					<div class="details-box details-box--narrow mb-3">
						<date-time-attribute :value="quote.created_at" created>
							<user-badge :user-id="quote.created_by_user_id" />
						</date-time-attribute>

						<date-time-attribute :value="quote.approved_at" approved>
							<user-badge :user-id="quote.approved_by_user_id" />
						</date-time-attribute>

						<date-time-attribute :value="quote.sent_at" sent>
							<user-badge :user-id="quote.sent_by_user_id" />
						</date-time-attribute>

						<date-time-attribute :value="quote.read_at" client-read />

						<div>
							<i class="far fa-coins"></i> Nettó végösszeg: <strong><Price
								:value="quote.total_price_net"
								:currency-id="quote.currency_id"
							/></strong>
						</div>
						<div>
							<i class="far fa-coins"></i> Bruttó végösszeg: <strong><Price
								:value="quote.total_price_gross"
								:currency-id="quote.currency_id"
							/></strong>
						</div>
					</div>
					<RadioField
						:values="radioFieldValues"
						label="Ügyfél válasza"
						name="answer"
						v-model="answer.status"
						/>

					<div class="mb-3">
						<label>Fizetési konstrukció</label>

						<v-select
							:options="paymentPlans"
							v-model="answer.project_type_payment_plan_id"
							label="name_public"
							:reduce="item => item.id"
							:clearable="false"
						>
						</v-select>
						<p class="text-muted mt-2" v-if="answer.project_type_payment_plan_id && paymentPlansById[answer.project_type_payment_plan_id].description" v-html="paymentPlansById[answer.project_type_payment_plan_id].description_html"></p>
					</div>

					<div v-if="answer.status === 'accepted'">
						<div v-if="optionalProducts.length" class="mb-1">
							<label>Opcionális termékek</label>
							<div class="bg-light p-3 mb-2" v-for="quoteProduct in optionalProducts">
								<h5>{{ quoteProduct.product.name }}</h5>

								<RadioField
									:values="optionalProductRadioFieldValues"
									:name="'pr_' + quoteProduct.id"
									v-model="answer.optionalProducts[quoteProduct.id]"
									value-type="boolean"
									:field-only="true"
								/>
							</div>
						</div>
						<label class="required">Aláírt ajánlat csatolása</label>
						<FileUploader
							field-name="signedPages"
							v-model="answer.signedPages"
							:editable="true"
							:multiple="true"
						></FileUploader>
					</div>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import Price from "../../components/Price.vue";
import RadioField from "../../components/form/RadioField.vue";
import FileUploader from "../../components/FileUploader.vue";
import vSelect from "vue-select";
import UserBadge from "../../components/badges/UserBadge.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import {useProjectDataStore} from "../../stores/projectData";
export default {
	components: {UserBadge, FileUploader, RadioField, Price, vSelect},
	emits: ['close', 'closeAndReload'],
	props: {
		quoteId: [Number, String],
	},
	data() {
		return {
			loading: true,
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			quote: {},
			optionalProducts: [],
			paymentPlans: [],
			answer: {
				status: 'accepted',
				optionalProducts: {},
				project_type_payment_plan_id: false
			},
			radioFieldValues: {
				accepted: {
					label: 'Elfogad',
					icon: 'far fa-check',
					color: 'success'
				},
				rejected: {
					label: 'Elutasít',
					icon: 'far fa-times',
					color: 'danger'
				}
			},
			optionalProductRadioFieldValues: {
				yes: {
					label: 'Igen',
					icon: 'far fa-check',
					color: 'success'
				},
				no: {
					label: 'Nem',
					icon: 'far fa-times',
					color: 'danger'
				}
			}
		}
	},
	computed: {
		valid: {
			get() {
				if (this.answer.status === 'reject'){
					return true
				}
				return this.answer.signedPages !== undefined && this.answer.signedPages.length
			}
		},
		paymentPlansById: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		}
	},
	mounted() {
		this.loadData()
	},
	methods: {
		loadData: function(){
			this.loading = true

			axios.get('/projects/' + this.projectDataStore.projectId + '/quoteAnswerForm/' + this.quoteId).then((response)=>{
				this.handleResponse(response)
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status !== undefined && response.data.status === 'success') {
				this.$emit('closeAndReload')
			}
			if (response.data.quote !== undefined) {
				this.quote = response.data.quote
			}
			if (response.data.paymentPlans !== undefined) {
				this.paymentPlans = _.filter(response.data.paymentPlans, item => !item.contract_without_quote)

				if (!this.answer.project_type_payment_plan_id && this.paymentPlans.length){
					this.answer.project_type_payment_plan_id = _.first(this.paymentPlans).id
				}
			}
			if (response.data.optionalProducts !== undefined) {
				this.optionalProducts = response.data.optionalProducts

				this.answer.optionalProducts = {}
				for (let i in this.optionalProducts){
					this.answer.optionalProducts[this.optionalProducts[i].id] = false
				}
			}
			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}
		},
		saveForm: function(e){
			if (this.valid){
				this.loading = true

				axios.post('/projects/saveQuoteAnswer/' + this.quoteId, this.answer).then((response)=>{
					this.handleResponse(response)
				}).catch((error) => {
					this.loading = false
					this.flashStore.addError(error)
				})
			}
		},
		emitClose: function(){
			this.$emit('close')
		},
	}
}
</script>

<style scoped>

</style>
<script setup>
import Modal from "../../components/Modal.vue";
import LockMessage from "../../components/LockMessage.vue";
import DateTime from "../../components/DateTime.vue";
import UserAttribute from "../../components/attributes/UserAttribute.vue";
import UpdatedAttribute from "../../components/UpdatedAttribute.vue";
import DateTimeAttribute from "../../components/attributes/DateTimeAttribute.vue";
</script>
