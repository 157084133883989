<template>
	<modal
		:title="getModalTitle()"
		:loading="loading"
		:full-width="true"
		:buttons-on-top="true"
		:closable="true"
		:required-data="['products', 'productManufacturers', 'productTypes', 'productAttributes']"
		@close="$emit('close')">

		<template #buttons>
			<div class="d-flex flex-wrap gap-2">
				<button type="submit" class="btn btn-primary btn-label waves-effect" :disabled="!contractValid" v-if="isEditable" @click.prevent="saveForm">
					<i class="fas fa-save label-icon"></i> Mentés
				</button>
				<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
					<i class="fas fa-times label-icon"></i> Bezár
				</button>
			</div>

			<LockMessage :lock="projectDataStore.lock" :margin-bottom="false" :margin-left="true"></LockMessage>
		</template>

		<template #default>
			<div class="row">
				<div class="col-md-3">
					<ProjectInfoSidebar></ProjectInfoSidebar>
				</div>
				<div class="col-md-9">
					<div class="card" v-if="contract.id !== undefined">
						<div class="card-body">
							<div class="details-box mb-3">
								<div>
									<i class="far fa-hashtag"></i> Azonosító:
									<strong>{{ contract.public_id }}</strong>
								</div>
								<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }">
									<div v-if="dataStore.getData('contractStatusLabels')[contract.status] !== undefined">
										<i class="far fa-question"></i> Státusz:
										<color-badge :value="dataStore.getData('contractStatusLabels')[contract.status]"></color-badge>
									</div>
								</data-loader>
								<div v-if="contract.deadline !== undefined && contract.deadline">
									<i class="far fa-calendar-check"></i> Érvényesség:
									<strong><date-time :value="contract.deadline" :time="false"/></strong>
								</div>

								<attribute icon="far fa-truck" label="Szállítás">
									<contract-delivery-badges :contract="contract" :display-installation="false"/>
								</attribute>

								<attribute icon="far fa-tools" label="Telepítés">
									<contract-delivery-badges :contract="contract" :display-delivery="false"/>
								</attribute>

								<div class="break"></div>

								<date-time-attribute :value="contract.created_at" created>
									<user-badge :user-id="contract.created_by_user_id" />
								</date-time-attribute>

								<date-time-attribute :value="contract.updated_at" updated />
							</div>
							<div v-if="contract.files && contract.files.length" class="mb-3">
								<FileList :files="contract.files" title="Aláírt szerződés" />
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title mb-4">Adatok</h4>
							<div class="row">
								<div class="col-md-12">
									<InputField
										label="Megnevezés"
										name="foreign_contract_title"
										rules="required"
										v-model="contract.title"
										:disabled="!isEditable"
										:errors="formErrors"
									/>
								</div>
								<div class="col-md-6">
									<InputField
										label="Nettó végösszeg"
										name="foreign_contract_total_price_net"
										data-type="number"
										:step="0.1"
										v-model="contract.total_price_net"
										:disabled="!isEditable || !!contract.quote_id"
										:errors="formErrors"
									/>
								</div>
								<div class="col-md-6">
									<InputField
										label="Bruttó végösszeg"
										name="foreign_contract_total_price_gross"
										data-type="number"
										:step="0.1"
										rules="required"
										v-model="contract.total_price_gross"
										:disabled="!isEditable || !!contract.quote_id"
										:errors="formErrors"
									/>
								</div>
								<div class="col-md-6">
									<currency-select-field
										label="Pénznem"
										name="foreign_contract_currency_id"
										rules="required"
										v-model="contract.currency_id"
										:disabled="!isEditable || !!contract.quote_id"
										:errors="formErrors"
									/>
								</div>
								<div class="col-md-6">
									<DateField
										label="Aláírás dátuma"
										name="foreign_contract_generated_at"
										rules="required"
										v-model="contract.generated_at"
										:disabled="!isEditable"
										:errors="formErrors"
									/>
								</div>
								<div class="col-md-6">
									<InputField
										label="Azonosító"
										name="fforeign_contract_oreign_id"
										v-model="contract.foreign_id"
										:disabled="!isEditable"
										:errors="formErrors"
									/>
								</div>
								<div class="col-md-6">
									<select-field
										label="Fizetési konstrukció"
										:options="paymentPlans"
										v-model="contract.project_type_payment_plan_id"
										name="foreign_contract_name_public"
										:disabled="!isEditable"
										rules="required"
									>
									</select-field>
								</div>
								<div class="col-md-6">
									<select-field
										label="Árajánlat"
										:options="quotes"
										v-model="contract.quote_id"
										name="foreign_contract_quote_id"
										:disabled="!isEditable"
									>
										<template v-slot:option="option">
											<div class="d-flex gap-2 flex-wrap align-items-center">
												<strong>{{ option.option.public_id }}</strong>
												<price :value="option.option.total_price_gross" :currency-id="option.option.currency_id" />
												<color-badge v-if="option.option.use_project_type_pricing" content="Egyedi árazás" color="secondary"></color-badge>
											</div>
										</template>
										<template v-slot:selected-option="option">
											<div class="d-flex gap-2 flex-wrap align-items-center">
												<strong>{{ option.option.public_id }}</strong>
												<price :value="option.option.total_price_gross" :currency-id="option.option.currency_id" />
												<color-badge v-if="option.option.use_project_type_pricing" content="Egyedi árazás" color="secondary"></color-badge>
											</div>
										</template>
									</select-field>
								</div>
								<div class="col-md-6">
									<SwitchField
										label="Egyedi számlázás"
										name="custom_payment"
										v-model="contract.custom_payment"
										v-if="app.hasPermission('ProjectsController_enableCustomBilling')"
									/>
								</div>
							</div>
							<label class="required">Aláírt szerződés csatolása</label>
							<FileUploader
								field-name="signedPages"
								v-model="signedPages"
								:editable="true"
								:multiple="true"
							></FileUploader>
						</div>
					</div>

					<ProjectProductList
						:data-url="'/projects/' + projectDataStore.projectId + '/calculateContractPrices'"
						:is-editable="isEditable"
						v-model="contractProducts"
						:parent-model="contract"
						:product-versions="contract.product_versions"
						mode="foreignContract"
						v-if="!contract.quote_id"
						ref="productList"
						:initial-prices="productPrices"
					>
					</ProjectProductList>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import {moveValueInArray} from "../../functions";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import InputField from "../../components/form/InputField.vue";
import ProjectProductList from "./ProjectProductList.vue";
import Price from "../../components/Price.vue";
import vSelect from "vue-select";
import FileList from "../../components/FileList.vue";
import SelectField from "../../components/form/SelectField.vue";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import yupLocaleHun from '../../validationMessages'
import DateField from "../../components/form/DateField.vue";
import FileUploader from "../../components/FileUploader.vue";
import UserBadge from "../../components/badges/UserBadge.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import CurrencySelectField from "../../components/form/CurrencySelectField.vue";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useProjectDataStore} from "../../stores/projectData";
import LockMessage from "../../components/LockMessage.vue";
import Modal from "../../components/Modal.vue";
import DateTime from "../../components/DateTime.vue";
import Attribute from "../../components/Attribute.vue";
import ContractDeliveryBadges from "./ContractDeliveryBadges.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import UserAttribute from "../../components/attributes/UserAttribute.vue";
import DateTimeAttribute from "../../components/attributes/DateTimeAttribute.vue";

yup.setLocale(yupLocaleHun);

export default {
	components: {
		DateTimeAttribute,
		UserAttribute,
		SwitchField,
		ContractDeliveryBadges,
		Attribute,
		DateTime,
		Modal,
		LockMessage,
		ColorBadge,
		DataLoader,
		CurrencySelectField,
		UserBadge,
		FileUploader, DateField, Form, Field, ErrorMessage, SelectField, FileList, Price, ProjectProductList, InputField, ProjectInfoSidebar, vSelect},
	emits: ['close', 'closeAndReload', 'generate', 'updateStats', 'enableDelivery'],
	props: {
		editable: Boolean,
		formErrors: Object,
		contractId: {
			type: [Number, String],
			default: 0
		}
	},
	mounted() {
		this.loadData()
	},
	computed: {
		isEditable: {
			get(){
				if (this.contract.id === undefined && !this.projectDataStore.can('contracts', 'create')){
					return false
				}
				if (this.contract.id !== undefined && !this.projectDataStore.can('contracts', 'update')){
					return false
				}
				if (!this.editable){
					return false
				}
				return this.contract.editable
			}
		},
		allCategoriesValid: {
			get(){
				return this.$refs.productList.allCategoriesValid()
			}
		},
		paymentPlansById: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		},
		contractValid: {
			get(){
				if (!this.contract.title || !this.contract.total_price_gross || !this.contract.project_type_payment_plan_id || !this.signedPages.length){
					return false
				}

				return true
			}
		},
	},
	watch: {
		'contract.quote_id'(newVal, oldVal){
			if (newVal && (this.contract.total_price_gross === undefined || !this.contract.total_price_gross)){
				let quotes = _.keyBy(this.quotes, 'id')
				this.contract.total_price_net = quotes[newVal].total_price_net
				this.contract.total_price_gross = quotes[newVal].total_price_gross
				this.contract.currency_id = quotes[newVal].currency_id
			}
		}
	},
	data() {
		return {
			contract: {},
			contractProducts: [],
			loading: false,
			productPrices: {},
			pricesLoading: false,
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			app: useApplicationStore(),
			pricesLoadingCancelToken: null,
			priceCalculationRequired: false,
			paymentPlans: [],
			signedPages: [],
			quotes: [],
		};
	},
	methods: {
		moveValueInArray: moveValueInArray,
		loadData: function () {
			this.loading = true

			axios.get('/projects/' + this.projectDataStore.projectId + '/foreignContract' + (this.contractId ? '/' + this.contractId : '')).then((response) => {
				this.handleResponse(response)
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error);
			})
		},
		productChanged: function(){
			this.$refs.productList.productChanged()
		},
		emitClose: function(){
			this.$emit('close')
		},
		saveForm: function(e){
			if (this.signedPages){
				this.loading = true

				let payload = {
					contract: this.contract,
					products: this.contract.quote_id ? [] : this.$refs.productList.getFilteredProducts(),
					foreign: true,
					signedPages: this.signedPages
				}

				let url = '/projects/' + this.projectDataStore.projectId + '/saveContract'
				if (this.contractId){
					url += '/' + this.contractId
				}
				axios.post(url + '?action=save', payload).then((response)=>{
					this.handleResponse(response)
				}).catch((error) => {
					this.loading = false
					this.flashStore.addError(error);
				})
			} else {
				this.flashStore.addFlash({level: 'danger', message: 'Az aláírt szerződés feltöltése kötelező!'})
			}
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status === undefined || response.data.status === 'success') {
				this.contract = response.data.contract
				this.productPrices = response.data.prices
				this.contractProducts = this.contract.products_pivot
				this.paymentPlans = response.data.paymentPlans
				this.quotes = response.data.quotes

				if (response.data.status !== undefined && response.data.status === 'success') {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}
		},
		getModalTitle: function (){
			if (this.contract.id !== undefined){
				return this.editable ? 'Szerződés szerkesztése' : 'Szerződés'
			}

			return 'Szerződés létrehozása'
		}
	}
}
</script>

<style scoped>

</style>
