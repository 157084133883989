<template>
	<TitleBar :title="attributes.id !== undefined ? 'Cimke módosítása' : 'Cimke hozzáadása'" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('tags.store', model.id)"
		:back-url="route('tags.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="label"
								rules="required"
								v-model="attributes.label"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<order-field
								v-model="attributes"
								label="Sorrend"
							/>
						</div>
						<div class="col-sm-6">
							<select-field
								label="Típus"
								name="type"
								rules="required"
								v-model="attributes.type"
								:display-badge="true"
								data-type="string"
								:disabled="!attributes.editable"
								:errors="errors"
								:options="typesArray"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import SelectField from "../components/form/SelectField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import {objectToArray} from "../functions";
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import DateTime from "../components/DateTime.vue";
import OrderField from "../components/form/OrderField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	layout: AppLayout,
	components: {UpdatedAttribute, OrderField, DateTime, TitleBar, InputField, FormWrapper, SelectField},
	props: {
		model: Object,
		types: Object
	},
	computed: {
		typesArray: {
			get(){
				return objectToArray(this.types)
			}
		}
	},
	data(){
		return {
			attributes: this.model,
		}
	},
}
</script>
