<script setup>

import TableActionDropdown from "../../components/TableActionDropdown.vue";
import DateTime from "../../components/DateTime.vue";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import {ref} from "vue";
import WarrantyForm from "./WarrantyForm.vue";
import {useProjectDataStore} from "../../stores/projectData";

const props = defineProps({
	dataUrl: String,
	contract: Object,
	editable: Boolean,
	canStartEditing: Boolean
})

const warrantyDialogOpen = ref(false)
const warrantyDialogContractId = ref(0)
const warrantyDialogId = ref(0)
const projectDataStore = useProjectDataStore()
const show = ref(true)
const columns = ref([
	{
		text: 'Azonosító',
		value: 'public_id',
		sortable: true,
	},
	{
		text: 'Termékek',
		value: 'products',
		align: 'right',
		sortable: true,
		width: 10
	},
	{
		text: 'Létrehozva',
		value: 'created_at',
		sortable: true,
		width: 10
	},
	{
		label: '',
		value: 'operations',
		width: 5,
	}
])

function openWarrantyModal(id){
	warrantyDialogId.value = id
	warrantyDialogContractId.value = props.contract.id
	warrantyDialogOpen.value = true
}
</script>

<template>
	<div :class="{'card': 1, 'd-none': !show}">
		<div class="card-body">
			<h4 class="card-title mb-4">Garanciajegyek</h4>
			<AdvancedDataTable
				:data-url="dataUrl"
				:columns="columns"
				sort-by="created_at"
				ref="contractWarrantyCardsTable"
				sort-type="desc"
				name="contractWarrantyCards"
				@no-results-found="show = false"
				:compact="true"
			>

				<template #item-operations="{item, deleteButtonPressed}">
					<div class="operations-wrapper">
						<div class="mobile-cell">
							<div class="stacked-cell">
								<strong>{{ item.public_id }}</strong>
								{{ item.products }}
								<date-time :value="item.created_at"/>
							</div>
						</div>
						<TableActionDropdown
							:data="item"
							:emit-edit-event="true"
							:emit-view-event="true"
							:emit-delete-event="true"
							:is-editable="false"
							:is-deletable="false"
							@viewButtonPressed="openWarrantyModal"
							v-slot="{ row }"
						>
							<a class="dropdown-item" v-close-popper :href="'/projects/' + contract.project_id + '/downloadWarrantyCard/' + row.id"  v-if="row.file_id">
								<i class="far fa-download fa-fw me-1 text-secondary"></i> Letöltés
							</a>
						</TableActionDropdown>
					</div>
				</template>

				<template #item-public_id="item">
					<div class="stacked-cell">
						<strong>{{ item.public_id }}</strong>
					</div>
				</template>

				<template #item-products="item">
					{{ item.products }}
				</template>

				<template #item-created_at="item">
					<date-time :value="item.created_at"/>
				</template>
			</AdvancedDataTable>
		</div>
	</div>

	<WarrantyForm
		v-if="warrantyDialogOpen"
		:project-id="projectDataStore.projectId"
		:contract-id="warrantyDialogContractId"
		:warranty-card-id="warrantyDialogId"
		@close="warrantyDialogOpen = false"
		@closeAndReload="warrantyDialogOpen = false; $refs.table.getData()"
	/>
</template>

<style scoped>

</style>
