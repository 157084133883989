<template>
	<div class="stacked-cell">
		<template v-if="displayDelivery">

			<color-badge
				v-if="contract.status === 'completed'"
				color="light"
				icon="far fa-truck-loading"
				:small="small"
			>
				<number :value="contract.delivery_stats.delivered" /> / <number :value="contract.delivery_stats.products" /> kiszállítva
			</color-badge>
			<color-badge
				v-else-if="!contract.delivery_enabled"
				color="light"
				icon="far fa-truck-loading"
				:small="small"
				content="Nincs"
			/>
			<template v-else>
				<color-badge
					v-if="contract.delivery_stats.products > (contract.delivery_stats.reserved + contract.delivery_stats.delivered)"
					:color="contract.delivery_stats.reserved ? 'warning' : 'danger'"
					icon="fas fa-cubes"
					:small="small"
				>
					Lefoglalandó: <number :value="contract.delivery_stats.products - Math.max(contract.delivery_stats.reserved - contract.delivery_stats.delivered)" />
				</color-badge>

				<color-badge
					:color="contract.delivery_stats.reserved > contract.delivery_stats.delivered ? 'warning' : 'light'"
					icon="fas fa-truck-loading"
					:small="small"
					v-if="contract.delivery_stats.reserved > contract.delivery_stats.delivered"
				>
					Kiszállítandó: <number :value="contract.delivery_stats.reserved" />
				</color-badge>

				<color-badge
					color="success"
					icon="fas fa-truck-loading"
					:small="small"
					v-if="contract.delivery_stats.products <= contract.delivery_stats.delivered"
				>
					Kiszállítva
				</color-badge>
			</template>
		</template>
		<template v-if="displayInstallation">
			<template v-if="parseInt(contract.has_installation)">
				<color-badge
					:small="small"
					color="success"
					icon="far fa-tools"
					content="Telepítve"
					v-if="!parseInt(contract.has_pending_installation)"/>

				<color-badge
					:small="small"
					color="warning"
					icon="far fa-tools"
					:content="contract.installation_date ? 'Telepítés: ' + contract.installation_date : 'Telepítés folyamatban'"
					v-else/>
			</template>
			<template v-else>
				<color-badge
					v-if="contract.installation_enabled"
					color="light"
					icon="fas fa-tools"
					:small="small"
				>
					Telepítés indítható
				</color-badge>
			</template>
		</template>
	</div>
</template>

<script>
import ColorBadge from "../../components/ColorBadge.vue";
import Number from "../../components/Number.vue";

export default {
	components: {Number, ColorBadge},
	props: {
		contract: Object,
		small: Boolean,
		displayDelivery: {
			type: Boolean,
			default: true
		},
		displayInstallation: {
			type: Boolean,
			default: true
		}
	}
}
</script>
