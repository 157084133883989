<template>
	<div class="dropdown d-inline-block" ref="notificationsWrapper">
		<button type="button" class="btn header-item noti-icon waves-effect topbar-button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i class="far fa-bell"></i>
			<span class="badge bg-primary rounded-pill" v-if="app.notificationCount">{{ app.notificationCount }}</span>
		</button>
		<div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
			<div class="p-3">
				<div class="row align-items-center">
					<div class="col">
						<h6 class="m-0" key="t-notifications"> Értesítések </h6>
					</div>
				</div>
			</div>
			<div style="max-height: 300px;overflow-y:scroll;">
				<div class="d-flex flex-column gap-1">
					<div :class="{'text-reset': 1, 'notification-item': 1, 'read': notification.read_at}" v-for="notification in app.notifications" :key="notification.id">
						<div class="d-flex">
							<div class="avatar-xs me-3">
								<span :class="'avatar-title bg-' + notification.color + ' rounded-circle font-size-16'">
									<i :class="notification.icon"></i>
								</span>
							</div>
							<div class="flex-grow-1">
								<h6 class="mt-0 mb-1">{{ notification.title }}</h6>
								<div class="font-size-12 text-muted">
									<p class="mb-1">{{ notification.description }}</p>
									<div class="d-flex gap-2">
										<div>
											<i class="far fa-clock"></i> <RelativeTime v-model="notification.created_at" />
										</div>
										<div v-if="notification.read_at" class="text-success">
											<i class="far fa-check"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						<Link :href="'/notifications/redirect/' + notification.id"></Link>
					</div>
				</div>
			</div>
			<div class="p-2 border-top d-grid">
				<Link class="btn btn-sm btn-link font-size-14 text-center" href="/notifications">
					<i class="far fa-angle-right me-1"></i> <span key="t-view-more">Összes megtekintése</span>
				</Link>
			</div>
		</div>
	</div>
</template>

<script>
import RelativeTime from "./RelativeTime.vue";
import { router, usePage, Link } from '@inertiajs/vue3'
import {useApplicationStore} from "../stores/applicationStore";

export default {
	components: {RelativeTime, Link},
	data(){
		const page = usePage()
		return {
			page: page,
			app: useApplicationStore()
		}
	},
	mounted() {
		this.$refs.notificationsWrapper.addEventListener('show.bs.dropdown', event => {
			axios.post('/notifications/post', {'action': 'read'}).then((response) => {
				this.app.notificationCount = 0
			}).catch(function (error) {
			})
		})
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
