<template>
	<data-loader :data="['vehicles']" v-slot="{ dataStore }">
		<select-field
			:label="label"
			:name="name"
			v-model="componentValue"
			:options="dataStore.getData('vehicles')"
			:errors="errors"
			:rules="rules"
			:info="info"
			:field-only="fieldOnly"
			:disabled="disabled"
		>
			<template v-slot:option="option">
				<vehicle-label :vehicle-id="option.option.id"/>
			</template>
			<template v-slot:selected-option="option">
				<vehicle-label :vehicle-id="option.option.id"/>
			</template>
		</select-field>
	</data-loader>
</template>

<script>
import SelectField from "./SelectField.vue";
import vSelect from "vue-select";
import UserLevelBadge from "../UserLevelBadge.vue";
import DataLoader from "../DataLoader.vue";
import ColorBadge from "../ColorBadge.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import VehicleLabel from "../VehicleLabel.vue";

export default {
	emits: ['update:modelValue'],
	components: {VehicleLabel, ColorBadge, DataLoader, UserLevelBadge, vSelect, SelectField},
	props: {
		modelValue: Number,
		errors: Object,
		name: String,
		label: String,
		fieldOnly: Boolean,
		disabled: Boolean,
		info: String,
		rules: {
			type: String,
			default: ''
		},
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	mounted() {
	},
	data(){
		return {
			options: [],
			dataStore: useGeneralDataStore()
		}
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
