<template>
	<TitleBar :title="$t('Jelszó beállítása')" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('clientArea.auth.setPassword', hash)"
		v-model="attributes"
		:show-buttons="false"
		v-slot="{ errors }"
	>

		<template v-if="client.id === undefined">
			<div class="row">
				<div class="col-md-6">
					<input-field
						:label="$t('Vezetéknév')"
						name="last_name"
						rules="required"
						v-model="attributes.last_name"
						:errors="errors"
						:multilang="false"
					/>

					<input-field
						v-if="isCompany"
						:label="$t('Cégnév')"
						name="company_name"
						rules="required"
						v-model="attributes.company_name"
						:errors="errors"
						:multilang="false"
					/>

					<input-field
						v-if="isCompany"
						:label="$t('Cégjegyzékszám')"
						name="company_id_number"
						rules="required"
						v-model="attributes.company_id_number"
						:errors="errors"
						:multilang="false"
					/>

					<select-field
						name="project_type_id"
						:label="$t('Szolgáltatás')"
						:options="projectTypes"
						v-model="attributes.project_type_id"
						rules="required"
						:errors="errors"
					/>
				</div>
				<div class="col-md-6">
					<input-field
						:label="$t('Keresztnév')"
						name="first_name"
						rules="required"
						v-model="attributes.first_name"
						:errors="errors"
						:multilang="false"
					/>

					<input-field
						v-if="isCompany"
						:label="$t('Adószám')"
						name="company_tax_number"
						rules="required"
						v-model="attributes.company_tax_number"
						:errors="errors"
						:multilang="false"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<input-field
						:label="$t('E-mail cím')"
						name="email"
						rules="required"
						data-type="email"
						v-model="attributes.email"
						:errors="errors"
						:multilang="false"
					/>
				</div>
				<div class="col-md-6">
					<input-field
						:label="$t('Telefonszám')"
						name="phone"
						rules="required"
						data-type="phone"
						v-model="attributes.phone"
						:errors="errors"
						:multilang="false"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<input-field
						:label="$t('Jelszó')"
						name="password"
						data-type="password"
						rules="min:8|required"
						v-model="attributes.password"
						:errors="errors"
						:multilang="false"
					/>
				</div>
				<div class="col-md-6">
					<input-field
						:label="$t('Jelszó újra')"
						name="password_confirmation"
						rules="required"
						data-type="password"
						v-model="attributes.password_confirmation"
						:equal-to="attributes.password"
						:errors="errors"
						:multilang="false"
					/>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="mb-3">
				<label>{{ $t('E-mail cím') }}</label>
				<input type="text" readonly class="form-control-plaintext" v-model="client.email" />
			</div>

			<input-field
				:label="$t('Jelszó')"
				name="password"
				data-type="password"
				rules="min:8|required"
				v-model="attributes.password"
				:errors="errors"
				:multilang="false"
			/>

			<input-field
				:label="$t('Jelszó újra')"
				name="password_confirmation"
				rules="required"
				data-type="password"
				v-model="attributes.password_confirmation"
				:equal-to="attributes.password"
				:errors="errors"
				:multilang="false"
			/>
		</template>

		<div class="mb-3">
			<div class="form-check">
				<Field
					v-model="attributes.acceptGdpr"
					name="gdprCheckbox"
					id="gdprCheckbox"
					type="checkbox"
					:rules="gdprCheckboxRules"
					class="form-check-input"
					:value="true"
				/>
				<label class="form-check-label" for="gdprCheckbox">
					{{ $t('Elfogadom az adatvédelmi nyilatkozatot') }} <a :href="route('gdpr')" target="_blank"><i class="far fa-link"></i> {{ $t('link')}}</a>
				</label>
			</div>
			<div class="invalid-feedback d-block" v-if="errors.gdprCheckbox">{{ errors.gdprCheckbox }}</div>
		</div>

		<div class="mb-3">
			<div class="form-check">
				<Field
					v-model="attributes.allowDataStorage"
					name="allowDataStorage"
					id="allowDataStorage"
					type="checkbox"
					:rules="allowDataStorageCheckboxRules"
					:value="true"
					class="form-check-input"
				/>
				<label class="form-check-label" for="allowDataStorage">
					{{ $t('Hozzájárulok személyes adataim kezeléséhez') }}
				</label>
			</div>
			<div class="invalid-feedback d-block" v-if="errors.allowDataStorage">{{ errors.allowDataStorage }}</div>
		</div>

		<div class="mt-4 d-grid">
			<button class="btn btn-primary waves-effect waves-light" type="submit">
				<i class="far fa-user-plus"></i> {{ $t('Regisztáció') }}
			</button>
		</div>
	</form-wrapper>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import ClientAreaAuthLayout from "../layouts/ClientAreaAuthLayout.vue";
import {useApplicationStore} from "../stores/applicationStore";
import SelectField from "../components/form/SelectField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import {Field} from "vee-validate";
import * as yup from "yup";

export default {
	layout: ClientAreaAuthLayout,
	components: {Field, InputField, FormWrapper, SelectField, TitleBar},
	props: {
		projectTypes: Array,
		isCompany: Boolean,
		hash: String,
		client: {
			type: Object,
			default: function(){
				return {}
			}
		}
	},
	data(){
		let attributes = {
			acceptGdpr: false,
			allowDataStorage: false
		}
		if (this.client.id === undefined){
			attributes.address = {}
			attributes.mail_address = {}
			attributes.project_type_id = _.first(this.projectTypes).id
		}
		return {
			app: useApplicationStore(),
			attributes: attributes,
			loading: false,
			flash: [],
		}
	},
	computed: {
		gdprCheckboxRules: {
			get(){
				return yup.bool().oneOf([true],this.$t('El kell fogadnia az adatvédelmi nyilatkozatot'))
					.required(this.$t('El kell fogadnia az adatvédelmi nyilatkozatot'))
			}
		},
		allowDataStorageCheckboxRules: {
			get(){
				return yup.bool().oneOf([true],this.$t('Bele kell egyeznie adatainak kezelésébe'))
					.required(this.$t('Bele kell egyeznie adatainak kezelésébe'))
			}
		},
	},
}
</script>
