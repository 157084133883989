<template>
	<data-loader :data="['componentProducts', 'productTypes']" v-slot="{ dataStore }">
		<div class="card" v-if="dataStore.getData('componentProducts').length && (editable || componentValue.length)">
			<div class="card-body">
				<h4 class="card-title mb-2">
					Alkatrészek
				</h4>
				<div class="row mb-3" v-if="isEditable && app.hasPermission('ProjectsController_canAddComponentsToContract')">
					<div class="col-lg-6">
						<select-field
							label="Alkatrész hozzáadása"
							name="addComponent"
							field-only
							v-model="addComponent"
							:options="dataStore.getData('componentProducts')"
							@update:modelValue="addComponentProduct"
						>
							<template v-slot:option="option">
								<div>{{ option.option.name }}</div>

								<div class="font-size-11 d-flex align-items-center gap-2">
									<i class="far fa-tag ms-1" v-if="option.option.track_serial_number"></i>
									<div>({{ option.option.sku }})</div>
									<div class="fst-italic">{{ option.option.categoryName }}</div>
									<strong v-if="option.option.manufacturer">{{ option.option.manufacturer }}</strong>
								</div>
							</template>
							<template v-slot:selected-option="option">
								({{ option.option.sku }}) {{ option.option.name }}
							</template>
						</select-field>
					</div>
				</div>

				<table class="table table-collapse-mobile-auto">
					<thead>
					<tr>
						<th>Termék</th>
						<th width="160" class="text-end">Mennyiség</th>
						<th width="80" class="text-end" v-if="contract.delivery_enabled">Kiszállítás</th>
						<th width="80" class="text-end" v-if="contract.delivery_enabled">Foglalva</th>
						<th width="80" class="text-end" v-if="contract.delivery_enabled">Kiszállítva</th>
						<th width="15%">Típus</th>
						<th width="1%" v-if="isEditable && app.hasPermission('ProjectsController_canAddComponentsToContract')">&nbsp;</th>
					</tr>
					</thead>
					<tbody>
					<template v-for="(product, productIndex) in componentValue" :key="'component_' + product.product_id">
						<tr>
							<td>
								<product-badge
									:product-id="product.product_id"
									:product-name="dataStore.getData('componentProducts', true)[product.product_id].name"
									:product-manufacturer-id="dataStore.getData('componentProducts', true)[product.product_id].product_manufacturer_id"
									:key="'PB' + product.product_id"
								/>
							</td>
							<td class="text-end">
								<input-field
									data-type="number"
									:step="1"
									v-model="product.quantity"
									:name="'componentQuantity_' + product.product_id"
									:field-only="true"
									:suffix="dataStore.getData('componentProducts', true)[product.product_id].unit"
									label="Mennyiség"
									v-if="isEditable && app.hasPermission('ProjectsController_canAddComponentsToContract')"
								/>
								<mobile-attribute
									label="Mennyiség"
									color="light"
									v-else
								>
									<template #default>
										<number :value="product.quantity" :unit="dataStore.getData('componentProducts', true)[product.product_id].unit"/>
									</template>
								</mobile-attribute>
							</td>
							<td class="text-end" v-if="contract.delivery_enabled">
								<template v-if="dataStore.getData('componentProducts', true)[product.product_id].type === 'material' && dataStore.getData('componentProducts', true)[product.product_id].track_inventory">
									<template v-if="isEditable">
										<div class="d-none d-lg-block">
											<switch-field
												:name="'componentDelivery_' + productIndex"
												v-model="product.requires_delivery"
												field-only
											/>
										</div>
										<div class="d-lg-none">
											<switch-field
												:name="'componentDeliveryMobile_' + productIndex"
												v-model="product.requires_delivery"
												label="Kiszállítás"
												inline
												inline-reversed
											/>
										</div>
									</template>
									<template v-else>
										<color-badge
											:color="product.requires_delivery ? 'primary' : 'dark'"
											:content="product.requires_delivery ? 'Kiszállítás' : 'Nincs kiszállítás'"
											small
											/>
									</template>
								</template>
							</td>
							<td class="text-end vertical-align-middle" v-if="contract.delivery_enabled">
								<template v-if="dataStore.getData('componentProducts', true)[product.product_id].type === 'material' && dataStore.getData('componentProducts', true)[product.product_id].track_inventory && product.requires_delivery">
									<mobile-attribute
										label="Foglalva"
										color="light"
										icon="far fa-cubes"
									>
										<template #default>
											<number :value="product.reserved"/>
										</template>
									</mobile-attribute>
								</template>
							</td>
							<td class="text-end vertical-align-middle" v-if="contract.delivery_enabled">
								<template v-if="dataStore.getData('componentProducts', true)[product.product_id].type === 'material' && product.requires_delivery">
									<mobile-attribute
										label="Kiszállítva"
										:color="product.delivered >= product.quantity ? 'success' : (product.delivered < product.quantity ? 'danger' : 'light')"
										icon="fas fa-truck-loading"
									>
										<template #default>
											<span :class="{
												'fw-bold': 1,
												'text-success': product.delivered >= product.quantity,
												'text-danger': product.delivered < product.quantity}">
												<number :value="product.delivered"/>
											</span>
										</template>
										<template #mobile>
											<number :value="product.delivered"/>
										</template>
									</mobile-attribute>
								</template>
							</td>
							<td class="vertical-align-middle">
								<div v-if="product.product_id">
									<color-badge :value="dataStore.getData('productTypes')[dataStore.getData('componentProducts', true)[product.product_id].type]"></color-badge>
								</div>
							</td>
							<td v-if="isEditable && app.hasPermission('ProjectsController_canAddComponentsToContract')">
								<button type="button" class="btn btn-danger" @click.prevent="removeComponents(productIndex)" title="Törlés"><i class="far fa-times"></i><span class="d-lg-none ms-1">Eltávolít</span></button>
							</td>
						</tr>
					</template>
					</tbody>
				</table>
				<div class="mt-2 d-flex gap-2" v-if="isEditable && app.hasPermission('ProjectsController_canAddComponentsToContract')">
					<button type="button" class="btn btn-primary btn-label" @click.prevent="saveComponents"><i class="far fa-cog label-icon"></i> Alkatrészek mentése</button>
				</div>
			</div>
		</div>
	</data-loader>
</template>

<script>
import Number from "../../components/Number.vue";
import MobileAttribute from "../../components/MobileAttribute.vue";
import DataLoader from "../../components/DataLoader.vue";
import SelectField from "../../components/form/SelectField.vue";
import InputField from "../../components/form/InputField.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import {useApplicationStore} from "../../stores/applicationStore";
import {useProjectDataStore} from "../../stores/projectData";
import SwitchField from "../../components/form/SwitchField.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import ProductBadge from "../../components/badges/ProductBadge.vue";

export default {
	emits: ['update:modelValue', 'componentsChanged'],
	components: {ProductBadge, SwitchField, ColorBadge, InputField, SelectField, DataLoader, MobileAttribute, Number},
	props: {
		modelValue: Object,
		contract: Object,
		editable: Boolean
	},
	data(){
		return {
			addComponent: null,
			flashStore: useFlashStoreStore(),
			app: useApplicationStore(),
			projectDataStore: useProjectDataStore(),
			dataStore: useGeneralDataStore()
		}
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		isEditable: {
			get(){
				return ['signed', 'generated', 'draft'].indexOf(this.contract.status) > -1 && this.editable
			}
		}
	},
	methods: {
		addComponentProduct: function (){
			if (this.addComponent){
				let added = false
				for (let i = 0; i < this.componentValue.length; i++){
					if (this.componentValue[i].product_id === this.addComponent){
						this.componentValue[i].quantity++
						added = true
					}
				}
				if (!added) {
					this.componentValue.push({
						product_id: this.addComponent,
						quantity: 1,
						delivered: 0,
						reserved: 0,
						requires_delivery: !!this.dataStore.getData('componentProducts', true)[this.addComponent].requires_delivery
					})
				}
			}
			this.addComponent = null
		},
		removeComponents: function (index){
			this.componentValue.splice(index, 1)
		},
		saveComponents: function (){
			axios.post('/projects/' + this.projectDataStore.projectId + '/saveContractComponents/' + this.contract.id, {
				components: this.componentValue
			}).then((response)=>{
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash)
					this.$emit('componentsChanged')
				}
				this.componentValue = response.data.components
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		}
	}
}
</script>

<style scoped>

</style>
