<script>
import FileSize from "../../components/FileSize.vue";
import DateTime from "../../components/DateTime.vue";
import Attribute from "../../components/Attribute.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import DataLoader from "../../components/DataLoader.vue";
import data from "bootstrap/js/src/dom/data";

export default {
	emits: ['loadTimeline'],
	data() {
		return {
			actionsVisible: false
		}
	},
	props: {
		event: Object,
		showSeenEvents: Boolean,
		compact: Boolean,
		showActions: {
			type: Boolean,
			default: true
		},
		showDate: {
			type: Boolean,
			default: true
		},
		fadeSeen: {
			type: Boolean,
			default: true
		},
		vertical: {
			type: Boolean,
			default: false
		},
		iconColorOnly: {
			type: Boolean,
			default: false
		},
	},
	components: {DataLoader, ColorBadge, Attribute, DateTime, FileSize},
	methods: {
		loadTimeline: function (from){
			this.$emit('loadTimeline', from)
		},
		timelineEventAction: function (entry, action){
			axios.post('/dashboard/action/' + entry.id + '/' + action,  {}).then((response) => {
				this.loadTimeline(entry.sort_date)
			}).catch(function (error) {
			})
		},
	}
}
</script>

<template>
	<div :class="{'timeline-item-event': 1, 'timeline-item-event-seen': event.seen_at && fadeSeen, 'timeline-item-event--compact': compact}" v-if="showSeenEvents || !event.seen_at">
		<div class="d-flex gap-1 w-100 gap-lg-2">
			<div :class="{'d-flex': 1, 'gap-2': 1, 'flex-column': 1, 'flex-lg-row': !vertical, 'gap-lg-2': !vertical}">
				<attribute
					icon="far fa-clock"
				>
					<date-time :value="event.created_at" :date="showDate"/>
				</attribute>
				<template v-if="event.project_type_milestone_id">
					<data-loader :data="['projectTypeMilestones']" v-slot="{ dataStore }">
						<div v-if="dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id] === undefined">
							{{dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id]}}
						</div>
						<template v-else>
							<template v-if="dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id].is_hidden">
								<div class="d-flex gap-2 align-items-center">
									<i
										:class="'far fa-' + (dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id].icon ? dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id].icon : 'check')"
										:style="'color: ' + dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id].color"></i>
									<strong>{{ dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id].name }}</strong>
								</div>
							</template>
							<template v-else-if="iconColorOnly">
								<div class="d-flex gap-2 align-items-center">
									<color-badge
										small
										:color="dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id].color"
										:luminosity="dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id].luminosity"
									>
										<i :class="'far fa-' + dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id].icon"></i>
									</color-badge>
									<div class="flex-grow-1">{{ dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id].name }}</div>
								</div>
							</template>
							<template v-else>
								<color-badge
									small
									:value="dataStore.getData('projectTypeMilestones', true)[event.project_type_milestone_id]"/>
							</template>
						</template>
					</data-loader>
				</template>
				<template v-if="event.event">
					<div class="d-flex gap-2 align-items-center">
						<data-loader :data="['projectEventTypes']" v-slot="{ dataStore }">
							<i
								:class="dataStore.getData('projectEventTypes')[event.event].icon + ' text-' + dataStore.getData('projectEventTypes')[event.event].color"></i>
							<strong>{{ dataStore.getData('projectEventTypes')[event.event].name }}</strong>
						</data-loader>
					</div>
				</template>
			</div>
			<template v-if="showActions">
				<div class="ms-auto" v-if="event.event === 'approveDocumentBundle'">
					<button type="button" class="btn btn-light btn-sm timeline-item--action-toggle" @click.prevent="actionsVisible = !actionsVisible">
						<i class="far fa-ellipsis-h"></i>
					</button>
				</div>
			</template>
		</div>
		<template v-if="actionsVisible">
			<div class="timeline-item--action-list" v-if="event.event === 'approveDocumentBundle'">
				<a href="#" @click.prevent="timelineEventAction(event, 'approve')">
					<i class="fas fa-check text-success fa-fw"></i> Jóváhagyás
				</a>
				<a :href="file.url" v-for="file in event.event_details.files">
					<i class="fas fa-download fa-fw"></i> Letöltés: {{ file.name }} (<file-size :value="file.size"/>)
				</a>
			</div>
		</template>
	</div>
</template>

<style scoped>

</style>
