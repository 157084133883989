<template>
	<TitleBar title="Érdeklődők" />

	<AdvancedDataTable
		:data-url="route('inquiries.list')"
		:columns="columns"
		sort-by="public_id"
		sort-type="desc"
		name="inquiries"
		:default-filters="defaultFilters"
		v-if="dataStore.dataLoaded('inquiryStatusLabels')"
		show-tracked-edit-mode
	>
		<template #navigation>
			<ButtonGroup>
				<template #create v-if="app.hasPermission('InquiriesController_create')">
					<Link :href="route('inquiries.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
				</template>
			</ButtonGroup>
		</template>

		<template #filters="{serverOptions}">
			<div class="data-table-filter-item">
				<v-select
					:options="statusLabelsExtended"
					v-model="serverOptions.status"
					:reduce="item => item.value"
					placeholder="Állapot"
				>
					<template v-slot:option="option">
						<span :class="'badge bg-' + option.color"><i :class="option.icon"></i> {{ option.name }}</span>
					</template>
					<template v-slot:selected-option="option">
						<span :class="'badge bg-' + option.color"><i :class="option.icon"></i> {{ option.name }}</span>
					</template>
				</v-select>
			</div>
			<div class="data-table-filter-item">
				<data-loader :data="['projectTypes']" v-slot="{ dataStore }">
					<v-select
						:options="dataStore.getData('projectTypes')"
						v-model="serverOptions.project_type_id"
						:reduce="item => item.id"
						placeholder="Típus"
					>
						<template v-slot:option="option">
							<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
						</template>
						<template v-slot:selected-option="option">
							<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
						</template>
					</v-select>
				</data-loader>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<InquiryTableCell :item="item" :show-name="true"/>

					<boolean-badge
						v-model="item.is_company"
						:key="'is_companyM_' + item.id"
						company
					/>

					<div class="mobile-horizontal">
						<email-address v-if="item.email" :value="item.email"></email-address>
						<phone-number v-if="item.phone" :value="item.phone"></phone-number>
					</div>

					<date-time :value="item.created_at"/>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('inquiries.update')"
					:delete-url="route('inquiries.delete')"
					:is-editable="item.editable"
					:is-deletable="item.deletable"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
		<template #item-public_id="item">
			<InquiryTableCell :item="item"/>
		</template>
		<template #item-is_company="item">
			<boolean-badge
				v-model="item.is_company"
				:key="'is_company_' + item.id"
			/>
		</template>
		<template #item-email="item">
			<div class="d-flex flex-column gap-2 align-items-start">
				<email-address v-if="item.email" :value="item.email"></email-address>
				<phone-number v-if="item.phone" :value="item.phone"></phone-number>
			</div>
		</template>
		<template #item-created_at="item">
			<date-time :value="item.created_at"/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import TableActionCell from "../components/TableActionCell.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import ProjectTypeCell from "../modules/projects/ProjectTypeCell.vue";
import DataLoader from "../components/DataLoader.vue";
import vSelect from "vue-select";
import {Link} from "@inertiajs/vue3";
import {useGeneralDataStore} from "../stores/generalData";
import {useApplicationStore} from "../stores/applicationStore";
import EmailAddress from "../components/EmailAddress.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import DateTime from "../components/DateTime.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import ClientCell from "../modules/projects/ClientCell.vue";
import ProjectMilestonesCell from "../modules/projects/ProjectMilestonesCell.vue";
import ProjectTableProjectCell from "../modules/projects/ProjectTableProjectCell.vue";
import ColorBadge from "../components/ColorBadge.vue";
import InquiryTableCell from "../modules/inquiries/InquiryTableCell.vue";

export default {
	layout: AppLayout,
	components: {
		InquiryTableCell,
		ColorBadge,
		ProjectTableProjectCell, ProjectMilestonesCell, ClientCell,
		ButtonGroup,
		DateTime,
		PhoneNumber,
		EmailAddress,
		vSelect, DataLoader, ProjectTypeCell, BooleanBadge, TableActionCell, TitleBar, Link, AdvancedDataTable},
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'public_id',
				sortable: true
			},
			{
				text: 'Cég',
				value: 'is_company',
				sortable: true,
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Elérhetőség',
				value: 'email',
				sortable: true
			},
			{
				text: 'Ajánló',
				value: 'handlerUserName',
				sortable: true
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true,
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			dataStore: useGeneralDataStore(),
			app: useApplicationStore(),
			defaultFilters: {
				status: 'pending'
			}
		}
	},
	computed: {
		statusLabelsExtended: {
			get(){
				let payload = []
				for (let i in this.dataStore.getData('inquiryStatusLabels')){
					payload.push({
						value: i,
						name: this.dataStore.getData('inquiryStatusLabels')[i].name,
						icon: this.dataStore.getData('inquiryStatusLabels')[i].icon,
						color: this.dataStore.getData('inquiryStatusLabels')[i].color,
					})
				}

				return payload
			}
		},
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
