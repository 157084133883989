<template>
	<TitleBar :title="$t('Regisztráció')" />

	<div>
		<p class="text-muted">{{$t('Töltse ki az alábbi űrlapot a regisztrációhoz!') }}</p>
		<p class="text-muted">{{ $t('Tanácsadó') }}: {{ referer.name }}</p>
	</div>
	<div class="mt-4">
		<form-wrapper
			ref="formWrapper"
			:store-url="route('clientArea.auth.register', hash)"
			v-model="attributes"
			:show-buttons="false"
			v-slot="{ errors }"
		>

			<div class="row">
				<div class="col-md-6">
					<input-field
						:label="$t('Vezetéknév')"
						name="last_name"
						rules="required"
						v-model="attributes.last_name"
						:errors="errors"
					/>

					<input-field
						v-if="isCompany"
						:label="$t('Cégnév')"
						name="company_name"
						rules="required"
						v-model="attributes.company_name"
						:errors="errors"
					/>

					<input-field
						v-if="isCompany"
						:label="$t('Cégjegyzékszám')"
						name="company_id_number"
						rules="required"
						v-model="attributes.company_id_number"
						:errors="errors"
					/>

					<select-field
						name="project_type_id"
						:label="$t('Szolgáltatás')"
						:options="projectTypes"
						v-model="attributes.project_type_id"
						rules="required"
						:errors="errors"
					/>
				</div>
				<div class="col-md-6">
					<input-field
						:label="$t('Keresztnév')"
						name="first_name"
						rules="required"
						v-model="attributes.first_name"
						:errors="errors"
					/>

					<input-field
						v-if="isCompany"
						:label="$t('Adószám')"
						name="company_tax_number"
						rules="required"
						v-model="attributes.company_tax_number"
						:errors="errors"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<input-field
						:label="$t('E-mail cím')"
						name="email"
						rules="required"
						data-type="email"
						v-model="attributes.email"
						:errors="errors"
					/>
				</div>
				<div class="col-md-6">
					<phone-field
						:label="$t('Telefonszám')"
						name="phone"
						rules="required"
						data-type="phone"
						v-model="attributes.phone"
						:errors="errors"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<input-field
						:label="$t('Jelszó')"
						name="password"
						data-type="password"
						rules="min:8|required"
						v-model="attributes.password"
						:errors="errors"
					/>
				</div>
				<div class="col-md-6">
					<input-field
						:label="$t('Jelszó újra')"
						name="password_confirmation"
						rules="required"
						data-type="password"
						v-model="attributes.password_confirmation"
						:equal-to="attributes.password"
						:errors="errors"
					/>
				</div>
			</div>

			<div class="mb-3">
				<div class="form-check">
					<Field
						v-model="attributes.acceptGdpr"
						name="gdprCheckbox"
						id="gdprCheckbox"
						type="checkbox"
						:rules="gdprCheckboxRules"
						class="form-check-input"
						:value="true"
					/>
					<label class="form-check-label" for="gdprCheckbox">
						{{ $t('Elfogadom az adatvédelmi nyilatkozatot') }} <a :href="route('gdpr')" target="_blank"><i class="far fa-link"></i> {{ $t('link')}}</a>
					</label>
				</div>
				<div class="invalid-feedback d-block" v-if="errors.gdprCheckbox">{{ errors.gdprCheckbox }}</div>
			</div>

			<div class="mb-3">
				<div class="form-check">
					<Field
						v-model="attributes.allowDataStorage"
						name="allowDataStorage"
						id="allowDataStorage"
						type="checkbox"
						:rules="allowDataStorageCheckboxRules"
						:value="true"
						class="form-check-input"
					/>
					<label class="form-check-label" for="allowDataStorage">
						{{ $t('Hozzájárulok személyes adataim kezeléséhez') }}
					</label>
				</div>
				<div class="invalid-feedback d-block" v-if="errors.allowDataStorage">{{ errors.allowDataStorage }}</div>
			</div>

			<div class="mt-4 d-grid">
				<button class="btn btn-primary waves-effect waves-light" type="submit">
					<i class="far fa-user-plus"></i> {{ $t('Regisztáció') }}
				</button>
			</div>
		</form-wrapper>

	</div>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import SwitchField from "../components/form/SwitchField.vue";
import {Link} from "@inertiajs/vue3";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import {useApplicationStore} from "../stores/applicationStore";
import * as yup from 'yup';
import {Field} from "vee-validate";
import ClientAreaAuthWideLayout from "../layouts/ClientAreaAuthWideLayout.vue";
import SelectField from "../components/form/SelectField.vue";
import PhoneField from "../components/form/PhoneField.vue";


export default {
	components: {PhoneField, SelectField, Field, InputField, FormWrapper, Link, SwitchField, TitleBar},
	layout: ClientAreaAuthWideLayout,
	props: {
		hash: String,
		referer: Object,
		client: Object,
		isCompany: Boolean,
		projectTypes: Array,
	},
	computed: {
		gdprCheckboxRules: {
			get(){
				return yup.bool().oneOf([true],this.$t('El kell fogadnia az adatvédelmi nyilatkozatot'))
					.required(this.$t('El kell fogadnia az adatvédelmi nyilatkozatot'))
			}
		},
		allowDataStorageCheckboxRules: {
			get(){
				return yup.bool().oneOf([true],this.$t('Bele kell egyeznie adatainak kezelésébe'))
					.required(this.$t('Bele kell egyeznie adatainak kezelésébe'))
			}
		},
	},

	data() {
		let attributes = {
			acceptGdpr: false,
			allowDataStorage: false
		}
		attributes.address = {}
		attributes.mail_address = {}
		attributes.project_type_id = _.first(this.projectTypes).id

		if (this.client.email !== undefined){
			attributes.email = this.client.email
		}
		if (this.client.last_name !== undefined){
			attributes.last_name = this.client.last_name
		}
		if (this.client.first_name !== undefined){
			attributes.first_name = this.client.first_name
		}

		return {
			app: useApplicationStore(),
			attributes: attributes
		}
	}
}
</script>
