<template>
	<template v-for="(paymentPlan, paymentPlanKey) in componentValue" v-bind:key="'paymentPlan_' + paymentPlan.order">
		<div class="card">
			<div class="card-body">
				<div class="d-flex">
					<div class="sortable-form-card--left">
						<button type="button" @click.prevent="movePaymentPlan(paymentPlanKey, 'up')" class="btn btn-secondary mx-1 mt-2"><i class="far fa-arrow-up"></i></button>
						<button type="button" @click.prevent="movePaymentPlan(paymentPlanKey, 'down')" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
						<button type="button" @click.prevent="removePaymentPlan(paymentPlanKey)" v-if="!paymentPlan.in_use" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
					</div>
					<div class="sortable-form-card--right">
						<div class="row">
							<div class="col-md-6">
								<input-field
									label="Név"
									:name="'paymentMethod_name_' + paymentPlanKey"
									rules="required"
									v-model="paymentPlan.name"
									:errors="errors"
									:multilang="true"
									:languages="languages"
									v-model:translations="paymentPlan.translations"
									translation-field-name="name"
								></input-field>

								<input-field
									label="Publikus név"
									:name="'paymentMethod_name_public_' + paymentPlanKey"
									rules="required"
									v-model="paymentPlan.name_public"
									:errors="errors"
									:multilang="true"
									:languages="languages"
									v-model:translations="paymentPlan.translations"
									translation-field-name="name_public"
								></input-field>

								<textarea-field
									label="Leírás"
									:name="'paymentMethod_description_' + paymentPlanKey"
									v-model="paymentPlan.description"
									:errors="errors"
									:multilang="true"
									:languages="languages"
									v-model:translations="paymentPlan.translations"
									translation-field-name="description"
								></textarea-field>

								<input-field
									label="Szerződés határidő"
									:name="'paymentMethod_contract_time_limit_' + paymentPlanKey"
									rules="required"
									data-type="number"
									:step="1"
									:min="1"
									suffix="nap"
									v-model="paymentPlan.contract_time_limit"
									:errors="errors"
								></input-field>
							</div>
							<div class="col-md-6">
								<switch-field
									label="Aktív"
									:name="'paymentMethod_active_' + paymentPlanKey"
									v-model="paymentPlan.active"
									:errors="errors"
								></switch-field>

								<switch-field
									label="Árajánlat nélküli szerződéshez"
									:name="'paymentMethod_contract_without_quote_' + paymentPlanKey"
									v-model="paymentPlan.contract_without_quote"
									:errors="errors"
								></switch-field>

								<div class="mb-3">
									<label>Fizetési mód</label>
									<v-select
										:options="paymentMethods"
										v-model="paymentPlan.payment_method"
										:reduce="item => item.value"
										label="name"
										:clearable="false"
										:errors="errors"
									/>
								</div>
							</div>
						</div>

						<h4 class="card-title mb-2 mt-3">Részletek</h4>
						<div class="alert alert-danger mb-3" v-if="getPaymentPlanStepSum(paymentPlan, 'material') !== 100">
							Az anyagköltség részletek összege nem 100%! ({{getPaymentPlanStepSum(paymentPlan, 'material')}}%)
						</div>
						<div class="alert alert-danger mb-3" v-if="getPaymentPlanStepSum(paymentPlan, 'work') !== 100">
							Az munkadíj részletek összege nem 100%! ({{getPaymentPlanStepSum(paymentPlan, 'work')}}%)
						</div>
						<div class="alert alert-danger mb-3" v-if="getPaymentPlanStepSum(paymentPlan, 'service') !== 100">
							A szolgáltatás részletek összege nem 100%! ({{getPaymentPlanStepSum(paymentPlan, 'service')}}%)
						</div>
						<div class="alert alert-danger mb-3" v-if="getPaymentPlanStepSum(paymentPlan, 'relayed_service') !== 100">
							A közvetített szolgáltatás részletek összege nem 100%! ({{getPaymentPlanStepSum(paymentPlan, 'relayed_service')}}%)
						</div>
						<div class="card bg-light p-3 mb-3" v-for="(step, stepKey) in paymentPlan.steps">
							<div class="row">
								<div class="col-md-6">
									<input-field
										label="Név"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_name'"
										rules="required"
										v-model="step.name"
										:errors="errors"
										:multilang="true"
										:languages="languages"
										v-model:translations="step.translations"
										translation-field-name="name"
									></input-field>
								</div>
								<div class="col-md-6">
									<input-field
										label="Tétel megnevezés a számlán"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_name_on_invoice'"
										v-model="step.name_on_invoice"
										:errors="errors"
										:multilang="true"
										:languages="languages"
										v-model:translations="step.translations"
										translation-field-name="name_on_invoice"
									></input-field>
								</div>
								<div class="col-md-6">
									<input-field
										label="Tétel leírása a számlán"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_description_on_invoice'"
										v-model="step.description_on_invoice"
										:errors="errors"
										:multilang="true"
										:languages="languages"
										v-model:translations="step.translations"
										translation-field-name="description_on_invoice"
									></input-field>
								</div>
								<div class="col-md-6">
									<input-field
										label="Számla fizetési határidő"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_invoice_due_date_offset'"
										v-model="step.invoice_due_date_offset"
										suffix="nap"
										data-type="number"
										:step="1"
										:min="1"
										:errors="errors"
									></input-field>
								</div>
								<div class="col-md-12">
									<textarea-field
										label="Szerződés leírás"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_contract_text'"
										rules="required"
										v-model="step.contract_text"
										:errors="errors"
										:multilang="true"
										:languages="languages"
										v-model:translations="step.translations"
										translation-field-name="contract_text"
									>
										<div class="mb-3 bg-light p-2">
											<variable-select
												label="Változó beszúrása"
												:field-only="true"
												:options="textVariables"
												:errors="errors"
											/>
										</div>
									</textarea-field>
								</div>
								<div class="col-md-12">
									<textarea-field
										label="Számla megjegyzés mező tartalma"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_comment'"
										v-model="step.comment"
										:errors="errors"
										:multilang="true"
										:languages="languages"
										v-model:translations="step.translations"
										translation-field-name="comment"
									>
										<div class="mb-3 bg-light p-2">
											<variable-select
												label="Változó beszúrása"
												:field-only="true"
												:options="textVariables"
												:errors="errors"
											/>
										</div>
									</textarea-field>
								</div>
								<div class="col-md-6">
									<input-field
										label="Részlet (anyag)"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_percent_material'"
										rules="required"
										data-type="number"
										suffix-icon="far fa-percent"
										:step="0.1"
										v-model="step.percent_material"
										:errors="errors"
									></input-field>
								</div>
								<div class="col-md-6">
									<input-field
										label="Részlet (munka)"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_percent_work'"
										rules="required"
										data-type="number"
										suffix-icon="far fa-percent"
										:step="0.1"
										v-model="step.percent_work"
										:errors="errors"
									></input-field>
								</div>
								<div class="col-md-6">
									<input-field
										label="Részlet (szolgáltatás)"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_percent_service'"
										rules="required"
										data-type="number"
										suffix-icon="far fa-percent"
										:step="0.1"
										v-model="step.percent_service"
										:errors="errors"
									></input-field>
								</div>
								<div class="col-md-6">
									<input-field
										label="Részlet (közvetített szolgáltatás)"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_percent_relayed_service'"
										rules="required"
										data-type="number"
										suffix-icon="far fa-percent"
										:step="0.1"
										v-model="step.percent_relayed_service"
										:errors="errors"
									></input-field>
								</div>
								<div class="col-md-6">
									<switch-field
										label="Termékek rendeléséhez szükséges"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_required_for_order'"
										v-model="step.required_for_order"
										:errors="errors"
										></switch-field>
								</div>
								<div class="col-md-6">
									<switch-field
										label="Díjbekérő kiállítása"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_create_proforma'"
										v-model="step.create_proforma"
										:errors="errors"
										></switch-field>
								</div>
								<div class="col-md-6">
									<switch-field
										label="Ügyfél értesítés tiltása"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_disable_client_notification'"
										v-model="step.disable_client_notification"
										:errors="errors"
										></switch-field>
								</div>
								<div class="col-md-6">
									<input-field
										label="Automatikus számla az előző részlet után"
										:name="'paymentMethod_' + paymentPlanKey + '_step_' + stepKey + '_auto_create_delay'"
										v-model="step.auto_create_delay"
										data-type="number"
										:step="1"
										suffix="nap"
										:errors="errors"
										></input-field>
								</div>
							</div>
							<div class="mt-2 text-center">
								<a href="#" class="text-danger" v-if="!paymentPlan.in_use" @click.prevent="removePaymentPlanStep(paymentPlanKey, stepKey)"><i class="far fa-times"></i> törlés</a>
							</div>
						</div>
						<div class="mt-3">
							<button type="button" class="btn btn-secondary btn-label" v-if="!paymentPlan.in_use" @click.prevent="addPaymentPlanStep(paymentPlanKey)">
								<i class="far fa-plus label-icon"></i> Részletet hozzáad
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</template>

	<div class="mt-3">
		<button type="button" class="btn btn-secondary btn-label" @click.prevent="addPaymentPlan">
			<i class="far fa-plus label-icon"></i> Hozzáad
		</button>
	</div>
</template>

<script>
import SwitchField from "../../components/form/SwitchField.vue";
import InputField from "../../components/form/InputField.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import SelectField from "../../components/form/SelectField.vue";
import vSelect from "vue-select";
import {moveValueInArray} from "../../functions";
import CopyToClipboard from "../../components/CopyToClipboard.vue";
import VariableSelect from "../../components/form/VariableSelect.vue";
export default {
	emits: ['update:modelValue', 'validate'],
	components: {
		VariableSelect,
		CopyToClipboard,
		SwitchField,
		InputField,
		SelectField,
		TextareaField,
		vSelect,
	},
	props: {
		modelValue: Array,
		productCategories: Array,
		errors: Object,
		languages: Array,
		fields: Array
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
		textVariables: {
			get(){
				let variables = {
					'nettó_összeg': 'Nettó összeg',
					'bruttó_összeg': 'Bruttó összeg',
					'nettó_összeg_szöveg': 'Nettó összeg szövegesen',
					'bruttó_összeg_szöveg': 'Bruttó összeg szövegesen'
				}
				_.forEach(this.fields, function (field){
					if (['title', 'copyButton'].indexOf(field.type) < 0){
						variables['mező_' + field.field_number] = field.field_number + '. ' + field.title
					}
					if (field.children !== undefined){
						_.forEach(field.children, function (child){
							if (['title', 'copyButton'].indexOf(child.type) < 0){
								variables['mező_' + child.field_number] = child.field_number + '. ' + field.title + ' / ' + child.title
							}
						})
					}
				})

				return variables
			}
		}
	},
	data(){
		return {
			paymentMethods: [
				{
					value: 'wireTransfer',
					name: 'Átutalás'
				},
				{
					value: 'creditCard',
					name: 'Bankkártya'
				},
				{
					value: 'cash',
					name: 'Készpénz'
				},
			]
		}
	},
	methods: {
		moveValueInArray,
		addPaymentPlan: function(){
			let maxOrder = 0

			this.componentValue.forEach(function(item, index){
				if (item.order > maxOrder){
					maxOrder = item.order
				}
			})
			this.componentValue.push({
				order: maxOrder + 1,
				active: 1,
				in_use: false,
				payment_method: 'wireTransfer',
				steps: [],
                translations: {}
			})

			this.$emit('validate')
		},
		addPaymentPlanStep: function(index){
			let maxOrder = 0

			this.componentValue[index].steps.forEach(function(item, index){
				if (item.order > maxOrder){
					maxOrder = item.order
				}
			})
			this.componentValue[index].steps.push({
				order: maxOrder + 1,
				percent_material: Math.max(0, 100 - this.getPaymentPlanStepSum(this.componentValue[index], 'material')),
				percent_work: Math.max(0, 100 - this.getPaymentPlanStepSum(this.componentValue[index], 'work')),
				percent_service: Math.max(0, 100 - this.getPaymentPlanStepSum(this.componentValue[index], 'service')),
				percent_relayed_service: Math.max(0, 100 - this.getPaymentPlanStepSum(this.componentValue[index], 'relayed_service')),
				invoice_type: 'proforma',
				create_proforma: true,
				disable_client_notification: false,
                translations: {}
			})

			this.updateStepInvoiceTypes(index)

			this.$emit('validate')
		},
		movePaymentPlan: function(index, direction){
			this.componentValue = this.moveValueInArray(this.componentValue, index, direction)
			let order = 0
			this.componentValue.forEach(function(item, index){
				item.order = order
				order++
			})
		},
		removePaymentPlan: function(index){
			this.componentValue.splice(index, 1);

			this.$emit('validate')
		},
		removePaymentPlanStep: function(index, stepIndex){
			this.componentValue[index].steps.splice(stepIndex, 1);
			this.updateStepInvoiceTypes(index)

			this.$emit('validate')
		},
		updateStepInvoiceTypes: function(index){
			let lastIndex = this.componentValue[index].steps.length - 1
			for (let i = 0; i <= lastIndex; i++){
				if (this.componentValue[index].steps[i] === undefined){
					continue
				}
				this.componentValue[index].steps[i].invoice_type = i === lastIndex ? 'final' : 'proforma'
			}
		},
		getPaymentPlanStepSum: function (paymentPlan, type){
			let sum = 0
			_.forEach(paymentPlan.steps, function(step){
				if (step['percent_' + type] !== undefined) {
					sum += parseFloat(step['percent_' + type])
				}
			})
			return sum
		},
	}
}
</script>

<style scoped>

</style>
