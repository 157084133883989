<template>
	<div :class="{'card': 1, 'loading': loading}" v-if="projectDataStore.documentsLoaded">
		<div class="card-body">
			<div class="row mb-3">
				<div class="col-md-4 col-xl-3">
					<ul class="list-group document-types-list-group mb-3">
						<a href="#"
						   :class="{
														'list-group-item': 1,
														'list-group-item-action': 1,
														'list-group-item-action-active': typeof activeDocumentType !== null && activeDocumentType !== 'object' && activeDocumentType === 'forms',
														'd-flex': 1,
														'justify-content-between': 1,
														'align-items-start': 1}"
						   v-if="!installer && projectDataStore.can('documents', 'forms')"
						   @click.prevent="activeDocumentType = 'forms'">
							<div class="ms-2 me-auto">
								<span class="list-group-item-border" style="background:#34c38f"></span>
								<i class="me-2 far fa-ballot-check"></i>Űrlapok
							</div>
							<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('forms')">{{ getDocumentTypeCount('forms') }}</span>
						</a>
						<a href="#"
						   :class="{
														'list-group-item': 1,
														'list-group-item-action': 1,
														'list-group-item-action-active': typeof activeDocumentType !== null && activeDocumentType !== 'object' && activeDocumentType === 'documentBundles',
														'd-flex': 1,
														'justify-content-between': 1,
														'align-items-start': 1}"
						   v-if="!installer && projectDataStore.can('documents', 'bundles')"
						   @click.prevent="activeDocumentType = 'documentBundles'">
							<div class="ms-2 me-auto">
								<span class="list-group-item-border" style="background:#34c38f"></span>
								<i class="me-2 far fa-box-check"></i>Dokumentum csomagok
							</div>
							<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('documentBundles')">{{ getDocumentTypeCount('documentBundles') }}</span>
						</a>
						<template v-for="documentType in availableDocumentTypes">
							<a href="#"
							   :class="{
															'list-group-item': 1,
															'list-group-item-action': 1,
															'list-group-item-action-active': activeDocumentType !== null && typeof activeDocumentType === 'object' && activeDocumentType.id === documentType.id,
															'd-flex': 1,
															'justify-content-between': 1,
															'align-items-start': 1}"
							   @click.prevent="activeDocumentType = documentType">
								<div class="ms-2 me-auto">
									<span class="list-group-item-border" :style="'background:' + (documentType.color || '#eff2f7') + ';'"></span>
									<i :class="'me-2 far fa-' + documentType.icon" v-if="documentType.icon"></i>{{ documentType.name }}
								</div>
								<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount(documentType)">{{ getDocumentTypeCount(documentType) }}</span>
							</a>
						</template>
						<a href="#"
						   :class="{
														'list-group-item': 1,
														'list-group-item-action': 1,
														'list-group-item-action-active': activeDocumentType !== null && typeof activeDocumentType !== 'object' && activeDocumentType === 'quotes',
														'd-flex': 1,
														'justify-content-between': 1,
														'align-items-start': 1}"
						   v-if="!installer && projectDataStore.can('documents', 'system')"
						   @click.prevent="activeDocumentType = 'quotes'">
							<div class="ms-2 me-auto">
								<span class="list-group-item-border" style="background:#f1b44c"></span>
								<i class="me-2 far fa-file-invoice-dollar"></i>Árajánlatok
							</div>
							<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('quotes')">{{ getDocumentTypeCount('quotes') }}</span>
						</a>
						<a href="#"
						   :class="{
														'list-group-item': 1,
														'list-group-item-action': 1,
														'list-group-item-action-active': activeDocumentType !== null && typeof activeDocumentType !== 'object' && activeDocumentType === 'contracts',
														'd-flex': 1,
														'justify-content-between': 1,
														'align-items-start': 1}"
						   v-if="!installer && projectDataStore.can('documents', 'system')"
						   @click.prevent="activeDocumentType = 'contracts'">
							<div class="ms-2 me-auto">
								<span class="list-group-item-border" style="background:#f1b44c"></span>
								<i class="me-2 far fa-file-contract"></i>Szerződések
							</div>
							<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('contracts')">{{ getDocumentTypeCount('contracts') }}</span>
						</a>
						<a href="#"
						   :class="{
														'list-group-item': 1,
														'list-group-item-action': 1,
														'list-group-item-action-active': activeDocumentType !== null && typeof activeDocumentType !== 'object' && activeDocumentType === 'invoices',
														'd-flex': 1,
														'justify-content-between': 1,
														'align-items-start': 1}"
						   v-if="!installer && projectDataStore.can('documents', 'system')"
						   @click.prevent="activeDocumentType = 'invoices'">
							<div class="ms-2 me-auto">
								<span class="list-group-item-border" style="background:#f1b44c"></span>
								<i class="me-2 far fa-file-invoice"></i>Számlák
							</div>
							<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('invoices')">{{ getDocumentTypeCount('invoices') }}</span>
						</a>
						<a href="#"
						   :class="{
														'list-group-item': 1,
														'list-group-item-action': 1,
														'list-group-item-action-active': activeDocumentType !== null && typeof activeDocumentType !== 'object' && activeDocumentType === 'shipments',
														'd-flex': 1,
														'justify-content-between': 1,
														'align-items-start': 1}"
						   v-if="!installer && projectDataStore.can('documents', 'system')"
						   @click.prevent="activeDocumentType = 'shipments'">
							<div class="ms-2 me-auto">
								<span class="list-group-item-border" style="background:#f1b44c"></span>
								<i class="me-2 far fa-truck"></i>Szállítmányok
							</div>
							<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('shipments')">{{ getDocumentTypeCount('shipments') }}</span>
						</a>
					</ul>
				</div>
				<div class="col-md-8 col-xl-9" v-if="activeDocumentType !== null">
					<template v-if="typeof activeDocumentType === 'object'">
						<h4 class="mb-2">{{ activeDocumentType.name }}</h4>
						<ProjectFormDocumentCategory
							:editable="editable && (!installer || activeDocumentType.installation > 0)"
							:creating-enabled="projectDataStore.project.editable && editable && projectDataStore.can('documents', 'create')"
							:document-type="activeDocumentType"
							:errors="errors"
							@valueChanged="$emit('valueChanged')"
						></ProjectFormDocumentCategory>
					</template>
					<template v-else>
						<template v-if="activeDocumentType === 'forms'">
							<h4 class="mb-2">Űrlapok</h4>
							<div class="bg-light card p-2 d-flex gap-2 flex-wrap flex-row justify-content-start" v-if="
							projectDataStore.forms.length && editable && app.hasPermission('ProjectsController_canGenerateForm')">

								<div class="dropdown" v-for="formCategory in projectDataStore.forms">
									<button type="button" class="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" :id="'formCategoryDropdown_' + formCategory.id">
										{{ formCategory.name }} <i class="far fa-angle-down"></i>
									</button>

									<ul class="dropdown-menu" :aria-labelledby="'formCategoryDropdown_' + formCategory.id">
										<li v-for="form in formCategory.forms">
											<a class="dropdown-item" href="#" @click.prevent="openFormWindow(form)">
												{{ form.name }}
											</a>
										</li>
									</ul>
								</div>
							</div>
							<template v-if="projectDataStore.generatedForms !== undefined">
								<template v-for="(form, index) in projectDataStore.generatedForms" :key="'generatedForm_' + form.id">
									<div class="card border shadow-none mb-2 file-card">
										<div class="file-card-content">
											<div class="file-card--thumbnail">
												<div class="file-icon">
													<FileIcon :filename="form.file.extension"></FileIcon>
												</div>

												<div class="mt-2 d-flex gap-2" v-if="app.hasPermission('ProjectsController_canDeleteForm') && editable">
													<background-action-button @call="deleteGeneratedForm($event, form)" v-slot="scope" confirm-message="Biztos, hogy törölni akarod?">
														<button type="button" @click.prevent="scope.click" class="btn btn-danger btn-label"><i class="far fa-times label-icon"></i> Törlés</button>
													</background-action-button>
												</div>
											</div>
											<div class="file-card--details">
												<div class="filename fw-bold">
													<a :href="form.file.download_url" target="_blank" :title="form.file.name + '.' + form.file.extension"><i class="far fa-download"></i> {{ form.file.name }}</a>
												</div>
												<div class="d-flex flex-wrap gap-2">
													<attribute icon="far fa-file">
														<file-size :value="form.file.size"/>
													</attribute>
													<attribute icon="far fa-calendar-alt">
														<date-time :value="form.file.created_at"/>
													</attribute>
													<attribute icon="far fa-user" :content="form.file.created_by_user_name" />
													<attribute icon="fas fa-ballot-check" :content="form.formName" />
												</div>
											</div>
										</div>
									</div>
								</template>
							</template>
						</template>
						<template v-else-if="activeDocumentType === 'documentBundles'">
							<h4 class="mb-2">Dokumentum csomagok</h4>
							<div class="bg-light card p-2 d-flex gap-2 flex-wrap flex-row justify-content-start" v-if="projectDataStore.documentBundles !== undefined && projectDataStore.documentBundles.length && editable && app.hasPermission('ProjectsController_canGenerateDocumentBundle')">
								<button type="button" class="btn btn-secondary btn-label" @click.prevent="createDocumentBundle(documentBundle)" v-for="documentBundle in projectDataStore.documentBundles"><i class="far fa-plus label-icon"></i> {{ documentBundle.name }}</button>
							</div>
							<template v-if="projectDataStore.generatedDocumentBundles !== undefined">
								<div :class="{
									'card': 1,
									'border': 1,
									'shadow-none': 1,
									'card-body': 1,
									'bg-soft': 1,
									'bg-primary': projectDataStore.activeTabCommand === 'documentBundles' && parseInt(projectDataStore.activeTabParameter) === documentBundle.id}"
									 v-for="(documentBundle, index) in projectDataStore.generatedDocumentBundles" :key="'documentBundle_' + documentBundle.id">
									<h4 class="card-title mb-1">{{ documentBundle.bundleName }}</h4>
									<p class="mb-0"><i class="far fa-hashtag"></i> {{ documentBundle.public_id }}</p>
									<p class="mb-0"><i class="far fa-clock"></i> <date-time :value="documentBundle.createdAt"/></p>
									<p class="mb-0" v-if="documentBundle.createdByUserName"><i class="far fa-user"></i> {{ documentBundle.createdByUserName }}</p>
									<p class="mb-0 text-success" v-if="documentBundle.approvedAt"><i class="far fa-check"></i> Jóváhagyva: <date-time :value="documentBundle.approvedAt"/>
										<span v-if="documentBundle.approvedByUserName" class="ms-2"><i class="far fa-user"></i> {{ documentBundle.approvedByUserName }}</span>
									</p>
									<p class="mb-0 text-danger" v-else><i class="far fa-times"></i> Jóváhagyásra vár</p>

									<div class="d-flex gap-2 flex-wrap mt-2">
										<a :href="file.download_url" class="badge badge-xl bg-light" v-for="(file, fileIndex) in documentBundle.files" :key="fileIndex">
											{{ file.name + '.' + file.extension}} (<file-size :value="file.size"/>)
										</a>
									</div>
									<div class="d-flex gap-2 flex-wrap mt-2" v-if="editable">
										<background-action-button v-if="documentBundle.bundleSendEmail && documentBundle.approvedAt" @call="sendBundleEmail($event, documentBundle)" v-slot="scope">
											<button type="button" @click.prevent="scope.click" class="btn btn-warning btn-label"><i class="far fa-envelope label-icon"></i> E-mail újraküldése</button>
										</background-action-button>
										<background-action-button v-if="app.hasPermission('ProjectsController_canApproveDocumentBundle') && !documentBundle.approvedAt && editable" @call="approveBundle($event, documentBundle)" v-slot="scope">
											<button type="button" @click.prevent="scope.click" class="btn btn-success btn-label"><i class="far fa-check label-icon"></i> Jóváhagy</button>
										</background-action-button>
										<background-action-button v-if="app.hasPermission('ProjectsController_canDeleteDocumentBundle') && editable" confirm-message="Biztos, hogy törölni akarod?" @call="deleteDocumentBundle($event, documentBundle)" v-slot="scope">
											<button type="button" @click.prevent="scope.click" class="btn btn-danger btn-label"><i class="far fa-times label-icon"></i> Törlés</button>
										</background-action-button>

									</div>
								</div>
							</template>
							<template v-for="bundle in projectDataStore.documentBundles">
								<div v-if="bundle.progress !== null"
									 :class="{
									'card': 1,
									'border': 1,
									'shadow-none': 1,
									'card-body': 1,
									'bg-soft': 1}">
									<h4 class="card-title mb-2">{{ bundle.name }}</h4>
									<div class="fw-bold mb-1">Triggerek</div>
									<div class="d-flex gap-2 flex-wrap">
										<color-badge
											v-for="trigger in bundle.progress.triggers"
											:color="trigger.completed ? 'success' : 'light'"
											:icon="'far fa-' + (trigger.completed ? 'check' : 'hourglass')"
											>
											<template v-if="trigger.form_id && projectDataStore.getFormById(trigger.form_id)">
												Űrlap: {{ projectDataStore.getFormById(trigger.form_id).name }}
											</template>
											<template v-else-if="trigger.project_type_milestone_id && projectDataStore.getMilestoneById(trigger.project_type_milestone_id)">
												Mérföldkő: {{ projectDataStore.getMilestoneById(trigger.project_type_milestone_id).name }}
											</template>
											<template v-else-if="trigger.project_type_document_type_id && projectDataStore.getDocumentTypeById(trigger.project_type_document_type_id)">
												Dokumentum: {{ projectDataStore.getDocumentTypeById(trigger.project_type_document_type_id).name }} ({{ trigger.progress[0] }} / {{ trigger.progress[1] }})
											</template>
											<template v-else-if="trigger.special === 'inventoryShipmentGenerated'">
												Szállítólevél generálva
											</template>
											<template v-else-if="trigger.special === 'installationPlanned'">
												Telepítés kitűzve
											</template>
											<template v-else>
												Ismeretlen trigger
											</template>
										</color-badge>
									</div>
									<div class="mt-2" v-if="bundle.progress.requiresProduct">
										<div class="fw-bold mb-1">Termékek</div>

										<div v-if="bundle.progress.selectedContractId">
											<h5>Kiválasztott szerződés: {{ bundle.progress.selectedContract }}</h5>
										</div>
										<div v-else-if="bundle.progress.selectedQuoteId">
											<h5>Kiválasztott árajánlat: {{ bundle.progress.selectedQuote }}</h5>
										</div>
										<div v-else>
											<h5 class="text-danger mb-2">Árajánlat vagy szerződés kiválasztása szükséges!</h5>
											<button type="button" class="btn btn-primary btn-label" @click.prevent="openBundleSettingsWindow(bundle)" v-if="editable">
												<i class="far fa-file-check label-icon"></i> Kiválasztás
											</button>
										</div>
									</div>
									<div class="mt-2" v-if="bundle.progress.error !== null">
										<div class="fw-bold mb-1">Hiba</div>
										<div class="text-danger">{{ bundle.progress.error }}</div>
									</div>
								</div>
							</template>
						</template>
						<template v-else>
							<h4 class="mb-2" v-if="activeDocumentType === 'quotes'">Árajánlatok</h4>
							<h4 class="mb-2" v-if="activeDocumentType === 'contracts'">Szerződések</h4>
							<h4 class="mb-2" v-if="activeDocumentType === 'invoices'">Számlák</h4>
							<h4 class="mb-2" v-if="activeDocumentType === 'shipments'">Szállítmányok</h4>
							<template v-if="projectDataStore.systemDocuments !== undefined">
								<template v-for="(file, index) in projectDataStore.systemDocuments[activeDocumentType]" :key="'systemDocument_' + file.id">
									<div class="card border shadow-none mb-2 file-card">
										<div class="file-card-content">
											<div class="file-card--thumbnail">
												<div class="file-icon">
													<FileIcon :filename="file.name + '.' + file.extension"></FileIcon>
												</div>
											</div>
											<div class="file-card--details">
												<div class="filename fw-bold">
													<a :href="file.download_url" target="_blank" :title="file.name + '.' + file.extension"><i class="far fa-download"></i> {{file.name}}</a>
												</div>
												<div class="d-flex flex-wrap gap-2">
													<attribute icon="far fa-file">
														<file-size :value="file.size"/>
													</attribute>
													<attribute v-if="file.width" icon="far fa-image" :content="file.width + ' x ' + file.height + ' px'" />
													<attribute icon="far fa-calendar-alt">
														<date-time :value="file.created_at"/>
													</attribute>
													<attribute icon="far fa-user" :content="file.created_by_user_name" />
												</div>
											</div>
										</div>
									</div>
								</template>
							</template>
						</template>
					</template>
				</div>
			</div>
		</div>
	</div>
	<teleport to="body" v-if="formOverlayOpen">
		<FormOverlay
			:form="overlayForm"
			:generated-form-id="overlayGeneratedFormId"
			@close="closeOverlay"
			@close-and-update="closeAndUpdate"
			></FormOverlay>
	</teleport>
	<teleport to="body" v-if="bundleSettingsOverlayOpen">
		<BundleSettingsOverlay
			:bundle="bundleSettingsBundle"
			@close="closeOverlay"
			@close-and-update="closeAndUpdate"
			></BundleSettingsOverlay>
	</teleport>
</template>

<script>
import ProjectFormDocumentCategory from "./ProjectFormDocumentCategory.vue";
import FileIcon from "../../components/FileIcon.vue";
import QuoteResponseForm from "./QuoteResponseForm.vue";
import FormOverlay from "./FormOverlay.vue";
import BackgroundActionButton from "../../components/BackgroundActionButton.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import {useProjectDataStore} from "../../stores/projectData";
import {useApplicationStore} from "../../stores/applicationStore";
import FileSize from "../../components/FileSize.vue";
import DateTime from "../../components/DateTime.vue";
import Attribute from "../../components/Attribute.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import SelectField from "../../components/form/SelectField.vue";
import {Link} from "@inertiajs/vue3";
import BundleSettingsOverlay from "./BundleSettingsOverlay.vue";

export default {
	components: {
		BundleSettingsOverlay,
		SelectField,
		ColorBadge,
		Attribute,
		DateTime,
		FileSize,
		BackgroundActionButton,
		FormOverlay,
		QuoteResponseForm,
		FileIcon,
		Link,
		ProjectFormDocumentCategory,
	},
	emits: ['valueChanged', 'updateBadgeCount', 'loadData'],
	props: {
		errors: Object,
		project: Object,
		editable: Boolean,
		active: Boolean,
		userType: String,
		installer: Boolean,
	},
	computed: {
		availableDocumentTypes: {
			get() {
				if (!this.installer || this.projectDataStore.can('documents', 'read')){
					return this.projectDataStore.projectType.document_types
				}
				let payload = []
				_.forEach(this.projectDataStore.projectType.document_types, function (item){
					if (item.installation || item.installer_can_view){
						payload.push(item)
					}
				})

				return payload
			}
		}
	},
	mounted() {
		if (this.projectDataStore.activeTabCommand !== null && this.projectDataStore.activeTabCommand){
			this.activeDocumentType = this.projectDataStore.activeTabCommand

			for (let i = 0; i < this.availableDocumentTypes.length; i++) {
				if (this.activeDocumentType === this.availableDocumentTypes[i].id) {
					this.activeDocumentType = this.availableDocumentTypes[i]
				}
			}
		} else {
			for (let i = 0; i < this.availableDocumentTypes.length; i++) {
				if (this.activeDocumentType === null) {
					this.activeDocumentType = this.availableDocumentTypes[i]
				}
				this.projectDataStore.uploadedFiles[this.availableDocumentTypes[i].id] = []
			}
			if (this.activeDocumentType === null) {
				this.activeDocumentType = 'quotes'
			}
		}

		if (!this.projectDataStore.documentsLoaded) {
			this.projectDataStore.loadDocuments()
		}
	},
	data() {
		return {
			activeDocumentType: null,
			loaded: false,
			loading: false,
			formOverlayOpen: false,
			overlayForm: null,
			bundleSettingsBundle: false,
			bundleSettingsOverlayOpen: false,
			projectDataStore: useProjectDataStore(),
			flashStore: useFlashStoreStore(),
			app: useApplicationStore(),
			overlayGeneratedFormId: null,
		}
	},
	methods: {
		getDocumentTypeCount: function(type){
			try {
				if (type === null) {
					return 0
				}
				if (typeof type === 'object' && ((this.projectDataStore.documents[type.id] !== undefined) || this.projectDataStore.uploadedFiles[type.id] !== undefined)) {
					return ((this.projectDataStore.documents[type.id] !== undefined) ? this.projectDataStore.documents[type.id].length : 0) +
						(this.projectDataStore.uploadedFiles[type.id] !== undefined ? this.projectDataStore.uploadedFiles[type.id].length : 0)
				}

				return this.getSystemDocuments(type).length
			} catch (e){
			}
		},
		getSystemDocuments: function(type){
			if (type === 'forms'){
				return this.projectDataStore.generatedForms
			}
			if (this.projectDataStore.systemDocuments !== undefined && this.projectDataStore.systemDocuments[type] !== undefined){
				return this.projectDataStore.systemDocuments[type]
			}

			return []
		},
		openBundleSettingsWindow: function (bundle){
			this.bundleSettingsBundle = bundle
			this.bundleSettingsOverlayOpen = true
		},
		openFormWindow: function (form, generatedFormId){
			this.overlayForm = form
			this.overlayGeneratedFormId = generatedFormId | null
			this.formOverlayOpen = true
		},
		closeOverlay: function (){
			this.formOverlayOpen = false
			this.bundleSettingsOverlayOpen = false
			this.overlayForm = null
			this.bundleSettingsBundle = null
			this.overlayGeneratedFormId = null
		},
		closeAndUpdate: function (){
			this.closeOverlay()
			this.projectDataStore.loadDocuments()
		},
		createDocumentBundle: function (documentBundle){
			axios.post('/projects/' + this.projectDataStore.projectId + '/generateDocumentBundle/' + documentBundle.id).then((response) => {
				if (response.data.success){
					this.projectDataStore.loadDocuments()
				}
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
			}).catch((error) => {
				this.flashStore.addFlash({
					level: 'danger',
					message: error.response !== undefined ? error.response.data.message : error
				})
			})
		},
		approveBundle: function (clear, bundle){
			axios.put('/projects/approveGeneratedDocumentBundle/' + bundle.id).then((response) => {
				if (response.data.success){
					this.projectDataStore.loadDocuments()
				}
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				clear()
			}).catch((error) => {
				this.flashStore.addFlash({
					level: 'danger',
					message: error.response !== undefined ? error.response.data.message : error
				})
				clear()
			})
		},
		sendBundleEmail: function (clear, bundle) {
			axios.put('/projects/sendGeneratedDocumentBundleEmail/' + bundle.id).then((response) => {
				if (response.data.success){
					this.projectDataStore.loadDocuments()
				}
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				clear()
			}).catch((error) => {
				this.flashStore.addFlash({
					level: 'danger',
					message: error.response !== undefined ? error.response.data.message : error
				})
				clear()
			})
		},
		deleteGeneratedForm: function (clear, form) {
			axios.delete('/projects/deleteGeneratedForm/' + form.id).then((response) => {
				if (response.data.success){
					this.projectDataStore.loadDocuments()
				}
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				clear()
			}).catch((error) => {
				this.flashStore.addFlash({
					level: 'danger',
					message: error.response !== undefined ? error.response.data.message : error
				})
				clear()
			})
		},
		deleteDocumentBundle: function (clear, bundle) {
			axios.delete('/projects/deleteGeneratedDocumentBundle/' + bundle.id).then((response) => {
				if (response.data.success){
					this.projectDataStore.loadDocuments()
				}
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				clear()
			}).catch((error) => {
				this.flashStore.addFlash({
					level: 'danger',
					message: error.response !== undefined ? error.response.data.message : error
				})
				clear()
			})
		},
	}
}
</script>

<style scoped>

</style>
