<template>
	<TitleBar title="Profilom"/>

	<form-wrapper
		v-model="client"
		:hidden-attributes="['created_by_user', 'locked_by']"
		:back-url="route('clientArea.dashboard')"
		:store-url="route('clientArea.profile') + '?action=saveClient'"
		@attributes-updated="setAttributes"
	>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">{{ $t('Belépési adatok') }}</h4>
					<div class="alert alert-danger mb-4">{{ $t('A csillaggal megjelölt mezők kitöltése kötelező!') }}</div>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								:label="$t('E-mail cím')"
								name="email"
								rules="required"
								data-type="email"
								v-model="client.email"
								:disabled="!client.editable"
								:errors="errors"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								:label="$t('Új jelszó')"
								name="password"
								data-type="password"
								rules="min:8"
								v-model="client.password"
								:disabled="!client.editable"
								:info="$t('Csak akkor töltsd ki, ha új jelszót akarsz beállítani')"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								:label="$t('Új jelszó újra')"
								name="password_confirmation"
								data-type="password"
								:equal-to="client.password"
								v-model="client.password_confirmation"
								:disabled="!client.editable"
								:info="$t('Csak akkor töltsd ki, ha új jelszót akarsz beállítani')"
								:errors="errors"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">{{ $t('Személyes adatok') }}</h4>
					<template v-if="!client.is_company">
						<div class="row">
							<div class="col-sm-6">
								<input-field
									:label="$t('Vezetéknév')"
									name="last_name"
									rules="required"
									v-model="client.last_name"
									:disabled="!client.editable"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									:label="$t('Keresztnév')"
									name="first_name"
									rules="required"
									v-model="client.first_name"
									:disabled="!client.editable"
									:errors="errors"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<phone-field
									:label="$t('Telefonszám')"
									name="phone"
									rules="required"
									v-model="client.phone"
									:disabled="!client.editable"
									:errors="errors"
								/>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="row">
							<div class="col-sm-4">
								<input-field
									:label="$t('Cégnév')"
									name="company_name"
									rules="required"
									v-model="client.company_name"
									:disabled="!client.editable"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-4">
								<input-field
									:label="$t('Adószám')"
									name="company_tax_number"
									rules=""
									v-model="client.company_tax_number"
									:disabled="!client.editable"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-4">
								<input-field
									:label="$t('Cégjegyzékszám')"
									name="company_id_number"
									rules=""
									v-model="client.company_id_number"
									:disabled="!client.editable"
									:errors="errors"
								/>
							</div>
						</div>
					</template>
				</div>
			</div>
			<div class="card mb-3" v-if="client.is_company">
				<div class="card-body">
					<h4 class="card-title mb-4">{{ $t('Cégképviseletre jogosult személy') }}</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								:label="$t('Vezetéknév')"
								name="last_name"
								rules="required"
								v-model="client.last_name"
								:disabled="!client.editable"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								:label="$t('Keresztnév')"
								name="first_name"
								rules="required"
								v-model="client.first_name"
								:disabled="!client.editable"
								:errors="errors"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6">
							<phone-field
								:label="$t('Telefonszám')"
								name="phone"
								rules="required"
								v-model="client.phone"
								:disabled="!client.editable"
								:errors="errors"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import ClientAreaLayout from "../layouts/ClientAreaLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import PhoneField from "../components/form/PhoneField.vue";

export default {
	components: {PhoneField, InputField, FormWrapper, TitleBar},
	layout: ClientAreaLayout,
	props: {
		model: Object
	},
	data(){
		let client = this.model

		if (client.address === null){
			client.address = {}
		}
		if (client.mail_address === null){
			client.mail_address = {}
		}
		return {
			client: client,
			flash: [],
		}
	},
	mounted() {
		this.setAttributes()
	},
	methods: {
		setAttributes: function(){
			if (this.client.address === undefined || this.client.address === null){
				this.client.address = {}
			}
			if (this.client.mail_address === undefined || this.client.mail_address === null){
				this.client.mail_address = {}
			}
		}
	}
}
</script>
