<template>
	<teleport to="body">
		<div :class="{modal: 1, fade: 1, show: 1}" :style="'display:block;z-index:' + zIndex" @click="clickedOnBackdrop">
			<div :class="{'modal-dialog': 1, 'modal-xl': fullWidth, 'modal-md': wide}">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{ title }}</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Bezár" v-if="closable" @click.prevent="$emit('close')"></button>
					</div>
					<template v-if="(!loading || loadingKeepsContent) && visible">
						<div class="modal-footer modal-footer-top" v-if="$slots.buttons && buttonsOnTop">
							<slot name="buttons"></slot>
						</div>
						<div :class="{'modal-body': 1, 'loading': loading, 'loading--light': loading}">
							<slot></slot>
						</div>
						<div class="modal-footer" v-if="$slots.buttons && !buttonsOnTop">
							<slot name="buttons"></slot>
						</div>
					</template>
					<div class="modal-body modal-loading" v-else></div>
				</div>
			</div>
		</div>
		<div class="modal-backdrop fade show" :style="'display:block;z-index:' + (zIndex - 1)"></div>
	</teleport>
</template>

<script>
import {useGeneralDataStore} from "../stores/generalData";

export default {
	emits: ['close', 'dataLoaded'],
	props: {
		fullWidth: Boolean,
		wide: Boolean,
		closable: Boolean,
		title: String,
		loading: Boolean,
		loadingKeepsContent: Boolean,
		buttonsOnTop: Boolean,
		requiredData: {
			type: Array,
			default: function () {
				return []
			}
		},
		zIndex: {
			type: Number,
			default: 2000
		}
	},
	mounted() {
		if (this.closable) {
			window.addEventListener('keydown', this.keydownListener)
		}
		if (this.requiredData.length){
			this.dataStore.loadData(this.requiredData, () => {
				this.visible = true
				this.$emit('dataLoaded')
			})
		} else {
			this.visible = true
			this.$emit('dataLoaded')
		}
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.keyDownListener)
	},
	data(){
		return {
			keydownListener: null,
			visible: false,
			dataStore: useGeneralDataStore()
		}
	},
	methods: {
		clickedOnBackdrop: function (e){
			if (e.target.classList.contains('modal') && this.closable){
				this.$emit('close')
			}
		},
		keyDownListener: function (e){
			if (e.key === 'Escape') {
				window.removeEventListener('keydown', this.keyDownListener)
				this.$emit('close')
			}
		}
	}
}
</script>

<style scoped>

</style>
