<template>
	{{ formattedValue }}
</template>

<script>
export default {
	props: {
		value: Number
	},
	computed: {
		formattedValue: {
			get() {
				const size = this.value
				if (size > 1024 * 1024 * 1024 * 1024) {
					return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
				} else if (size > 1024 * 1024 * 1024) {
					return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
				} else if (size > 1024 * 1024) {
					return (size / 1024 / 1024).toFixed(2) + ' MB'
				} else if (size > 1024) {
					return (size / 1024).toFixed(2) + ' KB'
				}
				return size.toString() + ' B'
			}
		}
	}
}
</script>
