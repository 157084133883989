<template>
	<TitleBar title="Feladat" />
	<form-wrapper
		ref="formWrapper"
		:store-url="'/tasks/answer/' + attributes.id"
		back-url="/tasks"
		v-model="attributes"
		:disable-submit="!attributes.editable"
	>
		<template v-slot:buttons>
			<Link :href="attributes.url" v-if="attributes.type !== 'personal'" class="btn btn-label btn-secondary"><i class="far fa-external-link label-icon"></i> Link követése</Link>
			<button type="button" @click.prevent="showTransferTaskForm = true" v-if="attributes.transferable" class="btn btn-label btn-warning"><i class="far fa-random label-icon"></i> Feladat átadása</button>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="row">
				<div class="col-md-6">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Feladat adatok</h4>

							<div class="mb-3">
								<label>Típus</label>
								<div>
									<color-badge :value="attributes"></color-badge>
								</div>
							</div>
							<div class="mb-3">
								<label>Állapot</label>
								<div>
									<color-badge
										:content="attributes.completed_at ? 'Teljesítve' : 'Folyamatban'"
										:color="attributes.completed_at ? 'success' : 'danger'"
										:icon="attributes.completed_at ? 'far fa-check' : 'far fa-hourglass'"
									></color-badge>
								</div>
							</div>
							<div class="mb-3" v-if="attributes.transfer_to_user_id">
								<label>Átadás</label>
								<div>
									<template v-if="attributes.transfer_to_user_id">
										<color-badge v-if="attributes.assigned_to_user_id === app.userId" color="light" icon="far fa-random" small>
											Átadás alatt:
											<user-badge :user-id="attributes.transfer_to_user_id"/>
										</color-badge>
										<template v-if="attributes.transfer_to_user_id === app.userId">
											<color-badge color="light" icon="far fa-random" small>
												Átadási kérés:
												<user-badge :user-id="attributes.assigned_to_user_id"/>
											</color-badge>

											<div class="mt-2 d-flex gap-2 p-3 bg-light">
												<Link :href="route('tasks.acceptTransfer', attributes.id)" class="btn btn-success btn-label">
													<i class="far fa-arrow-to-right label-icon"></i> Átadás elfogadása
												</Link>
												<Link :href="route('tasks.denyTransfer', attributes.id)" class="btn btn-danger btn-label">
													<i class="far fa-arrow-left label-icon"></i> Átadás elutasítása
												</Link>
											</div>
										</template>
									</template>
								</div>
							</div>
							<div class="mb-3">
								<label>Létrehozva</label>
								<div><DateTime :value="attributes.created_at"/></div>
							</div>
							<div class="mb-3" v-if="attributes.completed_at">
								<label>Teljesítve</label>
								<div><DateTime :value="attributes.completed_at"/></div>
							</div>
							<div class="mb-3" v-if="attributes.created_by_user_id">
								<label>Létrehozó</label>
								<div><user-badge :user-id="attributes.created_by_user_id"/></div>
							</div>
							<div class="mb-3" v-if="attributes.type === 'project'">
								<label>Projekt</label>
								<div><Link :href="attributes.url">{{ attributes.details_as_string }}</Link></div>
							</div>
							<div class="mb-3">
								<label>Cím</label>
								<div>{{ attributes.title }}</div>
							</div>
							<div class="mb-3" v-if="attributes.deadline">
								<label>Határidő</label>
								<div><DateTime :value="attributes.deadline" :time="false"/></div>
							</div>
							<div class="mb-3" v-if="attributes.description">
								<label>Leírás</label>
								<div v-html="attributes.description_formatted"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Válasz</h4>

							<template v-if="attributes.completed || !attributes.editable">
								<div v-html="attributes.result_formatted"></div>
							</template>
							<template v-else>
								<switch-field
									label="Elkészült"
									name="setCompleted"
									v-model="attributes.setCompleted"
								></switch-field>

								<textarea-field
									label="Leírás"
									name="result"
									v-model="attributes.result"
									:errors="errors"
								></textarea-field>
							</template>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
	<modal
		title="Feladat átadása más felhasználónak"
		v-if="showTransferTaskForm"
		:closable="true"
		@close="showTransferTaskForm = false">
		<template #default>
			<form-wrapper
				:show-top-buttons="false"
				:show-bottom-buttons="false"
				v-model="transferAttributes"
				:store-url="'/tasks/transfer/' + attributes.id"
				:no-margin="true"
				ref="taskTransferForm"
			>
				<template #default="{activeTab, errors, editable}">
					<user-select-field
						label="Felhasználó"
						v-model="transferAttributes.transfer_to_user_id"
						name="transfer_to_user_id"
						:errors="errors"
						:excluded-id="app.userId"
						rules="required"
						/>
					<button type="submit" ref="taskTransferFormSubmitButton" class="d-none">submit</button>
				</template>
			</form-wrapper>
		</template>

		<template #buttons>
			<button type="button" class="btn btn-primary btn-label waves-effect" @click.prevent="$refs.taskTransferFormSubmitButton.click()">
				<i class="fas fa-random label-icon"></i> Átadás
			</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="$emit('close')">
				<i class="fas fa-times label-icon"></i> Bezár
			</button>
		</template>
	</modal>
</template>

<script>
import FormWrapper from "../components/FormWrapper.vue";
import TextareaField from "../components/form/TextareaField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import ColorBadge from "../components/ColorBadge.vue";
import AppLayout from "../layouts/AppLayout.vue";
import DateTime from "../components/DateTime.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import TitleBar from "../components/TitleBar.vue";
import {Link} from "@inertiajs/vue3";
import Modal from "../components/Modal.vue";
import UserSelectField from "../components/form/UserSelectField.vue";
import {useApplicationStore} from "../stores/applicationStore";

export default {
	layout: AppLayout,
	components: {
		UserSelectField,
		Modal, TitleBar, UserBadge, DateTime, ColorBadge, SwitchField, TextareaField, FormWrapper, Link},
	props: {
		model: Object
	},
	data(){
		return {
			attributes: this.model,
			showTransferTaskForm: false,
			app: useApplicationStore(),
			transferAttributes: {
				transfer_to_user_id: null
			}
		}
	},
	methods: {
	}
}
</script>
