<template>
	<template v-if="!item.closed_at">
		<color-badge
			v-if="!item.installation_date"
			color="danger"
			content="Nincs"
			label="Telepítés dátuma"
		/>
		<color-badge
			v-else-if="Date(item.installation_date) > Date()"
			color="warning"
			:content="item.installation_date"
			label="Telepítés dátuma"
		/>
		<color-badge
			v-else
			color="success"
			:content="item.installation_date"
			label="Telepítés dátuma"
		/>
	</template>
	<template v-else>
		<color-badge
			color="success"
			:content="!item.installation_date ? 'Telepítve' : item.installation_date"
			label="Telepítés dátuma"
			/>
	</template>
</template>

<script>
import ColorBadge from "../../components/ColorBadge.vue";

export default {
	components: {ColorBadge},
	props: {
		item: Object
	}
}
</script>
