<template>
	<TitleBar :title="model.id !== undefined ? 'Dokumentum szerkesztése' : 'Dokumentum létrehozása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('documents.store', model.id)"
		:back-url="route('documents.index')"
		v-model="attributes"
	>
		<template #default="{activeTab, errors}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Megnevezés"
								name="name"
								rules="required"
								v-model="attributes.name"
								:errors="errors"
							/>

							<input-field
								label="Prioritás"
								name="priority"
								rules="required"
								data-type="number"
								:step="1"
								:min="1"
								v-model="attributes.priority"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<select-field
								label="Kategória"
								name="document_category_id"
								rules="required"
								v-model="attributes.document_category_id"
								:options="categories"
								:errors="errors"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">File</h4>
					<FileUploader
						field-name="file"
						v-model="attributes.file"
						:multiple="false"
						:editable="true"
					></FileUploader>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Leírás</h4>
					<textarea rows="6" v-model="attributes.description" class="form-control"></textarea>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import SelectField from "../components/form/SelectField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import FileUploader from "../components/FileUploader.vue";

export default {
	components: {FileUploader, InputField, FormWrapper, SelectField, TitleBar},
	layout: AppLayout,
	props: {
		model: Object,
		categories: Array
	},
	data(){
		let attributes = _.clone(this.model)
		attributes.file = attributes.file !== undefined && attributes.file !== null ? [attributes.file] : []
		return {
			attributes: attributes,
			loading: false,
			flash: [],
		}
	},
}
</script>
