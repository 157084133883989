<template>
	<div :class="{card: 1}">
		<div class="card-body">
			<div v-if="!project.roles.length" class="alert alert-info">Nincs hozzáadva jogosultság</div>
			<table class="table table-collapse-mobile" v-else>
				<thead>
				<tr>
					<th width="34%">Jogosultság</th>
					<th width="33%">Felhasználó</th>
					<th>Szerep</th>
					<th width="1%" v-if="project.can('roles', 'delete') && editable">&nbsp;</th>
				</tr>
				</thead>
				<tbody>
				<template v-for="(role, roleKey) in project.roles">
					<tr>
						<td>
							<v-select
								:options="project.projectType.roles"
								v-model="role.project_type_role_id"
								label="name"
								:reduce="item => item.id"
								:clearable="false"
								:disabled="!editable || !project.can('roles', 'update')"
							>
							</v-select>
						</td>
						<td>
							<user-select-field
								v-model="role.user_id"
								:field-only="true"
								label="Felhasználó"
								:name="'role_' + roleKey + '_user_id'"
								:disabled="!editable || !project.can('roles', 'update')"
							/>
						</td>
						<td>
							<role-select-field
								v-model="role.role_id"
								:field-only="true"
								label="Szerep"
								:name="'role_' + roleKey + '_role_id'"
								:disabled="!editable || !project.can('roles', 'update')"
							/>
						</td>
						<td v-if="project.can('roles', 'delete') && editable">
							<button type="button" class="btn btn-danger" @click.prevent="remove(roleKey)" title="Törlés"><i class="far fa-times"></i></button>
						</td>
					</tr>
				</template>
				</tbody>
			</table>

			<div class="mt-3" v-if="editable && project.can('roles', 'update')">
				<button type="button" class="btn btn-secondary btn-label" @click.prevent="add">
					<i class="far fa-plus label-icon"></i> Hozzáad
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import {useGeneralDataStore} from "../../stores/generalData";
import UserSelectField from "../../components/form/UserSelectField.vue";
import DataLoader from "../../components/DataLoader.vue";
import {useProjectDataStore} from "../../stores/projectData";
import RoleSelectField from "../../components/form/RoleSelectField.vue";

export default {
	components: {RoleSelectField, DataLoader, UserSelectField, vSelect},
	emits: ['update:modelValue', 'loadData'],
	props: {
		modelValue: Array,
		editable: Boolean,
	},
	data(){
		return {
			loaded: false,
			loading: true,
			users: [],
			dataStore: useGeneralDataStore(),
			project: useProjectDataStore(),
		}
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	methods: {
		add: function(){
			this.project.roles.push({
				project_type_role_id: null,
				user_id: null,
				role_id: null,
			})
		},
		remove: function(key){
			this.project.roles.splice(key, 1)
		},

		reload: function (){
		},
	}
}
</script>

<style scoped>

</style>
