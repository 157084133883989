<template>
	<TitleBar :title="approval ? 'Jóváhagyásra váró felhasználók' : 'Felhasználólista'"/>

	<AdvancedDataTable
		:data-url="approval ? route('users.listAwaitingApproval') : route('users.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="users"
		show-tracked-edit-mode
	>
		<template #navigation>
			<ButtonGroup>
				<template #create v-if="app.hasPermission('UsersController_create')">
					<Link :href="route('users.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
				</template>
				<template #index v-if="approval">
					<Link :href="route('users.index')" class="btn btn-secondary btn-label"><i
						class="fas fa-angle-left label-icon"></i> Összes felhasználó
					</Link>
				</template>
				<template #awaitingApproval v-if="app.hasPermission('UsersController_canApproveUser') && !approval">
					<Link :href="route('users.awaitingApproval')"
						  class="btn btn-warning btn-label"><i class="fas fa-check label-icon"></i> Jóváhagyásra váró
						felhasználók
					</Link>
				</template>
			</ButtonGroup>
		</template>

		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<div class="mobile-horizontal">
							<data-loader :data="['userTypes']" v-slot="{ dataStore }">
								<ColorBadge :value="dataStore.getData('userTypes')[item.type]"></ColorBadge>
							</data-loader>
							<UserLevelBadge
								:user-level-id="item.user_level_id"
							/>
							<boolean-badge
								v-model="item.active"
								:key="'active_' + item.id"
								active
							/>
						</div>
						<div class="mobile-horizontal">
							<email-address :value="item.email"/>
							<phone-number :value="item.phone"/>
						</div>
						<div class="mobile-horizontal text">
							<attribute v-if="item.last_login" icon="far fa-clock" label="Utolsó belépés">
								<date-time :value="item.last_login"/>
							</attribute>
							<attribute v-if="item.commission" icon="far fa-coins" label="Jutalék">
								<Price :value="item.commission"/>
							</attribute>
						</div>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('users.update')"
					:delete-url="route('users.delete')"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
		<template #item-active="item">
			<boolean-badge
				v-model="item.active"
				:key="'active_' + item.id"
			/>
		</template>
		<template #item-last_login="item">
			<date-time :value="item.last_login"/>
		</template>
		<template #item-email="item">
			<email-address :value="item.email"/>
		</template>
		<template #item-phone="item">
			<phone-number :value="item.phone"/>
		</template>
		<template #item-level="item">
			<UserLevelBadge
				:user-level-id="item.user_level_id"
				:small="true"
				:short="true"
			/>
		</template>
		<template #item-commission="item">
			<Price
				:value="item.commission"
			/>
		</template>
		<template #item-type="item">
			<data-loader :data="['userTypes']" v-slot="{ dataStore }">
				<ColorBadge :value="dataStore.getData('userTypes')[item.type]" :small="true"></ColorBadge>
			</data-loader>
		</template>
	</AdvancedDataTable>
</template>

<script>
import Price from "../components/Price.vue";
import TableActionCell from "../components/TableActionCell.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import UserLevelBadge from "../components/UserLevelBadge.vue";
import ColorBadge from "../components/ColorBadge.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {Link} from "@inertiajs/vue3";
import DateTime from "../components/DateTime.vue";
import {useApplicationStore} from "../stores/applicationStore";
import TitleBar from "../components/TitleBar.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import ProjectTypeCell from "../modules/projects/ProjectTypeCell.vue";
import ClientCell from "../modules/projects/ClientCell.vue";
import ProjectMilestonesCell from "../modules/projects/ProjectMilestonesCell.vue";
import ProjectTableProjectCell from "../modules/projects/ProjectTableProjectCell.vue";
import EmailAddress from "../components/EmailAddress.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import Attribute from "../components/Attribute.vue";

export default {
	layout: AppLayout,
	components: {
		Attribute,
		PhoneNumber,
		EmailAddress,
		ProjectTableProjectCell, ProjectMilestonesCell, ClientCell, ProjectTypeCell,
		ButtonGroup,
		TitleBar,
		DateTime,
		ColorBadge, UserLevelBadge, DataLoader, AdvancedDataTable, BooleanBadge, TableActionCell, Price, Link
	},
	props: {
		showUserLevels: Boolean,
		showCommissions: Boolean,
		approval: Boolean,
	},
	data() {
		let columns = [
			{
				text: 'Aktív',
				value: 'active',
				sortable: true,
			}
		]

		columns.push({
			text: 'Név',
			value: 'name',
			sortable: true
		})
		columns.push({
			text: 'Típus',
			value: 'type',
			sortable: true
		})

		if (this.showUserLevels) {
			columns.push({
				text: 'Szint',
				value: 'level',
				sortable: true
			})
		}

		columns.push({
			text: 'E-mail cím',
			value: 'email',
			sortable: true
		})
		columns.push({
			text: 'Telefonszám',
			value: 'phone',
			sortable: true
		})
		columns.push({
			text: 'Utolsó belépés',
			value: 'last_login',
			sortable: true,
		})

		if (this.showCommissions) {
			columns.push({
				text: 'Jutalék',
				value: 'commission',
				sortable: true
			})
		}
		if (this.approval) {
			columns.push({
				text: 'Ajánló',
				value: 'referer',
				sortable: true
			})
		}

		columns.push({
			label: '',
			value: 'operations',
			width: 5,
		})

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
