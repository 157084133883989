<template>
	<TitleBar :title="client.id !== undefined ? 'Ügyfél módosítása' : 'Ügyfél hozzáadása'" />
	<form-wrapper
		v-model="client"
		:tabs="tabs"
		:tab-badges="tabBadges"
		:display-tabs="!!client.id"
		:hidden-attributes="['created_by_user', 'locked_by']"
		:back-url="client.id !== undefined ? route('clients.unlock', client.id) : route('clients.index')"
		:store-url="route('clients.store', client.id) + '?action=saveClient'"
		:update-lock-url="route('clients.store', client.id) + '?action=updateLock'"
		:lock-url="route('clients.store', client.id) + '?action=lock'"
		@attributes-updated="setAttributes"
		@lock-acquired="lockAcquired"
	>
		<template v-slot:buttons>
			<Link :href="loginAsClientUrl" v-if="loginAsClientUrl.length" class="btn btn-label btn-info"><i class="far fa-sign-in label-icon"></i> Belépés az ügyfélportálra</Link>
		</template>
		<template v-slot:top>
			<div class="card mb-3" v-if="client.id !== undefined">
				<div class="card-body">
					<div>
						<div class="details-box">
							<div>
								<i class="far fa-hashtag"></i> Azonosító: <strong>{{ client.public_id  }}</strong>
							</div>
							<div v-if="client.handler_user_id">
								<i class="far fa-user-tie"></i> Tanácsadó:
								<UserBadge :user-id="client.handler_user_id"></UserBadge>
							</div>
							<updated-attribute :data="client" created/>
							<updated-attribute :data="client" updated/>

							<div v-if="clientLoginUrl">
								<i class="far fa-sign-in"></i> Ügyfél belépés: <a :href="clientLoginUrl" target="_blank">[link]</a> <CopyToClipboard :value="clientLoginUrl" />
							</div>

							<inquiry-attribute v-if="inquiry" :inquiry-id="inquiry.id" :public-id="inquiry.public_id" :name="inquiry.name" />
						</div>
					</div>
					<LockMessage :lock="lock" :margin-top="true" :margin-bottom="false"></LockMessage>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div v-if="activeTab === 'form'">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Adatok</h4>
						<div class="row">
							<div class="col-sm-6">
								<radio-field
									label="Típus"
									v-model="client.is_company"
									:disabled="!editable"
									:values="typeValues"
									:hide-non-active="client.id !== undefined"
								/>
							</div>
							<div class="col-sm-6">
								<data-loader :data="['languages']" v-slot="{ dataStore }">
									<select-field
										name="language_id"
										label="Nyelv"
										:options="dataStore.getData('languages')"
										v-model="client.language_id"
										rules="required"
										:disabled="!editable"
										:errors="errors"
									/>
								</data-loader>
							</div>
						</div>
						<template v-if="!client.is_company">
							<div class="row">
								<div class="col-sm-6">
									<input-field
										label="Vezetéknév"
										name="last_name_pp"
										rules="required"
										v-model="client.last_name"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-6">
									<input-field
										label="Keresztnév"
										name="first_name_pp"
										rules="required"
										v-model="client.first_name"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<input-field
										label="E-mail cím"
										name="email_pp"
										rules="required"
										data-type="email"
										v-model="client.email"
										:disabled="!editable"
										:errors="errors"
									/>

									<data-loader :data="['projectTypes']" v-slot="{ dataStore }">
										<select-field
											name="project_type_id_pp"
											label="Elsődleges szolgáltatás"
											:options="dataStore.getData('projectTypes')"
											v-model="client.project_type_id"
											rules="required"
											:disabled="!editable"
											:errors="errors"
										/>
									</data-loader>
								</div>
								<div class="col-sm-6">
									<phone-field
										label="Telefonszám"
										name="phone_pp"
										rules="required"
										data-type="phone"
										v-model="client.phone"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<input-field
										label="Édesanyja neve"
										name="mothers_name"
										v-model="client.mothers_name"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<input-field
										label="Személyi igazolvány szám"
										name="personal_id_number"
										v-model="client.personal_id_number"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-6">
									<input-field
										label="Adóazonosító"
										name="personal_tax_number"
										v-model="client.personal_tax_number"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-4">
									<input-field
										label="Születési név"
										name="birth_name"
										v-model="client.birth_name"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-4">
									<input-field
										label="Születési hely"
										name="place_of_birth"
										v-model="client.place_of_birth"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-4">
									<date-field
										label="Születési idő"
										name="date_of_birth"
										v-model="client.date_of_birth"
										:disabled="!editable"
										:errors="errors"
										past
									/>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="row">
								<div class="col-sm-4">
									<input-field
										label="Cégnév"
										name="company_name_c"
										rules="required"
										v-model="client.company_name"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-4">
									<input-field
										label="Adószám"
										name="company_tax_number_c"
										v-model="client.company_tax_number"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-4">
									<input-field
										label="Cégjegyzékszám"
										name="company_id_number_c"
										v-model="client.company_id_number"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
							</div>
						</template>
						<switch-field
							label="Értesítések tiltása"
							name="disable_notifications"
							:disabled="!editable"
							v-model="client.disable_notifications"
						/>
					</div>
				</div>
				<div class="card mb-3" v-if="client.has_password">
					<div class="card-body">
						<h4 class="card-title mb-4">Belépés</h4>
						<div class="row">
							<div class="col-sm-6">
								<input-field
									label="Új jelszó"
									name="password"
									data-type="password"
									rules="min:8"
									v-model="client.password"
									:disabled="!editable"
									info="Csak akkor töltsd ki, ha új jelszót akarsz beállítani"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									label="Új jelszó újra"
									name="password_confirmation"
									data-type="password"
									:equal-to="client.password"
									v-model="client.password_confirmation"
									:disabled="!editable"
									info="Csak akkor töltsd ki, ha új jelszót akarsz beállítani"
									:errors="errors"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-if="editable || Object.keys(client.address).length">
					<div class="card-body">
						<h4 class="card-title mb-4">Cím</h4>
						<address-input
							v-model="client.address"
							attribute-name="address"
							:editable="editable"
							:errors="errors"
						/>
					</div>
				</div>
				<div class="card" v-if="editable || Object.keys(client.billing_address).length">
					<div class="card-body">
						<h4 class="card-title mb-4">Számlázási cím</h4>
						<address-input
							v-model="client.billing_address"
							attribute-name="billing_address"
							:editable="editable"
							:errors="errors"
						/>
					</div>
				</div>
				<div class="card mb-3" v-if="client.is_company">
					<div class="card-body">
						<h4 class="card-title mb-4">Cégképviseletre jogosult személy</h4>
						<div class="row">
							<div class="col-sm-6">
								<input-field
									label="Vezetéknév"
									name="last_name_c"
									rules="required"
									v-model="client.last_name"
									:disabled="!editable"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									label="Keresztnév"
									name="first_name_c"
									rules="required"
									v-model="client.first_name"
									:disabled="!editable"
									:errors="errors"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<input-field
									label="E-mail cím"
									name="email_c"
									data-type="email"
									rules="required"
									v-model="client.email"
									:disabled="!editable"
									:errors="errors"
								/>

								<data-loader :data="['projectTypes']" v-slot="{ dataStore }">
									<select-field
										name="project_type_id_c"
										label="Elsődleges szolgáltatás"
										:options="dataStore.getData('projectTypes')"
										v-model="client.project_type_id"
										rules="required"
										:disabled="!editable"
										:errors="errors"
									/>
								</data-loader>
							</div>
							<div class="col-sm-6">
								<phone-field
									label="Telefonszám"
									name="phone_c"
									rules="required"
									v-model="client.phone"
									:disabled="!editable"
									:errors="errors"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<input-field
									label="Édesanyja neve"
									name="mothers_name_c"
									v-model="client.mothers_name"
									:disabled="!editable"
									:errors="errors"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<input-field
									label="Személyi igazolvány szám"
									name="personal_id_number_c"
									v-model="client.personal_id_number"
									:disabled="!editable"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									label="Adóazonosító"
									name="personal_tax_number_c"
									v-model="client.personal_tax_number"
									:disabled="!editable"
									:errors="errors"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-4">
								<input-field
									label="Születési név"
									name="birth_name_c"
									v-model="client.birth_name"
									:disabled="!editable"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-4">
								<input-field
									label="Születési hely"
									name="place_of_birth_c"
									v-model="client.place_of_birth"
									:disabled="!editable"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-4">
								<date-field
									label="Születési idő"
									name="date_of_birth_c"
									v-model="client.date_of_birth"
									:disabled="!editable"
									:errors="errors"
									past
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'projects'">
				<div class="card">
					<div class="card-body">
						<div class="mb-3 d-flex gap-2" v-if="app.hasPermission('ProjectsController_create')">
							<Link :href="route('projects.create', client.id)" class="btn btn-primary btn-label waves-effect"><i class="far fa-plus label-icon"></i> Projekt létrehozása</Link>
						</div>
						<project-list
							:data-url="route('projects.list')"
							:edit-url="route('projects.update')"
							:delete-url="app.hasPermission(['ProjectsController_delete', 'ProjectsController_deleteAll']) ? route('projects.delete') : ''"
							:client-id="client.id"
							embeded
						></project-list>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'comments'">
				<Comments
					:data-url="client.id !== undefined ? route('clients.comments', client.id) : ''"
					@comment-count="commentCountUpdated"
				></Comments>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import LockMessage from "../components/LockMessage.vue";
import FormWrapper from "../components/FormWrapper.vue";
import SelectField from "../components/form/SelectField.vue";
import DateField from "../components/form/DateField.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import RadioField from "../components/form/RadioField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import CopyToClipboard from "../components/CopyToClipboard.vue";
import ProjectList from "../modules/projects/ProjectList.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import Comments from "../components/Comments.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import AppLayout from "../layouts/AppLayout.vue";
import DateTime from "../components/DateTime.vue";
import PhoneField from "../components/form/PhoneField.vue";
import Attribute from "../components/Attribute.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import InquiryBadge from "../components/badges/InquiryBadge.vue";
import InquiryAttribute from "../components/attributes/InquiryAttribute.vue";
import AddressInput from "../components/AddressInput.vue";

export default {
	layout: AppLayout,
	components: {
		AddressInput,
		InquiryAttribute,
		InquiryBadge,
		UpdatedAttribute,
		Attribute,
		PhoneField,
		DateTime,
		Comments,
		UserBadge,
		ProjectList,
		CopyToClipboard,
		SwitchField,
		RadioField,
		DataLoader,
		InputField,
		DateField,
		SelectField,
		FormWrapper,
		LockMessage,
		Link,
		TitleBar
	},
	props: {
		model: Object,
		inquiry: Object,
		stats: Object,
	},
	data(){
		let client = this.model

		if (client.address === null){
			client.address = {}
		}
		if (client.mail_address === null){
			client.mail_address = {}
		}
		if (client.billing_address === null){
			client.billing_address = {}
		}
		const app = useApplicationStore()
		let tabs =  [
			{
				label: 'Adatlap',
				icon: 'far fa-file-spreadsheet',
				name: 'form'
			}
		]

		if (app.hasPermission('ProjectsController_view')){
			tabs.push({
				label: 'Projektek',
				icon: 'far fa-project-diagram',
				name: 'projects',
			})
		}

		tabs.push({
			label: 'Üzenőfal',
			icon: 'far fa-comments',
			name: 'comments',
		})
		return {
			client: client,
			app: app,
			lock: {
				updated: false,
				locked: false,
				lockedByCurrentUser: false,
				lockedByUserName: '',
				lockedByClientName: '',
				lockedAt: false,
			},
			typeValues: [
				{
					label: 'Magánszemély',
					icon: 'far fa-user'
				},
				{
					label: 'Cég',
					icon: 'far fa-industry'
				},
			],
			tabBadges: {
				projects: this.stats.projects,
				comments: this.stats.comments,
			},
			tabs: tabs
		}
	},
	computed: {
		clientLoginUrl: {
			get(){
				return this.client.login_hash !== undefined ? route('clientArea.auth.setPassword', this.client.login_hash) : ''
			}
		},
		loginAsClientUrl: {
			get(){
				return this.client.id !== undefined && this.app.hasPermission('ClientsController_login') ? route('clientArea.auth.loginAs', this.client.id) : ''
			}
		},
	},
	mounted() {
		this.setAttributes()
	},
	methods: {
		lockAcquired: function(lock){
			this.lock = lock
		},
		setAttributes: function(){
			if (this.client.address === undefined || this.client.address === null){
				this.client.address = {}
			}
			if (this.client.mail_address === undefined || this.client.mail_address === null){
				this.client.mail_address = {}
			}
		},
		commentCountUpdated: function(count){
			this.tabBadges.comments = count
		}
	}
}
</script>

<style scoped>

</style>
