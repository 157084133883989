<template>
	<AdvancedDataTable
		:data-url="'/projects/' + projectDataStore.projectId + '/contracts'"
		:columns="columns"
		sort-by="created_at"
		ref="table"
		sort-type="desc"
		name="contracts"
		@data-loaded="dataLoaded"
	>
		<template #navigation>
			<button type="button" class="btn btn-primary btn-label" v-if="projectDataStore.project.editable && editable && projectDataStore.can('contracts', 'create')"
					@click.prevent="createContract"><i class="far fa-plus label-icon"></i> Szerződés létrehozása
			</button>
			<button type="button" class="btn btn-secondary btn-label"
					v-if="app.hasPermission('ProjectsController_createForeignContract') && editable"
					@click.prevent="createForeignContract"><i class="far fa-plus label-icon"></i> Külső szerződés
				hozzáadása
			</button>
		</template>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.public_id }}</strong>
						<div>{{ item.title_formatted }}</div>
						<div class="mobile-horizontal">
							<attribute v-if="item.foreign_id" icon="far fa-hashtag" :content="item.foreign_id"/>
							<attribute v-if="item.quote_id" icon="far fa-file-invoice" label="Számla" :content="item.custom_payment ? item.invoices : item.invoices + '/' + item.paymentSteps"/>
							<attribute v-if="!item.is_foreign" icon="far fa-file-plus" label="Módosítás" :content="item.amendments"/>
							<attribute icon="far fa-clock">
								<date-time :value="item.created_at"/>
							</attribute>

							<project-badge :project-id="projectDataStore.projectId" :project-type-id="projectDataStore.projectType.id" :quote-id="item.quote_id">
								{{ item.quotePublicId }}
							</project-badge>
						</div>

						<div class="mobile-horizontal">
							<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }">
								<color-badge :value="dataStore.getData('contractStatusLabels')[item.status]"></color-badge>
							</data-loader>
							<color-badge v-if="item.is_foreign" color="danger" icon="file-import" content="Külső szerződés"></color-badge>
						</div>
						<div class="mobile-horizontal">
							<color-badge color="light">
								Nettó: <Price
								:value="item.total_price_net"
								:currency-id="item.currency_id"
							/>
							</color-badge>
							<color-badge color="dark">
								Bruttó: <Price
								:value="item.total_price_gross"
								:currency-id="item.currency_id"
							/>
							</color-badge>
							<color-badge color="success" v-if="item.commission_paid">
								Jutalék: <Price
								:value="item.commission_paid"
								:currency-id="item.currency_id"
							/>
							</color-badge>
							<color-badge :color="item.paid_at ? 'success' : 'warning'" v-if="item.paid_amount">
								Fizetve: <Price
								:value="item.paid_amount"
								:currency-id="item.currency_id"
							/>
							</color-badge>
							<color-badge color="danger">
								Nincs fizetve
							</color-badge>
						</div>

						<contract-delivery-badges :contract="item" />
					</div>
				</div>
				<TableActionDropdown
					:data="item"
					:emit-edit-event="true"
					:emit-view-event="true"
					:emit-delete-event="true"
					:is-editable="item.status === 'draft' && item.editable && editable && projectDataStore.can('contracts', 'update')"
					:is-deletable="item.deletable && editable && projectDataStore.can('contracts', 'delete')"
					@edit-button-pressed="openContract"
					@view-button-pressed="openContract"
					@delete-button-pressed="deleteContract"
					v-slot="{ row }"
				>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="displayPaymentDialog(row)"
					   v-if="displayPaymentLink(row)">
						<i class="far fa-file-invoice fa-fw me-1 text-warning"></i> Következő díjbekérő kiállítása
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="displayInvoiceUploadDialog(row)"
					   v-if="!row.paid_at && row.status === 'signed' && editable && app.hasPermission('ProjectsController_canAttachExternalInvoice')">
						<i class="far fa-file-invoice fa-fw me-1 text-warning"></i> Számlák feltöltése
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="openResponseForm(row.id)"
					   v-if="editable && row.status === 'generated' && projectDataStore.project.editable && projectDataStore.can('contracts', 'answer')">
						<i class="far fa-reply fa-fw me-1 text-warning"></i> Ügyfél válasz rögzítése
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="setDeliveryForContract(row.id)"
					   v-if="editable && row.status === 'signed' && !row.delivery_enabled && app.hasPermission('ProjectsController_setDeliveryForContract')">
						<i class="far fa-truck fa-fw me-1 text-warning"></i> Kiszállítás engedélyezése
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="showContractTermination(row)" v-if="editable && app.hasPermission('ProjectsController_canTerminateContract') && row.status === 'signed'">
						<i class="far fa-trash-alt fa-fw me-1 text-danger"></i> Felbontás
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="showContractClosing(row)" v-if="editable && app.hasPermission('ProjectsController_canCloseContract') && row.status === 'signed'">
						<i class="far fa-check fa-fw me-1 text-success"></i> Teljesítettre állít
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="createContractCommission(row.id)"
					   v-if="editable && row.status === 'signed' && app.hasPermission('UsersController_createCommission') && !row.commission_calculated">
						<i class="far fa-money-bill-wave fa-fw me-1 text-warning"></i> Jutalék kifizetése
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="createWarrantyCard(row.id)"
					   v-if="editable && row.has_product_without_warranty_card && app.hasPermission('ProjectsController_canCreateWarrantyCard')">
						<i class="far fa-badge-check fa-fw me-1 text-warning"></i> Garanciajegy generálása
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="deleteWarrantyCards(row.id)"
					   v-if="editable && row.has_product_with_warranty_card && app.hasPermission('ProjectsController_canDeleteWarrantyCard')">
						<i class="far fa-times fa-fw me-1 text-danger"></i> Garanciajegy(ek) törlése
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="setInstallationForContract(row.id)"
					   v-if="editable && row.status === 'signed' && !row.installation_enabled && app.hasPermission('ProjectsController_canEnableInstallation')">
						<i class="far fa-truck fa-fw me-1 text-warning"></i> Telepítés engedélyezése
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="reopenContract(row.id)"
					   v-if="editable && row.status === 'completed' && app.hasPermission('ProjectsController_canReopenContract')">
						<i class="far fa-lock-open fa-fw me-1 text-warning"></i> Szerződés újranyitása
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="startInstallation(row.id)"
					   v-if="editable && row.status === 'signed' && row.installation_enabled && !row.has_installation && app.hasPermission('ProjectsController_canStartInstallation')">
						<i class="far fa-tools fa-fw me-1 text-warning"></i>
						{{ parseInt(row.hasInstallation) ? 'Telepítés részletek' : 'Telepítés indítása' }}
					</a>
					<a href="#" class="dropdown-item" v-close-popper @click.prevent="attachQuoteToForeignContract(row.id)"
					   v-if="editable && ['signed', 'completed'].indexOf(row.status) > -1 && row.is_foreign && !row.quote_id && app.hasPermission('ProjectsController_createForeignContract')">
						<i class="far fa-file-invoice-dollar fa-fw me-1 text-warning"></i> Árajánlat csatolása
					</a>
					<Link :href="route('inventoryReservations.contract.index')  + '/' + row.id" class="dropdown-item"
					   v-if="row.status === 'signed' && row.delivery_enabled && app.hasPermission('InventoryReservationsController_view')">
						<i class="far fa-cubes fa-fw me-1"></i> Készletfoglalás
					</Link>
					<a class="dropdown-item" href="#" v-close-popper @click.prevent="generateContract(row.id)"
					   v-if="editable && row.id && row.status === 'draft' && projectDataStore.project.editable && projectDataStore.can('contracts', 'update')">
						<i class="fas fa-file-contract fa-fw label-icon text-warning"></i> Generálás
					</a>
					<a class="dropdown-item"
					   v-close-popper
					   :href="'/projects/' + projectDataStore.projectId + '/downloadContract/' + row.id"
					   v-if="row.file_id">
						<i class="far fa-download fa-fw me-1 text-secondary"></i> Letöltés
					</a>
				</TableActionDropdown>
			</div>
		</template>

		<template #item-public_id="item">
			<div class="stacked-cell">
				<strong>{{ item.public_id }}</strong>
				<div>{{ item.title_formatted }}</div>
				<div v-if="item.foreign_id">{{ item.foreign_id }}</div>
				<project-badge :project-id="projectDataStore.projectId" :project-type-id="projectDataStore.projectType.id" :quote-id="item.quote_id" v-if="item.quote_id">
					{{ item.quotePublicId }} - {{ item.clientName }}
				</project-badge>
			</div>
		</template>

		<template #item-quote_id="item">
		</template>

		<template #item-paid_amount="item">
			<div :class="{
				'text-end': 1,
				'text-nowrap': 1,
				'text-success': item.paid_at,
				'text-danger': !item.paid_at && item.status == 'signed',
				'fw-bold': item.status == 'signed' || item.status == 'completed'
			}">
				<Price
					:value="item.paid_amount"
					:currency-id="item.currency_id"
				/>
			</div>
		</template>

		<template #item-total_price_gross="item">
			<div class="d-flex gap-2 text-nowrap">
				<strong>N</strong>
				<Price
					:value="item.total_price_net"
					:currency-id="item.currency_id"
				/>
			</div>
			<div class="d-flex gap-2 text-nowrap">
				<strong>B</strong>
				<Price
					:value="item.total_price_gross"
					:currency-id="item.currency_id"
				/>
			</div>
			<div class="d-flex gap-2 text-nowrap text-success" v-if="item.commission_paid">
				<strong>Jutalék</strong>
				<Price
					:value="item.commission_paid"
					:currency-id="item.currency_id"
				/>
			</div>
		</template>

		<template #item-created_at="item">
			<div class="text-end text-nowrap">
				<date-time :value="item.created_at" :time="false"/>
			</div>
		</template>

		<template #item-details="item">
			<div v-if="item.quote_id" class="text-nowrap">
				<strong>Számla:</strong>
				{{ item.custom_payment ? item.invoices : item.invoices + '/' + item.paymentSteps }}
			</div>
			<span v-if="!item.is_foreign" class="text-nowrap">
				<strong>Módosítás:</strong> {{ item.amendments }}
			</span>
		</template>

		<template #item-status="item">
			<div class="stacked-cell">
				<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }">
					<color-badge :value="dataStore.getData('contractStatusLabels')[item.status]"
								 :small="true"></color-badge>
				</data-loader>
				<color-badge v-if="item.is_foreign" color="danger" icon="file-import" content="Külső szerződés"
							 :small="true"></color-badge>
			</div>
		</template>

		<template #item-delivery="item">
			<contract-delivery-badges :contract="item" small/>
		</template>
	</AdvancedDataTable>

	<teleport to="body" v-if="formOpen">
		<ContractForm
			:contract-id="contractFormId"
			:editable="editable"
			@close="formOpen = false"
			@close-and-reload="closeContractFormAndUpdateList"
			@generate="generateContract"
			@reload-list="$refs.table.getData()"
			@update-stats="updateStats"
			@enable-delivery="enableDelivery"
			@enable-installation="enableInstallation"
			@start-installation="startInstallation"
			:can-start-editing="canStartEditing"
			@start-editing="$emit('startEditing')"
			:form-errors="formErrors"
			@terminateContract="showContractTermination"
		></ContractForm>
	</teleport>
	<teleport to="body" v-if="foreignContractFormOpen">
		<ForeignContractForm
			:contract-id="contractFormId"
			:editable="editable"
			@close="foreignContractFormOpen = false"
			:form-errors="formErrors"
			@close-and-reload="closeContractFormAndUpdateList"
			@update-stats="updateStats"
		></ForeignContractForm>
	</teleport>
	<teleport to="body" v-if="responseFormOpen">
		<ContractResponseForm
			:contract-id="contractFormId"
			@close="responseFormOpen = false"
			@close-and-reload="closeContractResponseFormAndUpdateList"
			@update-stats="updateStats"
		></ContractResponseForm>
	</teleport>
	<teleport to="body" v-if="paymentDialogContractId">
		<ContractPaymentForm
			:contract-id="paymentDialogContractId"
			@close="paymentDialogContractId = false"
			@close-and-reload="closeContractPaymentFormAndUpdateList"
			@update-stats="updateStats"
		></ContractPaymentForm>
	</teleport>
	<teleport to="body" v-if="installationDialogOpen">
		<ProjectInstallationForm
			:contract-id="installationDialogId"
			:installation-id="null"
			:editable="editable"
			:can-start-editing="canStartEditing"
			@close="installationDialogOpen = false"
			@close-and-reload="closeInstallationFormAndUpdateList"
		/>
	</teleport>
	<InvoiceUploadDialog
		v-if="invoiceUploadDialogContractId"
		:contract-id="invoiceUploadDialogContractId"
		:project-id="projectDataStore.projectId"
		@close="closeInvoicesDialog"
		@update="updateTable"
		@stats="updateStats"
		/>
	<modal
		title="Árajánlat csatolása"
		:full-width="false"
		:closable="true"
		:loading="attachQuoteToContractLoading"
		v-if="attachQuoteToContractDialogId !== null"
		:z-index="2004"
		@close="attachQuoteToContractDialogId = null"
	>
		<template #default>
			<quote-select-field
				label="Árajánlat"
				name="attachQuoteToContractId"
				v-model="attachQuoteToContractId"
				:project-id="projectDataStore.projectId"
				rules="required"
				show-price
				accepted
				/>
		</template>

		<template #buttons>
			<button type="button" class="btn btn-primary btn-label waves-effect" @click.prevent="attachQuoteToContract"><i class="far fa-save label-icon"></i> Mentés</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="attachQuoteToContractDialogId = null"><i class="fas fa-times label-icon"></i> Mégse</button>
		</template>
	</modal>
	<modal
		title="Megerősítés"
		:full-width="false"
		:closable="true"
		v-if="terminatingContract !== null"
		:z-index="2004"
		@close="terminatingContract = null"
	>
		<template #default>
			<h5>Biztos, hogy fel akarod bontani a(z) {{ terminatingContract.public_id }} szerződést?</h5>

			<textarea-field
				name="contractTerminationReason"
				v-model="terminationReason"
				label="Felbontás indoka"
				rules="required"
				:errors="formErrors"
			/>

			<warehouse-select-field
				name="contractTerminationWarehouse"
				label="Raktár"
				v-model="contractTerminationWarehouse"
				rules="required"
				:show-mobile="false"
				info="Ide készül szállítólevél a visszaszállítandó termékekről"
				/>
		</template>

		<template #buttons>
			<button type="button" class="btn btn-danger btn-label waves-effect" :disabled="terminationReason.length < 1" @click.prevent="terminateContract"><i class="far fa-trash-alt label-icon"></i> Igen</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="terminatingContract = null"><i class="fas fa-times label-icon"></i> Nem</button>
		</template>
	</modal>
	<modal
		title="Megerősítés"
		:full-width="false"
		:closable="true"
		v-if="closingContract !== null"
		:z-index="2004"
		@close="closingContract = null"
	>
		<template #default>
			<h5>Biztos, hogy teljesítettre akarod állítani a(z) {{ closingContract.public_id }} szerződést?</h5>
			<p>Ezután már nem lehetséges termékeket kiszállítani.</p>
		</template>

		<template #buttons>
			<button type="button" class="btn btn-success btn-label waves-effect" @click.prevent="closeContract"><i class="far fa-check label-icon"></i> Igen</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="closingContract = null"><i class="fas fa-times label-icon"></i> Nem</button>
		</template>
	</modal>
	<WarrantyForm
		v-if="warrantyDialogOpen"
		:project-id="projectDataStore.projectId"
		:contract-id="warrantyDialogContractId"
		:warranty-card-id="warrantyDialogId"
		@close="warrantyDialogOpen = false"
		@closeAndReload="warrantyDialogOpen = false; $refs.table.getData()"
		/>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import Price from "../../components/Price.vue";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import ContractForm from "./ContractForm.vue";
import ForeignContractForm from "./ForeignContractForm.vue";
import ContractResponseForm from "./ContractResponseForm.vue";
import QuoteList from "./QuoteList.vue";
import ContractAmendmentForm from "./ContractAmendmentForm.vue";
import ContractPaymentForm from "./ContractPaymentForm.vue";
import ProjectInstallationForm from "./ProjectInstallationForm.vue";
import QuoteForm from "./QuoteForm.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useProjectDataStore} from "../../stores/projectData";
import {useApplicationStore} from "../../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import Attribute from "../../components/Attribute.vue";
import Number from "../../components/Number.vue";
import DateTime from "../../components/DateTime.vue";
import Modal from "../../components/Modal.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import WarehouseSelectField from "../../components/form/WarehouseSelectField.vue";
import ContractDeliveryBadges from "./ContractDeliveryBadges.vue";
import InvoiceUploadDialog from "./InvoiceUploadDialog.vue";
import WarrantyForm from "./WarrantyForm.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";
import QuoteSelectField from "../../components/form/QuoteSelectField.vue";

export default {
	components: {
		QuoteSelectField,
		ProjectBadge,
		WarrantyForm,
		InvoiceUploadDialog,
		ContractDeliveryBadges,
		WarehouseSelectField,
		TextareaField, Modal,
		DateTime,
		Number, Attribute,
		Link,
		ColorBadge,
		DataLoader,
		QuoteForm,
		ProjectInstallationForm,
		ContractPaymentForm,
		ContractAmendmentForm,
		QuoteList,
		ContractResponseForm,
		ContractForm,
		ForeignContractForm,
		TableActionDropdown,
		Price,
		TableActionCell,
		AdvancedDataTable,
	},
	emits: ['contractCount', 'updateStats', 'openQuote', 'startEditing', 'loadData'],
	props: {
		projectAttributes: [Array, Object],
		canStartEditing: Boolean,
		editable: Boolean,
		formErrors: Object,
	},
	data() {
		let columns = [
			{
				text: 'Azonosító',
				value: 'public_id',
				sortable: true,
			},
			{
				text: 'Állapot',
				value: 'status',
				sortable: false,
			},
			{
				text: 'Ár',
				value: 'total_price_gross',
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				text: 'Fizetve',
				value: 'paid_amount',
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				text: 'Részletek',
				value: 'details',
				width: 10
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true,
				width: 10
			},
			{
				text: 'Kiszállítás',
				value: 'delivery',
				sortable: false,
				width: 10
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			formOpen: false,
			responseFormOpen: false,
			foreignContractFormOpen: false,
			app: useApplicationStore(),
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			contractFormId: null,
			loading: false,
			installationDialogOpen: false,
			installationDialogId: null,
			attachQuoteToContractDialogId: null,
			attachQuoteToContractLoading: false,
			warrantyDialogOpen: false,
			warrantyDialogContractId: null,
			warrantyDialogId: null,
			paymentDialogContractId: null,
			invoiceUploadDialogContractId: null,
			terminatingContract: null,
			closingContract: null,
			attachQuoteToContractId: null,
			terminationReason: '',
			contractTerminationWarehouse: null,
		}
	},
	mounted() {
		if (this.projectDataStore.activeTabCommand === 'contract' && this.projectDataStore.activeTabParameter) {
			setTimeout(() => {
				this.openContract(this.projectDataStore.activeTabParameter)
			}, 300)
		}
		if (this.projectDataStore.activeTabCommand === 'amendment' && this.projectDataStore.activeTabParameter) {
			setTimeout(() => {
				let tmp = this.projectDataStore.activeTabParameter.split('|')
				this.openContract(tmp[0])
			}, 300)
		}
	},
	methods: {
		dataLoaded: function (data) {
			this.$emit('contractCount', data.meta.total)
		},
		isEditable: function (row) {
			return row.editable
		},
		openContract: function (id) {
			this.contractFormId = id
			this.formOpen = true
		},
		deleteContract: function (id) {
			this.loading = true

			axios.delete('/projects/deleteContract/' + id).then((response) => {
				this.loading = false
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash);
				}
				if (response.data.stats !== undefined) {
					this.updateStats(response.data.stats);
				}
				this.$refs.table.getData()
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		closeContractFormAndUpdateList: function () {
			this.formOpen = false
			this.foreignContractFormOpen = false
			this.contractFormId = null
			this.$refs.table.getData()
		},
		closeContractResponseFormAndUpdateList: function () {
			this.responseFormOpen = false
			this.contractFormId = null
			this.$refs.table.getData()
		},
		generateContract: function (contractId) {
			this.loading = true
			axios.put('/projects/generateContract/' + contractId).then((response) => {
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash);
				}
				this.loading = false
				this.formOpen = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		setDeliveryForContract: function (contractId) {
			this.loading = true
			axios.put('/projects/enableContractDelivery/' + contractId).then((response) => {
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash);
				}
				this.loading = false
				this.formOpen = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		setInstallationForContract: function (contractId) {
			this.loading = true
			axios.put('/projects/enableContractInstallation/' + contractId).then((response) => {
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash);
				}
				this.loading = false
				this.formOpen = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		reopenContract: function (contractId) {
			this.loading = true
			axios.put('/projects/reopenContract/' + contractId).then((response) => {
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash);
				}
				this.loading = false
				this.formOpen = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		createContractCommission: function (contractId) {
			this.loading = true
			axios.post('/users/createCommission/' + contractId, {}).then((response) => {
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash);
				}
				this.loading = false
				this.$refs.table.getData()
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		openResponseForm: function (id) {
			this.contractFormId = id
			this.responseFormOpen = true
		},
		enableDelivery: function () {
			this.setDeliveryForContract(this.contractFormId)
		},
		enableInstallation: function () {
			this.setInstallationForContract(this.contractFormId)
		},
		updateStats: function (stats) {
			this.$emit('updateStats', stats);
		},
		emitOpenQuote: function (id) {
			this.$emit('openQuote', id);
		},
		displayPaymentLink: function (row) {
			if (!this.editable || row.status !== 'signed' || !this.app.hasPermission('ProjectsController_canCreatePayment')) {
				return false
			}
			if (!this.projectDataStore.can('contracts', 'invoice')) {
				if (!this.app.hasPermission('ProjectsController_updateAll') && this.projectDataStore.project.handler_user_id !== this.app.userId) {
					return false
				}
			}
			if (!row.custom_payment) {
				return row.invoices < row.paymentSteps
			}

			return row.paid_at === null
		},
		displayInvoiceUploadDialog: function (row) {
			this.invoiceUploadDialogContractId = row.id
		},
		displayPaymentDialog: function (row) {
			this.paymentDialogContractId = row.id
		},
		closeContractPaymentFormAndUpdateList: function () {
			this.paymentDialogContractId = null
			this.$refs.table.getData()
		},
		closeInstallationFormAndUpdateList: function () {
			this.installationDialogOpen = false
			this.$refs.table.getData()
		},
		startInstallation: function (id) {
			this.installationDialogId = id
			this.installationDialogOpen = true
		},
		attachQuoteToForeignContract: function (id) {
			this.attachQuoteToContractDialogId = id
		},
		attachQuoteToContract: function (){
			if (this.attachQuoteToContractDialogId && this.attachQuoteToContractId){
				axios.post('/projects/attachQuoteToContract/' + this.attachQuoteToContractDialogId + '/' + this.attachQuoteToContractId, {}).then((response) => {
					if (response.data.flash !== undefined) {
						this.flashStore.addFlash(response.data.flash);
					}
					this.attachQuoteToContractDialogId = null
					this.$refs.table.getData()
				}).catch((error) => {
					this.loading = false
					this.flashStore.addError(error)
				})
			}
		},
		createForeignContract: function () {
			this.contractFormId = null
			this.foreignContractFormOpen = true
		},
		createContract: function () {
			this.contractFormId = null
			this.formOpen = true
		},
		showContractTermination: function (contract){
			this.contractFormId = null
			this.formOpen = false
			this.terminatingContract = contract
		},
		showContractClosing: function (contract){
			this.contractFormId = null
			this.formOpen = false
			this.closingContract = contract
		},
		terminateContract: function (){
			axios.put('/projects/terminateContract/' + this.terminatingContract.id + '/' + this.contractTerminationWarehouse, {
				termination_reason: this.terminationReason,
			}).then((response) => {
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash);
				}
				this.$refs.table.getData()
			}).catch((error) => {
				this.flashStore.addError(error)
			})
			this.terminatingContract = null
			this.terminationReason = ''
		},
		closeContract: function (){
			axios.put('/projects/closeContract/' + this.closingContract.id).then((response) => {
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash);
				}
				this.$refs.table.getData()
			}).catch((error) => {
				this.flashStore.addError(error)
			})
			this.closingContract = null
		},
		deleteWarrantyCards: function (id){
			axios.delete('/projects/deleteWarrantyCards/' + id).then((response) => {
				if (response.data.flash !== undefined) {
					this.flashStore.addFlash(response.data.flash);
				}
				this.$refs.table.getData()
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		closeInvoicesDialog: function (){
			this.invoiceUploadDialogContractId = null
		},
		updateTable: function (){
			this.$refs.table.getData()
		},
		createWarrantyCard: function (id){
			this.warrantyDialogId = null
			this.warrantyDialogContractId = id
			this.warrantyDialogOpen = true
		}
	}
}
</script>

<style scoped>
</style>
