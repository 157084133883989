<script setup>
import DataLoader from "../DataLoader.vue";
import ColorBadge from "../ColorBadge.vue";

const props = defineProps({
	small: {
		type: Boolean,
		default: true
	}
})
const model = defineModel()
</script>

<template>
	<data-loader :data="['saleStatusLabels']" v-slot="{ dataStore }">
		<color-badge
			v-if="dataStore.getData('saleStatusLabels', true)[model] !== undefined"
			:icon="dataStore.getData('saleStatusLabels', true)[model].icon"
			:color="dataStore.getData('saleStatusLabels', true)[model].color"
			:content="dataStore.getData('saleStatusLabels', true)[model].name"
			:small="small"
			/>
	</data-loader>
</template>

<style scoped>

</style>
