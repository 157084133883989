<template>
	<TitleBar :title="attributes.id !== undefined ? 'Bónusz módosítása' : 'Bónusz hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('userBonuses.store', model.id)"
		:back-url="route('userBonuses.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
						<div v-if="attributes.last_run">
							<i class="far fa-calendar-alt"></i> Előző futás: <strong><DateTime :value="attributes.last_run"/></strong>
						</div>
						<div v-if="attributes.next_runtime">
							<i class="far fa-calendar-alt"></i> Következő futás: <strong><DateTime :value="attributes.next_runtime"/></strong>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="row">
				<div class="col-md-6">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Adatok</h4>
							<switch-field
								label="Aktív"
								name="active"
								v-model="attributes.active"
							/>

							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<iconpicker-field
								v-model="attributes.icon"
								label="Ikon"
								name="icon"
							/>
							<div class="mb-3">
								<label>Szín</label>
								<input type="color" class="form-control form-control-color" v-model="attributes.color"  />
							</div>
						</div>
					</div>
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Feltétel</h4>
							<div class="alert alert-secondary alert-sm">
								<p>A Symfony Expression nyelv szabályairól <a href="https://symfony.com/doc/current/reference/formats/expression_language.html" target="_blank">itt olvashatsz</a>.</p>
							</div>
							<textarea-field
								v-model="attributes.condition_expression"
								name="condition_expression"
								rules="required"
								:field-only="true"
								:no-margin="true"
							>
								<div class="mb-3 bg-light p-2">
									<variable-select
										label="Változó beszúrása"
										:field-only="true"
										:options="variables"
										:errors="errors"
									/>
								</div>
							</textarea-field>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Futási idők</h4>
							<div class="alert alert-secondary alert-sm">
								<p>Az alábbi mezők határozzák meg, hogy milyen időközönként fut a bónusz logikája. Futáskor a rendszer összehasonlítja a megfelelő mezőket (pl az itt megadott hónapot az aktuális hónappal), ha mindegyik egyezik, akkor lefut. A mezők lehetséges értékei:</p>
								<ul>
									<li><strong>*</strong> - minden esetben fusson</li>
									<li><strong>*/3</strong> - minden 3. esetben fusson</li>
									<li><strong>2-5</strong> - csak akkor fusson, ha az aktuális érték a tartományon belül van</li>
									<li><strong>4</strong> - csak akkor fusson, ha az aktuális érték 4</li>
								</ul>
							</div>
							<input-field
								label="Futási idő (hónap)"
								name="interval_month"
								v-model="attributes.interval_month"
								rules="required"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Futási idő (hét napja)"
								name="interval_week"
								v-model="attributes.interval_week"
								rules="required"
								info="Hétfő: 1, ... , Vasárnap: 7"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Futási idő (nap)"
								name="interval_day"
								v-model="attributes.interval_day"
								rules="required"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
						</div>
					</div>

					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Jutalom</h4>
							<div class="alert alert-secondary alert-sm">
								<p>A Symfony Expression nyelv szabályairól <a href="https://symfony.com/doc/current/reference/formats/expression_language.html" target="_blank">itt olvashatsz</a>.</p>
							</div>
							<textarea-field
								v-model="attributes.reward_expression"
								name="reward_expression"
								rules="required"
								:field-only="true"
								:no-margin="true"
							>
								<div class="mb-3 bg-light p-2">
									<variable-select
										label="Változó beszúrása"
										:field-only="true"
										:options="variables"
										:errors="errors"
									/>
								</div>
							</textarea-field>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import FormWrapper from "../components/FormWrapper.vue";
import TextareaField from "../components/form/TextareaField.vue";
import InputField from "../components/form/InputField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import IconpickerField from "../components/form/IconpickerField.vue";
import VariableSelect from "../components/form/VariableSelect.vue";
import DateTime from "../components/DateTime.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {
		UpdatedAttribute,
		DateTime,
		VariableSelect, IconpickerField, SwitchField, InputField, TextareaField, FormWrapper, TitleBar},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		return {
			attributes: this.model,
			loading: false,
			flash: [],
			variables: {
				ujMunkatars: 'Beléptetett munkatárs',
				fizetettSzerzodes: 'Fizetett szerződések száma',
				fizetettSzerzodesOsszeg: 'Fizetett szerződések összértéke',
				szint: 'Felhasználó szintje',
			}
		}
	},
}
</script>
