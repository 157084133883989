<template>
	<div class="text-reset task-item">
		<div class="d-flex">
			<div class="task-icon-wrapper me-3">
				<span :class="'task-icon text-' + (task.completed ? 'white' : task.color) + ' border-' + (task.color) + ' font-size-14' + (task.completed ? ' bg-' + task.color : '')">
					<i :class="task.icon"></i>
				</span>
			</div>
			<div class="flex-grow-1">
				<h6 class="mt-0 mb-1">
					<task-list-item-title :task="task" />
				</h6>
				<div class="font-size-12 text-muted">
					<div class="d-flex gap-2 flex-wrap align-items-center">
						<template v-if="task.type === 'project' && tabs[task.details.tab] !== undefined">
							<color-badge
								color="dark"
								:small="true"
								:icon="tabs[task.details.tab].icon"
								:content="tabs[task.details.tab].label"
							/>
						</template>
						<task-list-item-details :task="task" />
						<attribute icon="fas fa-boxes" v-if="task.type === 'lowStockWarning'">
							{{ task.details_as_array.name }}
						</attribute>
						<attribute icon="fas fa-hashtag" v-if="task.type === 'lowStockWarning'">
							{{ task.details_as_array.sku }}
						</attribute>
						<attribute icon="fas fa-address-book" v-if="['approveQuote', 'approveDocumentBundle', 'documentBundleError'].indexOf(task.type) > -1">
							{{ task.details_as_array.clientName }}
						</attribute>
						<attribute icon="far fa-clock">
							<RelativeTime v-model="task.created_at" v-if="!absoluteTime" />
							<date-time :value="task.created_at" v-else />
						</attribute>
						<attribute icon="far fa-user" v-if="task.created_by_user_name">
							{{ task.created_by_user_name }}
						</attribute>
						<template v-if="task.transfer_to_user_id">
							<color-badge v-if="task.assigned_to_user_id === app.userId" color="light" icon="far fa-random" small>
								Átadás alatt:
								<user-badge :user-id="task.transfer_to_user_id" hide-level hide-link  :key="'US_' + task.transfer_to_user_id"/>
							</color-badge>
							<color-badge v-if="task.transfer_to_user_id === app.userId" color="warning" icon="far fa-random" small>
								Átadási kérés:
								<user-badge :user-id="task.assigned_to_user_id" hide-level hide-link :key="'US_' + task.assigned_to_user_id"/>
							</color-badge>
						</template>
						<color-badge
							v-if="task.completed"
							color="success"
							icon="far fa-check"
							small
						>
							<DateTime :value="task.completed_at"/>
						</color-badge>
					</div>
				</div>
			</div>
			<div class="ps-2" v-if="task.closable">
				<button class="btn btn-close-task" title="Feladat teljesítettre állítása" @click.prevent="showCloseTaskModal = true"><i class="far fa-check"></i></button>
			</div>
		</div>
		<template v-if="task.type === 'personal' || task.type === 'project' || task.transfer_to_user_id !== null">
			<a href="#" @click.prevent="modalVisible = true"></a>
			<task-modal :taskId="task.id" v-if="modalVisible" @close="modalVisible = false" @closeAndReload="modalVisible = false"/>
		</template>
		<template v-else>
			<Link :href="'/tasks/redirect/' + task.id" ></Link>
		</template>

		<modal
			title="Feladat teljesítettre állítása"
			@close="showCloseTaskModal = false"
			v-if="showCloseTaskModal"
			:closable="true"
		>
			<template #default>
				<form-wrapper
					:show-top-buttons="false"
					:show-bottom-buttons="false"
					v-model="closeAttributes"
					:store-url="'/tasks/update/' + task.id"
					@afterSubmit="taskClosed"
					:disable-redirect="true"
					:no-margin="true"
					ref="taskCloseForm"
				>
					<template #default="{activeTab, errors, editable}">
						<label>Megjegyzés</label>
						<ckeditor-field
							md-height
							name="result"
							v-model="closeAttributes.result"
							no-margin
						/>

						<button type="submit" ref="taskFormSubmitButton" class="d-none">submit</button>
					</template>
				</form-wrapper>
			</template>

			<template #buttons>
				<button
					type="button"
					class="btn btn-primary btn-label waves-effect"
					@click.prevent="$refs.taskFormSubmitButton.click()">
					<i class="fas fa-save label-icon"></i> Mentés
				</button>
				<button type="button" class="btn btn-secondary btn-label" @click.prevent="showCloseTaskModal = false"><i class="far fa-times label-icon"></i> Bezárás</button>
			</template>
		</modal>
	</div>
</template>

<script>
import {Link} from "@inertiajs/vue3";
import ColorBadge from "./ColorBadge.vue";
import Attribute from "./Attribute.vue";
import RelativeTime from "./RelativeTime.vue";
import {useProjectDataStore} from "../stores/projectData";
import UserBadge from "./badges/UserBadge.vue";
import {useApplicationStore} from "../stores/applicationStore";
import TaskModal from "../modules/dashboard/TaskModal.vue";
import DateTime from "./DateTime.vue";
import TaskListItemTitle from "./TaskListItemTitle.vue";
import TaskListItemDetails from "./TaskListItemDetails.vue";
import {useFlashStoreStore} from "../stores/flashStore";
import CkeditorField from "./form/CkeditorField.vue";
import InputField from "./form/InputField.vue";
import TimePicker from "./form/TimePicker.vue";
import SwitchField from "./form/SwitchField.vue";
import UserSelectField from "./form/UserSelectField.vue";
import ProjectStatusIcon from "../modules/projects/ProjectStatusIcon.vue";
import DataLoader from "./DataLoader.vue";
import Modal from "./Modal.vue";
import DateField from "./form/DateField.vue";
import FormWrapper from "./FormWrapper.vue";

export default {
	props: {
		task: Object,
		absoluteTime: Boolean
	},
	components: {
		FormWrapper,
		DateField,
		Modal,
		DataLoader,
		ProjectStatusIcon,
		UserSelectField,
		SwitchField,
		TimePicker,
		InputField,
		CkeditorField,
		TaskListItemDetails,
		TaskListItemTitle, DateTime, TaskModal, UserBadge, RelativeTime, Attribute, ColorBadge, Link
	},
	data(){
		return {
			app: useApplicationStore(),
			componentProjectDataStore: this.projectDataStore !== undefined ? this.projectDataStore : useProjectDataStore(),
			modalVisible: false,
			flashStore: useFlashStoreStore(),
			showCloseTaskModal: false,
			closeAttributes: {
				completed: true,
				result: ''
			}
		}
	},
	computed: {
		tabs: {
			get(){
				return _.keyBy(this.componentProjectDataStore.possibleTabs, 'name')
			}
		},
	},
	methods: {
		taskClosed: function (){
			this.app.reloadTasks()
			this.showCloseTaskModal = false
		}
	}
}
</script>
