<template>
	<div :class="{'mb-3': !fieldOnly, 'radio-field': 1}">
		<label v-if="!fieldOnly && label">{{ label }}</label>
		<div>
			<div :class="{'btn-group': !vertical, 'btn-group-vertical': vertical, 'w-100': fullWidth}">
				<template v-for="(value, valueKey) in values">
				<button
					type="button"
					:class="getClasses(valueKey)"
					v-if="!hideNonActive || isActive(valueKey)"
					@click.prevent="setValue(valueKey)">
					<i :class="value.icon + ' me-2'" v-if="value.icon !== undefined"></i><span v-if="value.label || value.name"> {{ value.label ? value.label : value.name }}</span>
				</button>
				</template>
			</div>
		</div>
		<p class="text-muted mt-2 text-info" v-if="info" v-html="info"></p>
	</div>
</template>

<script>
export default {
	emits: ['update:modelValue', 'input'],
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: String,
		name: String,
		modelValue: Number|Boolean,
		info: {
			type: String,
			default: ''
		},
		values: [Object, Array],
		hideNonActive: {
			type: Boolean,
			default: false
		},
		fieldOnly: Boolean,
		fullWidth: Boolean,
		valueType: {
			type: String,
			default: 'string'
		},
		hasSelectedValue: Boolean,
		notSelectedByDefault: Boolean,
		vertical: Boolean,
		ignoreColor: Boolean,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				if (this.valueType === 'integer'){
					value = parseInt(value)
				}
				if (this.valueType === 'boolean'){
					if (value !== null){
						value = value === 'yes'
					}
				}
				this.$emit('update:modelValue', value)
				this.$emit('input')
			}
		},
		componentId: {
			get() {
				return this.name + '_checkbox'
			}
		},
	},
	methods: {
		setValue: function(value){
			if (!this.disabled){
				this.componentValue = value
			}
		},
		isActive: function(value){
			if (this.valueType === 'boolean'){
				if (this.componentValue === null){
					return false
				}
				return (value === 'yes' && this.componentValue) || (value === 'no' && !this.componentValue)
			}
			if (this.valueType === 'integer'){
				return parseInt(value) === this.componentValue
			}
			return value === this.componentValue
		},
		getClasses: function(valueKey){
			let classes = ['btn'];
			if (!this.isActive(valueKey)){
				classes.push('btn-secondary')
				classes.push('btn-secondary-inactive')
			} else {
				if (this.values[valueKey].color !== undefined && !this.ignoreColor){
					classes.push('btn-' + this.values[valueKey].color)
				} else {
					classes.push('btn-primary')
				}
			}
			if (this.disabled){
				classes.push('disabled')
			}

			return classes
		}
	}
}
</script>

<style scoped>
</style>
