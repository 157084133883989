<template>
	<supplier-badge :supplier-id="item.correction_supplier_id" v-if="item.correction_supplier_id" :key="'SU_' + item.correction_supplier_id" />
	<supplier-badge :supplier-id="item.supplier_id" v-if="item.supplier_id" :key="'SU_' + item.supplier_id" />
	<warehouse-badge :warehouse-id="item.source_warehouse_id" v-else-if="item.source_warehouse_id" />
	<template v-else-if="item.contract_id !== null">
		<project-badge
			:contract-id="item.contract_id"
			:project-id="item.project_id"
			:project-type-id="item.project_type_id"
			:key="'PR' + item.project_id + '_C_' + item.contract_id"
			icon="far fa-file-contract"
			:small="small"
			v-if="item.contract"
		>
			{{ item.contract }}
		</project-badge>
		<color-badge
			v-else
			color="light"
			icon="far fa-file-contract"
			:small="small"
			content="Telepítési hely"
			/>
	</template>
	<template v-else>
		<color-badge
			color="light"
			icon="far fa-user"
			:small="small"
			:content="item.sourceName"
			/>
	</template>
</template>

<script>
import DataLoader from "../../components/DataLoader.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import ColorBadge from "../../components/ColorBadge.vue";
import WarehouseBadge from "../../components/badges/WarehouseBadge.vue";
import SupplierBadge from "../../components/badges/SupplierBadge.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";

export default {
	components: {ProjectBadge, SupplierBadge, WarehouseBadge, ColorBadge, ProjectTypeCell, DataLoader},
	props: {
		item: Object,
		small: Boolean
	},
	data(){
		return {
			dataStore: useGeneralDataStore()
		}
	},
}
</script>
