<template>
	<div class="mt-2" v-if="error !== '' || componentValue.lat">
		<div class="alert alert-danger mb-3" v-if="error !== ''">{{ error }}</div>
		<div class="accordion accordion-flush" v-if="componentValue.lat">
			<div class="accordion-item">
				<h2 class="accordion-header" v-if="!mapOnly">
					<button :class="{'accordion-button': 1, 'collapsed': !mapOpen}" type="button" @click.prevent="mapOpen = !mapOpen">
						<i class="far fa-globe me-1"></i> Térkép
					</button>
				</h2>
				<div class="accordion-collapse collapse show" v-if="componentValue.lat">
					<div class="accordion-body p-0">
						<div class="p-2" v-if="editable">
							<div class="alert alert-info m-0">Amennyiben a térképen lévő jelölő nem a pontos lokációra mutat, rákattintva a megfelelő helyre lehet húzni.</div>
						</div>
						<div style="height:500px" v-if="componentValue.lat && mapOpen">
							<l-map ref="map" :zoom="16" :center="[componentValue.lat, componentValue.lng]">
								<l-marker :lat-lng="[componentValue.lat, componentValue.lng]" :draggable="editable" @update:latLng="markerMoved"></l-marker>
								<l-tile-layer
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									layer-type="base"
									name="OpenStreetMap"
								></l-tile-layer>
							</l-map>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {LMap, LMarker, LTileLayer} from "@vue-leaflet/vue-leaflet";
export default {
	components: {LMarker, LTileLayer, LMap},
	props: {
		modelValue: Object,
		countries: Array,
		attributeName: String,
		editable: Boolean,
		mapOnly: Boolean
	},
	data() {
		return {
			error: '',
			mapOpen: this.mapOnly
		}
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		countriesById: {
			get() {
				return _.keyBy(this.countries, 'id')
			}
		}
	},
	methods: {
		geocode: function(e){
			if (this.componentValue.city
				&& this.componentValue.street !== undefined
				&& this.componentValue.street.length
				&& this.componentValue.house_number !== undefined
				&& this.componentValue.house_number.length){
				let country = this.countriesById[this.componentValue.city.country_id]

				axios.post('/geocode', {
					address: country.name + ', ' + this.componentValue.city.zip_code + ' ' + this.componentValue.city.name + ', ' + this.componentValue.street.replace('utca', 'u') + ' ' + this.componentValue.house_number
				}).then((response)=>{
					if (response.data.success){
						this.componentValue.lat = response.data.latitude
						this.componentValue.lng = response.data.longitude
						this.error = ''
					} else {
						this.error = _.first(response.data.flash).message
						this.componentValue.lat = ''
						this.componentValue.lng = ''
					}
				}).catch((error) => {
					this.error = error.response.data.message
				})
			} else {
				this.componentValue.lat = ''
				this.componentValue.lng = ''
				this.mapOpen = false
			}
		},
		markerMoved: function (e){
			this.componentValue.lat = e.lat
			this.componentValue.lng = e.lng
		}
	}
}
</script>

<style scoped>

</style>
