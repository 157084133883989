<template>
	<template v-if="created && data.created_at !== undefined && data.created_at !== null">
		<date-time-attribute :value="data.created_at" created :hide-label="hideLabel">
			<div v-if="data.created_by_user_id !== undefined && data.created_by_user_id !== null && !hideUser">
				<user-badge :user-id="data.created_by_user_id"></user-badge>
			</div>
		</date-time-attribute>
	</template>
	<template v-else-if="updated && data.updated_at !== undefined && data.updated_at !== null">
		<date-time-attribute :value="data.updated_at" updated :hide-label="hideLabel">
			<div v-if="data.updated_by_user_id !== undefined && data.updated_by_user_id !== null && !hideUser">
				<user-badge :user-id="data.updated_by_user_id" ></user-badge>
			</div>
		</date-time-attribute>
	</template>
</template>

<script>
import Attribute from "./Attribute.vue";
import DateTime from "./DateTime.vue";
import UserBadge from "./badges/UserBadge.vue";
import DateTimeAttribute from "./attributes/DateTimeAttribute.vue";

export default {
	components: {DateTimeAttribute, UserBadge, DateTime, Attribute},
	props: {
		data: Object,
		content: [String, Number],
		created: Boolean,
		updated: Boolean,
		hideUser: Boolean,
		hideLabel: Boolean,
	}
}
</script>
