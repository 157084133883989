<template>
	<TitleBar :title="attributes.id !== undefined ? 'Hírlevél módosítása' : 'Hírlevél hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('newsletters.store', model)"
		:back-url="route('newsletters.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="row">
				<div class="col-sm-4">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Adatok</h4>
							<div class="alert alert-success mb-3" v-if="attributes.sent_at">
								<h4>Kiküldve {{ attributes.sent_to }} címzettnek.</h4>
								<p>Küldés időpontja: <DateTime :value="attributes.sent_at"/></p>
							</div>
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>

							<input-field
								label="E-mail tárgya"
								name="subject"
								rules="required"
								v-model="attributes.subject"
								:disabled="!attributes.editable"
								:errors="errors"
							/>

							<input-field
								label="Teszt e-mail címek"
								name="test_addresses"
								info="Az itt megadott, vesszővel elválasztott e-mail címekre mentés után elküldi a hírlevelet tesztelési célból."
								v-model="attributes.test_addresses"
								:disabled="!attributes.editable"
								:errors="errors"
							/>

							<project-type-select-field
								label="Érdeklődők"
								name="inquiries"
								v-model="attributes.recipients.inquiries.project_types"
								:disabled="!attributes.editable"
								:multiple="true"
							></project-type-select-field>

							<project-type-select-field
								label="Ügyfelek"
								name="clients"
								v-model="attributes.recipients.clients.project_types"
								:disabled="!attributes.editable"
								:multiple="true"
								@update:model-value="updateMilestones"
							></project-type-select-field>

							<select-field
								label="Mérföldkövek"
								name="milestones"
								v-model="attributes.recipients.clients.milestones"
								:disabled="!attributes.editable"
								:multiple="true"
								:options="filteredMilestones"
								display-badge
								v-if="filteredMilestones.length"
							></select-field>

							<label>Címzett lista feltöltése</label>
							<FileUploader
								field-name="recipientFile"
								v-model="attributes.addressListFile"
								file-type="xls"
								info="Xls file, melyben az első oszlop az e-mail cím, a második a címzett neve. Max 500 címzett."
								:editable="attributes.editable"
								compact
								disable-no-file-message
								:multiple="false"
							></FileUploader>

							<div class="alert alert-success" v-if="attributes.recipients.direct && Object.keys(attributes.recipients.direct).length">
								{{ Object.keys(attributes.recipients.direct).length }} címzett hozzáadva feltöltött fileból.
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-8">
					<div class="card mb-3">
						<div class="card-body">
							<div class="bg-light p-2">
								<variable-select
									label="Változó beszúrása"
									:field-only="true"
									:options="{'név': 'Név', 'email': 'E-mail cím'}"
									:errors="errors"
								/>
							</div>
						</div>
					</div>

					<div class="card mb-3" v-for="(content, contentKey) in attributes.contents" :key="content.type + '_' + contentKey">
						<div class="card-body">
							<template v-if="content.type === 'text'">
								<ckeditor-field
									:name="'textField_' + contentKey"
									v-model="content.value"
								></ckeditor-field>
							</template>
							<template v-if="content.type === 'image'">
								<FileUploader
									:field-name="'file_' + contentKey"
									v-model="content.value"
									file-type="image"
									:editable="attributes.editable"
									:multiple="false"
								></FileUploader>
							</template>
							<div class="mt-3 d-flex gap-2" v-if="attributes.editable">
								<button type="button" class="btn btn-secondary btn-sm" @click.prevent="moveContent('up', contentKey)"><i class="far fa-chevron-up"></i></button>
								<button type="button" class="btn btn-secondary btn-sm" @click.prevent="moveContent('down', contentKey)"><i class="far fa-chevron-down"></i></button>
								<button type="button" class="btn btn-primary btn-sm" @click.prevent="addContent('text', contentKey)"><i class="far fa-text"></i> Szöveg hozzáadása</button>
								<button type="button" class="btn btn-primary btn-sm" @click.prevent="addContent('image', contentKey)"><i class="far fa-image"></i> Kép hozzáadása</button>
								<button type="button" class="btn btn-danger btn-sm" @click.prevent="deleteContent(contentKey)" v-if="attributes.contents.length > 1"><i class="far fa-times"></i> Tartalom törlése</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import FileUploader from "../components/FileUploader.vue";
import VariableSelect from "../components/form/VariableSelect.vue";
import CkeditorField from "../components/form/CkeditorField.vue";
import ProjectTypeSelectField from "../components/form/ProjectTypeSelectField.vue";
import {moveValueInArray} from "../functions";
import DateTime from "../components/DateTime.vue";
import TextareaField from "../components/form/TextareaField.vue";
import SelectField from "../components/form/SelectField.vue";
import Number from "../components/Number.vue";
import Attribute from "../components/Attribute.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	layout: AppLayout,
	components: {
		UpdatedAttribute,
		Attribute, Number,
		SelectField,
		TextareaField,
		DateTime,
		ProjectTypeSelectField, CkeditorField, VariableSelect, FileUploader, InputField, FormWrapper, TitleBar},
	props: {
		model: Object,
		milestones: Array
	},
	computed: {
		filteredMilestones: {
			get() {
				let payload = []
				if (this.attributes.recipients !== undefined && this.attributes.recipients.clients !== undefined && this.attributes.recipients.clients.project_types !== undefined && this.attributes.recipients.clients.project_types.length) {
					_.forEach(this.milestones, (milestone) => {
						if (this.attributes.recipients.clients.project_types.indexOf(milestone.project_type_id) > -1) {
							payload.push(milestone)
						}
					})
				}

				return payload
			}
		}
	},
	data(){
		let attributes = this.model
		if (attributes.contact === undefined || attributes.contact === null){
			attributes.contact = []
		}
		if (attributes.contents === undefined || !attributes.contents || !attributes.contents.length){
			attributes.contents = [{type: 'text', value: '<p></p>'}]
		}
		if (attributes.address_list_file !== undefined){
			attributes.addressListFile = [attributes.address_list_file]
		}
		if (attributes.addressListFile === null){
			attributes.addressListFile = []
		}

		return {
			attributes: attributes,
		}
	},
	methods: {
		addContent: function (type, index){
			let content = {}
			if (type === 'text'){
				content = {type: 'text', value: '<p></p>'}
			}
			if (type === 'image'){
				content = {type: 'image', value: []}
			}

			this.attributes.contents.splice(index + 1, 0, content)
		},
		moveContent: function (move, index){
			moveValueInArray(this.attributes.contents, index, move)
		},
		deleteContent: function (index){
			if (this.attributes.contents.length > 1){
				this.attributes.contents.splice(index, 1)
			}
		},
		updateMilestones: function (){
			const available = _.keyBy(this.filteredMilestones, 'id')
			const prev = this.attributes.recipients.clients.milestones
			this.attributes.recipients.clients.milestones = []

			for (let i = 0; i < prev.length; i++){
				if (available[prev[i]] !== undefined){
					this.attributes.recipients.clients.milestones.push(prev[i])
				}
			}
		}
	}
}
</script>
