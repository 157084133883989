<template>

	<modal
		title="Dokumentum csomag beállítások"
		:loading="loading"
		:closable="true"
		@close="$emit('close')">

		<template #buttons>
			<button type="button" class="btn btn-primary btn-label" @click.prevent="saveSettings"><i class="far fa-save label-icon"></i> Mentés</button>
			<button type="button" class="btn btn-secondary btn-label" @click.prevent="$emit('close')"><i class="far fa-times label-icon"></i> Mégse</button>
		</template>

		<template #default>
			<select-field
				label="Árajánlat"
				:name="'field_' + bundle.id + '_quote_id'"
				label-field="title"
				v-model="attributes.quote_id"
				:options="quotes"
				v-if="!attributes.contract_id"
			>
				<template v-slot:option="option">
					<strong class="pe-2">{{ option.option.public_id }}</strong>{{ option.option.title }}
				</template>
				<template v-slot:selected-option="option">
					<strong class="pe-2">{{ option.option.public_id }}</strong>{{ option.option.title }}
				</template>
			</select-field>

			<select-field
				label="Szerződés"
				:name="'field_' + bundle.id + '_contract_id'"
				label-field="title"
				v-model="attributes.contract_id"
				:options="contracts"
				v-if="!attributes.quote_id"
			>
				<template v-slot:option="option">
					<strong class="pe-2">{{ option.option.public_id }}</strong>{{ option.option.title }}
				</template>
				<template v-slot:selected-option="option">
					<strong class="pe-2">{{ option.option.public_id }}</strong>{{ option.option.title }}
				</template>
			</select-field>

		</template>
	</modal>
</template>

<script>
import SelectField from "../../components/form/SelectField.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import {useProjectDataStore} from "../../stores/projectData";
import Modal from "../../components/Modal.vue";

export default {
	emits: ['closeAndUpdate', 'close'],
	components: {Modal, SwitchField, SelectField},
	props: {
		bundle: Object,
	},
	mounted() {
		this.getData()
	},
	data(){
		return {
			loading: true,
			loaded: false,
			quoteId: null,
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			contractId: null,
			quotes: [],
			contracts: [],
			attributes: {
				quote_id: this.bundle.progress.selectedQuoteId,
				contract_id: this.bundle.progress.selectedContractId
			}
		}
	},
	methods: {
		getData: function (){
			this.loading = true
			let url = '/projects/' + this.projectDataStore.projectId + '/documentBundleSettings/' + this.bundle.id

			axios.get(url).then((response)=>{
				this.loading = false
				if (response.data.success) {
					this.loaded = true
					this.quotes = response.data.quotes
					this.contracts = response.data.contracts
				} else {
					this.flashStore.addFlash(response.data.flash)
					this.$emit('close')
				}
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		saveSettings: function (){
			this.loading = true
			let attributes = _.clone(this.attributes)

			attributes.quoteId = this.quoteId
			attributes.contractId = this.contractId

			axios.post('/projects/' + this.projectDataStore.projectId + '/documentBundleSettings/' + this.bundle.id, attributes).then((response)=>{
				this.loading = false
				if (response.data.success) {
					this.$emit('closeAndUpdate')
				}
				this.flashStore.addFlash(response.data.flash)
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		}
	}
}
</script>

<style scoped>

</style>
<script setup>
</script>
