<template>
	<span :class="badgeClasses" :style="(bgColor ? 'background:' + bgColor + ';' : '') + (fontSize !== undefined ? 'font-size:' + fontSize + 'px;' : '')">
		<i v-if="iconClasses.length" :class="iconClasses"></i>

		<template v-if="label !== undefined">
			<span class="d-md-none">
				<attribute :label="label">
					<template #default>
						<span v-if="contentString !== undefined && contentString !== null && contentString.length">{{contentString}}</span>
						<slot name="default" />
					</template>
				</attribute>
			</span>
			<span class="d-none d-md-block">
				<span v-if="contentString !== undefined && contentString !== null && contentString.length">{{contentString}}</span>
				<slot name="default" />
			</span>
		</template>
		<template v-else>
			<span v-if="contentString !== undefined && contentString !== null && contentString.length">{{contentString}}</span>
			<slot name="default" />
		</template>

		<a href="#" class="badge-close-button" @click.prevent="$emit('removePressed')" v-if="removeButton"><i class="far fa-times"></i></a>
	</span>
</template>

<script>
import Attribute from "./Attribute.vue";

export default {
	components: {Attribute},
	emits: ['removePressed'],
	props: {
		value: {
			type: [Object]
		},
		content: String,
		icon: String,
		color: {
			type: String,
			default: 'light'
		},
		luminosity: String,
		textColor: {
			type: String,
			default: ''
		},
		small: Boolean,
		shadow: Boolean,
		large: Boolean,
		removeButton: Boolean,
		label: String,
		labelAttribute: String,
		wrap: Boolean,
		fontSize: Number
	},
	computed: {
		contentString: {
			get() {
				if (this.labelAttribute !== undefined){
					return this.value[this.labelAttribute]
				}
				if (this.value !== undefined && this.value.name !== undefined && this.value.name){
					return this.value.name
				}
				if (this.value !== undefined && this.value.label !== undefined && this.value.label){
					return this.value.label
				}

				return this.content
			}
		},
		bgColor: {
			get() {
				if (this.value !== undefined && this.value.color !== undefined && this.value.color && this.value.luminosity !== undefined && this.value.luminosity){
					return this.value.color
				}

				return this.color
			}
		},
		iconClasses: {
			get() {
				let tmp = []
				if (this.value !== undefined && this.value.icon !== undefined && this.value.icon){
					tmp.push('far')
					tmp.push('fa-fw')
					tmp.push('fa-' + this.value.icon)
				} else if (this.icon){
					tmp.push('far')
					tmp.push('fa-fw')
					tmp.push('fa-' + this.icon)
				}

				return tmp
			}
		},
		badgeClasses: {
			get() {
				let classes = ['colored-badge']
				let color = this.value !== undefined && this.value.color !== undefined && this.value.color ? this.value.color : this.color
				if (this.value !== undefined && this.value.color !== undefined && this.value.color && this.value.luminosity !== undefined && this.value.luminosity){
					classes.push('colored-badge-' + this.value.luminosity)
				} else if (this.luminosity){
					classes.push('colored-badge-' + this.luminosity)
				} else if (this.value !== undefined && this.value.color !== undefined && this.value.color){
					classes.push('bg-' + this.value.color)
					if (this.value.color === 'light' && !this.textColor){
						classes.push('text-dark')
					}
				} else if (['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'dark', 'light'].indexOf(color) > -1) {
					classes.push('bg-' + color)
					if (color === 'light' && !this.textColor){
						classes.push('text-dark')
					}
				} else {
					classes.push('bg-primary')
				}
				if (this.small){
					classes.push('colored-badge-sm')
				}
				if (this.large){
					classes.push('colored-badge-xl')
				}
				if (this.shadow){
					classes.push('shadow')
				}
				if (this.wrap){
					classes.push('wrap')
				}
				if (this.textColor){
					classes.push('text-' + this.textColor)
				}
				return classes
			}
		},
	},
	data() {
		return {
			isTrue: this.value
		}
	},
}
</script>

<style scoped>

</style>
