<template>
	<div class="d-flex flex-wrap gap-2 mb-3" v-if="app.hasPermission('UsersController_createPayment')">
		<button type="button" class="btn btn-primary btn-label" @click.prevent="createPayment" v-if="unpaidCommission > 0"><i class="fas fa-plus label-icon"></i> Hozzáadás</button>
	</div>
	<AdvancedDataTable
		:data-url="route('users.paymentList', userId)"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		name="userPayments"
		ref="dataTable"
	>
		<template #item-created_at="item">
			<date-time :value="item.created_at" :time="false"/>
		</template>
		<template #item-amount="item">
			<Price
				:value="item.amount"
			/>
		</template>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="table-row-operations d-flex gap-2">
				<button type="button" class="btn btn-primary btn-sm" title="Részletek" @click.prevent="loadData(item.id)"><i class="far fa-eye"></i></button>
				<a :href="route('users.downloadPaymentDocument') + '/' + item.id" target="_blank" class="btn btn-secondary btn-sm" title="Letöltés"><i class="far fa-download"></i></a>
			</div>
		</template>
	</AdvancedDataTable>
	<teleport to="body" v-if="formOpen">
		<div :class="{'overlay-form-wrapper': 1}">
			<div :class="'overlay-form-wrapper--content' + (formLoading ? ' loading' : '')">
				<div class="page-title-box">
					<h4 class="mb-sm-0 font-size-18">{{ paymentModel.id !== undefined ? 'Kifizetés ' + paymentModel.public_id : 'Kifizetés létrehozása' }}</h4>
				</div>
				<form-wrapper
					ref="formWrapper"
					v-model="paymentModel"
					v-slot="{ errors }"
					:submit-function="storePayment"
					:show-buttons="false"
					:no-margin="true"
				>
					<div class="row">
						<div class="col-md-6">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title mb-4">Összeg</h4>

									<h3 class="text-primary fw-bold mb-0">
										<Price :value="paymentAmount"></Price>
									</h3>
								</div>
							</div>
							<div class="card">
								<div class="card-body">
									<h4 class="card-title mb-4">Megjegyzés</h4>

									<textarea-field
										v-model="paymentModel.comment"
										:field-only="true"
										name="comment"
										label="Megjegyzés"
										></textarea-field>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title mb-4">Számla</h4>

									<FileUploader
										field-name="invoices"
										v-model="paymentModel.invoices"
										:editable="true"
										:multiple="true"
									></FileUploader>
								</div>
							</div>
						</div>
					</div>
					<template v-if="unpaidContractCommissions.length">
						<div class="card card-body">
							<h4 class="card-title mb-2">{{ paymentModel.id !== undefined ? 'Fizetett jutalékok' : 'Fizetetlen jutalékok' }}</h4>
							<div class="mb-3 d-flex gap-2" v-if="paymentModel.id === undefined">
								<button type="button" class="btn btn-secondary btn-label" @click.prevent="checkAll"><i class="label-icon far fa-check-square"></i> Mindet be</button>
								<button type="button" class="btn btn-secondary btn-label" @click.prevent="checkNone"><i class="label-icon far fa-square"></i> Mindet ki</button>
							</div>
							<table class="table">
								<thead>
								<tr>
									<th style="width:150px" v-if="paymentModel.id === undefined">Kifizet</th>
									<th>Összeg</th>
									<th v-if="paymentModel.id === undefined">Fizetendő</th>
									<th>Forrás</th>
									<th>Típus</th>
									<th>Létrehozva</th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="commission in unpaidContractCommissions">
									<td v-if="paymentModel.id === undefined">
										<div class="d-flex flex-column gap-2">
											<switch-field
												:name="'selected_' + commission.id"
												label="Kifizet"
												:inline="true"
												:colored-background="true"
												v-model="selectedUnpaidCommissions[commission.id].selected"
												></switch-field>

											<switch-field
												:name="'partial_' + commission.id"
												label="Részleges"
												:inline="true"
												:colored-background="true"
												v-model="selectedUnpaidCommissions[commission.id].partial"
												v-if="selectedUnpaidCommissions[commission.id].selected"
												></switch-field>

											<input-field
												:name="'amount_' + commission.id"
												label="Összeg"
												:field-only="true"
												v-if="selectedUnpaidCommissions[commission.id].selected && selectedUnpaidCommissions[commission.id].partial"
												v-model="selectedUnpaidCommissions[commission.id].amount"
												data-type="number"
												rules="required"
												:max="Math.round(commission.amount - commission.paid_amount)"
												:errors="errors"
												></input-field>
										</div>
									</td>
									<td>
										<Price :value="commission.amount"></Price>
									</td>
									<td v-if="paymentModel.id === undefined">
										<Price :value="commission.amount - commission.paid_amount"></Price>
									</td>
									<td>
										<project-badge
											:project-id="commission.project_id"
											:project-type-id="commission.project_type_id"
											:contract-id="commission.contract_id"
											:key="'PR' + commission.project_id + '_C_' + commission.contract_id"
										>
											{{ commission.clientName }} - {{ commission.projectPublicId }}
										</project-badge>
									</td>
									<td>
										<Property>
											<data-loader :data="['commissionTypes']" v-slot="{ dataStore }">
												<color-badge
													:value="dataStore.getData('commissionTypes')[commission.type]"
													:small="true"
												></color-badge>
											</data-loader>
										</Property>
										<Property v-if="commission.sourceUserName">
											<i class="far fa-user"></i> {{ commission.sourceUserName }}
										</Property>
									</td>
									<td>
										<date-time :value="commission.created_at"/>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</template>
					<div class="alert alert-danger" v-else>
						Nincs kifizetetlen jutalék.
					</div>

					<div class="mt-3 d-flex gap-2">
						<button v-if="
						unpaidContractCommissions.length
						|| paymentModel.id !== undefined
						|| !paymentModel.invoice_id" :disabled="errors.length > 0 || paymentAmount < 1" type="submit" class="btn btn-success btn-label"><i class="far fa-save label-icon"></i> Mentés</button>
						<button type="button" class="btn btn-secondary btn-label" @click.prevent="formOpen = false"><i class="far fa-times label-icon"></i> Bezár</button>
					</div>

				</form-wrapper>
			</div>
		</div>
	</teleport>
</template>
<script>
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import BooleanBadge from "../../components/badges/BooleanBadge.vue";
import Price from "../../components/Price.vue";
import InputField from "../../components/form/InputField.vue";
import SelectField from "../../components/form/SelectField.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";
import Property from "../../components/Property.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import FileUploader from "../../components/FileUploader.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import DateTime from "../../components/DateTime.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";

export default {
	components: {
		ProjectBadge,
		DateTime,
		DataLoader,
		FileUploader,
		TextareaField,
		SwitchField,
		Property,
		ProjectTypeCell,
		ColorBadge,
		FormWrapper,
		SelectField, InputField,
		Price,
		AdvancedDataTable,
		BooleanBadge,
	},
	props: {
		userId: Number,
		isEditable: Boolean,
		unpaidCommission: Number,
	},
	computed: {
		paymentAmount: {
			get(){
				if (this.paymentModel.id !== undefined){
					return this.paymentModel.amount
				}
				let amount = 0
				_.forEach(this.unpaidContractCommissions, (commission) => {
					if (this.selectedUnpaidCommissions[commission.id].selected){
						if (this.selectedUnpaidCommissions[commission.id].partial){
							amount += this.selectedUnpaidCommissions[commission.id].amount
						} else {
							amount += commission.amount - commission.paid_amount
						}
					}
				})

				return amount
			}
		}
	},
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'public_id',
				sortable: true,
			},
			{
				text: 'Időpont',
				value: 'created_at',
				sortable: true,
			},
			{
				text: 'Összeg',
				value: 'amount',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Megjegyzés',
				value: 'comment',
				sortable: false,
			},
			{
				text: 'Létrehozó',
				value: 'createdByUserName',
				sortable: true,
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			paymentModel: {},
			formOpen: false,
			formLoading: true,
			flashStore: useFlashStoreStore(),
			app: useApplicationStore(),
			unpaidContractCommissions: [],
			selectedUnpaidCommissions: {}
		}
	},
	methods: {
		storePayment: function (e){
			let attributes = this.paymentModel
			attributes.selectedCommissions = this.selectedUnpaidCommissions
			axios.post(route('users.paymentPost', this.userId) + '?action=storePayment', attributes).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				if (response.data.success){
					this.formOpen = false

					this.$refs.dataTable.getData()
				}
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		loadData: function (id){
			this.formOpen = true
			this.formLoading = true
			let url = route('users.paymentPost', this.userId) + '?action=load&id=' + (id !== undefined ? id : '')
			axios.get(url).then((response) => {
				this.formLoading = false
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				if (response.data.success) {
					this.paymentModel = response.data.model
					this.unpaidContractCommissions = response.data.contractCommissions

					this.selectedUnpaidCommissions = {}
					_.forEach(this.unpaidContractCommissions, (commission) => {
						this.selectedUnpaidCommissions[commission.id] = {
							selected: true,
							partial: false,
							amount: Math.round(commission.amount - commission.paid_amount)
						}
					})
				} else {
					this.formOpen = false
				}
			}).catch((error) => {
				this.flashStore.addFlash({
					level: 'danger',
					message: error.response !== undefined ? error.response.data.message : error
				})
			})
		},
		createPayment: function (){
			this.loadData()
		},
		submitForm: function (){
			this.$refs.formWrapper.submit()
		},
		checkAll: function (){
			_.forEach(this.selectedUnpaidCommissions, (v, k) => {
				this.selectedUnpaidCommissions[k].selected = true
			})
		},
		checkNone: function (){
			_.forEach(this.selectedUnpaidCommissions, (v, k) => {
				this.selectedUnpaidCommissions[k].selected = false
			})
		}
	}
}
</script>

<style scoped>
</style>
