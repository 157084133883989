<template>
	<AdvancedDataTable
		:data-url="route('projects.deliveredProducts', [projectId, contractId])"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		compact
		name="contractDeliveredProducts"
		ref="table"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<mobile>
					<div class="mobile-cell">
						<div class="stacked-cell">
							<product-badge
								:product-id="item.product_id"
								:product-name="item.name"
								:product-manufacturer-id="item.product_manufacturer_id"
								:key="'PB' + item.product_id"
								mobile
							/>

							<div class="mobile-horizontal">
								<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
									<color-badge
										icon="fas fa-industry-alt"
										color="light"
										small
										v-if="dataStore.getData('productManufacturers', true)[item.product_manufacturer_id] !== undefined"
									>
										{{dataStore.getData('productManufacturers', true)[item.product_manufacturer_id].name}}
									</color-badge>
								</data-loader>
								<color-badge
									icon="fas fa-hashtag"
									color="light"
									small
								>
									{{ item.sku }}
								</color-badge>
							</div>
							<div class="mobile-horizontal">
								<color-badge
									color="light"
									label="Mennyiség"
								>
									<number :value="item.quantity"/>
								</color-badge>
								<color-badge
									:color="item.to_return ? 'warning' : 'light'"
									label="Visszáru"
								>
									<number :value="item.to_return"/>
								</color-badge>
							</div>
						</div>
					</div>
				</mobile>
			</div>
		</template>
		<template #item-to_return="item">
			<number :value="item.to_return"/>
		</template>
		<template #item-quantity="item">
			<number :value="item.quantity"/>
		</template>
		<template #item-created_at="item">
			<date-time :value="item.created_at"/>
		</template>
		<template #item-name="item">
			<div class="stacked-cell">
				<product-badge
					:product-id="item.product_id"
					:product-name="item.name"
					:product-manufacturer-id="item.product_manufacturer_id"
					:key="'PB' + item.product_id"
					desktop
				/>

				<div class="horizontal">
					<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
						<color-badge
							icon="fas fa-industry-alt"
							color="light"
							small
							v-if="dataStore.getData('productManufacturers', true)[item.product_manufacturer_id] !== undefined"
						>
							{{dataStore.getData('productManufacturers', true)[item.product_manufacturer_id].name}}
						</color-badge>
					</data-loader>
					<color-badge
						icon="fas fa-hashtag"
						color="light"
						small
					>
						{{ item.sku }}
					</color-badge>
				</div>
			</div>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import DataLoader from "../../components/DataLoader.vue";
import vSelect from "vue-select";
import Number from "../../components/Number.vue";
import DateTime from "../../components/DateTime.vue";
import TitleBar from "../../components/TitleBar.vue";
import {Link} from "@inertiajs/vue3";
import ColorBadge from "../../components/ColorBadge.vue";
import Attribute from "../../components/Attribute.vue";
import TableActionCell from "../../components/TableActionCell.vue";
import ShipmentDestinationCell from "../inventory/ShipmentDestinationCell.vue";
import ShipmentSourceCell from "../inventory/ShipmentSourceCell.vue";
import ProductManufacturerSelectField from "../../components/form/ProductManufacturerSelectField.vue";
import ProductBadge from "../../components/badges/ProductBadge.vue";
import Mobile from "../../components/responsive/Mobile.vue";
import Desktop from "../../components/responsive/Desktop.vue";

export default {
	components: {
		Desktop,
		Mobile,
		ProductBadge,
		ProductManufacturerSelectField,
		ShipmentSourceCell, ShipmentDestinationCell,
		TableActionCell,
		Attribute, ColorBadge, TitleBar, DateTime, Link, Number, vSelect, DataLoader, AdvancedDataTable},
	props: {
		contractId: Number,
		projectId: Number,
	},
	data(){
		let columns = [
			{
				text: "Termék",
				value: "name"
			},
			{
				text: "Mennyiség",
				value: "quantity",
			},
			{
				text: "Visszáru",
				value: "to_return",
			},
			{
				text: "Dátum",
				value: "created_at",
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]
		return {
			columns: columns,
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>
