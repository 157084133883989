<template>
	<div class="table-row-operations d-flex gap-2 flex-column">
		<div class="d-flex gap-2 table-row-operations-buttons">
			<template v-if="displayEditButton">
				<Link v-if="editButtonLink" :href="editButtonLink" @click="viewButtonPressed" class="btn btn-primary btn-sm waves-effect" title="Szerkesztés"><i class="fas fa-edit"></i></Link>
				<button v-else type="button" @click.prevent="editButtonPressed" class="btn btn-primary btn-sm waves-effect" title="Szerkesztés"><i class="fas fa-edit"></i></button>
			</template>
			<template v-if="displayViewButton">
				<Link v-if="viewButtonLink" :href="viewButtonLink" @click="viewButtonPressed" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="fas fa-eye"></i></Link>
				<button v-else type="button" @click.prevent="viewButtonPressed" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="fas fa-eye"></i></button>
			</template>

			<slot :row="data"></slot>

			<confirm-button
				v-if="displayDeleteButton"
				:data="data"
				:emit-delete-event="emitDeleteEvent"
				:url="deleteButtonUrl"
				message="Biztos, hogy törölni akarod?"
				@confirmed="deleteButtonPressed"
			></confirm-button>
		</div>

		<div v-if="trackedEditMode !== undefined && trackedEditMode.enabled && isTrackedEditUpdated" class="text-center w-100">
			<color-badge
				:color="data.updated_by_user_id !== undefined && data.updated_by_user_id !== app.userId ? 'warning' : 'success'"
				icon="far fa-edit"
				small
			>
				<span class="d-none d-md-inline"><date-time :value="data.updated_at"/></span>
			</color-badge>
		</div>
	</div>
</template>

<script>
import ConfirmButton from "./ConfirmButton";
import {Link} from "@inertiajs/vue3";
import ColorBadge from "./ColorBadge.vue";
import DateTime from "./DateTime.vue";
import {useApplicationStore} from "../stores/applicationStore";
export default {
	components: {DateTime, ColorBadge, ConfirmButton, Link},
	props: {
		data: {
			type: Object
		},
		trackedEditMode: {
			type: Object
		},
		editUrl: {
			type: String,
			default: ''
		},
		deleteUrl: {
			type: String,
			default: ''
		},
		isEditable: {
			type: [Boolean, Function],
			default: false
		},
		isDeletable: {
			type: [Boolean, Function],
			default: false
		},
		emitEditEvent: {
			type: Boolean
		},
		emitViewEvent: {
			type: Boolean
		},
		emitDeleteEvent: {
			type: Boolean
		}
	},
	emits: ['editButtonPressed', 'viewButtonPressed', 'deleteButtonPressed'],
	computed: {
		displayEditButton: {
			get(){
				if (!this.editUrl && !this.emitEditEvent){
					return false
				}
				if (this.isEditable === false && this.data.editable !== undefined && this.data.editable){
					return true
				}
				return !(this.isEditable instanceof Function ? !this.isEditable(this.data) : !this.isEditable);
			}
		},
		isTrackedEditUpdated: {
			get(){
				if (!this.trackedEditMode.enabled ||!this.trackedEditMode.enabledAt){
					return false
				}
				if (this.data.id === undefined || this.data.updated_at === undefined || this.data.updated_at === null){
					return false
				}
				return new Date(this.data.updated_at) >= new Date(this.trackedEditMode.enabledAt)
			}
		},
		editButtonLink: {
			get(){
				return this.editUrl.length ? this.editUrl + '/' + this.data.id : ''
			}
		},
		displayViewButton: {
			get(){
				if (!this.editUrl && !this.emitViewEvent){
					return false
				}
				if (this.data.editable !== undefined && this.data.editable){
					return false
				}
				return this.isEditable instanceof Function ? !this.isEditable(this.data) : !this.isEditable;
			}
		},
		viewButtonLink: {
			get(){
				return this.editUrl.length ? this.editUrl + '/' + this.data.id : ''
			}
		},
		displayDeleteButton: {
			get(){
				if (!this.deleteUrl && !this.emitDeleteEvent){
					return false
				}
				if (this.data.deletable !== undefined && this.data.deletable){
					return true
				}
				return this.isDeletable instanceof Function ? this.isDeletable(this.data) : this.isDeletable;
			}
		},
		deleteButtonUrl: {
			get(){
				return this.deleteUrl.length ? this.deleteUrl + '/' + this.data.id : ''
			}
		},
	},
	data(){
		return {
			app: useApplicationStore()
		}
	},
	methods: {
		editButtonPressed: function(){
			this.$emit('editButtonPressed', this.data.id)
		},
		viewButtonPressed: function(){
			this.$emit('viewButtonPressed', this.data.id)
		},
		deleteButtonPressed: function(){
			this.$emit('deleteButtonPressed', this.data.id, this.deleteButtonUrl)
		},
	}
}
</script>

<style scoped>

</style>
