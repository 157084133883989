<script setup>
import {onMounted, ref} from "vue";
import ProjectTypeCell from "./ProjectTypeCell.vue";
import Attribute from "../../components/Attribute.vue";
import Number from "../../components/Number.vue";
import ProjectMilestonesCell from "./ProjectMilestonesCell.vue";
import {Link} from "@inertiajs/vue3";
import Price from "../../components/Price.vue";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import DateTime from "../../components/DateTime.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import TaskModal from "../dashboard/TaskModal.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import UserAttribute from "../../components/attributes/UserAttribute.vue";
import AddressAttribute from "../../components/attributes/AddressAttribute.vue";

const props = defineProps({
	projectId: Number,
	contractId: Number,
	quoteId: Number,
	absolute: {
		type: Boolean,
		default: false
	},
	top: {
		type: Boolean,
		default: true
	}
})

const emit = defineEmits(['loaded'])
const app = useApplicationStore()
const dataStore = useGeneralDataStore()
const showProjectFull = ref(false)
const loaded = ref(false)
const error = ref(false)
const createTaskFormVisible = ref(false)
const details = ref({})

function load(){
	if (!loaded.value) {
		let url = '/tooltips/project/' + props.projectId
		let type = 'projects'
		let id = props.projectId
		let loadedData = dataStore.getTooltipData(type, id)
		if (props.contractId !== undefined){
			url = '/tooltips/contract/' + props.contractId
			type = 'contracts'
			id = props.contractId
			loadedData = dataStore.getTooltipData(type, id)
		}
		if (props.quoteId !== undefined){
			url = '/tooltips/quote/' + props.quoteId
			type = 'quotes'
			id = props.quoteId
			loadedData = dataStore.getTooltipData(type, id)
		}
		if (loadedData !== false && loadedData){
			details.value = loadedData
			loaded.value = true
			emit('loaded', details.value)
		} else {
			axios.get(url, {}).then((response) => {
				details.value = response.data.details
				dataStore.addTooltip(type, id, details.value)
				loaded.value = true
				emit('loaded', details.value)
			}).catch((e) => {
				error.value = true
			})
		}
	}
}
onMounted(() => {
	load()
})
</script>

<template>
	<div :class="{'overview-tooltip': 1}">
		<div class="overview-tooltip-head">
			<template v-if="loaded">
				<div class="d-flex">
					<div class="flex-grow-1 d-flex gap-2 flex-column align-items-start">
						<h5 class="font-size-15 m-0">{{ details.public_id }}</h5>
						<h4 class="font-size-16 m-0">{{ details.client }}</h4>
						<address-attribute :address="details.address" />
						<project-type-cell :data="{projectTypeId: details.project_type_id}" small/>
					</div>
					<div class="d-flex flex-column gap-2">
						<Link :href="'/projects/edit/' + details.id" class="btn btn-soft-secondary btn-sm" title="Adatlap"><i class="far fa-external-link"></i></Link>
						<button
							class="btn btn-soft-secondary btn-sm"
							:title="showProjectFull ? 'Részletek elrejtése' : 'Részletek mutatása'"
							v-if="details.contract !== undefined || details.quote !== undefined"
							@click.prevent="showProjectFull = !showProjectFull">
							<i :class="{'far': 1, 'fa-eye': !showProjectFull, 'fa-eye-slash': showProjectFull}"></i>
						</button>
						<button
							class="btn btn-soft-secondary btn-sm"
							title="Feladat létrehozása"
							v-if="app.hasPermission('ProjectsController_canCreateTask')"
							@click.prevent="createTaskFormVisible = true">
							<i :class="{'far': 1, 'fa-list': 1}"></i>
						</button>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="placeholder-glow mb-1">
					<span class="placeholder font-size-15" :style="'width:32%'"></span>
				</div>
				<div class="placeholder-glow mb-1">
					<span class="placeholder font-size-16" :style="'width:51%'"></span>
				</div>
			</template>
		</div>
		<div class="overview-tooltip-body" v-if="(details.contract === undefined && details.quote === undefined) || showProjectFull">
			<template v-if="loaded">
				<div class="details-box">
					<attribute
						icon="far fa-file-invoice-dollar"
						label="Árajánlatok"
						>
						<number :value="details.quotes"/>
					</attribute>
					<attribute
						icon="far fa-file-contract"
						label="Szerződések"
						>
						<number :value="details.contracts"/>
					</attribute>

					<user-attribute :user-id="details.handler_user_id" handler />

					<attribute
						icon="far fa-truck-loading"
						label="Kiszállítandó termékek"
					>
						<strong :class="'text-' + (details.productsToDeliver > 0 ? 'warning' : 'success')">
							<number :value="details.productsToDeliver"/>
						</strong>
					</attribute>

					<attribute
						icon="far fa-cubes"
						label="Lefoglalt termékek"
					>
						<strong :class="'text-' + (details.productsReserved > 0 ? 'warning' : 'dark')">
							<number :value="details.productsReserved"/>
						</strong>
					</attribute>

					<attribute
						icon="far fa-cube"
						label="Kiszállított termékek"
					>
						<strong :class="'text-' + (details.deliveredProducts > 0 ? 'success' : 'dark')">
							<number :value="details.deliveredProducts"/>
						</strong>
					</attribute>

					<attribute
						icon="far fa-dolly"
						label="Visszaszállítandó termékek"
					>
						<strong :class="'text-' + (details.productsToReturn > 0 ? 'danger' : 'dark')">
							<number :value="details.productsToReturn"/>
						</strong>
					</attribute>

					<ProjectMilestonesCell :data="details"/>
				</div>
			</template>
			<template v-else>
				<div class="p-2">
					<div class="placeholder-glow mb-2">
						<span class="placeholder font-size-16" :style="'width:51%'"></span>
					</div>
					<div class="placeholder-glow mb-2">
						<span class="placeholder font-size-16" :style="'width:32%'"></span>
					</div>
				</div>
			</template>
		</div>
		<template v-if="details.quote !== undefined">
			<div class="overview-tooltip-subtitle">
				<div class="d-flex">
					<div class="flex-grow-1">
						<h5 class="font-size-15 mb-1">{{ details.quote.public_id }}</h5>
						<h4 class="font-size-16 mb-1" v-if="details.quote.title">{{ details.quote.title }}</h4>
						<div class="horizontal">
							<color-badge icon="far fa-file-invoice-dollar" color="light" content="Árajánlat" small />
							<data-loader :data="['quoteStatusLabels']" v-slot="{ dataStore }" hide-loader>
								<color-badge
									v-if="dataStore.getData('quoteStatusLabels')[details.quote.status] !== undefined"
									:color="dataStore.getData('quoteStatusLabels')[details.quote.status].color"
									:icon="dataStore.getData('quoteStatusLabels')[details.quote.status].icon"
									:content="dataStore.getData('quoteStatusLabels')[details.quote.status].name"
									small
								/>
							</data-loader>
						</div>
					</div>
					<div class="d-flex flex-column gap-2">
						<Link :href="'/projects/edit/' + details.id + '/quotes/quote:' + details.quote.id" class="btn btn-soft-secondary btn-sm" title="Adatlap"><i class="far fa-external-link"></i></Link>
					</div>
				</div>
			</div>
			<div class="overview-tooltip-body">
				<div class="details-box">
					<attribute
						icon="far fa-coins"
						label="Bruttó végösszeg"
					>
						<price :value="details.quote.total_price_gross" :currency-id="details.quote.currency_id"/>
					</attribute>
					<attribute
						icon="far fa-clock"
						label="Elolvasva"
					>
						<span class="text-success" v-if="details.quote.read_at">
							<date-time :value="details.quote.read_at"/>
						</span>
						<color-badge v-else
									 color="danger"
									 icon="far fa-times"
									 content="nincs"/>
					</attribute>
					<attribute
						icon="far fa-clock"
						label="Megválaszolva"
					>
						<span class="text-success" v-if="details.quote.answered_at">
							<date-time :value="details.quote.answered_at"/>
						</span>
						<color-badge v-else
									 color="danger"
									 icon="far fa-times"
									 content="nincs"/>
					</attribute>
				</div>
			</div>
		</template>
		<template v-if="details.contract !== undefined">
			<div class="overview-tooltip-subtitle">
				<div class="d-flex">
					<div class="flex-grow-1">
						<h5 class="font-size-15 mb-1">{{ details.contract.public_id }}</h5>
						<h4 class="font-size-16 mb-1" v-if="details.contract.title">{{ details.contract.title }}</h4>
						<div class="horizontal">
							<color-badge icon="far fa-file-contract" color="light" content="Szerződés" small />
							<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }">
								<color-badge
									:color="dataStore.getData('contractStatusLabels')[details.contract.status].color"
									:icon="dataStore.getData('contractStatusLabels')[details.contract.status].icon"
									:content="dataStore.getData('contractStatusLabels')[details.contract.status].name"
									v-if="dataStore.getData('contractStatusLabels')[details.contract.status] !== undefined"
									small
									/>
							</data-loader>
						</div>
					</div>
					<div class="d-flex flex-column gap-2">
						<Link :href="'/projects/edit/' + details.id + '/contracts/contract:' + details.contract.id" class="btn btn-soft-secondary btn-sm" title="Adatlap"><i class="far fa-external-link"></i></Link>
					</div>
				</div>
			</div>
			<div class="overview-tooltip-body">
				<div class="details-box">
					<attribute
						icon="far fa-coins"
						label="Bruttó végösszeg"
					>
						<price :value="details.contract.total_price_gross" :currency-id="details.contract.currency_id"/>
					</attribute>
					<attribute
						icon="far fa-clock"
						label="Aláírva"
						v-if="details.contract.signed_at"
					>
						<date-time :value="details.contract.signed_at"/>
					</attribute>

					<attribute
						icon="far fa-truck-loading"
						label="Kiszállítandó termékek"
					>
						<strong :class="'text-' + (details.contract.productsToDeliver > 0 ? 'warning' : 'success')">
							<number :value="details.contract.productsToDeliver"/>
						</strong>
					</attribute>

					<attribute
						icon="far fa-cubes"
						label="Lefoglalt termékek"
					>
						<strong :class="'text-' + (details.contract.productsReserved > 0 ? 'warning' : 'dark')">
							<number :value="details.contract.productsReserved"/>
						</strong>
					</attribute>

					<attribute
						icon="far fa-cube"
						label="Kiszállított termékek"
					>
						<strong :class="'text-' + (details.contract.deliveredProducts > 0 ? 'success' : 'dark')">
							<number :value="details.contract.deliveredProducts"/>
						</strong>
					</attribute>

					<attribute
						icon="far fa-dolly"
						label="Visszaszállítandó termékek"
					>
						<strong :class="'text-' + (details.contract.productsToReturn > 0 ? 'danger' : 'dark')">
							<number :value="details.contract.productsToReturn"/>
						</strong>
					</attribute>
				</div>
			</div>
		</template>
	</div>
	<task-modal
		v-if="createTaskFormVisible"
		:project-id="projectId"
		@close="createTaskFormVisible = false"
		@close-and-reload="createTaskFormVisible = false"
	/>
</template>

<style scoped>

</style>
