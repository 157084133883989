<template>
	<span class="text-nowrap">{{ formattedValue }}</span>
</template>

<script>
export default {
	props: {
		value: null,
		time: {
			type: Boolean,
			default: true
		},
		date: {
			type: Boolean,
			default: true
		}
	},
	created: function () {
		this.moment = moment
	},
	computed: {
		formattedValue: {
			get(){
				if (this.value === null || !this.value){
					return ''
				}
				const moment = this.moment(this.value)
				if (!this.date){
					return moment.format('HH:mm')
				}
				return this.time ? moment.format('YYYY-MM-DD HH:mm') : moment.format('YYYY-MM-DD')
			}
		}
	}
}
</script>

<style scoped>

</style>
