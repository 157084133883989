<template>

</template>

<script>
import FormWrapper from "../../components/FormWrapper.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import SelectField from "../../components/form/SelectField.vue";
import InputField from "../../components/form/InputField.vue";
import FileUploader from "../../components/FileUploader.vue";
import {objectToArray} from "../../functions";
import InventoryShipmentSelect from "../../components/form/InventoryShipmentSelect.vue";
import ContractSelect from "../../components/form/ContractSelect.vue";
import DataLoader from "../../components/DataLoader.vue";
import WarehouseSelectField from "../../components/form/WarehouseSelectField.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import {useApplicationStore} from "../../stores/applicationStore";
import TitleBar from "../../components/TitleBar.vue";
export default {
	emits: ['update:modelValue', 'shipmentCreated'],
	components: {
		TitleBar,
		WarehouseSelectField,
		DataLoader,
		ContractSelect, InventoryShipmentSelect, InputField, TextareaField, FormWrapper, SelectField, FileUploader},
	props: {
		modelValue: Object,
		storeUrl: String,
		backUrl: String,
		contracts: Array,
		shipments: Array,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		shipmentSearchUrlFormatted: {
			get(){
				if (this.componentValue.correction_type && this.dataStore.getData('inventoryCorrectionTypes')[this.componentValue.correction_type].hasInventoryShipment){
					return route('inventory.shipmentSearch') + '?type=' + this.dataStore.getData('inventoryCorrectionTypes')[this.componentValue.correction_type].hasInventoryShipment + '&q='
				}

				return route('inventory.shipmentSearch') + '?q='
			}
		},
		destinationWarehouses: {
			get(){
				let payload = _.filter(this.warehouses, (item) => {
					return item.id !== this.componentValue.source_warehouse_id
				})

				if (this.componentValue.source_warehouse_id === this.componentValue.destination_warehouse_id){
					let first = _.first(payload)
					if (first) {
						this.componentValue.destination_warehouse_id = first.id
					} else {
						this.componentValue.destination_warehouse_id = ''
					}
				}

				return payload
			}
		}
	},
	data(){
		return {
			attributes: this.initialAttributes,
			dataStore: useGeneralDataStore(),
			app: useApplicationStore()
		}
	},
	methods: {
		shipmentCreated: function(attributes){
			this.$emit('shipmentCreated', attributes)
		},
	}
}
</script>

<style scoped>

</style>
