<template>
	<TitleBar :title="attributes.id !== undefined ? 'Nyelv módosítása' : 'Nyelv hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('translations.store', model)"
		:back-url="route('translations.index')"
		v-model="attributes"
	>
		<template #default="{activeTab, errors}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<switch-field
								label="Alapértelmezett"
								name="is_default"
								v-model="attributes.is_default"
								:disabled="!attributes.editable"
							/>
							<switch-field
								label="Aktív"
								name="active"
								v-model="attributes.active"
								:disabled="!attributes.editable"
							/>

							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>

							<input-field
								label="Rövid név"
								name="short_name"
								rules="required"
								v-model="attributes.short_name"
								:disabled="!attributes.editable"
								:errors="errors"
								info="Ez jelenik meg a nyelvválasztóban"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								label="Kód"
								name="code"
								rules="required"
								v-model="attributes.code"
								:disabled="!attributes.editable"
								:errors="errors"
								info="ISO 639-1 szabvány szerinti nyelvkód (https://www.w3schools.com/tags/ref_language_codes.asp)"
							/>

							<input-field
								label="Sorrend"
								name="order"
								data-type="number"
								v-model="attributes.order"
								:disabled="!attributes.editable"
								:errors="errors"
								info="Növekvő sorrendben, 0 az első"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import SwitchField from "../components/form/SwitchField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";

export default {
	components: {InputField, FormWrapper, SwitchField, TitleBar},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		return {
			attributes: this.model
		}
	},
}
</script>
