<template>
	<TitleBar title="Részletes készlet" />

	<AdvancedDataTable
		:data-url="route('inventory.detailedList')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="inventoryDetailed"
	>
		<template #navigation>
			<ButtonGroup>
				<template #add>
					<AddShipmentButton/>
				</template>
				<template #index>
					<Link :href="route('inventory.index')" class="btn btn-secondary btn-label"><i class="far fa-inventory label-icon"></i> Raktárkészlet</Link>
				</template>
				<template #shipments>
					<Link :href="route('inventory.shipments')" class="btn btn-secondary btn-label"><i class="far fa-truck label-icon"></i> Szállítmányok</Link>
				</template>
				<template #delivered>
					<Link :href="route('inventory.delivered')" class="btn btn-secondary btn-label"><i class="far fa-truck-loading label-icon"></i> Kiszállított áruk</Link>
				</template>
			</ButtonGroup>
		</template>
		<template #filters="{serverOptions}">
			<div class="data-table-filter-item">
				<warehouse-select-field
					v-model="serverOptions.warehouse_id"
					label="Raktárak"
					name="warehouse_id"
					:field-only="true"
				/>
			</div>
			<div class="data-table-filter-item">
				<product-category-select-field
					v-model="serverOptions.product_category_id"
					name="product_category_id"
					label="Kategória"
					:field-only="true"
				/>
			</div>
			<div class="data-table-filter-item">
				<product-manufacturer-select-field
					v-model="serverOptions.product_manufacturer_id"
					name="product_manufacturer_id"
					label="Gyártó"
					:field-only="true"
				/>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<mobile>
					<div class="mobile-cell">
						<div class="stacked-cell">
							<product-badge
								:product-id="item.product_id"
								:product-manufacturer-id="item.product_manufacturer_id"
								:product-name="item.productName"
								:product-category-id="item.product_category_id"
								:sku="item.sku"
								:key="'PB' + item.product_id"
								mobile
							/>

							<color-badge
								color="dark"
								icon="far fa-tags"
								:content="item.serial_number"
								v-if="item.serial_number"
							/>

							<div class="mobile-horizontal">
								<data-loader :data="['warehouses']" v-slot="{ dataStore }">
									<color-badge
										:color="item.stock > 0 ? 'info' : 'danger'"
									>
										<number :value="item.stock"/> {{ item.unit }}
									</color-badge>
									<WarehouseBadge :warehouse-id="item.warehouse_id" />
								</data-loader>
							</div>
						</div>
					</div>
				</mobile>
				<div class="table-row-operations d-flex gap-2">
					<Link :href="(item.serial_number ? route('inventory.serialNumber') + '/' + item.serial_number : route('inventory.product') + '/' + item.product_id)" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="far fa-eye"></i></Link>
				</div>
			</div>
		</template>
		<template #item-productName="item">
			<product-badge
				:product-id="item.product_id"
				:product-manufacturer-id="item.product_manufacturer_id"
				:product-name="item.productName"
				:product-category-id="item.product_category_id"
				:key="'PB' + item.product_id"
				desktop
			/>
		</template>
		<template #item-stock="item">
			<color-badge :color="item.stock > 0 ? 'info' : 'danger'">
				<number :value="item.stock"/>
			</color-badge>
		</template>
		<template #item-warehouse_id="item">
			<WarehouseBadge :warehouse-id="item.warehouse_id" />
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import {Link} from "@inertiajs/vue3";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import AddShipmentButton from "../modules/inventory/AddShipmentButton.vue";
import TitleBar from "../components/TitleBar.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import ColorBadge from "../components/ColorBadge.vue";
import DateTime from "../components/DateTime.vue";
import Number from "../components/Number.vue";
import ProductManufacturerSelectField from "../components/form/ProductManufacturerSelectField.vue";
import ProductCategorySelectField from "../components/form/ProductCategorySelectField.vue";
import WarehouseBadge from "../components/badges/WarehouseBadge.vue";
import ProductBadge from "../components/badges/ProductBadge.vue";
import Mobile from "../components/responsive/Mobile.vue";

export default {
	layout: AppLayout,
	components: {
		Mobile,
		ProductBadge,
		WarehouseBadge,
		ProductCategorySelectField, ProductManufacturerSelectField,
		Number,
		DateTime,
		ColorBadge,
		ButtonGroup, TitleBar, AddShipmentButton, DataLoader, AdvancedDataTable, WarehouseSelectField, Link},
	data(){
		let columns = [
			{
				text: 'Terméknév',
				value: 'productName',
				sortable: true
			},
			{
				text: 'Cikkszám',
				value: 'sku',
				sortable: true
			},
			{
				text: 'Sorozatszám',
				value: 'serial_number',
				sortable: true
			},
			{
				text: 'Raktár',
				value: 'warehouse_id',
				sortable: true
			},
			{
				text: 'Mennyiség',
				value: 'stock',
				align: 'right',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
}
</script>
