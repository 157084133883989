<script setup>
import {ref} from "vue";
import {useApplicationStore} from "../../stores/applicationStore";
import {useGeneralDataStore} from "../../stores/generalData";
import ColorBadge from "../ColorBadge.vue";
import Popper from "vue3-popper";
import {Link} from "@inertiajs/vue3";
import Attribute from "../Attribute.vue";
import Number from "../Number.vue";
import ShipmentSourceCell from "../../modules/inventory/ShipmentSourceCell.vue";
import ProductBadge from "./ProductBadge.vue";
import EmailAddress from "../EmailAddress.vue";
import PhoneNumber from "../PhoneNumber.vue";
import UserAttribute from "../attributes/UserAttribute.vue";
import DateTimeAttribute from "../attributes/DateTimeAttribute.vue";
import UserBadge from "./UserBadge.vue";
import ProjectBadge from "./ProjectBadge.vue";

const props = defineProps({
	inquiryId: Number,
	publicId: String,
	name: String,
	disableLink: Boolean,
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
})

const loaded = ref(false)
const app = useApplicationStore()
const open = ref(false)
const error = ref(false)
const dataStore = useGeneralDataStore()
const details = ref({})

function load(){
	if (!loaded.value) {
		let loadedData = dataStore.getTooltipData('inquiry', props.inquiryId)
		if (loadedData !== false && loadedData){
			details.value = loadedData
			loaded.value = true
		} else {
			axios.get('/tooltips/inquiry/' + props.inquiryId, {}).then((response) => {
				details.value = response.data.details
				dataStore.addTooltip('inquiry', props.inquiryId, details.value)
				loaded.value = true
			}).catch((e) => {
				error.value = true
			})
		}
	}
}

function onOpen(){
	open.value = true
	load()
}

</script>

<template>
	<template v-if="inquiryId">
		<template v-if="!disableLink">
			<VDropdown
				@show="onOpen">
				<div class="vertical">
					<a :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" href="#">
						<i class="far fa-user-plus"></i> <span class="colored-badge-content-wrap">{{ publicId + (name ? ' - ' + name : '') }}</span> <i class="far fa-info-circle font-size-10"></i>
					</a>
				</div>
				<template #popper>
					<div :class="{'overview-tooltip': 1}">
						<div class="overview-tooltip-head">
							<template v-if="loaded">
								<div class="d-flex">
									<div class="flex-grow-1">
										<color-badge
											color="secondary"
											icon="far fa-user-plus"
											small
											content="Érdeklődő"
										/>
										<h5 class="font-size-15 mt-1 mb-1">{{ details.name }}</h5>
										<h5 class="font-size-14 mt-1 mb-1">{{ details.public_id }}</h5>
										<color-badge
											:color="details.is_company ? 'dark' : 'light'"
											:icon="details.is_company ? 'far fa-industry' : 'far fa-user'"
											:content="details.is_company ? 'Cég' : 'Magánszemély'"
											small/>
									</div>
									<div class="d-flex flex-column gap-2">
										<Link :href="'/inquiries/edit/' + inquiryId" class="btn btn-soft-secondary btn-sm" title="Adatlap" v-if="app.hasPermission('InquiriesController_view')"><i class="far fa-external-link"></i></Link>
									</div>
								</div>
							</template>
							<template v-else>
								<div class="placeholder-glow mb-1">
									<span class="placeholder font-size-15" :style="'width:32%'"></span>
								</div>
								<div class="placeholder-glow mb-1">
									<span class="placeholder font-size-14" :style="'width:51%'"></span>
								</div>
							</template>
						</div>
						<div class="overview-tooltip-body">
							<template v-if="loaded">
								<div class="details-box">
									<EmailAddress :value="details.email" v-if="details.email"></EmailAddress>
									<PhoneNumber :value="details.phone" v-if="details.phone"></PhoneNumber>

									<user-attribute :user-id="details.handler_user_id" handler/>
									<date-time-attribute :value="details.created_at" created/>
									<user-attribute :user-id="details.created_by_user_id" creator/>
									<attribute v-if="details.comment" icon="far fa-comment" label="Megjegyzés" :content="details.comment"/>
								</div>
							</template>
							<template v-else>
								<div class="p-2">
									<div class="placeholder-glow mb-2">
										<span class="placeholder font-size-16" :style="'width:51%'"></span>
									</div>
									<div class="placeholder-glow mb-2">
										<span class="placeholder font-size-16" :style="'width:32%'"></span>
									</div>
								</div>
							</template>
						</div>
					</div>
				</template>
			</VDropdown>
		</template>
		<color-badge
			v-else
			color="light"
			icon="far fa-user-plus"
			:small="small"
		>
			{{ publicId + (name ? ' - ' + name : '') }}
		</color-badge>
	</template>
</template>

<style scoped>

</style>
