<script setup>
import {ref, watch} from "vue";
import Modal from "../../components/Modal.vue";
import InputField from "../../components/form/InputField.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import Attribute from "../../components/Attribute.vue";
import DateTime from "../../components/DateTime.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import DataLoader from "../../components/DataLoader.vue";
import Price from "../../components/Price.vue";

const props = defineProps({
	contractId: {type: Number, required: true},
	projectId: {type: Number, required: true}
})
const invoiceDownloadUrl = ref('')
const invoices = ref([])
const loading = ref(false)
const flashStore = useFlashStoreStore()
const emit = defineEmits(['close', 'update', 'stats'])

function addInvoice(){
	loading.value = true
	axios.post('/projects/' + props.projectId + '/uploadInvoice/' + props.contractId, {url: invoiceDownloadUrl.value}).then((response) => {
		if (response.data.success) {
			invoices.value.push(response.data.invoice)
			emit('update')
		}

		if (response.data.flash || 0){
			flashStore.addFlash(response.data.flash)
		}

		if (response.data.stats || 0){
			emit('stats', response.data.stats)
		}
		invoiceDownloadUrl.value = ''
		loading.value = false
	}).catch((error) => {
		flashStore.addError(error)
		loading.value = false
	})
}

function close(){
	emit('close')
}

function pasted(){
	setTimeout(() => {
		addInvoice()
	}, 10)
}

</script>

<template>
	<modal
		title="Számlák feltöltése"
		closable
		:loading="loading"
		@close="close">
		<input-field
			name="invoiceDownloadUrl"
			v-model="invoiceDownloadUrl"
			:errors="{}"
			label="Billingo számla letöltése URL"
			info="A Billingo felületen kattints a számla letöltése ikonra jobb gombbal, majd másold ki a hivatkozás linkjét."
			input-group
			@paste="pasted"
		>
			<template #buttonAfter>
				<button type="button" class="btn btn-primary" @click.prevent="addInvoice"><i class="far fa-upload"></i> Feltöltés</button>
			</template>
		</input-field>
		<div class="mt-3" v-if="invoices.length">
			<h4 class="mb-2">Feltöltött számlák</h4>
			<div :class="{'mb-2': 1, 'p-2': 1, 'border-bottom': index < (invoices.length - 1), 'border-secondary': 1}" v-for="(invoice, index) in invoices" :key="invoice.id">
				<div class="d-flex flex-column gap-2">
					<h5>{{ invoice.invoice_number }}</h5>

					<attribute icon="far fa-clock" label="Dátum">
						<date-time :value="invoice.date" :time="false" />
					</attribute>
					<attribute icon="far fa-coins" label="Végösszeg">
						<price :value="invoice.amount" :currency-id="invoice.currency_id" />
					</attribute>
					<div class="horizontal">
						<data-loader :data="['invoiceTypeLabels']" v-slot="{ dataStore }">
							<color-badge :value="dataStore.getData('invoiceTypeLabels')[invoice.type]"></color-badge>
						</data-loader>

						<data-loader :data="['paymentMethods']" v-slot="{ dataStore }">
							<color-badge :value="dataStore.getData('paymentMethods')[invoice.payment_method]"></color-badge>
						</data-loader>

						<color-badge
							:color="invoice.paid_at === null ? 'warning' : 'success'"
							:icon="invoice.paid_at === null ? 'far fa-hourglass' : 'far fa-check'"
						>
							<template v-if="invoice.paid_at === null">
								Nincs fizetve
							</template>
							<template v-else>
								<date-time :value="invoice.paid_at" :time="false" />
							</template>
						</color-badge>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<style scoped>

</style>
