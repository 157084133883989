<script>
export default {
	props: {
		task: Object
	}
}
</script>

<template>
	<div class="text-danger" v-if="task.type === 'documentBundleError'">
		{{ task.details_as_array.description }}
	</div>
</template>

<style scoped>

</style>
