<script setup>
import DataLoader from "../DataLoader.vue";
import SelectField from "./SelectField.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import SaleStatusBadge from "../badges/SaleStatusBadge.vue";

const props = defineProps({
	errors: Object,
	name: String,
	label: String,
	fieldOnly: Boolean,
	disabled: Boolean,
	showNormal: {
		type: Boolean,
		default: true
	},
	showMobile: {
		type: Boolean,
		default: true
	},
	info: String,
	rules: {
		type: String,
		default: ''
	},
})
const dataStore = useGeneralDataStore()
const componentValue = defineModel()
</script>

<template>
	<data-loader :data="['saleStatusLabels']" v-slot="{ dataStore }">
		<select-field
			:label="label"
			:name="name"
			v-model="componentValue"
			:options="dataStore.getData('saleStatusLabels')"
			:errors="errors"
			:rules="rules"
			:info="info"
			:field-only="fieldOnly"
			data-type="string"
			:disabled="disabled"
		>
			<template v-slot:option="option">
				<sale-status-badge v-model="option.option.id" />
			</template>
			<template v-slot:selected-option="option">
				<sale-status-badge v-model="option.option.id" />
			</template>
		</select-field>
	</data-loader>
</template>

<style scoped>

</style>
