<template>
	<a :href="'mailto:' + value" class="badge bg-light badge-xl email-badge" :title="value" v-if="value !== undefined && value !== null">{{value}}</a>
</template>

<script>
export default {
	props: {
		value: String
	},
}
</script>

<style scoped>

</style>
