<script setup>
import ColorBadge from "../ColorBadge.vue";
import Attribute from "../Attribute.vue";
import SelectField from "./SelectField.vue";
import {onMounted, ref} from "vue";
import ClientBadge from "../badges/ClientBadge.vue";

const props = defineProps({
	label: String,
	name: String,
	errors: Object,
	disabled: Boolean,
	fieldOnly: Boolean,
	rules: {
		type: String,
		default: ''
	},
})

const model = defineModel()

const options = ref([])

onMounted(() => {
	search(null, '')
})

function search(loading, search){
	let url = '/data/select/client/' + search
	if (model.value){
		url += '?id=' + model.value
	}

	fetch(
		url
	).then(res => {
		res.json().then(json => (options.value = json.results));
		if (loading !== null) {
			loading(false)
		}
	});
}
function filterOptions(options, search){
	return options
}
</script>

<template>
	<select-field
		:label="label"
		:name="name"
		v-model="model"
		:options="options"
		:errors="errors"
		:disabled="disabled"
		:filter-options="filterOptions"
		:search="search"
		:rules="rules"
		:fieldOnly="fieldOnly"
		:display-search-info-bottom="true"
	>
		<template v-slot:option="option">
			<client-badge
				:client-id="option.option.id"
				:public-id="option.option.public_id"
				:name="option.option.name"
				v-if="option.option.id"
				disable-link
			/>
		</template>
		<template v-slot:selected-option="option">
			<client-badge
				:client-id="option.option.id"
				:public-id="option.option.public_id"
				:name="option.option.name"
				v-if="option.option.id"
				disable-link
			/>
		</template>
	</select-field>

</template>

<style scoped>

</style>
