<script setup>
import ColorBadge from "../ColorBadge.vue";
import DataLoader from "../DataLoader.vue";
import EmailAddress from "../EmailAddress.vue";
import Popper from "vue3-popper";
import {Link} from "@inertiajs/vue3";
import PhoneNumber from "../PhoneNumber.vue";
import {onMounted, ref} from "vue";
import {useApplicationStore} from "../../stores/applicationStore";
import AddressAttribute from "../attributes/AddressAttribute.vue";
import UserAttribute from "../attributes/UserAttribute.vue";
import {useGeneralDataStore} from "../../stores/generalData";

const app = useApplicationStore()
const store = useGeneralDataStore()
const props = defineProps({
	userId: Number,
	hideLevel: Boolean,
	hideLink: Boolean,
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
})

const userData = ref(false)
const open = ref(false)

onMounted(async () => {
	if (props.userId) {
		if (store.getTooltipData('user', props.userId)) {
			userData.value = store.getTooltipData('user', props.userId)
		} else {
			userData.value = await store.getTooltipDataDelayed('/tooltips/users', 'user', props.userId)
			store.addTooltip('user', props.userId, userData.value)
		}
	}
})
</script>
<template>
	<template v-if="userId">
		<template v-if="userData === false">
			<div class="placeholder-glow">
				<span class="placeholder font-size-16" :style="'width:32%'"></span>
			</div>
		</template>
		<template v-else-if="userData.deleted === undefined">
			<data-loader :data="['userLevels', 'userTypes', 'roles']" v-slot="{ dataStore }" inline>
				<template v-if="!hideLink">
					<VDropdown
						@show="open = true"
						@hide="open = false">
						<a :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" href="#">
							<i class="far fa-user"></i> {{ userData.name }} <i class="far fa-info-circle font-size-10"></i>

							<color-badge
								:color="dataStore.getData('userLevels', true)[userData.user_level_id].color"
								:icon="dataStore.getData('userLevels', true)[userData.user_level_id].icon"
								:luminosity="dataStore.getData('userLevels', true)[userData.user_level_id].luminosity"
								:content="dataStore.getData('userLevels', true)[userData.user_level_id].name_short ? dataStore.getData('userLevels', true)[userData.user_level_id].name_short : dataStore.getData('userLevels', true)[userData.user_level_id].name"
								:small="true"
								v-if="userData.user_level_id && !hideLevel"
							></color-badge>
						</a>
						<template #popper>
							<div :class="{'overview-tooltip': 1}" v-if="open">
								<div class="overview-tooltip-head">
									<div class="d-flex">
										<div class="flex-grow-1">
											<h5 class="font-size-15 mt-1">{{ userData.name }}</h5>
											<div class="mt-1">
												<div class="horizontal">
													<color-badge
														:color="dataStore.getData('userLevels', true)[userData.user_level_id].color"
														:icon="dataStore.getData('userLevels', true)[userData.user_level_id].icon"
														:luminosity="dataStore.getData('userLevels', true)[userData.user_level_id].luminosity"
														:content="dataStore.getData('userLevels', true)[userData.user_level_id].name"
														:small="true"
														v-if="userData.user_level_id"
													></color-badge>
													<color-badge
														color="secondary"
														icon="far fa-user"
														small
														content="Felhasználó"
														v-else
													/>
													<color-badge
														:color="dataStore.getData('userTypes', true)[userData.type].color"
														:content="dataStore.getData('userTypes', true)[userData.type].name"
														:small="true"
													></color-badge>
												</div>
											</div>
										</div>
										<div class="d-flex flex-column gap-2">
											<Link :href="'/users/edit/' + userId" class="btn btn-soft-secondary btn-sm" title="Adatlap" v-if="app.hasPermission('UsersController_view')"><i class="far fa-external-link"></i></Link>
											<Link :href="'/projects/user/' + userId" class="btn btn-soft-secondary btn-sm" title="Projektek" v-if="app.hasPermission('ProjectsController_viewAll')"><i class="far fa-project-diagram"></i></Link>
										</div>
									</div>
								</div>
								<div class="overview-tooltip-body" v-if="app.hasPermission('UsersController_view')">
									<div class="details-box">
										<div v-if="userData.phone">
											<phone-number :value="userData.phone"/>
										</div>
										<div v-if="userData.email">
											<email-address :value="userData.email"/>
										</div>

										<address-attribute v-if="userData.address" :address="userData.address"/>

										<user-attribute v-if="userData.superior_user_id && open" :user-id="userData.superior_user_id" superior />

										<div v-if="userData.roles.length">
											<template
												v-for="roleId in userData.roles"
												:key="'role_' + userId + '_' + roleId">
												<color-badge
													color="light"
													:content="dataStore.getData('roles', true)[roleId].name"
													:small="true"
												></color-badge>
											</template>
										</div>
									</div>
								</div>
							</div>
						</template>
					</VDropdown>
				</template>
				<color-badge
					v-else
					color="light"
					icon="far fa-user"
					:small="small"
					:content="userData.name"
				/>
			</data-loader>
		</template>
		<color-badge
			v-else
			color="light"
			text-color="danger"
			icon="far fa-user"
			:small="small"
			content="Törölt felhasználó"
		/>
	</template>
</template>
