<template>
	<TitleBar :title="attributes.id !== undefined ? 'Kategória módosítása' : 'Kategória hozzáadása'" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('productCategories.store', attributes.id)"
		:back-url="route('productCategories.index')"
		v-model="attributes"
		:required-data="['productAttributes', 'languages']"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable, dataStore}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
								:multilang="true"
								v-model:translations="attributes.translations"
							/>
							<order-field
								v-model="attributes"
								label="Sorrend"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								label="Publikus név"
								name="name_public"
								v-model="attributes.name_public"
								:disabled="!attributes.editable"
								:errors="errors"
								:multilang="true"
								v-model:translations="attributes.translations"
							/>
							<select-field
								label="Terméktulajdonságok"
								name="product_attributes"
								v-model="attributes.product_attributes"
								:disabled="!attributes.editable"
								multiple
								:errors="errors"
								:options="dataStore.getData('productAttributes')"
								/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import OrderField from "../components/form/OrderField.vue";
import SelectField from "../components/form/SelectField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	layout: AppLayout,
	components: {UpdatedAttribute, SelectField, OrderField, InputField, FormWrapper, TitleBar},
	props: {
		model: Object
	},
	data(){
		let attributes = _.clone(this.model)

		if (Array.isArray(attributes.translations) && !attributes.translations.length){
			attributes.translations = {}
		}
		return {
			attributes: attributes,
			loading: false,
			flash: []
		}
	},
}
</script>

<style scoped>

</style>
