<template>
	<TitleBar :title="attributes.id !== undefined ? 'Adó módosítása' : 'Adó hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('taxes.store', model)"
		:back-url="route('taxes.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<order-field
								v-model="attributes"
								label="Sorrend"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								label="Érték"
								name="value"
								rules="required"
								v-model="attributes.value"
								data-type="number"
								suffix="%"
								:step="0.01"
								:disabled="!attributes.editable || attributes.id !== undefined"
								:errors="errors"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import DateTime from "../components/DateTime.vue";
import OrderField from "../components/form/OrderField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {UpdatedAttribute, OrderField, DateTime, InputField, FormWrapper, TitleBar},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		return {
			attributes: this.model,
		}
	},
}
</script>
