<template>
	<div :class="'attribute' + (color ? ' text-' + color : '') + (maxWidth !== undefined ? ' limited-width' : '')" :style="maxWidth !== undefined ? 'max-width:' + maxWidth + 'px;' : ''" v-if="content || Object.keys($slots).length">
		<i :class="'fa-fw ' + icon" v-if="icon"></i>
		<div class="attribute-label" v-if="label">{{ label }}:</div>
		<div class="attribute-value" v-if="content !== undefined">{{ content }}</div>

		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		content: [String, Number],
		icon: String,
		label: String,
		color: String,
		maxWidth: Number
	}
}
</script>
