<template>
	<modal
		:title="getModalTitle()"
		:loading="loading"
		:full-width="true"
		:buttons-on-top="true"
		:closable="true"
		:required-data="['products', 'productManufacturers']"
		@close="$emit('close')">

		<template #buttons>
			<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable
				&& ((!contract.id && projectDataStore.can('contracts', 'create'))
					|| (contract.id && projectDataStore.can('contracts', 'update')))"
					:disabled="!allCategoriesValid" @click.prevent="saveForm">
				<i class="fas fa-save label-icon"></i> Mentés
			</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
				<i class="fas fa-times label-icon"></i> Bezár
			</button>
			<template v-if="!editable && canStartEditing">
				<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="$emit('startEditing')">
					<i class="fas fa-pencil label-icon"></i> Szerkesztés
				</button>
			</template>
			<button-group :buttons-to-show="0" desktop>
				<template #generate v-if="editable && contract.id && contract.status === 'draft'">
					<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="generateContract">
						<i class="fas fa-check label-icon"></i> Generálás
					</button>
				</template>
				<template #modify v-if="editable && contract.id && (contract.status === 'signed' || contract.status === 'generated') && !contract.is_foreign && app.hasPermission('ProjectsController_createContractAmendments')">
					<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="createNewAmendment">
						<i class="fas fa-file-plus label-icon"></i> Szerződésmódosítás létrehozása
					</button>
				</template>
				<template #modifyHidden v-if="editable && contract.id && (contract.status === 'signed' || contract.status === 'generated') && app.hasPermission('ProjectsController_canCreateHiddenContractAmendments')">
					<button type="submit" class="btn btn-danger btn-label waves-effect" @click.prevent="createHiddenUpdate">
						<i class="fas fa-file-edit label-icon"></i> Rejtett szerződésmódosítás létrehozása
					</button>
				</template>
				<template #terminate v-if="app.hasPermission('ProjectsController_canTerminateContract') && editable && contract.id && contract.status === 'signed'">
					<button type="submit" class="btn btn-danger btn-label waves-effect" @click.prevent="$emit('terminateContract', contract)">
						<i class="fas fa-trash-alt label-icon"></i> Szerződés felbontása
					</button>
				</template>
				<template #delivery v-if="editable && contract.id && contract.status === 'signed' && !contract.delivery_enabled && app.hasPermission('ProjectsController_setDeliveryForContract')">
					<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="enableDelivery">
						<i class="fas fa-truck label-icon"></i> Kiszállítás engedélyezése
					</button>
				</template>
				<template #install v-if="editable && contract.id && contract.status === 'signed' && !contract.installation_enabled && app.hasPermission('ProjectsController_canEnableInstallation')">
					<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="enableInstallation">
						<i class="fas fa-tools label-icon"></i> Telepítés engedélyezése
					</button>
				</template>
				<template #install v-if="editable && contract.status === 'completed' && app.hasPermission('ProjectsController_canReopenContract')">
					<button type="submit" class="btn btn-secondary btn-label waves-effect" @click.prevent="reopen">
						<i class="fas fa-lock-open label-icon"></i> Szerződés újranyitása
					</button>
				</template>
				<template #install v-if="editable && contract.id && contract.status === 'signed' && contract.installation_enabled && !contract.has_installation && app.hasPermission('ProjectsController_canStartInstallation')">
					<button type="submit" class="btn btn-secondary btn-label waves-effect" @click.prevent="startInstallation">
						<i class="fas fa-tools label-icon"></i> Telepítés indítása
					</button>
				</template>
				<template #download v-if="contract.id && contract.status !== 'draft' && contract.file_id">
					<a class="btn btn-secondary btn-label waves-effect" :href="'/projects/' + contract.project_id + '/downloadContract/' + contract.id">
						<i class="far fa-download label-icon"></i> Letöltés
					</a>
				</template>
				<template #reservation v-if="contract.id && contract.status === 'signed' && contract.delivery_enabled && app.hasPermission('InventoryReservationsController_view')">
					<Link class="btn btn-secondary btn-label waves-effect" :href="route('inventoryReservations.contract.index')  + '/' + contract.id">
						<i class="far fa-cubes label-icon"></i> Készletfoglalás
					</Link>
				</template>
			</button-group>
			<LockMessage :lock="projectDataStore.lock" :margin-bottom="false" :margin-left="true"></LockMessage>
		</template>

		<template #default>
			<div class="row">
				<div class="col-md-3">
					<ProjectInfoSidebar :key="'sidebar_' + projectDataStore.projectId"></ProjectInfoSidebar>
				</div>
				<div class="col-md-9">
					<div class="card">
						<div class="card-body">
							<div class="details-box">
								<attribute icon="far fa-hashtag" label="Azonosító" v-if="contract.id !== undefined">{{ contract.public_id }}</attribute>

								<data-loader :data="['contractStatusLabels']" v-slot="{ dataStore }">
									<attribute
										v-if="dataStore.getData('contractStatusLabels')[contract.status] !== undefined"
										icon="far fa-question"
										label="Státusz">
										<color-badge :value="dataStore.getData('contractStatusLabels')[contract.status]" small></color-badge>
									</attribute>
								</data-loader>

								<attribute icon="far fa-hashtag" label="Külső azonosító" v-if="contract.foreign_id">{{ contract.foreign_id }}</attribute>

								<attribute icon="far fa-question" label="Típus" v-if="contract.is_foreign">
									<color-badge icon="far fa-file-import" color="danger" content="Külső szerződés" small/>
								</attribute>

								<quote-id-attribute :project-id="projectDataStore.project.id" :project-type-id="projectDataStore.projectType.id" :public-id="contract.quote_public_id" :quote-id="contract.quote_id" v-if="contract.quote_id" />

								<attribute icon="far fa-ruler"
										   :label="projectTypeMainAttributeValue[4]"
										   v-if="projectTypeMainAttributeValue !== null && projectTypeMainAttributeValue[0]">
									<strong>{{ projectTypeMainAttributeValue[0] }} {{ projectTypeMainAttributeValue[1] }}</strong>
								</attribute>

								<data-loader :data="['languages']" v-slot="{ dataStore }">
									<attribute icon="far fa-language" label="Nyelv" v-if="contract.id !== undefined">
										{{ dataStore.getData('languages', true)[contract.language_id].name }}
									</attribute>
								</data-loader>

								<date-time-attribute :value="contract.deadline" v-if="contract.deadline !== undefined" validity hide-time />

								<data-loader :data="['currencies']" v-slot="{ dataStore }">
									<template v-for="currency in dataStore.getData('currencies')" v-if="contract.currency_values !== undefined">
										<attribute icon="far fa-coins" :label="currency.name + ' árfolyam'" v-if="contract.currency_values['display_' + currency.id] !== undefined">
											<Price
												:value="contract.currency_values['display_' + currency.id]"
												:decimals="2"
											/>
										</attribute>
									</template>
								</data-loader>

								<attribute icon="far fa-truck" label="Szállítás">
									<contract-delivery-badges :contract="contract" :display-installation="false" small />
								</attribute>

								<attribute icon="far fa-tools" label="Telepítés">
									<contract-delivery-badges :contract="contract" :display-delivery="false" small />
								</attribute>

								<template v-if="contract.id !== undefined">
									<template v-if="contract.status === 'terminated'">
										<div class="break"></div>

										<date-time-attribute :value="contract.terminated_at" terminated />
										<user-attribute :user-id="contract.terminated_by_user_id" terminator />

										<attribute icon="far fa-comment" label="Felbontás oka">{{ contract.termination_reason }}</attribute>
									</template>

									<div class="break"></div>

									<date-time-attribute :value="contract.created_at" created />

									<user-attribute :user-id="contract.created_by_user_id" v-if="contract.created_by_user_id" creator />

									<date-time-attribute :value="contract.updated_at" updated />

									<date-time-attribute :value="contract.answered_at" answered />

									<user-attribute :user-id="contract.answered_by_user_id" v-if="contract.answered_by_user_id" answer-submitter />

									<date-time-attribute :value="contract.signed_at" signed />
								</template>
							</div>
							<div v-if="contract.files && contract.files.length" class="mt-3">
								<FileList :files="contract.files" title="Aláírt szerződés" />
							</div>
							<div class="row mt-3">
								<div class="col-md-4" v-if="!contract.is_foreign">
									<InputField
										label="Kedvezmény"
										name="contract_discount"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount"
										:disabled="!isEditable"
										:errors="formErrors"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4" v-if="!contract.is_foreign">
									<InputField
										label="Kedvezmény anyag"
										name="contract_discount_material"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_material"
										:disabled="!isEditable"
										:errors="formErrors"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4" v-if="!contract.is_foreign">
									<InputField
										label="Kedvezmény munka"
										name="contract_discount_work"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_work"
										:disabled="!isEditable"
										:errors="formErrors"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4" v-if="!contract.is_foreign">
									<InputField
										label="Kedvezmény szolgáltatás"
										name="contract_discount_service"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_service"
										:disabled="!isEditable"
										:errors="formErrors"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4" v-if="!contract.is_foreign">
									<InputField
										label="Kedvezmény közvetített szolgáltatás"
										name="contract_discount_relayed_service"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_relayed_service"
										:disabled="!isEditable"
										:errors="formErrors"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4">
									<select-field
										:options="paymentPlans"
										v-model="contract.project_type_payment_plan_id"
										name="contract_project_type_payment_plan_id"
										label-field="name_public"
										label="Fizetési konstrukció"
										:disabled="!isEditable"
										rules="required"
										:errors="formErrors"
									>
									</select-field>
									<p class="text-muted mt-2" v-if="isEditable && contract.project_type_payment_plan_id && paymentPlansById[contract.project_type_payment_plan_id].description" v-html="paymentPlansById[contract.project_type_payment_plan_id].description_html"></p>
								</div>
							</div>
						</div>
					</div>

					<template v-if="contract.id !== undefined">
						<div :class="{'card': 1, 'd-none': !showInstallationList}">
							<div class="card-body">
								<h4 class="card-title mb-4">Telepítés</h4>
								<ProjectInstallationList
									:list-url="'/projects/contractInstallations/' + contract.id"
									v-if="contract.id !== undefined"
									@results-found="showInstallationList = true"
									:hide-contract="true"
									:editable="editable"
									:can-start-editing="canStartEditing"
									:form-errors="formErrors"
									@start-editing="$emit('startEditing')"
									@reloadShipments="reloadShipments"
								></ProjectInstallationList>
							</div>
						</div>

						<div :class="{'card': 1, 'd-none': !showShipmentList}">
							<div class="card-body">
								<h4 class="card-title mb-4">Szállítmányok</h4>
								<ContractShipmentList
									v-if="contract.id !== undefined"
									:contract-id="contract.id"
									ref="shipmentList"
									@results-found="showShipmentList = true"
								></ContractShipmentList>
							</div>
						</div>

						<ContractAmendmentList
							:data-url="'/projects/' + contract.project_id + '/contract/amendments/' + contract.id"
							:contract="contract"
							:editable="editable"
							:can-start-editing="canStartEditing"
							ref="amendmentList"
							@start-editing="$emit('startEditing')"
							@changed="reloadContractList"
						/>

						<ContractWarrantyList
							:data-url="'/projects/' + contract.project_id + '/contract/warrantyCards/' + contract.id"
							:editable="editable"
							:contract="contract"
							/>

					</template>

					<template v-if="(editable && contract.status === 'draft') || contractProducts.length">
						<ProjectProductList
							:data-url="'/projects/' + projectDataStore.projectId + '/calculateContractPrices'"
							:is-editable="isEditable"
							v-model="contractProducts"
							:parent-model="contract"
							:product-versions="contract.product_versions"
							:initial-prices="productPrices"
							mode="contract"
							ref="productList"
							@validationChanged="validationChanged"
							@projectTypeMainAttributeValueChanged="projectTypeMainAttributeValueChanged"
							v-if="!contract.quote_id && !contract.is_foreign && !contract.amendment_to_contract_id"
						>
						</ProjectProductList>
						<template v-else>
							<div class="card border">
								<div class="card-body">
									<h4 class="card-title mb-4">Termékek</h4>

									<data-loader :data="['products']" v-slot="{ dataStore }">
										<table class="table table-collapse-mobile-auto">
											<thead>
											<tr>
												<th>Termék</th>
												<th width="70" class="text-end" v-if="contract.amendments !== undefined && contract.amendments.length">Eredeti<br />menny.</th>
												<th width="70" class="text-end">Menny.</th>
												<th width="70" class="text-end" v-if="contract.delivery_enabled">Foglalva</th>
												<th width="70" class="text-end" v-if="contract.delivery_enabled">Kiszáll.</th>
												<th width="110">Típus</th>
												<th width="80" class="text-end text-nowrap">Nettó<br />egys.</th>
												<th width="100" class="text-end" v-if="contract.amendments !== undefined && contract.amendments.length">Eredeti<br />bruttó ár</th>
												<th width="100" class="text-end">Bruttó ár</th>
											</tr>
											</thead>
											<tbody>
											<template v-for="product in contractProducts">
												<tr :class="{'opacity-25': !product.quantity}">
													<td>
														<product-badge
															:product-id="product.product_id"
															:product-name="dataStore.getData('products', true)[product.product_id].name"
															:product-manufacturer-id="dataStore.getData('products', true)[product.product_id].product_manufacturer_id"
															:key="'PB' + product.product_id"
															/>

														<div class="mt-2" v-if="product.quantity !== product.calculation_quantity">
															<color-badge
																color="warning"
																icon="far fa-eye-slash"
																content="Rejtett módosítás"
																small
															/>
														</div>
													</td>
													<td class="text-end" v-if="contract.amendments !== undefined && contract.amendments.length">
														{{ product.original_quantity }} {{ dataStore.getData('products', true)[product.product_id] !== undefined ? dataStore.getData('products', true)[product.product_id].unit : 'db' }}
													</td>
													<td class="text-end">
														<span :class="{'fw-bold': contract.amendments !== undefined && contract.amendments.length && product.quantity !== product.original_quantity}">
															{{ product.quantity }} {{ dataStore.getData('products', true)[product.product_id] !== undefined ? dataStore.getData('products', true)[product.product_id].unit : 'db' }}
														</span>
													</td>
													<td class="text-end" v-if="contract.delivery_enabled">
														<template v-if="dataStore.getData('products', true)[product.product_id].type === 'material' && dataStore.getData('products', true)[product.product_id].track_inventory">
															<mobile-attribute
																label="Foglalva"
																color="light"
																icon="far fa-cubes"
															>
																<template #default>
																	<number :value="product.reserved"/>
																</template>
															</mobile-attribute>
														</template>
													</td>
													<td class="text-end" v-if="contract.delivery_enabled">
														<template v-if="dataStore.getData('products', true)[product.product_id].type === 'material' && dataStore.getData('products', true)[product.product_id].requires_delivery">
															<mobile-attribute
																label="Kiszállítva"
																:color="product.delivered_quantity >= product.quantity ? 'success' : (product.delivered_quantity < product.quantity ? 'danger' : 'light')"
																icon="fas fa-truck-loading"
															>
																<template #default>
																	<span :class="{
																		'fw-bold': 1,
																		'text-success': product.delivered_quantity >= product.quantity,
																		'text-danger': product.delivered_quantity < product.quantity}">
																		<number :value="product.delivered_quantity"/>
																	</span>
																</template>
																<template #mobile>
																	<number :value="product.delivered_quantity"/>
																</template>
															</mobile-attribute>
														</template>
													</td>
													<td>
														<data-loader :data="['productTypes']" v-slot="{ dataStore }">
															<div v-if="product.product_id">
																<color-badge :value="dataStore.getData('productTypes')[dataStore.getData('products', true)[product.product_id].type]" small></color-badge>
															</div>
														</data-loader>
													</td>
													<td class="text-end vertical-align-middle">
														<mobile-attribute
															label="Nettó egységár"
															color="light"
															icon="fas fa-coins"
														>
															<template #default>
																<Price
																	v-if="product.product_id && productPrices[(product.order + 1)] !== undefined"
																	:value="productPrices[(product.order + 1)].discountNet || productPrices[(product.order + 1)].net"
																	:loading="pricesLoading"
																/>
															</template>
														</mobile-attribute>
													</td>
													<td class="text-end vertical-align-middle" v-if="contract.amendments !== undefined && contract.amendments.length">
														<mobile-attribute
															label="Bruttó ár"
															color="light"
															icon="fas fa-coins"
														>
															<template #default>
																<Price
																	v-if="product.product_id && productPrices[(product.order + 1)] !== undefined"
																	:value="(productPrices[(product.order + 1)].discountGross || productPrices[(product.order + 1)].gross) * product.original_quantity"
																	:loading="pricesLoading"
																/>
															</template>
														</mobile-attribute>
													</td>
													<td class="text-end vertical-align-middle">
														<mobile-attribute
															label="Bruttó ár"
															color="light"
															icon="fas fa-coins"
														>
															<template #default>
																<Price
																	v-if="product.product_id && productPrices[(product.order + 1)] !== undefined"
																	:value="(productPrices[(product.order + 1)].discountGross || productPrices[(product.order + 1)].gross) * product.calculation_quantity"
																	:loading="pricesLoading"
																/>
															</template>
														</mobile-attribute>
													</td>
												</tr>
											</template>
											</tbody>
										</table>
									</data-loader>
								</div>
							</div>


							<div class="card">
								<div class="card-body">
									<table class="table table-collapse-mobile-auto">
										<thead>
										<tr>
											<th>&nbsp;</th>
											<th width="15%" class="text-end">Nettó</th>
											<th width="15%" class="text-end">ÁFA</th>
											<th width="15%" class="text-end">Bruttó</th>
										</tr>
										</thead>
										<tbody>
										<tr v-if="getTotalPrice(false, true, 'work') > 0">
											<td class="text-end">
												<strong>Kedvezmény a munka jellegű tételekből</strong>
											</td>
											<td class="text-end">
												<mobile-attribute
													label="Nettó"
													color="light"
												>
													<template #default>
														<Price
															:value="getTotalPrice(false, true, 'work')"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</td>
											<td class="text-end">
												<mobile-attribute
													label="ÁFA"
													color="light"
												>
													<template #default>
														<Price
															:value="getTotalPrice(true, true, 'work') - getTotalPrice(false, true, 'work')"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</td>
											<td class="text-end">
												<mobile-attribute
													label="Bruttó"
													color="light"
												>
													<template #default>
														<Price
															:value="getTotalPrice(true, true, 'work')"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</td>
										</tr>
										<tr v-if="getTotalPrice(false, true, 'material') > 0">
											<td class="text-end">
												<strong>Kedvezmény az anyag jellegű tételekből</strong>
											</td>
											<td class="text-end">
												<mobile-attribute
													label="Nettó"
													color="light"
												>
													<template #default>
														<Price
															:value="getTotalPrice(false, true, 'material')"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</td>
											<td class="text-end">
												<mobile-attribute
													label="ÁFA"
													color="light"
												>
													<template #default>
														<Price
															:value="getTotalPrice(true, true, 'material') - getTotalPrice(false, true, 'material')"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</td>
											<td class="text-end">
												<mobile-attribute
													label="Bruttó"
													color="light"
												>
													<template #default>
														<Price
															:value="getTotalPrice(true, true, 'material')"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</td>
										</tr>
										<template v-if="contract.price_history.length">
											<tr v-for="historyItem in contract.price_history">
												<td class="text-end">
													<strong>{{ historyItem.name }}</strong>
												</td>
												<td class="text-end">
													<mobile-attribute
														label="Nettó"
														color="light"
													>
														<template #default>
															<Price
																:value="historyItem.net"
																:loading="pricesLoading"
															/>
														</template>
													</mobile-attribute>
												</td>
												<td class="text-end">
													<mobile-attribute
														label="ÁFA"
														color="light"
													>
														<template #default>
															<Price
																:value="historyItem.gross - historyItem.net"
																:loading="pricesLoading"
															/>
														</template>
													</mobile-attribute>
												</td>
												<td class="text-end">
													<mobile-attribute
														label="Bruttó"
														color="light"
													>
														<template #default>
															<Price
																:value="historyItem.gross"
																:loading="pricesLoading"
															/>
														</template>
													</mobile-attribute>
												</td>
											</tr>
										</template>
										<tr>
											<td class="text-end">
												<strong>Végösszeg</strong>
											</td>
											<td class="text-end">
												<mobile-attribute
													label="Nettó"
													color="light"
												>
													<template #default>
														<Price
															:value="contract.total_price_net"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</td>
											<td class="text-end">
												<mobile-attribute
													label="ÁFA"
													color="light"
												>
													<template #default>
														<Price
															:value="contract.total_price_gross !== 0 && contract.total_price_net !== 0 ? contract.total_price_gross - contract.total_price_net : 0"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</td>
											<td class="text-end">
												<mobile-attribute
													label="Bruttó"
													color="light"
												>
													<template #default>
														<Price
															:value="contract.total_price_gross"
															:loading="pricesLoading"
														/>
													</template>
												</mobile-attribute>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</template>
					</template>

					<contract-components
						:contract="contract"
						:editable="editable"
						:model-value="componentProducts"
					/>

					<template v-if="contract.id !== undefined && contract.id !== null">
						<div class="card">
							<div class="card-body">
								<h4 class="card-title mb-4">Kiszállított termékek</h4>
								<contract-delivered-product-list
									:contract-id="contract.id"
									:project-id="contract.project_id"
								></contract-delivered-product-list>
							</div>
						</div>
					</template>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import {moveValueInArray} from "../../functions";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import InputField from "../../components/form/InputField.vue";
import ProjectProductList from "./ProjectProductList.vue";
import Price from "../../components/Price.vue";
import vSelect from "vue-select";
import FileList from "../../components/FileList.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import ContractAmendmentList from "./ContractAmendmentList.vue";
import ProjectInstallationList from "./ProjectInstallationList.vue";
import LockMessage from "../../components/LockMessage.vue";
import data from "bootstrap/js/src/dom/data";
import UserBadge from "../../components/badges/UserBadge.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import SelectField from "../../components/form/SelectField.vue";
import {useProjectDataStore} from "../../stores/projectData";
import {useApplicationStore} from "../../stores/applicationStore";
import ContractDeliveredProductList from './ContractDeliveredProductList.vue'
import Modal from "../../components/Modal.vue";
import DateTime from "../../components/DateTime.vue";
import Number from "../../components/Number.vue";
import ButtonGroup from "../../components/ButtonGroup.vue";
import Attribute from "../../components/Attribute.vue";
import ContractShipmentList from "./ContractShipmentList.vue";
import MobileAttribute from "../../components/MobileAttribute.vue";
import ContractComponents from "./ContractComponents.vue";
import ContractDeliveryBadges from "./ContractDeliveryBadges.vue";
import {Link} from "@inertiajs/vue3";
import ContractWarrantyList from "./ContractWarrantyList.vue";
import ProductBadge from "../../components/badges/ProductBadge.vue";
import QuoteIdAttribute from "../../components/attributes/QuoteIdAttribute.vue";
import ProjectIdAttribute from "../../components/attributes/ProjectIdAttribute.vue";
import UserAttribute from "../../components/attributes/UserAttribute.vue";
import DateTimeAttribute from "../../components/attributes/DateTimeAttribute.vue";

export default {
	components: {
		DateTimeAttribute,
		UserAttribute,
		ProjectIdAttribute,
		QuoteIdAttribute,
		ProductBadge,
		ContractWarrantyList,
		ContractDeliveryBadges,
		SelectField,
		ColorBadge,
		DataLoader,
		UserBadge,
		LockMessage,
		ProjectInstallationList,
		ContractAmendmentList,
		TableActionDropdown,
		FileList,
		Price,
		ProjectProductList,
		InputField,
		ProjectInfoSidebar,
		vSelect,
		AdvancedDataTable,
		ContractDeliveredProductList,
		ButtonGroup,
		Attribute,
		DateTime,
		Modal,
		ContractShipmentList,
		MobileAttribute,
		Link,
		ContractComponents,
		Number,
	},
	emits: ['close', 'closeAndReload', 'reloadList', 'generate', 'updateStats', 'enableDelivery', 'enableInstallation',
		'startInstallation', 'startEditing', 'terminateContract', 'reopen'],
	props: {
		editable: Boolean,
		projectAttributes: Object,
		canStartEditing: Boolean,
		formErrors: Object,
		contractId: Number
	},
	mounted() {
		this.loadData()
	},
	computed: {
		data() {
			return data
		},
		isEditable: {
			get(){
				if (this.contract.id === undefined && !this.projectDataStore.can('contracts', 'create')){
					return false
				}
				if (this.contract.id !== undefined && !this.projectDataStore.can('contracts', 'update')){
					return false
				}
				if (!this.editable){
					return false
				}
				return this.contract.editable
			}
		},
		paymentPlansById: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		},
	},
	data() {
		return {
			contract: {
				product_versions: {}
			},
			contractProducts: [],
			componentProducts: [],
			loading: false,
			productPrices: {},
			pricesLoading: false,
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			app: useApplicationStore(),
			pricesLoadingCancelToken: null,
			priceCalculationRequired: false,
			showInstallationList: false,
			showShipmentList: false,
			paymentPlans: [],
			allCategoriesValid: false,
			addComponent: null,
			projectTypeMainAttributeValue: null,
		};
	},
	methods: {
		moveValueInArray: moveValueInArray,
		loadData: function () {
			this.loading = true

			axios.get('/projects/' + this.projectDataStore.projectId + '/contract' + (this.contractId ? '/' + this.contractId : '')).then((response) => {
				this.handleResponse(response)
			}).catch((error) =>  {
				if (error.response !== undefined) {
					this.flashStore.addError(error)
				}
			})
		},
		reloadContractList: function(){
			this.loadData()
			this.$emit('reloadList', this.contractId)
		},
		productChanged: function(){
			this.$refs.productList.productChanged()
		},
		generateContract: function(){
			this.loading = true
			this.$emit('generate', this.contractId)
		},
		enableDelivery: function(){
			this.loading = true
			this.$emit('enableDelivery', this.contractId)
		},
		enableInstallation: function(){
			this.loading = true
			this.$emit('enableInstallation', this.contractId)
		},
		reopen: function(){
			this.loading = true
			this.$emit('reopen', this.contractId)

			this.$emit('close')
		},
		emitClose: function(){
			this.$emit('close')
		},
		saveForm: function(e){
			if (this.allCategoriesValid){
				this.loading = true

				let payload = {
					contract: this.contract,
					products: this.$refs.productList.getFilteredProducts()
				}

				axios.post('/projects/' + this.projectDataStore.projectId + '/saveContract' + (this.contractId ? '/' + this.contractId : ''), payload).then((response)=>{
					this.handleResponse(response)
				}).catch((error) => {
					this.flashStore.addError(error)
				})
			}
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status === undefined || response.data.status === 'success') {
				this.contract = response.data.contract
				this.productPrices = response.data.prices
				this.contractProducts = this.contract.products_pivot
				this.componentProducts = this.contract.component_products
				this.paymentPlans = response.data.paymentPlans
				this.productPrices = response.data.prices

				if (response.data.projectTypeMainAttributeValue !== undefined){
					this.projectTypeMainAttributeValue = response.data.projectTypeMainAttributeValue
				}
				if (response.data.status !== undefined && response.data.status === 'success') {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}
		},
		getTotalPrice: function(gross, discount, type) {
			type = type || 'all'
			let total = 0

			_.forEach(this.productPrices, function(productPrice, productPriceKey){
				if (productPrice.bundle || (type !== 'all' && productPrice.type !== type)){
					return
				}
				if (discount){
					if (productPrice.discountGross !== 0) {
						total += ((gross ? productPrice.gross : productPrice.net) - (gross ? productPrice.discountGross : productPrice.discountNet)) * productPrice.quantity
					}
				} else {
					total += (gross ? productPrice.gross : productPrice.net) * productPrice.quantity
				}
			})

			return total
		},
		createNewAmendment: function(){
			this.$refs.amendmentList.openContract(null)
		},
		createHiddenUpdate: function (){
			this.$refs.amendmentList.openContract(null, true)
		},
		startInstallation: function (){
			this.$emit('startInstallation', this.contract.id)
		},
		getModalTitle: function (){
			if (this.contract.id !== undefined){
				return this.editable ? 'Szerződés szerkesztése' : 'Szerződés'
			}

			return 'Szerződés létrehozása'
		},
		validationChanged: function (valid){
			this.allCategoriesValid = valid
		},
		projectTypeMainAttributeValueChanged: function (value){
			this.projectTypeMainAttributeValue = value
		},
		reloadShipments: function (){
			this.$refs.shipmentList.reloadTable()
		}
	}
}
</script>

<style scoped>

</style>
