<template>
	<AdvancedDataTable
		:data-url="route('deliveryRoutes.inventoryShipmentList', model)"
		:columns="columns"
		sort-by="closed_at"
		sort-type="asc"
		compact
		name="deliveryRouteInventoryShipments"
		ref="table"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.public_id }}</strong>

						<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
							<color-badge :value="dataStore.getData('inventoryShipmentTypes')[item.type]"></color-badge>
						</data-loader>

						<div class="mobile-horizontal">
							<shipment-source-cell :item="item" small />
							<shipment-destination-cell :item="item" small />
						</div>

						<color-badge
							v-if="item.closed_at !== null"
							color="success"
							icon="far fa-check">
							Lezárva: <DateTime :value="item.closed_at"/>
						</color-badge>
						<color-badge
							v-else
							color="danger"
							icon="far fa-lock-open">
							Nincs lezárva
						</color-badge>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('inventory.shipment')"
					:emit-delete-event="true"
					:is-deletable="false"
					@deleteButtonPressed="deleteButtonPressed"
				>
				</TableActionCell>
			</div>
		</template>
		<template #item-public_id="item">
			{{ item.foreign_id ? item.foreign_id : item.public_id }}
		</template>
		<template #item-type="item">
			<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
				<color-badge :value="dataStore.getData('inventoryShipmentTypes')[item.type]" small></color-badge>
			</data-loader>
		</template>
		<template #item-closed_at="item">
			<color-badge
				v-if="item.closed_at !== null"
				color="success"
				small
				icon="far fa-check">
				<DateTime :value="item.closed_at"/>
			</color-badge>
			<color-badge
				v-else
				color="danger"
				small
				icon="far fa-lock-open">
				Nincs lezárva
			</color-badge>
		</template>
		<template #item-source="item">
			<shipment-source-cell :item="item" small />
		</template>
		<template #item-destination="item">
			<shipment-destination-cell :item="item" small />
		</template>
	</AdvancedDataTable>
</template>

<script>
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import DataLoader from "../../components/DataLoader.vue";
import vSelect from "vue-select";
import Number from "../../components/Number.vue";
import DateTime from "../../components/DateTime.vue";
import TitleBar from "../../components/TitleBar.vue";
import {Link} from "@inertiajs/vue3";
import ColorBadge from "../../components/ColorBadge.vue";
import Attribute from "../../components/Attribute.vue";
import TableActionCell from "../../components/TableActionCell.vue";
import ShipmentDestinationCell from "../inventory/ShipmentDestinationCell.vue";
import ShipmentSourceCell from "../inventory/ShipmentSourceCell.vue";

export default {
	components: {
		ShipmentSourceCell, ShipmentDestinationCell,
		TableActionCell,
		Attribute, ColorBadge, TitleBar, DateTime, Link, Number, vSelect, DataLoader, AdvancedDataTable},
	props: {
		model: Object
	},
	data(){
		let columns = [
			{
				text: "Azon.",
				value: "public_id",
				sortable: true
			},
			{
				text: "Típus",
				value: "type",
				sortable: true
			},
			{
				text: "Lezárva",
				value: "closed_at",
				sortable: true
			},
			{
				text: "Forrás",
				value: "source",
				sortable: false
			},
			{
				text: "Cél",
				value: "destination",
				sortable: false
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]
		return {
			columns: columns,
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>
