import { defineStore } from 'pinia'
import {objectToArray, timeout} from "../functions";

export const useGeneralDataStore = defineStore('generalData', {
	state: () => {
		return {
			dataToLoad: [],
			loadedData: {},
			loadedDataObjects: {},
			loading: false,
			interval: null,
			productsInterval: null,
			callbacks: [],
			productsCallbacks: [],
			productsToLoad: [],
			loadingProducts: [],
			productsLoading: false,
			loadedProducts: {},
			tooltips: {},
			tooltipsToLoad: {},
		}
	},
	getters: {
	},
	actions: {
		dataLoaded(data, callback) {
			let result = true
			if (!_.isArray(data)){
				data = [data]
			}
			for (let i = 0; i < data.length; i++){
				if (this.loadedData[data[i]] === undefined){
					result = false

					if (this.dataToLoad.indexOf(data[i]) < 0) {
						this.dataToLoad.push(data[i])
					}
				}
			}

			if (callback !== undefined && callback !== false){
				if (result){
					callback()
				} else {
					this.callbacks.push({
						data: data,
						callback: callback
					})
				}
			}

			if (this.interval === null){
				this.interval = setInterval(this.loadData, 100)
			}

			return result
		},
		loadData(data, callback, reload = false) {
			if (data !== undefined){
				if (!_.isArray(data)){
					data = [data]
				}
				for (let i = 0; i < data.length; i++){
					if ((this.loadedData[data[i]] === undefined && !reload) || (this.loadedData[data[i]] !== undefined && reload)) {
						this.dataToLoad.push(data[i])
					}
				}
				if (callback !== undefined && callback !== false){
					this.callbacks.push({
						data: data,
						callback: callback
					})
				}

				if (reload && data.indexOf('products') > -1){
					this.loadedProducts = {}
				}
			}
			if (this.dataToLoad.length && !this.loading){
				this.loading = true
				axios.get('/data?types=' + this.dataToLoad.join('|')).then((response) => {
					let loadedData = response.data
					_.forEach(response.data, (value, key) =>{
						this.loadedData[key] = value
					})
					this.loading = false
					this.dataToLoad = []
					this.checkCallbacks()
				}).catch((error) =>{
					this.loading = false
				});

				if (this.interval !== null){
					clearInterval(this.interval)
					this.interval = null
				}
			}
			if (data !== undefined){
				this.checkCallbacks()
			}
		},
		getData(type, toObject = false, excludedIds){
			if (this.loadedData[type] !== undefined){
				let data = this.loadedData[type]

				if (excludedIds !== undefined){
					if (!_.isArray(excludedIds)){
						excludedIds = [excludedIds]
					}
					data = _.filter(data, function (item){
						return excludedIds.indexOf(item.id) < 0
					})
					if (toObject && _.isArray(data)) {
						return _.keyBy(data, 'id')
					}
				} else {
					if (toObject && _.isArray(this.loadedData[type])) {
						if (this.loadedDataObjects[type] === undefined) {
							this.loadedDataObjects[type] = _.keyBy(this.loadedData[type], 'id')
						}
						return this.loadedDataObjects[type]
					}
				}
				return data
			}

			return toObject ? {} : []
		},
		getDataAsArray(type){
			let data = this.getData(type)

			return objectToArray(data)
		},
		getDataValues(type){
			let data = this.getData(type)

			return _.values(data)
		},
		checkCallbacks() {
			for (let i = 0; i < this.callbacks.length; i++){
				let allLoaded = true
				for (let j = 0; j < this.callbacks[i].data.length; j++){
					if (this.loadedData[this.callbacks[i].data[j]] === undefined){
						allLoaded = false
					}
				}
				if (allLoaded) {
					this.callbacks[i].callback()
					delete this.callbacks[i]
				}
			}

			let callbacks = []
			for (let i = 0; i < this.callbacks.length; i++){
				if (this.callbacks[i] !== undefined) {
					callbacks.push(this.callbacks[i])
				}
			}
			this.callbacks = callbacks
		},
		getProductsDetailsWithCallback(productIds, callback){
			if (this.productDetailsLoaded(productIds)) {
				callback()
				return
			}
			this.productsCallbacks.push(callback)
		},
		productDetailsLoaded(productIds) {
			let result = true
			if (!productIds){
				return false
			}
			if (!_.isArray(productIds)){
				productIds = [productIds]
			}
			if (!productIds.length){
				return false
			}
			_.forEach(productIds, (productId) => {
				if (this.loadedProducts[productId] === undefined){
					result = false

					if (this.productsToLoad.indexOf(productId) < 0) {
						this.productsToLoad.push(productId)
					}
				}
			})

			if (!result && !this.productsLoading && this.productsInterval === null) {
				this.productsInterval = setInterval(this.loadProducts, 100)
			}

			return result
		},
		loadProducts() {
			if (this.productsToLoad.length && !this.productsLoading){
				this.productsToLoad = _.uniq(this.productsToLoad)
				this.productsToLoad = _.difference(this.productsToLoad, this.loadingProducts)
				if (this.productsToLoad.length) {
					this.productsLoading = true

					this.loadingProducts = _.merge(this.loadingProducts, this.productsToLoad)

					axios.get('/data?productIds=' + this.productsToLoad.join('|')).then((response) => {
						_.forEach(response.data.products, (value, key) => {
							this.loadedProducts[key] = value
							if (this.loadingProducts.indexOf(key) > -1) {
								this.loadingProducts.splice(key, 1)
							}
						})
						this.productsLoading = false
						this.productsToLoad = []
						if (this.productsCallbacks.length){
							for (let i = 0; i < this.productsCallbacks.length; i++){
								this.productsCallbacks[i]()
							}
							this.productsCallbacks = []
						}
					}).catch((error) => {
						this.productsLoading = false
					});
				}
			}
		},
		getProductDetails(productId){
			if (!productId){
				return false
			}
			if (this.productDetailsLoaded([productId])){
				return this.loadedProducts[productId]
			}

			return false
		},
		async loadProductDetails(productId){
			if (this.productDetailsLoaded([productId])){
				return this.loadedProducts[productId]
			}
			if (this.loadingProducts.indexOf(productId) > -1){

			} else {
				try {
					const response = await axios.get('/data?productIds=' + productId)
					_.forEach(response.data.products, (value, key) => {
						this.loadedProducts[key] = value
					})
					return this.loadedProducts[productId]
				} catch (error) {
					return false
				}
			}
		},
		getProductsDetails(productIds, callback){
			let allLoaded = true
			let payload = {}
			_.forEach(productIds, (productId) => {
				if (this.loadedProducts[productId] === undefined){
					allLoaded = false
				} else {
					payload[productId] = this.loadedProducts[productId]
				}
			})
			return payload
		},
		addTooltip(type, id, value){
			if (this.tooltips[type] === undefined){
				this.tooltips[type] = {}
			}

			this.tooltips[type][id] = {
				addedAt: new Date(),
				value: value
			}
		},
		getTooltipData(type, id){
			if (this.tooltips[type] === undefined || this.tooltips[type][id] === undefined){
				return false
			}

			if (this.tooltips[type][id].addedAt.getTime() + 120000 < new Date().getTime()){
				delete this.tooltips[type][id]
				return false
			}

			return this.tooltips[type][id].value
		},
		clearTooltipData(type){
			this.tooltips[type] = {}
		},
		async getTooltipDataDelayed(url, type, id){
			if (this.getTooltipData(type, id) !== false){
				return this.getTooltipData(type, id)
			}
			if (this.tooltips[type] === undefined){
				this.tooltips[type] = {}
			}
			if (this.tooltipsToLoad[type] === undefined){
				this.tooltipsToLoad[type] = []
			}
			this.tooltipsToLoad[type].push(id)

			await timeout(30);

			if (this.tooltipsToLoad[type].length){
				let ids = _.uniq(this.tooltipsToLoad[type]).join('|')
				this.tooltipsToLoad[type] = []
				let loadedData = await axios.get(url + '?id=' + ids);
				_.forEach(loadedData.data.data, (row) =>{
					this.addTooltip(type, row.id, row)
				})
			} else {
				let cnt = 0
				while (this.tooltips[type][id] === undefined){
					await timeout(10);
					cnt++
				}
			}

			return this.getTooltipData(type, id)
		}
	},
})
