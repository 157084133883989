<template>
	<TitleBar title="Bejelentkezés" />
	<div>
		<h5 class="text-primary">Üdv!</h5>
		<p class="text-muted">Jelentkezz be az {{ app.appName }}-be</p>
	</div>
	<div class="mt-4">
		<form-wrapper
			v-model="attributes"
			:store-url="route('auth.login')"
			:show-bottom-buttons="false"
			:show-top-buttons="false"
		>
			<template #default="{activeTab, errors, editable}">
				<input-field
					label="E-mail cím"
					name="email"
					v-model="attributes.email"
					rules="required"
					:multilang="false"
					data-type="email"
					:errors="errors"
					/>

				<input-field
					label="Jelszó"
					name="password"
					v-model="attributes.password"
					rules="required"
					:multilang="false"
					data-type="password"
					:errors="errors"
					>
					<template #beforeLabel>
						<div class="float-end">
							<Link :href="route('auth.lostPassword')" class="text-muted" tabindex="5">Elfelejtetted a jelszavad?</Link>
						</div>
					</template>
				</input-field>

				<switch-field
					label="Jegyezzen meg"
					name="remember"
					v-model="attributes.remember"
					:inline="true"
					:inline-reversed="true"
					/>

				<div class="mt-3 d-grid">
					<button class="btn btn-primary waves-effect waves-light" tabindex="4" type="submit">Belépés</button>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import {Link} from "@inertiajs/vue3";
import EnvironmentBadge from "../layouts/EnvironmentBadge.vue";
import {useApplicationStore} from "../stores/applicationStore";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import AuthLayout from "../layouts/AuthLayout.vue";
import TitleBar from "../components/TitleBar.vue";
export default {
	layout: AuthLayout,
	components: {TitleBar, SwitchField, InputField, FormWrapper, EnvironmentBadge, Link},
	data(){
		return {
			app: useApplicationStore(),
			attributes: {
				email: '',
				password: '',
				remember: false
			}
		}
	}
}
</script>

<style scoped>

</style>
