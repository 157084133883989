<template>
	<TitleBar title="Dokumentumsablon szerkesztése" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('documentTemplates.store', model)"
		:back-url="route('documentTemplates.index')"
		v-model="attributes"
		:hidden-attributes="['variables', 'updater', 'language', 'files', 'file_types']"
	>
		<template v-slot:top>
			<div class="card mb-3">
				<div class="card-body">
					<div class="details-box">
						<div>
							<i class="far fa-align-left"></i> <strong>{{ model.name }}</strong>
						</div>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors}">
			<div class="card" v-if="languages.length > 1">
				<div class="card-body">
					<ul class="nav nav-pills nav-justified mb-0" role="tablist">
						<template v-for="language in languages">
							<li class="nav-item" role="presentation">
								<a :class="{'nav-link': 1, 'active': activeLanguage === language.code}" @click.prevent="activeLanguage = language.code" href="#" role="tab" :aria-selected="activeLanguage === language.code ? 'true' : 'false'">
									<div class="label">
										{{ language.name }}
									</div>
								</a>
							</li>
						</template>
					</ul>
				</div>
			</div>
			<template v-for="language in languages" :key="language.code">
				<div :class="{'d-none': language.code !== activeLanguage}">
					<template v-if="language.is_default">
						<div class="card mb-3">
							<div class="card-body">
								<h4 class="card-title mb-4">Tartalom</h4>
								<div class="mb-3 bg-light p-2">
									<variable-select
										label="Változó beszúrása"
										:field-only="true"
										:options="attributes.variables"
										:errors="errors"
									/>
								</div>
								<div class="mb-3 alert alert-primary" v-if="model.has_table">
									A dokumentumsablon táblázatot is tartalmaz. Amennyiben szeretnéd megjeleníteni, a sablonba egy külön bekezdésbe tedd be a {táblázat} kifejezést.
								</div>
								<ckeditor-field
									name="content"
									v-model="attributes.content"
								></ckeditor-field>
							</div>
						</div>
						<template v-for="(fileTypeDetails, fileType) in attributes.file_types">
							<div class="card mb-3">
								<div class="card-body">
									<h4 class="card-title mb-3">{{ fileTypeDetails.name }}</h4>
									<FileUploader
										:field-name="fileType"
										v-model="attributes[fileType]"
										:editable="attributes.editable"
										:multiple="fileTypeDetails.multiple"
									></FileUploader>
								</div>
							</div>
						</template>
					</template>
					<template v-else>
						<div class="card mb-3">
							<div class="card-body">
								<h4 class="card-title mb-4">Tartalom</h4>
								<ckeditor-field
									:name="'content_' + language.code"
									v-model="attributes.translations[language.code].content"
								></ckeditor-field>
							</div>
						</div>
						<template v-for="(fileTypeDetails, fileType) in attributes.file_types">
							<div class="card mb-3">
								<div class="card-body">
									<h4 class="card-title mb-3">{{ fileTypeDetails.name }}</h4>
									<FileUploader
										:field-name="'fileType_' + language.code"
										v-model="attributes.translations[language.code][fileType]"
										:editable="attributes.editable"
										:multiple="fileTypeDetails.multiple"
									></FileUploader>
								</div>
							</div>
						</template>
					</template>
				</div>
			</template>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import FileUploader from "../components/FileUploader.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import CkeditorField from "../components/form/CkeditorField.vue";
import VariableSelect from "../components/form/VariableSelect.vue";
import FormWrapper from "../components/FormWrapper.vue";
import DateTime from "../components/DateTime.vue";
import TextareaField from "../components/form/TextareaField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {
		UpdatedAttribute,
		TextareaField, DateTime, FormWrapper, VariableSelect, CkeditorField, UserBadge, FileUploader, TitleBar},
	layout: AppLayout,
	props: {
		model: Object,
		initialTranslations: Object,
		initialFiles: Object,
		languages: Array,
	},
	data(){
		let attributes = this.model
		let translations = this.initialTranslations
		for (let i = 0; i < this.languages.length; i++){
			if (this.languages[i].is_default){
				for (let j in this.initialFiles[this.languages[i].code]){
					attributes[j] = this.initialFiles[this.languages[i].code][j]
				}
			} else {
				for (let j in this.initialFiles[this.languages[i].code]){
					translations[this.languages[i].code][j] = this.initialFiles[this.languages[i].code][j]
				}
			}
		}
		attributes.translations = translations
		return {
			attributes: attributes,
			loading: false,
			flash: [],
			clipboardCopyTimer: null,
			copiedToClipboard: '',
			activeLanguage: _.first(this.languages).code.toLowerCase()
		}
	},
}
</script>
