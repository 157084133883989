<template>
	<TitleBar title="Projekttípusok" />

	<AdvancedDataTable
		:data-url="route('projectTypes.list')"
		:columns="columns"
		ref="dataTable"
		sort-by="order"
		sort-type="asc"
		name="projectTypes"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('ProjectTypesController_create')">
			<Link :href="route('projectTypes.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
						<attribute icon="far fa-sort-amount-up">
							<number :value="item.priority"/>
						</attribute>

						<color-badge
							:color="item.color"
							:luminosity="item.luminosity"
							:icon="item.icon"
							:content="item.name"
						></color-badge>

						<div class="mobile-horizontal">
							<boolean-badge
								v-model="item.active"
								:key="'activeM_' + item.id"
								active
							/>
							<boolean-badge
								v-model="item.public"
								:key="'publicM_' + item.id"
								public
							/>
						</div>

						<order-cell :model="item" @changed="$refs.dataTable.getData" :key="'orderCell_' + item.id + '_' + item.order"/>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('projectTypes.update')"
					:delete-url="route('projectTypes.delete')"
					:emit-delete-event="true"
					:tracked-edit-mode="trackedEditMode"
					@deleteButtonPressed="deleteButtonPressed"
				>
					<Link :href="route('projectTypes.duplicate') + '/' + item.id" v-if="app.hasPermission('ProjectTypesController_create')" class="btn btn-warning btn-sm" title="Duplikálás"><i class="far fa-clone"></i></Link>
				</TableActionCell>
			</div>
		</template>
		<template #item-active="item">
			<boolean-badge
				v-model="item.active"
				:key="'active_' + item.id"
			/>
		</template>
		<template #item-name="item">
			<color-badge
				:color="item.color"
				:luminosity="item.luminosity"
				:icon="item.icon"
				:content="item.name"
			></color-badge>
		</template>
		<template #item-public="item">
			<boolean-badge
				v-model="item.public"
				:key="'public_' + item.id"
			/>
		</template>
		<template #item-order="item">
			<order-cell :model="item" @changed="$refs.dataTable.getData" :key="'orderCell_' + item.id + '_' + item.order"/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import TableActionCell from "../components/TableActionCell.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import ColorBadge from "../components/ColorBadge.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import Attribute from "../components/Attribute.vue";
import DateTime from "../components/DateTime.vue";
import Number from "../components/Number.vue";
import OrderCell from "../components/OrderCell.vue";

export default {
	components: {
		OrderCell,
		Number,
		DateTime, Attribute, ColorBadge, AdvancedDataTable, Link, BooleanBadge, TableActionCell, TitleBar},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Sorrend',
				value: 'order',
				width: 15
			},
			{
				text: 'Név',
				value: 'name',
			},
			{
				text: 'Aktív',
				value: 'active',
			},
			{
				text: 'Publikus',
				value: 'public',
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
