<script setup>

import DataLoader from "../DataLoader.vue";
import WarehouseBadge from "../badges/WarehouseBadge.vue";
import SelectField from "./SelectField.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import {computed} from "@vue/compat";
import SupplierBadge from "../badges/SupplierBadge.vue";

const props = defineProps({
	errors: Object,
	name: String,
	label: String,
	fieldOnly: Boolean,
	disabled: Boolean,
	excludedId: Number,
	showNormal: {
		type: Boolean,
		default: true
	},
	showMobile: {
		type: Boolean,
		default: true
	},
	requireAddress: Boolean,
	info: String,
	rules: {
		type: String,
		default: ''
	},
})
const dataStore = useGeneralDataStore()
const componentValue = defineModel()
</script>

<template>
	<data-loader :data="['suppliers']" v-slot="{ dataStore }">
		<select-field
			:label="label"
			:name="name"
			v-model="componentValue"
			:options="dataStore.getData('suppliers')"
			:errors="errors"
			:rules="rules"
			:info="info"
			:field-only="fieldOnly"
			:disabled="disabled"
		>
			<template v-slot:option="option">
				<supplier-badge :supplier-id="option.option.id" disable-link :key="'SU_' + option.option.id" />
			</template>
			<template v-slot:selected-option="option">
				<supplier-badge :supplier-id="option.option.id" disable-link :key="'SU_' + option.option.id" />
			</template>
		</select-field>
	</data-loader>
</template>

<style scoped>

</style>
