<template>
	<TitleBar title="Értesítések" />

	<div class="card">
		<div class="card-body">
			<AdvancedDataTable
				:data-url="route('notifications.list')"
				:columns="columns"
				sort-by="created_at"
				sort-type="desc"
				name="notifications"
				:search="false"
			>
				<template #item-title="item">
					<span :class="'badge badge-xl bg-' + item.color"><i :class="item.icon"></i> {{ item.title }}</span>
				</template>
				<template #item-created_at="item">
					<date-time :value="item.created_at"/>
				</template>
				<template #item-read_at="item">
					<div class="text-center">
						<i class="far fa-times text-danger" v-if="!item.read_at"></i>
						<span v-else><date-time :value="item.read_at"/></span>
					</div>
				</template>
				<template #item-operations="{item, deleteButtonPressed}">
					<Link :href="'notifications/redirect/' + item.id" class="btn btn-primary btn-sm"><i class="far fa-eye"></i></Link>
				</template>
			</AdvancedDataTable>
		</div>
	</div>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DateTime from "../components/DateTime.vue";
import {Link} from "@inertiajs/vue3";

export default {
	components: {DateTime, AdvancedDataTable, TitleBar, Link},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true
			},
			{
				text: 'Cím',
				value: 'title',
				sortable: false,
			},
			{
				text: 'Részletek',
				value: 'description',
				sortable: false,
			},
			{
				text: 'Olvasva',
				value: 'read_at',
				sortable: true
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
}
</script>
