<template>
	<data-loader :data="['userLevels']" v-slot="{ dataStore }">
		<div v-if="currentUserLevel">
			<span :class="badgeClasses" :style="currentUserLevel.color ? 'background:' + currentUserLevel.color + ';' : ''">
				<i :class="'me-2 far fa-' + currentUserLevel.icon" v-if="currentUserLevel.icon"></i><span>{{ short ? currentUserLevel.name_short : currentUserLevel.name }}</span>
			</span>
		</div>
	</data-loader>
</template>

<script>
import DataLoader from "./DataLoader.vue";
import {useGeneralDataStore} from "../stores/generalData";

export default {
	components: {DataLoader},
	props: {
		userLevelId: Number,
		userLevels: Array,
		small: Boolean,
		large: Boolean,
		short: Boolean,
	},
	data(){
		return {
			dataStore: useGeneralDataStore()
		}
	},
	methods: {
	},
	computed: {
		currentUserLevel: {
			get() {
				if (!this.userLevelId){
					return null;
				}
				return this.dataStore.getData('userLevels', true)[this.userLevelId]
			}
		},
		badgeClasses: {
			get() {
				let classes = ['colored-badge']
				if (this.currentUserLevel.color){
					classes.push('colored-badge-' + this.currentUserLevel.luminosity)
				} else {
					classes.push('bg-primary')
				}
				if (this.small){
					classes.push('colored-badge-sm')
				}
				if (this.large){
					classes.push('colored-badge-xl')
				}
				return classes
			}
		},
	},
}
</script>

<style scoped>

</style>
