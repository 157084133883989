<template>
	<TitleBar title="Kiszállított termékek" />

	<AdvancedDataTable
		:data-url="route('inventory.deliveredList')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="inventoryDelivered"
	>
		<template #navigation>
			<ButtonGroup>
				<template #add>
					<AddShipmentButton/>
				</template>
				<template #index>
					<Link :href="route('inventory.index')" class="btn btn-secondary btn-label"><i class="far fa-inventory label-icon"></i> Raktárkészlet</Link>
				</template>
				<template #detailed>
					<Link :href="route('inventory.detailed')" class="btn btn-secondary btn-label"><i class="far fa-clipboard-list label-icon"></i> Részletes készlet</Link>
				</template>
				<template #shipments>
					<Link :href="route('inventory.shipments')" class="btn btn-secondary btn-label"><i class="far fa-truck label-icon"></i> Szállítmányok</Link>
				</template>
			</ButtonGroup>
		</template>
		<template #filters="{serverOptions}">
			<div class="data-table-filter-item">
				<warehouse-select-field
					v-model="serverOptions.warehouse_id"
					label="Raktárak"
					name="warehouse_id"
					:field-only="true"
				/>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<product-badge
							:product-id="item.product_id"
							:product-manufacturer-id="item.product_manufacturer_id"
							:product-name="item.productName"
							:product-category-id="item.product_category_id"
							:key="'PB' + item.product_id"
							mobile
						/>

						<color-badge
							color="dark"
							icon="tags"
							:content="item.serial_number"
							v-if="item.serial_number"
						/>

						<div class="mobile-horizontal text">
							<attribute icon="far fa-user">
								{{ item.clientName }}
							</attribute>
							<attribute icon="far fa-hashtag">
								{{ item.contractId }}
							</attribute>
						</div>

						<div class="mobile-horizontal">
							<color-badge color="primary" >
								<number :value="item.stock" :suffix="item.unit"/>
							</color-badge>

							<WarehouseBadge :warehouse-id="item.warehouse_id" />
						</div>
					</div>
				</div>
				<div class="table-row-operations d-flex gap-2">
					<Link :href="(item.serial_number ? route('inventory.serialNumber') + '/' + item.serial_number : route('inventory.product') + '/' + item.product_id)" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="far fa-eye"></i></Link>
				</div>
			</div>
		</template>
		<template #item-productName="item">
			<product-badge
				:product-id="item.product_id"
				:product-manufacturer-id="item.product_manufacturer_id"
				:product-name="item.productName"
				:product-category-id="item.product_category_id"
				:key="'PB' + item.product_id"
				desktop
			/>
		</template>
		<template #item-clientName="item">
			<project-badge
				:project-id="item.project_id"
				:project-type-id="item.project_type_id"
				:contract-id="item.contract_id"
				:key="'PR' + item.project_id + '_C_' + item.contract_id"
			>
				{{ item.clientName }}
			</project-badge>
		</template>
		<template #item-stock="item">
			{{ item.stock }}
		</template>
		<template #item-warehouse_id="item">
			<WarehouseBadge :warehouse-id="item.warehouse_id" />
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import {Link} from "@inertiajs/vue3";
import AddShipmentButton from "../modules/inventory/AddShipmentButton.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import TitleBar from "../components/TitleBar.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import ColorBadge from "../components/ColorBadge.vue";
import Number from "../components/Number.vue";
import Attribute from "../components/Attribute.vue";
import WarehouseBadge from "../components/badges/WarehouseBadge.vue";
import ProductBadge from "../components/badges/ProductBadge.vue";
import ProjectBadge from "../components/badges/ProjectBadge.vue";
import Mobile from "../components/responsive/Mobile.vue";

export default {
	layout: AppLayout,
	components: {
		Mobile,
		ProjectBadge,
		ProductBadge,
		WarehouseBadge,
		Attribute,
		Number,
		ColorBadge,
		ButtonGroup, TitleBar, DataLoader, AdvancedDataTable, WarehouseSelectField, AddShipmentButton, Link},
	data(){
		let columns = [
			{
				text: 'Terméknév',
				value: 'productName',
				sortable: true
			},
			{
				text: 'Cikkszám',
				value: 'sku',
				sortable: true
			},
			{
				text: 'Sorozatszám',
				value: 'serial_number',
				sortable: true
			},
			{
				text: 'Raktár',
				value: 'warehouse_id',
				sortable: true
			},
			{
				text: 'Mennyiség',
				value: 'stock',
				align: 'right',
				sortable: true
			},
			{
				text: 'Ügyfél',
				value: 'clientName',
				sortable: true
			},
			{
				text: 'Szerződés',
				value: 'contractId',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	}
}
</script>
