<template>
	<AdvancedDataTable
			:data-url="'/projects/' + this.projectDataStore.projectId + '/invoices'"
			:columns="columns"
			sort-by="created_at"
			ref="table"
			sort-type="desc"
			name="invoices"
			:row-class-callback="getRowClass"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.invoice_number }}</strong>

						<attribute icon="far fa-clock" label="Dátum">
							<date-time :value="item.date" :time="false" />
						</attribute>
						<attribute icon="far fa-clock" :color="item.expired ? 'danger' : 'success'" label="Határidő">
							<date-time :value="item.deadline" :time="false"/>
						</attribute>
						<attribute icon="far fa-file-contract" label="Szerződés">
							<project-badge
								:project-id="item.project_id"
								:project-type-id="projectDataStore.projectType.id"
								:contract-id="item.contract_id"
								:key="'PR' + item.project_id + '_C_' + item.contract_id"
							>
								{{ item.contract_public_id }}
							</project-badge>
						</attribute>

						<div class="mobile-horizontal">
							<data-loader :data="['invoiceTypeLabels']" v-slot="{ dataStore }">
								<color-badge :value="dataStore.getData('invoiceTypeLabels')[item.type]"></color-badge>
							</data-loader>

							<data-loader :data="['paymentMethods']" v-slot="{ dataStore }">
								<color-badge :value="dataStore.getData('paymentMethods')[item.payment_method]"></color-badge>
							</data-loader>

							<color-badge
								:color="item.paid_at === null ? 'warning' : 'success'"
								:icon="item.paid_at === null ? 'far fa-hourglass' : 'far fa-check'"
							>
								<template v-if="item.paid_at === null">
									Nincs fizetve
								</template>
								<template v-else>
									<date-time :value="item.paid_at" :time="false" />
								</template>
							</color-badge>
						</div>
					</div>
				</div>
				<div class="table-row-operations d-flex gap-2">
					<template v-if="item.file_id">
						<a class="btn btn-secondary btn-sm waves-effect" :href="'/projects/' + projectDataStore.projectId + '/downloadInvoice/' + item.id" title="Letöltés">
							<i class="far fa-download label-icon"></i>
						</a>
						<button type="button" class="btn btn-warning btn-sm waves-effect" v-if="app.hasPermission('ProjectsController_regenerateProformaInvoice') && item.deletable && editable" title="Újragenerálás" @click.prevent="regenerateProformaInvoice(item)">
							<i class="far fa-repeat label-icon"></i>
						</button>
					</template>
					<span class="badge bg-warning" v-else>Generálásra vár</span>
				</div>
			</div>
		</template>
		<template #item-invoice_number="item">
			<div v-if="highlight === item.id" class="highlighted">
				{{item.invoice_number}} <i class="far fa-exclamation-triangle ms-2"></i>
			</div>
			<template v-else>
				{{item.invoice_number}}
			</template>
		</template>
		<template #item-type="item">
			<data-loader :data="['invoiceTypeLabels']" v-slot="{ dataStore }">
				<color-badge :value="dataStore.getData('invoiceTypeLabels')[item.type]" small></color-badge>
			</data-loader>
		</template>
		<template #item-payment_method="item">
			<data-loader :data="['paymentMethods']" v-slot="{ dataStore }">
				<color-badge :value="dataStore.getData('paymentMethods')[item.payment_method]" small></color-badge>
			</data-loader>
		</template>
		<template #item-amount="item">
			<Price
				:value="item.amount"
				:currency-id="item.currency_id"
			/>
		</template>
		<template #item-paid_at="item">
			<color-badge
				:color="item.paid_at === null ? 'warning' : 'success'"
				:icon="item.paid_at === null ? 'far fa-hourglass' : 'far fa-check'"
				small
				>
				<template v-if="item.paid_at !== null">
					<date-time :value="item.paid_at" :time="false" />
				</template>
			</color-badge>
		</template>
		<template #item-contract_public_id="item">
			<project-badge
				:project-id="item.project_id"
				:project-type-id="projectDataStore.projectType.id"
				:contract-id="item.contract_id"
				:key="'PR' + item.project_id + '_C_' + item.contract_id">
				{{ item.contract_public_id }}
			</project-badge>
		</template>
		<template #item-created_at="item">
			<date-time :value="item.date" :time="false" />
		</template>
		<template #item-deadline="item">
			<attribute :color="item.expired ? 'danger' : 'success'">
				<date-time :value="item.deadline" :time="false"/>
			</attribute>
		</template>
	</AdvancedDataTable>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import QuoteForm from './QuoteForm.vue'
import Price from "../../components/Price.vue";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import QuoteResponseForm from "./QuoteResponseForm.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import DataLoader from "../../components/DataLoader.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import {useProjectDataStore} from "../../stores/projectData";
import {useApplicationStore} from "../../stores/applicationStore";
import Attribute from "../../components/Attribute.vue";
import Number from "../../components/Number.vue";
import DateTime from "../../components/DateTime.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";

export default {
	components: {
		ProjectBadge,
		DateTime,
		Number, Attribute,
		ColorBadge,
		DataLoader,
		TableActionDropdown,
		QuoteResponseForm,
		Price,
		TableActionCell,
		QuoteForm,
		AdvancedDataTable,
	},
	emits: ['openContract'],
	props: {
		editable: Boolean,
	},
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'invoice_number',
				sortable: true,
			},
			{
				text: 'Szerződés',
				value: 'contract_public_id',
				sortable: true,
			},
			{
				text: 'Összeg',
				value: 'amount',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Típus',
				value: 'type',
				sortable: true,
			},
			{
				text: 'Fizetés',
				value: 'payment_method',
				sortable: true,
			},
			{
				text: 'Dátum',
				value: 'date',
				sortable: true,
			},
			{
				text: 'Határidő',
				value: 'deadline',
				sortable: true,
			},
			{
				text: 'Fizetve',
				value: 'paid_at',
				sortable: true,
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]
		let highlight = null;
		if (location.hash && location.hash.length > 1){
			let tmp = location.hash.substring(1).split('-')
			if (tmp[0] !== undefined && tmp[0] === 'invoices' && tmp[1] !== undefined && tmp[1].indexOf('highlight') > -1) {
				highlight = parseInt(tmp[1].replace('highlight:', ''))
			}
		}

		return {
			highlight: highlight,
			columns: columns,
			formOpen: false,
			projectDataStore: useProjectDataStore(),
			flashStore: useFlashStoreStore(),
			app: useApplicationStore(),
			quoteFormId: null,
			responseFormOpen: false,
		}
	},
	methods: {
		emitOpenContract: function(id){
			this.$emit('openContract', id);
		},
		regenerateProformaInvoice: function(row){
			axios.put('/projects/regenerateProformaInvoice/' + row.id).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash);
				}
				this.$refs.table.getData()
			}).catch((error) => {
				this.loading = false
				this.flashStore.addError(error)
			})
		},
		getRowClass: function (item, rowNumber){
			let classes = []
			if (this.projectDataStore.activeTabCommand === 'invoice' && parseInt(this.projectDataStore.activeTabParameter) === item.id){
				classes.push('table-info')
			}
			return classes.join(' ')
		}
	}
}
</script>

<style scoped>
</style>
