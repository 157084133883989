<template>
	<TitleBar :title="attributes.id !== undefined ? 'Pénznem módosítása' : 'Pénznem hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('currencies.store', model)"
		:back-url="route('currencies.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div>
						<div class="details-box">
							<div v-if="attributes.public_id !== undefined">
								<i class="far fa-hashtag"></i> Azonosító: <strong>{{ attributes.public_id  }}</strong>
							</div>
							<updated-attribute :data="attributes" created/>
							<updated-attribute :data="attributes" updated/>
							<attribute icon="far fa-recycle" label="Érték frissítve" v-if="attributes.updated_from_remote_at">
								<date-time :value="attributes.updated_from_remote_at"/>
							</attribute>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<switch-field
								label="Elsődleges"
								name="primary"
								v-model="attributes.primary"
								:disabled="!attributes.editable"
							/>

							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>

							<input-field
								label="Kód"
								name="code"
								rules="required"
								v-model="attributes.code"
								:disabled="!attributes.editable"
								:errors="errors"
							/>

							<input-field
								label="Előtag"
								name="prefix"
								rules=""
								v-model="attributes.prefix"
								:disabled="!attributes.editable"
								:errors="errors"
								info="A '_' karakter szóközre cserélődik"
							/>

							<input-field
								label="Utótag"
								name="suffix"
								rules=""
								v-model="attributes.suffix"
								:disabled="!attributes.editable"
								:errors="errors"
								info="A '_' karakter szóközre cserélődik"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								label="Érték"
								name="value"
								rules="required"
								v-model="attributes.value"
								data-type="number"
								:step="0.01"
								:disabled="!attributes.editable"
								v-if="attributes.id === undefined"
								:errors="errors"
							/>
							<input-field
								label="Érték módosítása"
								name="value_offset"
								rules=""
								v-model="attributes.value_offset"
								data-type="number"
								:step="0.01"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Tizedesjegyek"
								name="decimals"
								data-type="number"
								rules="required"
								v-model="attributes.decimals"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<order-field
								v-model="attributes"
								label="Sorrend"
							/>
							<input-field
								label="Frissítési küszöb"
								name="threshold"
								data-type="number"
								:step="0.1"
								v-model="attributes.threshold"
								:disabled="!attributes.editable"
								info="Csak akkor módosítja az értékét, ha a jelenlegi és az importált összeg különbsége nagyobb, mint ez az érték"
								:errors="errors"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import SwitchField from "../components/form/SwitchField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import DateTime from "../components/DateTime.vue";
import TitleBar from "../components/TitleBar.vue";
import Attribute from "../components/Attribute.vue";
import Number from "../components/Number.vue";
import OrderField from "../components/form/OrderField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {
		UpdatedAttribute,
		OrderField, Number, Attribute, TitleBar, DateTime, InputField, FormWrapper, SwitchField},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		return {
			attributes: this.model,
		}
	},
}
</script>
