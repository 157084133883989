<template>
	<div class="stacked-cell">
		<template v-if="showName">
			<div class="mobile-horizontal">
				<strong>{{ item.name }}</strong>
				<div>{{ item.public_id }}</div>
			</div>
		</template>
		<strong v-else>{{ item.public_id }}</strong>

		<div class="mobile-horizontal">
			<project-type-cell :data="item" :small="true"></project-type-cell>

			<data-loader :data="['inquiryStatusLabels']" v-slot="{ dataStore }">
				<span :class="'badge badge-xl bg-' + dataStore.getData('inquiryStatusLabels')[item.status].color">
					<i :class="dataStore.getData('inquiryStatusLabels')[item.status].icon"></i> {{ dataStore.getData('inquiryStatusLabels')[item.status].name }}
				</span>
			</data-loader>
		</div>
	</div>
</template>

<script>
import DataLoader from "../../components/DataLoader.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";

export default {
	components: {ProjectTypeCell, DataLoader},
	props: {
		item: Object,
		showName: Boolean
	}
}
</script>
