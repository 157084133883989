<template>
	<TitleBar title="Raktárkészlet exportálása"/>

	<div class="card">
		<div class="card-body">
			<form :action="route('inventory.export')" method="post">
				<input type="hidden" name="_token" :value="csrf">
				<input type="hidden" name="warehouse_id" :value="exportSettings.warehouse_id">
				<div class="row">
					<div class="col-lg-6">
						<warehouse-select-field
							label="Raktár"
							name="exportWarehouseId"
							v-model="exportSettings.warehouse_id"
							info="Amennyiben nincs kiválasztva, az összes raktár készelete belekerül az exportba."
							rules="required"
						></warehouse-select-field>
					</div>
				</div>
				<button type="submit" class="btn btn-primary btn-label"><i
					class="far fa-file-export label-icon"></i> Export
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import ProductCategorySelectField from "../components/form/ProductCategorySelectField.vue";
import WarehouseSelectField from "../components/form/WarehouseSelectField.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import {usePage, Link} from "@inertiajs/vue3";
import TitleBar from "../components/TitleBar.vue";
import AddShipmentButton from "../modules/inventory/AddShipmentButton.vue";
import ButtonGroup from "../components/ButtonGroup.vue";

export default {
	components: {
		ButtonGroup,
		AddShipmentButton,
		TitleBar, DataLoader, AdvancedDataTable, WarehouseSelectField, ProductCategorySelectField, Link
	},
	layout: AppLayout,
	data() {
		const page = usePage()
		return {
			csrf: page.props.csrf_token,
			exportSettings: {
				warehouse_id: null,
			}
		}
	},
}
</script>
