<template>
	<AdvancedDataTable
		:data-url="route('users.pendingCommissionList', userId)"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		ref="dataTable"
		name="userPendingCommissions"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>
							<Price
								:value="item.amount"
							/>
						</strong>

						<attribute
							icon="far fa-clock"
						>
							<DateTime :value="item.created_at"/>
						</attribute>
						<div class="stacked-cell">
							<strong>{{ item.clientName }}</strong>
							<div class="horizontal">
								<div class="d-flex gap-1 align-items-center">
									<project-status-icon :item="item"/>
									<strong>{{ item.projectPublicId }}</strong>
								</div>

								<project-type-cell :data="item" small/>
							</div>

							<address-attribute :address="item" />
						</div>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
				>
					<background-action-button @call="createCommission($event, item.id)" v-slot="scope" v-if="app.hasPermission('UsersController_createCommission')">
						<button type="button" @click.prevent="scope.click" class="btn btn-primary btn-sm waves-effect"><i class="far fa-check"></i> <span>Kifizetés</span></button>
					</background-action-button>
				</TableActionCell>
			</div>
		</template>
		<template #item-created_at="item">
			<DateTime :value="item.created_at"/>
		</template>
		<template #item-projectPublicId="item">
			<project-badge :project-id="item.project_id" :project-type-id="item.project_type_id" :contract-id="item.contract_id">
				{{ item.clientName }} - {{ item.projectPublicId }}
			</project-badge>
		</template>
		<template #item-amount="item">
			<Price
				:value="item.amount"
			/>
		</template>
	</AdvancedDataTable>
</template>
<script>
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/badges/BooleanBadge.vue";
import UserLevelBadge from "../../components/UserLevelBadge.vue";
import Price from "../../components/Price.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import BackgroundActionButton from "../../components/BackgroundActionButton.vue";
import {useApplicationStore} from "../../stores/applicationStore";
import {useFlashStoreStore} from "../../stores/flashStore";
import DateTime from "../../components/DateTime.vue";
import Attribute from "../../components/Attribute.vue";
import ProjectStatusIcon from "../projects/ProjectStatusIcon.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";
import DataLoader from "../../components/DataLoader.vue";
import AddressAttribute from "../../components/attributes/AddressAttribute.vue";
import ProjectBadge from "../../components/badges/ProjectBadge.vue";

export default {
	components: {
		ProjectBadge,
		AddressAttribute,
		DataLoader,
		ProjectTypeCell, ProjectStatusIcon, Attribute,
		DateTime,
		BackgroundActionButton,
		ColorBadge,
		Price,
		UserLevelBadge,
		AdvancedDataTable,
		BooleanBadge,
		TableActionCell
	},
	props: {
		userId: Number,
	},
	computed: {
	},
	data() {
		let columns = [
			{
				text: 'Időpont',
				value: 'created_at',
				sortable: true,
			},
			{
				text: 'Összeg',
				value: 'amount',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Projekt',
				value: 'projectPublicId',
				sortable: true,
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore(),
			flashStore: useFlashStoreStore()
		}
	},
	methods: {
		reloadTable: function (){
			this.$refs.dataTable.getData()
		},
		createCommission: function (clear, id){
			axios.post(route('users.createCommission') + '/' + id, {}).then((response)=>{
				clear()
				this.reloadTable()
				this.flashStore.addFlash(response.data.flash)
			}).catch((error) => {
				clear()
				this.flashStore.addError(error)
			})
		}
	}
}
</script>

<style scoped>
</style>
