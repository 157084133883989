<template>
	<TitleBar title="Raktárak" />

	<AdvancedDataTable
		:data-url="route('warehouses.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="warehouses"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('WarehousesController_create')">
			<Link :href="route('warehouses.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>

		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>

						<boolean-badge
							v-model="item.mobile"
							true-label="Mozgó raktár"
							false-label="Fix raktár"
							alternative-colors
						/>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('warehouses.update')"
					:is-deletable="false"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
		<template #item-mobile="item">
			<boolean-badge
				v-model="item.mobile"
			/>
		</template>
	</AdvancedDataTable>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TableActionCell from "../components/TableActionCell.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import {useApplicationStore} from "../stores/applicationStore";
import {Link} from "@inertiajs/vue3";
import TitleBar from "../components/TitleBar.vue";
import ColorBadge from "../components/ColorBadge.vue";

export default {
	components: {ColorBadge, TitleBar, AdvancedDataTable, Link, BooleanBadge, TableActionCell},
	layout: AppLayout,
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Mozgó raktár',
				value: 'mobile',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
