<script setup>
import Modal from "../../components/Modal.vue";
import {onMounted, ref} from "vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import ColorBadge from "../../components/ColorBadge.vue";
import DataLoader from "../../components/DataLoader.vue";
import DateTime from "../../components/DateTime.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import InputField from "../../components/form/InputField.vue";
import DateField from "../../components/form/DateField.vue";
import LockMessage from "../../components/LockMessage.vue";
import Attribute from "../../components/Attribute.vue";
import {Link} from "@inertiajs/vue3";
import UpdatedAttribute from "../../components/UpdatedAttribute.vue";
import ProjectIdAttribute from "../../components/attributes/ProjectIdAttribute.vue";
import ContractIdAttribute from "../../components/attributes/ContractIdAttribute.vue";

const emit = defineEmits(['close', 'closeAndReload'])
const props = defineProps({
	projectId: {
		type: Number,
		default: 0
	},
	contractId: {
		type: Number,
		default: 0
	},
	warrantyCardId: {
		type: Number,
		default: 0
	}
})

const loading = ref(true)
const flashStore = useFlashStoreStore()
const projectDetails = ref({})
const contractDetails = ref({})
const products = ref([])
const warrantyCard = ref(null)

onMounted(() => {
	getData()
})

function getData(){
	axios.get('/projects/' + props.projectId + '/warranty/' + props.contractId + (props.warrantyCardId ? '/' + props.warrantyCardId : ''), {}).then((response)=>{
		loading.value = false
		projectDetails.value = response.data.projectDetails
		contractDetails.value = response.data.contractDetails
		products.value = response.data.products

		if (response.data.warrantyCard !== undefined) {
			warrantyCard.value = response.data.warrantyCard
		}
		if (response.data.flash !== undefined) {
			flashStore.addFlash(response.data.flash)
		}


	}).catch((error) => {
		flashStore.addError(error)
	})
}

function submit(){
	let productsPayload = {}
	_.forEach(products.value, (product) => {
		productsPayload[product.key] = {
			warranty_starts_at: product.warranty_starts_at
		}
	})
	axios.post('/projects/' + props.projectId + '/createWarranty/' + props.contractId, {
		products: productsPayload
	}).then((response)=>{
		if (response.data.flash !== undefined){
			flashStore.addFlash(response.data.flash)
		}
		if (response.data.success) {
			emit('closeAndReload')
		}
	}).catch((error) => {
		flashStore.addError(error)
	})
}
</script>

<template>
	<modal
		:title="warrantyCardId ? 'Garanciajegy' : 'Garanciajegy létrehozása'"
		full-width
		:closable="true"
		:z-index="3000"
		:loading="loading"
		buttons-on-top
		@close="emit('close')"
	>
		<template #buttons>
			<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="!warrantyCardId" @click.prevent="submit">
				<i class="fas fa-save label-icon"></i> Mentés
			</button>
			<a class="btn btn-secondary btn-label waves-effect" v-else :href="'/projects/' + projectDetails.id + '/downloadWarrantyCard/' + warrantyCard.id">
				<i class="far fa-download label-icon"></i> Letöltés
			</a>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emit('close')">
				<i class="fas fa-times label-icon"></i> Bezár
			</button>
		</template>

		<template #default>
			<div class="card">
				<div class="card-body">
					<div class="details-box">
						<project-id-attribute :project-id="projectDetails.id" :public-id="projectDetails.public_id" :project-type-id="projectDetails.project_type_id" />
						<contract-id-attribute :project-id="projectDetails.id" :public-id="contractDetails.public_id" :project-type-id="projectDetails.project_type_id" :contract-id="contractDetails.id" />
					</div>
					<div class="details-box" v-if="warrantyCardId">
						<h4>Garanciajegy</h4>

						<attribute icon="far fa-hashtag" label="Azonosító">
							{{ warrantyCard.public_id }}
						</attribute>
						<updated-attribute :data="warrantyCard" created/>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<h4 class="card-title mb-2">Termékek</h4>

					<table class="table table-collapse-mobile-auto mb-3">
						<thead>
						<tr>
							<th>Termék</th>
							<th>Szállítmány</th>
							<th class="text-end">Sorozatszám</th>
							<th class="text-end">Mennyiség</th>
							<th class="text-end" v-if="!warrantyCardId">Garancia ideje</th>
							<th class="text-end" width="150">{{ !warrantyCardId ? 'Garancia kezdet felülírása' : 'Garancia időtartama'}}</th>
						</tr>
						</thead>
						<tbody>
						<template v-for="(item, itemKey) in products" :key="'product_' + itemKey">
							<tr>

								<td>
									<div>{{ item.name }}</div>
									<div class="horizontal">
										<color-badge
											icon="fas fa-hashtag"
											color="light"
											small
										>
											{{ item.sku }}
										</color-badge>
										<data-loader :data="['productManufacturers']" v-slot="{ dataStore }">
											<color-badge
												icon="fas fa-industry-alt"
												color="light"
												small
												v-if="item.product_manufacturer_id"
											>
												{{ dataStore.getData('productManufacturers', true)[item.product_manufacturer_id].name }}
											</color-badge>
										</data-loader>
									</div>
								</td>
								<td>
									<div>{{ item.shipment_public_id }}</div>
								</td>

								<td class="text-end">
									{{ item.serial_number }}
								</td>
								<td class="text-end">
									{{ item.quantity }} {{ item.unit }}
								</td>
								<td class="text-end" v-if="!warrantyCardId">
									{{ item.warranty_months }} hónap
								</td>
								<td class="text-end" v-if="!warrantyCardId">
									<date-field
										:name="'warranty_start_' + itemKey"
										v-model="item.warranty_starts_at"
										field-only
										/>
								</td>
								<td class="text-end" v-else>
									<div class="d-flex flex-column gap-2">
										<date-time :value="item.warranty_valid_from" :time="false" />
										<date-time :value="item.warranty_valid_until" :time="false" />
									</div>
								</td>
							</tr>
						</template>
						</tbody>
					</table>
				</div>
			</div>
		</template>
	</modal>
</template>

<style scoped>

</style>
