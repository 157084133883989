<template>
	<TitleBar :title="projectDataStore.projectId ? 'Projekt szerkesztése' : 'Projekt létrehozása'" />
	<div class="project-form" v-if="projectDataStore.loaded || projectId === undefined || projectId === null">
		<template v-if="projectId === undefined || projectId === null">
			<div class="card mb-3">
				<div class="card-body">
					<div class="wizard clearfix">
						<div class="steps clearfix">
							<ul role="tablist">
								<li role="tab" :class="{first: 1, current: !projectDataStore.client, done: projectDataStore.client}">
									<a href="#">
										<span class="number">1.</span> Ügyfél kiválasztása
									</a>
								</li>
								<li role="tab" :class="{current: projectDataStore.client && !projectDataStore.projectType, done: projectDataStore.client && projectDataStore.projectType}">
									<a href="#">
										<span class="number">2.</span> Projekttípus kiválasztása
									</a>
								</li>
								<li role="tab" :class="{current: projectDataStore.client && projectDataStore.projectType}">
									<a href="#">
										<span class="number">3.</span> Projekt részletek megadása
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!projectDataStore.client">
				<div class="card mb-3">
					<div class="card-body">
						<div class="mb-3 position-relative">
							<label>Válassz ügyfelet</label>
							<v-select
								:options="clientOptions"
								v-model="projectDataStore.client"
								label="name"
								placeholder="Kezd el gépelni a keresést"
								@search="onSearch"
								:filterable="false"
							>
								<template slot="no-options">
									Nincs találat
								</template>
								<template slot="option" slot-scope="option">
									{{ option.public_id}} - {{ option.name }} ({{option.address_text}})
								</template>
								<template slot="selected-option" slot-scope="option">
									{{ option.public_id}} - {{ option.name }} ({{option.address_text}})
								</template>
							</v-select>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!projectDataStore.projectType && projectDataStore.client">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="mb-0">{{ projectDataStore.client.name }}</h4>
					</div>
				</div>
				<div class="card mb-3">
					<div class="card-body">
						<label>Válassz projekttípust</label>
						<div class="mt-2 d-flex flex-wrap gap-2">
							<data-loader :data="['projectTypes']" v-slot="{ dataStore }">
								<template v-for="type in dataStore.getData('projectTypes')">
									<button v-if="type.active" :class="'btn btn-secondary btn-label waves-effect border-0 btn-bg-' + type.luminosity" :style="'background-color:' + type.color + ';'" @click.prevent="createProject(type)">
										<i :class="'far fa-' + type.icon + ' label-icon'"></i>
										{{ type.name }}
									</button>
								</template>
							</data-loader>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<form-wrapper
				ref="formWrapper"
				:submit-function="saveForm"
				v-model="projectDataStore.project"
				:back-url="'/projects/unlock/' + projectDataStore.projectId"
				:tabs="tabs"
				:display-tabs="true"
				:error-event-enabled="true"
				:tab-badges="tabBadges"
				:tab-url-base="'/projects/edit/' + projectDataStore.projectId"
				:lock-url="'/projects/lock/' + projectDataStore.projectId"
				:update-lock-url="'/projects/lockStatus/' + projectDataStore.projectId"
				:store="projectDataStore"
				:initial-tab="tab"
				:initial-tab-parameters="tabParameters"
				@errors-changed="errorsChanged"
				v-if="projectDataStore.loaded"
			>
				<template #buttons="{editable}">
					<ButtonGroup :buttons-to-show="0" desktop>
						<template #close v-if="!projectDataStore.project.closed_at && app.hasPermission('ProjectsController_canCloseProject') && editable" >
							<button type="button" @click.prevent="closeProject" class="btn btn-label btn-success"><i class="far fa-check label-icon"></i> Projekt lezárása</button>
						</template>
						<template #postpone v-if="!projectDataStore.project.closed_at && app.hasPermission('ProjectsController_canPostponeProject') && editable">
							<button type="button" @click.prevent="postponeFormVisible = true" class="btn btn-label btn-light"><i class="far fa-clock label-icon"></i> Projekt elhalasztása</button>
						</template>
						<template #task v-if="app.hasPermission('ProjectsController_canCreateTask')">
							<button type="button" @click.prevent="createTaskFormVisible = true" class="btn btn-label btn-light"><i class="far far fa-list label-icon"></i> Feladat létrehozása</button>
						</template>
						<template #reopen v-if="projectDataStore.project.closed_at && app.hasPermission('ProjectsController_canReopenProject')">
							<Link :href="route('projects.reopen', projectDataStore.projectId)" class="btn btn-label btn-light"><i class="far fa-lock-open label-icon"></i> Projekt újranyitása</Link>
						</template>
						<template #delete v-if="!projectDataStore.project.deleted_at && projectDataStore.project.deletable && editable">
							<confirm-button
								:data="projectDataStore.project"
								text="Projekt törlése"
								:emit-delete-event="true"
								:small="false"
								message="Biztos, hogy törölni akarod?"
								@confirmed="deleteButtonPressed"
							></confirm-button>
						</template>
					</ButtonGroup>
				</template>
				<template v-slot:top>
					<div class="card mb-3">
						<div class="card-body">
							<div class="row">
								<div class="col-md-6">
									<p v-if="projectDataStore.project.public_id" class="mb-0 fw-medium">{{ projectDataStore.project.public_id }}</p>
									<h5 class="mb-0">
										{{ projectDataStore.client.name }}
									</h5>
									<div class="horizontal mt-1 mb-2" v-if="projectDataStore.project.deploy_address !== null || projectDataStore.project.deleted_at || projectDataStore.client.deleted_at">
										<attribute
											class="mb-1"
											v-if="projectDataStore.project.deploy_address !== null"
										>
											<address-badge :address="projectDataStore.project.deploy_address" />
										</attribute>
										<template v-if="projectDataStore.project.deleted_at">
											<color-badge
												color="danger"
												icon="far fa-trash"
												content="Törölt projekt"
											></color-badge>
										</template>
										<template v-if="projectDataStore.client.deleted_at">
											<color-badge
												color="danger"
												icon="far fa-trash"
												content="Törölt ügyfél"
											></color-badge>
										</template>
									</div>
									<div class="d-flex gap-2 flex-wrap">
										<color-badge :value="projectDataStore.projectType"></color-badge>
										<color-badge v-if="projectDataStore.project.closed_at" color="success" icon="far fa-check">
											Lezárva: <date-time :value="projectDataStore.project.closed_at"/>
										</color-badge>
										<color-badge v-if="!projectDataStore.project.closed_at && projectDataStore.project.postponed_until" color="warning" icon="far fa-clock">
											Elhalasztva: <date-time :value="projectDataStore.project.postponed_until" :time="false"/>
										</color-badge>

										<button type="button" class="btn" @click.prevent="detailsVisible = !detailsVisible">Részletek <i :class="'far fa-' + (detailsVisible ? 'chevron-up' : 'chevron-down')"></i></button>

										<LockMessage :lock="projectDataStore.lock" :margin-top="false" :margin-bottom="false"></LockMessage>

										<div class="d-md-none mt-2 d-flex gap-2 w-100">
											<phone-number :value="projectDataStore.client.phone" class="btn btn-secondary btn-label flex-grow-1" icon="far fa-phone label-icon" content="Hívás" />
											<a v-if="projectDataStore.project.deploy_address !== undefined && projectDataStore.project.deploy_address !== null" :href="'geo:' + projectDataStore.project.deploy_address.lat + ',' + projectDataStore.project.deploy_address.lng + '?q=' + projectDataStore.project.deploy_address.formatted" class="btn btn-secondary btn-label flex-grow-1">
												<i class="far fa-map label-icon"></i> Navigáció
											</a>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="mt-2 mt-lg-0">
										<div class="d-lg-none text-center mb-2">
											<button class="btn btn-light btn-label" @click.prevent="showTimelineEntries = !showTimelineEntries" type="button">
												<i class="far fa-stream label-icon"></i> Események {{ showTimelineEntries ? 'elrejtése' : 'mutatása' }}
											</button>
										</div>
										<div :class="{'d-none': !showTimelineEntries, 'd-lg-block': 1}">
											<div class="project-timeline-entity-list-scroll">
												<template v-for="entity in projectDataStore.timelineEntities">
													<project-timeline-entry :event="entity" :show-seen-events="true" :fade-seen="false" compact :show-actions="false" />
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="mt-3 details-box" v-if="detailsVisible">

								<date-time-attribute :value="projectDataStore.project.created_at" created>
									<user-badge :user-id="projectDataStore.project.created_by_user_id" />
								</date-time-attribute>

								<date-time-attribute :value="projectDataStore.project.updated_at" updated>
									<user-badge :user-id="projectDataStore.project.updated_by_user_id" />
								</date-time-attribute>

								<user-attribute :user-id="projectDataStore.project.handler_user_id" v-if="projectDataStore.project.handler_user_id" handler />

								<date-time-attribute :value="projectDataStore.project.client_input_at" display-if-empty client-input />

								<client-attribute :client-id="projectDataStore.client.id" :name="projectDataStore.client.name" :public-id="projectDataStore.client.public_id"  />
							</div>
							<div class="mt-3 details-box" v-if="projectDataStore.inquiry && detailsVisible">
								<h4>Érdeklődés</h4>
								<div>
									<i class="far fa-hashtag"></i> Azonosító:
									<Link :href="'/inquiries/edit/' + projectDataStore.inquiry.id" v-if="app.hasPermission('InquiriesController_view')">{{ projectDataStore.inquiry.public_id }}</Link>
									<strong v-else>{{ projectDataStore.inquiry.public_id }}</strong>
								</div>
								<div>
									<i class="far fa-calendar-alt"></i> Időpont: <strong><date-time :value="projectDataStore.inquiry.created_at"/></strong>
								</div>

								<user-attribute :user-id="projectDataStore.inquiry.handler_user_id" v-if="projectDataStore.inquiry.handler_user_id" handler />
							</div>
						</div>
					</div>
					<project-tasks
						:project-data-store="projectDataStore"
						:featured-task="featuredTask"
					/>
				</template>
				<template #default="{activeTab, errors, editable}">
					<div>
						<div :class="{'d-none': activeTab !== 'attributes'}">
							<div class="card card-body">
								<div class="row">
									<div class="col-md-4">
										<h5 class="card-title">
											Tanácsadó
										</h5>
										<UserBadge :user-id="projectDataStore.project.handler_user_id" v-if="!editable || !admin"></UserBadge>
										<data-loader :data="['userLevels']" v-slot="{ dataStore }" v-else>
											<user-select-field
												name="handlerUser"
												v-model="projectDataStore.project.handler_user_id"
												label="Tanácsadó"
												:errors="{}"
												:field-only="true"
												:disabled-value-only="true"
												:user-levels="dataStore.getData('userLevels')"
											/>
										</data-loader>
									</div>
									<div class="col-md-4" v-if="editable && projectDataStore.can('attributes', 'update')">
										<SwitchField
											v-model="projectDataStore.project.client_input_required"
											label="Kitöltöm az ügyfél helyett a számlázáshoz és a külsős ügyvitelhez szükséges adatokat"
											:field-only="true"
											:invert="true"
											name="client_input_required"
										></SwitchField>
										<p class="mt-2 mb-0">
											Kitöltöm az ügyfél helyett a számlázáshoz és a külsős ügyvitelhez szükséges adatokat
										</p>
									</div>
									<div class="col-md-4" v-if="(((app.hasPermission('ProjectsController_updateAll') || app.userId === projectDataStore.project.handler_user_id) && editable) || projectDataStore.project.shared_commission)">
										<SwitchField
											v-model="projectDataStore.project.shared_commission"
											name="shared_commission"
											label="Jutalékmegosztás"
											:field-only="true"
											@input="sharedCommissionChanged"
											v-if="(app.hasPermission('ProjectsController_updateAll') || app.userId === projectDataStore.project.handler_user_id) && editable"
										></SwitchField>

										<p class="mt-2 mb-0">
											Jutalékmegosztás
										</p>
									</div>
								</div>

								<div class="mt-3" v-if="(((app.hasPermission('ProjectsController_updateAll') || app.userId === projectDataStore.project.handler_user_id) && editable) || projectDataStore.project.shared_commission)">
									<table class="table m-0" v-if="projectDataStore.project.shared_commission">
										<thead>
										<tr>
											<th width="50%">Felhasználó</th>
											<th>Arány</th>
											<th width="1%" v-if="(app.hasPermission('ProjectsController_updateAll') || app.userId === projectDataStore.project.handler_user_id) && editable"></th>
										</tr>
										</thead>
										<tbody>
										<template v-for="(item, itemKey) in projectDataStore.commissions" :key="itemKey">
											<tr>
												<td>
													<data-loader :data="['userLevels']" v-slot="{ dataStore }">
														<user-select-field
															v-model="item.user_id"
															:field-only="true"
															:disabled="item.user_id === projectDataStore.project.handler_user_id || !(app.hasPermission('ProjectsController_updateAll') || app.userId === projectDataStore.project.handler_user_id) || !editable"
															:name="'shared_commission_user_id_' + itemKey"
															:disabled-value-only="true"
															:user-levels="dataStore.getData('userLevels')"
															:key="'userSelect' + item.user_id"
														></user-select-field>
													</data-loader>
												</td>
												<td>
													<input-field
														v-model="item.portion"
														data-type="number"
														:field-only="true"
														suffix-icon="far fa-percent"
														:disabled="item.user_id === projectDataStore.project.handler_user_id || !editable"
														:name="'shared_commission_portion_' + itemKey"
														@change="calculateCommission"
														:disabled-value-only="true"
														@update:modelValue="calculateCommission"
													></input-field>
												</td>
												<td v-if="(app.hasPermission('ProjectsController_updateAll') || app.userId === projectDataStore.project.handler_user_id) && editable">
													<button class="btn btn-danger" v-if="item.user_id !== projectDataStore.project.handler_user_id" title="Töröl" type="button" @click.prevent="removeFromCommissionList(itemKey)"><i class="far fa-times"></i></button>
												</td>
											</tr>
										</template>
										</tbody>
										<tfoot v-if="(app.hasPermission('ProjectsController_updateAll') || app.userId === projectDataStore.project.handler_user_id) && editable">
										<tr>
											<td colspan="3">
												<button class="btn btn-secondary btn-label" type="button" @click.prevent="addItemToCommissionList"><i class="far fa-plus label-icon"></i> Hozzáad</button>
											</td>
										</tr>
										</tfoot>
									</table>
								</div>
							</div>

							<ProjectFormAttributes
								v-model="projectDataStore.project"
								:errors="errors"
								:editable="true"
								v-if="editable"
								:allow-update="projectDataStore.can('attributes', 'update')"
								user-type="user"
								ref="projectAttributes"
								@valueChanged="valueChanged"
							></ProjectFormAttributes>
							<ProjectFormAttributes
								v-model="projectDataStore.project"
								:errors="errors"
								:editable="false"
								v-else
								:allow-update="projectDataStore.can('attributes', 'update')"
								user-type="user"
								ref="projectAttributes"
								@valueChanged="valueChanged"
							></ProjectFormAttributes>
						</div>

						<ProjectFormDocuments
							:editable="editable"
							ref="documents"
							v-if="activeTab === 'documents'"
							@updateBadgeCount="updateBadgeCount"
						></ProjectFormDocuments>

						<div v-if="activeTab === 'progress'">
							<div class="card">
								<div class="card-body">
									<table class="table mb-0 table-collapse-mobile">
										<thead class="table-light">
										<tr>
											<th>Mérföldkő</th>
											<th>Időpont</th>
											<th>Felhasználó</th>
										</tr>
										</thead>
										<tbody>
										<tr v-for="milestone in projectDataStore.projectType.milestones">
											<td>
												<div :class="'milestone-badge milestone-badge-' + milestone.luminosity + (projectDataStore.project.milestone_log[milestone.id] === undefined ? ' milestone-badge-inactive' : '')" :style="'background:' + milestone.color + ';'">
													<i :class="'far fa-' + milestone.icon"></i> {{ milestone.name }}
												</div>
											</td>
											<td>
												<div v-if="projectDataStore.project.milestone_log[milestone.id] !== undefined"><date-time :value="projectDataStore.project.milestone_log[milestone.id].created_at"/></div>
											</td>
											<td>
												<div v-if="projectDataStore.project.milestone_log[milestone.id] !== undefined">{{ projectDataStore.project.milestone_log[milestone.id].created_by }}</div>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div v-if="activeTab === 'comments'">
							<Comments
								:data-url="'/projects/' + projectDataStore.projectId + '/comments'"
								:can-create="editable && projectDataStore.can('comments', 'create')"
								@comment-count="commentCountUpdated"
								:active-tab-command="projectDataStore.activeTabCommand"
								:active-tab-parameter="projectDataStore.activeTabParameter"
							></Comments>
						</div>
						<div v-if="activeTab === 'quotes'">
							<QuoteList
								:editable="editable"
								ref="quoteList"
								:can-start-editing="canStartEditing"
								@start-editing="startEditing"
								@update-stats="updateStats"
								@open-contract="openContract"
								:form-errors="formErrors"
							/>
						</div>
						<div v-if="activeTab === 'contracts'">
							<ContractList
								:editable="editable"
								ref="contractList"
								:can-start-editing="canStartEditing"
								@start-editing="startEditing"
								@update-stats="updateStats"
								@open-quote="openQuote"
								:form-errors="formErrors"
							/>
						</div>
						<div v-if="activeTab === 'invoices'">
							<InvoiceList
								:editable="editable"
								ref="invoiceList"
								@open-contract="openContract"
							/>
						</div>
						<div v-if="activeTab === 'roles'">
							<ProjectRoles
								:editable="editable"
							/>
						</div>
					</div>
				</template>
			</form-wrapper>
		</template>
	</div>

	<modal
		title="Projekt elhalasztása"
		:closable="true"
		v-if="postponeFormVisible"
		@close="postponeFormVisible = false">
		<template #default>
			<date-field
				label="Elhalasztás eddig"
				name="postponed_until"
				v-model="postponeAttributes.postponed_until"
				future
			/>

			<textarea-field
				label="Elhalasztás üzenet"
				name="postponed_message"
				v-model="postponeAttributes.postponed_message"
			/>
		</template>

		<template #buttons>
			<button type="button" class="btn btn-primary btn-label waves-effect" @click.prevent="postponeProject"><i class="far fa-save label-icon"></i> Mentés</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="postponeFormVisible = false"><i class="fas fa-times label-icon"></i> Bezár</button>
		</template>
	</modal>
	<task-modal
		v-if="createTaskFormVisible"
		:project-data-store="projectDataStore"
		@close="createTaskFormVisible = false"
		@close-and-reload="createTaskFormVisible = false"
		/>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import VueUploadComponent from 'vue-upload-component'
import QuoteList from '../modules/projects/QuoteList.vue'
import PhoneNumber from '../components/PhoneNumber.vue';
import EmailAddress from '../components/EmailAddress.vue';
import FileIcon from "../components/FileIcon.vue";
import Comments from "../components/Comments.vue";
import ConfirmButton from "../components/ConfirmButton.vue";
import ConfirmModal from "../components/ConfirmModal.vue";
import vSelect from 'vue-select'
import AddressInput from "../components/AddressInput.vue";
import TextareaAutosize from "../components/TextareaAutosize.vue";
import SwitchField from "../components/form/SwitchField.vue";
import ProjectFormAttributes from "../modules/projects/ProjectFormAttributes.vue";
import ContractList from "../modules/projects/ContractList.vue";
import InvoiceList from "../modules/projects/InvoiceList.vue";
import UserSelectField from "../components/form/UserSelectField.vue";
import LockMessage from "../components/LockMessage.vue";
import ColorBadge from "../components/ColorBadge.vue";
import FormWrapper from "../components/FormWrapper.vue";
import ProjectFormDocuments from "../modules/projects/ProjectFormDocuments.vue";
import QuoteForm from "../modules/projects/QuoteForm.vue";
import SelectField from "../components/form/SelectField.vue";
import InputField from "../components/form/InputField.vue";
import DateField from "../components/form/DateField.vue";
import TextareaField from "../components/form/TextareaField.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import DataLoader from "../components/DataLoader.vue";
import {useFlashStoreStore} from "../stores/flashStore";
import {useProjectDataStore} from "../stores/projectData";
import Modal from "../components/Modal.vue";
import {router, Link} from "@inertiajs/vue3";
import {useApplicationStore} from "../stores/applicationStore";
import DateTime from "../components/DateTime.vue";
import AddShipmentButton from "../modules/inventory/AddShipmentButton.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import ProjectRoles from "../modules/projects/ProjectRoles.vue";
import ProjectTasks from "../modules/projects/ProjectTasks.vue";
import Attribute from "../components/Attribute.vue";
import TaskModal from "../modules/dashboard/TaskModal.vue";
import ProjectTimelineEntry from "../modules/dashboard/ProjectTimelineEntry.vue";
import UserAttribute from "../components/attributes/UserAttribute.vue";
import DateTimeAttribute from "../components/attributes/DateTimeAttribute.vue";
import ContractIdAttribute from "../components/attributes/ContractIdAttribute.vue";
import ClientBadge from "../components/badges/ClientBadge.vue";
import ClientAttribute from "../components/attributes/ClientAttribute.vue";
import AddressBadge from "../components/badges/AddressBadge.vue";

export default {
	components: {
		AddressBadge,
		ClientAttribute,
		ClientBadge,
		ContractIdAttribute, DateTimeAttribute,
		UserAttribute,
		ProjectTimelineEntry,
		TaskModal,
		Attribute,
		ProjectTasks,
		ProjectRoles,
		AdvancedDataTable, ButtonGroup, AddShipmentButton,
		DateTime,
		TitleBar,
		Link,
		Modal,
		DataLoader,
		UserBadge,
		TextareaField,
		DateField,
		InputField,
		SelectField,
		QuoteForm,
		ProjectFormDocuments,
		FormWrapper,
		ColorBadge,
		LockMessage,
		UserSelectField,
		ProjectFormAttributes,
		SwitchField,
		VueUploadComponent,
		QuoteList,
		PhoneNumber,
		EmailAddress,
		FileIcon,
		Comments,
		ConfirmButton,
		ConfirmModal,
		vSelect,
		AddressInput,
		TextareaAutosize,
		ContractList,
		InvoiceList,
	},
	layout: AppLayout,

	props: {
		admin: Boolean,
		projectId: Number,
		stats: Object,
		featuredTask: Object,
		client: Object,
		tab: String,
		tabParameters: String,
	},
	data(){
		return {
			clientOptions: [],
			flashStore: useFlashStoreStore(),
			projectDataStore: useProjectDataStore(),
			app: useApplicationStore(),
			detailsVisible: false,
			postponeFormVisible: false,
			createTaskFormVisible: false,
			tabBadges: this.stats,
			formErrors: {},
			tabs: [],
			postponeAttributes: {},
			showTimelineEntries: false
		}
	},
	created() {
		this.projectDataStore.clearData()
		if (this.projectId !== undefined && this.projectId !== null) {
			this.projectDataStore.projectId = this.projectId
			this.projectDataStore.loadData(this.setVisibleTabs)

			this.postponeAttributes = {
				postponed_until: this.projectDataStore.project.postponed_until,
				postponed_message: this.projectDataStore.project.postponed_message
			}
		} else if (this.client){
			this.projectDataStore.client = this.client
		}
	},
	beforeUnmount() {
		this.projectDataStore.clearData()
	},
	computed: {
		clientAddress: {
			get() {
				for (let i = 0; i < this.projectDataStore.projectType.fields_data.length; i++){
					if (this.projectDataStore.projectType.fields_data[i].type === 'address'){
						if (this.projectDataStore.project.attribute_values[this.projectDataStore.projectType.fields_data[i].id] !== undefined){
							return this.projectDataStore.project.attribute_values[this.projectDataStore.projectType.fields_data[i].id].formatted_value
						}
					}
				}
				return ''
			}
		},
		canStartEditing: {
			get() {
				return this.$refs.formWrapper !== undefined && this.$refs.formWrapper.lockable && this.projectDataStore.project.editable && !this.$refs.formWrapper.lockAcquired
			}
		}
	},
	methods: {
		setVisibleTabs: function(){
			let tabs = []
			let possibleTabs = this.projectDataStore.possibleTabs
			_.forEach(possibleTabs, (tab) => {
				if (this.projectDataStore.can(tab.name, 'read')){
					tabs.push(tab)
				}
			})
			this.tabs = tabs
		},
		onSearch(search, loading) {
			if(search.length) {
				loading(true);
				this.search(loading, search, this);
			}
		},
		search: function(loading, search, vm) {
			fetch(
				'/projects/clientSearch/' + search
			).then(res => {
				res.json().then(json => (vm.clientOptions = json.results));
				loading(false);
			});
		},
		saveForm: function(e){
			let payload = {
				attributes: JSON.parse(JSON.stringify(this.projectDataStore.project['attribute_values'])),
				client_input_required: this.projectDataStore.project.client_input_required,
			}
			if (this.projectDataStore.documentsLoaded){
				payload.uploadedFiles = this.projectDataStore.uploadedFiles
				payload.documents = this.projectDataStore.documents
			}
			if (this.admin){
				payload.handlerUserId = this.projectDataStore.project.handler_user_id
				payload.roles = this.projectDataStore.roles
			}
			if ((this.app.hasPermission('ProjectsController_updateAll') || this.app.userId === this.projectDataStore.project.handler_user_id)){
				payload.commissions = this.projectDataStore.commissions
				payload.shared_commission = this.projectDataStore.project.shared_commission
			}

			axios.post('/projects/save/' + this.projectDataStore.projectId, payload).then((response)=>{
				this.handleResponse(response)
				this.$refs.formWrapper.lockResponse(response)
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		handleResponse: function (response){
			if (response.data.redirect !== undefined){
				this.$refs.formWrapper.formClean()
				router.visit(response.data.redirect)
			}

			this.$refs.formWrapper.formClean()
			this.projectDataStore.projectSaveResponse(response)

			if (response.data.stats !== undefined) {
				this.tabBadges = response.data.stats
			}

			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}

			if (this.$refs.projectAttributes !== undefined) {
				this.$refs.projectAttributes.updateVisibleFields()
			}
		},
		createProject: function(type){
			axios.post('/projects/create', {
				typeId: type.id,
				clientId: this.projectDataStore.client.id
			}).then((response)=>{
				router.visit(response.data.redirect)
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		commentCountUpdated: function(count){
			this.tabBadges.comments = count
		},
		updateStats: function (stats){
			this.tabBadges = stats
		},
		openContract: function(id){
			this.$refs.formWrapper.setActiveTab('contracts')

			setTimeout(() => {
				this.$refs.contractList.openContract(id)
			}, 100)
		},
		openQuote: function(id){
			this.$refs.formWrapper.setActiveTab('quotes')

			setTimeout(() => {
				this.$refs.quoteList.openContract(id)
			}, 100)
		},
		closeProject: function (){
			axios.post('/projects/close/' + this.projectDataStore.projectId).then((response) => {
				if (response.data.success) {
					this.handleResponse(response)
					this.$refs.formWrapper.lockResponse(response)
				} else {
					this.flashStore.addFlash(response.data.flash)
				}
			}).catch((error) => {
				this.flashStore.addFlash({
					level: 'danger',
					message: error.response !== undefined ? error.response.data.message : error
				})
			})
		},
		startEditing: function (){
			this.$refs.formWrapper.startEditing()
		},
		updateBadgeCount: function (tab, count){
			this.tabBadges[tab] = count
		},
		postponeProject: function (){
			this.postponeFormVisible = false
			axios.post('/projects/postpone/' + this.projectDataStore.projectId, this.postponeAttributes).then((response)=>{
				this.handleResponse(response)
				this.$refs.formWrapper.lockResponse(response)
			}).catch((error) => {
				this.flashStore.addFlash({
					level: 'danger',
					message: error.response !== undefined ? error.response.data.message : error
				})
			})
		},
		errorsChanged: function (errors){
			this.formErrors = errors

			let firstErrorField = null
			let onlyAttributeErrors = true
			_.forEach(this.formErrors, function (msg, field){
				if (field.indexOf('projectAttribute_') === 0){
					if (firstErrorField === null) {
						firstErrorField = field
					}
				} else {
					onlyAttributeErrors = false
				}
			})

			if (firstErrorField && onlyAttributeErrors) {
				this.$refs.formWrapper.setActiveTab('attributes')
				setTimeout(() => {
					this.$refs.formWrapper.setActiveTab('attributes', 'scroll:' + firstErrorField)
				}, 50)
			}
		},
		sharedCommissionChanged: function (v){
			if (this.projectDataStore.project.shared_commission && !this.projectDataStore.commissions.length){
				this.projectDataStore.commissions.push({
					user_id: this.projectDataStore.project.handler_user_id,
					portion: 100
				})
			}
		},
		addItemToCommissionList: function (){
			this.projectDataStore.commissions.push({
				user_id: null,
				portion: 0
			})
		},
		calculateCommission: function (){
			let handlerPortion = 100
			let handlerKey = null
			for (let i = 0; i < this.projectDataStore.commissions.length; i++){
				if (this.projectDataStore.commissions[i].user_id !== this.projectDataStore.project.handler_user_id){
					let portion = this.projectDataStore.commissions[i].portion
					if (portion >= handlerPortion){
						portion = handlerPortion - 1
						this.projectDataStore.commissions[i].portion = portion
					}
					handlerPortion -= portion
				} else {
					handlerKey = i
				}
			}

			this.projectDataStore.commissions[handlerKey].portion = handlerPortion
		},
		removeFromCommissionList: function (index){
			this.projectDataStore.commissions.splice(index, 1)
			this.calculateCommission()
		},
		valueChanged: function (e){
			this.$refs.formWrapper.valueChanged(e)
		},
		deleteButtonPressed: function (e){
			axios.delete('/projects/delete/' + this.projectDataStore.projectId).then((response)=>{
				this.handleResponse(response)
				this.$refs.formWrapper.lockResponse(response)
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		}
	}
}
</script>

<style scoped>

</style>
