<template>
	<div class="mb-3">
		<label>{{ label }}</label>

		<div class="d-flex gap-2">
			<radio-field
				:label="label"
				v-model="componentValue.set_order"
				:field-only="true"
				:values="{top: {label: 'Legelső', icon: 'far fa-arrow-to-top'}, bottom: {label: 'Legutolsó', icon: 'far fa-arrow-to-bottom'}, custom: {label: 'Pontos pozíció', icon: 'far fa-arrow-to-right'}}"
			/>
			<input type="number" class="form-control" :min="1" style="width:100px" v-if="componentValue.set_order === 'custom'" v-model="componentValue.set_order_index" />
		</div>
	</div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from "yup";
import RadioField from "./RadioField.vue";

export default {
	emits: ['update:modelValue', 'input'],
	components: {RadioField, Field, ErrorMessage},
	props: {
		label: String,
		modelValue: Object,
	},
	data() {
		return {
			rulesValue: null,
			isRequired: false
		}
	},
	created() {
		if (this.componentValue.id === undefined){
			this.componentValue.set_order = 'top'
			this.componentValue.set_order_index = 1
		} else {
			this.componentValue.set_order = 'custom'
			this.componentValue.set_order_index = Math.max(this.componentValue.order, 1)
		}
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
