<template>
	<div>
		<template v-if="dropdown">
			<a href="#" :class="classes" v-on:click.prevent="onClick" v-if="buttonText.length"><i :class="iconClass"></i> {{buttonText}}</a>
			<a href="#" :class="classes" v-on:click.prevent="onClick" v-else><i :class="iconClass"></i> Törlés</a>
		</template>
		<template v-else>
			<a href="#" :class="classes" v-on:click.prevent="onClick" v-if="buttonText.length"><i :class="iconClass"></i> {{buttonText}}</a>
			<a href="#" :class="classes" v-on:click.prevent="onClick" v-else title="Törlés"><i :class="iconClass"></i></a>
		</template>
		<modal
			title="Megerősítés"
			:full-width="false"
			:closable="true"
			v-if="modalActive"
			:z-index="9999999"
		>
			<template #default>
				{{ confirmMessage }}
			</template>

			<template #buttons>
				<button type="button" class="btn btn-danger btn-label waves-effect" @click.prevent="yesSelected"><i class="far fa-trash-alt label-icon"></i> Igen</button>
				<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="noSelected"><i class="fas fa-times label-icon"></i> Nem</button>
			</template>
		</modal>
	</div>
</template>

<script>
import ConfirmModal from "./ConfirmModal.vue";
import {router} from "@inertiajs/vue3";
import Modal from "./Modal.vue";
export default {
	components: {Modal, ConfirmModal},
	emits: ['confirmed'],
	props: {
		data: Object,
		url: {
			type: String,
			default: ''
		},
		message: {
			type: String,
			default: 'Biztos, hogy törölni akarod?'
		},
		text: {
			type: String,
			default: ''
		},
		icon: {
			type: String,
			default: 'fas fa-times'
		},
		buttonClass: {
			type: String,
			default: 'danger'
		},
		small: {
			type: Boolean,
			default: true
		},
		dropdown: {
			type: Boolean,
			default: false
		},
		emitDeleteEvent: Boolean
	},
	data() {
		return {
			classes: [],
			iconClass: [],
			modalActive: false,
			buttonText: '',
			confirmMessage: this.message
		}
	},
	mounted() {
		if (this.text.length) {
			this.buttonText = this.text
			this.iconClass = this.icon + ' text-danger fa-fw me-1'
		}
		if (this.dropdown){
			this.classes = ['dropdown-item']
		} else {
			this.classes = ['btn', 'btn-' + this.buttonClass, 'waves-effect']
			if (this.small) {
				this.classes.push('btn-sm')
			}
			this.iconClass = this.icon
			if (this.buttonText.length) {
				this.classes.push('btn-label')
				this.iconClass += ' label-icon'
			}
		}
	},
	methods: {
		onClick: function(){
			this.modalActive = true
		},
		yesSelected: function(){
			if (this.emitDeleteEvent){
				this.$emit('confirmed', this.data.id, this.url)
				this.modalActive = false
			} else {
				router.visit(this.url)
			}
		},
		noSelected: function(){
			this.modalActive = false
		}
	}
}
</script>

<style scoped>

</style>
