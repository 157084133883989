<template>
	<TitleBar title="Szerepek" />

	<AdvancedDataTable
		:data-url="route('roles.list')"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="roles"
		show-tracked-edit-mode
	>
		<template #navigation v-if="app.hasPermission('RolesController_create')">
			<Link :href="route('roles.create')" class="btn btn-primary btn-label"><i class="fas fa-plus label-icon"></i> Hozzáadás</Link>
		</template>
		<template #item-operations="{item, deleteButtonPressed, trackedEditMode}">
			<div class="operations-wrapper">
				<div class="mobile-cell">
					<div class="stacked-cell">
						<strong>{{ item.name }}</strong>
					</div>
				</div>
				<TableActionCell
					:data="item"
					:edit-url="route('roles.update')"
					:delete-url="route('roles.delete')"
					:is-deletable="true"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
					:tracked-edit-mode="trackedEditMode"
				></TableActionCell>
			</div>
		</template>
	</AdvancedDataTable>
</template>

<script>
import Price from "../components/Price.vue";
import TableActionCell from "../components/TableActionCell.vue";
import BooleanBadge from "../components/badges/BooleanBadge.vue";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import DataLoader from "../components/DataLoader.vue";
import UserLevelBadge from "../components/UserLevelBadge.vue";
import ColorBadge from "../components/ColorBadge.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {Link} from "@inertiajs/vue3";
import DateTime from "../components/DateTime.vue";
import {useApplicationStore} from "../stores/applicationStore";
import TitleBar from "../components/TitleBar.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import EmailAddress from "../components/EmailAddress.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import SaleStatusBadge from "../components/badges/SaleStatusBadge.vue";
import ClientBadge from "../components/badges/ClientBadge.vue";

export default {
	layout: AppLayout,
	components: {
		ClientBadge,
		SaleStatusBadge,
		PhoneNumber, EmailAddress,
		ButtonGroup,
		TitleBar,
		DateTime,
		ColorBadge, UserLevelBadge, DataLoader, AdvancedDataTable, BooleanBadge, TableActionCell, Price, Link},
	props: {
	},
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			app: useApplicationStore()
		}
	},
}
</script>
