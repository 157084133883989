<template>
	<template v-if="!desktop || (Object.keys($slots).length <= buttonsToShow)">
		<template v-for="(key, keyIndex) in Object.keys($slots)">
			<template v-if="keyIndex < buttonsToShow">
				<slot :name="key"></slot>
			</template>
			<template v-else-if="!desktop">
				<div :class="{'button-group-hide-on-mobile': !mobileShowButtons}">
					<slot :name="key"></slot>
				</div>
			</template>
		</template>
	</template>
	<template v-if="Object.keys($slots).length > buttonsToShow">
		<div :class="{'dropdown': 1, 'button-group-dropdown': 1, 'button-group-dropdown-desktop': desktop}">
			<button type="button" class="btn btn-secondary button-group-toggle-btn" data-bs-toggle="dropdown" aria-expanded="false"
					><i class="far fa-ellipsis-h"></i></button>

			<ul class="dropdown-menu" role="menu">
				<template v-for="(key, keyIndex) in Object.keys($slots)">
					<li>
						<slot :name="key" v-if="keyIndex >= buttonsToShow"></slot>
					</li>
				</template>
			</ul>
		</div>
	</template>
</template>

<script>
export default {
	props: {
		buttonsToShow: {
			type: Number,
			default: 1
		},
		desktop: Boolean
	},
	data() {
		return {
			mobileShowButtons: false
		}
	}
}
</script>
