<template>
	<data-loader :data="['productTypes']" v-slot="{ dataStore }">
		<select-field
			:label="label"
			:name="name"
			v-model="componentValue"
			:options="dataStore.getData('productTypes', true)"
			:errors="errors"
			:rules="rules"
			:info="info"
			:disabled="disabled"
			:field-only="fieldOnly"
			:display-badge="true"
		>
		</select-field>
	</data-loader>
</template>

<script>
import SelectField from "./SelectField.vue";
import DataLoader from "../DataLoader.vue";

export default {
	emits: ['update:modelValue'],
	components: {DataLoader, SelectField},
	props: {
		modelValue: String,
		errors: Object,
		userLevels: Array,
		name: String,
		label: String,
		info: String,
		disabled: Boolean,
		fieldOnly: Boolean,
		rules: {
			type: String,
			default: ''
		},
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	mounted() {
	},
	data(){
		return {
		}
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
