<template>
	<div>
		<template v-if="show">
			<modal
				:title="title"
				:z-index="99999"
				@close="$emit('close')">

				<template #buttons>
					<button type="button" :class="noButtonClasses" v-on:click.prevent="noClicked"><i :class="noButtonIconClasses" v-if="noButtonIcon"></i>{{ noButtonText }}</button>
					<button type="button" :class="yesButtonClasses" v-on:click.prevent="yesClicked"><i :class="yesButtonIconClasses" v-if="yesButtonIcon"></i>{{ yesButtonText }}</button>
				</template>

				<template #default>
					fsdfsdfsd
					<div class="modal-body" v-html="content"></div>
				</template>
			</modal>
		</template>
	</div>
</template>

<script>

import Modal from "./Modal.vue";

export default {
	name: "ConfirmModal",
	components: {Modal},
	emits: ['no', 'yes'],
	props: {
		title: {
			default: ''
		},
		content: {
			default: ''
		},
		yesButtonText: {
			default: 'Igen'
		},
		yesButtonClass: {
			default: 'danger'
		},
		yesButtonIcon: {
			default: 'fas fa-check'
		},
		noButtonText: {
			default: 'Nem'
		},
		noButtonClass: {
			default: 'secondary'
		},
		noButtonIcon: {
			default: 'fas fa-times'
		},
	},
	data() {
		return {
			yesButtonClasses: 'btn btn-' + this.yesButtonClass,
			noButtonClasses: 'btn btn-' + this.noButtonClass,
			yesButtonIconClasses: this.yesButtonIcon ? 'me-2 ' + this.yesButtonIcon : '',
			noButtonIconClasses: this.noButtonIcon ? 'me-2 ' + this.noButtonIcon : '',
			bootstrapModal: null,
			show: true
		}
	},
	mounted() {
	},
	methods: {
		yesClicked: function(){
			this.$emit('yes', 1)
			this.show = false
		},
		noClicked: function(){
			this.$emit('no', 0)
			this.show = false
		},
	}
}
</script>

<style scoped>

</style>
