<template>
	<TitleBar title="Telepítés" />

	<form-wrapper
		ref="formWrapper"
		v-model="installationAttributes"
		:back-url="route('installations.unlock', installation.id)"
		:tabs="tabs"
		:display-tabs="true"
		:lock-url="'/projects/lock/' + projectDataStore.projectId"
		:update-lock-url="'/projects/lockStatus/' + projectDataStore.projectId"
		:error-event-enabled="true"
		:tab-badges="tabBadges"
		:submit-function="saveForm"
		:show-submit-button="false"
		@lock-acquired="lockAcquired"
		:store="projectDataStore"
		v-if="projectDataStore.loaded"
	>
		<template v-slot:top>
			<div class="card mb-3">
				<div class="card-body">
					<h4>
						<strong v-if="project.public_id" class="me-1">{{ project.public_id }}</strong>
						{{ installation.contract.project.client.name }}
					</h4>

					<div class="d-flex gap-2 flex-wrap">
						<color-badge :value="projectDataStore.projectType"></color-badge>
						<button type="button" class="btn" @click.prevent="detailsVisible = !detailsVisible">Részletek <i :class="'far fa-' + (detailsVisible ? 'chevron-up' : 'chevron-down')"></i></button>

						<LockMessage :lock="projectDataStore.lock" :margin-top="false" :margin-bottom="false"></LockMessage>

						<div class="d-md-none mt-2 d-flex gap-2 w-100">
							<phone-number :value="projectDataStore.client.phone" class="btn btn-secondary btn-label flex-grow-1" icon="far fa-phone label-icon" content="Hívás" />
							<a v-if="projectDataStore.project.deploy_address !== undefined && projectDataStore.project.deploy_address !== null" :href="'geo:' + projectDataStore.project.deploy_address.lat + ',' + projectDataStore.project.deploy_address.lng + '?q=' + projectDataStore.project.deploy_address.formatted" class="btn btn-secondary btn-label flex-grow-1">
								<i class="far fa-map label-icon"></i> Navigáció
							</a>
						</div>
					</div>

					<div class="mt-3 details-box" v-if="project.id !== undefined && detailsVisible">

						<date-time-attribute :value="project.created_at" created>
							<user-badge :user-id="project.created_by_user_id" />
						</date-time-attribute>

						<date-time-attribute :value="project.updated_at" updated>
							<user-badge :user-id="project.updated_by_user_id" />
						</date-time-attribute>

						<user-attribute :user-id="project.handler_user_id" v-if="project.handler_user_id" handler />

						<div v-if="project.client_input_required || project.client_input_at">
							<i class="far fa-clipboard-check"></i> Ügyfél adatkitöltés:
							<strong>
								<date-time v-if="project.client_input_at" :value="project.client_input_at"/>
								<template v-else>-</template>
							</strong>
						</div>

						<client-attribute  :client-id="client.id" :name="client.name" :public-id="client.public_id"/>

						<inquiry-attribute v-if="project.inquiry" :inquiry-id="project.inquiry.id" :public-id="project.inquiry.public_id" :name="project.inquiry.name" />
					</div>

					<div class="mt-3 details-box" v-if="project.inquiry && detailsVisible">
						<h4>Érdeklődés</h4>
						<div v-if="project.inquiry">
							<i class="far fa-hashtag"></i> Azonosító:
							<strong>{{ project.inquiry.public_id }}</strong>
						</div>
						<div v-if="project.inquiry">
							<i class="far fa-calendar-alt"></i> Időpont: <strong><date-time :value="project.inquiry.created_at"/></strong>
						</div>

						<user-attribute :user-id="project.inquiry.handler_user_id" v-if="project.inquiry && project.inquiry.handler_user_id" handler />
					</div>
				</div>
			</div>
		</template>

		<template #default="{activeTab, errors, editable, activeTabParameter, activeTabCommand}">
			<div :class="{'d-none': activeTab !== 'attributes'}">
				<ProjectFormAttributes
					v-model="project"
					:editable="false"
					user-type="user"
					ref="projectAttributes"
				></ProjectFormAttributes>
			</div>
			<div v-if="activeTab === 'documents'">
				<ProjectFormDocuments
					:editable="false"
					:project="project"
					ref="documents"
					:active="activeTab === 'documents'"
					v-if="project"
					:installer="true"
					@updateBadgeCount="updateBadgeCount"
				></ProjectFormDocuments>
			</div>
			<div v-if="activeTab === 'comments'">
				<Comments
					:data-url="route('installations.comments', installation.id)"
					:can-create="editable"
					@comment-count="commentCountUpdated"
					:active-tab-command="projectDataStore.activeTabCommand"
					:active-tab-parameter="projectDataStore.activeTabParameter"
				></Comments>
			</div>
			<div v-if="activeTab === 'installations'">
				<div class="card">
					<div class="card-body">
						<ProjectInstallationList
							:list-url="'/projects/projectInstallations/' + projectDataStore.projectId"
							:editable="editable && installation.editable"
							:can-start-editing="canStartEditing"
							@start-editing="startEditing"
							:installation-document-types="installationDocumentTypes"
						></ProjectInstallationList>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import EmailAddress from "../components/EmailAddress.vue";
import LockMessage from "../components/LockMessage.vue";
import FormWrapper from "../components/FormWrapper.vue";
import ProjectFormDocuments from "../modules/projects/ProjectFormDocuments.vue";
import ProjectFormAttributes from "../modules/projects/ProjectFormAttributes.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import Comments from "../components/Comments.vue";
import ProjectInstallationList from "../modules/projects/ProjectInstallationList.vue";
import TitleBar from "../components/TitleBar.vue";
import {useFlashStoreStore} from "../stores/flashStore";
import DateTime from "../components/DateTime.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {useProjectDataStore} from "../stores/projectData";
import ColorBadge from "../components/ColorBadge.vue";
import {useApplicationStore} from "../stores/applicationStore";
import UserAttribute from "../components/attributes/UserAttribute.vue";
import DateTimeAttribute from "../components/attributes/DateTimeAttribute.vue";
import ContractIdAttribute from "../components/attributes/ContractIdAttribute.vue";
import Attribute from "../components/Attribute.vue";
import ClientBadge from "../components/badges/ClientBadge.vue";
import InquiryAttribute from "../components/attributes/InquiryAttribute.vue";
import ClientAttribute from "../components/attributes/ClientAttribute.vue";

export default {
	layout: AppLayout,
	components: {
		ClientAttribute,
		InquiryAttribute,
		ClientBadge, Attribute,
		ContractIdAttribute, DateTimeAttribute,
		UserAttribute,
		ColorBadge,
		DateTime,
		TitleBar,
		ProjectInstallationList,
		Comments,
		UserBadge,
		PhoneNumber,
		ProjectFormAttributes,
		ProjectFormDocuments,
		FormWrapper,
		LockMessage,
		EmailAddress
	},
	props: {
		installation: Object,
		initialProject: Object,
		initialStats: Object,
		installationDocumentTypes: Array,
	},
	data(){
		return {
			project: this.initialProject,
			projectDataStore: useProjectDataStore(),
			app: useApplicationStore(),
			client: this.initialProject.client !== undefined ? this.initialProject.client : null,
			projectType: this.initialProject.project_type !== undefined ? this.initialProject.project_type : null,
			uploadedFiles: {},
			fields: [],
			alertTimeout: null,
			installationAttributes: this.installation,
			unsavedChanged: false,
			handlerUser: this.initialProject.handler_user_id,
			unloadHandler: null,
			detailsVisible: false,
			tabBadges: this.initialStats,
			flashStore: useFlashStoreStore(),
			lock: {
				updated: false,
				locked: false,
				lockedByCurrentUser: false,
				lockedByUserName: '',
				lockedByClientName: '',
				lockedAt: false,
			},
			tabs: [
				{
					name: 'attributes',
					label: 'Adatlap',
					icon: 'far fa-file-spreadsheet'
				},
				{
					name: 'comments',
					label: 'Üzenőfal',
					icon: 'far fa-comments'
				},
				{
					name: 'documents',
					label: 'Dokumentumok',
					icon: 'far fa-folder-open'
				},
				{
					name: 'installations',
					label: 'Telepítések',
					icon: 'far fa-tools'
				},
			]
		}
	},
	created: function () {
		this.projectDataStore.projectId = this.project.id
		this.projectDataStore.setData(this.project)
	},
	beforeUnmount() {
		this.projectDataStore.clearData()
	},
	computed: {
		clientAddress: {
			get() {
				for (let i = 0; i < this.projectType.fields_data.length; i++){
					if (this.projectType.fields_data[i].type === 'address'){
						if (this.project.attribute_values[this.projectType.fields_data[i].id] !== undefined){
							return this.project.attribute_values[this.projectType.fields_data[i].id].formatted_value
						}
					}
				}
				return ''
			}
		},
		editable: {
			get() {
				return this.projectDataStore.lock.locked && this.projectDataStore.lock.lockedByCurrentUser
			}
		},
		canStartEditing: {
			get() {
				return !this.projectDataStore.lock.locked && this.installation.editable
			}
		}
	},
	methods: {
		search: function(loading, search, vm) {
			fetch(
				route('installations.formEdit', this.installation.id) + '?action=clientSearch&q=' + search
			).then(res => {
				res.json().then(json => (vm.clientOptions = json.results));
				loading(false);
			});
		},
		saveForm: function(e){
			let payload = {
			}
			if (this.$refs.documents !== undefined && this.$refs.documents.loaded){
				payload.uploadedFiles = this.$refs.documents.uploadedFiles
				payload.documents = this.dataStore.documents
			}

			axios.post(route('installations.formEdit', this.installation.id) + '?action=saveProject', payload).then((response)=>{
				this.handleResponse(response)
				this.$refs.formWrapper.lockResponse(response)
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		handleResponse: function (response){
			if (response.data.redirect !== undefined){
				location.href = response.data.redirect
			}

			if (response.data.flash !== undefined){
				this.flashStore.addFlash(response.data.flash)
			}

			this.$refs.projectAttributes.updateVisibleFields()
		},
		commentCountUpdated: function(count){
			this.tabBadges.comments = count
		},
		updateStats: function (stats){
			this.tabBadges = stats
		},
		startEditing: function (){
			this.$refs.formWrapper.startEditing()
		},
		lockAcquired: function(lock){
			this.lock = lock
		},
		updateBadgeCount: function (tab, count){
			this.tabBadges[tab] = count
		},
	}
}
</script>
