<script setup>
import ColorBadge from "../ColorBadge.vue";
import {onMounted, ref, useSlots, useTemplateRef} from "vue";
import ProjectOverviewTooltip from "../../modules/projects/ProjectOverviewTooltip.vue";
import Popper from "vue3-popper";
import ProjectTypeCell from "../../modules/projects/ProjectTypeCell.vue";
import DataLoader from "../DataLoader.vue";

const props = defineProps({
	projectId: Number,
	contractId: Number,
	quoteId: Number,
	projectTypeId: Number,
	icon: {
		type: String,
		default: 'far fa-hashtag'
	},
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
	disableLink: Boolean
})
const slots = useSlots()
const tooltip = useTemplateRef('tooltip')

const open = ref(false)
const defaultContent = ref('')

onMounted(() => {
	if (!slots.default || !slots.default()){
		open.value = true
	}
})

function tooltipLoaded(details){
	defaultContent.value = details.public_id + ' - ' + details.client
}
</script>

<template>
	<template v-if="disableLink">
		<span :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}">
			<i :class="{'far': 1, 'fa-project-graph': !contractId && !quoteId, 'fa-file-contract': contractId, 'fa-file-invoice-dollar': quoteId}"></i> <slot name="default">{{ defaultContent }}</slot>
		</span>
		<span class="d-none">
			<ProjectOverviewTooltip ref="tooltip" :project-id="projectId" :contract-id="contractId" :quote-id="quoteId" v-if="open" @loaded="tooltipLoaded"></ProjectOverviewTooltip>
		</span>
	</template>
	<VDropdown
		v-else
		@show="open = true"
		@hide="open = false">
		<a :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-dark': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" href="#">
			<i :class="icon"></i> <slot name="default">{{ defaultContent }}</slot> <i class="far fa-info-circle font-size-10"></i>
			<data-loader :data="['projectTypes']" v-slot="{ dataStore }" hide-loader v-if="projectTypeId">
				<color-badge
					:luminosity="dataStore.getData('projectTypes', true)[projectTypeId].luminosity"
					:color="dataStore.getData('projectTypes', true)[projectTypeId].color"
					:icon="dataStore.getData('projectTypes', true)[projectTypeId].icon"
					:small="small"
				></color-badge>
			</data-loader>
		</a>
		<template #popper>
			<ProjectOverviewTooltip ref="tooltip" :project-id="projectId" :contract-id="contractId" :quote-id="quoteId" v-if="open" @loaded="tooltipLoaded"></ProjectOverviewTooltip>
		</template>
	</VDropdown>
</template>

<style scoped>

</style>
