<template>
	<TitleBar title="Ügyfél meghívása" />
	<form-wrapper
		v-model="client"
		:back-url="route('clients.index')"
		:store-url="route('clients.invite')"
		save-button-label="Küldés"
		save-button-icon="far fa-envelope"
	>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<radio-field
								label="Típus"
								v-model="client.is_company"
								:values="typeValues"
							/>
						</div>
						<div class="col-sm-6">
							<data-loader :data="['languages']" v-slot="{ dataStore }">
								<select-field
									name="language_id"
									label="Nyelv"
									:options="dataStore.getData('languages')"
									v-model="client.language_id"
									rules="required"
									:errors="errors"
								/>
							</data-loader>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Vezetéknév"
								name="last_name"
								rules="required"
								v-model="client.last_name"
								:errors="errors"
							/>
							<input-field
								label="E-mail cím"
								name="email"
								data-type="email"
								rules="required"
								v-model="client.email"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								label="Keresztnév"
								name="first_name"
								rules="required"
								v-model="client.first_name"
								:errors="errors"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import FormWrapper from "../components/FormWrapper.vue";
import SelectField from "../components/form/SelectField.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import RadioField from "../components/form/RadioField.vue";
import AppLayout from "../layouts/AppLayout.vue";

export default {
	name: "ClientInvite",
	components: {RadioField, DataLoader, InputField, SelectField, FormWrapper, TitleBar},
	layout: AppLayout,
	data() {
		return {
			client: {
				is_company: 0,
				editable: 1
			},
			typeValues: [
				{
					label: 'Magánszemély',
					icon: 'far fa-user'
				},
				{
					label: 'Cég',
					icon: 'far fa-industry'
				},
			]
		}
	},
}
</script>

<style scoped>

</style>
