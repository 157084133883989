<template>
	<div :class="{
			'mb-3': !fieldOnly && !(inline || inlineReversed) && !noMargin,
	}">
		<div :class="{
			'd-flex': (inline || inlineReversed),
			'gap-2': (inline || inlineReversed),
			'align-items-center': (inline || inlineReversed),
			'bg-secondary': (inline || inlineReversed) && coloredBackground,
			'bg-soft': (inline || inlineReversed) && coloredBackground,
			'rounded': (inline || inlineReversed) && coloredBackground,
			'px-3': (inline || inlineReversed) && coloredBackground,
			'py-1': (inline || inlineReversed) && coloredBackground,
			'switch-invalid': errors[name]
		}">
			<label v-if="(inline || inlineReversed) && offLabel !== ''" :class="{'mb-0': inline || inlineReversed}">{{ offLabel }}</label>
			<label v-else-if="!fieldOnly && !inlineReversed" :class="{'mb-0': inline}">{{ label }}</label>
			<div :class="{'switch-wrapper': 1, 'switch-wrapper-sm': small}" v-if="name !== undefined">
				<Field
					v-model="componentValue"
					:name="name"
					:rules="rulesValue"
					v-slot="{ field }"
					:class="{'form-control': 1}"
				>
					<input
						type="checkbox"
						:id="componentId"
						:name="name"
						switch="bool"
						v-model="componentValue"
						value="1"
						:readonly="disabled"
						:disabled="disabled"
					>
					<label :for="componentId" :class="{disabled: disabled}" data-on-label="" data-off-label=""></label>
				</Field>
			</div>
			<label v-if="(inline || inlineReversed) && onLabel !== ''" :class="{'mb-0': inline || inlineReversed}">{{ onLabel }}</label>
			<label v-else-if="!fieldOnly && inlineReversed" class="mb-0">{{ label }}</label>
			<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
		</div>
		<p class="text-muted text-info mt-2" v-if="info" v-html="info"></p>
	</div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from "yup";

export default {
	emits: ['update:modelValue', 'input'],
	components: {Field, ErrorMessage},
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		inline: {
			type: Boolean,
			default: false
		},
		inlineReversed: Boolean,
		label: String,
		onLabel: {
			type: String,
			default: ''
		},
		offLabel: {
			type: String,
			default: ''
		},
		name: String,
		modelValue: Number|Boolean,
		noMargin: Boolean,
		info: {
			type: String,
			default: ''
		},
		fieldOnly: Boolean,
		invert: Boolean,
		coloredBackground: Boolean,
		errors: {
			type: Object,
			default: function(){
				return {}
			}
		},
		rules: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			rulesValue: null,
			isRequired: false
		}
	},
	created() {
		this.parseRules()
	},
	computed: {
		componentValue: {
			get() {
				return this.invert ? !this.modelValue : !!this.modelValue
			},
			set(value) {
				if (this.invert){
					value = value === true ? 0 : 1
				} else {
					value = value === true ? 1 : 0
				}
				this.$emit('update:modelValue', value)
				this.$emit('input')
			}
		},
		componentId: {
			get() {
				return this.name + '_checkbox'
			}
		},
	},
	methods: {
		parseRules: function() {
			let tmp = this.rules.split(',')
			this.isRequired = tmp.indexOf('required') > -1

			this.rulesValue = yup.boolean()
			if (this.isRequired){
				this.rulesValue = this.rulesValue.oneOf([true], () => {
					return this.$t(':field kitöltése kötelező', {field: this.label})
				})
			} else {
				this.rulesValue = this.rulesValue.nullable()
			}
			this.rulesValue = this.rulesValue.label(this.label)
		}
	}
}
</script>

<style scoped>

</style>
