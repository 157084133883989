<template>
	<data-loader :data="['warehouses']" v-slot="{ dataStore }">
		<select-field
			:label="label"
			:name="name"
			v-model="componentValue"
			:options="componentOptions"
			:errors="errors"
			:rules="rules"
			:info="info"
			:field-only="fieldOnly"
			:disabled="disabled"
		>
			<template v-slot:option="option">
				<WarehouseBadge :warehouse-id="option.option.id" disable-link />
			</template>
			<template v-slot:selected-option="option">
				<WarehouseBadge :warehouse-id="option.option.id" disable-link />
			</template>
		</select-field>
	</data-loader>
</template>

<script>
import SelectField from "./SelectField.vue";
import vSelect from "vue-select";
import UserLevelBadge from "../UserLevelBadge.vue";
import DataLoader from "../DataLoader.vue";
import ColorBadge from "../ColorBadge.vue";
import {useGeneralDataStore} from "../../stores/generalData";
import WarehouseBadge from "../badges/WarehouseBadge.vue";

export default {
	emits: ['update:modelValue'],
	components: {WarehouseBadge, ColorBadge, DataLoader, UserLevelBadge, vSelect, SelectField},
	props: {
		modelValue: Number,
		errors: Object,
		name: String,
		label: String,
		fieldOnly: Boolean,
		disabled: Boolean,
		excludedId: Number,
		showNormal: {
			type: Boolean,
			default: true
		},
		showMobile: {
			type: Boolean,
			default: true
		},
		requireAddress: Boolean,
		info: String,
		rules: {
			type: String,
			default: ''
		},
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		componentOptions: {
			get(){
				let payload = []
				_.forEach(this.dataStore.getData('warehouses', false, this.excludedId), (item) => {
					if (this.requireAddress && !item.address){
						return
					}
					if ((item.mobile && this.showMobile) || (!item.mobile && this.showNormal)){
						payload.push(item)
					}
				})

				return payload
			}
		}
	},
	mounted() {
	},
	data(){
		return {
			options: [],
			dataStore: useGeneralDataStore()
		}
	},
	methods: {
	}
}
</script>

<style scoped>

</style>
