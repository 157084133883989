<template>
	<TitleBar title="Hírek" />
	<div class="d-flex flex-wrap gap-2 mb-4">
		<Link :href="route('posts.index')" class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza a hírekhez</Link>
	</div>
	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-3">{{ post.title }}</h4>
			<div class="d-flex mb-4 gap-2">
				<div><i class="far fa-clock"></i> <date-time :value="post.created_at"/></div>
				<div><i class="far fa-user"></i> <user-badge :user-id="post.created_by_user_id" /></div>
			</div>
			<div class="lead mb-4" v-if="post.lead" v-html="post.lead_html"></div>
			<div class="mb-4" v-html="post.content"></div>

			<div class="row" v-if="post.images.length">
				<div class="col-md-4" v-for="image in post.images">
					<a :href="image.download_url">
						<img :src="image.download_url" class="img-fluid img-thumbnail" />
					</a>
				</div>
			</div>
		</div>
	</div>

	<div class="card" v-if="post.documents.length">
		<div class="card-body">
			<h4 class="card-title mb-3">Kapcsolódó dokumentumok</h4>
			<p v-for="document in post.documents">
				<a :href="document.download_url"><i class="far fa-download"></i> {{ document.name }}</a>
			</p>
		</div>
	</div>
</template>

<script>
import {Link} from "@inertiajs/vue3";
import UserBadge from "../components/badges/UserBadge.vue";
import TitleBar from "../components/TitleBar.vue";
import AppLayout from "../layouts/AppLayout.vue";
import DateTime from "../components/DateTime.vue";
export default {
	components: {DateTime, TitleBar, UserBadge, Link },
	layout: AppLayout,
	props: {
		post: Object
	}
}
</script>

<style scoped>

</style>
