<template>
	<TitleBar :title="attributes.id !== undefined ? 'Beszállító módosítása' : 'Beszállító hozzáadása'" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('suppliers.store', attributes.id)"
		:back-url="route('suppliers.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>

		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<switch-field
						label="Aktív"
						name="active"
						v-model="attributes.active"
						:disabled="!attributes.editable"
					/>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<phone-field
								label="Telefonszám"
								name="phone"
								v-model="attributes.phone"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								label="E-mail cím"
								name="email"
								rules="email"
								v-model="attributes.email"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Weboldal"
								name="url"
								rules=""
								v-model="attributes.url"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
						</div>
					</div>
					<textarea-field
						label="Leírás"
						name="description"
						v-model="attributes.description"
						:disabled="!attributes.editable"
						:errors="errors"
					></textarea-field>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Kapcsolat</h4>
					<table class="table">
						<thead>
						<tr>
							<th>Név</th>
							<th>Beosztás</th>
							<th>Telefon</th>
							<th>E-mail</th>
							<th width="1%">&nbsp;</th>
						</tr>
						</thead>
						<tbody>
						<template v-for="(contact, contactKey) in attributes.contact">
							<tr>
								<td><input type="text" class="form-control" v-model="contact.name" /></td>
								<td><input type="text" class="form-control" v-model="contact.position" /></td>
								<td><input type="text" class="form-control" v-model="contact.phone" /></td>
								<td><input type="text" class="form-control" v-model="contact.email" /></td>
								<td><button type="button" class="btn btn-danger" @click.prevent="removeContact(contactKey)"><i class="far fa-times"></i></button></td>
							</tr>
						</template>
						</tbody>
					</table>

					<div class="mt-3">
						<button type="button" class="btn btn-secondary btn-label" @click.prevent="addContact">
							<i class="far fa-plus label-icon"></i> Hozzáad
						</button>
					</div>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Dokumentumok</h4>
					<FileUploader
						field-name="document"
						v-model="attributes.documents"
						file-type="document"
						:editable="attributes.editable"
					></FileUploader>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import FormWrapper from "../components/FormWrapper.vue";
import TextareaField from "../components/form/TextareaField.vue";
import InputField from "../components/form/InputField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import FileUploader from "../components/FileUploader.vue";
import TitleBar from "../components/TitleBar.vue";
import PhoneField from "../components/form/PhoneField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";

export default {
	components: {
		UpdatedAttribute,
		PhoneField, TitleBar, FileUploader, SwitchField, InputField, TextareaField, FormWrapper},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		let attributes = _.clone(this.model)
		if (attributes.contact === undefined || attributes.contact === null){
			attributes.contact = []
		}
		return {
			attributes: attributes,
			loading: false,
			flash: []
		}
	},
	methods: {
		addContact: function(){
			let maxOrder = 0

			this.attributes.contact.forEach(function(item, index){
				if (item.order > maxOrder){
					maxOrder = item.order
				}
			})
			this.attributes.contact.push({
				order: maxOrder + 1,
			})
		},
		removeContact: function(index){
			this.attributes.contact.splice(index, 1);
		},
	}
}
</script>
