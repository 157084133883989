<template>
	<select-field
			:label="label"
			:name="name"
			v-model="componentValue"
			:get-option-label="formatLabel"
			:options="options"
			:errors="errors"
			:filter-options="filterOptions"
			:rules="rules"
			:search="search"
			:field-only="fieldOnly"
			:display-search-info-bottom="true"
	>

		<template v-slot:option="option">
			<div>{{ option.option.name }}</div>

			<div class="font-size-11 d-flex align-items-center gap-2">
				<i class="far fa-tag ms-1" v-if="option.option.track_serial_number"></i>
				<div>({{ option.option.sku }})</div>
				<div class="fst-italic">{{ option.option.categoryName }}</div>
				<strong v-if="option.option.manufacturer">{{ option.option.manufacturer }}</strong>
			</div>
		</template>
		<template v-slot:selected-option="option">
			({{ option.option.sku }}) {{ option.option.name }}
		</template>
	</select-field>
</template>

<script>
import SelectField from "./SelectField.vue";
import vSelect from "vue-select";
import UserLevelBadge from "../UserLevelBadge.vue";

export default {
	emits: ['update:modelValue'],
	components: {UserLevelBadge, vSelect, SelectField},
	props: {
		modelValue: Number,
		errors: Object,
		userLevels: Array,
		name: String,
		label: String,
		typeFilter: {
			type: Array,
			default: function (){
				return []
			}
		},
		fieldOnly: Boolean,
		excluded: {
			type: Array,
			default: function (){
				return []
			}
		},
		rules: {
			type: String,
			default: ''
		},
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	mounted() {
		this.search(null, '')
	},
	data(){
		return {
			options: []
		}
	},
	methods: {
		search: function (loading, search){
			let url = '/products/search?q=' + search
			if (this.typeFilter){
				url += '&type=' + this.typeFilter.join('|')
			}
			if (this.componentValue){
				url += '&id=' + this.componentValue
			}
			if (this.excluded.length){
				url += '&exclude=' + this.excluded.join('|')
			}

			fetch(
				url
			).then(res => {
				res.json().then(json => (this.options = json.results));
				if (loading !== null) {
					loading(false)
				}
			});
		},
		formatLabel: function(item){
			return item.name + ' (' + item.clientName + ' / ' + item.address + ')'
		},
		filterOptions: function(options, search){
			return options
		}
	}
}
</script>

<style scoped>

</style>
