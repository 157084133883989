<template>
	<div class="dashboard-wrapper">
		<template
			v-if="dashboardMode === 'calendar'">
			<div class="dashboard-title">
				<TitleBar title="Vezérlőpult" />
				<div :class="{'timeline-settings': 1, 'timeline-settings-wide': isFullWidth}">
					<div class="dropdown" v-if="app.hasPermission('ProjectsController_updateAll')">
						<button type="button" class="btn btn-light btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
							<template v-if="userId !== null && userId !== 'all'">
								<i class="far fa-portal-exit"></i> <span class="d-none d-md-inline"><user-badge :user-id="userId" hide-link hide-level /></span>
							</template>
							<template v-else-if="userId === 'all'">
								<i class="far fa-globe-americas fa-fw"></i> <span class="d-none d-md-inline">Globális</span>
							</template>
							<template v-else>
								<i class="far fa-user fa-fw"></i> <span class="d-none d-md-inline">Saját</span>
							</template>
							<i class="far fa-angle-down d-none d-md-inline-block"></i>
						</button>
						<div class="dropdown-menu dropdown-menu-end" role="menu">
							<a class="dropdown-item" href="#" @click.prevent="userId = null">
								<i class="far fa-user fa-fw"></i> Saját vezérlőpult
							</a>
							<a class="dropdown-item" href="#" @click.prevent="userId = null; showUserSelect = true">
								<i class="far fa-portal-enter fa-fw"></i> Felhasználó vezérlőpultja
							</a>
							<a class="dropdown-item" href="#" @click.prevent="userId = 'all'">
								<i class="far fa-globe-americas fa-fw"></i> Globális vezérlőpult
							</a>
						</div>
					</div>
					<div class="dropdown">
						<button type="button" class="btn btn-light btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="far fa-calendar-alt fa-fw"></i> <span class="d-none d-md-inline">Naptár</span>
							<i class="far fa-angle-down d-none d-md-inline-block"></i>
						</button>
						<div class="dropdown-menu dropdown-menu-end" role="menu">
							<a class="dropdown-item" href="#" @click.prevent="dashboardMode = 'timeline'">
								<i class="far fa-stream fa-fw"></i> Hírfolyam
							</a>
							<a class="dropdown-item" href="#" @click.prevent="dashboardMode = 'calendar'">
								<i class="far fa-calendar-alt fa-fw"></i> Naptár
							</a>
							<a class="dropdown-item" href="#" @click.prevent="dashboardMode = 'tasks'" v-if="app.isMobile">
								<i class="far fa-list fa-fw"></i> Feladatok
							</a>
							<a class="dropdown-item" href="#" @click.prevent="dashboardMode = 'contracts'" v-if="app.isMobile">
								<i class="far fa-file-contract fa-fw"></i> Szerződések
							</a>
						</div>
					</div>
				</div>
			</div>
			<dashboard-calendar
				:show-projects="!!calendarShowProjects"
				:project-type-milestones="projectTypeMilestones"
				:project-event-types="projectEventTypes"
				:project-types="projectTypes"
				:user-id="userId"
				:available-types="calendarAvailableTypes"
			/>
		</template>
		<div class="row m-0 dashboard-grid" v-else>
			<div :class="{'col-md-8': !isFullWidth, 'col-md-12': isFullWidth, 'px-0': 1}">
				<div class="dashboard-title">
					<TitleBar title="Vezérlőpult" />
					<div :class="{'timeline-settings': 1, 'timeline-settings-wide': isFullWidth}">
						<switch-field
							name="calendarShowProjects"
							v-model="calendarShowProjects"
							label="Minden"
							inline-reversed
							field-only
							on-label="Összes"
							off-label="Feladatok"
							v-if="false && dashboardMode === 'calendar'"
							small
							/>
						<div class="dropdown" v-if="dashboardMode === 'timeline'">
							<button type="button" class="btn btn-light btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
								<template v-if="timelineShowAll">
									<i class="fas fa-eye fa-fw"></i> <span class="d-none d-md-inline">Összes</span>
								</template>
								<template v-else>
									<i class="far fa-eye fa-fw"></i> <span class="d-none d-md-inline">Olvasatlan</span>
								</template>
								<i class="far fa-angle-down d-none d-md-inline-block"></i>
							</button>
							<div class="dropdown-menu dropdown-menu-end" role="menu">
								<a class="dropdown-item" href="#" @click.prevent="timelineShowAll = true">
									<i class="fas fa-eye fa-fw"></i> Összes
								</a>
								<a class="dropdown-item" href="#" @click.prevent="timelineShowAll = false">
									<i class="far fa-eye fa-fw"></i> Olvasatlan
								</a>
							</div>
						</div>
						<div class="dropdown" v-if="app.hasPermission('ProjectsController_updateAll')">
							<button type="button" class="btn btn-light btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
								<template v-if="userId !== null && userId !== 'all'">
									<i class="far fa-portal-exit"></i> <span class="d-none d-md-inline"><user-badge :user-id="userId" hide-link hide-level /></span>
								</template>
								<template v-else-if="userId === 'all'">
									<i class="far fa-globe-americas fa-fw"></i> <span class="d-none d-md-inline">Globális</span>
								</template>
								<template v-else>
									<i class="far fa-user fa-fw"></i> <span class="d-none d-md-inline">Saját</span>
								</template>
								<i class="far fa-angle-down d-none d-md-inline-block"></i>
							</button>
							<div class="dropdown-menu dropdown-menu-end" role="menu">
								<a class="dropdown-item" href="#" @click.prevent="userId = null">
									<i class="far fa-user fa-fw"></i> Saját vezérlőpult
								</a>
								<a class="dropdown-item" href="#" @click.prevent="userId = null; showUserSelect = true">
									<i class="far fa-portal-enter fa-fw"></i> Felhasználó vezérlőpultja
								</a>
								<a class="dropdown-item" href="#" @click.prevent="userId = 'all'">
									<i class="far fa-globe-americas fa-fw"></i> Globális vezérlőpult
								</a>
							</div>
						</div>
						<div class="dropdown">
							<button type="button" class="btn btn-light btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
								<template v-if="dashboardMode === 'tasks'">
									<i class="far fa-list fa-fw"></i> <span class="d-none d-md-inline">Feladatok</span>
								</template>
								<template v-else-if="dashboardMode === 'contracts'">
									<i class="far fa-file-contract fa-fw"></i> <span class="d-none d-md-inline">Szerződések</span>
								</template>
								<template v-else>
									<i class="far fa-stream fa-fw"></i> <span class="d-none d-md-inline">Hírfolyam</span>
								</template>
								<i class="far fa-angle-down d-none d-md-inline-block"></i>
							</button>
							<div class="dropdown-menu dropdown-menu-end" role="menu">
								<a class="dropdown-item" href="#" @click.prevent="dashboardMode = 'timeline'">
									<i class="far fa-stream fa-fw"></i> Hírfolyam
								</a>
								<a class="dropdown-item" href="#" @click.prevent="dashboardMode = 'calendar'">
									<i class="far fa-calendar-alt fa-fw"></i> Naptár
								</a>
								<a class="dropdown-item" href="#" @click.prevent="dashboardMode = 'tasks'" v-if="app.isMobile">
									<i class="far fa-list fa-fw"></i> Feladatok
								</a>
								<a class="dropdown-item" href="#" @click.prevent="dashboardMode = 'contracts'" v-if="app.isMobile">
									<i class="far fa-file-contract fa-fw"></i> Szerződések
								</a>
							</div>
						</div>
					</div>
				</div>

				<timeline
					v-if="dashboardMode === 'timeline'"
					:project-event-types="projectEventTypes"
					:show-all="timelineShowAll"
					:user-id="userId"
				/>


				<dashboard-tasks
					v-if="dashboardMode === 'tasks' && app.isMobile"
				/>

				<dashboard-contract-list
					v-if="dashboardMode === 'contracts' && app.isMobile"
				/>
			</div>
			<div class="col-md-4 p-0 bg-white border-top" v-if="!app.isMobile && !isFullWidth">
				<ul class="nav nav-pills nav-justified mb-0" role="tablist">
					<li class="nav-item" role="presentation">
						<a :class="{'nav-link': 1, 'active': sidebarActiveTab === 'events'}"
						   href="#"
						   role="tab"
						   @click.prevent="sidebarActiveTab = 'events'"
						   :aria-selected="sidebarActiveTab === 'events' ? 'true' : 'false'">
							<div class="icon">
								<i class="far fa-list"></i>
							</div>
							<div class="label">Feladatok</div>
						</a>
					</li>
					<li class="nav-item" role="presentation">
						<a :class="{'nav-link': 1, 'active': sidebarActiveTab === 'contracts'}"
						   href="#"
						   role="tab"
						   @click.prevent="sidebarActiveTab = 'contracts'"
						   :aria-selected="sidebarActiveTab === 'contracts' ? 'true' : 'false'">
							<div class="icon">
								<i class="far fa-file-contract"></i>
							</div>
							<div class="label">Szerződések</div>
						</a>
					</li>
				</ul>

				<dashboard-tasks
					v-if="sidebarActiveTab === 'events'"
				/>

				<dashboard-contract-list
					v-if="sidebarActiveTab === 'contracts'"
					/>
			</div>
		</div>
	</div>
	<modal
		title="Felhasználó kiválasztása"
		@close="showUserSelect = false"
		v-if="showUserSelect"
		:closable="true"
		>

		<user-select-field
			label="Felhasználó"
			v-model="userId"
			name="userId"
			info="Válaszd ki, melyik felhasználó hírfolyamát szeretnéd megnézni"
			/>
	</modal>
</template>
<script>
import DashboardNotifications from "../modules/dashboard/DashboardNotifications.vue";
import DashboardTasks from "../modules/dashboard/DashboardTasks.vue";
import DashboardPosts from "../modules/dashboard/DashboardPosts.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {Head, Link} from "@inertiajs/vue3";
import TitleBar from "../components/TitleBar.vue";
import Attribute from "../components/Attribute.vue";
import DateTime from "../components/DateTime.vue";
import Timeline from "../modules/dashboard/Timeline.vue";
import FileSize from "../components/FileSize.vue";
import {useApplicationStore} from "../stores/applicationStore";
import Modal from "../components/Modal.vue";
import UserSelectField from "../components/form/UserSelectField.vue";
import DataLoader from "../components/DataLoader.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import SwitchField from "../components/form/SwitchField.vue";
import {usePermanentStore} from "../stores/permanentStore";
import DashboardContractList from "../modules/dashboard/DashboardContractList.vue";
import DashboardCalendar from "../modules/dashboard/DashboardCalendar.vue";

export default {
	components: {
		DashboardCalendar,
		DashboardContractList,
		SwitchField,
		UserBadge,
		DataLoader,
		UserSelectField,
		Modal,
		FileSize,
		Timeline,
		DateTime,
		Attribute, TitleBar, AppLayout, DashboardPosts, DashboardNotifications, DashboardTasks, Head, Link},
	layout: AppLayout,
	data(){
		const store = usePermanentStore()
		return {
			timelineShowAll: store.get('timelineShowAll', false),
			calendarShowProjects: store.get('calendarShowProjects', false),
			userId: store.get('dashboardUserId', null),
			app: useApplicationStore(),
			sidebarActiveTab: 'events',
			store: store,
			showUserSelect: false,
			dashboardMode: store.get('dashboardMode', 'timeline'),
		}
	},
	mounted() {
		this.disableWrapperScroll()
	},
	beforeUnmount() {
		document.getElementsByClassName('main-content')[0].classList.remove('disable-scroll')
	},
	computed: {
		isFullWidth: {
			get(){
				return this.dashboardMode === 'calendar'
			}
		}
	},
	watch: {
		calendarShowProjects(oldv, newv){
			this.store.set('calendarShowProjects', this.calendarShowProjects)
		},
		userId(oldv, newv){
			this.store.set('dashboardUserId', this.userId)
		},
		dashboardMode(oldv, newv){
			this.store.set('dashboardMode', this.dashboardMode)
			this.disableWrapperScroll()
		},
	},
	props: {
		projectTypeMilestones: Object,
		projectTypes: Object,
		projectEventTypes: Object,
		calendarAvailableTypes: Array,
	},
	methods: {
		disableWrapperScroll: function (){
			if (this.dashboardMode === 'calendar'){
				document.getElementsByClassName('main-content')[0].classList.remove('disable-scroll')
			} else {
				document.getElementsByClassName('main-content')[0].classList.add('disable-scroll')
			}
		},
		setMode: function (mode){
			this.timelineUserId = null
			this.dashboardMode = mode
			this.store.set('timelineMode', mode)
		}
	}
}
</script>
<style scoped>

</style>
