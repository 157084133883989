<template>
	<div :class="noMargin ? '' : 'mb-4'" v-if="visible && componentValue !== undefined">
		<div class="inactivity-overlay-bg" v-if="isInactive">
			<div class="inactivity-overlay" v-if="isInactive">
				<p>{{ $t('Túl sokáig voltál inaktív, ezért újra kell töltened az oldalt!') }}</p>
				<a href="#" class="btn btn-primary" @click.prevent="reloadPage">{{ $t('Oldal újratöltése') }}</a>
			</div>
		</div>
		<Form @submit="onSubmit" v-slot="{ errors }" ref="form" @input="valueChanged" enctype="multipart/form-data" v-if="isLoaded">
			<div class="sticky-form-buttons" v-if="showButtons && showTopButtons">
				<form-buttons
					:editable="!!componentValue.editable && !disableSubmit"
					:invalid="Object.keys(errors).length > 0"
					:back-url="backUrl"
					@back-pressed="backPressed"
					@edit-pressed="startEditing"
					:buttons="buttons"
					:lockable="lockable"
					:lock-acquired="lockAcquired"
					:save-button-label="saveButtonLabel"
					:save-button-icon="saveButtonIcon"
					:grouped="groupedButtons"
					:show-back-button="showBackButton"
					:show-submit-button="showSubmitButton"
					ref="topButtons"
				>
					<slot name="buttons"
						  :editable="componentValue.editable && (!lockable || (lockable && lockAcquired))"></slot>
				</form-buttons>
			</div>
			<div v-if="!showTopButtons && !showBottomButtons" class="d-none">
				<button type="submit" class="btn btn-primary btn-label" ref="saveButton" :disabled="Object.keys(errors).length > 0">
					<i class="fas fa-save label-icon"></i> Mentés
				</button>
			</div>

			<slot name="top"></slot>

			<template v-if="tabs.length && displayTabs">
				<div class="card tabs-card">
					<div class="card-body">
						<ul class="nav nav-pills nav-justified mb-0" role="tablist">
							<li class="nav-item" role="presentation" v-for="tab in tabs">
								<Link :class="{'nav-link': 1, 'active': activeTab === tab.name}"
									  preserve-state
									  replace
									  v-if="tabUrlBase"
								   :href="tabUrlBase + '/' + tab.name" role="tab"
								   :aria-selected="activeTab === tab.name ? 'true' : 'false'">
									<div class="icon">
										<i :class="tab.icon"></i>
										<span class="badge bg-primary ms-1 rounded-pill"
											  v-if="tabBadges[tab.name] !== undefined">{{ tabBadges[tab.name] }}</span>
									</div>
									<div class="label">{{ tab.label }}</div>
								</Link>
								<a :class="{'nav-link': 1, 'active': activeTab === tab.name}"
									  v-else
								   href="#" role="tab"
								   @click.prevent="setActiveTab(tab.name)"
								   :aria-selected="activeTab === tab.name ? 'true' : 'false'">
									<div class="icon">
										<i :class="tab.icon"></i>
										<span class="badge bg-primary ms-1 rounded-pill"
											  v-if="tabBadges[tab.name] !== undefined">{{ tabBadges[tab.name] }}</span>
									</div>
									<div class="label">{{ tab.label }}</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</template>

			<slot
				:active-tab="activeTab"
				:errors="errors"
				:editable="componentValue.editable && (!lockable || (lockable && lockAcquired))"
				:active-tab-parameter="activeTabParameter"
				:active-tab-command="activeTabCommand"
				:lock-status="lockStatus"
				:data-store="dataStore"
			></slot>

			<div class="mt-4" v-if="showButtons && showBottomButtons">
				<form-buttons
					:editable="!!componentValue.editable && !disableSubmit"
					:invalid="Object.keys(errors).length > 0"
					:back-url="backUrl"
					@back-pressed="backPressed"
					@edit-pressed="startEditing"
					:buttons="buttons"
					:lockable="lockable"
					:lock-acquired="lockAcquired"
					:save-button-label="saveButtonLabel"
					:save-button-icon="saveButtonIcon"
					:show-submit-button="showSubmitButton"
					:grouped="groupedButtons"
					:show-back-button="showBackButton"
				>
					<slot name="buttons"
						  :editable="componentValue.editable && (!lockable || (lockable && lockAcquired))"></slot>
				</form-buttons>
			</div>
		</Form>
	</div>
	<modal
		title="Navigáció"
		:full-width="false"
		:closable="true"
		v-if="displayUnsavedDataAlert"
	>
		<template #default>
			{{ $t('Az oldalon el nem mentett változások vannak. Biztos, hogy el akarod hagyni az oldalt?') }}
		</template>

		<template #buttons>
			<button type="button" class="btn btn-warning btn-label waves-effect" @click.prevent="navigateToIntendedDestination"><i class="far fa-check label-icon"></i> Igen</button>
			<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="displayUnsavedDataAlert = false"><i class="fas fa-times label-icon"></i> Nem</button>
		</template>
	</modal>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import yupLocaleHun from '../validationMessages'
import FormButtons from "./FormButtons.vue";
import {trans, isLoaded} from "laravel-vue-i18n";
import {useFlashStoreStore} from "../stores/flashStore";
import {Link, router, usePage} from '@inertiajs/vue3'
import {useGeneralDataStore} from "../stores/generalData";
import Modal from "./Modal.vue";
import DateField from "./form/DateField.vue";
import TextareaField from "./form/TextareaField.vue";
import {usePermanentStore} from "../stores/permanentStore";

yup.setLocale(yupLocaleHun);

export default {
	components: {TextareaField, DateField, Modal, Form, Field, ErrorMessage, FormButtons, Link},
	props: {
		storeUrl: String,
		backUrl: String,
		tableName: String,
		lockUrl: {
			type: String,
			default: ''
		},
		updateLockUrl: {
			type: String,
			default: ''
		},
		tabUrlBase: String,
		modelValue: Object,
		extraAttributes: Object,
		disableRedirect: {
			type: Boolean,
			default: false
		},
		disableSubmit: {
			type: Boolean,
			default: false
		},
		buttons: {
			type: Array,
			default: function () {
				return []
			}
		},
		hiddenAttributes: {
			type: Array,
			default: function () {
				return []
			}
		},
		onlyAttributes: {
			type: Array,
			default: function () {
				return []
			}
		},
		initialTab: String,
		initialTabParameters: String,
		showButtons: {
			type: Boolean,
			default: true
		},
		showTopButtons: {
			type: Boolean,
			default: true
		},
		showBottomButtons: {
			type: Boolean,
			default: false
		},
		submitFunction: {
			type: Function,
			default: null
		},
		tabs: {
			type: Array,
			default: function () {
				return []
			}
		},
		tabBadges: {
			type: Object,
			default: function () {
				return {}
			}
		},
		showBackButton: {
			type: Boolean,
			default: true
		},
		showSubmitButton: {
			type: Boolean,
			default: true
		},
		displayTabs: Boolean,
		saveInPlace: Boolean,
		errorEventEnabled: Boolean,
		saveButtonLabel: {
			type: String,
			default: ''
		},
		saveButtonIcon: {
			type: String,
			default: ''
		},
		groupedButtons: Boolean,
		noMargin: Boolean,
		store: Object,
		requiredData: {
			type: Array,
			default: function () {
				return []
			}
		}
	},
	created: function () {
		this.lockInterval = setInterval(this.updateLock, 30000);

		window.addEventListener('keydown', this.resetTimer)
		window.addEventListener('click', this.resetTimer)
		window.addEventListener('touchmove', this.resetTimer)
		window.addEventListener('touchstart', this.resetTimer)
		window.addEventListener('mousedown', this.resetTimer)
		window.addEventListener('mousemove', this.resetTimer)
		window.addEventListener('scroll', this.resetTimer)

		this.updateLock()

		this.routerSuccessListener = router.on('success', (event) => {
			if (event.detail.page !== undefined && event.detail.page.props !== undefined && event.detail.page.props.tab !== undefined && event.detail.page.props.tab !== null) {
				this.setActiveTab(
					event.detail.page.props.tab,
					event.detail.page.props.tabParameters !== undefined ? event.detail.page.props.tabParameters : null
				)
			}
		})

		if (this.initialTab !== undefined && this.initialTab !== null && this.initialTab) {
			this.setActiveTab(this.initialTab, this.initialTabParameters)
		}
	},
	beforeUnmount() {
		clearInterval(this.lockInterval)

		window.removeEventListener('keydown', this.resetTimer)
		window.removeEventListener('click', this.resetTimer)
		window.removeEventListener('touchmove', this.resetTimer)
		window.removeEventListener('touchstart', this.resetTimer)
		window.removeEventListener('mousedown', this.resetTimer)
		window.removeEventListener('mousemove', this.resetTimer)
		window.removeEventListener('scroll', this.resetTimer)

		this.routerSuccessListener()

		if (this.routerListener !== null){
			this.routerListener()
		}

		if (this.errorEventTimeout !== null){
			clearTimeout(this.errorEventTimeout)
		}
	},
	emits: ['update:modelValue', 'beforeSubmit', 'afterSubmit', 'backPressed', 'startEditing', 'attributesUpdated', 'lockAcquired', 'errorsChanged', 'dataLoaded'],
	data() {
		let activeTab = this.initialTab !== undefined && this.initialTab !== null && this.initialTab ? this.initialTab : ''
		if (this.tabs.length && activeTab === '') {
			activeTab = this.tabs[0].name
		}
		return {
			loading: false,
			routerListener: null,
			activeTab: activeTab,
			activeTabCommand: null,
			activeTabParameter: null,
			activityTimer: null,
			isInactive: false,
			unsavedChanged: false,
			flashStore: useFlashStoreStore(),
			dataStore: useGeneralDataStore(),
			lockAcquired: false,
			initialLockUpdate: true,
			lockStatus: {},
			lockInterval: null,
			visible: false,
			page: usePage(),
			previousErrors: {},
			errors: {},
			disableBeforeLeave: false,
			displayUnsavedDataAlert: false,
			intendedDestination: null,
			routerSuccessListener: null,
			errorEventTimeout: null
		}
	},
	mounted() {

		if (this.requiredData.length){
			this.dataStore.loadData(this.requiredData, () => {
				this.visible = true
				this.$emit('dataLoaded')
			})
		} else {
			this.visible = true
			this.$emit('dataLoaded')
		}

		//if (this.errorEventEnabled) {
			this.errorEventTimeout = setInterval(() => {
				if (this.$refs.form !== undefined && this.$refs.form !== null) {
					this.previousErrors = this.errors
					this.errors = this.$refs.form.getErrors()
					if (!_.isEqual(this.errors, this.previousErrors)) {
						this.$emit('errorsChanged', this.errors)
						if (!Object.keys(this.previousErrors).length && !this.tabs.length){
							const firstErrorFieldName = Object.keys(this.errors)[0];
							const el = document.querySelector(`[name="${firstErrorFieldName}"]`);
							if (el) {
								el.scrollIntoView({behavior: "smooth", block: 'center'});
							}
						}
					}
				}
			}, 200)
		//}

	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		lockable: {
			get() {
				return this.lockUrl !== '' && this.updateLockUrl !== '' && this.modelValue.id !== undefined && !!this.modelValue.id && (this.modelValue.deleted_at === undefined || !this.modelValue.deleted_at)
			}
		}
	},
	methods: {
		isLoaded,
		setActiveTab: function (tab, params) {
			params = params || ''
			this.activeTab = tab

			let tmp = params.split(':')

			this.activeTabCommand = null
			this.activeTabParameter = null
			if (tmp[0] !== undefined) {
				this.activeTabCommand = tmp[0]
				this.activeTabParameter = tmp[1] !== undefined ? tmp[1] : null
			}

			if (this.store !== undefined) {
				this.store.activeTab = this.activeTab
				this.store.activeTabCommand = this.activeTabCommand
				this.store.activeTabParameter = this.activeTabParameter
			}
		},
		resetTimer: function () {
			clearTimeout(this.activityTimer);

			this.activityTimer = setTimeout(() => {
				if (this.editable) {
					this.isInactive = true
				}
			}, 3600000);
		},
		updateLock: function () {
			if (this.componentValue !== undefined
				&& this.componentValue.id !== undefined
				&& this.componentValue.id
				&& this.lockable
				&& this.componentValue.editable
				&& (this.lockAcquired || this.initialLockUpdate)
				&& !this.isInactive) {
				this.initialLockUpdate = false
				axios.post(this.updateLockUrl).then((response) => {
					this.lockResponse(response)
				}).catch((error) => {
					this.flashStore.addFlash([{
						level: 'danger',
						message: error.response !== undefined ? error.response.data.message : error
					}])
				})
			}
		},
		lockResponse: function (response) {
			if (response.data.lock !== undefined) {
				this.lockStatus = response.data.lock

				this.lockAcquired = !!(response.data.lock.locked && response.data.lock.lockedByCurrentUser);

				if (this.store !== undefined) {
					this.store.lock = this.lockStatus
					this.store.lockAcquired = this.lockAcquired
				}
				this.$emit('lockAcquired', response.data.lock)
			}
			if (response.data.flash !== undefined){
				this.flashStore.set(response.data.flash)
			}
		},
		onSubmit: function (value, actions) {
			if (this.submitFunction !== null) {
				this.submitFunction()
			} else {
				this.$emit('beforeSubmit')

				let attributes = this.componentValue

				if (this.extraAttributes !== undefined){
					attributes = _.merge(attributes, this.extraAttributes)
				}
				if (this.hiddenAttributes.length) {
					attributes = _.omit(attributes, this.hiddenAttributes)
				}
				if (this.onlyAttributes.length) {
					attributes = _.pick(attributes, this.onlyAttributes)
				}

				axios.post(this.storeUrl, attributes)
					.then((response) => {
						this.handleResponse(response)
					})
					.catch((error) => {
						if (error.response && error.response.data && error.response.data.errors) {
							actions.setErrors(error.response.data.errors);
						} else {
							this.flashStore.addFlash({
								level: 'danger',
								message: error
							})
						}
					})
			}
		},
		handleResponse: function (response) {
			this.flashStore.addFlash(response.data.flash || [])

			if (response.data.reloadDataStore !== undefined){
				this.dataStore.loadData(response.data.reloadDataStore, false, true)
			}
			if (response.data.clearTooltip !== undefined){
				_.forEach(response.data.clearTooltip, (type) => {
					this.dataStore.clearTooltipData(type)
				})
			}

			if (response.data.success) {
				this.formClean()
				if (response.data.redirect !== undefined) {
					try {
						router.visit(_.clone(response.data.redirect))
					} catch (e){
						location.href = response.data.redirect
					}
				} else if ((this.disableRedirect === undefined || !this.disableRedirect) && this.backUrl !== undefined && this.backUrl) {
					router.visit(this.backUrl)
				} else {
					this.$emit('afterSubmit',  response.data.model, response.data)
				}
				this.lockAcquired = false
			} else {
				if (response.data.errors !== undefined) {
					let errors = []
					_.forEach(response.data.errors, function (fieldErrors) {
						errors.push(fieldErrors[0])
					})
					this.flashStore.addFlash({
						level: 'danger',
						message: errors.join(', ')
					})
					if (response.data.redirect !== undefined){
						router.visit(response.data.redirect)
					}
				}

				if (response.data.attributes !== undefined) {
					this.componentValue = response.data.attributes
					this.$emit('attributesUpdated')
				}
			}
		},
		backPressed: function () {
			this.$emit('backPressed')
		},
		startEditing: function () {
			axios.post(this.lockUrl).then((response) => {
				this.lockResponse(response)
			}).catch((error) => {
				this.lockAcquired = false
				this.flashStore.addFlash({level: 'danger', message: error.response.data.message})
			})
		},
		formClean: function () {
			this.unsavedChanged = false
			if (this.routerListener !== null){
				this.routerListener()
				this.routerListener = null
			}
		},
		reloadPage: function () {
			this.formClean()
			location.reload()
		},
		beforeLeave: function (event){
			if (this.unsavedChanged && !event.detail.visit.only.length) {
				if (!(this.tabUrlBase !== undefined && event.detail.visit.url.pathname.indexOf(this.tabUrlBase) === 0)){
					this.displayUnsavedDataAlert = true
					this.intendedDestination = event.detail.visit.url
					return false;
				}
			}

			return true
		},
		valueChanged: function (e) {
			//this.routerListener = router.on('before', this.beforeLeave)

			this.unsavedChanged = true
		},
		navigateToIntendedDestination: function() {
			if (this.intendedDestination && this.routerListener !== null){
				this.unsavedChanged = false
				this.routerListener()
				this.routerListener = null
				router.visit(this.intendedDestination)
			}
		},
		validate: function () {
			let valid = this.$refs.form.validate()
			this.valueChanged()

			return valid
		},
		submit: function () {
			if (this.$refs.topButtons !== undefined){
				this.$refs.topButtons.triggerSubmit()
			}
			if (this.$refs.saveButton !== undefined){
				this.$refs.saveButton.click()
			}
		},
	}
}
</script>

<style scoped>

</style>
