<template>
	<TitleBar :title="attributes.id !== undefined ? 'Termék módosítása' : 'Termék hozzáadása'" />
	<form-wrapper
		ref="formWrapper"
		:store-url="route('products.store', attributes.id)"
		:back-url="route('products.index')"
		v-model="attributes"
		@before-submit="beforeSubmit"
		table-name="products"
		:required-data="['siPrefixes', 'suppliers', 'productTypes', 'productAttributes']"
		@dataLoaded="initData"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div>
						<div class="details-box">
							<div v-if="attributes.public_id !== undefined">
								<i class="far fa-hashtag"></i> Azonosító: <strong>{{ attributes.public_id  }}</strong>
							</div>
							<updated-attribute :data="attributes" created/>
							<updated-attribute :data="attributes" updated/>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template #default="{activeTab, errors, editable}">
			<div class="row" v-if="barcodeUrl !== ''">
				<div class="col-md-6">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-3">Vonalkód</h4>
							<div class="mb-3 d-flex flex-column align-items-center">
								<div class="mb-2">
									<img :src="barcodeUrl" />
								</div>
								<div class="d-flex gap-2">
									<button type="button" class="btn btn-primary" @click.prevent="printBarcode"><i class="far fa-print"></i> Nyomtatás</button>
									<a :href="barcodeDownloadUrl + '?action=download'" class="btn btn-secondary"><i class="far fa-download"></i> Letöltés</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Adatok</h4>
					<data-loader :data="['productTypes']" v-slot="{ dataStore }">
						<radio-field
							label="Típus"
							v-model="attributes.type"
							:disabled="!attributes.editable"
							:values="dataStore.getData('productTypes')"
							:hide-non-active="attributes.id !== undefined"
						/>
					</data-loader>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model:model-value="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
								:multilang="true"
								v-model:translations="attributes.translations"
							/>

							<input-field
								label="Cikkszám"
								name="sku"
								rules="required|alpha_num"
								v-model="attributes.sku"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Vonalkód"
								name="barcode"
								rules="alpha_num"
								v-model="attributes.barcode"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Gyártói azonosító"
								name="manufacturer_code"
								rules=""
								v-model="attributes.manufacturer_code"
								:disabled="!attributes.editable"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>
							<input-field
								label="Prioritás"
								name="priority"
								data-type="number"
								:min="0"
								v-model="attributes.priority"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Garancia ideje"
								name="warranty_months"
								data-type="numeric"
								v-model="attributes.warranty_months"
								:disabled="!attributes.editable"
								suffix="hónap"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>
							<input-field
								label="Tömeg"
								name="weight_kg"
								data-type="numeric"
								v-model="attributes.weight_kg"
								:disabled="!attributes.editable"
								:step="0.001"
								suffix="kg"
								:errors="errors"
								v-if="attributes.type === 'material' || attributes.type === 'equipment' || attributes.type === 'consumable'"
							/>

							<input-field
								label="Alacsony készlet értesítés"
								name="low_stock_warning"
								v-model="attributes.low_stock_warning"
								:disabled="!attributes.editable"
								data-type="number"
								:step="1"
								:errors="errors"
								v-if="attributes.track_inventory"
								:suffix="attributes.unit"
								info="Amennyiben meg van adva, értesítést generál, ha a raktárkészlet a megadott mennyiség alá csökken"
							/>
						</div>
						<div class="col-sm-6">
							<product-category-select-field
								label="Kategória"
								name="product_category_id"
								rules="required"
								v-model="attributes.product_category_id"
								:disabled="!attributes.editable"
								:errors="errors"
								@update:modelValue="updateAttributes"
							/>

							<project-type-select-field
								label="Projekt típusok"
								name="project_types"
								v-model="attributes.project_types"
								:disabled="!attributes.editable"
								:multiple="true"
								:errors="errors"
								v-if="attributes.type === 'consumable'"
							/>

							<product-manufacturer-select-field
								label="Gyártó"
								name="product_manufacturer_id"
								v-model="attributes.product_manufacturer_id"
								:disabled="!attributes.editable"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>

							<input-field
								label="Ömlesztett termék szorzó"
								name="bulk_multiplier"
								rules="required"
								data-type="number"
								v-model="attributes.bulk_multiplier"
								:disabled="!attributes.editable"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>

							<input-field
								label="Mennyiségi egység"
								name="unit"
								v-model="attributes.unit"
								:disabled="!attributes.editable"
								:errors="errors"
							/>

							<input-field
								label="Max mennyiség árajánlatban"
								name="max_quantity"
								v-model="attributes.max_quantity"
								:disabled="!attributes.editable"
								data-type="number"
								:step="1"
								:errors="errors"
								v-if="attributes.type !== 'equipment' && attributes.type !== 'consumable'"
							/>

							<input-field
								label="Mennyiségi egység"
								name="bundle_unit"
								v-model="attributes.bundle_unit"
								:disabled="!attributes.editable"
								rules="required"
								:errors="errors"
								v-if="attributes.type === 'bundle' && attributes.bundle_use_custom_unit"
							/>

						</div>
					</div>
					<div class="row">
						<div class="col-sm-6" v-if="attributes.type === 'material' || attributes.type === 'equipment' || attributes.type === 'consumable'">
							<switch-field
								label="Raktárkészlet követése"
								name="track_inventory"
								v-model="attributes.track_inventory"
								:disabled="!!trackInventory || !attributes.editable"
								:inline="true"
								:inline-reversed="true"
							/>

							<switch-field
								label="Sorozatszám követése"
								name="track_serial_number"
								v-model="attributes.track_serial_number"
								:disabled="!!trackInventory || !attributes.editable"
								:inline="true"
								:inline-reversed="true"
							/>

							<switch-field
								label="Alkatrészként használható"
								name="allow_using_as_component"
								v-model="attributes.allow_using_as_component"
								:disabled="!attributes.editable"
								:inline="true"
								:inline-reversed="true"
								info="Amennyiben be van kapcsolva, szerződéshez és eladáshoz készlet követésével csatolható az ár befolyásolása nélkül"
							/>
						</div>
						<div class="col-sm-6">
							<switch-field
								label="Egyedi mennyiségi egység"
								name="bundle_use_custom_unit"
								v-model="attributes.bundle_use_custom_unit"
								:disabled="!attributes.editable"
								v-if="attributes.type === 'bundle'"
								:inline="true"
								:inline-reversed="true"
							/>

							<switch-field
								label="Kiszállítást igényel"
								name="requires_delivery"
								v-model="attributes.requires_delivery"
								:disabled="!attributes.editable"
								v-if="attributes.type === 'material'"
								:inline="true"
								:inline-reversed="true"
							/>
							<switch-field
								label="Opcionális"
								name="is_optional"
								v-model="attributes.is_optional"
								:disabled="!attributes.editable"
								v-if="attributes.type !== 'bundle' && attributes.type !== 'equipment' && attributes.type !== 'consumable'"
								:inline="true"
								:inline-reversed="true"
							/>

							<switch-field
								label="Részletek mutatása az árajánlatban"
								name="show_details_in_quote"
								v-model="attributes.show_details_in_quote"
								:disabled="!attributes.editable"
								v-if="attributes.type !== 'bundle' && attributes.type !== 'equipment' && attributes.type !== 'consumable' && attributes.type !== 'relayedService' && attributes.type !== 'service'"
								:inline="true"
								:inline-reversed="true"
							/>

							<switch-field
								v-if="attributes.type === 'bundle'"
								label="Árajánlatban kibontás"
								name="expand_in_quote"
								v-model="attributes.expand_in_quote"
								:disabled="!attributes.editable"
								:inline="true"
								:inline-reversed="true"
							/>
						</div>
					</div>
				</div>
			</div>

			<data-loader :data="['productAttributes', 'productAttributeGroups']" v-slot="{ dataStore }">
				<div class="card mb-3" v-if="getAvailableAttributes().length">
					<div class="card-body">
						<h4 class="card-title mb-3">Tulajdonságok</h4>
						<div class="row">
							<div class="col-md-4">
								<data-loader :data="['productAttributeGroups']" v-slot="{ dataStore }">
									<select-field
										label="Tulajdonságcsoport"
										:options="dataStore.getData('productAttributeGroups')"
										name="product_attribute_group_id"
										v-model="attributes.product_attribute_group_id"
										:disabled="!attributes.editable"
										:errors="errors"
										@change="updateAttributesFromGroup"
									/>
								</data-loader>
							</div>
						</div>
						<table class="table m-0">
							<thead>
							<tr>
								<th width="50%">Tulajdonság</th>
								<th width="49%">Érték</th>
								<th width="1%" v-if="attributes.editable"></th>
							</tr>
							</thead>
							<tbody>
							<template v-for="(item, itemKey) in attributes.product_attributes" :key="'attribute_' + itemKey">
								<tr :class="{'table-primary': !itemKey}">
									<td>
										<product-attribute-select-field
											v-model="item.product_attribute_id"
											:field-only="true"
											:disabled="!attributes.editable"
											:name="'attribute_id_' + itemKey"
											:selectable="isAttributeSelectable"
										></product-attribute-select-field>
									</td>
									<td>
										<template v-if="item.product_attribute_id">
											<template v-if="dataStore.getData('productAttributes', true)[item.product_attribute_id].type === 'string'">
												<input-field
													label="Érték"
													v-model="item.value_string"
													:field-only="true"
													:disabled="!attributes.editable"
													:name="'attribute_value_string_' + itemKey"
												></input-field>
											</template>
											<template v-if="dataStore.getData('productAttributes', true)[item.product_attribute_id].type === 'number'">
												<input-field
													label="Érték"
													v-model="item.value_number"
													data-type="number"
													:step="0.01"
													:field-only="true"
													:disabled="!attributes.editable"
													:suffix="(dataStore.getData('productAttributes', true)[item.product_attribute_id].input_field_prefix && dataStore.getData('productAttributes', true)[item.product_attribute_id].input_field_prefix !== '-' ? dataStore.getData('productAttributes', true)[item.product_attribute_id].input_field_prefix : '') + dataStore.getData('productAttributes', true)[item.product_attribute_id].unit"
													:name="'attribute_value_number_' + itemKey"
												></input-field>
											</template>
											<template v-if="dataStore.getData('productAttributes', true)[item.product_attribute_id].type === 'select'">
												<select-field
													label="Érték"
													:options="dataStore.getData('productAttributes', true)[item.product_attribute_id].values"
													v-model="item.product_attribute_value_id"
													label-field="value_string"
													:field-only="true"
													:disabled="!attributes.editable"
													:name="'attribute_value_select_' + itemKey"
												></select-field>
											</template>
										</template>
									</td>
									<td v-if="attributes.editable">
										<div class="d-flex gap-2">
											<button class="btn btn-light" title="Feljebb" type="button" @click.prevent="moveAttribute(itemKey, 'up')"><i class="far fa-angle-up"></i></button>
											<button class="btn btn-light" title="Lejjebb" type="button" @click.prevent="moveAttribute(itemKey, 'down')"><i class="far fa-angle-down"></i></button>
											<button class="btn btn-danger" title="Töröl" type="button" @click.prevent="removeAttribute(itemKey)"><i class="far fa-times"></i></button>
										</div>
									</td>
								</tr>
							</template>
							</tbody>
							<tfoot v-if="attributes.editable">
							<tr>
								<td colspan="3">
									<button class="btn btn-secondary btn-label" type="button" @click.prevent="addAttribute"><i class="far fa-plus label-icon"></i> Hozzáad</button>
								</td>
							</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</data-loader>
			<div class="row">
				<div class="col-md-6" v-if="attributes.type !== 'bundle' && attributes.type !== 'equipment'">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-3">Ár</h4>

							<input-field
								label="Beszerzési ár"
								name="price_original"
								data-type="number"
								:step="0.01"
								v-model="attributes.price"
								:disabled="!attributes.editable"
								:errors="errors"
								v-if="attributes.type === 'material' || attributes.type === 'consumable' || attributes.type === 'equipment'"
							/>
							<input-field
								label="Ár"
								name="price"
								data-type="number"
								:step="0.01"
								v-model="attributes.price"
								:disabled="!attributes.editable"
								:errors="errors"
								v-else
							/>
							<currency-select-field
								label="Pénznem"
								name="currency_id"
								rules="required"
								v-model="attributes.currency_id"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<tax-select-field
								label="ÁFA"
								name="tax_id"
								rules="required"
								v-model="attributes.tax_id"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Haszonkulcs"
								name="profit_margin"
								data-type="number"
								:step="0.1"
								v-model="attributes.profit_margin"
								:disabled="!attributes.editable"
								suffix="%"
								:errors="errors"
								v-if="attributes.type !== 'work' && attributes.type !== 'service'"
							/>
						</div>
					</div>
				</div>
				<div class="col-md-6" v-if="attributes.type === 'material' || attributes.type === 'consumable' || attributes.type === 'equipment'">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-3">Csomagolások</h4>

							<data-loader :data="['productContainers']" v-slot="{ dataStore }">
								<template v-for="productContainer in dataStore.getData('productContainers')">
									<input-field
										:label="productContainer.name"
										:name="'productContainer_' + productContainer.id"
										data-type="number"
										:step="1"
										v-model="attributes.containers[productContainer.id]"
										:disabled="!attributes.editable"
										:errors="errors"
									/>
								</template>
							</data-loader>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3" v-if="attributes.type === 'bundle'">
				<div class="card-body">
					<h4 class="card-title mb-3">Termékcsomag</h4>
					<div class="mb-3 d-flex flex-wrap gap-2" v-if="attributes.editable">
						<button type="button" class="btn btn-secondary" @click.prevent="addProductToBundle"><i class="fas fa-plus"></i> Hozzáad</button>
					</div>
					<table class="table table-striped table-bordered">
						<thead>
						<tr>
							<th>Termék</th>
							<th width="10%">Szorzó</th>
							<th width="10%">Ármódosító <i class="far fa-percent"></i></th>
							<th width="10%">Választható</th>
							<th width="10%">Fix mennyiség</th>
							<th width="10%">Min</th>
							<th width="10%">Max</th>
							<th width="5%">&nbsp;</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(item, itemKey) in bundleProducts">
							<td :key="'bundleItem_' + item.uniqueId">
								<v-select
									:options="products"
									v-model="item.product_id"
									:reduce="x => x.id"
									label="formattedName"
									:clearable="false"
									placeholder="Válassz terméket"
									:name="'bundle_products_' + item.uniqueId + '_product'"
									:multiple="item.selectable === 1 || item.selectable === true"
									:key="'bundleItemSelect_' + item.uniqueId"
									v-if="!hideBundleSelects"
								/>
								<div class="invalid-tooltip mt-1" v-if="item.error !== undefined && item.error && item.error.length" style="display:block">
									{{ item.error }}
								</div>
								<div class="mt-2" v-if="item.selectable === 1 || item.selectable === true">
									<input-field
										label="Megnevezés"
										:name="'bundle_products_' + itemKey + '_label'"
										v-model="item.label"
										v-if="item.optional === 1 || item.optional === true"
										:errors="errors"
										:field-only="true"
										:show-placeholder="true"
									/>

									<SwitchField
										v-model="item.optional"
										label="Opcionális"
										:inline="true"
										:name="'bundle_product_optional_' + itemKey"
									/>

									<SwitchField
										v-model="item.select_first_by_default"
										label="Elsőt kiválaszt"
										:inline="true"
										:name="'bundle_product_select_first_by_default_' + itemKey"
									/>
								</div>
							</td>
							<td>
								<InputField
									v-model="item.multiplier"
									:name="'bundle_product_multiplier_' + itemKey"
									:field-only="true"
									label="Szorzó"
									data-type="number"
									rules="required"
									:errors="errors"
									:step="0.01"
									:min="0.01"
								/>
							</td>
							<td>
								<InputField
									v-model="item.price_multiplier"
									:name="'bundle_product_price_multiplier_' + itemKey"
									:field-only="true"
									label="Ármódosító"
									data-type="number"
									:errors="errors"
									:step="0.1"
								/>
							</td>
							<td style="vertical-align: middle; text-align:center;">
								<SwitchField
									v-model="item.selectable"
									:field-only="true"
									:name="'bundle_product_selectable_' + itemKey"
								/>
							</td>
							<td style="vertical-align: middle; text-align:center;">
								<SwitchField
									v-model="item.fixed_quantity"
									:field-only="true"
									:name="'bundle_product_fixed_quantity_' + itemKey"
								/>
							</td>
							<td>
								<InputField
									v-model="item.min_quantity"
									:name="'bundle_product_min_quantity_' + itemKey"
									:field-only="true"
									label="Min mennyiség"
									data-type="number"
									:errors="errors"
									:step="1"
									:min="1"
								/>
							</td>
							<td>
								<InputField
									v-model="item.max_quantity"
									:name="'bundle_product_max_quantity_' + itemKey"
									:field-only="true"
									label="Max mennyiség"
									data-type="number"
									:errors="errors"
									:step="1"
								/>
							</td>
							<td>
								<div class="d-flex gap-2" v-if="attributes.editable">
									<button type="button" @click.prevent="hideBundleSelects = true; moveBundleItem(itemKey, 'up'); hideBundleSelects = false" class="btn btn-secondary"><i class="far fa-arrow-up"></i></button>
									<button type="button" @click.prevent="moveBundleItem(itemKey, 'down')" class="btn btn-secondary"><i class="far fa-arrow-down"></i></button>
									<button type="button" class="btn btn-danger" @click.prevent="removeBundleItem(itemKey)"><i class="far fa-times"></i></button>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Leírás</h4>
					<textarea rows="6" v-model="attributes.description" class="form-control"></textarea>
					<h4 class="card-title mb-3 mt-4">Megjegyzés szállítólevél generáláshoz</h4>
					<textarea rows="6" v-model="attributes.comment_for_shipment" class="form-control"></textarea>
				</div>
			</div>
			<div class="card mb-3" v-if="attributes.type === 'material' || attributes.type === 'consumable' || attributes.type === 'equipment'">
				<div class="card-body">
					<h4 class="card-title mb-3">Beszállítók</h4>
					<div class="row py-2 border-bottom mx-0">
						<div class="col-md-4 fw-bold">
							Beszállító
						</div>
						<div class="col-md-4 fw-bold">
							Cikkszám
						</div>
						<div class="col-md-4 fw-bold">
							Vonalkód
						</div>
					</div>

					<data-loader :data="['suppliers']" v-slot="{ dataStore }">
						<div class="row py-2 border-bottom mx-0" v-for="supplier in dataStore.getData('suppliers')">
							<div class="col-md-4 d-flex align-items-center">
								{{ supplier.name }}
							</div>
							<div class="col-md-4">
								<input type="text" class="form-control" v-model="attributes.supplier_products[supplier.id].supplier_code" />
							</div>
							<div class="col-md-4">
								<input type="text" class="form-control" v-model="attributes.supplier_products[supplier.id].supplier_barcode" />
							</div>
						</div>
					</data-loader>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Dokumentumok</h4>
					<FileUploader
						field-name="document"
						v-model="attributes.documents"
						file-type="document"
						:editable="attributes.editable"
					></FileUploader>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Képek</h4>
					<FileUploader
						field-name="image"
						v-model="attributes.images"
						file-type="image"
						:editable="attributes.editable"
					></FileUploader>
				</div>
			</div>
		</template>
	</form-wrapper>
</template>

<script>
import SelectField from "../components/form/SelectField.vue";
import DataLoader from "../components/DataLoader.vue";
import SwitchField from "../components/form/SwitchField.vue";
import vSelect from "vue-select";
import ProjectTypeSelectField from "../components/form/ProjectTypeSelectField.vue";
import CurrencySelectField from "../components/form/CurrencySelectField.vue";
import TaxSelectField from "../components/form/TaxSelectField.vue";
import ProductCategorySelectField from "../components/form/ProductCategorySelectField.vue";
import FormWrapper from "../components/FormWrapper.vue";
import ProductAttributeSelectField from "../components/form/ProductAttributeSelectField.vue";
import InputField from "../components/form/InputField.vue";
import RadioField from "../components/form/RadioField.vue";
import FileUploader from "../components/FileUploader.vue";
import ProductManufacturerSelectField from "../components/form/ProductManufacturerSelectField.vue";
import {useGeneralDataStore} from "../stores/generalData";
import {moveValueInArray} from "../functions";
import printJS from "print-js";
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import {Link} from "@inertiajs/vue3";
import AdvancedDataTable from "../components/AdvancedDataTable.vue";
import CopyToClipboard from "../components/CopyToClipboard.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import UserBadge from "../components/badges/UserBadge.vue";
import DateTime from "../components/DateTime.vue";
import LockMessage from "../components/LockMessage.vue";

export default {
	layout: AppLayout,
	components: {
		LockMessage, DateTime, UserBadge, UpdatedAttribute, CopyToClipboard,
		AdvancedDataTable,
		TitleBar,
		ProductManufacturerSelectField,
		FileUploader,
		RadioField,
		InputField,
		ProductAttributeSelectField,
		FormWrapper,
		ProductCategorySelectField,
		TaxSelectField,
		CurrencySelectField,
		ProjectTypeSelectField,
		vSelect,
		SwitchField,
		DataLoader,
		SelectField
	},
	props: {
		model: Object,
		trackInventory: Boolean,
		trackSerialNumber: Boolean,
		products: Array,
		barcodeUrl: String,
		barcodeDownloadUrl: String,
	},
	data(){
		return {
			attributes: {},
			dataLoaded: false,
			bundleProducts: [],
			hideBundleSelects: false,
			dataStore: useGeneralDataStore()
		}
	},
	computed: {
		usedAttributes: {
			get() {
				let payload = []
				_.forEach(this.attributes.product_attributes, function (attribute){
					if (attribute.product_attribute_id && payload.indexOf(attribute.product_attribute_id) < 0){
						payload.push(attribute.product_attribute_id)
					}
				})

				return payload
			}
		},
		category: {
			get() {
				if (!this.attributes.product_category_id){
					return false
				}
				return this.dataStore.getData('productCategories', true)[this.attributes.product_category_id]
			}
		}
	},
	mounted() {
		this.dataStore.loadData(['productAttributes', 'productAttributeGroups'], this.updateAttributesFromGroup)

	},
	methods: {
		getAvailableAttributes: function(){
			let payload = []
			_.forEach(this.dataStore.getData('productAttributes'), (attribute) => {
				if (!attribute.product_category_id || attribute.product_category_id === this.attributes.product_category_id){
					payload.push(attribute)
				}
			})

			return payload
		},
		initData: function (){
			let attributes = _.clone(this.model)

			if (Array.isArray(attributes.translations) && !attributes.translations.length){
				attributes.translations = {}
			}

			if (attributes.supplier_products === undefined){
				attributes.supplier_products = {}
			}
			if (attributes.product_attributes === undefined || attributes.product_attributes === null){
				attributes.product_attributes = []
			} else {
				let tmp = []
				_.forEach(attributes.product_attributes, (attribute) => {
					let multiplier = 1
					if (attribute.type === 'number'
						&& attribute.input_field_prefix
						&& attribute.input_field_prefix !== '-'){
						multiplier = Math.pow(10, -1 * this.dataStore.getData('siPrefixes')[attribute.input_field_prefix].exponent)
					}
					tmp.push({
						product_attribute_id: attribute.id,
						product_attribute_value_id: attribute.pivot.product_attribute_value_id,
						value_string: attribute.pivot.value_string,
						value_number: attribute.pivot.value_number * multiplier,
					})
				})

				attributes.product_attributes = tmp
			}

			let tmp = attributes.supplier_products || []
			attributes.supplier_products = {}
			let projectTypes = attributes.project_types
			attributes.project_types = []
			_.forEach(projectTypes, function (type){
				attributes.project_types.push(type.id)
			})

			for (let i in tmp){
				attributes.supplier_products[tmp[i].supplier_id] = {
					supplier_code: tmp[i].supplier_code,
					supplier_barcode: tmp[i].supplier_barcode
				}
			}

			for (let i in this.dataStore.getData('suppliers')){
				if (attributes.supplier_products[this.dataStore.getData('suppliers')[i].id] === undefined){
					attributes.supplier_products[this.dataStore.getData('suppliers')[i].id] = {
						supplier_code: '',
						supplier_barcode: ''
					}
				}
			}

			if (!parseFloat(attributes.last_acquisition_price)){
				attributes.last_acquisition_price = ''
			}
			if (!parseFloat(attributes.price)){
				attributes.price = ''
			}
			let bundleProducts = []
			let bundleTmp = []
			if (attributes.bundle_products !== undefined && attributes.bundle_products.length){
				let uniqueId = 1;
				_.forEach(attributes.bundle_products, function(item){
					item.pivot.selectable = item.pivot.selectable === 1
					item.pivot.uniqueId = uniqueId++
					if (item.pivot.selectable){
						if (bundleTmp[item.pivot.order] === undefined){
							item.pivot.product_id = [item.pivot.product_id]
							bundleTmp[item.pivot.order] = item.pivot
						} else {
							bundleTmp[item.pivot.order].product_id.push(item.pivot.product_id)
						}
					} else {
						bundleTmp[item.pivot.order] = item.pivot
					}
				})
				bundleProducts = _.compact(_.values(bundleTmp))
				delete attributes.bundle_products
			}

			this.attributes = attributes
			this.bundleProducts = bundleProducts
			this.dataLoaded = true

			this.updateAttributes()
		},
		moveValueInArray,
		beforeSubmit: function(){
			this.attributes.bundleProducts = []
			for (let i in this.bundleProducts){
				if (this.bundleProducts[i].error !== undefined){
					delete this.bundleProducts[i].error
				}
				if (this.bundleProducts[i].selectable){
					let order = 1
					for (let j in this.bundleProducts[i].product_id){
						let row = _.clone(this.bundleProducts[i])
						row.product_id = this.bundleProducts[i].product_id[j]
						row.value_order = order++
						this.attributes.bundleProducts.push(row)
					}
				} else {
					let row = _.clone(this.bundleProducts[i])
					row.value_order = 1
					this.attributes.bundleProducts.push(row)
				}
			}
		},
		isNumber: function(e){
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			const keyPressed = e.key;

			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},
		removeBundleItem: function(itemKey){
			this.bundleProducts.splice(itemKey, 1)
		},
		addProductToBundle: function(){
			let order = 0
			let uniqueId = 1
			for (let i = 0; i < this.bundleProducts.length; i++){
				if (this.bundleProducts[i].order > order){
					order = this.bundleProducts[i].order
				}
				if (this.bundleProducts[i].uniqueId > uniqueId){
					uniqueId = this.bundleProducts[i].uniqueId
				}
			}
			this.bundleProducts.push({
				product_id: '',
				multiplier: 1,
				selectable: false,
				order: order + 1,
				uniqueId: uniqueId + 1,
				min_quantity: null,
				max_quantity: null,
			})
		},
		moveBundleItem: function(index, direction){
			this.bundleProducts = this.moveValueInArray(this.bundleProducts, index, direction)

			let order = 0
			this.bundleProducts.forEach(function(item, index){
				item.order = order
				order++
			})
		},
		printBarcode: function (){
			printJS({printable: this.barcodeDownloadUrl, type: 'pdf'})
		},
		addAttribute: function (){
			this.attributes.product_attributes.push({
				product_attribute_id: null,
				product_attribute_value_id: null,
				value_string: null,
				value_number: null,
			})
		},
		moveAttribute: function (index, direction){
			this.attributes.product_attributes = this.moveValueInArray(this.attributes.product_attributes, index, direction)
		},
		removeAttribute: function (index){
			this.attributes.product_attributes.splice(index, 1)
		},
		isAttributeSelectable: function (attribute){
			if (attribute.product_categories !== null && attribute.product_categories.length && attribute.product_categories.indexOf(this.attributes.product_category_id) < 0){
				return false
			}
			if (attribute.disabled_product_categories !== null && attribute.disabled_product_categories.length && attribute.disabled_product_categories.indexOf(this.attributes.product_category_id) >= 0){
				return false
			}
			if (attribute.allow_multiple){
				return true
			}
			return this.usedAttributes.indexOf(attribute.id) < 0
		},
		isAttributeAdded: function (attributeId){
			for (let i = 0; i < this.attributes.product_attributes.length; i++){
				if (this.attributes.product_attributes[i].product_attribute_id === attributeId){
					return true
				}
			}

			return false
		},
		updateAttributes: function (){
			if (this.category){
				for (let i = 0; i < this.category.product_attributes.length; i++){
					const attributeId = this.category.product_attributes[i]
					if (!this.isAttributeAdded(attributeId)){
						this.attributes.product_attributes.push({
							product_attribute_id: attributeId,
							product_attribute_value_id: null,
							value_string: null,
							value_number: null,
						})
					}
				}
			}
		},
		updateAttributesFromGroup: function (e){
			const groups = _.keyBy(this.dataStore.getData('productAttributeGroups'), 'id')
			const attributes = _.keyBy(this.dataStore.getData('productAttributes'), 'id')

			if (this.attributes.product_attribute_group_id !== undefined && groups[this.attributes.product_attribute_group_id] !== undefined){
				const group = groups[this.attributes.product_attribute_group_id]
				let currentValues = _.keyBy(this.attributes.product_attributes, 'product_attribute_id')
				let productAttributes = []
				const groupAttributesWithOrder = []

				_.forEach(group.product_attributes, function(value){
					if (attributes[value] !== undefined){
						groupAttributesWithOrder.push({id: value, order: attributes[value].order})
					}
				})

				const orderedAttributes = _.sortBy(groupAttributesWithOrder, ['order'])

				_.forEach(orderedAttributes, function(attribute){
					productAttributes.push({
						product_attribute_id: attribute.id,
						product_attribute_value_id: currentValues[attribute.id] !== undefined ? currentValues[attribute.id].product_attribute_value_id : null,
						value_string: currentValues[attribute.id] !== undefined ? currentValues[attribute.id].value_string : null,
						value_number: currentValues[attribute.id] !== undefined ? currentValues[attribute.id].value_number : null,
					})
				})

				this.attributes.product_attributes = productAttributes
			}
		}
	}
}
</script>
