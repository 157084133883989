<template>
	<modal
		title="Feladat késznek jelölése"
		@close="$emit('close')"
		:closable="true"
		>
		<template #default>
			<div class="gap-4 d-flex align-items-center flex-row mb-2">
				<div class="flex-shrink-0 align-self-center">
					<div :class="'mini-stat-icon avatar-sm rounded-circle bg-' + task.color">
						<span :class="'avatar-title bg-' + (task.completed_at ? 'success' : task.color)">
							<i :class="'font-size-24 ' + task.icon"></i>
						</span>
					</div>
				</div>

				<div class="flex-grow-1">
					<h5 class="mb-0">{{ task.title }}</h5>
					<p class="text-muted fw-medium mb-0" v-html="task.description"></p>
					<div class="horizontal">
						<attribute icon="far fa-clock"><date-time :value="task.created_at"></date-time></attribute>
						<attribute icon="far fa-user"><user-badge :user-id="task.created_by_user_id" hide-level hide-link></user-badge></attribute>
					</div>
				</div>
			</div>
			<textarea-field
				label="Válasz"
				name="result"
				v-model="taskResult"
				no-margin
			></textarea-field>
		</template>

		<template #buttons>
			<button type="button" class="btn btn-success btn-label" @click.prevent="setCompleted"><i class="far fa-check label-icon"></i> Késznek jelöl</button>
			<button type="button" class="btn btn-secondary btn-label" @click.prevent="$emit('close')"><i class="far fa-times label-icon"></i> Mégse</button>
		</template>
	</modal>
</template>

<script>
import Modal from "../../components/Modal.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import UserBadge from "../../components/badges/UserBadge.vue";
import Attribute from "../../components/Attribute.vue";
import DateTime from "../../components/DateTime.vue";
import {useFlashStoreStore} from "../../stores/flashStore";

export default {
	components: {DateTime, Attribute, UserBadge, TextareaField, Modal},
	emits: ['close', 'closeAndUpdate'],
	data(){
		return {
			taskResult: '',
			flashStore: useFlashStoreStore()
		}
	},
	props: {
		task: Object
	},
	methods: {
		setCompleted: function (){
			axios.post('/tasks/answer/' + this.task.id, {
				result: this.taskResult,
				setCompleted: 1
			}).then((response)=>{
				if (response.data.flash !== undefined){
					this.flashStore.addFlash(response.data.flash)
				}
				this.$emit('closeAndUpdate')
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		}
	}
}
</script>
