<script setup>
import Attribute from "../Attribute.vue";
import DateTime from "../DateTime.vue";
import {computed} from "@vue/compat";
import UserBadge from "../badges/UserBadge.vue";

const props = defineProps({
	userId: Number,
	label: {
		type: String,
		default: 'Felhasználó'
	},
	icon: {
		type: String,
		default: ''
	},
	handler: Boolean,
	creator: Boolean,
	updater: Boolean,
	approver: Boolean,
	sender: Boolean,
	answerSubmitter: Boolean,
	terminator: Boolean,
	closer: Boolean,
	superior: Boolean,
})

const labelComputed = computed(() => {
	if (props.handler){
		return 'Tanácsadó'
	}
	if (props.creator){
		return 'Létrehozó'
	}
	if (props.updater){
		return 'Módosító'
	}
	if (props.approver){
		return 'Jóváhagyta'
	}
	if (props.sender){
		return 'Küldő'
	}
	if (props.answerSubmitter){
		return 'Választ rögzítette'
	}
	if (props.terminator){
		return 'Felbontotta'
	}
	if (props.closer){
		return 'Lezárta'
	}
	if (props.superior){
		return 'Felettes'
	}

	return props.label
})

const iconComputed = computed(() => {
	if (props.icon !== ''){
		return props.icon
	}
	if (props.handler || props.superior){
		return 'far fa-user-tie'
	}
	if (props.updater){
		return 'far fa-user-cog'
	}
	if (props.approver || props.closer){
		return 'far fa-user-check'
	}
	if (props.sender || props.answerSubmitter || props.creator){
		return 'far fa-user-plus'
	}
	if (props.terminator){
		return 'far fa-user-times'
	}

	return 'far fa-user'
})
</script>

<template>
	<attribute
		:icon="iconComputed"
		:label="labelComputed"
		v-if="userId"
	>
		<user-badge small :user-id="userId" :key="'US_' + userId" />
	</attribute>
</template>

<style scoped>

</style>
