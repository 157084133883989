<script setup>
import {ref} from "vue";
import {useApplicationStore} from "../../stores/applicationStore";
import {useGeneralDataStore} from "../../stores/generalData";
import ColorBadge from "../ColorBadge.vue";
import Popper from "vue3-popper";
import {Link} from "@inertiajs/vue3";
import DataLoader from "../DataLoader.vue";
import {computed} from "@vue/compat";
import Attribute from "../Attribute.vue";
import Number from "../Number.vue";
import Price from "../Price.vue";
import EmailAddress from "../EmailAddress.vue";
import PhoneNumber from "../PhoneNumber.vue";
import WarehouseBadge from "./WarehouseBadge.vue";

const props = defineProps({
	productId: Number,
	productName: String,
	productManufacturerId: Number,
	productCategoryId: Number,
	deletedAt: String|Date,
	sku: String,
	disableLink: Boolean,
	dark: Boolean,
	small: {
		type: Boolean,
		default: true
	},
	desktop:{
		type: Boolean,
		default: false
	},
	mobile:{
		type: Boolean,
		default: false
	},
})

const loaded = ref(false)
const app = useApplicationStore()
const open = ref(false)
const error = ref(false)
const dataStore = useGeneralDataStore()
const details = ref({})

function load(){
	if (!loaded.value) {
		let loadedData = dataStore.getTooltipData('product', props.productId)
		if (loadedData !== false && loadedData){
			details.value = loadedData
			loaded.value = true
		} else {
			axios.get('/tooltips/product/' + props.productId, {}).then((response) => {
				details.value = response.data.details
				dataStore.addTooltip('product', props.productId, details.value)
				loaded.value = true
			}).catch((e) => {
				error.value = true
			})
		}
	}
}

const isDeleted = computed(() => {
	return props.productId === null || !props.productId || props.deletedAt
})

function onOpen(){
	open.value = true
	load()
}

</script>

<template>
	<data-loader :data="['productManufacturers', 'productCategories', 'productTypes']" v-slot="{ dataStore }" v-if="app.responsiveDisplayable(mobile, desktop)" hide-loader>
		<template v-if="!disableLink">
			<VDropdown
				@show="onOpen">
				<div class="vertical">
					<a :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'text-danger' : isDeleted, 'colored-badge-sm': small, 'text-dark': !dark && !isDeleted, 'text-white': dark && !isDeleted}" href="#">
						<i class="far fa-boxes"></i>
						<span class="colored-badge-content-wrap">{{ !productName ? 'Törölt termék' : productName }}</span>
						<i class="far fa-info-circle font-size-10"></i>
					</a>
					<div v-if="productManufacturerId || productCategoryId || sku" class="horizontal opacity-75">
						<span :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" v-if="sku">
							<i class="far fa-hashtag"></i> {{ sku }}
						</span>
						<span :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" v-if="productManufacturerId">
							<i class="far fa-industry-alt"></i> {{
								dataStore.getData('productManufacturers', true)[productManufacturerId].name
							}}
						</span>
						<span :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" v-if="productCategoryId">
							<i class="far fa-sitemap"></i> {{ dataStore.getData('productCategories', true)[productCategoryId].name }}
						</span>
					</div>
				</div>
				<template #popper>
					<div :class="{'overview-tooltip': 1}">
						<div class="overview-tooltip-head">
							<template v-if="loaded">
								<div class="d-flex">
									<div class="flex-grow-1">
										<div class="horizontal">
											<color-badge
												color="secondary"
												icon="far fa-boxes"
												small
												content="Termék"
											/>
											<color-badge
												v-if="details.deleted_at"
												color="danger"
												icon="far fa-times"
												small
												content="Törölt termék"
											/>
										</div>
										<h5 class="font-size-15 mt-1">{{ details.name }}</h5>
										<color-badge
											icon="fas fa-hashtag"
											color="light"
											small
										>
											{{ details.sku }}
										</color-badge>
									</div>
									<div class="d-flex flex-column gap-2">
										<Link :href="'/products/edit/' + productId" class="btn btn-soft-secondary btn-sm" title="Adatlap" v-if="app.hasPermission('ProductsController_view')"><i class="far fa-external-link"></i></Link>
										<Link :href="'/inventory/product/' + productId" class="btn btn-soft-secondary btn-sm" title="Raktárkészlet" v-if="app.hasPermission('InventoryController_view')"><i class="far fa-inventory"></i></Link>
									</div>
								</div>
							</template>
							<template v-else>
								<div class="placeholder-glow mb-1">
									<span class="placeholder font-size-15" :style="'width:32%'"></span>
								</div>
								<div class="placeholder-glow mb-1">
									<span class="placeholder font-size-14" :style="'width:51%'"></span>
								</div>
							</template>
						</div>
						<div class="overview-tooltip-body">
							<template v-if="loaded">
								<div class="details-box">
									<color-badge
										:value="dataStore.getData('productTypes')[details.type]"
										small
									></color-badge>
									<color-badge
										color="light"
										icon="far fa-industry"
										small
										v-if="dataStore.getData('productManufacturers', true)[details.product_manufacturer_id] !== undefined"
										:content="dataStore.getData('productManufacturers', true)[details.product_manufacturer_id].name"
									></color-badge>
									<color-badge
										color="light"
										icon="far fa-folder-open"
										v-if="dataStore.getData('productCategories', true)[details.product_category_id] !== undefined"
										small
										:content="dataStore.getData('productCategories', true)[details.product_category_id].name"
									></color-badge>
									<color-badge
										:color="details.track_inventory ? 'success' : 'danger'"
										:icon="'far fa-' + (details.track_inventory ? 'check': 'times')"
										small
										content="Készlet követése"
									></color-badge>
									<color-badge
										v-if="details.type === 'material'"
										:color="details.requires_delivery ? 'success' : 'danger'"
										:icon="'far fa-' + (details.requires_delivery ? 'check': 'times')"
										small
										content="Kiszállítás"
									></color-badge>
									<color-badge
										v-if="details.allow_using_as_component"
										color="light"
										icon="far fa-cog"
										small
										content="Alkatrészként használható"
									></color-badge>
									<attribute
										v-if="details.manufacturer_code"
										label="Gyártói azonosító"
										icon="fas fa-hashtag"
										:content="details.manufacturer_code"
										/>
									<attribute
										v-if="details.warranty_months"
										label="Garancia ideje"
										icon="fas fa-badge-check"
										>
										<number :value="details.warranty_months" /> hónap
									</attribute>
									<attribute
										v-if="details.weight"
										icon="far fa-balance-scale"
										label="Tömeg"
										>
										<number :value="details.weight" /> kg
									</attribute>
									<attribute
										v-if="details.price"
										icon="far fa-coins"
										label="Beszerzési ár"
										>
										<price :value="details.price" :currency-id="details.currency_id" />
									</attribute>
									<attribute
										v-if="details.price_net"
										icon="far fa-coins"
										label="Aktuális eladási ár"
										>
										<price :value="details.price_net" />
									</attribute>
								</div>
							</template>
							<template v-else>
								<div class="p-2">
									<div class="placeholder-glow mb-2">
										<span class="placeholder font-size-16" :style="'width:51%'"></span>
									</div>
									<div class="placeholder-glow mb-2">
										<span class="placeholder font-size-16" :style="'width:32%'"></span>
									</div>
								</div>
							</template>
						</div>

						<template v-if="details.stock !== undefined">
							<div class="overview-tooltip-subtitle">
								<h5 class="font-size-15 m-0"><i class="far fa-inventory"></i> Készlet</h5>
							</div>
							<div class="overview-tooltip-body">
								<div class="details-box">
									<div v-for="(stock, warehouseId) in details.stock" :key="'productStock_' + details.id + '_' + warehouseId">
										<div class="d-flex flex-column align-items-start gap-1">
											<warehouse-badge :warehouse-id="parseInt(warehouseId)" />
											<div class="d-flex gap-2">
												<strong :class="{'text-success': stock.available > 0, 'text-danger': stock.available < 1}">{{ stock.available }}</strong>
												<strong :class="{'text-warning': stock.physical - stock.available > 0}">{{ stock.physical - stock.available }}</strong>
												<strong>{{ stock.physical }}</strong>
												<span>(elérhető, lefoglalt, valós)</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</template>
			</VDropdown>
		</template>
		<div class="vertical" v-else>
			<color-badge
				color="light"
				:text-color="isDeleted ? 'danger' : null"
				icon="far fa-boxes"
				:small="small"
			>
				<span class="colored-badge-content-wrap">{{ !productName ? 'Törölt termék' : productName }}</span>
			</color-badge>
			<div v-if="(productManufacturerId || productCategoryId || sku)" class="horizontal opacity-75">
						<span :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" v-if="sku">
							<i class="far fa-hashtag"></i> {{ sku }}
						</span>
				<span :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" v-if="productManufacturerId">
							<i class="far fa-industry-alt"></i> {{
						dataStore.getData('productManufacturers', true)[productManufacturerId].name
					}}
						</span>
				<span :class="{'colored-badge': 1, 'bg-light': !dark, 'bg-secondary': dark, 'colored-badge-sm': small, 'text-dark': !dark, 'text-white': dark}" v-if="productCategoryId">
							<i class="far fa-sitemap"></i> {{ dataStore.getData('productCategories', true)[productCategoryId].name }}
						</span>
			</div>
		</div>
	</data-loader>
</template>

<style scoped>

</style>
