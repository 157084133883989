<script setup>
import Vue3EasyDataTable from "vue3-easy-data-table";
import {useFlashStoreStore} from "../stores/flashStore";
import vSelect from "vue-select";
import {VueAwesomePaginate} from "vue-awesome-paginate";
import {useGeneralDataStore} from "../stores/generalData";
import {useApplicationStore} from "../stores/applicationStore";
import {usePermanentStore} from "../stores/permanentStore";
import {formatDate} from "../functions";
import DateTime from "./DateTime.vue";
import {LMap, LMarker, LPopup, LTileLayer} from "@vue-leaflet/vue-leaflet";
import ProjectOverviewTooltip from "../modules/projects/ProjectOverviewTooltip.vue";
import {router} from "@inertiajs/vue3";
import {onMounted, onUnmounted, ref, useTemplateRef, watch} from "vue";
import {computed} from "@vue/compat";

const emit = defineEmits(['dataLoaded', 'noResultsFound', 'resultsFound'])

const props = defineProps({
	dataUrl: String,
	columns: Array,
	sortBy: String,
	sortType: {
		type: String,
		default: 'asc'
	},
	name: String,
	disableSearch: Boolean,
	search: {
		type: Boolean,
		default: true
	},
	pagination: {
		type: Boolean,
		default: true
	},
	hasMapView: Boolean,
	compact: {
		type: Boolean,
		default: false
	},
	compactWithPagination: {
		type: Boolean,
		default: false
	},
	requiredData: {
		type: Array,
		default() {
			return []
		}
	},
	showTrackedEditMode: {
		type: Boolean,
		default: false
	},
	controlsInNavigation: {
		type: Boolean,
		default: false
	},
	rowClassCallback: {
		type: Function
	},
	setOptions: {
		type: Function
	},
	runAtInit: Function,
	mapMarkerColor: Function,
	mapMarkerIcon: Function,
	mapVisitUrl: String,
	defaultFilters: {
		type: Object,
		default: function(){
			return {}
		}
	}
})


const componentOptions = ref({
	search: '',
	visibleColumns: [],
	viewMode: 'table'
})
const dataTableOptions = ref({
	page: 1,
	rowsPerPage: 25,
	sortBy: props.sortBy || props.columns[0].name,
	sortType: props.sortType,
})
const trackedEditMode = ref({
	enabled: false,
	enabledAt: null,
})
_.merge(dataTableOptions.value, props.defaultFilters)
const store = usePermanentStore()

let stored = store.get(props.name + 'List', {})
if (stored.dataTableOptions !== undefined){
	_.forEach(stored.dataTableOptions, (val, key) => {
		dataTableOptions.value[key] = val
	})
	_.forEach(stored.componentOptions, (val, key) => {
		componentOptions.value[key] = val
	})
}
if (props.setOptions){
	let override = props.setOptions()

	_.merge(componentOptions.value, override)
}
if (stored.trackedEditMode !== undefined){
	trackedEditMode.value = stored.trackedEditMode
}

if (!props.showTrackedEditMode){
	trackedEditMode.value.enabled = false
}

const columnDetails = ref({})
for (let i = 0; i < props.columns.length; i++){
	columnDetails.value[props.columns[i].value] = props.columns[i]
}
const flashStore = useFlashStoreStore()
const dataStore = useGeneralDataStore()
const items = ref([])
const serverItemsLength = ref(0)
const defaultLatitude = 47.1611615
const defaultLongitude = 19.5057541
const mapCenter = ref([])
const visible = ref(true)
const debounceTimer = ref(null)
const cancelToken = ref(null)
const mobileSearchVisible = ref(false)
const activeMarker = ref(null)
const mapObject = ref(null)
const showMapScrollMessage = ref(false)
const showMapScrollMessageTimeout = ref(null)
const dataTable = useTemplateRef('dataTable')
const currentPageValue = ref(dataTableOptions.value.page)
const rowsPerPage = ref(dataTableOptions.value.rowsPerPage)
let scrollContainer = null
let scrollContainerWrapper = null
const resizeObserver = new ResizeObserver(updateShadows);

const visibleColumns = computed(() => {
	let payload = []
	if (componentOptions.value.visibleColumns === undefined){
		componentOptions.value.visibleColumns = []
	}
	let showAll = componentOptions.value.visibleColumns.length < 1

	_.forEach(props.columns, (column) => {
		if (showAll || componentOptions.value.visibleColumns.indexOf(column.value) > -1 || column.value === 'operations'){
			payload.push(column)
		}
	})

	return payload
})

const rowsPerPageOptions = computed(() => {
	let options = []
	if (dataTable.value) {
		_.forEach(dataTable.value.rowsPerPageOptions, (size) => {
			options.push({id: size, label: size + ' / oldal'})
		})
	}
	return options
})

const columnCount = computed(() => {
	return props.columns.length - 1
})

const hasPagination = computed(() => {
	return props.compactWithPagination || (!props.compact && props.pagination)
})

const hasSearch = computed(() => {
	return !props.compact && props.search
})

const currentPageFirstIndex = computed(() => {
	return (currentPageValue.value - 1) * rowsPerPageActiveOption.value
})

const currentPageLastIndex = computed(() => {
	return Math.min(((currentPageValue.value * rowsPerPageActiveOption.value) - 1), serverItemsLength.value)
})

const viewMode = computed({
	get() {
		return componentOptions.value.viewMode !== undefined ? componentOptions.value.viewMode : 'table'
	},
	set(value) {
		componentOptions.value.viewMode = value
	}
})

function onMouseWheel(e){
	if (viewMode.value === 'map') {
		if (showMapScrollMessageTimeout.value !== null) {
			clearTimeout(showMapScrollMessageTimeout.value)
			showMapScrollMessageTimeout.value = null
			showMapScrollMessage.value = false
		}
		if (e.target.closest(".map-wrapper") !== null) {
			activeMarker.value = null
			if (e.metaKey || e.ctrlKey) {
				e.preventDefault();
				mapObject.value.scrollWheelZoom.enable()
			} else {
				mapObject.value.scrollWheelZoom.disable()
				showMapScrollMessage.value = true
				showMapScrollMessageTimeout.value = setTimeout(() => {
					showMapScrollMessage.value = false
					showMapScrollMessageTimeout.value = null
				}, 2000)
			}
		} else {
			mapObject.value.scrollWheelZoom.disable()
		}
	}
}

onMounted(async () => {
	if (props.requiredData.length){
		visible.value = false
		dataStore.loadData(props.requiredData, () => {
			visible.value = true
		})
	}

	window.addEventListener('wheel', onMouseWheel, { passive: false });

	if (props.runAtInit !== undefined){
		props.runAtInit()
	}
	if (viewMode.value === 'map'){
		initMap()
	}

	scrollContainer = document.querySelector('#' + props.name + 'TableWrapper .vue3-easy-data-table__main')

	updateShadows();

	scrollContainer.addEventListener('scroll', updateShadows);

	resizeObserver.observe(scrollContainer.parentElement);

	setTimeout(() => {
		getData()
	}, 1)
})

onUnmounted(() => {
	window.removeEventListener('wheel', onMouseWheel)
	scrollContainer.removeEventListener('scroll', updateShadows)
	resizeObserver.disconnect()
})

watch(rowsPerPage, async (newValue, oldValue) => {
	if (dataTable.value) {
		currentPageValue.value = 1
		dataTable.value.updateRowsPerPageActiveOption(newValue)
	}
})

watch(currentPageValue, async (newValue, oldValue) => {
	if (dataTable.value) {
		dataTable.value.updatePage(newValue)
	}
})

watch(dataTableOptions, async (newValue, oldValue) => {
	getDataDebounced();
	store.set(props.name + 'List', {
		dataTableOptions: newValue,
		trackedEditMode: trackedEditMode.value,
		componentOptions: componentOptions.value
	});
})
watch(componentOptions.value, async (newValue, oldValue) => {
	getDataDebounced();
	store.set(props.name + 'List', {
		dataTableOptions: dataTableOptions.value,
		trackedEditMode: trackedEditMode.value,
		componentOptions: newValue
	});
})
watch(trackedEditMode, async (newValue, oldValue) => {
	getDataDebounced();
	store.set(props.name + 'List', {
		dataTableOptions: dataTableOptions.value,
		trackedEditMode: newValue,
		componentOptions: componentOptions.value
	});
})

defineExpose({
	componentOptions,
	dataTableOptions,
	getData,
	items
})

function getSlotName(colName){
	return 'item-' + colName.value
}
function getDataDebounced(){
	if (debounceTimer.value !== null){
		clearTimeout(debounceTimer.value)
		debounceTimer.value = null
	}
	debounceTimer.value = setTimeout(getData, 200)
}
function getData() {
	if (cancelToken.value !== undefined && cancelToken.value !== null && cancelToken.value.cancel !== undefined) {
		cancelToken.value.cancel("Operation canceled due to new request.")
	}

	cancelToken.value = axios.CancelToken.source()

	let params = _.merge(_.clone(dataTableOptions.value), _.clone(componentOptions.value))
	params.pagination = hasPagination

	axios.get(props.dataUrl, {
		params: params,
		cancelToken: cancelToken.value.token
	}).then((response)=>{
		if (response.data.meta.last_page < response.data.meta.current_page){
			dataTableOptions.value.page = response.data.meta.last_page
			getData()

			return
		}
		items.value = response.data.data
		serverItemsLength.value = response.data.meta.total

		emit('dataLoaded', response.data)
		if (serverItemsLength.value < 1){
			emit('noResultsFound')
		} else {
			emit('resultsFound')
		}
	}).catch((error) => {
		if (error.request !== undefined && error.request.status === 401){
			window.location.href = '/login?returnTo=' + window.location.href
		}
		if (error.code === undefined || error.code === 'ERR_CANCELED'){
			flashStore.addError(error)
		}
	})
}
function deleteButtonPressed(id, url){
	axios.get(url, {}).then((response)=>{
		if (response.data.flash !== undefined) {
			flashStore.addFlash(response.data.flash)
		}
		if (response.data.reloadDataStore !== undefined){
			dataStore.loadData(response.data.reloadDataStore, false, true)
		}
		if (response.data.clearTooltip !== undefined){
			_.forEach(response.data.clearTooltip, (type) => {
				dataStore.clearTooltipData(type)
			})
		}

		getData()
	}).catch((error) => {
		flashStore.addError(error)
	})
}
function columnVisible(column){
	if (componentOptions.value.visibleColumns === undefined || componentOptions.value.visibleColumns.length < 1){
		return true
	}
	for (let i = 0; i < componentOptions.value.visibleColumns.length; i++){
		if (componentOptions.value.visibleColumns[i] === column.value){
			return true
		}
	}

	return false
}
function toggleColumn(column){
	if (componentOptions.value.visibleColumns.length < 1){
		showAllColumn()
	}
	if (columnVisible(column)){
		_.pull(componentOptions.value.visibleColumns, column.value)
	} else {
		componentOptions.value.visibleColumns.push(column.value)
	}
}
function showAllColumn(){
	let tmp = []
	for (let i = 0; i < props.columns.length - 1; i++){
		tmp.push(props.columns[i].value)
	}
	componentOptions.value.visibleColumns = tmp
}
function getHeaderItemClass(colDetails){
	let classes = []
	if (colDetails.align !== undefined && colDetails.align === 'right'){
		classes.push('text-end')
	}
	if (colDetails.align !== undefined && colDetails.align === 'center'){
		classes.push('text-center')
	}

	return classes
}
function getCellClass(colName){
	let classes = []
	if (colName === 'operations'){
		classes.push('operations-cell')
	}
	if (columnDetails.value[colName] !== undefined) {
		if (columnDetails.value[colName].align !== undefined && columnDetails.value[colName].align === 'right') {
			classes.push('text-end')
		}
		if (columnDetails.value[colName].align !== undefined && columnDetails.value[colName].align === 'center') {
			classes.push('text-center')
		}
		if (columnDetails.value[colName].wrap !== undefined && !columnDetails.value[colName].wrap) {
			classes.push('text-nowrap')
		}
	}

	return classes
}
function getRowClass(item, rowNumber){
	let classes = []
	if (props.rowClassCallback !== undefined){
		classes = props.rowClassCallback(item, rowNumber)
	}
	return classes
}
function toggleTrackedEditMode(){
	trackedEditMode.value.enabled = !trackedEditMode.value.enabled
	if (trackedEditMode.value.enabled){
		trackedEditMode.value.enabledAt = formatDate(new Date(), true)
	} else {
		trackedEditMode.value.enabledAt = null
	}
}
function initMap(){
	mapCenter.value = [defaultLatitude, defaultLongitude]
}
function toggleViewMode(){
	viewMode.value = viewMode.value === 'table' ? 'map' : 'table'

	if (viewMode.value === 'map'){
		initMap()
	}

	items.value = []

	getData()
}
function getIcon(item){
	let icon = ''
	if (props.mapMarkerIcon !== undefined){
		icon = props.mapMarkerIcon(item)
	}
	let color = item.color
	if (props.mapMarkerColor !== undefined){
		color = props.mapMarkerColor(item)
	}
	let circleBg = '#fff'
	let iconColor = '#495057'
	if (item.id === activeMarker.value){
		iconColor = '#fff'
		circleBg = '#495057'
	}
	return L.divIcon({
		iconSize: [40, 60],
		iconAnchor: [20, 60],
		className: 'customIcon',
		html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="60" width="40">
				  <g transform="translate(-814.59595,-274.38623)">
					<g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
					  <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="fill:${color};stroke:#74788d;stroke-width: 0.5px;"/>
					  <circle r="6" cy="288.25278" cx="823.03064" id="path3049" style="fill:${circleBg}"/>
					</g>
				  </g>
				  	<g transform="translate(10,10)" style="transform-box: fill-box;">
						<g transform="scale(0.015)" style="fill:${iconColor};">
						${icon}
					  </g>
				  </g>
				</svg>`
	});
}
function setActiveMarker(item, event){
	if (activeMarker.value === item.id){
		activeMarker.value = null
	} else {
		mapObject.value.panTo([item.lat, item.lng], {animate: false})
		setTimeout(() => {
			activeMarker.value = item.id
		}, 100)
	}
}
function mapUpdated(){
	activeMarker.value = null
}
function mapMousedown(e){
	if (e.originalEvent.target.classList.contains('leaflet-container')){
		activeMarker.value = null
	}
}
function onScroll(e){
	//console.log(e)
}
function mapReady(mapObject){
	mapObject.value = mapObject
}
function updateShadows() {
	const scrollLeft = scrollContainer.scrollLeft;
	const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

	if (scrollContainer.scrollWidth <= scrollContainer.clientWidth) {
		scrollContainer.parentElement.classList.remove('shadow-left');
		scrollContainer.parentElement.classList.remove('shadow-right');
	} else {
		scrollContainer.parentElement.classList.toggle('shadow-left', scrollLeft > 0);
		scrollContainer.parentElement.classList.toggle('shadow-right', scrollLeft < maxScrollLeft)
	}
}
</script>

<template>
	<div :class="{'data-table-compact': compact}" v-if="visible">
		<div class="card data-table-navigation-card" v-if="$slots.navigation || !compact">
			<div class="card-body d-flex flex-column gap-2">
				<div v-if="$slots.navigation" class="d-flex flex-wrap gap-2">
					<slot name="navigation"/>
					<template v-if="controlsInNavigation">
						<div class="dropdown column-select-dropdown">
							<button type="button" class="btn btn-secondary text-nowrap" data-bs-toggle="dropdown" :id="'colToggleDropdown_' + name" aria-expanded="false"><i class="far fa-eye"></i> {{ visibleColumns.length - 1 }}/{{ columnCount }} <i class="far fa-angle-down"></i></button>
							<ul class="dropdown-menu" :aria-labelledby="'colToggleDropdown_' + name">
								<template v-for="column in columns">
									<li v-if="column.value !== 'operations'">
										<a :class="'dropdown-item' + (columnVisible(column) ? '' : ' text-muted')" href="#" @click.prevent="toggleColumn(column)">
											<i :class="'far fa-eye' + (columnVisible(column) ? '' : '-slash')"></i> {{ column.text }}
										</a>
									</li>
								</template>
								<li>
									<hr class="dropdown-divider" />
								</li>
								<li>
									<a class="dropdown-item" href="#" @click.prevent="showAllColumn">
										<i class="far fa-check"></i> Mindet mutat
									</a>
								</li>
							</ul>
						</div>

						<button type="button" class="btn btn-secondary text-nowrap reload-data-btn" @click="getData"><i class="far fa-sync"></i></button>
						<button type="button" class="btn btn-secondary text-nowrap" @click="toggleViewMode" v-if="hasMapView">
							<i :class="{
								'far': 1,
								'fa-globe-europe': componentOptions.viewMode === undefined || componentOptions.viewMode === 'table',
								'fa-table': componentOptions.viewMode !== undefined && componentOptions.viewMode === 'map',
							}"></i>
						</button>
						<button type="button" class="btn btn-secondary text-nowrap" @click="toggleTrackedEditMode" title="Szerkesztések követése" v-if="showTrackedEditMode"><i :class="{
							'far': !trackedEditMode.enabled, 'fas': trackedEditMode.enabled, 'fa-comment-alt-edit': 1
						}"></i></button>
						<button type="button" class="btn btn-secondary text-nowrap d-lg-none" v-if="$slots.filters" @click="mobileSearchVisible = !mobileSearchVisible"><i :class="{fal: mobileSearchVisible, fas: !mobileSearchVisible, 'fa-filter': 1}"></i></button>

						<div class="data-table-filter-item data-table-filter-item-fixed" v-if="!disableSearch">
							<div class="input-group">
								<input
									name="name"
									class="form-control"
									v-model="componentOptions.search"
									placeholder="Keresés"
								/>
								<span class="input-group-text clear-search" v-if="componentOptions.search" @click.prevent="componentOptions.search = ''"><i class="far fa-times"></i></span>
							</div>
						</div>

						<div v-if="showTrackedEditMode && trackedEditMode.enabled" class="fw-bold d-flex align-items-center">
							Módosítások követve <date-time :value="trackedEditMode.enabledAt"/> óta.
						</div>
					</template>
				</div>
				<div :class="{'data-table-filter-wrapper': 1, 'data-table-filter-wrapper-search-visible': mobileSearchVisible}" v-if="hasSearch">
					<template v-if="!controlsInNavigation">
						<div class="dropdown column-select-dropdown">
							<button type="button" class="btn btn-secondary text-nowrap" data-bs-toggle="dropdown" :id="'colToggleDropdown_' + name" aria-expanded="false"><i class="far fa-eye"></i> {{ visibleColumns.length - 1 }}/{{ columnCount }} <i class="far fa-angle-down"></i></button>
							<ul class="dropdown-menu" :aria-labelledby="'colToggleDropdown_' + name">
								<template v-for="column in columns">
									<li v-if="column.value !== 'operations'">
										<a :class="'dropdown-item' + (columnVisible(column) ? '' : ' text-muted')" href="#" @click.prevent="toggleColumn(column)">
											<i :class="'far fa-eye' + (columnVisible(column) ? '' : '-slash')"></i> {{ column.text }}
										</a>
									</li>
								</template>
								<li>
									<hr class="dropdown-divider" />
								</li>
								<li>
									<a class="dropdown-item" href="#" @click.prevent="showAllColumn">
										<i class="far fa-check"></i> Mindet mutat
									</a>
								</li>
							</ul>
						</div>

						<button type="button" class="btn btn-secondary text-nowrap reload-data-btn" @click="getData"><i class="far fa-sync"></i></button>
						<button type="button" class="btn btn-secondary text-nowrap" @click="toggleTrackedEditMode" title="Szerkesztések követése" v-if="showTrackedEditMode"><i :class="{
							'far': !trackedEditMode.enabled, 'fas': trackedEditMode.enabled, 'fa-comment-alt-edit': 1
						}"></i></button>
						<button type="button" class="btn btn-secondary text-nowrap d-lg-none" v-if="$slots.filters" @click="mobileSearchVisible = !mobileSearchVisible"><i :class="{fal: mobileSearchVisible, fas: !mobileSearchVisible, 'fa-filter': 1}"></i></button>

						<div class="data-table-filter-item data-table-filter-item-fixed" v-if="!disableSearch">
							<div class="input-group">
								<input
									name="name"
									class="form-control"
									v-model="componentOptions.search"
									placeholder="Keresés"
								/>
								<span class="input-group-text clear-search" v-if="componentOptions.search" @click.prevent="componentOptions.search = ''"><i class="far fa-times"></i></span>
							</div>
						</div>

						<div v-if="showTrackedEditMode && trackedEditMode.enabled" class="fw-bold d-flex align-items-center">
							Módosítások követve <date-time :value="trackedEditMode.enabledAt"/> óta.
						</div>
					</template>

					<slot name="filters" :server-options="componentOptions"></slot>
				</div>
			</div>
		</div>

		<div :class="{'card': !compact, 'data-table-table-card': 1}" v-if="viewMode === 'table'">
			<div :class="{'card-body': !compact}" :id="name + 'TableWrapper'">
				<Vue3EasyDataTable
					v-model:server-options="dataTableOptions"
					:server-items-length="serverItemsLength"
					buttons-pagination
					:headers="visibleColumns"
					:items="items"
					:must-sort="true"
					class="data-table-bootstrap"
					:alternating="false"
					:buttons-pagination="true"
					empty-message="Nincs találat"
					:body-row-class-name="getRowClass"
					:fixed-index="false"
					rows-per-page-message="sor oldalanként"
					rows-of-page-separator-message=", összesen"
					theme-color="#4cc1efff"
					ref="dataTable"
					:hide-footer="true"
					:header-item-class-name="getHeaderItemClass"
					:body-item-class-name="getCellClass"
				>
					<template v-for="(_, name) in $slots" #[name]="slotProps">
						<template v-if="name === 'item-operations'">
							<slot :name="name" :item="slotProps || {}" :trackedEditMode="trackedEditMode" :deleteButtonPressed="deleteButtonPressed"></slot>
						</template>
						<template v-else>
							<slot :name="name" v-bind="slotProps || {}"></slot>
						</template>
					</template>
				</Vue3EasyDataTable>
			</div>
		</div>

		<div class="card" v-if="viewMode === 'map'">
			<div class="card-body">
				<div style="height:800px" class="position-relative map-wrapper">
					<div class="map-scroll-message" v-if="showMapScrollMessage">
						<div>Használd a <strong>Ctrl + görgetést</strong> a nagyításhoz/kicsinyítéshez!</div>
					</div>
					<l-map ref="map"
						   :zoom="7"
						   :center="mapCenter"
						   :options="{scrollWheelZoom:false}"
						   v-if="mapCenter && mapCenter[0]"
						   @update:center="mapUpdated"
						   @mousedown="mapMousedown"
						   @ready="mapReady"
					>
						<div :style="'position:absolute;left:50%;top:50%;transform:translate3d(-50%, 0, 0px);z-index:10000;'" v-if="activeMarker">
							<ProjectOverviewTooltip
								:project-id="activeMarker"
								:key="'tooltip_' + activeMarker"
							/>
						</div>
						<template v-for="item in items" :key="'mapMarker_' + item.id">
							<l-marker
								v-if="item.lat"
								:lat-lng="[item.lat, item.lng]"
								:title="item.publicId"
								:icon="getIcon(item)"
								@click="setActiveMarker(item, $event)"
							>
							</l-marker>
						</template>
						<l-tile-layer
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							layer-type="base"
							name="OpenStreetMap"
						></l-tile-layer>
					</l-map>
				</div>
			</div>
		</div>
		<div :class="{'card': !compact, 'data-table-footer-card': 1}" v-if="viewMode === 'table' && hasPagination && dataTable !== undefined && dataTable !== null">
			<div :class="{'card-body': 1, 'pt-3': compact, 'px-0': compact, 'pb-0': compact}">
				<v-select
					v-model="rowsPerPage"
					:options="rowsPerPageOptions"
					:reduce="option => option.id"
					label="label"
					:multiple="false"
					:clearable="false"
				></v-select>
				<div>
					{{ (((currentPageValue - 1) * rowsPerPage) + 1) + ' - ' + (Math.min(((currentPageValue * rowsPerPage)), serverItemsLength)) + ', összesen ' + serverItemsLength }}
				</div>
				<vue-awesome-paginate
					:total-items="serverItemsLength"
					:items-per-page="rowsPerPage"
					:max-pages-shown="5"
					v-model="currentPageValue"
					>
					<template #prev-button>
						<i class="fas fa-angle-left"></i>
					</template>

					<template #next-button>
						<i class="fas fa-angle-right"></i>
					</template>
				</vue-awesome-paginate>
			</div>
		</div>
	</div>
</template>

<style scoped>
.map-scroll-message {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px;
	background: rgba(30,30,30,0.5);
	color: #fff;
	font-size: 22px;
	z-index: 33333;
}
</style>
