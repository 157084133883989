<template>
	<teleport to="body">
		<div :class="{'flash-wrapper': 1, 'visible': visible}">
			<template v-for="(item, key) in flashStore.flash">
				<div v-if="hidden.indexOf(key) < 0" :class="'alert alert-' + item.level" @click.prevent="clicked(key)">
					<div v-html="'<p>' + item.message + '</p>'"></div>
				</div>
			</template>
		</div>
	</teleport>
</template>

<script>
import {useFlashStoreStore} from "../stores/flashStore";
export default {
	props: {
		initial: Array
	},
	data() {
		return {
			flashStore: useFlashStoreStore(),
			visible: false,
			hideTimeout: null,
			flashDisplayed: false,
			hidden: []
		}
	},
	watch: {
		'flashStore.flash'(newValue, oldValue) {
			this.flashDisplayed = false
			this.update()
		}
	},
	mounted() {
		if (this.initial.length){
			this.flashStore.addFlash(this.initial)
		}
	},
	methods: {
		update: function(){
			if (!this.visible && this.flashStore.flash.length && !this.flashDisplayed){
				this.flashDisplayed = true
				setTimeout(() => {
					this.visible = true

				}, 50)

				if (this.hideTimeout){
					clearTimeout(this.hideTimeout)
				}
				this.hideTimeout = setTimeout(() => {
					this.visible = false
				}, 10000)
			}
			if (this.visible && !this.flashStore.flash.length){
				this.visible = false
			}
			this.hidden = []
		},
		clicked: function(index){
			this.visible = false
		}
	}
}
</script>

<style scoped>
.flash-wrapper {
	position: fixed;
	left: 50%;
	top: 0;
	transform: translate(-50%, -100%);
	z-index: 500000;
	padding: 30px;
	transition: all .6s ease;
}
.flash-wrapper.visible {
	opacity: 1;
	transform: translate(-50%, 0);
}
.flash-wrapper .alert,
.flash-wrapper-inline .alert {
	cursor: pointer;
}
</style>
