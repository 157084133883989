<template>
	<div class="vertical-menu">
		<div>
			<div id="sidebar-menu">
				<ul class="list-unstyled" id="side-menu" :style="'max-height:' + (app.modules.length * 45) + 'px;'">
					<template v-for="module in app.modules">
						<template v-if="module.children !== undefined">
							<li>
								<a href="#" class="has-arrow waves-effect" @click.prevent="toggleSubmenu(module.id)">
									<i :class="module.icon"></i>
									<span>{{ module.name }}</span>
									<span class="badge rounded-pill bg-primary float-end" v-if="app.badges[module.id] !== undefined && app.badges[module.id] > 0">{{ app.badges[module.id] }}</span>
								</a>
								<SlideUpDown :active="activeSubmenu === module.id" :duration="500">
									<ul class="sub-menu">
										<li v-for="child in module.children" :class="{'active' : route() && route().current() && route().current().startsWith(child.slug)}">
											<Link :href="route(child.slug + '.index')" @click="menuItemClicked">
												<i :class="child.icon"></i>
												<span>{{ child.name }}</span>
												<span class="badge rounded-pill bg-primary float-end" v-if="app.badges[child.id] !== undefined && app.badges[child.id] > 0">{{ app.badges[child.id] }}</span>
											</Link>
										</li>
									</ul>
								</SlideUpDown>
							</li>
						</template>
						<li :class="{'active' : route() && route().current() && route().current().startsWith(module.slug)}" v-else>
							<Link :href="route(module.slug + '.index')" @click="menuItemClicked">
								<i :class="module.icon"></i>
								<span>{{ module.name }}</span>
								<span class="badge rounded-pill bg-primary float-end" v-if="app.badges[module.id] !== undefined && app.badges[module.id] > 0">{{ app.badges[module.id] }}</span>
							</Link>
						</li>
					</template>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {Link, usePage, router } from "@inertiajs/vue3";
import SlideUpDown from "vue-slide-up-down";
import {useApplicationStore} from "../stores/applicationStore";
import {usePermanentStore} from "../stores/permanentStore";
export default {
	components: {Link, SlideUpDown},
	created() {
		this.app.onLoaded(this.setCurrentSubmenu)
	},
	data(){
		return {
			activeSubmenu: null,
			page: usePage(),
			app: useApplicationStore(),
			store: usePermanentStore()
		}
	},
	methods: {
		toggleSubmenu: function (id){
			if (this.activeSubmenu === null || this.activeSubmenu !== id){
				this.activeSubmenu = id
			} else {
				this.activeSubmenu = null
			}
		},
		setCurrentSubmenu: function (){
			_.forEach(this.app.modules, (module) => {
				if (module.children !== undefined){
					_.forEach(module.children, (child) => {
						if (route().current() === child.slug + '.index'){
							this.activeSubmenu = module.id
						}
					})
				}
			})
		},
		menuItemClicked: function (e){
			this.app.mobileMenuOpen = false
		}
	}
}
</script>

<style scoped>
.sub-menu {
	padding: 0;
}
.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
	max-height: 100px;
	overflow: hidden;
}

.slide-enter, .slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
