<template>
	<div>
		<div v-if="field.type === 'title'">
			<h4 class="card-title mb-0">
				{{ field.title }}
			</h4>
			<div v-if="field.description" class="mt-3">
				<div v-html="field.description_html"></div>
			</div>
		</div>
		<div class="project-attribute--wrapper" v-if="field.type !== 'title' && field.type !== 'table' && field.type !== 'address' && field.type !== 'multirow'">
			<div :class="{'project-attribute--label': 1, 'project-attribute--label-editable': componentEditable}" v-if="displayLabel">
				<label :class="{required: isRequired(field) && componentEditable}">
					{{ field.title }}
				</label>
				<div v-if="field.description && componentEditable" class="mt-3">
					<div v-html="field.description_html"></div>
				</div>
			</div>
			<div class="flex-grow-1">
				<div v-if="componentEditable">
					<div v-if="field.type === 'text'">
						<input-field
							:name="'projectAttribute_' + field.id + '_' + parentKey"
							:key="'projectAttribute_' + field.id + '_' + parentKey"
							:prefix="field.prefix"
							:suffix="field.suffix"
							v-model="componentValue.value"
							:label="field.title"
							:field-only="true"
							:rules="isRequired(field) ? 'required' : ''"
							:errors="errors"
							@input="onFieldChange"
							></input-field>
					</div>
					<div v-if="field.type === 'phone'">
						<phone-field
							:name="'projectAttribute_' + field.id + '_' + parentKey"
							:key="'projectAttribute_' + field.id + '_' + parentKey"
							prefix-icon="far fa-phone"
							v-model="componentValue.value"
							:label="field.title"
							:field-only="true"
							:rules="isRequired(field) ? 'required' : ''"
							:errors="errors"
							@input="onFieldChange"
							/>
					</div>
					<div v-if="field.type === 'textarea'">
						<textarea-field
							:name="'projectAttribute_' + field.id + '_' + parentKey"
							:key="'projectAttribute_' + field.id + '_' + parentKey"
							v-model="componentValue.value"
							:label="field.title"
							:field-only="true"
							:rules="isRequired(field) ? 'required' : ''"
							:errors="errors"
							@input="onFieldChange"
						></textarea-field>
					</div>
					<div v-if="field.type === 'bool' || field.type === 'copyButton'">
						<SwitchField
							v-model="componentValue.value"
							:label="field.title"
							:name="'projectAttribute_' + field.id + '_' + parentKey"
							:key="'projectAttribute_' + field.id + '_' + parentKey"
							:rules="isRequired(field) ? 'required' : ''"
							:errors="errors"
							:field-only="true"
							@input="onFieldChange"
						/>
					</div>
					<div v-if="field.type === 'number'">
						<input-field
							:name="'projectAttribute_' + field.id + '_' + parentKey"
							:key="'projectAttribute_' + field.id + '_' + parentKey"
							:prefix="field.prefix"
							:suffix="field.suffix"
							:min="field.min_value > 0 ? field.min_value : null"
							:max="field.max_value > 0 ? field.max_value : null"
							:step="field.step"
							v-model="componentValue.value"
							data-type="number"
							:label="field.title"
							:field-only="true"
							@input="onFieldChange"
							:rules="isRequired(field) ? 'required' : ''"
							:errors="errors"
						></input-field>
					</div>
					<div v-if="field.type === 'date'">
						<date-field
							:name="'projectAttribute_' + field.id + '_' + parentKey"
							:key="'projectAttribute_' + field.id + '_' + parentKey"
							v-model="componentValue.value"
							:label="field.title"
							:field-only="true"
							:rules="isRequired(field) ? 'required' : ''"
							:errors="errors"
							@input="onFieldChange"
							/>
					</div>
					<div v-if="field.type === 'select'">
						<div v-if="!field.alternative_display">
							<select-field
								:name="'projectAttribute_' + field.id + '_' + parentKey"
								:key="'projectAttribute_' + field.id + '_' + parentKey"
								v-model="componentValue.value"
								:options="field.options"
								label-field="value"
								:label="field.title"
								:field-only="true"
								:rules="isRequired(field) ? 'required' : ''"
								:errors="errors"
								@update:modelValue="onFieldChange"
								/>
						</div>
						<div v-else>
							<RadioField
								v-model="componentValue.value"
								:values="getRadioFieldOptions(field.options)"
								value-type="integer"
								:field-only="true"
								@input="onFieldChange"
								:name="'projectAttribute_' + field.id + '_' + parentKey"
								/>
						</div>
					</div>
				</div>
				<div v-if="!componentEditable">
					<div v-if="field.type === 'phone'">
						<phone-number :value="componentValue.value"></phone-number>
					</div>
					<div v-else v-html="componentValue.formatted_value"></div>
				</div>
			</div>
		</div>
		<div v-if="field.type === 'address'">
			<h4 :class="{'card-title': 1}">
				{{ field.title }}
			</h4>
			<div v-if="field.description" class="mb-3">
				<div v-html="field.description_html"></div>
			</div>

			<address-input
				v-model="componentValue"
				:required="isRequired(field)"
				:attribute-name="'projectAttribute_' + field.id"
				:errors="errors"
				:editable="editable"
			></address-input>
		</div>
		<div v-if="field.type === 'table'">
			<h4 class="card-title mb-0">
				{{ field.title }}
			</h4>
			<div v-if="field.description" class="mb-3">
				<div v-html="field.description_html"></div>
			</div>

			<table class="table">
				<thead>
				<tr>
					<th>&nbsp;</th>
					<th v-for="column in field.columns">{{ column.value }}</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="row in field.rows">
					<td>{{ row.value }}</td>
					<td v-for="column in field.columns">
						<div v-if="componentEditable">
							<input type="text" class="form-control" @input="onFieldChange" v-model="componentValue.value[column.id][row.id]" />
						</div>
						<div v-if="!componentEditable">
							{{ componentValue.value[column.id][row.id] }}
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div v-if="field.type === 'multirow'">
			<h4 class="card-title mb-0">
				{{ field.title }}
			</h4>
			<div v-if="field.description" class="mb-3">
				<div v-html="field.description_html"></div>
			</div>

			<div class="mt-3">
				<div v-for="(row, rowKey) in componentValue" class="row py-3 multirow-row" :key="'field_' + field.id + '_row_' + rowKey">
					<div class="row">
						<div class="col-md-1">
							<h4>{{ rowKey + 1 }}.</h4>
							<div class="mb-3" v-if="componentValue.length > 1 && componentEditable">
								<button type="button" class="btn btn-danger" @click.prevent="removeRow(rowKey)">
									<i class="far fa-times"></i>
								</button>
							</div>
						</div>
						<div class="col-md-11">
							<div class="row" v-if="field.children">
								<template v-for="(childField, childFieldKey) in field.children" :key="'field_' + field.id + '_row_' + rowKey + '_' + childFieldKey">
									<div class="col-md-6" v-if="visibleFields.indexOf(childField.id + '_' + rowKey) >= 0
									&& (!childField.private_only || (childField.private_only && !projectDataStore.client.is_company))
									&& (!childField.company_only || (childField.company_only && projectDataStore.client.is_company))">

										<div class="mb-2">
											<label :class="{required: isRequired(childField) && componentEditable}">
												{{ childField.title }}
											</label>

											<ProjectAttribute
												v-model="componentValue[rowKey][childField.id]"
												:field="childField"
												:field-key="childFieldKey"
												:parent-key="field.id + '_' + fieldKey + '_' + rowKey"
												:parent-order="field.order"
												:editable="editable"
												:display-label="false"
												:errors="errors"
												:ref="'field_' + field.id + '_' + rowKey + '_' + childField.id"
												:visible-fields="visibleFields"
												:client-input-required="clientInputRequired"
												v-if="childField.type === 'text' || childField.type === 'textarea' || childField.type === 'number' || childField.type === 'bool' || childField.type === 'select' || childField.type === 'date'"
												@value-changed="onFieldChange"
											></ProjectAttribute>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>

				<div class="mt-3" v-if="componentEditable">
					<button type="button" class="btn btn-secondary btn-label" @click.prevent="addRow">
						<i class="far fa-plus label-icon"></i> Hozzáad
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ToggleButton from '../../components/ToggleButton.vue'
import DatePicker from 'vue-datepicker-next';
import moment from 'moment'
import vSelect from "vue-select";
import TextareaAutosize from "../../components/TextareaAutosize.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import RadioField from "../../components/form/RadioField.vue";
import AddressGeocoder from "../../components/AddressGeocoder.vue";
import AddressInput from "../../components/AddressInput.vue";
import InputField from "../../components/form/InputField.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import DateField from "../../components/form/DateField.vue";
import SelectField from "../../components/form/SelectField.vue";
import {useProjectDataStore} from "../../stores/projectData";
import PhoneField from "../../components/form/PhoneField.vue";
import PhoneNumber from "../../components/PhoneNumber.vue";
import AddressBadge from "../../components/badges/AddressBadge.vue";

export default {

	emits: ['update:modelValue', 'approveAnswer', 'valueChanged', 'revokeAnswerApproval'],
	props: [
		'modelValue',
		'field',
		'fieldKey',
		'parentOrder',
		'parentKey',
		'editable',
		'displayLabel',
		'visibleFields',
		'userType',
		'errors',
		'clientInputRequired'
	],
	components: {
		AddressBadge,
		PhoneNumber,
		PhoneField,
		SelectField,
		DateField,
		TextareaField,
		InputField,
		AddressInput,
		AddressGeocoder,
		ToggleButton,
		DatePicker,
		vSelect,
		TextareaAutosize,
		SwitchField,
		RadioField,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
		componentEditable: {
			get() {
				return this.editable
			}
		},
		fieldKeyFormatted: {
			get() {
				return this.field.field_number
			}
		},
	},
	data(){
		return {
			isInvalid: false,
			projectDataStore: useProjectDataStore(),
			addressPartsValid: {
				city_id: true,
				street: true,
				house_number: true,
			},
			validationChecked: false
		};
	},
	created: function () {
		this.moment = moment;
	},
	mounted: function(){
	},
	methods: {
		onFieldChange: function(e){
			this.$emit('valueChanged')
		},
		isRequired: function(field) {
			if (field.type === 'copyButton' || field.type === 'title'){
				return false
			}
			if (field.required){
				return this.clientInputRequired && field.client_fillable ? (this.userType === 'client') : true
			}

			return false
		},
		isValid: function(){
			if (!this.isRequired(this.field) || this.field.type === 'title' || this.field.type === 'multirow'){
				return true
			}
			if (this.field.type === 'select'){
				if (this.field.multiple){
					if (this.componentValue.value.length){
						return true
					}
				} else {
					if (this.componentValue.value){
						return true
					}
				}

				return false
			} else if (this.field.type !== 'table'){
				return !!this.componentValue.value;
			}
			if (this.field.rows !== undefined) {
				for (const [rowId, row] of Object.entries(this.field.rows)) {
					for (const [columnId, column] of Object.entries(this.field.columns)) {
						if (this.componentValue.value[column.id][row.id] === undefined || !this.componentValue.value[column.id][row.id]) {
							return false
						}

					}
				}
			}

			return true
		},
		addRow: function(){
			this.componentValue.push(JSON.parse(JSON.stringify(this.field.empty_row)))
			this.$emit('valueChanged')
		},
		removeRow: function(rowIndex){
			if (this.componentValue.length > 1){
				this.componentValue.splice(rowIndex, 1)
			}
		},
		isNumber: function(e){
			let keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			if (this.field.step < 1){
				keysAllowed.push('.')
			}
			const keyPressed = e.key;

			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},
		getRadioFieldOptions: function(options){
			let payload = {}
			_.forEach(options, function(value){
				payload[value.id] = {
					label: value.value
				}
			})
			return payload
		}
	}
}
</script>

<style scoped>
	i .sup {
		vertical-align: top;
		font-size: 6px;
		color: var(--bs-danger);
	}
	.multirow-row {
		border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
	}
	.multirow-row:first-child {
		border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
	}

</style>
