<template>
	<TitleBar :title="pageTitle" />

	<form-wrapper
		ref="formWrapper"
		store-url=""
		:back-url="route('inventory.shipments')"
		v-model="attributes"
		:disable-submit="!!attributes.closed_at"
		v-if="loaded"
	>
		<template #buttons="{editable}">
			<template v-if="!attributes.closed_at && attributes.editable && !hasInventoryInspection">
				<button type="button" class="btn btn-primary btn-label" @click.prevent="closeShipment"><i class="far fa-lock label-icon"></i> Lezárás</button>
			</template>
			<template v-if="attributes.closed_at && attributes.type === 'incoming' && app.hasPermission('InventoryController_createCorrection')">
				<Link :href="route('inventory.create.correction') + '?correction_type=incoming&inventory_shipment_id=' + attributes.id" class="btn btn-warning btn-label"><i class="far fa-edit label-icon"></i> Korrekció létrehozása</Link>
			</template>
			<template v-if="app.hasPermission('InventoryController_reopenShipment') && attributes.closed_at && ['delivery', 'return'].indexOf(attributes.type) >= 0">
				<Link :href="'/inventory/reopen/' + attributes.id" class="btn btn-warning btn-label"><i class="far fa-edit label-icon"></i> Újranyitás</Link>
			</template>
			<template v-if="!attributes.closed_at && (attributes.type === 'delivery' || attributes.type === 'return')">
				<template v-if="attributes.type === 'delivery' && Object.keys(reservedProducts).length">
					<div class="dropdown">
						<button type="button" class="btn btn-success btn-label" data-bs-toggle="dropdown" aria-expanded="false"><i class="far fa-scanner label-icon"></i> Beolvasás sorban</button>

						<div class="dropdown-menu" role="menu">
							<a class="dropdown-item" href="#" @click.prevent="startScanning(true)">
								Lefoglalt termékek
							</a>
							<a class="dropdown-item" href="#" @click.prevent="startScanning(false)">
								Összes termék
							</a>
						</div>
					</div>
				</template>
				<template v-if="attributes.type === 'return' && Object.keys(deliveredProducts).length">
					<button type="button" class="btn btn-success btn-label" @click.prevent="startScanning" data-bs-toggle="dropdown" aria-expanded="false"><i class="far fa-scanner label-icon"></i> Beolvasás sorban</button>
				</template>
			</template>
			<template v-if="attributes.file_id">
				<a :href="'/inventory/shipment/download/' + attributes.id" class="btn btn-warning btn-label" v-if="attributes.type === 'transfer' && (attributes.source_warehouse.mobile || attributes.destination_warehouse.mobile)"><i class="far fa-download label-icon"></i> Átadás-átvételi jegyzőkönyv letöltése</a>
				<a :href="'/inventory/shipment/download/' + attributes.id" class="btn btn-warning btn-label" v-else><i class="far fa-download label-icon"></i> Szállítólevél letöltése</a>
			</template>
		</template>
		<template #default="{activeTab, errors}">
			<div class="card">
				<div class="card-body">
					<div class="details-box mb-0">
						<attribute icon="far fa-hashtag" label="Azonosító" :content="attributes.public_id"/>
						<attribute icon="far fa-tag" label="Típus">
							<data-loader :data="['inventoryShipmentTypes']" v-slot="{ dataStore }">
								<span :class="'badge badge-xl bg-' + dataStore.getData('inventoryShipmentTypes')[attributes.type].color">
									<i :class="dataStore.getData('inventoryShipmentTypes')[attributes.type].icon"></i> {{ dataStore.getData('inventoryShipmentTypes')[attributes.type].name }}
								</span>
							</data-loader>
						</attribute>
						<attribute icon="far fa-hashtag" label="Külső azonosító" :content="attributes.foreign_id" v-if="attributes.foreign_id"/>
						<updated-attribute :data="attributes" created/>

						<template v-if="attributes.type === 'correction'">
							<warehouse-attribute :warehouse-id="attributes.source_warehouse_id" v-if="attributes.source_warehouse_id" />
							<attribute icon="far fa-truck" label="Szállítmány" v-if="attributes.inventory_shipment">
								<Link :href="route('inventory.shipment') + '/' + attributes.inventory_shipment_id">{{ attributes.inventory_shipment.public_id }}</Link>
							</attribute>
							<attribute icon="far fa-tag" label="Korrekció típus">
								<data-loader :data="['inventoryCorrectionTypes']" v-slot="{ dataStore }">
									<span :class="'badge badge-xl bg-' + dataStore.getData('inventoryCorrectionTypes')[attributes.correction_type].color">
										<i :class="dataStore.getData('inventoryCorrectionTypes')[attributes.correction_type].icon"></i> {{ dataStore.getData('inventoryCorrectionTypes')[attributes.correction_type].name }}
									</span>
								</data-loader>
							</attribute>
						</template>
						<template v-else>
							<template v-if="attributes.type === 'return'">
								<attribute icon="far fa-truck" label="Eredeti szállítmány" v-if="!attributes.contract_id && attributes.inventory_shipment">
									{{ attributes.inventory_shipment.public_id + ' (' + attributes.inventory_shipment.destination_name + ')' }}
								</attribute>
							</template>
							<attribute icon="far fa-truck" label="Beszállító" v-if="attributes.supplier_id">
								<supplier-badge :supplier-id="attributes.supplier_id" small />
							</attribute>
							<warehouse-attribute :warehouse-id="attributes.source_warehouse_id" :source="attributes.type !== 'inspection'" v-if="attributes.source_warehouse_id" />
							<warehouse-attribute :warehouse-id="attributes.destination_warehouse_id" destination v-if="attributes.destination_warehouse_id" />
							<attribute icon="far fa-dolly" label="Címzett" v-if="attributes.destination_name && attributes.destination_address">
								{{ attributes.destination_name + ' (' + attributes.destination_address + ')' }}
							</attribute>
							<attribute icon="far fa-truck-loading" label="Fuvar" v-if="deliveryRoute !== undefined && deliveryRoute">
								<Link :href="route('deliveryRoutes.update', deliveryRoute.id)">{{ deliveryRoute.public_id }}</Link>
							</attribute>
							<address-attribute :address="projectDetails.deploy_address" v-if="projectDetails !== undefined && projectDetails && projectDetails.deploy_address" />
							<contract-id-attribute
								:project-id="projectDetails.id"
								:project-type-id="projectDetails.project_type_id"
								:public-id="contractDetails.public_id"
								:client-name="contractDetails.clientName"
								:contract-id="attributes.contract_id"
								v-if="projectDetails !== undefined && projectDetails && attributes.contract_id"
							/>

							<sale-attribute
								v-if="sale"
								v-model="sale.id"
								:public-id="sale.public_id"
								:client-name="sale.clientName"
								/>
						</template>
						<attribute icon="far fa-lock" label="Lezárva">
							<color-badge
								:icon="attributes.closed_at ? 'far fa-check' : 'far fa-hourglass'"
								:color="attributes.closed_at ? 'success' : 'warning'"
								small
								>
								<date-time v-if="attributes.closed_at" :value="attributes.closed_at"/>
								<template v-else>
									Nincs lezárva
								</template>
							</color-badge>
						</attribute>

						<div v-if="attributes.closed_at && attributes.comment_html" class="full-width text">
							<i class="far fa-comment"></i> Megjegyzés:
							<div v-html="attributes.comment_html"></div>
						</div>
					</div>

					<div v-if="!attributes.closed_at && hasInventoryInspection" class="alert alert-danger mt-3 mb-0">
						<h4>Leltár</h4>
						<p>A szállítmányhoz tartozó raktár leltár alatt áll, így nem módosítható a készlete a leltár végéig.</p>
					</div>

					<div v-if="(attributes.closed_at && app.hasPermission('InventoryController_attachDocument')) || (attributes.files && attributes.files.length)">
						<h4 class="card-title mt-3 mb-3">Csatolt fileok</h4>

						<div v-if="attributes.closed_at && app.hasPermission('InventoryController_attachDocument')">
							<FileUploader
								field-name="documents"
								v-model="attributes.documents"
								file-type="document"
								:editable="true"
								:allow-remove="false"
								disable-no-file-message
								:call-url-after-upload="'/inventory/shipment/attachDocument/' + attributes.id"
							></FileUploader>
						</div>
						<div v-if="attributes.files && attributes.files.length" class="mt-3">
							<FileList :files="attributes.files" />
						</div>
					</div>
				</div>
			</div>
			<div class="card" v-if="!attributes.closed_at">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6" v-if="attributes.type === 'delivery' && !attributes.skip_delivery">
							<date-field
								label="Kiszállítás dátuma"
								name="foreign_id"
								v-model="attributes.shipment_date"
								:errors="errors"
								info="Akkor kell megadni, amikor nem a szállítólevél készítésének napján történik a szállítás, így a megrendelő a megfelelő dátumot kapja értesítésben."
								future
								no-margin
							/>
						</div>
						<div :class="'col-md-' + (attributes.type === 'delivery' && !attributes.skip_delivery ? 6 : 12)">
							<TextareaField
								name="comment"
								v-model="attributes.comment"
								label="Megjegyzés"
								no-margin
								field-only
							></TextareaField>
						</div>
					</div>
				</div>
			</div>
			<div class="alert alert-success" v-if="sequentialScanningCompleted">
				<i class="far fa-check"></i> Az összes szükséges termék beolvasva!
			</div>
			<template v-if="canEdit">
				<div class="row">
					<div :class="'col-md-' + (sidebarMode ? 6 : 12)">
						<div class="row">
							<div :class="'col-md-' + (sidebarMode ? 12 : 6)">
								<div class="card">
									<div class="card-body">
										<div class="mb-4 d-flex align-items-center">
											<h4 class="card-title">Termék kiválasztása</h4>
											<button type="button" class="btn btn-secondary btn-sm ms-auto" @click.prevent="openAddProductForm"><i class="far fa-plus"></i> Termék hozzáadása</button>
										</div>

										<v-select
											:options="products"
											v-model="selectedProductId"
											:reduce="item => item.id"
											label="name"
											:filter-by="productFilter"
											v-on:option:selected="onProductSelected"
											:clearable="true"
											placeholder="Kattints ide"
										>
											<template v-slot:option="option">
												<product-badge
													:product-id="option.id"
													:product-name="option.name"
													:product-manufacturer-id="option.product_manufacturer_id"
													:product-category-id="option.product_category_id"
													:sku="option.sku"
													disable-link />
											</template>
											<template v-slot:selected-option="option">
												<product-badge
													:product-id="option.id"
													:product-name="option.name"
													:product-manufacturer-id="option.product_manufacturer_id"
													:product-category-id="option.product_category_id"
													:sku="option.sku"
													disable-link />
											</template>
										</v-select>

										<div v-if="barcodeDownloadUrl && selectedProductId" class="mt-2 text-center">
											<button type="button" class="btn btn-secondary btn-label" @click.prevent="printBarcode"><i class="far fa-print label-icon"></i> Vonalkód nyomtatása</button>
										</div>
									</div>
								</div>
							</div>
							<div :class="'col-md-' + (sidebarMode ? 12 : 6)">
								<div class="card">
									<div class="card-body">
										<h4 class="card-title mb-4">{{ selectedProductId && productsById[selectedProductId].track_serial_number ? 'Sorozatszám megadása' : 'Vonalkód megadása' }}</h4>

										<form v-on:submit.prevent="barcodeSubmit">
											<input-field
												name="barcode"
												:label="selectedProductId && productsById[selectedProductId].track_serial_number ? 'Sorozatszám' : 'Vonalkód'"
												v-model="barcode"
												:field-only="true"
												id="barcodeInput"
												ref="barcodeInput"
												:show-placeholder="true"
											/>
										</form>

										<template v-if="selectedProductId">
											<div class="mt-3 d-flex justify-content-center">
												<button class="btn btn-secondary btn-label " @click.prevent="selectedProductId = null">
													<i class="far fa-barcode-scan label-icon"></i> Új termék kiválasztása
												</button>
											</div>
										</template>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="card">
									<div class="card-body">
										<h4 class="card-title mb-4">Mennyiség</h4>
										<div class="row" v-if="selectedProductHasContainers">
											<div class="col-md-6">
												<data-loader :data="['productContainers']" v-slot="{ dataStore }">
													<table class="table">
														<thead>
														<tr>
															<th>Kiszerelés</th>
															<th>Mennyiség</th>
														</tr>
														</thead>
														<tbody>
														<template v-for="container in dataStore.getData('productContainers')">
															<tr v-if="productsById[selectedProductId].containers[container.id] !== undefined">
																<td>{{ container.name }}</td>
																<td><number :value="productsById[selectedProductId].containers[container.id]" :unit="productsById[selectedProductId].unit" /></td>
															</tr>
														</template>
														</tbody>
													</table>
												</data-loader>
											</div>
										</div>
										<div class="grid gap-3">
											<div :class="selectedProductHasContainers ? 'g-col-md-3 g-col-6' : 'g-col-6'">
												<input-field
													name="quantityInput"
													v-model="quantityInput"
													:field-only="true"
													data-type="number"
													:step="1"
													:min="1"
													:disabled="!selectedProductId || (!!productsById[selectedProductId].track_serial_number && barcode.length < 6)"
													:spinner-buttons="true"
												/>
											</div>
											<div class="g-col-md-3 g-col-6" v-if="selectedProductHasContainers">
												<data-loader :data="['productContainers']" v-slot="{ dataStore }">
													<v-select
														:options="selectedProductUsableContainers"
														v-model="selectedProductContainerId"
														:reduce="item => item.id"
														:clearable="false"
													>
														<template v-slot:option="option">
															{{ option.name }}
														</template>
														<template v-slot:selected-option="option">
															{{ option.name }}
														</template>
													</v-select>
												</data-loader>
											</div>
											<template v-if="canSubtract">
												<div class="g-col-6 grid gap-3">
													<div class="g-col-6">
														<button class="btn btn-success w-100 d-block" :disabled="!selectedProductId || (!!productsById[selectedProductId].track_serial_number && barcode.length < 6)" @click.prevent="sendScanRequest(true)"><i class="far fa-plus"></i> Hozzáad</button>
													</div>
													<div class="g-col-6">
														<button class="btn btn-danger w-100 d-block" :disabled="!selectedProductId || (!!productsById[selectedProductId].track_serial_number && barcode.length < 6)" @click.prevent="sendScanRequest(false)"><i class="far fa-minus"></i> Kivesz</button>
													</div>
												</div>
											</template>
											<div class="g-col-6" v-else>
												<button class="btn btn-success w-100 d-block" :disabled="!selectedProductId || (!!productsById[selectedProductId].track_serial_number && barcode.length < 6)" @click.prevent="sendScanRequest(true)"><i class="far fa-plus"></i> Hozzáad</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6" v-if="sidebarMode">
						<div class="card" v-if="inspectionProductsOrdered.length">
							<div class="card-body pb-1">
								<h4 class="card-title mb-3">{{ 'Visszalévő tételek' }}</h4>
								<div style="height: 500px;overflow-y: scroll">
									<template v-for="productDetails in inspectionProductsOrdered" :key="'inspProd_' + productDetails.id">
										<template v-if="productsById[productDetails.id].track_serial_number">
											<div
												:class="{'inventory-inspection-required-item': 1, 'opacity-50': selectedProductId !== productDetails.id}"
												@click.prevent="setSelectedProductId(productDetails.id)">
												<color-badge
													:color="getInspectionProductRemainingQuantity(productDetails) < 1 ? 'success' : 'danger'"
												>
													<number :value="getInspectionProductRemainingQuantity(productDetails)" />
												</color-badge>

												<product-badge
													:product-id="parseInt(productDetails.id)"
													:product-name="productsById[productDetails.id].name"
													:product-manufacturer-id="productsById[productDetails.id].product_manufacturer_id"
													:sku="productsById[productDetails.id].sku"
												/>
												<div class="d-flex flex-wrap gap-1 w-100 serial-numbers">
													<template v-for="(serialNumber, index) in productDetails.serial_numbers" :key="'inspProd_' + serialNumber.serialNumber">
														<span :class="{'badge':1, 'bg-light': !serialNumber.scanned, 'bg-success': serialNumber.scanned, 'font-size-11': 1}" v-if="index < 3 || selectedProductId === productDetails.id">
															{{ serialNumber.serialNumber }}
														</span>
													</template>
												</div>
											</div>
										</template>
										<template v-else>
											<div
												:class="{'inventory-inspection-required-item': 1, 'opacity-50': selectedProductId !== productDetails.id}"
												v-if="productDetails.quantity !== getScanCount(productDetails.id)"
												@click.prevent="setSelectedProductId(productDetails.id)"
											>
												<color-badge
													:color="getInspectionProductRemainingQuantity(productDetails) < 0 ? 'info' : 'danger'"
													>
													<number :value="getInspectionProductRemainingQuantity(productDetails)" />
												</color-badge>

												<product-badge
													:product-id="parseInt(productDetails.id)"
													:product-name="productsById[productDetails.id].name"
													:product-manufacturer-id="productsById[productDetails.id].product_manufacturer_id"
													:sku="productsById[productDetails.id].sku"
												/>
											</div>
										</template>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-if="requiredProducts.length">
				<div class="card">
					<div class="card-body pb-1">
						<h4 class="card-title mb-3">{{ 'Szállítandó termékek' }}</h4>
						<table class="table table-collapse-mobile-auto mb-3">
							<thead>
							<tr>
								<th>Termékn</th>
								<th class="text-end">Mennyiség</th>
								<th class="text-end">Hozzáadva</th>
							</tr>
							</thead>
							<tbody>
							<template v-for="(item, itemKey) in requiredProducts">
								<tr v-if="this.productsById[item.product_id] !== undefined" :key="'requiredProduct_' + itemKey">
									<td>
										<product-badge
											:product-id="item.product_id"
											:product-name="getProductById(item.product_id) ? getProductById(item.product_id).name : null"
											:sku="getProductById(item.product_id) ? getProductById(item.product_id).sku : null"
											:product-category-id="getProductById(item.product_id) ? getProductById(item.product_id).product_category_id : null"
											:product-manufacturer-id="getProductById(item.product_id) ? getProductById(item.product_id).product_manufacturer_id : null"
											:key="'PB' + item.product_id"
										/>
									</td>

									<td class="text-end">
										<color-badge
											:color="'light'"
											label="Kiszállított">
											<number :value="getRequiredQuantity(item)"/>
										</color-badge>
									</td>
									<td class="text-end">
										<color-badge
											v-if="getScanCount(item.product_id)"
											:color="getScanCount(item.product_id) >= getRequiredQuantity(item) ? 'success' : 'warning'"
											label="Hozzáadva">
											<number :value="getScanCount(item.product_id)"/>
										</color-badge>
										<color-badge
											v-else
											color="light"
											label="Hozzáadva">
											<number :value="0"/>
										</color-badge>
									</td>
								</tr>
							</template>
							</tbody>
						</table>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="card" v-if="reservedProductsArray.length && !attributes.closed_at && attributes.type === 'delivery'">
					<div class="card-body pb-1">
						<h4 class="card-title mb-3">{{ 'Szállítandó termékek' }}</h4>
						<table class="table table-collapse-mobile-auto mb-3">
							<thead>
							<tr>
								<th>Termék</th>
								<th class="text-end">Kiszállítandó</th>
								<th class="text-end">Lefoglalva</th>
								<th class="text-end">Hozzáadva</th>
							</tr>
							</thead>
							<tbody>
							<template v-for="(item, itemKey) in reservedProductsArray">
								<tr v-if="this.productsById[item.productId] !== undefined" :key="'requiredProduct_' + item.productId" :class="{
									'divider-row': !item.asComponent && reservedProductsArray[itemKey + 1] !== undefined && reservedProductsArray[itemKey + 1].asComponent
								}">
									<td>
										<div class="vertical">
											<product-badge
												:product-id="item.productId"
												:product-name="getProductById(item.productId) ? getProductById(item.productId).name : null"
												:sku="getProductById(item.productId) ? getProductById(item.productId).sku : null"
												:product-category-id="getProductById(item.productId) ? getProductById(item.productId).product_category_id : null"
												:product-manufacturer-id="getProductById(item.productId) ? getProductById(item.productId).product_manufacturer_id : null"
												:key="'PB' + item.productId"
											/>
											<color-badge
												icon="fas fa-cog"
												color="dark"
												small
												v-if="item.asComponent"
											>
												Alkatrész
											</color-badge>
										</div>
									</td>
									<td class="text-end">
										<color-badge
											:color="item.quantity > item.delivered ? 'light' : 'success'"
											label="Kiszállítandó">
											<number :value="item.quantity - item.delivered"/>
										</color-badge>
									</td>
									<td class="text-end">
										<color-badge
											:color="item.reserved ? 'warning' : 'light'"
											label="Lefoglalva">
											<number :value="item.reserved"/>
										</color-badge>
									</td>
									<td class="text-end">
										<color-badge
											v-if="getScanCount(item.productId)"
											:color="getScanCount(item.productId) >= item.reserved ? 'success' : 'warning'"
											label="Hozzáadva">
											<number :value="getScanCount(item.productId)"/>
										</color-badge>
										<color-badge
											v-else
											color="light"
											label="Hozzáadva">
											<number :value="0"/>
										</color-badge>
									</td>
								</tr>
							</template>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card" v-if="Object.keys(deliveredProducts).length && !attributes.closed_at && attributes.type === 'return'">
					<div class="card-body pb-1">
						<h4 class="card-title mb-3">{{ 'Kiszállított termékek' }}</h4>
						<table class="table table-collapse-mobile-auto mb-3">
							<thead>
							<tr>
								<th>Termék</th>
								<th class="text-end">Kiszállított</th>
								<th class="text-end">Szállítandó</th>
								<th class="text-end">Hozzáadva</th>
							</tr>
							</thead>
							<tbody>
							<template v-for="(item, productId) in deliveredProducts">
								<tr v-if="this.productsById[productId] !== undefined" :key="'requiredProduct_' + productId">
									<td>
										<product-badge
											:product-id="productId"
											:product-name="getProductById(productId) ? getProductById(productId).name : null"
											:sku="getProductById(productId) ? getProductById(productId).sku : null"
											:product-category-id="getProductById(productId) ? getProductById(productId).product_category_id : null"
											:product-manufacturer-id="getProductById(productId) ? getProductById(productId).product_manufacturer_id : null"
											:key="'PB' + productId"
										/>
									</td>

									<td class="text-end">
										<color-badge
											:color="'light'"
											label="Kiszállított">
											<number :value="item.quantity"/>
										</color-badge>
									</td>
									<td class="text-end">
										<color-badge
											:color="item.to_return && item.to_return > getScanCount(productId) ? 'warning' : 'light'"
											label="Szállítandó">
											<number :value="item.to_return"/>
										</color-badge>
									</td>
									<td class="text-end">
										<color-badge
											v-if="getScanCount(productId)"
											:color="getScanCount(productId) >= item.to_return ? 'success' : 'warning'"
											label="Hozzáadva">
											<number :value="getScanCount(productId)"/>
										</color-badge>
										<color-badge
											v-else
											color="light"
											label="Hozzáadva">
											<number :value="0"/>
										</color-badge>
									</td>
								</tr>
							</template>
							</tbody>
						</table>
					</div>
				</div>
			</template>

			<div class="card">
				<div class="card-body pb-1">
					<h4 class="card-title mb-3">Termékek</h4>
					<div class="alert alert-info" v-if="!items.length">Még nincsenek termékek beolvasva.</div>

					<div class="mt-2 mb-2" v-if="productComments.length">
						<div class="card border border-primary">
							<div class="card-header bg-transparent border-primary">
								<h5 class="my-0 text-primary"><i class="far fa-exclamation-triangle"></i> Termék megjegyzések </h5>
							</div>
							<div class="card-body">
								<div class="d-flex flex-column gap-2">
									<div v-for="comment in productComments" :key="comment.id">
										<h5>{{ comment.name }}</h5>
										<div v-html="comment.comment"></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="items.length">
						<table class="table table-collapse-mobile-auto mb-3">
							<thead>
							<tr>
								<th>&nbsp;</th>
								<th>Termék</th>
								<th class="text-end">Mennyiség</th>
								<th class="text-end">Sorozatszám</th>
								<th class="text-end">Időpont</th>
								<th width="1%" v-if="canEdit">&nbsp;</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="item in items" :key="item.id">
								<td class="text-end">
									<color-badge
										color="dark"
										small
									>
										{{ item.counter }}/{{ item.counter_max }}
									</color-badge>
								</td>
								<td>
									<product-badge
										:product-id="item.product_id"
										:product-name="getProductById(item.product_id) ? getProductById(item.product_id).name : null"
										:sku="getProductById(item.product_id) ? getProductById(item.product_id).sku : null"
										:product-category-id="getProductById(item.product_id) ? getProductById(item.product_id).product_category_id : null"
										:product-manufacturer-id="getProductById(item.product_id) ? getProductById(item.product_id).product_manufacturer_id : null"
										:key="'PB' + item.product_id"
									/>
								</td>
								<td :class="{'button-cell': 1, 'text-end': 1, 'text-danger': canSubtract && item.quantity < 0, 'text-success': canSubtract && item.quantity > 0}">
									<div v-if="canSubtract" class="d-flex gap-2 align-items-center justify-content-end">
										<button type="button" class="btn btn-secondary btn-sm me-2" @click.prevent="toggleAddRemove(item)" v-if="canEdit">
											<i class="far fa-plus"></i> / <i class="far fa-minus"></i>
										</button>
										<div class="fw-bold">{{ item.quantity }}</div>
									</div>
									<template v-else>
										<color-badge
											color="primary"
											label="Mennyiség"
										>
											<number :value="item.quantity" />
										</color-badge>
										<div class="fw-bold"></div>
									</template>
								</td>
								<td class="text-end">
									<color-badge
										color="dark"
										icon="far fa-tags"
										v-if="item.serial_number"
										:content="item.serial_number"
									/>
								</td>
								<td class="text-end"><date-time :value="item.created_at"/></td>
								<td v-if="canEdit" class="button-cell"><button type="button" :class="'btn btn-danger btn-sm' + (item.deleteLoading !== undefined ? ' btn-loading' : '')" @click.prevent="removeItem(item)"><i class="far fa-times"></i></button></td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</template>
	</form-wrapper>

	<modal
		:title="'Ismeretlen vonalkód: ' + unknownBarcode"
		:closable="true"
		v-if="unknownBarcode"
		:z-index="2020"
		@close="closeUnknownBarcodeDialog">
		<template #default>
			<template v-if="!mapBarcodeModalAdd">
				<div class="alert alert-info">Kérlek válaszd ki, melyik termékhez tartozik a beolvasott vonalkód <strong>{{ unknownBarcode }}</strong>!</div>

				<product-select-field
					label="Termék kiválasztása"
					name="selectProduct"
					:type-filter="['material', 'consumable']"
					v-model="mapBarcodeToProduct"
				></product-select-field>

				<div class="mb-3" v-if="mapBarcodeToProduct">
					<label>Hozzárendelés</label>
					<div class="form-check mb-3">
						<input class="form-check-input" type="radio" value="product" id="mapProductTypeProduct" :disabled="productsById[mapBarcodeToProduct].barcode !== null" v-model="mapBarcodeToProductType">
						<label class="form-check-label" for="mapProductTypeProduct">
							Termék fő vonalkód <strong v-if="productsById[mapBarcodeToProduct].barcode !== null">({{ productsById[mapBarcodeToProduct].barcode }})</strong>
						</label>
					</div>

					<data-loader :data="['suppliers']" v-slot="{ dataStore }">
						<div class="form-check mb-3" v-for="supplier in dataStore.getData('suppliers')">
							<input class="form-check-input" type="radio" :value="supplier.id" :id="'mapProductType' + supplier.id" :disabled="getSupplierBarcodeForProduct(mapBarcodeToProduct, supplier.id)" v-model="mapBarcodeToProductType">
							<label class="form-check-label" :for="'mapProductType' + supplier.id">
								{{ supplier.name }} <strong v-if="getSupplierBarcodeForProduct(mapBarcodeToProduct, supplier.id)">({{ getSupplierBarcodeForProduct(mapBarcodeToProduct, supplier.id) }})</strong>
							</label>
						</div>
					</data-loader>
				</div>

				<p class="fw-bold text-center font-size-15">vagy</p>
				<div class="d-flex justify-content-center">
					<button type="button" class="btn btn-primary btn-label" @click.prevent="mapBarcodeModalAdd = true">
						<i class="far fa-plus label-icon"></i> Új termék hozzáadása
					</button>
				</div>
			</template>
			<template v-if="mapBarcodeModalAdd">
				<InventoryAddProductForm
					v-model="mapBarcodeModalNewModel"
					ref="mapBarcodeAddForm"
					@form-submitted="assignBarcodeToProduct"
				></InventoryAddProductForm>
			</template>
		</template>
		<template #buttons>
			<button type="button" class="btn btn-secondary btn-label" @click.prevent="closeUnknownBarcodeDialog"><i class="far fa-times label-icon"></i> Bezár</button>
			<button type="button" class="btn btn-primary btn-label" @click.prevent="assignBarcodeToProduct" :disabled="(!mapBarcodeToProduct || !mapBarcodeToProductType) && !mapBarcodeModalAdd"><i class="far fa-save label-icon"></i> Mentés</button>
		</template>
	</modal>
	<modal
		title="Termék hozzáadása"
		:closable="true"
		v-if="addProduct"
		:z-index="2020"
		@close="closeAddProductForm">

		<template #buttons>
			<button type="button" class="btn btn-primary btn-label" @click.prevent="newProductSubmit"><i class="far fa-save label-icon"></i> Hozzáadás</button>
			<button type="button" class="btn btn-secondary btn-label" @click.prevent="closeAddProductForm"><i class="far fa-times label-icon"></i> Bezár</button>
		</template>
		<template #default>
			<InventoryAddProductForm
				v-model="addProductModel"
				ref="addProductForm"
				@form-submitted="storeNewProduct"
			></InventoryAddProductForm>
		</template>
	</modal>
	<modal
		title="Termékek beolvasása"
		:closable="true"
		wide
		v-if="sequentialScanning && sequentialScanningProduct"
		@close="sequentialScanning = false">

		<template #default>
			<div class="d-flex gap-3 align-items-center flex-column">
				<h2 style="font-size: 50px;" class="text-center">{{ sequentialScanningProduct.product.name }}</h2>

				<color-badge
					:font-size="30"
					icon="fas fa-industry-alt"
					color="light"
					v-if="sequentialScanningProduct.product.manufacturer"
				>
					{{ sequentialScanningProduct.product.manufacturer }}
				</color-badge>

				<color-badge
					:font-size="30"
					icon="fas fa-hashtag"
					color="light"
				>
					{{ sequentialScanningProduct.product.sku }}
				</color-badge>

				<color-badge
					:font-size="22"
					icon="fas fa-cog"
					color="dark"
					small
					v-if="sequentialScanningProduct.details.asComponent"
				>
					Alkatrész
				</color-badge>

				<template v-if="requiredProducts.length">
					<p class="text-muted mb-0" style="font-size: 30px;">Szükséges mennyiség</p>
					<h1 class="m-0 text-primary fw-bold" style="font-size: 60px;">
						<number :value="(attributes.type === 'delivery' ? sequentialScanningProduct.details.quantity_to_deliver : sequentialScanningProduct.details.quantity_to_return)
						- getScanCount(sequentialScanningProduct.product.id)" :unit="sequentialScanningProduct.product.unit"/>
					</h1>
				</template>
				<template v-else>
					<template v-if="attributes.type === 'delivery'">
						<p class="text-muted mb-0" style="font-size: 30px;">Szükséges mennyiség</p>
						<h1 class="m-0 text-primary fw-bold" style="font-size: 60px;">
							<number
								v-if="sequentialScanningReservedOnly"
								:value="sequentialScanningProduct.details.reserved - getScanCount(sequentialScanningProduct.product.id)"
								:unit="sequentialScanningProduct.product.unit"
							/>
							<number
								v-else
								:value="sequentialScanningProduct.details.quantity - getScanCount(sequentialScanningProduct.product.id)"
								:unit="sequentialScanningProduct.product.unit"
							/>
						</h1>
					</template>
					<template v-if="attributes.type === 'return'">
						<p class="text-muted mb-0" style="font-size: 30px;">Szállítható mennyiség</p>
						<h1 class="m-0 text-primary fw-bold" style="font-size: 60px;">
							<number :value="sequentialScanningProduct.details.to_return - getScanCount(sequentialScanningProduct.product.id)" :unit="sequentialScanningProduct.product.unit"/>
						</h1>
					</template>
				</template>
				<div class="d-flex justify-content-center mt-2" v-if="sequentialScanningProduct.product.track_serial_number">
					<form v-on:submit.prevent="barcodeSubmit">
						<input-field
							name="barcodeAlt"
							label="Sorozatszám"
							v-model="barcode"
							:field-only="true"
							ref="barcodeInputAlt"
							:show-placeholder="true"
						/>
					</form>
				</div>
				<div class="d-flex justify-content-center gap-2 mt-2">
					<button
						type="button"
						class="btn btn-success btn-label"
						v-if="!sequentialScanningProduct.product.track_serial_number"
						@click.prevent="setProductAsScanned(sequentialScanningProduct)">
						<i class="far fa-check label-icon"></i> Csomagolva
					</button>
					<button
						type="button"
						class="btn btn-secondary btn-label"
						@click.prevent="skipSequentialProduct(sequentialScanningProduct)">
						<i class="far fa-forward label-icon"></i> Tétel átugrása
					</button>
				</div>
			</div>
		</template>
	</modal>
</template>

<script>
import ProductSelectField from "../components/form/ProductSelectField.vue";
import InputField from "../components/form/InputField.vue";
import DataLoader from "../components/DataLoader.vue";
import InventoryAddProductForm from "../modules/inventory/InventoryAddProductForm.vue";
import vSelect from "vue-select";
import UserBadge from "../components/badges/UserBadge.vue";
import FileList from "../components/FileList.vue";
import AppLayout from "../layouts/AppLayout.vue";
import {useGeneralDataStore} from "../stores/generalData";
import {useFlashStoreStore} from "../stores/flashStore";
import printJS from "print-js";
import {Link} from "@inertiajs/vue3";
import InventoryShipmentForm from "../modules/inventory/InventoryShipmentForm.vue";
import {useApplicationStore} from "../stores/applicationStore";
import Number from "../components/Number.vue";
import DateTime from "../components/DateTime.vue";
import TitleBar from "../components/TitleBar.vue";
import ColorBadge from "../components/ColorBadge.vue";
import FormWrapper from "../components/FormWrapper.vue";
import ButtonGroup from "../components/ButtonGroup.vue";
import Modal from "../components/Modal.vue";
import Attribute from "../components/Attribute.vue";
import FileUploader from "../components/FileUploader.vue";
import {objectToArray} from "../functions";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import WarehouseBadge from "../components/badges/WarehouseBadge.vue";
import SupplierBadge from "../components/badges/SupplierBadge.vue";
import ProductBadge from "../components/badges/ProductBadge.vue";
import ProjectIdAttribute from "../components/attributes/ProjectIdAttribute.vue";
import ContractIdAttribute from "../components/attributes/ContractIdAttribute.vue";
import AddressBadge from "../components/badges/AddressBadge.vue";
import AddressAttribute from "../components/attributes/AddressAttribute.vue";
import DateField from "../components/form/DateField.vue";
import TextareaField from "../components/form/TextareaField.vue";
import WarehouseAttribute from "../components/attributes/WarehouseAttribute.vue";
import SaleBadge from "../components/badges/SaleBadge.vue";
import SaleAttribute from "../components/attributes/SaleAttribute.vue";

export default {
	layout: AppLayout,
	components: {
		SaleAttribute,
		SaleBadge,
		WarehouseAttribute,
		TextareaField,
		DateField,
		AddressAttribute,
		AddressBadge,
		ContractIdAttribute,
		ProjectIdAttribute,
		ProductBadge,
		SupplierBadge,
		WarehouseBadge,
		UpdatedAttribute,
		FileUploader,
		Attribute,
		Modal,
		ButtonGroup,
		FormWrapper,
		ColorBadge,
		TitleBar,
		DateTime,
		Number,
		InventoryShipmentForm,
		FileList, UserBadge, vSelect, Link, InventoryAddProductForm, DataLoader, InputField, ProductSelectField},
	props: {
		model: Object,
		productsInitial: Array,
		requiredProducts: Array,
		deliveredProducts: Object,
		reservedProducts: Object,
		projectDetails: Object,
		contractDetails: Object,
		deliveryRoute: Object,
		inspectionProducts: Array,
		initialItems: Array,
		sale: Object,
		barcodeDownloadUrl: String,
		hasInventoryInspection: Boolean
	},
	data(){
		return {
			loaded: false,
			attributes: this.model,
			products: this.productsInitial,
			selectedProductId: null,
			selectedProductContainerId: 0,
			barcode: '',
			unknownBarcode: '',
			quantityInput: 1,
			successSound: '',
			errorSound: '',
			selectedSound: '',
			app: useApplicationStore(),
			dataStore: useGeneralDataStore(),
			flashStore: useFlashStoreStore(),
			items: this.initialItems,
			mapBarcodeToProduct: null,
			mapBarcodeToProductType: null,
			mapBarcodeModalLoading: false,
			mapBarcodeModalAdd: false,
			mapBarcodeModalNewModel: {},
			addProduct: false,
			addProductModalLoading: false,
			addProductModel: {},
			sequentialScanning: false,
			sequentialScanningReservedOnly: true,
			sequentialScanningCompleted: false,
			sequentialScanIndex: 0,
			inspectionProductsOrdered: this.inspectionProducts
		}
	},
	watch: {
		model(newValue, oldValue){
			this.attributes = this.model
		},
		productsInitial(newValue, oldValue){
			this.products = this.productsInitial
		},
	},
	created(){
		this.successSound = new Audio('/audio/inventory-scan-success.mp3')
		this.successSound.preload = true

		this.errorSound = new Audio('/audio/inventory-scan-error.mp3')
		this.errorSound.preload = true

		this.selectedSound = new Audio('/audio/inventory-scan-selected.mp3')
		this.selectedSound.preload = true

		window.addEventListener("keypress", this.onKeyPress);

		this.dataStore.loadData(['productContainers', 'inventoryShipmentTypes', 'userLevels', 'products'], () => {
			this.loaded = true
		})
		if (this.inspectionProductsOrdered.length){
			this.reorderInspectionProducts()
		}
	},
	beforeUnmount() {
		window.removeEventListener("keypress", this.onKeyPress);
	},
	computed: {
		productsById: {
			get(){
				return _.keyBy(this.products, 'id')
			}
		},
		canEdit: {
			get(){
				return this.attributes.editable !== undefined && !this.attributes.closed_at && this.attributes.editable && this.attributes.type !== 'installation' && !this.hasInventoryInspection
			}
		},
		pageTitle: {
			get(){
				if (this.attributes.type === 'inspection'){
					return 'Leltár'
				}
				return this.attributes.skip_delivery ? 'Kiadási bizonylat' : 'Szállítmány'
			}
		},
		reservedProductsArray: {
			get(){
				let parts = []
				let nonParts = []
				_.forEach(this.reservedProducts, (item, productId) => {
					item.productId = productId
					item.productPriority = this.productsById[productId] !== undefined ? this.productsById[productId].priority : 0
					if (item.asComponent){
						parts.push(_.clone(item))
					} else {
						nonParts.push(_.clone(item))
					}
				})
				parts = _.reverse(_.sortBy(parts, ['productPriority']))
				nonParts = _.reverse(_.sortBy(nonParts, ['productPriority']))
				return _.union(_.cloneDeep(nonParts), _.cloneDeep(parts))
			}
		},
		canSubtract: {
			get(){
				if (this.attributes.type === 'correction' && (this.attributes.correction_type === 'incoming' || this.attributes.correction_type === 'inventory')){
					return true
				}
				if (this.attributes.type === 'transfer'){
					return true
				}

				return false
			}
		},
		productComments: {
			get() {
				let payload = {}

				_.forEach(this.items, (item) => {
					if (payload[item.product_id] === undefined){
						let product = this.getProductById(item.product_id)
						if (product !== null && product.comment_for_shipment){
							payload[item.product_id] = {
								id: product.id,
								name: product.name,
								comment: product.comment_for_shipment
							}
						}
					}
				})

				return _.values(payload)
			}
		},
		selectedProductHasContainers: {
			get(){
				return this.productsById[this.selectedProductId] !== undefined && Object.keys(this.productsById[this.selectedProductId].containers).length
			}
		},
		sidebarMode: {
			get(){
				return this.attributes.type === 'inspection' && !this.attributes.closed_at
			}
		},
		selectedProductUsableContainers: {
			get(){
				if (!this.productsById[this.selectedProductId]){
					return []
				}
				let payload = [{id: 0, name: this.productsById[this.selectedProductId].unit}]
				if (this.selectedProductHasContainers){
					let containers = this.productsById[this.selectedProductId].containers
					_.forEach(this.dataStore.getData('productContainers'), function (container){
						if (containers[container.id] !== undefined){
							payload.push(container)
						}
					})
				}
				return payload
			}
		},
		getRequiredProducts: {
			get(){
				let payload = []
				for (let i in this.requiredProducts){
					let item = Object.assign({}, this.requiredProducts[i])
					item.scanned = 0
					item.reserved = 0

					if (this.reservedProducts[this.requiredProducts[i].product_id] !== undefined){
						item.reserved = this.reservedProducts[this.requiredProducts[i].product_id]
					}

					for (let j in this.items){
						if (this.items[j].product_id === item.product_id){
							item.scanned = this.items[j].counter_max
						}
					}

					if (this.attributes.type === 'delivery') {
						if (item.quantity > item.delivered) {
							payload.push(item)
						}
					}
					if (this.attributes.type === 'return' && item.quantity > 0) {
						item.delivered = item.quantity
						payload.push(item)
					}
				}

				return payload
			}
		},
		sequentialScanningProduct: {
			get(){
				if (this.requiredProducts.length){
					if (this.requiredProducts[this.sequentialScanIndex] === undefined) {
						return false
					}

					return {
						product: this.productsById[this.requiredProducts[this.sequentialScanIndex].product_id],
						details: this.requiredProducts[this.sequentialScanIndex]
					}
				} else {
					if (this.attributes.type === 'return') {
						let ids = _.keys(this.deliveredProducts)
						if (ids[this.sequentialScanIndex] === undefined) {
							return false
						}

						return {
							product: this.productsById[ids[this.sequentialScanIndex]],
							details: this.deliveredProducts[ids[this.sequentialScanIndex]]
						}
					}
					if (this.attributes.type === 'delivery') {
						if (this.reservedProductsArray[this.sequentialScanIndex] === undefined) {
							return false
						}

						return {
							product: this.productsById[this.reservedProductsArray[this.sequentialScanIndex].productId],
							details: this.reservedProductsArray[this.sequentialScanIndex]
						}
					}
				}
			}
		}
	},
	methods: {
		objectToArray,
		onKeyPress: function (e){
			if (e.target.tagName.toLowerCase() !== 'input' && e.target.tagName.toLowerCase() !== 'textarea'){
				if (e.key.match(/^[0-9a-z]+$/)){
					this.barcode = e.key
					if (document.getElementById('barcodeInput')) {
						document.getElementById('barcodeInput').focus()
					}
				}
			}
		},
		shipmentCreated: function(attributes){
			this.attributes = attributes
		},
		getProductById: function(id){
			if (this.productsById[id] === undefined){
				return false
			}

			return this.productsById[id]
		},
		productFilter: function(option, label, search) {
			return option.categoryName.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
				|| option.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
				|| option.sku.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
		},
		barcodeSubmit: function(){
			if (this.barcode.length < 6){
				return
			}
			this.sendScanRequest(true)
		},
		setSelectedProductId: function (id){
			this.selectedProductId = id

			this.quantityInput = 1
			if (this.selectedProductHasContainers){
				this.selectedProductContainerId = 0
			}
			if (this.inspectionProductsOrdered.length){
				this.reorderInspectionProducts()
			}
		},
		reorderInspectionProducts: function (){
			if (this.inspectionProductsOrdered[0].id !== this.selectedProductId){
				for (let i = 0; i < this.inspectionProductsOrdered.length; i++){
					if (this.selectedProductId === this.inspectionProductsOrdered[i].id){
						let tmp = this.inspectionProductsOrdered[i]

						if (tmp.serial_numbers !== undefined){
							tmp.serial_numbers = this.getOrderedSerialNumbers(tmp.serial_numbers)
						}

						this.inspectionProductsOrdered.splice(i, 1)
						this.inspectionProductsOrdered.unshift(tmp)
					} else {
						if (this.inspectionProductsOrdered[i].serial_numbers !== undefined
							&& this.inspectionProductsOrdered[i].serial_numbers.length
							&& !(this.inspectionProductsOrdered[i].serial_numbers[0] instanceof Object)){
							this.inspectionProductsOrdered[i].serial_numbers = this.getOrderedSerialNumbers(this.inspectionProductsOrdered[i].serial_numbers)
						}
					}
				}
			}
		},
		setProductAsScanned: function (product){
			this.quantityInput = this.sequentialScanningReservedOnly ? product.details.reserved : product.details.quantity
			this.setSelectedProductId(product.product.id)
			this.productContainerId = null
			this.barcode = null

			this.sendScanRequest(true)
		},
		skipSequentialProduct: function (currentIndex){
			this.incrementSequentialScanIndex()
		},
		sendScanRequest: function(add){
			axios.post('/inventory/shipment/' + this.attributes.id + '/scan', {
				barcode: this.barcode,
				productId: this.selectedProductId ? this.selectedProductId : null,
				quantity: add ? this.quantityInput : this.quantityInput * -1,
				productContainerId: this.selectedProductHasContainers ? this.selectedProductContainerId : null
			}).then((response) => {
				if (response.data.action === 'error'){
					this.playSound(this.errorSound)
				}
				if (response.data.action === 'selected'){
					this.playSound(this.selectedSound)
				}
				if (response.data.action === 'added'){
					this.playSound(this.successSound)
				}

				if (response.data.productId !== undefined) {
					this.setSelectedProductId(response.data.productId)
				} else {
					if (this.inspectionProductsOrdered.length){
						this.reorderInspectionProducts()
					}
				}

				this.flashStore.addFlash(response.data.flash)
				this.items = response.data.items

				if (response.data.unknownBarcode !== undefined){
					this.barcode = response.data.unknownBarcode
					this.openUnknownBarcodeDialog()
				} else {
					this.quantityInput = 1
					this.barcode = ''
				}

				this.sequentialScanCheck()
			})
				.catch((error) => {
					this.barcode = ''

					this.flashStore.addError(error)
				})
		},
		playSound: function(audio){
			if (!audio.paused){
				audio.pause()
			}
			audio.currentTime = 0
			audio.play()
		},
		onProductSelected: function(){
			this.setSelectedProductId(this.selectedProductId)
		},
		removeItem: function(inventoryItem){
			inventoryItem.deleteLoading = true
			axios.post('/inventory/shipment/' + this.attributes.id + '/removeProduct', {
				inventoryItemId: inventoryItem.id
			})
				.then((response) => {
					this.items = response.data.items
				})
				.catch((error) => {
					this.flashStore.addError(error)
				})
		},
		getSupplierBarcodeForProduct: function(product, supplierId){
			for (let i in product.supplier_products){
				if (product.supplier_products[i].supplier_id === supplierId && product.supplier_products[i].barcode !== null){
					return product.supplier_products[i].barcode
				}
			}
			return false
		},
		assignBarcodeToProduct: function(){
			if (this.mapBarcodeModalAdd){
				let promise = this.$refs.mapBarcodeAddForm.validate()
				promise.then((status) => {
					if (status.valid){
						this.sendAssignCommand()
					}
				})
			} else {
				this.sendAssignCommand()
			}

		},
		sendAssignCommand: function(){
			this.mapBarcodeModalLoading = true
			axios.post('/inventory/shipment/' + this.attributes.id + '/assignBarcodeToProduct', {
				barcode: this.barcode,
				productId: this.mapBarcodeToProduct,
				type: this.mapBarcodeToProductType,
				add: this.mapBarcodeModalAdd,
				product: this.mapBarcodeModalAdd ? this.mapBarcodeModalNewModel : {}
			})
				.then((response) => {
					this.mapBarcodeModalLoading = false
					this.flashStore.addFlash(response.data.flash)
					if (response.data.success) {
						this.unknownBarcode = ''
						this.products = response.data.products

						this.sendScanRequest(true)
					}
				})
				.catch((error) => {
					this.flashStore.addError(error)
					this.mapBarcodeModalLoading = false
				})
		},
		newProductSubmit: function(){
			let promise = this.$refs.addProductForm.validate()
			promise.then((status) => {
				if (status.valid){
					this.storeNewProduct()
				}
			})
		},
		storeNewProduct: function(){
			this.addProductModalLoading = true
			axios.post('/inventory/shipment/' + this.attributes.id + '/createProduct', {
				product: this.addProductModel
			})
				.then((response) => {
					this.addProductModalLoading = false
					this.flashStore.addFlash(response.data.flash)
					if (response.data.success) {
						this.closeAddProductForm()
						this.products = response.data.products
						this.setSelectedProductId(response.data.product.id)

						this.playSound(this.successSound)
						this.sequentialScanCheck()
					}
				})
				.catch((error) => {
					this.flashStore.addError(error)
					this.mapBarcodeModalLoading = false
				})
		},
		closeShipment: function(){
			axios.post('/inventory/shipment/' + this.attributes.id + '/close', {})
				.then((response) => {
					if (response.data.success){
						this.attributes = response.data.model
						this.items = this.attributes.items
					}
					this.$refs.formWrapper.formClean()
					this.flashStore.addFlash(response.data.flash)
				})
				.catch((error) => {
					this.flashStore.addError(error)
				})
		},
		toggleAddRemove: function(item){
			axios.post('/inventory/shipment/' + this.attributes.id + '/toggleAddRemove', {
				inventoryItemId: item.id,
				set: item.quantity > 0 ? 'remove' : 'add'
			})
				.then((response) => {
					this.items = response.data.items
					if (response.data.flash || 0){
						this.flashStore.addFlash(response.data.flash)
					}
					if (!response.data.success){
						this.playSound(this.errorSound)
					}
				})
				.catch((error) => {
					this.flashStore.addError(error)
				})
		},
		openUnknownBarcodeDialog: function (){
			this.mapBarcodeToProduct = null
			this.mapBarcodeToProductType = null
			this.mapBarcodeModalAdd = false
			this.mapBarcodeModalLoading = false
			this.mapBarcodeModalNewModel = {
				type: 'material'
			}
			this.unknownBarcode = this.barcode
		},
		closeUnknownBarcodeDialog: function (){
			this.unknownBarcode = ''
			this.barcode = ''
		},
		openAddProductForm: function (){
			this.addProduct = true
			this.addProductModalLoading = false
			this.addProductModel = {
				type: 'material'
			}
		},
		closeAddProductForm: function (){
			this.addProduct = false
		},
		printBarcode: function (){
			printJS({printable: this.barcodeDownloadUrl + '/' + this.selectedProductId, type: 'pdf'})
		},
		getScanCount: function (productId){
			productId = parseInt(productId)
			let payload = 0
			for (let j in this.items){
				if (this.items[j].product_id === productId){
					payload += this.items[j].quantity
				}
			}

			return payload
		},
		startScanning: function (onlyReserved){
			if (!this.sequentialScanning) {
				this.sequentialScanningReservedOnly = onlyReserved
				this.sequentialScanning = true
				this.sequentialScanningCompleted = false
				this.sequentialScanIndex = -1

				this.incrementSequentialScanIndex()
			}
		},
		incrementSequentialScanIndex: function (){
			if (this.requiredProducts.length){
				this.sequentialScanIndex++

				if (this.sequentialScanIndex >= this.requiredProducts.length){
					this.sequentialScanCompleted()
					return
				}
			} else {
				if (this.attributes.type === 'delivery') {
					this.sequentialScanIndex++

					if (this.sequentialScanIndex >= this.reservedProductsArray.length){
						this.sequentialScanCompleted()
						return
					}
				}
				if (this.attributes.type === 'return') {
					let ids = _.keys(this.deliveredProducts)
					this.sequentialScanIndex++

					if (this.sequentialScanIndex >= ids.length){
						this.sequentialScanCompleted()
						return
					}
				}
			}

			this.sequentialScanCheck()
		},
		sequentialScanCompleted: function (){
			this.sequentialScanning = false
			this.sequentialScanningCompleted = true
		},
		sequentialScanCheck: function (){
			if (this.requiredProducts.length){
				if (!this.requiredProducts[this.sequentialScanIndex]){
					return
				}
				if (this.attributes.type === 'delivery' && this.requiredProducts[this.sequentialScanIndex].quantity_to_deliver <= this.getScanCount(this.requiredProducts[this.sequentialScanIndex].product_id)) {
					this.incrementSequentialScanIndex()
				}
				if (this.attributes.type === 'return' && this.requiredProducts[this.sequentialScanIndex].quantity_to_return <= this.getScanCount(this.requiredProducts[this.sequentialScanIndex].product_id)) {
					this.incrementSequentialScanIndex()
				}
			} else {
				if (this.attributes.type === 'delivery') {
					if (this.reservedProductsArray[this.sequentialScanIndex] !== undefined) {
						if (this.sequentialScanningReservedOnly) {
							if (this.reservedProductsArray[this.sequentialScanIndex].reserved < 1
								|| (this.getScanCount(this.reservedProductsArray[this.sequentialScanIndex].productId) >= this.reservedProductsArray[this.sequentialScanIndex].reserved)) {
								this.incrementSequentialScanIndex()
							}
						} else {
							if (this.reservedProducts[this.sequentialScanIndex].quantity < 1
								|| (this.getScanCount(this.reservedProductsArray[this.sequentialScanIndex].productId) >= this.reservedProductsArray[this.sequentialScanIndex].quantity)) {
								this.incrementSequentialScanIndex()
							}
						}
					}
				}
				if (this.attributes.type === 'return') {
					let ids = _.keys(this.deliveredProducts)
					if (this.deliveredProducts[ids[this.sequentialScanIndex]] !== undefined) {
						if (this.deliveredProducts[ids[this.sequentialScanIndex]].to_return < 1
							|| (this.getScanCount(ids[this.sequentialScanIndex]) >= this.deliveredProducts[ids[this.sequentialScanIndex]].to_return)) {
							this.incrementSequentialScanIndex()
						}
					}
				}
			}
		},
		getRequiredQuantity: function (item){
			if (this.attributes.type === 'delivery') {
				return item.quantity_to_deliver
			}
			if (this.attributes.type === 'return') {
				return item.quantity_to_return
			}

			return 0
		},
		getOrderedSerialNumbers: function (serialNumbers){
			let scanned = []
			let payload = []
			_.forEach(serialNumbers, (item) => {
				let serialNumber = item instanceof Object ? item.serialNumber : item
				for (let j in this.items){
					if (this.items[j].serial_number === serialNumber){
						scanned.push({
							serialNumber: serialNumber,
							scanned: true
						})
						return
					}
				}

				payload.push({
					serialNumber: serialNumber,
					scanned: false
				})
			})
			_.forEach(scanned, (item) => {
				payload.push(item)
			})

			return payload
		},
		getInspectionProductRemainingQuantity: function (productDetails){
			if (productDetails.serial_numbers !== undefined){
				return _.filter(productDetails.serial_numbers, (item) => {
					return !item.scanned
				}).length
			} else {
				return productDetails.quantity - this.getScanCount(productDetails.id)
			}
		},
	}
}
</script>
<style scoped>
</style>
