<template>
	<TitleBar :title="$t('Bejelentkezés')" />

	<div>
		<h5 class="text-primary">{{ $t('Üdv!') }}</h5>
		<p class="text-muted">{{ $t('Jelentkezz be a rendszerbe') }}</p>
	</div>
	<div class="mt-4">
		<form-wrapper
			v-model="attributes"
			:store-url="route('clientArea.auth.login')"
			:show-bottom-buttons="false"
			:show-top-buttons="false"
		>
			<template #default="{activeTab, errors, editable}">
				<input-field
					:label="$t('E-mail cím')"
					name="email"
					v-model="attributes.email"
					rules="required"
					:multilang="false"
					data-type="email"
					:errors="errors"
				/>

				<input-field
					:label="$t('Jelszó')"
					name="password"
					v-model="attributes.password"
					rules="required"
					:multilang="false"
					data-type="password"
					:errors="errors"
				>
					<template #beforeLabel>
						<div class="float-end">
							<Link :href="route('clientArea.auth.lostPassword')" class="text-muted" tabindex="5">{{ $t('Elfelejtetted a jelszavad?') }}</Link>
						</div>
					</template>
				</input-field>

				<switch-field
					:label="$t('Jegyezzen meg')"
					name="remember"
					v-model="attributes.remember"
					:inline="true"
					:inline-reversed="true"
				/>

				<div class="mt-3 d-grid">
					<button class="btn btn-primary waves-effect waves-light" tabindex="4" type="submit">{{ $t('Belépés') }}</button>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import ClientAreaAuthLayout from "../layouts/ClientAreaAuthLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import SwitchField from "../components/form/SwitchField.vue";
import {Link} from "@inertiajs/vue3";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import {useApplicationStore} from "../stores/applicationStore";

export default {
	components: {InputField, FormWrapper, Link, SwitchField, TitleBar},
	layout: ClientAreaAuthLayout,
	data() {
		return {
			app: useApplicationStore(),
			attributes: {
				email: '',
				password: '',
				remember: false
			}
		}
	}
}
</script>
