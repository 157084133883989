<template>
	<i :class="{fas: 1, 'fa-clipboard': !open, 'fa-check': open, 'position-relative': 1, 'ms-2': leftMargin}" title="Vágólapra másolás" @click.prevent="copy">
		<span :class="{'clipboard-tooltip': 1, 'd-none': !open}">
			Vágólapra másolva
		</span>
	</i>
</template>

<script>
export default {
	props: {
		value: String,
		leftMargin: {
			type: Boolean,
			default: true
		}
	},
	data(){
		return {
			open: false,
			timer: null
		}
	},
	methods: {
		copy: function(){
			this.timer = setTimeout(() => {
				this.open = false
			}, 3000);
			navigator.clipboard.writeText(this.value)
			this.open = true
		}
	}
}
</script>

<style scoped>
i {
	cursor: pointer;
}
i:hover {
	color: var(--bs-primary);
}
.clipboard-tooltip {
	display: block;
	position: absolute;
	left: 50%;
	bottom: calc(100% + 5px);
	transform: translate(-50%, 0);
	background: var(--bs-dark);
	color: #fff;
	padding: 8px;
	border-radius: var(--bs-border-radius);
	font-size: var(--bs-body-font-size);
	white-space: nowrap;
	z-index: 500;
	font-family: var(--bs-body-font-family);
}
</style>
