<template>
	<div>
		<AdvancedDataTable
				:data-url="listUrl"
				:columns="columns"
				sort-by="created_at"
				ref="table"
				sort-type="desc"
				:row-class-callback="getRowClass"
				@results-found="$emit('resultsFound')"
				:compact="true"
				name="projectInstallationList"
		>
			<template #item-operations="{item, deleteButtonPressed}">
				<div class="operations-wrapper">
					<div class="mobile-cell">
						<div class="stacked-cell">
							<strong>{{ item.contract_public_id }}</strong>
							<attribute icon="far fa-clock" label="Aláírva"><date-time :value="item.contract_signed_at"/></attribute>
							<attribute icon="far fa-user" label="Telepítő">{{ item.installerUserName }}</attribute>
							<InstallationDateCell :item="item" />
							<color-badge
								:color="item.closed_at ? 'success' : 'warning'"
								:icon="item.closed_at ? 'far fa-check' : 'far fa-hourglass'"
								label="Állapot"
							>
								<template v-if="item.closed_at">
									Lezárva: <date-time :value="item.closed_at" :time="false"/>
								</template>
								<template v-else>
									Függőben
								</template>
							</color-badge>
							<color-badge
								:color="(item.contractQuantity <= item.deliveredQuantity) ? 'success' : (0 < item.deliveredQuantity ? 'warning' : 'danger')"
								label="Kiszállított termékek"
							>
								{{ item.deliveredQuantity + ' / ' + item.contractQuantity}}
							</color-badge>
						</div>
					</div>
					<div class="table-row-operations d-flex gap-2">
						<button v-if="item.editable && editable" type="button" class="btn btn-primary btn-sm waves-effect" title="Szerkesztés" @click.prevent="openInstallationDialog(item)">
							<i class="far fa-edit label-icon"></i>
						</button>
						<button v-else type="button" class="btn btn-primary btn-sm waves-effect" title="Részletek" @click.prevent="openInstallationDialog(item)">
							<i class="far fa-eye label-icon"></i>
						</button>
					</div>
				</div>
			</template>
			<template #item-contract_public_id="item">
				<div class="fw-bold">{{ item.contract_public_id }}</div>
				<attribute icon="far fa-clock" label="Aláírva"><date-time :value="item.contract_signed_at"/></attribute>
			</template>
			<template #item-installation_date="item">
				<InstallationDateCell :item="item" />
			</template>
			<template #item-closed_at="item">
				<color-badge
					:color="item.closed_at ? 'success' : 'warning'"
					:icon="item.closed_at ? 'far fa-check' : 'far fa-hourglass'"
					label="Állapot"
					>
					<template v-if="item.closed_at">
						Lezárva: <date-time :value="item.closed_at" :time="false"/>
					</template>
					<template v-else>
						Függőben
					</template>
				</color-badge>
			</template>
			<template #item-delivered_products="item">
				<color-badge
					:color="(item.contractQuantity <= item.deliveredQuantity) ? 'success' : (0 < item.deliveredQuantity ? 'warning' : 'danger')"
					label="Kiszállított termékek"
				>
					{{ item.deliveredQuantity + ' / ' + item.contractQuantity}}
				</color-badge>
			</template>
		</AdvancedDataTable>
	</div>
	<teleport to="body" v-if="installationDialogId">
		<ProjectInstallationForm
			:contract-id="installationDialogContractId"
			:installation-id="installationDialogId"
			:editable="editable"
			:form-errors="formErrors"
			:can-start-editing="canStartEditing"
			@close="installationDialogId = false"
			@close-and-reload="closeInstallationFormAndUpdateList"
			@start-editing="$emit('startEditing')"
			@load-data="loadData"
		/>
	</teleport>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import QuoteForm from './QuoteForm.vue'
import Price from "../../components/Price.vue";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import QuoteResponseForm from "./QuoteResponseForm.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import ProjectInstallationForm from "./ProjectInstallationForm.vue";
import {useProjectDataStore} from "../../stores/projectData";
import DateTime from "../../components/DateTime.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import ProjectTypeCell from "./ProjectTypeCell.vue";
import Attribute from "../../components/Attribute.vue";
import InstallationDateCell from "./InstallationDateCell.vue";

export default {
	components: {
		InstallationDateCell,
		Attribute, ProjectTypeCell, ColorBadge,
		DateTime,
		ProjectInstallationForm,
		TableActionDropdown,
		QuoteResponseForm,
		Price,
		TableActionCell,
		QuoteForm,
		AdvancedDataTable,
	},
	emits: ['formFlash', 'openContract', 'resultsFound', 'startEditing', 'loadData', 'reloadDocuments', 'reloadShipments'],
	props: {
		listUrl: String,
		dataUrl: String,
		installationDocumentTypes: Array,
		editable: Boolean,
		formErrors: Object,
		canStartEditing: Boolean,
		hideContract: Boolean,
	},
	mounted() {
		if (this.projectDataStore.activeTabCommand === 'installation' && this.projectDataStore.activeTabParameter){
			let tmp = this.projectDataStore.activeTabParameter.split('|')
			this.openInstallationDialog({id: tmp[1], contract_id: tmp[0]})
		}
	},
	data(){
		let columns = []
		if (!this.hideContract){
			columns.push({
				text: 'Szerződés',
				value: 'contract_public_id',
				sortable: true,
			})
		}
		columns.push({
			text: 'Állapot',
			value: 'closed_at',
			sortable: true,
		})
		columns.push({
			text: 'Telepítés dátuma',
			value: 'installation_date',
			sortable: true,
		})
		columns.push({
			text: 'Telepítő',
			value: 'installerUserName',
			sortable: true,
		})
		columns.push({
			text: 'Kiszállított termékek',
			value: 'delivered_products'
		})
		columns.push({
			label: '',
			value: 'operations',
			width: 5,
		})

		return {
			columns: columns,
			formOpen: false,
			quoteFormId: null,
			installationDialogId: null,
			projectDataStore: useProjectDataStore(),
			installationDialogContractId: null,
			responseFormOpen: false,
		}
	},
	methods: {
		getRowClass: function (item, rowNumber){
			let classes = []
			if (this.activeTabCommand === 'installation' && parseInt(this.activeTabParameter) === item.id){
				classes.push('table-info')
			}
			return classes.join(' ')
		},
		openInstallationDialog: function(item){
			this.installationDialogId = item.id
			this.installationDialogContractId = item.contract_id
		},
		closeInstallationFormAndUpdateList: function(){
			this.installationDialogId = false
			this.installationDialogContractId = false
			this.$refs.table.getData()
			this.projectDataStore.loadDocuments()
			this.$emit('reloadShipments')
		},
		loadData: function (data, cb){
			this.$emit('loadData', data, cb)
		},
		reloadDocuments: function (){
			this.$emit('reloadDocuments')
		}
	}
}
</script>

<style scoped>
</style>
