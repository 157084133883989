<script>
import {Field} from "vee-validate";
import {Link} from "@inertiajs/vue3";
import moment from "moment";

export default {
	components: {Link, Field},
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: String,
		name: {
			type: String,
			default: ''
		},
		tabindex: {
			type: Number,
			default: -1
		},
		modelValue: [String, Number],
		fieldOnly: Boolean,
		noMargin: Boolean,
		showPlaceholder: Boolean
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		componentHours: {
			get() {
				if (this.componentValue === undefined || this.componentValue === null){
					return null
				}
				let tmp = this.componentValue.split(':')
				tmp[0] = parseInt(tmp[0])
				let hasNan = false
				if (isNaN(tmp[0])){
					hasNan = true
					tmp[0] = 0
				}
				let value = tmp[0] < 10 ? "0" + tmp[0] : tmp[0]
				if (hasNan){
					this.componentHours = value
				}
				return value
			},
			set(value) {
				this.componentValue = value + ':' + (this.componentMinutes)
			}
		},
		componentMinutes: {
			get() {
				if (this.componentValue === undefined || this.componentValue === null){
					return null
				}
				let tmp = this.componentValue.split(':')
				if (tmp[1] === undefined){
					return null
				}
				tmp[1] = parseInt(tmp[1])
				let hasNan = false
				if (isNaN(tmp[1])){
					hasNan = true
					tmp[1] = 0
				}
				let value = tmp[1] < 10 ? "0" + tmp[1] : tmp[1]
				if (hasNan){
					this.componentMinutes = value
				}
				return value
			},
			set(value) {
				this.componentValue = this.componentHours + ':' + value
			}
		},
		isRequired: {
			get() {
				return this.rules.indexOf('required') > -1
			}
		},
	},
	methods: {
		moment
	}
}
</script>

<template>
	<div :class="{'mb-3': !fieldOnly && !noMargin}">
		<label :class="{required: isRequired}" v-if="!fieldOnly">{{ label }}</label>
		<Field
			v-model="componentValue"
			:name="name"
			type="time"
			:readonly="disabled"
			:disabled="disabled"
			:placeholder="showPlaceholder ? label : ''"
			:tabindex="tabindex > -1 ? tabindex : null"
		>
			<div class="d-flex w-100 gap-2 align-items-center time-picker">
				<div class="dropdown flex-grow-1">
					<template v-if="disabled">
						<button type="button" class="btn btn-disabled w-100" disabled>
							{{ componentHours === null ? '--' : componentHours }}
						</button>
					</template>
					<template v-else>
						<button type="button" class="btn btn-light w-100" data-bs-toggle="dropdown" aria-expanded="false">
							{{ componentHours === null ? '--' : componentHours }}
						</button>
						<div class="dropdown-menu" role="menu">
							<template v-for="index in 24">
								<a href="#" :class="{'dropdown-item': 1, 'active': componentHours !== null && parseInt(componentHours) === index - 1}" @click="componentHours = index - 1">
									{{ (index <= 10 ? '0' : '') + (index - 1) }}
								</a>
							</template>
						</div>
					</template>
				</div>
				<div class="font-size-16 fw-medium">:</div>
				<div class="dropdown flex-grow-1">
					<template v-if="disabled">
						<button type="button" class="btn btn-disabled w-100" disabled>
							{{ componentMinutes === null ? '--' : componentMinutes }}
						</button>
					</template>
					<template v-else>
						<button type="button" class="btn btn-light w-100" data-bs-toggle="dropdown" aria-expanded="false">
							{{ componentMinutes === null ? '--' : componentMinutes }}
						</button>
						<div class="dropdown-menu" role="menu">
							<a href="#" :class="{'dropdown-item': 1, 'active': componentMinutes !== null && parseInt(componentMinutes) === 0}" @click="componentMinutes = 0">
								00
							</a>
							<a href="#" :class="{'dropdown-item': 1, 'active': parseInt(componentMinutes) === 15}" @click="componentMinutes = 15">
								15
							</a>
							<a href="#" :class="{'dropdown-item': 1, 'active': parseInt(componentMinutes) === 30}" @click="componentMinutes = 30">
								30
							</a>
							<a href="#" :class="{'dropdown-item': 1, 'active': parseInt(componentMinutes) === 45}" @click="componentMinutes = 45">
								45
							</a>
						</div>
					</template>
				</div>
				<button class="btn text-danger" v-if="!isRequired && !disabled" @click.prevent="componentValue = null">
					<i class="far fa-times"></i>
				</button>
			</div>
		</Field>
	</div>
</template>

<style scoped>
.time-picker .dropdown-item:hover {
	background: var(--bs-primary) !important;
	color: #fff !important;
}
.time-picker .dropdown-item.active {
	background: var(--bs-secondary) !important;
	color: #fff !important;
}
.time-picker .btn-light {
	background: #fff !important;
	border: 1px solid var(--bs-input-border-color) !important;
	color: var(--bs-gray-700) !important;
}
.time-picker .btn-disabled {
	background: var(--bs-gray-200);
	border: 1px solid var(--bs-input-border-color);
	color: var(--bs-gray-700);
	opacity: 1 !important;
}
.time-picker .dropdown-menu {
	max-height: 300px;
	overflow: scroll;
}
.time-picker .btn.text-danger {
	padding-left: 5px;
	padding-right: 5px;
}
</style>
