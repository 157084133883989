<template>
	<TitleBar :title="attributes.id !== undefined ? 'Felhasználói szint módosítása' : 'Felhasználói szint hozzáadása'" />

	<form-wrapper
		ref="formWrapper"
		:store-url="route('userLevels.store', model.id)"
		:back-url="route('userLevels.index')"
		v-model="attributes"
	>
		<template v-slot:top>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<updated-attribute :data="attributes" created/>
						<updated-attribute :data="attributes" updated/>
					</div>
				</div>
			</div>
		</template>
		<template #default="{activeTab, errors, editable}">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Rövid név"
								name="name_short"
								rules="required"
								v-model="attributes.name_short"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<order-field
								v-model="attributes"
								label="Sorrend"
							/>
							<iconpicker-field
								v-model="attributes.icon"
								label="Ikon"
								name="icon"
							/>
							<div class="mb-3">
								<label>Szín</label>
								<input type="color" class="form-control form-control-color" v-model="attributes.color"  />
							</div>

							<input-field
								label="Jutalom (pénz)"
								name="reward_money"
								data-type="number"
								v-model="attributes.reward_money"
								suffix="Ft"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Jutalom (ajándék)"
								name="reward_gift"
								v-model="attributes.reward_gift"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								label="Szükséges egység"
								name="required_units"
								v-model="attributes.required_units"
								data-type="number"
								:step="1"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Szintlépés egységszámítás időablak"
								suffix="hónap"
								name="time_window_months"
								rules="required"
								v-model="attributes.time_window_months"
								data-type="number"
								:step="1"
								:min="1"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Egy beosztott egységeinek maximum aránya"
								suffix="%"
								name="max_ratio_per_underling"
								rules="required"
								v-model="attributes.max_ratio_per_underling"
								data-type="number"
								:step="0.1"
								:min="0"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Direkt jutalék"
								name="direct_commission"
								rules="required"
								v-model="attributes.direct_commission"
								data-type="number"
								suffix="%"
								:step="0.1"
								:min="0"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<input-field
								label="Csapat jutalék"
								name="indirect_commission"
								rules="required"
								v-model="attributes.indirect_commission"
								data-type="number"
								suffix="%"
								:step="0.1"
								:min="0"
								:disabled="!attributes.editable"
								:errors="errors"
							/>
							<switch-field
								label="Szintépésnél felettes feljebbléptetése"
								name="propagate_to_superior"
								v-model="attributes.propagate_to_superior"
							/>
						</div>
					</div>
				</div>
			</div>
			<discount-limits v-model="attributes" :editable="editable" :errors="errors" />
		</template>
	</form-wrapper>
</template>

<script>
import AppLayout from "../layouts/AppLayout.vue";
import TitleBar from "../components/TitleBar.vue";
import FormWrapper from "../components/FormWrapper.vue";
import InputField from "../components/form/InputField.vue";
import SwitchField from "../components/form/SwitchField.vue";
import IconpickerField from "../components/form/IconpickerField.vue";
import DateTime from "../components/DateTime.vue";
import OrderField from "../components/form/OrderField.vue";
import UpdatedAttribute from "../components/UpdatedAttribute.vue";
import DiscountLimits from "../components/DiscountLimits.vue";

export default {
	components: {DiscountLimits, UpdatedAttribute, OrderField, DateTime, IconpickerField, SwitchField, InputField, FormWrapper, TitleBar},
	layout: AppLayout,
	props: {
		model: Object
	},
	data(){
		return {
			attributes: this.model,
		}
	},
}
</script>
