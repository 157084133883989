<template>
	<div class="dashboard-contract-list" ref="dashboardContractList">
		<div v-for="contract in contracts" :key="'dashboardContract_' + contract.id" class="dashboard-contract-list--item">
			<div class="d-flex gap-2">
				<div class="stacked-cell flex-grow">
					<strong>{{ contract.clientName }}</strong>
					<project-type-cell :data="contract" small />
					<color-badge
						icon="far fa-map"
						color="light"
						small
						:content="contract.address"
					/>
				</div>

				<div class="ms-auto">
					<div class="dropdown">
						<button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="far fa-ellipsis-h"></i>
						</button>
						<div class="dropdown-menu dropdown-menu-end" role="menu">
							<Link :href="'/projects/edit/' + contract.projectId + '/contracts/contract:' + contract.id" class="dropdown-item">
								<i class="far fa-file-invoice fa-fw me-1 text-warning"></i> Szerződéshez
							</Link>
							<Link :href="'/projects/edit/' + contract.projectId + '/quotes/quote:' + contract.quoteId" class="dropdown-item" v-if="contract.quoteId">
								<i class="far fa-file-invoice fa-fw me-1 text-warning"></i> Árajánlathoz
							</Link>
							<Link :href="'/projects/edit/' + contract.projectId" class="dropdown-item">
								<i class="far fa-file-invoice fa-fw me-1 text-warning"></i> Projekthez
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RelativeTime from "../../components/RelativeTime.vue";
import { usePage, Link } from '@inertiajs/vue3';
import {useApplicationStore} from "../../stores/applicationStore";
import AdvancedDataTable from "../../components/AdvancedDataTable.vue";
import ButtonGroup from "../../components/ButtonGroup.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import Attribute from "../../components/Attribute.vue";
import DataLoader from "../../components/DataLoader.vue";
import AddShipmentButton from "../inventory/AddShipmentButton.vue";
import WarehouseSelectField from "../../components/form/WarehouseSelectField.vue";
import Number from "../../components/Number.vue";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import {useFlashStoreStore} from "../../stores/flashStore";
import FileSize from "../../components/FileSize.vue";
import {useInfiniteScroll} from "@vueuse/core";

export default {
	components: {
		FileSize,
		TableActionDropdown,
		ProjectTypeCell,
		Number,
		WarehouseSelectField,
		AddShipmentButton,
		DataLoader,
		Attribute,
		ColorBadge,
		ButtonGroup,
		AdvancedDataTable,
		RelativeTime, Link},
	data(){
		return {
			app: useApplicationStore(),
			flashStore: useFlashStoreStore(),
			page: usePage(),
			contracts: [],
			nextPage: null,
			infiniteScroll: null,
			firstLoad: true,
			loading: true,
			endReached: false
		}
	},
	mounted() {
		this.load()
	},
	watch: {
		'app.tasks': function (newVal, oldVal){
		}
	},
	methods: {
		load: function (link){
			this.loading = true
			axios.get(link || '/dashboard/contracts').then((response)=>{
				if (!response.data.data.length){
					this.endReached = true
				}
				this.contracts = this.contracts.concat(response.data.data)
				this.nextPage = response.data.links.next
				if (this.firstLoad){
					this.firstLoad = false
					setTimeout(() => {
						this.infiniteScroll = useInfiniteScroll(this.$refs.dashboardContractList, this.loadMore)
					}, 100)
				}
				this.loading = false
			}).catch((error) => {
				this.flashStore.addError(error)
			})
		},
		loadMore: function (){
			if (this.endReached || this.loading || this.nextPage === null){
				return;
			}
			this.load(this.nextPage)
		}
	}
}
</script>

<style scoped>

</style>
